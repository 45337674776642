import PolicyLimitGroup from '@/Enums/PolicyLimitGroupEnum';

export default class PolicyLimitGroupMapper {
    public byLimitId(id: string): PolicyLimitGroup {
        let result: string = '';
        switch (id) {
            case '1.2.':
            case '1.2.1.':
            case '1.2.5.':
            case '1.2.6.':
            case '1.2.6.21.':
            case '2.4.':
            case '1.2.8.':
            case '6':
            case 'EE.1.2.8.':
            case 'EE.1.2.8.1.38.':
            case 'LT.PROFIL':
            case 'LT.REAB':
                result = PolicyLimitGroup.Rehabilitation;
                break;

            case '1.2.9.':
            case '3':
            case '3.1.':
            case '3.2.':
            case 'EE.3':
            case 'LT.NERECEPT':
            case 'LT.VAIST':
                result = PolicyLimitGroup.Drugs;
                break;

            case '1.2.12.':
            case 'EE.1.2.12.':
            case 'LT.NESC':
                result = PolicyLimitGroup.Pregnancy;
                break;

            case '4':
            case '4.1.':
            case '4.1.2.3.3.':
            case '4.2.':
            case '4.5.':
            case '10':
            case 'EE.4':
            case 'LT.DABT':
                result = PolicyLimitGroup.Dentist;
                break;

            case '7':
            case '7.2.':
            case 'EE.7':
            case 'LT.SVEIK':
                result = PolicyLimitGroup.Sport;
                break;

            case '5':
            case '5.1.1.':
            case '5.3.1.':
            case 'EE.5':
            case 'LT.OPT':
                result = PolicyLimitGroup.Optics;
                break;

            default:
                result = PolicyLimitGroup.Basic;
        }

        return result as PolicyLimitGroup;
    }
}
