<style src="./TooltipsterOverride.scss" lang="scss"></style>
<script setup lang="ts">
import Vue, {Ref, ref, onMounted, computed, ComputedRef, watch} from 'vue';
import Libraries from '@/services/libraries.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {v4 as uuidv4} from 'uuid';
import {Subscription} from 'rxjs';
import KeyUpEvent = JQuery.KeyUpEvent;
import VueEvent from '@/Classes/VueEventClass';

const props = defineProps({
  title: {type: String, default: ''},
  description: {type: String, default: ''},
  openOnHover: {type: Boolean, default: false},
  mobileScrollable: {type: Boolean, default: false},
});

const emit = defineEmits(['icon-click']);
const openButtonElement: Ref<HTMLElement | null> = ref(null);
const id: string = ['tooltipster', Date.now(), Math.random()].join('-').replace('.', '');
const openOnHoverData: Ref<boolean> = ref(props.openOnHover);
const isVisibleIcon: ComputedRef<boolean> = computed(() => {
  return initialised && (props.title !== '' || props.description !== '');
});

let initialised: Ref<boolean> = ref(false);
let tooltipsterUuid: Ref<string> = ref('');

watch(() => props.openOnHover, (newValue) => {
  openOnHoverData.value = newValue;
});

onMounted((): void => {
  if (Libraries.ready) {
    initTooltipster();
  } else {
    const librariesLoadComplete: Subscription = Libraries.onLoadComplete.subscribe(() => {
      initTooltipster();
      librariesLoadComplete.unsubscribe();
    });
  }
  applyTooltipsterId();
  initCustomEvents();
});

function initTooltipster(): void {
  const tipster: any = $(openButtonElement.value!);
  if (!initialised.value && !!tipster.tooltipster) {
    initialised.value = true;
    const tipster: DynamicDictionary = $(openButtonElement.value!);
    tipster.tooltipster({
      theme: 'tooltipster-shadow',
      arrow: false,
      interactive: true,
      contentCloning: true,
      animationDuration: 200,
      minWidth: 320,
      maxWidth: 320,
      repositionOnScroll: false,
      side: ['right', 'bottom', 'left', 'top'],
      trigger: 'custom',
      triggerOpen: {
        click: true,
        tap: true,
        mouseenter: openOnHoverData.value || false,
        touchstart: openOnHoverData.value || false,
      },
      triggerClose: {
        click: true,
        tap: true,
        mouseleave: openOnHoverData.value || false,
        touchleave: openOnHoverData.value || false
      },
      functionPosition: position,
      functionReady: () => {
        $('.tooltipster-close').on('click', () => {
          closeActiveTooltipster();
        });
      }
    });
  }
}

function initCustomEvents(): void {
  Vue.nextTick(() => {
    applyKeyUpEvents();
    applyBlurEvents();
  })
}

function applyKeyUpEvents(): void {
  const tooltipster: JQuery = $('#' + tooltipsterUuid.value);
  tooltipster.off('keyup').on('keyup', (event: KeyUpEvent) => {
    if (tooltipster.is(':focus')) {
      if (event.key === 'Tab') {
        triggerTabPressEvent();
      }
      if (event.key === 'Enter') {
        triggerEnterPressEvent();
      }
      if (event.key === 'Escape') {
        closeActiveTooltipster();
      }
    }
  });
}

function triggerTabPressEvent(): void {
  if (openOnHoverData.value) {
    $(openButtonElement.value!).trigger('mouseover');
  }
}

function triggerEnterPressEvent(): void {
  $(openButtonElement.value!).trigger('click');
}

function applyBlurEvents(): void {
  const tooltipster: JQuery = $('#' + tooltipsterUuid.value);
  tooltipster.parent().off('blur').on('blur', () => {
    closeActiveTooltipster();
  })
}

function closeActiveTooltipster(): void {
  const tipsterClose: DynamicDictionary = $(openButtonElement.value!);
  tipsterClose.tooltipster('hide');
}

function applyTooltipsterId(): void {
  tooltipsterUuid.value = uuidv4();
}

function position(instance: any, helper: any, position: any): number {
  const windowHeight: number | undefined = $(window).height();
  const tooltipHeight: number = position.size.height;
  const tooltipTop: number = position.coord.top;
  if (windowHeight && (tooltipHeight + tooltipTop > windowHeight)) {
    let newTop: number = windowHeight * 0.5 - tooltipHeight * 0.5;
    if (newTop < 10) {
      newTop = 10;
    }
    position.coord.top = newTop;
  }

  return position;
}

function isEmpty(string: string): boolean {
    return string === '';
}

function emitIconClick(event: VueEvent): void {
  emit('icon-click', event);
}
</script>

<template>
  <div class="tooltipster informative-popup"
       tabindex="0"
       role="button"
       :class="{'visible': isVisibleIcon}"
       :id="tooltipsterUuid">
    <span ref="openButtonElement" class="icon-container" v-bind:data-tooltip-content="'#' + id"
          @click="emitIconClick($event)">
        <span class="icon main-icon">
            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="9" stroke-width="2"></circle>
                <path d="M9.06009 11.5887H10.8369C10.8369 10.3783 13 9.77304 13 7.70977C13 5.93535 11.5708 5 10.1159 5C8.75107 5 7.5279 5.74278 7 7.13205L8.51931 8.0674C8.7897 7.32462 9.29185 6.84319 10.0515 6.84319C10.7983 6.84319 11.2232 7.21458 11.2232 7.84732C11.2232 9.20908 9.06009 9.77304 9.06009 11.5887ZM9.9485 15C10.5536 15 11.0429 14.4773 11.0429 13.8308C11.0429 13.1843 10.5536 12.6616 9.9485 12.6616C9.34335 12.6616 8.85408 13.1843 8.85408 13.8308C8.85408 14.4773 9.34335 15 9.9485 15Z"></path>
            </svg>
        </span>
    </span>
    <div class="tooltipster-template">
        <span :id="id">
            <div class="tooltipster-bubble" :class="{'mobile-scrollable': mobileScrollable}">
                <div class="title" v-if="!isEmpty(title)" v-html="title"></div>
                <div class="description" v-if="!isEmpty(description)" v-html="description"></div>
                <span v-if="!openOnHoverData"
                      class="tooltipster-close" @click="close()">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000"
                         xml:space="preserve">
                        <g>
                            <path d="M587.8,503.9L971,120.7c25.3-25.3,25.3-66.4,0-91.7c-25.3-25.3-66.4-25.3-91.7,0L496.1,412.2L122.5,38.6c-25.7-25.7-67.5-25.7-93.2,0c-25.7,25.7-25.7,67.5,0,93.2l373.6,373.6L53.7,854.6c-25.3,25.3-25.3,66.4,0,91.7c25.3,25.3,66.4,25.3,91.7,0l349.2-349.2l373.6,373.6c25.7,25.7,67.5,25.7,93.2,0c25.7-25.7,25.7-67.5,0-93.2L587.8,503.9z"/>
                        </g>
                    </svg>
                </span>
            </div>
        </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.icon-container {
  display: flex;
}
</style>
