import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export const useTransforms = (): TransformParams => {
    const deepClonedObjectWithoutVueReactivity = (objectToClone: DynamicDictionary): DynamicDictionary => {
        return JSON.parse(JSON.stringify(objectToClone));
    }

    const flatteredObject = (param: DynamicDictionary): string => {
        let result: string = '';
        for (const o in param) {
            if (typeof param[o] === 'object') {
                result += flatteredObject(param[o]);
            } else {
                result += param[o];
            }
        }

        return result;
    }

    const transformedVueSafeString = (vueSafe: string): string => {
        return vueSafe.replace(/\|o\|/g, '{{')
            .replace(/\|c\|/g, '}}')
            .replace(/\|s\|/g, '\\"')
            .replace(/br \\>/g, 'br>');
    }

    return {
        deepClonedObjectWithoutVueReactivity,
        flatteredObject,
        transformedVueSafeString,
    }
}

export interface TransformParams {
    deepClonedObjectWithoutVueReactivity: (objectToClone: DynamicDictionary) => DynamicDictionary;
    flatteredObject: (param: DynamicDictionary) => string;
    transformedVueSafeString: (vueSafe: string) => string;
}
