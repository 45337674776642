<script setup lang="ts">
    import {computed, onMounted, PropType, Ref, ref, watch} from 'vue';
    import {useTranslate} from "@/Composables/Translate";
    import {AxiosResponse} from 'axios';
    import FormField from '@/assets/libraries/form/form-field';
    import SelectorItem from '@/Components/PopupSelector/SelectorItem';
    import RequestService from '@/services/request.service';
    import Url from '@/Enums/UrlEnum';
    import Error from '@/services/error.service';
    import ErrorType from '@/Enums/ErrorTypeEnum';

    const props = defineProps({
        componentName: {type: String, default: 'HumanBodyParts'},
        dataStoreDisabled: {type: Boolean, default: false},
        formField: {
            type: Object as PropType<FormField<SelectorItem[]>>,
            default: () => new FormField('', [])
        },
        translationType: {type: String, default: 'components'},
        payoutTable: {type: String, default: '*'},
        riskIc: {type: String, default: '*'},
        insuranceTerms: {type: String, default: '*'},
    });
    const emit = defineEmits(['human-body-parts-ready']);
    const {translateForType} = useTranslate();
    const componentIsReady: Ref<boolean> = ref(false);
    const selectorItems: Ref<SelectorItem[]> = ref([]);
    const isSelectorOpened: Ref<boolean> = ref(false);
    const selectedPart: Ref<string> = ref('');
    const injuredParts: Ref<SelectorItem[]> = computed((): SelectorItem[] => {
        return selectorItems.value.filter((item: SelectorItem) => item.selected);
    });
    const head: Ref<boolean> = computed((): boolean => {
        return selectorItems.value.some((item: SelectorItem) => item.selected && item.type === 'head');
    });
    const body: Ref<boolean> = computed((): boolean => {
        return selectorItems.value.some((item: SelectorItem) => item.selected && item.type === 'body');
    });
    const arms: Ref<boolean> = computed((): boolean => {
        return selectorItems.value.some((item: SelectorItem) => item.selected && item.type === 'arms');
    });
    const legs: Ref<boolean> = computed((): boolean => {
        return selectorItems.value.some((item: SelectorItem) => item.selected && item.type === 'legs');
    });

    watch(() => injuredParts.value, () => {
        if (componentIsReady.value) {
            props.formField.patch(injuredParts.value);
        }
    });

    onMounted((): void => {
        props.formField.onRestore.subscribe(() => {
            restoreInjuredParts(props.formField.value);
        });
        fetchInjuries().then(() => {
            componentIsReady.value = true;
            emit('human-body-parts-ready', componentIsReady.value);
            restoreInjuredParts(props.formField.value);
        });
    });

    function restoreInjuredParts(items: SelectorItem[]): void {
        if (items.length > 0 && selectorItems.value.length > 0) {
            items.forEach((restoreItem: SelectorItem) => {
                selectorItems.value.find((item: SelectorItem) =>
                    item.type === restoreItem.type && item.name === restoreItem.name)!
                    .selected = restoreItem.selected;
            });
        }
    }

    async function fetchInjuries(): Promise<void> {
        const params: Record<string, number | string> = {
            payoutTable: props.payoutTable,
            riskIc: props.riskIc,
            insuranceTerms: props.insuranceTerms,
        };
        await RequestService.getInstance().get({uri: Url.Ajax.humanBodyInjury, content: params})
            .then((response: AxiosResponse) => {
                const accidentInjury: Array<SelectorItem> = response.data.data.body.humanBodyInjury;
                accidentInjury.forEach((injury: SelectorItem) => {
                    injury.selected = false;
                    selectorItems.value.push(injury as SelectorItem);
                });
            }).catch((reason) => {
                Error.log(ErrorType.Error, props.componentName, 'error_human_body_invalid_data_fetch', reason);
            });
    }

    function injuryByBodyPart(type: string): SelectorItem[] {
        const items: Array<SelectorItem> = [];
        selectorItems.value.map((item: SelectorItem) => {
            if (item.type === type) {
                const accidentInjury: SelectorItem = new SelectorItem();
                accidentInjury.type = type;
                accidentInjury.name = item.name;
                accidentInjury.selected = item.selected;
                items.push(accidentInjury);
            }
        });

        return items;
    }

    function onOpenSelectorPopup(part: string): void {
        selectedPart.value = part;
        isSelectorOpened.value = true;
    }

    function onCloseSelectorPopup(): void {
        isSelectorOpened.value = false;
    }

    function onApplyItems(items: Array<SelectorItem>): void {
        isSelectorOpened.value = false;
        items.forEach((item: SelectorItem) => {
            selectorItems.value.forEach((injury: SelectorItem) => {
                if (item.type === injury.type && item.name === injury.name) {
                    injury.selected = item.selected;
                }
            })
        });
    }

    function onRemoveItem(type: string, name: string): void {
        const item: SelectorItem = selectorItems.value.find((item: SelectorItem) =>
            item.type === type && item.name === name) as SelectorItem;
        item.selected = false;
    }

    function injurySelectorTitle(type: string): string {
        return 'select_' + type + '_injury';
    }

    defineExpose({
        injuredParts,
    });
</script>
<template>
    <div class="human-body-parts"
         ref="humanBodyParts"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div class="container">
            <div class="injuries">
                <div>
                    <div v-for="(item, index) in injuredParts" :key="index">
                        <div class="injury">
                            {{ translateForType(item.name, translationType) }}
                            <div class="button-remove">
                                <svg
                                    @click="onRemoveItem(item.type, item.name)"
                                    width="10" height="10" viewBox="0 0 10 10" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 1L1 9M1 1L9 9" stroke="#9297A0" stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="silhouette-wrapper">
                <div class="human-body">
                    <div class="silhouette">
                        <div class="neck"
                             v-if="!body && !head"></div>
                        <div class="selected-head"
                             v-show="head"></div>
                        <div class="subordinate right"
                             v-if="!body && !arms"></div>
                        <div class="subordinate left"
                             v-if="!body && !arms"></div>
                        <div class="selected-arms"
                             v-show="arms"></div>
                        <div class="selected-body"
                             v-show="body"></div>
                        <div class="hip" v-if="!body && !legs"></div>
                        <div class="selected-legs"
                             v-show="legs"></div>
                        <div v-show="componentIsReady"
                             class="selector head-parts"
                             @click="onOpenSelectorPopup('head')">
                            {{ translateForType('human_body_selector_head', 'components') }}
                            <div class="radio"
                                 :class="{'checked': head}"
                                 @click="onOpenSelectorPopup('head')">
                            </div>
                        </div>
                        <div v-show="componentIsReady"
                             class="selector body-parts"
                             @click="onOpenSelectorPopup('body')">
                            {{ translateForType('human_body_selector_body', 'components') }}
                            <div class="radio"
                                 :class="{'checked': body}"
                                 @click="onOpenSelectorPopup('body')">
                            </div>
                        </div>
                        <div v-show="componentIsReady"
                             class="selector arms-parts left"
                             @click="onOpenSelectorPopup('arms')">
                            <div class="radio"
                                 :class="{'checked': arms}"
                                 @click="onOpenSelectorPopup('arms')">
                            </div>
                            {{ translateForType('human_body_selector_arms', 'components') }}
                        </div>
                        <div v-show="componentIsReady"
                             class="selector legs-parts"
                             @click="onOpenSelectorPopup('legs')">
                            {{ translateForType('human_body_selector_legs', 'components') }}
                            <div class="radio"
                                 :class="{'checked': legs}"
                                 @click="onOpenSelectorPopup('legs')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="popups">
                    <app-popup
                        class="simple-popup"
                        v-if="isSelectorOpened"
                        @close="onCloseSelectorPopup()">
                        <app-popup-selector
                            :translation-type="translationType"
                            :title="injurySelectorTitle(selectedPart)"
                            :items="injuryByBodyPart(selectedPart)"
                            @apply-items="onApplyItems($event)">
                        </app-popup-selector>
                    </app-popup>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.human-body-parts {
    width: 100%;

    .container {
        display: flex;
        flex-flow: row wrap;
    }

    .injuries {
        display: flex;
        max-height: 386px;
        overflow-x: hidden;
        overflow-y: auto;

        @include respond-above('lg') {
            width: 50%;
        }

        .injury {
            display: inline-flex;
            border: 2px solid var(--brand-teal);
            border-radius: 24px;
            justify-content: center;
            align-items: center;
            padding: 10px var(--size-tiny);
            margin-right: var(--size-small);
            margin-bottom: var(--size-pico);
            word-break: break-word;

            .button-remove {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    @mixin position($top, $left,$width, $height) {
        position: absolute;
        top: $top;
        left: $left;
        width: $width;
        height: $height;
    }

    @mixin image($src) {
        $assetPath: 'one/components/human-body-parts/';
        background: image(#{$assetPath} + $src) no-repeat center center;
    }

    .silhouette-wrapper {
        width: 100%;
        text-align: center;
        margin-top: var(--size-small);

        @include respond-above('lg') {
            width: fit-content;
            margin-top: 0;
        }

        .human-body {
            width: 243px;
            margin: 0 auto;

            @include respond-above('lg') {
                margin-left: 110px;
            }

            .silhouette {
                @include image('silhouette.svg');
                @include position(2px, 36px, 151px, 386px);
                position: relative;
            }

            .neck {
                @include image('neck.svg');
                @include position(54.4px, 65.4px, 23px, 2px);
            }

            .subordinate {
                @include image('subordinate.svg');

                &.right {
                    @include position(62.1px, 42.3px, 2px, 58px);
                }

                &.left {
                    @include position(62.1px, 106.4px, 2px, 58px);
                }
            }

            .hip {
                @include image('hip.svg');
                @include position(197.6px, 37.6px, 73px, 2px);
            }

            .selected-head {
                @include image('head.svg');
                @include position(0, 55px, 40.66px, 57px);
                cursor: pointer;
            }

            .selected-body {
                @include image('body.svg');
                @include position(54.7px, 36.8px, 76.6px, 145px);
                cursor: pointer;
            }

            .selected-arms {
                @include image('arms.svg');
                @include position(64.6px, -1.6px, 155px, 164px);
                cursor: pointer;
            }

            .selected-legs {
                @include image('legs.svg');
                @include position(198px, 36.6px, 76.6px, 188px);
                cursor: pointer;
            }

            .selector {
                background-color: rgb(255, 255, 255, 0.6);
                display: inline-flex;
                justify-content: right;
                align-items: center;
                text-align: right;
                width: 94px;
                border-radius: 24px;
                font-size: var(--font-size-nano);
                padding-left: var(--size-pico);
                cursor: pointer;

                &.left {
                    justify-content: left;
                    padding-left: 0;

                }

                .radio {
                    background-color: var(--white);
                    border: 2px solid #9297A085;
                    border-radius: 50%;
                    min-width: 24px;
                    height: 24px;
                    margin: var(--size-pico);

                    &.checked {
                        border: 8px solid var(--brand-red);
                    }
                }

                &.head-parts {
                    position: relative;
                    top: 8px;
                    left: -44px;
                }

                &.arms-parts {
                    position: relative;
                    top: 70px;
                    left: 82px;
                }

                &.body-parts {
                    position: relative;
                    top: 60px;
                    left: -32px;
                }

                &.legs-parts {
                    position: relative;
                    top: 132px;
                    left: -61.4px;
                }
            }
        }

        .popups {
            .single-popup {
                .wrapper {
                    .popup-selector {
                        ::v-deep > .container {
                            .title {
                                max-width: 85%;
                                margin-left: var(--size-small);
                                margin-top: 5px;

                                @include respond-above('sm') {
                                    margin-left: var(--size-big);
                                }
                            }

                            .selectors {
                                gap: 20px;

                                .item {
                                    height: auto;

                                    .checkbox {
                                        .title {
                                            text-indent: 0;
                                            padding-left: 10px;
                                            min-width: 240px;
                                            margin-left: var(--size-nano);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
