import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import SettingsService from '@/services/settings.service';

export default class AppCountry {
    public iso(): '' | 'LV' | 'LT' | 'EE' {
        const instance: SettingsService = SettingsService.getInstance();
        let iso: string = instance.localeIso();
        if (typeof instance === 'undefined' || typeof iso === 'undefined') {
            Error.log(
                ErrorType.Error,
                'AppCountry::iso()',
                '`AppCountry::iso()` was called too early - '
                + 'before external data is ready (`::externalDataIsReady()`). '
                + 'Most probably it happened because `AppCountry::iso()` was called in '
                + '`constructor()` or `created()` method or used in precalculated (get) method.'
            );
            iso = '';
        }

        return iso.toUpperCase() as ('' | 'LV' | 'LT' | 'EE');
    }

    public isEE(): boolean {
        return this.iso() === 'EE';
    }

    public isLT(): boolean {
        return this.iso() === 'LT';
    }

    public isLV(): boolean {
        return this.iso() === 'LV';
    }

    public isResidentForOneCountries(country: string): boolean {
        const countries: string[] = ['EE', 'LT', 'LV'];
        return countries.includes(country);
    }

    public get ready(): boolean {
        return SettingsService.getInstance().ready;
    }
}
