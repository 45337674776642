<script lang="ts">
import Vue, {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {VueApp} from '@/Types/VueAppType';
import FilesByLinkGenerateFormModule from '@/Modules/FilesByLinkGenerateForm/FilesByLinkGenerateFormModule.vue';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const filesByLinkGenerateFormModule: VueApp = new Vue(FilesByLinkGenerateFormModule);

        return {
            ...btaBase,
            ...{
                filesByLinkGenerateFormModule
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.filesByLinkGenerateFormModule.create();
        this.filesByLinkGenerateFormModule.beforeModuleMounted();
        this.filesByLinkGenerateFormModule.moduleMounted();
    },
});
</script>