import ErrorType from '@/Enums/ErrorTypeEnum';
import ErrorColor from '@/Enums/ErrorColorEnum';
import ErrorReason from '@/Enums/ErrorReasonEnum';
import DataLayer from '@/services/data.layer.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import OneBaseService from '@/services/OneBaseService';
import {useDefine} from '@/Composables/Define';
import {useTranslate} from '@/Composables/Translate';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useNavigate } from '@/Composables/Navigate';

const {isSet} = useDefine();
const {translateForType, hasLocalization} = useTranslate();

export default class Error {
    public readonly debug: boolean;
    public static readonly ReasonAxiosCancel: string = 'Cancel';
    private redirectUrl: string = '';
    private static instance: Error;

    private constructor(debug: boolean = false) {
        this.debug = debug;
    }

    public static getInstance(): Error {
        if (!Error.instance) {
            Error.instance = new Error(true);
        }

        return Error.instance;
    }

    public show(level: string, caller: string, reason: DynamicDictionary | string): void {
        const errorTitle: string = translateForType('btar_' + String(level).toLowerCase(), '');
        let reasonAborted: boolean = false;
        OneBaseService.getInstance().popup.applyErrorTitle(errorTitle);
        OneBaseService.getInstance().popup.applyErrorDetails(translateForType('btar_error_common', ''));
        if (typeof reason === 'string') {
            switch (true) {
                case hasLocalization(reason, ''):
                    OneBaseService.getInstance().popup.applyErrorDetails(translateForType(reason, ''));
                    break;
                case hasLocalization(reason, 'errors'):
                    OneBaseService.getInstance().popup.applyErrorDetails(translateForType(reason, 'errors'));
                    break;
                default:
                    OneBaseService.getInstance().popup.applyErrorDetails(reason);
            }
        } else if (this.isValidError(reason)) {
            this.redirectUrl = reason.data.errors.redirectUrl || '';
            let errorCode: string = reason.data.errors.code;
            if (isSet(reason.data.errors.details)) {
                errorCode = reason.data.errors.details;
            }
            if (hasLocalization(errorCode, '')) {
                OneBaseService.getInstance().popup.applyErrorDetails(translateForType(errorCode, ''));
            } else if (hasLocalization(errorCode, 'errors')) {
                OneBaseService.getInstance().popup.applyErrorDetails(translateForType(errorCode, 'errors'));
            } else if (isSet(reason.data.errors.title)) {
                OneBaseService.getInstance().popup.applyErrorDetails(reason.data.errors.title);
            } else {
                OneBaseService.getInstance().popup.applyErrorDetails(errorCode);
            }
            reasonAborted = isSet(reason.code) && (reason.code === ErrorReason.ReasonConnectionAborted);
        }
        if (!reasonAborted) {
            PopupService.getInstance().show(new OnePopup().withType().error);
            if (level === ErrorType.Error) {
                this.pushErrorToDataLayer(caller, reason);
            }
        }

        if (this.debug) {
            Error.log(level, caller, reason, true);
        }
    }

    public hide(): void {
        if (this.redirectUrl) {
            useNavigate().navigate(this.redirectUrl);
            this.redirectUrl = '';
        }
    }

    public isValidError(reason: DynamicDictionary): boolean {
        return isSet(reason.data)
            && isSet(reason.data.errors)
            && isSet(reason.data.errors.code);
    }

    public static log(level: string, caller: string, reason: LimitedVariant, skipDataLayer: boolean = false): void {
        let errorLevel: string = level;
        let format: string;
        switch (errorLevel) {
            case ErrorType.Error:
                format = ErrorColor.Error;
                break;
            case ErrorType.Warning:
                format = ErrorColor.Warning;
                break;
            default:
                format = ErrorColor.Info;
        }
        let connectionAborted: boolean = false;
        let connectionCanceled: boolean = false;
        if (reason !== null) {
            const reasonCode: DynamicDictionary = reason as DynamicDictionary;
            if (typeof reasonCode.code !== 'undefined' && ((reasonCode.code === ErrorReason.ReasonConnectionAborted))) {
                connectionAborted = true;
            }
            connectionCanceled = reason.toString() === this.ReasonAxiosCancel;
        }
        if (connectionAborted || connectionCanceled) {
            format = ErrorColor.Info;
            errorLevel = ErrorType.Info;
        }
        console.debug('%c ' + errorLevel + ': %c ' + caller + ': %c ' + reason,
            'background: ' + format + '; color: #ffffff',
            'background:transparent;color:#000;',
            'background:transparent;color:#666;'
        );
        if (typeof reason === 'object' && errorLevel === ErrorType.Error) {
            console.debug(caller + ' object: ', reason);
        }
        if (!skipDataLayer && errorLevel === ErrorType.Error) {
            Error.getInstance().pushErrorToDataLayer(caller, reason);
        }
    }

    private pushErrorToDataLayer(caller: string, reason: any = null): void {
        let errorType: string = caller;
        if (this.contextIsReady) {
            console.debug('pushErrorToDataLayer: application context is not ready yet, strings will not be translated');
        }
        let errorMessage: string = this.localized('btar_error_common');
        if (typeof reason === 'string') {
            errorMessage = this.localized(reason);
        } else if (this.isValidError(reason)) {
            const errorCode: string = reason.data.errors.code;
            if (this.contextIsReady && hasLocalization(errorCode, '')) {
                errorMessage = this.localized(errorCode)
            } else if (typeof reason.data.errors.title !== 'undefined') {
                errorType = caller + ' : ' + errorCode;
                errorMessage = reason.data.errors.title;
            } else if (typeof reason.data.errors.status !== 'undefined') {
                errorType = caller + ' : ' + errorCode;
                errorMessage = reason.data.errors.status;
            } else {
                errorMessage = errorCode;
            }
        }
        DataLayer.getInstance()
            .addRootParam('event', 'error')
            .addRootParam('errorType', errorType)
            .addRootParam('errorMessage', errorMessage)
            .buildAndPush();
    }

    private get contextIsReady(): boolean {
        return OneBaseService.getInstance().isReady.value;
    }

    private localized(stringId: string): string {
        let result: string = stringId;
        if (this.contextIsReady && hasLocalization(stringId, '')) {
            result = translateForType(stringId, '');
        }

        return result
    }
}
