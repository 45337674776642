import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FetchParams from '@/Components/MapWithPlaces/FetchParams';
import MapPlace from '@/interfaces/map.place.interface';
import {useDefine} from '@/Composables/Define';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Map from '@/Enums/MapEnum';
import axios, {AxiosResponse, CancelTokenSource} from "axios";
import Translations from '@/services/translations.service';

const {isSet} = useDefine();

export default class RepairShopService {

    private static instance: RepairShopService;
    private cancelToken: CancelTokenSource | null = null;

    public static getInstance(): RepairShopService {
        if (!RepairShopService.instance) {
            RepairShopService.instance = new RepairShopService();
        }

        return RepairShopService.instance;
    }

    public fetchPlaces(places: MapPlace[], fetchParams: FetchParams, fetchUrl?: string): Promise<void> {
        if (this.cancelToken) {
            this.cancelToken.cancel();
        }
        this.cancelToken = axios.CancelToken.source();
        const url: string = fetchUrl === ''
            ? this.translations.language + Url.Ajax.repairServices
            : this.translations.language + fetchUrl;
        const params: Record<string, DynamicDictionary> = {
            fetchParams: fetchParams,
        };

        return axios.get(url, {cancelToken: this.cancelToken.token, params})
            .then((value: AxiosResponse) => {
                if (this.validResponse(value)) {
                    value.data.data.body.places.forEach((place: MapPlace) => {
                        places.push(place as MapPlace);
                    })
                } else {
                    Error.log(ErrorType.Error, Map.Error.MapFetchDataInvalidStructure, value);
                }
            })
            .catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, this.fetchPlaces.name, reason);
            })
    }

    public validResponse(response: DynamicDictionary): boolean {
        const responseStatus: number = 200;

        return isSet(response.status)
            && response.status === responseStatus
            && isSet(response.data)
            && isSet(response.data.data);
    }

    private get translations(): Translations {
        return Translations.getInstance();
    }
}
