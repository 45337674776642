<script setup lang="ts">
import {ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';

const spaApp = ref(null);
OneBaseService.getInstance().applySpa(spaApp);
</script>

<template>
    <div class="layout-container">
        <div class="stepper-content sticky-top">
            <slot name="stepper"></slot>
        </div>
        <div class="route-content">
            <transition :name="'fade'" mode="out-in">
                <router-view ref="spaApp"></router-view>
            </transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.layout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include respond-above('lp') {
        max-width: 1368px;
    }

    .stepper-content {
        top: 0;
        width: 100%;
        z-index: 4;
    }

    .route-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        width: 1200px;
        @include respond-below('lp') {
            width: 100%;
        }

        .container {
            width: 100%;
            max-width: 960px;

            ::v-deep .form {
                display: flex;
                flex-direction: column;
                padding: 0 !important;

                > .input:not(:last-child) {
                    margin-bottom: 24px;
                }

                .row {
                    display: flex;
                    flex-direction: row;

                    > .input:not(:last-child) {
                        margin-right: 24px;
                    }
                }
            }

            ::v-deep .whiteboard-panel {
                position: relative;
                width: 100%;
                max-width: 960px;
                margin-top: var(--size-small);


                &-margin {
                    margin-top: 100px;
                }

                &:last-child {
                    margin-bottom: 100px;
                }

                > .back {
                    display: flex;
                    align-items: center;
                    font-size: var(--font-size-tiny);
                    font-weight: 600;
                    color: var(--text-color-link);
                    margin-bottom: var(--size-nano);

                    &:hover {
                        color: var(--text-color-link-hover);

                        img {
                            filter: invert(0%) sepia(0%) saturate(200%) hue-rotate(0deg) brightness(35%) contrast(106%);
                        }
                    }

                    span {
                        line-height: 22px;
                        margin-left: 12px;
                    }
                }

                > label {
                    font-weight: 600;
                    font-size: var(--font-size-big);
                    color: var(--text-color-default);
                    line-height: var(--line-height-basic);
                    margin-bottom: var(--size-big);
                    display: block;
                }

                .whiteboard {
                    position: relative;
                    background: var(--white);
                    padding: var(--size-big);
                    border-radius: var(--size-tiny);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    margin-top: var(--size-small);

                    @include respond-below('sm') {
                        padding: 0;
                        background: transparent;
                    }

                    h4 {
                        margin-bottom: 18px;
                        display: flex;
                        align-items: center;
                        font-size: var(--font-size-small);
                        font-weight: 700;
                    }

                    .header-description {
                        font-size: var(--font-size-tiny);
                    }

                    .row,
                    .input-block {
                        width: 100%;
                        display: block;

                        .margin-top {
                            margin-top: 18px;
                        }
                    }

                    section {
                        width: 100%;
                    }

                    > .button {
                        margin-top: var(--size-medium);

                        @include respond-below('sm') {
                            margin-bottom: var(--size-medium);
                        }
                    }
                }
            }
        }
    }
}
</style>
