import PaymentMethod from '@/Components/PaymentWidget/Interfaces/PaymentMethod.interface';

export default class PaymentMethodBuilder {
    private paymentMethod: PaymentMethod = {
        title: '',
        icon: '',
        imageMobile: '',
        imageDesktop: '',
        bankLinkId: 0,
        active: false,
        informationText: '',
    };

    public withTitle(value: string): PaymentMethodBuilder {
        this.paymentMethod.title = value;

        return this;
    }

    public withInformationText(value: string): PaymentMethodBuilder {
        this.paymentMethod.informationText = value;

        return this;
    }

    public withIcon(value: string): PaymentMethodBuilder {
        this.paymentMethod.icon = value;

        return this;
    }

    public withBankLinkId(value: number): PaymentMethodBuilder {
        this.paymentMethod.bankLinkId = value;

        return this;
    }

    public build(): PaymentMethod {
        return this.paymentMethod;
    }
}
