import Vue from 'vue';
import VueRouter from 'vue-router';
import VueApp from '@/Core/Routing/VueApp';
import App from '@/Apps/Renters/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import InsurancePage from '@/Apps/Renters/Pages/RentersInsurancePage.vue';
import CoveragePage from '@/Apps/Renters/Pages/RentersCoveragePage.vue';
import AddressPage from '@/Apps/Renters/Pages/RentersAddressPage.vue';
import SummaryAndPaymentPage from '@/Apps/Renters/Pages/RentersSummaryAndPaymentPage.vue';
import OptionsPage from '@/Apps/Renters/Pages/RentersOptionsPage.vue';
import CoveredPopupRentersMulti from '@/Components/CoveredPopup/CoveredPopupRentersMulti.vue';
import RentersMini from '@/Components/ProductBlock/Components/MiniPlans/RentersMini.vue';
import ProductBlockInnerRentersPolicy from '@/Components/ProductBlock/Components/ProductBlockInnerRentersPolicy.vue';
import CoveredPopupRentersSingle from '@/Components/CoveredPopup/CoveredPopupRentersSingle.vue';


// Routing
Vue.use(VueRouter);

// Pages
Vue.component('RentersInsurancePage', InsurancePage);
Vue.component('RentersCoveragePage', CoveragePage);
Vue.component('RentersOptionsPage', OptionsPage);
Vue.component('RentersAddressPage', AddressPage);
Vue.component('RentersSummaryAndPaymentPage', SummaryAndPaymentPage);

// Components
Vue.component('AppStepperLayout', StepperLayout);
Vue.component('AppRouteStepper', RouteStepper);
Vue.component('AppProductBlockInnerRentersPolicy', ProductBlockInnerRentersPolicy);
Vue.component('AppRentersMini', RentersMini);
Vue.component('AppCoveredPopupRentersMulti', CoveredPopupRentersMulti);
Vue.component('AppCoveredPopupRentersSingle', CoveredPopupRentersSingle);

export default class {
    public app(selector: string, routes: BackendRoute[]) {
        return new VueApp(App).withRoutes(routes).mount(selector);
    }
}
