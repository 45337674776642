export default class CascoClaimsPanelsNames {
    public static readonly WhenEventHappened: string = 'when-event-happened';
    public static readonly WhatTimeDidItHappened: string = 'what-time-did-it-happened';
    public static readonly WhatVehicleWasInvolved: string = 'what-vehicle-was-involved';

    public static readonly SelectDamageType: string = 'select-damage-type';
    public static readonly DamageSubtype: string = 'damage-subtype';
    public static readonly WhereAccidentHappened: string = 'where-accident-happened';
    public static readonly DidYouDamageThirdPartyProperty: string = 'did-you-damage-third-party-property';
    public static readonly HowAccidentIsReported: string = 'how-accident-is-reported';
    public static readonly ReportTheAccident: string = 'report-the-accident';
    public static readonly DescriptionOfAccident: string = 'description-of-accident';
    public static readonly RepairServiceDetails: string = 'repair-service-details';

    public static readonly HowYouWantToReceiveCompensation: string = 'how-you-want-to-receive-compensation';
    public static readonly DoYouWantToRepairVehicleAbroad: string = 'do-you-want-to-repair-vehicle-abroad';
    public static readonly CountryOfAccident: string = 'country-of-accident';
    public static readonly PleaseChooseRepairService: string = 'please-choose-repair-service';
}
