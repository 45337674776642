<script setup lang="ts">

import DrawerService from '@/services/drawer.service';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {computed, ComputedRef, onBeforeMount, onMounted, onUnmounted, reactive} from 'vue';
import {useGenerator} from '@/Composables/Generator';
import uuid from 'uuid';

const {autoFieldId} = useGenerator();

const react: UnwrapNestedRefs<DynamicDictionary> = reactive({
    animatorId: '',
    opened: false,
    drawerService: DrawerService.getInstance(),
});


onBeforeMount((): void => {
    react.animatorId = autoFieldId();
});

onMounted((): void => {
    react.drawerService.onVisibilityChange.subscribe((opened: boolean) => {
        react.opened = opened;
    });
    react.drawerService.init(react.animatorId);
});

onUnmounted(() => {
    react.drawerService.destroy();
});

function onCloseClick(): void {
    react.drawerService.hide();
}

const drawerComponent: ComputedRef<string> = computed(() => {
    return react.drawerService.componentName;
});

const drawerContent: ComputedRef<DynamicDictionary> = computed(() => {
    return react.drawerService.componentContent;
});

const drawerTranslationsType: ComputedRef<string> = computed(() => {
    return react.drawerService.translationsType;
});

const isVisibleComponent: ComputedRef<string> = computed(() => {
    return react.drawerService.isVisibleComponent;
});

const isOpened: ComputedRef<boolean> = computed(() => {
    return react.opened;
});
</script>

<template>
    <div class="drawer-container">
        <div class="drawer-overlay"
             @click="onCloseClick"
             v-bind:class="{'visible': isOpened}"></div>
        <div class="animator"
             v-bind:id="react.animatorId"
             v-bind:class="{'opened': isOpened}">
            <div class="component-wrapper"
                 v-if="drawerComponent !== ''">
                <component class="drawer-component"
                           :key="uuid.v4()"
                           v-bind:is="drawerComponent"
                           v-bind:content="drawerContent"
                           v-bind:translations-type="drawerTranslationsType"
                ></component>
            </div>
            <button class="close"
                    @click="onCloseClick">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L9 9M9 1L1 9" stroke="#9297A0" stroke-opacity="0.68" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.drawer-container {
    position: fixed;
    left: 0;
    top: 100%;
    height: 100%;
    width: 100%;
    z-index: 499;

    @include respond-above('md') {
        top: 0;
        left: auto;
        right: 0;
        width: 0;
    }

    .drawer-overlay {
        @include fullscreen;
        position: fixed;
        width: 0;
        height: 0;
        z-index: 1;
        background: var(--brand-black);
        opacity: 0;
        transition: opacity .3s ease-in;

        &.visible {
            width: 100%;
            height: 100%;
            opacity: .24;
        }
    }

    .animator {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        box-shadow: 0 0 32px rgb(146 151 160 / .32);
        transition: top .3s ease-in, left .3s ease-in;
        background-color: var(--background-light);
        z-index: 1;

        @include respond-above('md') {
            width: 720px;
        }

        .close {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: 1px solid var(--component-color-border-default);
            z-index: inherit;

            @include respond-above('md') {
                top: 40px;
                right: 40px;
            }

            &:hover,
            &:focus {
                box-shadow: 0 16px 32px rgb(146 151 160 / .32);
            }

            .svg {
                width: 40px;
                height: 40px;
            }
        }

        &.opened {
            top: -100%;

            @include respond-above('md') {
                top: auto;
                left: -720px;
            }
        }

        .component-wrapper {
            height: 100%;

            .drawer-component {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                height: 100%;

                ::v-deep .content-scrollable {
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
