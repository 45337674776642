<script lang="ts">
import {defineComponent} from 'vue';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        return {
            ...btaBase,
            ...{}
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setFacility(DataLayerFacilities.Promo);
    },
});
</script>