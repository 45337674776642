import {LimitedVariant} from '@/Types/LimitedVariantType';

export default class TransferStateService {
    private static instance: TransferStateService;
    private store: { [key: string]: LimitedVariant } = {};

    public static getInstance(): TransferStateService {
        if (!TransferStateService.instance) {
            TransferStateService.instance = new TransferStateService();
        }
        return TransferStateService.instance;
    }

    public set(name: string, data: LimitedVariant): void {
        this.store[name] = data;
    }

    public get<T = LimitedVariant>(name: string): T;

    public get<T = LimitedVariant>(name: string, defaultValue: T): T;

    public get<T = LimitedVariant>(name: string, defaultValue?: T): T | undefined {
        return (this.store[name] !== undefined ? this.store[name] : defaultValue) as T;
    }
}
