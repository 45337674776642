import Vue, {computed, reactive, Ref} from 'vue';
import PopupBase from '@/assets/libraries/popups/popup.base';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import VueModel from '@/services/vue.model.service';
import FormField from '@/assets/libraries/form/form-field';
import VueEvent from '@/Classes/VueEventClass';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import OneBaseService from '@/services/OneBaseService';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import PopupType from '@/Enums/PopupTypeEnum';
import {Subject} from 'rxjs';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import LoadingWaitService from '@/services/loading.wait.service';
import Error from '@/services/error.service';
import LazyLoader from '@/services/lazy.loader.service';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';

export default class PopupService {
    public readonly SinglePopup: string = '.single-popup';
    public readonly PopupWrapper: string = '.wrapper';
    private static instance: PopupService;
    private popupBase: UnwrapNestedRefs<PopupBase> = reactive(new PopupBase());

    public static getInstance(): PopupService {
        if (!PopupService.instance) {
            PopupService.instance = new PopupService();
        }
        return PopupService.instance;
    }

    public get current(): PopupBase {
        return <PopupBase>this.popupBase;
    }

    public get isActive(): boolean {
        return this.activePopupType !== undefined;
    }

    public active: Ref<boolean> = computed(() => {
        return this.current.popupType !== undefined;
    });

    public get activePopupType(): string {
        return this.current.popupType;
    }

    public get isErrorPopup(): boolean {
        return this.current.popupType === PopupType.Error;
    }

    public get inputField(): FormField {
        return this.current.inputs.field(this.current.inputName);
    }

    public get cancelButtonParams(): ButtonWithCallbackParams {
        return {
            title: this.current.cancelButtonText,
            textColor: ButtonTextColor.Black,
            backgroundColorHover: ButtonBackground.Grey,
        };
    }

    public get confirmButtonParams(): ButtonWithCallbackParams {
        return {
            title: this.current.confirmButtonText,
            backgroundColor: this.current.confirmButtonColor,
            textColor: ButtonTextColor.White,
            disabled: false,
        };
    }

    public get continueButtonParams(): ButtonWithCallbackParams {
        return {
            title: this.current.continueButtonText,
            backgroundColor: this.current.confirmButtonColor,
            textColor: ButtonTextColor.White,
            disabled: false,
        };
    }

    public show(popup: PopupBase): PopupService {
        this.applyBase(popup);
        Vue.nextTick((): void => {
            OneBaseService.getInstance()!.changeBodyVerticalScrollState();
            this.focusPopup().then((): void => {
                this.onPopupShow();
            });
        });

        return this;
    }

    public hide(): Promise<void> {
        return new Promise(resolve => {
            this.current.closeSubject.next(this.current.popupType);
            Object.assign(this.popupBase, new PopupBase());
            OneBaseService.getInstance()!.changeBodyVerticalScrollState();
            this.onPopupHide();
            resolve()
        });
    }

    public onHide(): Subject<string> {
        return this.current.closeSubject;
    }

    public onPopupShow(): void {
        switch (this.current.popupType) {
            case PopupType.Login:
                history.pushState('login-popup', document.title);
                new LazyLoader().init();
                break;
            case PopupType.LoadingWait:
                LoadingWaitService.getInstance().init();
                break;
            default:
                break;
        }
    }

    public onPopupHide(): void {
        LoadingWaitService.getInstance().stop();
        Error.getInstance().hide();
    }

    public confirmWithCallback(): void {
        let inputValue: LimitedVariant = '';
        if (this.current.inputType !== '') {
            this.requireUserInputs();
            inputValue = this.current.inputs.field(this.current.inputName).value;
            if (this.current.userInputsAreValid) {
                this.sendCallback(inputValue);
                this.popupBase.confirmSubject.next();
                this.hide().then();
            }
        } else {
            this.sendCallback();
            this.popupBase.confirmSubject.next();
            this.hide().then();
        }
    }

    public cancelWithCallback(): void {
        if (this.current.cancelCallbackModel && this.current.cancelCallbackContext &&
            this.current.cancelCallbackModel !== '') {
            VueModel.callMethodByName(
                this.current.cancelCallbackModel as string,
                this.current.cancelCallbackContext
            );
        } else if (this.current.cancelCallbackModel !== '' &&
            typeof this.current.cancelCallbackModel === 'function') {
            (this.current.cancelCallbackModel as Function)();
        }
        this.popupBase.cancelSubject.next();
        this.hide().then();
    }

    public clickWithCallback(event: VueEvent): void {
        if (this.current.clickCallbackModel !== '' && this.current.clickCallbackContext !== null) {
            VueModel.callMethodByName(
                this.current.clickCallbackModel as string,
                this.current.clickCallbackContext,
                event
            );
        } else if (this.current.clickCallbackModel !== '' &&
            typeof this.current.clickCallbackModel === 'function') {
            (this.current.clickCallbackModel as Function)(event);
        }
    }

    public loadingWaitText(translationType: string, suffix: string): string {
        const defaultType: string = 'default';
        const translations: TranslateParams = useTranslate();
        const keyWithTypePrefix: string = translationType + suffix;
        const keyWithDefaultType: string = defaultType + suffix;

        return translations.hasLocalization(keyWithTypePrefix)
        ? translations.translate(keyWithTypePrefix) : translations.translate(keyWithDefaultType);
    }

    private sendCallback(inputValue: LimitedVariant = null): void {
        if (this.current.confirmCallbackModel && this.current.confirmCallbackContext &&
            this.current.confirmCallbackModel !== '') {
            VueModel.callMethodByName(
                this.current.confirmCallbackModel as string,
                this.current.confirmCallbackContext,
                inputValue
            );
        } else if (this.current.confirmCallbackModel !== '' &&
            typeof this.current.confirmCallbackModel === 'function') {
            (this.current.confirmCallbackModel as Function)(inputValue);
        }
    }

    private focusPopup(): Promise<void> {
        return new Promise(resolve => {
            const focusablePopup: string = this.SinglePopup + '.' + this.current.popupType;
            Vue.nextTick((): void => {
                const element: HTMLElement = $(focusablePopup).find(this.PopupWrapper)[0];
                if (element !== undefined && element !== null) {
                    $(element).trigger('focus');
                }
                resolve();
            });
        });
    }

    private requireUserInputs(): void {
        this.current.inputs.fields().forEach((field: FormField): void => {
            field.addValidators('required');
        });
    }

    private applyBase(popup: PopupBase): void {
        Object.assign(this.popupBase, popup);
    }
}
