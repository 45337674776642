<script lang="ts">
import Vue, {
    computed,
    defineComponent,
    reactive,
    ref,
    Ref,
    watch
} from 'vue';
import OptionWithToken from '@/interfaces/option.with.token.interface';
import {AxiosResponse, CancelTokenSource} from 'axios';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import TravelTransformations from '@/Enums/TravelTransformationsEnum';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import TravelSearchTypes from '@/Enums/TravelSearchTypesEnum';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {StepsSubmitterParams, useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {DefineParams, useDefine} from '@/Composables/Define';
import AgeGroups from '@/services/age.groups.service';
import Counters from '@/interfaces/counters.interface';
import DateRange from '@/interfaces/date.range.interface';
import CountriesWithRegion from '@/interfaces/countries.with.region.interface';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import MultiCountersValue from '@/interfaces/multi.counters.value.interface';
import CounterValue from '@/interfaces/counter.value.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import Method from '@/Enums/MethodEnum';
import Territories from '@/interfaces/territories.interface';
import moment from 'moment';
import FormField from '@/assets/libraries/form/form-field';
import InsuranceSanitizers from '@/pages/Travel/Insurance/InsuranceSanitizers';
import OptionValue from '@/interfaces/option.value.interface';
import InsuranceValidators from '@/pages/Travel/Insurance/InsuranceValidators';
import TravelDataLayer from '@/pages/Travel/TravelDataLayer';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {ErrorInterface, useError} from '@/Composables/Error';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import {VueApp} from '@/Types/VueAppType';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import {useTransforms} from '@/Composables/Transforms';
import {SanitizerCallback} from '@/Types/SanitizerCallbackType';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const getInTouchModule: VueApp = new Vue(GetInTouchModule);

        const {isSet}: DefineParams = useDefine();
        const {translate, translateForType}: TranslateParams = useTranslate();
        const {logError}: ErrorInterface = useError();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const request: AxiosParams = useAxios();

        const CurrentStep: number = 1;
        const RegionRussia: string = 'RUSSIA';
        const MomentFormat: string = 'DD MMM YYYY';
        const MomentSubmitFormat: string = 'YYYY-MM-DD';
        const TravelTargetUrl: string = Url.Ajax.travelDestinations;
        const SkipMarker: string = 'skip';

        let callback: Function | null = null;
        let restrictedByGeoIp: boolean = false;
        let ageGroupLabel: string = '';
        let allTripsDurationDefaultValue: string = '';
        let dataLayerAxiosReceiveCount: number = 0;
        const validators: InsuranceValidators = new InsuranceValidators();
        const dataLayer: TravelDataLayer = new TravelDataLayer();
        const datesDefaults: DynamicDictionary = new class implements DynamicDictionary {
            public startOffset: number = 0;
            public endOffset: number = 0;
        };


        const tripDurationSelect: Ref<HTMLDivElement | null> = ref(null);
        const allTripsDurationSelect: Ref<HTMLDivElement | null> = ref(null);
        const countryCodes: Ref<DynamicDictionary> = ref({});
        const defaultTravelAllTripsDurationDropdownOptions = ref([]);
        const travelAllTripsDurationDropdownOptions = ref([]);
        const multiCounterData: Ref<Counters[]> = ref([]);
        const ageGroup: Ref<string> = ref(TravelTransformations.AgeGroupBasic);
        const isRanged: Ref<boolean> = ref(true);
        const evakAvailable: Ref<boolean> = ref(false);
        const isAxiosRequest: Ref<boolean> = ref(false);
        const v2PoliciesEnabled: Ref<boolean> = ref(false);
        const travelActivitiesHidden: Ref<boolean> = ref(false);
        const multipleTripsDisabled: Ref<boolean> = ref(false);
        const regionsOptionsCount: Ref<number> = ref(0);
        const destinationType: Ref<string> = ref('');
        const regionsMessage: Ref<string> = ref('');
        const multiCounterMessage: Ref<string> = ref('');
        const multiCountersTotal: Ref<number> = ref(0);
        const maxCountries: Ref<number> = ref(0);
        let regionDetails = reactive({});
        let insuranceOptions = reactive({
            tripDuration: new class implements OptionWithToken {
                public options: InputOption[] | string | boolean = [];
                public cancelToken: CancelTokenSource | null = null;
                public fetchIsInProgress: boolean = false;
                public oldValue: LimitedVariant = null;
                public default: string = '';
            },
            travelActivities: new class implements OptionWithToken {
                public options: InputOption[] | string | boolean = [];
                public cancelToken: CancelTokenSource | null = null;
                public fetchIsInProgress: boolean = false;
                public oldValue: LimitedVariant = null;
            },
            travelTarget: new class implements OptionWithToken {
                public cancelToken: CancelTokenSource | null = null;
                public fetchIsInProgress: boolean = false;
                public options: InputOption[] | string | boolean = [];
                public oldValue: LimitedVariant = null;
            },
            hasTripType: new class implements OptionWithToken {
                public cancelToken: CancelTokenSource | null = null;
                public fetchIsInProgress: boolean = false;
                public options: InputOption[] | string | boolean = false;
                public oldValue: LimitedVariant = null;
            },
            multipleTripsOnly: new class implements OptionWithToken {
                public cancelToken: CancelTokenSource | null = null;
                public fetchIsInProgress: boolean = false;
                public options: InputOption[] | string | boolean = false;
                public oldValue: LimitedVariant = null;
            },
        });
        let form: UnwrapNestedRefs<Form> = reactive(new Form());


        const multipleTripsIsDisabled: Ref<boolean> = computed(() => {
            return multipleTripsDisabled.value;
        });

        const multipleTripsOnly: Ref<boolean> = computed(() => {
            return (insuranceOptions as DynamicDictionary)[TravelSearchTypes.MultipleTripsOnly].options === true;
        });

        const showTravelActivities: Ref<boolean> = computed(() => {
            return !travelActivitiesHidden.value;
        });

        const hideTravelActivities: Ref<boolean> = computed(() => {
            const hasEmptyTravelActivities: boolean = travelActivitiesOptions.value.length === 0;
            const hasNoTravelActivity: boolean = travelActivitiesOptions.value.length === 1 &&
                travelActivitiesOptions.value[0].value === noTravelActivity.value;

            return hasEmptyTravelActivities || hasNoTravelActivity;
        });

        const showAllTripsDuration: Ref<boolean> = computed(() => {
            return form.field('regions').value === RegionRussia &&
                form.field('multipleTrips').value &&
                btaBase.settings.value('LOCALE_ISO') === 'LV';
        });

        const travelActivitiesOptions: Ref<InputOption[]> = computed(() => {
            return (multiCountersTotal.value > 0 ? insuranceOptions['travelActivities'].options : []) as InputOption[];
        });

        const activitiesAreSelected: Ref<boolean> = computed(() => {
            const travelActivities: string = form.field('travelActivities').value;

            return travelActivities !== '' &&
                String(travelActivities) !== noTravelActivity.value &&
                isSingeTrip.value;
        });

        const postActivitiesDateText: Ref<string> = computed(() => {
            let result: string = '';
            if (form.field('activitiesDates').value !== '') {
                const dates: DateRange = form.field('activitiesDates').value;
                const startDate: moment.Moment = moment(dates.startDate);
                if (dates.startDate !== '' && dates.endDate !== '') {
                    const endDate: moment.Moment = moment(dates.endDate);
                    result = dateDifference(startDate, endDate);
                }
            }

            return result;
        });

        const postDateText: Ref<string> = computed(() => {
            let result: string = '';
            if (form.field('travelDates').value !== '') {
                const dates: DateRange = form.field('travelDates').value;
                const startDate: moment.Moment = moment(dates.startDate);
                if (isRanged.value) {
                    if (dates.startDate !== '' && dates.endDate !== '') {
                        const endDate: moment.Moment = moment(dates.endDate);
                        result = dateDifference(startDate, endDate);
                    }
                } else {
                    const endDate: moment.Moment = btaBase.momentLocalized(
                        startDate.add(1, 'years')
                            .subtract(1, 'days')
                            .toString()
                    );
                    result = ' - ' + endDate.format(MomentFormat);
                }
            }

            return result;
        });

        const multipleTripsIsSelected: Ref<boolean> = computed(() => {
            return !isRanged.value;
        });

        const selectTravelDatesLabel: Ref<string> = computed(() => {
            return isRanged.value ?
                translate('travel_select_dates') : translate('travel_select_date');
        });

        const travelDatesLabel: Ref<string> = computed(() => {
            return isRanged.value ?
                translate('travel_dates') : translate('travel_starting_date');
        });

        const dataNotReadyToSubmit: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (form.field('travelActivities').value === '' ||
                Object.keys(travelActivitiesOptions.value).length === 0
            ) {
                result = true;
            }

            return result;
        });

        const componentIsDisabled: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (isAxiosRequest.value || form.field('regions').value === '') {
                result = true;
            }

            return result;
        });

        const noTravelActivity: Ref<string> = computed(() => {
            let result: string;
            if (btaBase.settings.value('LOCALE_ISO') === 'EE') {
                result = v2PoliciesEnabled.value ?
                    TravelTransformations.NoTravelActivityEEV2 : TravelTransformations.NoTravelActivityEE;
            } else {
                result = v2PoliciesEnabled.value ?
                    TravelTransformations.NoTravelActivityV2 : TravelTransformations.NoTravelActivity;
            }

            return result;
        });

        const isEvakAvailable: Ref<boolean> = computed(() => {
            return evakAvailable.value;
        });

        const checkboxIsVisible: Ref<boolean> = computed(() => {
            return !v2PoliciesEnabled.value ||
                (v2PoliciesEnabled.value && hasTripType.value);
        });

        const isEnabledV2: Ref<boolean> = computed(() => {
            return v2PoliciesEnabled.value;
        });

        const customElementIsVisible: Ref<boolean> = computed(() => {
            return regionsOptionsCount.value > 1;
        });

        const travelTargetCustomUrl: Ref<string> = computed(() => {
            return (new AppCountry()).isEE()
                ? Url.Ajax.travelDestinationsEstonia
                : Url.Ajax.travelDestinations;
        });

        const travelTargetValue: Ref<DynamicDictionary> = computed(() => {
            return {
                target: form.field('travelTarget').value
            };
        });

        const tripTypeExtra: Ref<string> = computed(() => {
            return isSet((insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripType].extra) ?
                (insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripType].extra : '';
        });

        const hasTripType: Ref<boolean> = computed(() => {
            return (insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripType].options;
        });

        const isSingeTrip: Ref<boolean> = computed(() => {
            return !form.field('multipleTrips').value;
        });

        const transformedMultipleTripType: Ref<string> = computed(() => {
            const multipleTripsValue: string = v2PoliciesEnabled.value && new AppCountry().isEE() ? 'MULTIPLE_TRIPS_DURING_1Y'
                : 'MULTIPLE_TRIPS_DURING_YEAR';
            return form.field('multipleTrips').value ? multipleTripsValue : 'SINGLE_TRIP';
        });

        watch(() => ageGroup.value, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                if (!isRanged.value) {
                    fetchOptions(TravelSearchTypes.TripDuration, optionsFetchParams()).then();
                }
                fetchActivities().then();
            }
        }, {immediate: true});

        watch(() => travelActivitiesOptions.value, () => {
            travelActivitiesHidden.value = hideTravelActivities.value;
            if (travelActivitiesOptions.value.length > 0) {
                checkAndUpdateSelectedActivity();
            }
        }, {immediate: true});

        function tripType(): string {
            let result: string = !form.field('multipleTrips').value
                ? TravelTransformations.SingleTrip
                : TravelTransformations.MultipleTrip;
            if (btaBase.settings.value('LOCALE_ISO') === 'EE') {
                result = result === TravelTransformations.SingleTrip
                    ? TravelTransformations.SingleTripEE
                    : TravelTransformations.MultipleTripEE;
            }

            return result;
        }

        function tripTypeV2(): string {
            let result: string = 'RESIDENCE_PERMIT';
            if (checkboxIsVisible.value) {
                result = form.field('multipleTrips').value ? 'MULTIPLE_TRIPS_DURING_YEAR' : 'SINGLE_TRIP';
            }

            return result;
        }

        function transformedTripType(): string {
            let result: string;
            if (isIso('LT') && tripTypeExtra.value !== '') {
                result = tripTypeExtra.value;
            } else {
                result = v2PoliciesEnabled.value ? tripTypeV2() : tripType();
            }

            return result;
        }

        function applyMultiCounter(counters: string, v2PoliciesEnabled: boolean): void {
            multiCounterData.value = AgeGroups
                .getInstance()
                .withGroups(JSON.parse(useTransforms().transformedVueSafeString(counters)))
                .withOptionalSubtitle('travelers_count_optional_subtitle')
                .withType(v2PoliciesEnabled)
                .build();
        }

        function applyMaxCountries(newMaxCountries: string): void {
            maxCountries.value = parseInt(newMaxCountries, 10);
        }

        function applyGeoIpRestriction(isRestricted: boolean): void {
            restrictedByGeoIp = isRestricted;
        }

        function applyAllTripsDurationDefault(defaultValue: string): void {
            allTripsDurationDefaultValue = JSON.parse(defaultValue);
        }

        function closeRegionsMessage(): void {
            regionsMessage.value = '';
        }

        function closeCounterMessage(): void {
            multiCounterMessage.value = '';
        }

        function onTravelTargetChange(): void {
            Vue.nextTick((): void => {
                const date: DateRange = defaultDateRange();
                form.field('travelDates').patch(date, false);
                form.field('multipleTrips').value = false;
                form.field('tripDuration').value = insuranceOptions['tripDuration'].default;
            });
        }

        function onRegionChanged(): void {
            if (couldBeFetched()) {
                Vue.nextTick((): void => {
                    if (v2PoliciesEnabled.value) {
                        fetchTripType().then((): void => {
                            applyMultipleTripsOnlyCheckboxStatus();
                            fetchTravelActivities();
                        });
                    } else {
                        fetchTravelActivities();
                    }
                });
            }
        }

        function enablePoliciesV2(): void {
            v2PoliciesEnabled.value = true;
        }

        function onTravelDatesChange(dates: DateRange): void {
            if (isSet(dates.startDate)) {
                form.field('activitiesDates').patch(dates, false);
            }
        }

        function onCountriesSelection(value: CountriesWithRegion): void {
            const interlineSeparator: string = '<br><br>';
            let message: string = '';
            if (value.region !== '') {
                const translatedRegion: string = translate('country_regions_region_' + value.region);
                message = translateForType('country_regions_message', '', {'%REGION%': translatedRegion});
            }
            if (value.countries.length > maxCountries.value) {
                message += (message !== '' ? interlineSeparator : '')
                    + translate('more_than_allowed_countries');
            }
            regionsMessage.value = message;
        }

        function onMultiCounterChange(currentValue: MultiCountersValue): void {
            if (currentValue.counters.length > 0) {
                multiCountersTotal.value = currentValue.counters[0].total;
                multiCounterMessage.value = '';
                if (hasMultipleIcGroups(currentValue)) {
                    multiCounterMessage.value = translate('cant_be_insured_together');
                }
                ageGroup.value = v2PoliciesEnabled.value ?
                    AgeGroups.getInstance().ageGroupV2(currentValue) : updateAgeGroup(currentValue);
                applyAgeGroupLabel(currentValue);
                fetchActivities().then();
            }
        }

        function hasMultipleIcGroups(counters: MultiCountersValue): boolean {
            const ageIcs: DynamicDictionary = {};
            counters.counters[0].counters.forEach((value: CounterValue, index: number): void => {
                const testIc: string = isIso('LT') ?
                    (multiCounterData.value[0] as Counters).counters[index].icd : (multiCounterData.value[0] as Counters).counters[index].ic;
                const ic: string = transformedAgeGroupIc(testIc);
                if (value.value > 0) {
                    ageIcs[ic] = true
                }
            });

            return useDefine().assocArrayLength(ageIcs) > 1;
        }

        function onTravelActivitiesChange(value: DynamicDictionary): void {
            const evakBlockIndex: number = 1;
            const isEvakAvailable: boolean = evakIsAvailable(value);
            (multiCounterData.value[evakBlockIndex] as Counters).enabled = false;
            (multiCounterData.value[evakBlockIndex] as Counters).available = isEvakAvailable;
            evakAvailable.value = isEvakAvailable;
        }

        function onSubmitStep(): void {
            requireTravelCountries().then((): void => {
                form.validate().then((): void => {
                    if (form.isValid()) {
                        btaBase.captcha.executeCaptcha(submit).then().catch((reason: string): void => {
                            logError(ErrorType.Error, 'onSubmitStep', reason);
                        });
                    }
                });
            });
        }

        function requireTravelCountries(): Promise<void> {
            return new Promise(resolve => {
                removeTravelCountriesValidation();
                if (new AppCountry().isLT() && destinationType.value === 'countries') {
                    form.field('countries').addValidators('required');
                }
                resolve();
            });
        }

        function removeTravelCountriesValidation(): void {
            form.field('countries').clearValidators();
        }

        function submit(token: string): void {
            prepareSubmit(token);
            dataLayer.pushDataLayer('');
            if (btaBase.settings.metaApiEnabled()) {
                sendViewContentEvent();
            }
            stepsSubmitter.submitMethod(Method.Get);
            stepsSubmitter.submitStep(Url.Ajax.travelInsurance);
        }

        function onRegionOptionsChange(options: InputOption[]): void {
            regionsOptionsCount.value = options.length;
        }

        function onDestinationTypeUpdated(type: string): void {
            removeTravelCountriesValidation();
            destinationType.value = type;
        }

        function onRegionDetailsUpdated(details: Territories): void {
            removeTravelCountriesValidation();
            regionDetails = details;
        }

        function onMultipleTripsChange(value: boolean, oldValue: boolean): void {
            isRanged.value = !value;
            if (!isRanged.value && value !== oldValue) {
                fetchOptions(TravelSearchTypes.TripDuration, optionsFetchParams()).then(() => {
                    fetchOptions(TravelSearchTypes.TravelActivities, optionsFetchParams()).then();
                });
            } else {
                fetchOptions(TravelSearchTypes.TravelActivities, optionsFetchParams()).then();
            }
        }

        function onTripDurationChange(): void {
            if (form.field('tripDuration').value !== '') {
                travelAllTripsDurationDropdownOptions.value = defaultTravelAllTripsDurationDropdownOptions.value
                    .filter((item: InputOption): boolean => {
                        return Number(item.value) >= Number(form.field('tripDuration').value);
                    });
                if (travelAllTripsDurationDropdownOptions.value.length > 0) {
                    form.field('allTripsDuration').value =
                        (travelAllTripsDurationDropdownOptions.value as InputOption[])[0].value;
                }
            }
        }

        function applyMultipleTrips(multiple: string): void {
            isRanged.value = multiple === 'false';
        }

        function applyTripDuration(defaultDuration: string): void {
            applyDefaultTripDuration(defaultDuration);
        }

        function applyCountryCodes(value: string): void {
            countryCodes.value = JSON.parse(value);
        }

        function applyAllTripsDurationOptions(options: string): void {
            defaultTravelAllTripsDurationDropdownOptions.value = JSON.parse(options)
                .map((item: { name: string; value: string }) => {
                    return new InputOptionBuilder()
                        .setName(item.name)
                        .setValue(item.value)
                        .build();
                });
            travelAllTripsDurationDropdownOptions.value = defaultTravelAllTripsDurationDropdownOptions.value;
        }

        function applyDatesDefaults(dates: string): void {
            const parsed: DynamicDictionary = JSON.parse(useTransforms().transformedVueSafeString(dates));
            datesDefaults.startOffset = parsed.startOffset;
            datesDefaults.endOffset = parsed.endOffset;
        }

        function isIso(iso: string): boolean {
            return btaBase.settings.value('LOCALE_ISO') === iso;
        }

        function setupForm(): Promise<void> {
            return new Promise(resolve => {
                if (v2PoliciesEnabled.value) {
                    form.addField(new FormField('travelTarget', '', validators.targetValidators()));
                }
                form.addField(new FormField('countries'));
                form.addField(new FormField('regions', '', validators.regionsValidators()));
                form.addField(new FormField('travelDates', '', validators.travelDatesValidators()));
                form.addField(new FormField('tripDuration', '', validators.tripDurationValidators()));
                form.addField(new FormField('multipleTrips', false));
                form.addField(new FormField(
                    'travellers',
                    '',
                    validators.travellersValidators(),
                    InsuranceSanitizers.travellersSanitizer as unknown as SanitizerCallback
                ));
                form.addField(new FormField('travelActivities', '', validators.travelActivitiesValidators()));
                form.addField(new FormField('activitiesDates', '', validators.activitiesDatesValidators()));
                form.addField(new FormField('allTripsDuration', allTripsDurationDefaultValue));
                if (isIso('LV')) {
                    form.field('allTripsDuration').addValidators(validators.allTripsDurationValidators());
                }
                form.addField(new FormField('travelStartsInEstonia', true));
                form.setReady();
                resolve();
            });
        }

        function closeAllTripsDurationSelect(): void {
            if (allTripsDurationSelect.value) {
                (allTripsDurationSelect.value as DynamicDictionary).close();
            }
        }

        function closeTripDurationSelect(): void {
            if (tripDurationSelect.value) {
                (tripDurationSelect.value as DynamicDictionary).close();
            }
        }

        function defaultDateRange(): DateRange {
            const startOffset: number = parseInt(datesDefaults.startOffset, 10);
            const endOffset: number = parseInt(datesDefaults.endOffset, 10);
            const dateFrom: moment.Moment = moment().add(startOffset, 'days');
            const dateTo: moment.Moment = moment().add(startOffset + endOffset, 'days');

            return new class implements DateRange {
                startDate: string = dateFrom.format();
                endDate: string = dateTo.format();
            };
        }

        function sendViewContentEvent(): void {
            request.post(Url.Ajax.viewContentEvent, {
                productCategory: 'TravelInsurance',
            }).then();
        }

        function onAfterFormRestored(): void {
            Vue.nextTick(() => {
                if (!form.field('multipleTrips').isRestored) {
                    form.field('multipleTrips').patch(!isRanged.value, false);
                }
                const date: DateRange = defaultDateRange()
                if (form.field('travelDates').value === '') {
                    form.field('travelDates').patch(date, false);
                }
                if (form.field('activitiesDates').value === '') {
                    form.field('activitiesDates').patch(date, false);
                }
            });
        }

        function onOptionsReceiveCallback(): void {
            dataLayerAxiosReceiveCount++;
            const maxAxiosReceiveCountForMarkingFormAsUntouched: number = 2;
            if (dataLayerAxiosReceiveCount >= maxAxiosReceiveCountForMarkingFormAsUntouched) {
                insuranceOptionsAddCallback(null);
                Vue.nextTick(() => {
                    form.markAsUntouched().then();
                });
            }
        }

        function fetchActivities(): Promise<void> {
            let result: Promise<void> = Promise.resolve();
            if (!form.field('regions').isEmpty() &&
                (!v2PoliciesEnabled.value || !form.field('travelTarget').isEmpty())
            ) {
                result = fetchOptions(TravelSearchTypes.TravelActivities, optionsFetchParams());
            }

            return result;
        }

        function assembledCountries(): string {
            return JSON.stringify(form.field('countries').value
                .map((value: OptionValue): string => value.custom));
        }

        function territoryOfOperation(): string {
            return destinationType.value === 'region'
                ? (regionDetails as Territories).territoryIc as string
                : assembledCountries();
        }

        function multipleTrips(): string | number {
            let result: string | number;
            if (v2PoliciesEnabled.value) {
                if (isIso('LT') && tripTypeExtra.value !== '') {
                    result = tripTypeExtra.value;
                } else {
                    result = (checkboxIsVisible.value ?
                        transformedMultipleTripType.value : 'RESIDENCE_PERMIT') as string | number;
                }
            } else {
                result = isSingeTrip.value ? 0 : 1;
            }

            return result;
        }

        function optionsFetchParams(): string {
            let target: string = '';
            if (v2PoliciesEnabled.value) {
                target = '&target=' + form.field('travelTarget').value;
            }
            const region: string = isIso('LT') ?
                (regionDetails as Territories).regionIc as string : form.field('regions').value;
            const tripType: string = transformedTripType();

            return region ?
                '?region=' + region + '&ageGroup=' + encodeURIComponent(ageGroup.value) + '&tripType=' + tripType + target :
                SkipMarker;
        }

        function updateAgeGroup(currentValue: MultiCountersValue): string {
            let result: string = TravelTransformations.AgeGroupBasic;
            currentValue.counters[0].counters.forEach((value: CounterValue): void => {
                if (value.value > 0 &&
                    AgeGroups.getInstance().ageGroupIs(value.name, TravelTransformations.AgeGroupSeniors)) {
                    result = TravelTransformations.AgeGroupSeniors;
                }
            });

            return transformedAgeGroup(result);
        }

        function endDateForMultipleTrips(travelStartDate: string): string {
            const startDate: moment.Moment = moment(travelStartDate);
            return startDate.add(1, 'years').subtract(1, 'days').format(MomentSubmitFormat);
        }

        function activityName(): string {
            let result: string = '';
            const travelActivity: string = form.field('travelActivities').value;
            travelActivitiesOptions.value.forEach((value: InputOption): void => {
                if (String(value.value) === String(travelActivity)) {
                    result = value.name;
                }
            });

            return result;
        }

        function dateRangeForSubmit(fieldName: string): DateRange {
            const result: DateRange = new class implements DateRange {
                endDate: string = '';
                startDate: string = '';
            };
            if (form.field(fieldName).value !== '') {
                const dates: DateRange = form.field(fieldName).value;
                result.startDate = moment(dates.startDate).format(MomentSubmitFormat);
                if (dates.endDate !== '') {
                    result.endDate = moment(dates.endDate).format(MomentSubmitFormat);
                }
            }

            return result;
        }

        function buildTravellers(skipZeroCount: boolean = false): string {
            const result: DynamicDictionary[] = [];
            const multiCounters: MultiCountersValue = form.field('travellers').value;
            multiCounters.counters[0].counters.forEach((value: CounterValue, index: number): void => {
                if (!skipZeroCount || (skipZeroCount && value.value > 0)) {
                    result.push({
                        ic: (multiCounterData.value as Counters[])[0].counters[index].ic,
                        minAge: (multiCounterData.value as Counters[])[0].counters[index].minAge,
                        maxAge: (multiCounterData.value as Counters[])[0].counters[index].maxAge,
                        maxCount: (multiCounterData.value as Counters[])[0].counters[index].maxCount,
                        count: value.value,
                        ehicCount: multiCounters.counters[1].counters[index].value

                    });
                }
            });

            return JSON.stringify(result);
        }

        function dateDifference(startDate: moment.Moment, endDate: moment.Moment): string {
            const locale: string = MomentBuilder.mappedLanguage();
            endDate.locale(locale);
            startDate.locale(locale);
            const differenceText: string = endDate.endOf('day').from(startDate.startOf('day'), true);
            const splitStart: number = 2;
            const splitEnd: number = 5;
            const differenceTextEn: string = differenceText.slice(splitStart, splitEnd);

            return endDate.diff(startDate, 'days') === 0
                ? locale === 'en' ? ' &nbsp; 1 ' + differenceTextEn : ' &nbsp; 1 ' + differenceText
                : ' &nbsp; ' + differenceText;
        }

        function transformedAgeGroup(type: string): string {
            let result: string = type;
            if (btaBase.settings.value('LOCALE_ISO') === 'EE') {
                result = result === TravelTransformations.AgeGroupBasic
                    ? TravelTransformations.AgeGroupBasicEE
                    : TravelTransformations.AgeGroupSeniorsEE;
            }

            return result;
        }

        function transformedAgeGroupIc(ageGroup: string): string {
            let result: string;
            switch (ageGroup) {
                case 'UP_TO_64':
                case 'FROM_65_TO_75':
                    result = TravelTransformations.AgeGroupBasic;
                    break;
                case '75+':
                    result = TravelTransformations.AgeGroupSeniors;
                    break;
                default:
                    result = ageGroup;
            }

            return result;
        }

        function applyAgeGroupLabel(currentValue: MultiCountersValue): void {
            const counters: CounterValue [] = currentValue.counters[0].counters;
            let selectedCountersCount: number = 0;
            counters.forEach((counter: CounterValue): void => {
                if (counter.value > 0) {
                    selectedCountersCount++;
                }
            });
            let label: string = '';
            if (selectedCountersCount === 1) {
                counters.forEach((counter: CounterValue, index: number): void => {
                    if (counter.value > 0) {
                        label = (multiCounterData.value as Counters[])[0].counters[index].label;
                    }
                });
            }
            ageGroupLabel = translate(label);
        }

        function targetAndRegionIsSet(): boolean {
            const travelTargetValue: boolean = v2PoliciesEnabled.value ?
                !form.field('travelTarget').isEmpty() : true;
            const regionsValue: boolean = !form.field('regions').isEmpty();

            return travelTargetValue && regionsValue;
        }

        function fetchTravelActivities(): void {
            Vue.nextTick((): void => {
                if (targetAndRegionIsSet()) {
                    fetchActivities().then((): void => {
                        onTravelActivitiesChange(form.field('travelActivities').value);
                    });
                }
            });
        }

        function fetchTripType(): Promise<void> {
            return fetchOptions(TravelSearchTypes.TripType, optionsFetchParams());
        }

        function couldBeFetched(): boolean {
            const travelTargetIsSet: boolean = !form.field('travelTarget').isEmpty();
            const regionIsSet: boolean = !form.field('regions').isEmpty();

            return v2PoliciesEnabled.value ? regionIsSet && travelTargetIsSet : regionIsSet;
        }

        function resetMultipleTripsDisabled(): void {
            multipleTripsDisabled.value = false;
        }

        function checkAndUpdateSelectedActivity(): void {
            const currentActivity: string = form.field('travelActivities').value;
            if (currentActivity !== '') {
                const currentActivityHasOption: boolean = travelActivitiesOptions.value
                    .filter((option: InputOption) => option.value === currentActivity)
                    .length > 0;
                if (!currentActivityHasOption) {
                    const defaultActivity: InputOption[] = travelActivitiesOptions.value
                        .filter((option: InputOption) => option.default === 'Y');
                    if (defaultActivity.length > 0) {
                        form.field('travelActivities').patch(defaultActivity[0].value);
                    }
                }
            }
        }

        function applyMultipleTripsOnlyCheckboxStatus(): void {
            resetMultipleTripsDisabled();
            if (multipleTripsOnly.value) {
                multipleTripsDisabled.value = true;
                form.field('multipleTrips').patch(true);
            }
        }

        function insuranceOptionsFetch(): void {
            if (isEnabledV2.value) {
                fetchOptions(TravelSearchTypes.TravelTarget).then();
            }
        }

        function insuranceOptionsAddCallback(newCallback: Function | null): void {
            callback = newCallback;
        }

        function applyDefaultTripDuration(defaultDuration: string): void {
            (insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripDuration].default = defaultDuration;
        }

        function evakIsAvailable(value: LimitedVariant): boolean {
            let result: boolean = false;
            (insuranceOptions as DynamicDictionary)[TravelSearchTypes.TravelActivities]
                .options.forEach((option: InputOption): void => {
                if (String(option.value) === String(value)) {
                    result = option.custom === 'Y';
                }
            });

            return result;
        }

        async function fetchOptions(type: string, params: string = ''): Promise<void> {
            if (params === SkipMarker) {
                return Promise.reject();
            }
            if (form.field(type).value !== '') {
                (insuranceOptions as DynamicDictionary)[type].oldValue = form.field(type).value;
            }
            const hash: string = type + params;
            const cache: DynamicDictionary = stepsSubmitter.axiosCache.fetchCache(hash) as DynamicDictionary;
            if (cache) {
                received(type, cache);
                await Promise.resolve();
            } else {
                (insuranceOptions as DynamicDictionary)[type].options = [];
                if ((insuranceOptions as DynamicDictionary)[type].fetchIsInProgress) {
                    (insuranceOptions as DynamicDictionary)[type].cancelToken.cancel();
                }
                (insuranceOptions as DynamicDictionary)[type].fetchIsInProgress = true;
                (insuranceOptions as DynamicDictionary)[type].cancelToken = request.CancelToken.source();
                const urlType: string = type +
                    ((isIso('LT') && type === TravelSearchTypes.TravelTarget) ? 'Unmapped' : '');
                const url: string = (Url.Ajax as DynamicDictionary)[urlType] + params;
                await request
                    .get(url, {cancelToken: (insuranceOptions as DynamicDictionary)[type].cancelToken.token})
                    .then((value: AxiosResponse): void => {
                        received(type, value.data.data.body);
                        stepsSubmitter.axiosCache.storeCache(hash, value.data.data.body);
                        (insuranceOptions as DynamicDictionary)[type].fetchIsInProgress = false;
                    })
                    .catch((reason: DynamicDictionary) => {
                        if (!request.isCancel(reason)) {
                            logError(ErrorType.Error, 'fetch(' + type + ')', reason);
                        }
                        form.field(type).clear().then();
                        (insuranceOptions as DynamicDictionary)[type].fetchIsInProgress = false;
                    })
                    .finally(() => {
                        form.validate().then();
                    });
            }
        }

        function received(type: string, body: DynamicDictionary): void {
            (insuranceOptions as DynamicDictionary)[type].options = [];
            const checkboxFormFieldName: string = 'multipleTrips';
            switch (type) {
                case  TravelSearchTypes.TripDuration:
                    body.duration.map((item: DynamicDictionary) => {
                        (insuranceOptions as DynamicDictionary)[type].options.push({
                            name: item.name,
                            value: item.travel_days_ic
                        });
                    });
                    if ((insuranceOptions as DynamicDictionary)[type].options.length > 0 && form.field(type).value === '') {
                        let defaultValue: LimitedVariant = (insuranceOptions as DynamicDictionary)[type].options[0].value;
                        if ((insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripDuration].default !== '') {
                            defaultValue = (insuranceOptions as DynamicDictionary)[TravelSearchTypes.TripDuration].default;
                        }
                        form.field(type).patch(defaultValue);
                    }
                    break;
                case TravelSearchTypes.TravelActivities:
                    body.activities.forEach((value: DynamicDictionary) => {
                        (insuranceOptions as DynamicDictionary)[type].options.push({
                            name: value.name,
                            value: value.activityIc,
                            custom: value.isEvakAvailable,
                            default: value.isDefaultActivity,
                            tooltip: value.tooltip
                        });
                        if (value.isDefaultActivity === 'Y' && form.field(type).value === '') {
                            form.field(type).patch(value.activityIc);
                        }
                    });
                    break;
                case TravelSearchTypes.TravelTarget:
                    body.targets.map((item: DynamicDictionary) => {
                        (insuranceOptions as DynamicDictionary)[type].options.push({
                            name: item.name,
                            value: item.targetIc,
                            default: item.isDefault
                        });
                        if (item.isDefault === 'Y' && form.field(type).value === '') {
                            form.field(type).patch(item.targetIc);
                        }
                    });
                    if ((insuranceOptions as DynamicDictionary)[type].options.length > 0 && form.field(type).value === '') {
                        const defaultValue: LimitedVariant = (insuranceOptions as DynamicDictionary)[type].options[0].value;
                        form.field(type).patch(defaultValue);
                    }
                    break;
                case TravelSearchTypes.TripType:
                    (insuranceOptions as DynamicDictionary)[type].options = body.hasTripTypes;
                    applyMultipleTripsOnly(body);
                    if (!body.hasTripTypes) {
                        form.field(checkboxFormFieldName).patch(false);
                    }
                    (insuranceOptions as DynamicDictionary)[type].extra = isSet(body.tripTypeIc) ? body.tripTypeIc : '';
                    break;
                default:
            }
            if (((insuranceOptions as DynamicDictionary)[type].oldValue &&
                hasOptionValue(type, String((insuranceOptions as DynamicDictionary)[type].oldValue)))
            ) {
                Vue.nextTick(() => {
                    form.field(type).patch((insuranceOptions as DynamicDictionary)[type].oldValue);
                });
            }
            if (callback) {
                callback(type);
            }
        }

        function applyMultipleTripsOnly(responseBody: DynamicDictionary): void {
            (insuranceOptions as DynamicDictionary)[TravelSearchTypes.MultipleTripsOnly].options = responseBody.multipleTripsOnly;
        }

        function hasOptionValue(type: string, fieldValue: string): boolean {
            let result: boolean = false;
            (insuranceOptions as DynamicDictionary)[type].options.forEach((value: DynamicDictionary) => {
                if (String(value.value) === fieldValue) {
                    result = true;
                }
            });

            return result;
        }

        function prepareValidators(): void {
            validators.init(
                form,
                isRanged,
                multipleTripsIsSelected,
                activitiesAreSelected,
                showAllTripsDuration,
                hasMultipleIcGroups
            );
        }

        function prepareSubmit(token: string): void {
            const travelDates: DateRange = dateRangeForSubmit('travelDates');
            stepsSubmitter.clearParams();
            stepsSubmitter.clearCustomParams();
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
            stepsSubmitter.addSubmitParam('travelStartDate', travelDates.startDate, true);
            stepsSubmitter.addSubmitParam('multipleTrips', multipleTrips(), true);
            stepsSubmitter.addSubmitParam('isSingleTrip', isSingeTrip.value, true);
            stepsSubmitter.addSubmitParam('travelers', buildTravellers(), true);
            stepsSubmitter.addSubmitCustomParam('activityName', activityName());
            if (v2PoliciesEnabled.value) {
                stepsSubmitter.addSubmitParam('travelTarget', form.field('travelTarget').value, true)
            }
            if (isSingeTrip.value) {
                stepsSubmitter.addSubmitParam('travelEndDate', travelDates.endDate, true);
            } else {
                stepsSubmitter.addSubmitParam('travelEndDate', endDateForMultipleTrips(travelDates.startDate), true);
                stepsSubmitter.addSubmitParam('tripDuration', form.field('tripDuration').value, true);
            }
            if (form.field('regions').value === RegionRussia && !isSingeTrip.value && btaBase.settings.value('LOCALE_ISO') === 'LV') {
                stepsSubmitter.addSubmitParam('allTripsDuration', form.field('allTripsDuration').value, true);
            }
            if (String(form.field('travelActivities').value) !== noTravelActivity.value) {
                stepsSubmitter.addSubmitParam('activityType', form.field('travelActivities').value, true);
                if (isSingeTrip.value) {
                    const activitiesDates: DateRange = dateRangeForSubmit('activitiesDates');
                    stepsSubmitter.addSubmitParam('activityStartDate', activitiesDates.startDate, true);
                    stepsSubmitter.addSubmitParam('activityEndDate', activitiesDates.endDate, true);
                } else {
                    stepsSubmitter.addSubmitParam('activityStartDate', travelDates.startDate, true);
                    stepsSubmitter.addSubmitParam('activityEndDate', endDateForMultipleTrips(travelDates.startDate), true);
                }
            }
            if (isIso('LT')) {
                stepsSubmitter.addSubmitCustomParam('destinationType', destinationType.value);
                stepsSubmitter.addSubmitParam('territoryOfOperation', territoryOfOperation(), true);
                stepsSubmitter.addSubmitParam('destination', (regionDetails as Territories).regionIc as string, true);
            } else {
                stepsSubmitter.addSubmitParam('destination', form.field('regions').value, true)
            }
            if (v2PoliciesEnabled.value && new AppCountry().isEE()) {
                stepsSubmitter.addSubmitParam('travelStartsInEstonia', form.field('travelStartsInEstonia').value, true)
            }
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        return {
            ...btaBase, ...{
                form,
                stepsSubmitter,
                applyMultiCounter,
                applyMaxCountries,
                applyMultipleTrips,
                applyTripDuration,
                applyDatesDefaults,
                applyAllTripsDurationOptions,
                travelAllTripsDurationDropdownOptions,
                closeTripDurationSelect,
                prepareSubmit,
                applyAllTripsDurationDefault,
                applyGeoIpRestriction,
                enablePoliciesV2,
                multipleTripsIsSelected,
                regionsMessage,
                customElementIsVisible,
                travelTargetCustomUrl,
                TravelTargetUrl,
                insuranceOptions,
                onCountriesSelection,
                onRegionChanged,
                onRegionOptionsChange,
                closeRegionsMessage,
                componentIsDisabled,
                travelDatesLabel,
                isRanged,
                selectTravelDatesLabel,
                onTravelDatesChange,
                onTravelTargetChange,
                postDateText,
                checkboxIsVisible,
                onMultipleTripsChange,
                showAllTripsDuration,
                multiCounterMessage,
                multiCounterData,
                isEvakAvailable,
                closeCounterMessage,
                onMultiCounterChange,
                ageGroupLabel,
                showTravelActivities,
                activitiesAreSelected,
                multipleTripsIsDisabled,
                travelActivitiesOptions,
                isAxiosRequest,
                onTravelActivitiesChange,
                onTripDurationChange,
                closeAllTripsDurationSelect,
                dataNotReadyToSubmit,
                travelTargetValue,
                onSubmitStep,
                multipleTrips,
                applyCountryCodes,
                applyStepUrls,
                destinationType,
                onDestinationTypeUpdated,
                onRegionDetailsUpdated,
                countryCodes,
                territoryOfOperation,
                assembledCountries,
                prepareValidators,
                setupForm,
                restrictedByGeoIp,
                getInTouchModule,
                CurrentStep,
                validators,
                hasMultipleIcGroups,
                insuranceOptionsAddCallback,
                onOptionsReceiveCallback,
                insuranceOptionsFetch,
                onAfterFormRestored,
                postActivitiesDateText,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setupForm().then((): void => {
            this.getInTouchModule.create();
            this.getInTouchModule.beforeModuleMounted();
            this.getInTouchModule.moduleMounted();
            this.getInTouchModule.enableScrollToMode();
            this.stepsSubmitter.addForm(this.form);
            if (!this.restrictedByGeoIp) {
                this.setStep(this.CurrentStep);
                this.setFacility('one-travel');
                this.setStorageUsage(true);
                this.prepareValidators();
                this.insuranceOptionsAddCallback(this.onOptionsReceiveCallback);
                const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady.subscribe((): void => {
                    this.insuranceOptionsFetch();
                    this.onAfterFormRestored();
                    if (onAfterFormRestoredSubscription) {
                        onAfterFormRestoredSubscription.unsubscribe();
                    }
                });
            }
        });
    },
});
</script>
