import { JSLoader } from '@/assets/libraries/jsloader/js-loader';
import Library from '@/Enums/LibraryEnum';

export class BranchesPlugin {
    private static version: string = '1.1';

    public static init(): Promise<void> {
        return (new JSLoader(Library.Branches.js.map((file: string) => Library.Path + file + '?' + this.version)))
            .load();
    }
}
