<script setup lang="ts">
    import {computed, Ref} from 'vue';

    const props = defineProps({
        iconType: {type: String, default: 'loader'},
        iconColor: {type: String, default: 'green'},
    });

    const iconSrc: Ref<string> = computed((): string => {
        return `images/one/components/common/${props.iconType}.svg`;
    });
</script>
<template>
    <div class="content-loader">
        <img
            :class="iconColor"
            :src="iconSrc"
            alt=""
        />
    </div>
</template>
<style lang="scss" scoped>
.content-loader {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    animation: loader-rotate 1s infinite linear;

    .green {
        filter: var(--to-color-green);
    }

    .red {
        filter: var(--to-color-red);
    }

    .blue {
        filter: var(--to-color-blue);
    }

    .grey {
        filter: var(--to-color-color-grey);
    }
}

@keyframes loader-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
