import VueEvent from '@/Classes/VueEventClass';
import Vue from 'vue';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';

export default class Video {
    public readonly Container: string = '.video-popup';
    private videoUrl: string = '';

    public get url(): string {
        return this.videoUrl;
    }

    public onVideoPopupOpenClick(event: VueEvent): void {
        this.videoUrl = event.params['url'];
        PopupService.getInstance().show(new OnePopup().withType().videoPopup)
        Vue.nextTick(() => {
            $(this.Container).find('iframe').focus();
        });
    }
}
