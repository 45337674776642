import {LimitedVariant} from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import ErrorColor from '@/Enums/ErrorColorEnum';

export const useError = (): ErrorInterface => {
    const logError = (errorLevel: ErrorType, caller: string, reason: LimitedVariant): void => {
        let format: string;
        switch (errorLevel) {
            case ErrorType.Error:
                format = ErrorColor.Error;
                break;
            case ErrorType.Warning:
                format = ErrorColor.Warning;
                break;
            default:
                format = ErrorColor.Info;
        }
        console.debug('%c ' + errorLevel + ': %c ' + caller + ': %c ' + reason,
            'background: ' + format + '; color: #ffffff',
            'background:transparent;color:#000;',
            'background:transparent;color:#666;'
        );
        if (typeof reason === 'object' && errorLevel === ErrorType.Error) {
            console.debug(caller + ' object: ', reason);
        }
    }

    return {
        logError,
    }
}

export interface ErrorInterface {
    logError: (errorLevel: ErrorType, caller: string, reason: LimitedVariant) => void;
}
