import Vue from 'vue';

export default class LazyLoader {
    private static lazyImageClass: string = 'lazy-image';
    private static lazyBackgroundImageClass: string = 'lazy-background-image';

    public init(): void {
        Vue.nextTick((): void => {
            this.enableLazyImageLoad();
            this.enableLazyBackgroundImageLoad();
        })
    }

    private enableLazyImageLoad(): void {
        const observer: IntersectionObserver = new IntersectionObserver(this.onImageIntersection);
        const lazyImagesList: JQuery = $('.' + LazyLoader.lazyImageClass)!;
        lazyImagesList.each((index: number, lazyImage: HTMLElement): void => {
            observer.observe(lazyImage);
        })
    }

    private enableLazyBackgroundImageLoad(): void {
        const observer: IntersectionObserver = new IntersectionObserver(this.onBackgroundIntersection);
        const lazyBackgroundImagesList: JQuery = $('.' + LazyLoader.lazyBackgroundImageClass)!;
        lazyBackgroundImagesList.each((index: number, lazyBackgroundImage: HTMLElement): void => {
            observer.observe(lazyBackgroundImage);
        })
    }

    private onImageIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            const imageTag: Element = entry.target;
            if (entry.isIntersecting) {
                const imageDataSource: string | null = imageTag.getAttribute('data-src')!;
                imageTag.setAttribute('src', imageDataSource);
                imageTag.classList.remove(LazyLoader.lazyImageClass);
                observer.unobserve(imageTag);
            }
        });
    }

    private onBackgroundIntersection(entries: IntersectionObserverEntry[], observer: IntersectionObserver): void {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            const elementWithBackground: Element = entry.target;
            if (entry.isIntersecting) {
                elementWithBackground.classList.remove(LazyLoader.lazyBackgroundImageClass);
                observer.unobserve(elementWithBackground);
            }
        });
    }
}
