import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Sanitizer from '@/services/sanitizer.service';
import { SanitizerCallback } from '@/Types/SanitizerCallbackType';

export default class SanitizersMap {
    public static byCountryIso(): DynamicDictionary {
        return {
            LV: Sanitizer.cleanPersonCodeLV,
            LT: Sanitizer.clearPersonCodeLT,
            EE: Sanitizer.clearPersonCodeEE,
        };
    }

    public static default(): SanitizerCallback {
        return Sanitizer.cleanIdentityNumberNonBaltic;
    }
}
