import OneModule from '@/interfaces/OneModuleInterface';
import {useModule} from '@/Composables/Module';

export default class OneModuleService {
    private static instance: OneModule;

    private constructor() {
    }

    public static getInstance(): OneModule {
        if (!OneModuleService.instance) {
            OneModuleService.instance = useModule();
        }

        return OneModuleService.instance;
    }
}
