import FormField from '@/assets/libraries/form/form-field';
import MovablePropertyDeviceOption from '@/Components/OptionsFieldList/Interfaces/MovablePropertyDeviceOption';
import Device from '@/pages/ItemsAndBelongings/Interfaces/DeviceInterface';
import MovablePropertyDeviceField from '@/Components/OptionsFieldList/Classes/MovablePropertyDeviceField';

export default class MovablePropertyDeviceOptionBuilder {
    private option: MovablePropertyDeviceOption = new class implements MovablePropertyDeviceOption {
        public device!: Device;
        public component: string = 'AppMovablePropertyDeviceOption';
        public field: FormField<string> = new FormField('');
        public enabled: FormField<boolean> = new FormField('enabled', false);
        public fieldHint: string = '';
        public fieldLabel: string = '';
        public fieldPlaceholder: string = '';
    }

    public withDevice(value: Device): MovablePropertyDeviceOptionBuilder {
        this.option.device = value;

        return this;
    }

    public withState(value: boolean): MovablePropertyDeviceOptionBuilder {
        this.option.enabled.setValue(value);

        return this;
    }

    public withOptionField(): MovablePropertyDeviceOptionBuilder {
        this.option.field = new MovablePropertyDeviceField(this.option.device).forCurrentDevice();

        return this;
    }

    public withInitialValue(value: string): MovablePropertyDeviceOptionBuilder {
        this.option.field.setValue(value);

        return this;
    }

    public withFieldHint(value: string): MovablePropertyDeviceOptionBuilder {
        this.option.fieldHint = value;

        return this;
    }

    public withFieldLabel(value: string): MovablePropertyDeviceOptionBuilder {
        this.option.fieldLabel = value;

        return this;
    }

    public withFieldPlaceholder(value: string): MovablePropertyDeviceOptionBuilder {
        this.option.fieldPlaceholder = value;

        return this;
    }

    public build(): MovablePropertyDeviceOption {
        return this.option;
    }
}
