export default class IndemnityReceiverFieldNames {
    public static readonly Person: string = 'person';
    public static readonly PersonType: string = 'personType';
    public static readonly Resident: string = 'resident';
    public static readonly CountryOfResident: string = 'countryOfResident';
    public static readonly Name: string = 'name';
    public static readonly Surname: string = 'surname';
    public static readonly PersonCode: string = 'personCode';
    public static readonly BirthDate: string = 'birthDate';
    public static readonly Email: string = 'email';
    public static readonly MobilePhone: string = 'mobilePhone';
    public static readonly BankAccountNumber: string = 'bankAccountNumber';
    public static readonly CompanyName: string = 'companyName';
    public static readonly CompanyType: string = 'companyType';
    public static readonly RegistrationNumber: string = 'registrationNumber';
}
