<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import Vue, {watch, onMounted, PropType, reactive} from 'vue';
    import Form from '@/assets/libraries/form/form';
    import Value from '@/assets/libraries/form/value';
    import moment, {Moment} from 'moment';
    import CreditCardDate from '@/Components/InputCreditCardDate/CreditCardDate';
    import AppInputText from '@/Components/InputText/InputText.vue';
    import {useTranslate} from '@/Composables/Translate';
    import {UnwrapNestedRefs} from 'vue/types/v3-generated';

    const props = defineProps({
        formField: {type: Object as PropType<FormField<CreditCardDate>>, default: () => new FormField('')},
        disabled: {type: Boolean, default: false},
        dataStoreDisabled: {type: Boolean, default: false},
    });

    const emit = defineEmits(['keyup']);

    const {translate} = useTranslate();

    const visibleDateLength: number = 9;
    const visibleDatePlaceholder: string = 'MM / YY';
    const cleanInputLength: number = 4;
    const monthYearJoinString: string = '  /  ';
    const yearOffset: number = -2;
    const monthOffset: number = 1;
    const millenium: string = '20';
    const monthPrefix: string = '0';
    const monthsToFormat: string[] = ['2', '3', '4', '5', '6', '7', '8', '9',];
    const cardDateSplitPattern: RegExp = /.{1,2}/g;
    const cardDateFormatThreshold: number = 2;
    const form: UnwrapNestedRefs<Form> = reactive(new Form());

    watch(() => props.formField.value, () => {
        if (!(new Value(props.formField.value)).isEmpty()) {
            props.formField.touch();
        }
    });

    onMounted((): void => {
        setupForm();
        Vue.nextTick(() => init());
    });

    function onCardDateInput(value: string): void {
        if (cleanValue(value).length === cleanInputLength) {
            props.formField.patch(cardValidThruDate());
        } else {
            props.formField.clear();
        }
        patchFormattedVisibleDate(value);
        props.formField.touch();
        emit('keyup', props.formField.value);
    }

    function onCardDateBlur(): void {
        props.formField.touch();
    }

    function formFieldMoment(): Moment {
        return moment(
            [
                props.formField.value.year,
                props.formField.value.month - monthOffset,
            ]);
    }

    function lastDayOfMonth(): number {
        return formFieldMoment().endOf('month').date();
    }

    function init(): void {
        props.formField.setValue(defaultDate());
        props.formField.addValidators(cardDateValidators());
        props.formField.onClear.subscribe(() => {
            form.fields().forEach((field: FormField) => {
                field.clear();
            })
        });
        props.formField.onPatch.subscribe(() => {
            applyValuesToChildFields();
        });
        props.formField.onTouch.subscribe(() => {
            form.validate().then(() => {
                props.formField.validate().then();
            });
        });
    }

    function setupForm(): void {
        form.addField(new FormField('cardValidThru', '', visibleDateValidators(), cleanNumber));
        form.addField(new FormField('cardDateSegment0'));
        form.addField(new FormField('cardDateSegment1'));
    }

    function cleanNumber(value: string): string {
        return isNaN(Number(value.slice(0, 1)))
            ? ''
            : value;
    }

    function applyValuesToChildFields(): void {
        if (!props.formField.isEmpty()) {
            form.field('cardValidThru').patch(visibleDate());
        }
    }

    function cardValidThruDate(): CreditCardDate {
        const cleanValueFromForm: string = cleanValue(form.field('cardValidThru').value);
        let cardValidThruDate: CreditCardDate = new CreditCardDate();
        if (cleanValueFromForm.length === cleanInputLength) {
            const explodedDate: RegExpMatchArray = cleanValueFromForm.match(cardDateSplitPattern)!;
            cardValidThruDate.month = Number(explodedDate[0]);
            cardValidThruDate.year = Number(millenium + explodedDate[1]);
        }
        return cardValidThruDate;
    }

    function visibleDate(): string {
        const formFieldDate: CreditCardDate = props.formField.value;

        return valueWithTransformedMonth(String(formFieldDate.month))
            + monthYearJoinString
            + formFieldDate.year.toString().slice(yearOffset);
    }

    function cleanValue(value: string): string {
        return value.toString().replace(/[^\d]/g, '');
    }

    function patchFormattedVisibleDate(value: string): void {
        let formattedDate: string = '';
        const transformedValue: string = valueWithTransformedMonth(value);
        const explodedNumber: string[] | null = cleanValue(transformedValue).match(cardDateSplitPattern);
        if (explodedNumber) {
            clearCardDateSegmentFields();
            explodedNumber.forEach((numberSegment: string, index: number) => {
                if (numberSegment.length === cardDateFormatThreshold) {
                    let suffix: string;
                    if (value.slice(-1) === ' ') {
                        suffix = '';
                    } else if (index === 0) {
                        suffix = monthYearJoinString;
                    } else {
                        suffix = '';
                    }
                    form.field('cardDateSegment' + index).patch(numberSegment + suffix);
                } else {
                    form.field('cardDateSegment' + index).patch(numberSegment);
                }
            })
        }
        if (transformedValue.length < cardDateFormatThreshold) {
            formattedDate = value;
        } else if (!form.field('cardDateSegment1').isEmpty()) {
            formattedDate = formattedVisibleDate();
        } else {
            formattedDate = form.field('cardDateSegment0').value;
        }
        form.field('cardValidThru').patch(formattedDate);
    }

    function formattedVisibleDate(): string {
        let formattedDate: string = '';
        form.fields().forEach((field: FormField) => {
            if (field.name.includes('cardDateSegment')) {
                formattedDate += field.value;
            }
        });

        return formattedDate;
    }

    function clearCardDateSegmentFields(): void {
        form.fields().forEach((field: FormField) => {
            if (field.name.includes('cardDateSegment')) {
                field.clear();
            }
        })
    }

    function valueWithTransformedMonth(value: string): string {
        if (monthsToFormat.includes(value)) {
            value = monthPrefix + value;
        } else if (value === monthPrefix + monthPrefix) {
            value = monthPrefix;
        }

        return value;
    }

    function defaultDate(): CreditCardDate {
        let cardDate: CreditCardDate = new CreditCardDate();
        cardDate.month = form.field('cardDateSegment0').value;
        cardDate.year = form.field('cardDateSegment1').value;

        return cardDate;
    }

    function cardDateValidators(): object {
        return {
            isCardNotExpired: () => {
                return props.formField.isTouched && formFieldMoment().isValid()
                    ? moment(
                        [props.formField.value.year,
                            props.formField.value.month - monthOffset,
                            lastDayOfMonth(),
                        ]).isSameOrAfter(moment(), 'day')
                    : true;
            },
            isValidDate: () => {
                return props.formField.isTouched
                    ? formFieldMoment().isValid()
                    : true;
            },
        }
    }

    function visibleDateValidators(): object {
        return {
            isValidVisibleDate: () => {
                return form.field('cardValidThru').isTouched
                    ? form.field('cardValidThru').value.length === visibleDateLength
                    : true;
            },
        }
    }
</script>

<template>
    <div class="input input-credit-card-date"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <app-input-text
                :id="formField.name + '-visibleDate'"
                :label="translate('btar_credit_card_valid_thru')"
                :form-field="form.field('cardValidThru')"
                :placeholder="visibleDatePlaceholder"
                :max-length="visibleDateLength"
                :data-store-disabled="true"
                :autocomplete="'cc-exp'"
                v-on:keyup="onCardDateInput"
                v-on:blur="onCardDateBlur">
        </app-input-text>
    </div>
</template>

<style lang="scss" scoped>
.input-credit-card-date {
    #cardValidThru::v-deep {
        input#cardValidThru-text {
            &::placeholder {
                font-size: var(--font-size-nano);
            }
        }
    }

  @include respond-above('sm') {
      #cardValidThru::v-deep {
          input#cardValidThru-text {
              &::placeholder {
                  font-size: var(--font-size-tiny);
              }
          }
      }
  }
}
</style>
