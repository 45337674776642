import { JSLoader } from '@/assets/libraries/jsloader/js-loader';
import Library from '@/Enums/LibraryEnum';

export class SlickPlugin {
    private static version: string = '1.8.2';

    public static init(): Promise<void> {
        return (new JSLoader(Library.Slick.js.map((file: string) => Library.Path + file + '?' + this.version)))
            .load();
    }
}
