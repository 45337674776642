<script setup lang="ts">
import { onMounted, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { useHtml } from '@/Composables/Html';
import PaymentMethod from '@/Components/PaymentWidget/Interfaces/PaymentMethod.interface';
import PaymentMethods from '@/services/PaymentMethods.service';
import FormField from '@/assets/libraries/form/form-field';

const emit = defineEmits(['payment-method-click']);
const {translate, translateForType} = useTranslate();
const {imgTag} = useHtml();
const paymentMethods: Ref<PaymentMethod[]> = ref([]);

let selectedPaymentMethod: Ref<PaymentMethod | null> = ref(null);

const props = defineProps({
    errorState: {type: Boolean, default: false},
    paymentInfoEnabled: {type: Boolean, default: false},
});

onMounted((): void => {
    init();
});

function showPaymentInfo(): boolean {
    return paymentInfo() !== '' && props.paymentInfoEnabled;
}

function paymentInfo(): string {
    return selectedPaymentMethod.value
        ? translate(selectedPaymentMethod.value.informationText)
        : '';
}

function onPaymentMethodClick(item: PaymentMethod): void {
    if (item.selected.value) {
        selectedPaymentMethod.value = null;
        item.selected.setValue(false);
        emit('payment-method-click', {
            bankLinkId: 0,
            bankLinkInfo: '',
        });
    } else {
        resetSelectedStatus().then(() => {
            selectedPaymentMethod.value = item;
            selectedPaymentMethod.value.selected.setValue(true);
            emit('payment-method-click', {
                bankLinkId: item.bankLinkId,
                bankLinkInfo: paymentInfo(),
            });
        });
    }
}

function desktopIconAttributes(item: PaymentMethod): ImageTagAttributes {
    return {
        class: 'item-desktop',
        src: item.imageDesktop,
        width: 180,
        height: 30,
        alt: item.title,
    }
}
function mobileIconAttributes(item: PaymentMethod): ImageTagAttributes {
    return {
        class: 'item-mobile',
        src: item.imageDesktop,
        width: 90,
        height: 24,
        alt: item.title,
    }
}

function resetSelectedStatus(): Promise<void> {
    return new Promise(resolve => {
        paymentMethods.value.forEach((method: PaymentMethod): void => {
            method.selected.setValue(false);
        });
        resolve();
    });
}

function init(): void {
    fetchPaymentMethods();
}

function fetchPaymentMethods() {
    paymentMethods.value = PaymentMethods.getInstance()
        .paymentMethods()
        .map((method: PaymentMethod): PaymentMethod => {
            return {
                ...method,
                selected: new FormField('selected', false),
            };
        });
}

</script>

<template>
    <div class="payment-methods"
         :id="'payment-methods'">
        <div class="title"
             :class="{'red-text': props.errorState}">
            {{translateForType('payment_methods_title', 'components')}}
        </div>
        <div class="padded heavy tiny grid-view">
            <button class="payment-method"
                    :class="{'active': item.selected.value}"
                    v-for="(item, index) in paymentMethods"
                    :key="index"
                    @click="onPaymentMethodClick(item)">
                <div class="icon" v-html="imgTag(mobileIconAttributes(item))"></div>
                <div class="icon" v-html="imgTag(desktopIconAttributes(item))"></div>
            </button>
        </div>
        <div v-if="showPaymentInfo()" class="payment-info heavy nano special-height" v-html="paymentInfo()"></div>
    </div>
</template>

<style lang="scss" scoped>
.payment-methods {
    width: 100%;
    max-width: 800px;
    border-radius: 16px;
    scroll-margin-top: 4em;
    padding: 0;

    @include respond-above('sm') {
        padding: 0;
    }

    .title {
        font-size: var(--font-size-big);
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.32px;
        text-align: center;
    }

    .padded {

        &:not(:first-child) {
            padding-top: var(--size-small);
        }
    }

    .red-text {
        color: var(--brand-red);
    }

    .flex {
        display: flex;

        &.mobile-column {
            flex-direction: column;
            gap: 20px;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }

    .special-height {
        line-height: var(--line-height-accented);
    }

    .heavy {
        font-weight: 600;
    }

    .tiny {
        font-size: var(--font-size-tiny);
    }

    .nano {
        font-size: var(--font-size-nano);
    }

    .grid-view {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--size-nano);
        justify-items: center;

        @include respond-above('sm') {
            grid-template-columns: repeat(4, 1fr);
        }

        .payment-method {
            width: 100%;
            height: 72px;
            border-radius: var(--size-tiny);
            border: 2px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 22px var(--size-normal);
            background: var(--white);

            @include respond-above('sm') {
                height: 100px;
            }

            &.active {
                border-color: var(--system-color-success-default);
                background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%), var(--component-color-background-base);
            }
        }
    }

    .payment-info {
        margin-bottom: 20px;
        max-width: 800px;
        align-self: center;
        background: var(--component-color-background-base);
        border-radius: 16px;
        color: var(--text-color-default);
        font-weight: 600;
        padding: 30px;
        text-align: left;
        width: 100%;

        ::v-deep a {
            color: var(--brand-teal);
        }
    }
}
</style>
