<script lang="ts">
import Vue, {computed, defineComponent, reactive, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {Subscription} from 'rxjs';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import CoveredPopupProperty from '@/pages/Property/CoveredPopupProperty';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PropertyAdditionalRisksDictionary from '@/interfaces/property.additional.risks.dictionary.interface';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import StringArrayDictionary from '@/interfaces/string.array.dictionary.interface';
import Method from '@/Enums/MethodEnum';
import PopupType from '@/Enums/PopupTypeEnum';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import {StepsSubmitterParams, useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {DefineParams, useDefine} from '@/Composables/Define';
import PropertyRisk from '@/interfaces/property.risk.interface';
import CssClass from '@/Enums/CssClassEnum';
import PropertyPrices from '@/interfaces/property.prices.interface';
import PropertyPaymentType from '@/Enums/PropertyPaymentTypeEnum';
import UserState from '@/Enums/UserStateEnum';
import VueEvent from '@/Classes/VueEventClass';
import PropertyProductTerms from '@/interfaces/property.product.terms.interface';
import PropertyRiskData from '@/interfaces/property.risk.data.interface';
import PropertyEnum from '@/Enums/PropertyEnum';
import {PriceParams, usePrice} from '@/Composables/Price';
import PropertyRiskDataPrice from '@/interfaces/property.risk.data.price.interface';
import FormField from '@/assets/libraries/form/form-field';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Url from '@/Enums/UrlEnum';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import OneBase from '@/interfaces/OneBaseInterface';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import PropertyRiskType from '@/Enums/PropertyRiskTypeEnum';
import {useTransforms} from '@/Composables/Transforms';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();


        const {isSet}: DefineParams = useDefine();
        const {translate, translateForType, language, hasLocalization}: TranslateParams = useTranslate();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const {price, sparse}: PriceParams = usePrice();


        const LastProduct: number = 2;
        const CurrentStep: number = 4;
        const RiskIsEnabled: string = '1';
        const coveredPopup: CoveredPopupProperty = new CoveredPopupProperty();
        const dataLayer: PropertyDataLayer = new PropertyDataLayer();
        let onWindowResizeSubscription!: Subscription;


        const policyPlan: Ref<string> = ref('');
        const paymentType: Ref<string> = ref('');
        const visibleMobilePlan: Ref<string> = ref('');
        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        let selectedAdditionalRisksForSave: UnwrapNestedRefs<string | DynamicDictionary> = reactive({});
        let selectedAdditionalRisks: UnwrapNestedRefs<PropertyAdditionalRisksDictionary> = reactive(
            new class implements PropertyAdditionalRisksDictionary {
                [key: string]: StringArrayDictionary;
            }
        );
        let selectedProductWithAdditionalRisks: UnwrapNestedRefs<DynamicDictionary> = reactive({});
        let propertyAdditionalRisksMapping: UnwrapNestedRefs<DynamicDictionary> = reactive({});
        let badges: Ref<DynamicDictionary[]> = ref([]);

        const additionalRisksIncluded: Ref<PropertyRisk[]> = ref([]);
        const additionalRisksNotIncluded: Ref<PropertyRisk[]> = ref([]);
        const selectedProductPrice: Ref<string> = ref('');
        const selectedProductOriginalPrice: Ref<string> = ref('');


        const policyPlanLocalized: Ref<string> = computed((): string => {
            return translation('one_property_policy_' + selectedPolicyPlan.value);
        });

        const additionalContainerClasses: Ref<string> = computed((): string => {
            return propertyProductIsSelected.value ? '' : CssClass.Hidden;
        });

        const propertyProductIsSelected: Ref<boolean> = computed((): boolean => {
            return selectedPolicyPlan.value !== '';
        });

        const youSave: Ref<string> = computed((): string => {
            let result: string = '';
            if (selectedPolicyPlan.value !== '') {
                const prices: PropertyPrices = productPrice(selectedPolicyPlan.value);
                result = sparsePrice(prices.price - prices.priceWithDiscount);
            }

            return result;
        });

        const paymentDescriptionType: Ref<string> = computed((): string => {
            let result: string = ' ' + translate('btar_euro');
            if (isMonthlyPayment.value) {
                result += '/' + translate('btar_month').toLowerCase();
            }

            return result;
        });

        const paymentDescriptionTypeVertical: Ref<string> = computed((): string => {
            let result: string = '';
            if (isMonthlyPayment.value) {
                result += translate('btar_month').toLowerCase();
            }

            return result;
        });

        const hasDiscount: Ref<boolean> = computed((): boolean => {
            const prices: PropertyPrices = productPrice(selectedPolicyPlan.value);

            return prices.price > prices.priceWithDiscount;
        });

        const isMonthlyPayment: Ref<boolean> = computed((): boolean => {
            return selectedPaymentType.value === PropertyPaymentType.Monthly;
        });

        const userType: Ref<string> = computed((): string => {
            return btaBase.user.isLogged() ? UserState.authenticated : UserState.guest;
        });

        const selectedPaymentType: Ref<string> = computed((): string => {
            return paymentType.value;
        });

        const selectedPolicyPlan: Ref<string> = computed((): string => {
            return policyPlan.value;
        });

        const policyPlanIsSelected: Ref<boolean> = computed((): boolean => {
            return selectedPolicyPlan.value !== '';
        });

        function selectAndSubmit(event: VueEvent): void {
            submit();
        }

        function additionalRiskOpenerClick(event: VueEvent, id: string): void {
            event.sender.toggleClass(CssClass.Opened);
            $('.description-opener[data-opener="' + id + '"]').toggleClass(CssClass.Visible);
        }

        function showSelectedPlanCovered(): void {
            if (policyPlan.value !== '') {
                showCoveredPopup(selectedPolicyPlan.value);
            }
        }

        function formattedPrice(targetPrice: string | number): string {
            return price(targetPrice, false);
        }

        function sparsePrice(targetPrice: number, noCents: boolean = false): string {
            return sparse(targetPrice, noCents);
        }

        function risks(id: string, type: string): PropertyRiskData[] {
            let result: PropertyRiskData[] = [];
            const term: PropertyProductTerms = productTermsById(id);
            if (isSet(term.risks) && isSet(term.risks[type])) {
                result = term.risks[type];
            }

            return result;
        }

        function riskName(risk: PropertyRiskData): string {
            return translateForType('one_property_risk_' + risk.id, PropertyEnum.TranslationType);
        }

        function policyPlanRiskPrice(risk: PropertyRiskData, currency: string): string {
            let targetPrice: number = 0;
            let message: boolean = false;
            if (isSet(risk.prices)) {
                risk.prices.forEach((value: PropertyRiskDataPrice): void => {
                    if (value.priceType === userType.value) {
                        if (isSet(value.price)) {
                            targetPrice = value.price;
                        }
                    }
                });
            } else {
                message = true;
            }
            let result: string = '<span class="zero-price"></span>';
            if (targetPrice > 0) {
                result = '<span>' + sparse(targetPrice, true) + '</span><span>' + currency + '</span>';
            } else if (message) {
                result = translateForType('one_property_risk_' + risk.id + '_IS_SELECTED', PropertyEnum.TranslationType);
            }

            return result;
        }

        function productPriceById(productId: string, isSparse: boolean = false, hideZeroPrice: boolean = false): string {
            const targetPrice: number = productPrice(productId).priceWithDiscount;
            let formattedPrice: string = isSparse ? sparsePrice(targetPrice) : String(targetPrice);
            if (hideZeroPrice && targetPrice === 0) {
                formattedPrice = '';
            }

            return formattedPrice;
        }

        function additionalRiskTooltipsterIsVisible(additionalRisk: PropertyRisk): boolean {
            return additionalRisk.tooltipTitle !== '' && additionalRisk.tooltipDescription !== '';
        }

        function onPropertyDataAction(): void {
            btaBase.navigate(SubmitterUrls.getInstance().previousStep());
        }

        function additionalOptionsButtonClasses(included: boolean): string {
            return included ? CssClass.Outside : CssClass.Red;
        }

        function selectAdditionalRisk(additionalRisk: PropertyRisk, mustBeAdded: boolean): void {
            const riskCombinedId: string =
                (additionalRisk.prefix !== '' ? additionalRisk.prefix + ':' : '') + additionalRisk.id;
            if (mustBeAdded) {
                if (!useDefine().arrayContains(
                    selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value], riskCombinedId)
                ) {
                    selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value].push(riskCombinedId);
                }
            } else {
                if (useDefine().arrayContains(
                    selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value], riskCombinedId)
                ) {
                    const from: number = useDefine().arrayContainsIndex(
                        selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value], riskCombinedId
                    );
                    selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value].splice(from, 1)
                }
            }
            form.field('selectedAdditionalRisksForSave').patch(JSON.stringify(selectedAdditionalRisks));
            doAdditionalSubmit();
        }

        function applyAdditionalRisksForSave(): void {
            if (form.field('selectedAdditionalRisksForSave').isNotEmpty()) {
                let current: string | DynamicDictionary = form.field('selectedAdditionalRisksForSave').value;
                if (typeof current === 'string') {
                    current = JSON.parse(current);
                }
                Object.assign(selectedAdditionalRisksForSave, current)
            }
        }

        function applyBadges(newBadges: string): void {
            badges.value = JSON.parse(useTransforms().transformedVueSafeString(newBadges));
        }

        function translation(stringId: string): string {
            return translateForType(stringId, PropertyEnum.TranslationType);
        }

        function hasTranslation(stringId: string): boolean {
            return hasLocalization(stringId, PropertyEnum.TranslationType);
        }

        function featuredRiskTooltipText(risk: PropertyRiskData, type: string = 'title'): string {
            return translation('one_property_risk_' + risk.id + '_tip_' + type);
        }

        function productTermsById(id: string): PropertyProductTerms {
            let result: PropertyProductTerms = new class implements PropertyProductTerms {
                id: string = '';
                deductible: number = 0;
                prices: PropertyRiskDataPrice[] = [];
                risks: Record<string, PropertyRiskData[]> = {};
            }
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((product: DynamicDictionary): void => {
                    if (product.id === id) {
                        const paymentTerms: DynamicDictionary = isSet(selectedProductWithAdditionalRisks.id) &&
                        selectedProductWithAdditionalRisks.id === product.id ?
                            selectedProductWithAdditionalRisks.paymentTerms : product.paymentTerms;
                        paymentTerms.forEach((terms: PropertyProductTerms): void => {
                            if (terms.id === selectedPaymentType.value) {
                                result = terms;
                            }
                        });
                    }
                });
            }

            return result;
        }

        function applyPropertyAdditionalRisksMapping(risks: string): void {
            propertyAdditionalRisksMapping = JSON.parse(useTransforms().transformedVueSafeString(risks));
        }

        function submit(): void {
            dataLayer.pushDataLayer(dataLayerParams());
            Vue.nextTick(() => {
                prepareSubmit();
                stepsSubmitter.proceedStep('', btaBase.nextStep());
            })
        }

        function additionalRisks(included: boolean): PropertyRisk[] {
            const result: PropertyRisk[] = [];
            const term: PropertyProductTerms = productTermsById(selectedPolicyPlan.value);
            if (isSet(term.risks.additional)) {
                const risks: PropertyRiskData[] = included ? term.risks.included : term.risks.additional;
                risks.forEach((risk: PropertyRiskData): void => {
                    if (validAdditionalRisk(risk)) {
                        const title: string = translation('one_property_risk_' + risk.id);
                        const description: string = translation('one_property_risk_' + risk.id + '_tip_description');
                        const tipTitle: string = 'one_property_risk_' + risk.id + '_tip_title';
                        const tooltipTitle: string = hasTranslation(tipTitle) ?
                            translation(tipTitle) : '';
                        const tipDescription: string = 'one_property_risk_' + risk.id + '_tip_description';
                        const tooltipDescription: string = hasTranslation(tipDescription) ?
                            translation(tipDescription) : '';
                        const riskId: string = risk.id;
                        const riskPrefix: string = risk.prefixByIc;
                        result.push(
                            new class implements PropertyRisk {
                                id: string = riskId;
                                prefix: string = riskPrefix;
                                title: string = title;
                                description: string = description;
                                tooltipTitle: string = tooltipTitle;
                                tooltipDescription: string = tooltipDescription;
                            }
                        )
                    }
                });
            }

            return result;
        }

        function hasAdditionalRisks(): boolean {
            return additionalRisksCombined() !== '';
        }

        function validAdditionalRisk(risk: PropertyRiskData): boolean {
            return isSet(propertyAdditionalRisksMapping[risk.id]) &&
                propertyAdditionalRisksMapping[risk.id][selectedPolicyPlan.value] == RiskIsEnabled;
        }

        function additionalRisksCombined(): string {
            let result: string = '';
            if (policyPlanIsSelected.value && isSet(selectedAdditionalRisks[selectedPolicyPlan.value])) {
                result = selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value].sort().join(',');
            }

            return result;
        }

        function additionalRisksMappingCombined(): string {
            let result: string = '';
            if (policyPlanIsSelected.value) {
                const selectedRisksMapping: string[] = [];
                selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value]
                    .sort()
                    .forEach((combinedRiskId: string): void => {
                        const riskId: string = combinedRiskId.split(':')[1];
                        selectedRisksMapping.push(propertyAdditionalRisksMapping[riskId].object_type);
                    });
                if (selectedRisksMapping.length > 0) {
                    result = selectedRisksMapping.join(',');
                }
            }

            return result;
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('policyPlan', selectedPolicyPlan.value);
            stepsSubmitter.addSubmitCustomParam('payment[type]', selectedPaymentType.value)
            stepsSubmitter.addSubmitCustomParam('risks[additional]', additionalRisksCombinedForRequest())
            stepsSubmitter.addSubmitCustomParam('risksMapping', additionalRisksMappingCombined())
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            if (hasAdditionalRisks()) {
                stepsSubmitter.addSubmitCustomParam('additionalPolicy', selectedProductWithAdditionalRisks);
            }
            stepsSubmitter.addSubmitCustomParam('dataLayerParams', dataLayerParams());
        }

        function dataLayerParams(): DynamicDictionary {
            const dataLayer: DynamicDictionary =
                useTransforms().deepClonedObjectWithoutVueReactivity(btaBase.userStorage.stepStorageData.dataLayerParams);
            dataLayer.variant = btaBase.userStorage.stepStorageData.dataLayerParams.variant + '-' + selectedPolicyPlan.value;
            dataLayer.price = selectedProductPrice.value;
            dataLayer.dimension9 = additionalRisksCombinedForRequest();
            dataLayer.dimension10 = selectedPaymentType.value;

            return dataLayer;
        }

        function showCoveredPopup(id: string): void {
            prepareCoveredPopup(id);
            PopupService.getInstance().show(new OnePopup().withType().coveredProperty)
            Vue.nextTick((): void => {
                btaBase.initPopupTooltips();
            });
        }

        function prepareCoveredPopup(id: string): void {
            const deductible: string = String(productTermsById(id).deductible ?? '');
            const coveredRisks: PropertyRiskData[] = risks(id, PropertyRiskType.Covered);
            coveredPopup.build(id, deductible, coveredRisks);
        }

        function restoreAdditionalRisks(): void {
            if (!form.field('selectedAdditionalRisksForSave').isEmpty()) {
                selectedAdditionalRisks = JSON.parse(form.field('selectedAdditionalRisksForSave').value);
            }
        }

        function setupForm(): void {
            form.addField(new FormField('selectedAdditionalRisksForSave'));
            form.setReady();
        }

        function productPrice(id: string): PropertyPrices {
            const result: PropertyPrices = new class implements PropertyPrices {
                discount: number = 0;
                price: number = 0;
                priceWithDiscount: number = 0;
            };
            const terms: PropertyProductTerms = productTermsById(id);
            if (isSet(terms.prices)) {
                terms.prices.forEach((currentPrice: PropertyRiskDataPrice): void => {
                    if (currentPrice.priceType === userType.value) {
                        result.price = currentPrice.price;
                    }
                    if (currentPrice.priceType === userType.value + '-discount') {
                        result.priceWithDiscount = currentPrice.price;
                        result.discount = currentPrice.discount;
                    }
                });
            }

            return result;
        }


        function doAdditionalRequestAfterRestore(): void {
            if (hasAdditionalRisks()) {
                doAdditionalSubmit();
            }
        }

        function prepareAdditionalSubmit(): void {
            stepsSubmitter.clearParams();
            stepsSubmitter.clearCustomParams();
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
            stepsSubmitter.addSubmitParam('policyPlan', selectedPolicyPlan.value, false);
            stepsSubmitter.addSubmitCustomParam('policy[additionalRisks]', additionalRisksCombinedForRequest());
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
        }

        function additionalRisksCombinedForRequest(): string {
            const result: string[] = [];
            if (policyPlanIsSelected.value) {
                selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value].sort();
                selectedAdditionalRisks[selectedPolicyPlan.value][selectedPaymentType.value]
                    .forEach((risk: string): void => {
                        result.push(risk.split(':')[1]);
                    });
            }

            return result.join(',');
        }

        function onAdditionalRequestReceive(value: DynamicDictionary): void {
            if (value.data.length === 1) {
                Object.assign(selectedProductWithAdditionalRisks, value.data[0]);
            } else {
                btaBase.error.show(ErrorType.Error, btaBase.facility() + '::onAdditionalRequestReceive()', 'no_products_updated');
            }
            applyRisks();
            applyPrices();
            btaBase.popup.showPopup(PopupType.None);
        }

        function onUserStorageReady(): void {
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((product: DynamicDictionary) => {
                    const productId: string = String(product.id);
                    selectedAdditionalRisks[productId] = new class implements StringArrayDictionary {
                        [key: string]: string[];
                    };
                    selectedAdditionalRisks[productId][PropertyPaymentType.Monthly] = [];
                    selectedAdditionalRisks[productId][PropertyPaymentType.Annual] = [];
                });
                applyPolicyPlan();
                applyPaymentType();
                applyRisks();
                applyPrices();
            }
        }

        function applyPrices(): void {
            selectedProductPrice.value = sparsePrice(productPrice(selectedPolicyPlan.value).priceWithDiscount);
            selectedProductOriginalPrice.value = sparsePrice(productPrice(selectedPolicyPlan.value).price);
        }

        function applyRisks(): void {
            additionalRisksIncluded.value = additionalRisks(true);
            additionalRisksNotIncluded.value = additionalRisks(false);
        }

        function applyPolicyPlan(): void {
            policyPlan.value = btaBase.userStorage.stepStorageData.policyPlan;
        }

        function applyPaymentType(): void {
            paymentType.value = btaBase.userStorage.stepStorageData['payment[type]'];
        }

        function doAdditionalSubmit(): void {
            if (policyPlanIsSelected.value) {
                PopupService.getInstance().show(new OnePopup().withType().loading);
                prepareAdditionalSubmit();
                stepsSubmitter.submitMethod(Method.Post);
                stepsSubmitter.addAdditionalRequestCallback(onAdditionalRequestReceive);
                stepsSubmitter.submitAdditionalRequest(Url.Ajax.propertyAdditionalRisks, false);
            }
        }

        function restoreAdditionalRisksFormFieldValueAsString(): void {
            if (!form.field('selectedAdditionalRisksForSave').isEmpty()) {
                form.field('selectedAdditionalRisksForSave')
                    .patch(JSON.stringify(form.field('selectedAdditionalRisksForSave').value));
            }
        }

        function onAfterFormRestored(restoredElementsCount: number): void {
            form.validate().then();
            btaBase.userStorage.storageData.forEach((value: DynamicDictionary, index: number): void => {
                if (index === 1 && selectedPolicyPlan.value === '') {
                    visibleMobilePlan.value = value.id;
                }
            });
            Vue.nextTick((): void => {
                PopupService.getInstance().hide();
                restoreAdditionalRisksFormFieldValueAsString();
                applyAdditionalRisksForSave();
                restoreAdditionalRisks();
                doAdditionalRequestAfterRestore();
                const availableRisksCount: number = $('.additional-list')
                    .not('.hidden')
                    .children('.additional-item')
                    .not('.hidden').length;
                if (availableRisksCount < 0) {
                    submit();
                }
            });
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        return {
            ...btaBase, ...{
                form,
                coveredPopup,
                policyPlan,
                paymentType,
                visibleMobilePlan,
                policyPlanLocalized,
                additionalRisksIncluded,
                additionalRisksNotIncluded,
                additionalContainerClasses,
                propertyProductIsSelected,
                selectedProductPrice,
                selectedProductOriginalPrice,
                youSave,
                paymentDescriptionType,
                paymentDescriptionTypeVertical,
                hasDiscount,
                isMonthlyPayment,
                userType,
                selectedPaymentType,
                selectedPolicyPlan,
                policyPlanIsSelected,
                CurrentStep,
                stepsSubmitter,
                onWindowResizeSubscription,
                onAfterFormRestored,
                selectAndSubmit,
                additionalRiskOpenerClick,
                showSelectedPlanCovered,
                formattedPrice,
                sparsePrice,
                risks,
                riskName,
                policyPlanRiskPrice,
                productPriceById,
                additionalRiskTooltipsterIsVisible,
                onPropertyDataAction,
                additionalOptionsButtonClasses,
                selectAdditionalRisk,
                applyAdditionalRisksForSave,
                applyBadges,
                translation,
                productTermsById,
                applyPropertyAdditionalRisksMapping,
                submit,
                additionalRisks,
                hasAdditionalRisks,
                prepareAdditionalSubmit,
                additionalRisksCombinedForRequest,
                onAdditionalRequestReceive,
                applyStepUrls,
                setupForm,
                onUserStorageReady,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
        this.setStep(this.CurrentStep);
        this.setFacility('one-property');
        this.setStorageUsage(true);
        this.setupForm();

        this.stepsSubmitter.submitMethod(Method.Get);
        const onAfterFormRestoredSubscription =
            this.userStorage.onFormStorageDataIsReady.subscribe((count: number) => {
                this.onAfterFormRestored(count);
                onAfterFormRestoredSubscription.unsubscribe();
            });
        this.onWindowResizeSubscription = this.onWindowResize
            .subscribe((): void => {
                if (this.isVerticalMode) {
                    if (this.policyPlan === '') {
                        this.policyPlan = this.visibleMobilePlan;
                    }
                }
            });
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady
            .subscribe((): void => {
                this.onUserStorageReady();
                onStorageReadySubscription.unsubscribe();
            });
    },

    beforeDestroy() {
        if (this.onWindowResizeSubscription) {
            this.onWindowResizeSubscription.unsubscribe();
        }
    },
});
</script>
