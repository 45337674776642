import ClickOutside from '@/Directives/ClickOutsideDirective';
import Uppercase from '@/Directives/UppercaseDirective';
import Lowercase from '@/Directives/LowercaseDirective';
import IframeResizer from '@/Directives/IframeResizerDirective';
import VisibilityState from '@/Directives/VisibilityStateDirective';
import MaskAnalytics from '@/Directives/MaskAnalyticsDirective';
import ResetFocus from '@/Directives/ResetFocusDirective';
import Vue from 'vue';

Vue.directive('click-outside', ClickOutside);
Vue.directive('uppercase', Uppercase.update);
Vue.directive('lowercase', Lowercase.update);
Vue.directive('iframe-resizer', IframeResizer);
Vue.directive('observe-visibility', VisibilityState);
Vue.directive('mask-analytics', MaskAnalytics);
Vue.directive('reset-focus', ResetFocus);

