export default class MtplClaimsAjaxCalls {
    private static readonly Prefix: string = '/ajax/claimsMtpl';
    public static readonly UploadCategories: string = `${this.Prefix}/uploadCategories`;
    public static readonly AccidentDescriptionText: string = `${this.Prefix}/accidentDescriptionText`;
    public static readonly ClaimsMtplObjects: string = `${this.Prefix}/insuredObjects`;
    public static readonly ClaimsMtplSingleObject: string = `${this.Prefix}/insuredObject`;
    public static readonly RepairServices: string = `${this.Prefix}/repairServices`;
    public static readonly AccidentRegistryData: string = `${this.Prefix}/accidentRegistryData`;
    public static readonly VehicleRegistryData: string = `${this.Prefix}/vehicleRegistryData`;
}
