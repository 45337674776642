import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Vue from 'vue';

export default class SentryLibrary {
    public init(dsn: string, targets: (string | RegExp)[], cid: string): void {
        const sentryConfig: DynamicDictionary = {
            Vue,
            dsn: dsn,
            integrations: [
                new BrowserTracing({
                    tracePropagationTargets: this.processedTargets(targets).concat(/^\//),
                }),
            ],
            tracesSampleRate: 1.0,
        };
        Sentry.setTag('x-correlation-id', cid);
        Sentry.init(sentryConfig);
    }

    private processedTargets(targets: (string | RegExp)[]): (string | RegExp)[] {
        return targets.map((target: string | RegExp): string | RegExp => {
            let result: string | RegExp = target;
            if (typeof target === 'string' && !target.match(/^(https?):\/\/.*/i)) {
                result = new RegExp('^https?://' + target, 'i');
            }
            return result;
        })
    }
}
