import {CSSLoader} from '@/assets/libraries/cssloader/css-loader';
import Library from '@/Enums/LibraryEnum';
import {JSLoader} from '@/assets/libraries/jsloader/js-loader';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';

export class AgGridPlugin {
    private static version: string = '29.1.0';
    private static initialized: boolean = false;

    public static init(): Promise<void> {
        let result: Promise<void> = Promise.resolve();
        if (!AgGridPlugin.initialized) {
            result = Promise.all([
                (new CSSLoader(Library.AgGrid.css.map((file: string) => Library.Path + file + '?' + this.version)))
                    .load(),
                (new JSLoader(Library.AgGrid.js.map((file: string) => Library.Path + file + '?' + this.version)))
                    .load(),
            ]).then(() => {
                AgGridPlugin.initialized = true;
            }).catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, 'AgGridPlugin::init', reason);
            });
        }

        return result;
    }
}
