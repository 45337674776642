import CreditCardType from '@/interfaces/credit.card.type.interface';

export default class DefaultCard implements CreditCardType {
    public readonly icon: string = '';
    public readonly maxDigitsFormatted: number = 22;
    public readonly name: string = '';
    public readonly numberDigits: number[] = [0];
    public readonly pattern: RegExp = /\d$/;
    public readonly startDigit: number = 0;
    public readonly whitelistedNumbers: string[] = [];
}
