import AppCountry from '@/assets/libraries/app/app-country';
import {useStrings} from '@/Composables/Strings';

export default class Sanitizer {
    private static readonly IdentityNumberMaxDigits: number = 11;
    private static readonly IdentityNumberLvMaxDigits: number = 12;
    private static readonly IdentityNumberNonBalticMaxDigits: number = 16;
    private static readonly DriversLicenseNumberMaxDigits: number = 20;
    private static readonly PlateOrVinNumberMaxDigits: number = 17;
    private static readonly DayMaxDigits: number = 2;
    private static readonly MonthMaxDigits: number = 2;
    private static readonly YearMaxDigits: number = 4;
    private static readonly IMEIMaxSymbols: number = 15;
    private static readonly CaptionMaxSymbols: number = 30;
    private static readonly BicycleModelMaxSymbols: number = 80;

    public static cleanAddressRequest(value: string): string {
        return value.toString().replace(/[,+><()~*"@]/g, '');
    }

    public static cleanPlateNumber(value: string, localeIso: string = new AppCountry().iso()): string {
        let updatedValue: string = value;
        switch (localeIso) {
            case 'EE':
                updatedValue = Sanitizer.cleanPlateNumberEE(value);
                break;
            case 'LT':
                updatedValue = Sanitizer.cleanPlateNumberLT(value);
                break;
            case 'LV':
                updatedValue = Sanitizer.cleanPlateNumberLV(value);
                break;
            default:
        }

        return updatedValue;
    }

    public static cleanPlateNumberEE(value: string): string {
        const cleanPlateNumber: string = value.toString().replace(/[^a-zA-Z0-9]$/g, '');
        const substringMax: number = 9;

        return cleanPlateNumber.substr(0, substringMax);
    }

    public static cleanPlateNumberLT(value: string): string {
        const cleanPlateNumber: string = value.toString().replace(/[^a-zA-Z0-9]$/g, '');
        const substringMax: number = 7;

        return cleanPlateNumber.substr(0, substringMax);
    }

    public static cleanPlateNumberLV(value: string): string {
        const cleanPlateNumber: string = value.toString().replace(/[^a-zA-Z0-9\\s+]/g, '');
        const substringMax: number = 8;

        return cleanPlateNumber.substr(0, substringMax);
    }

    public static cleanPlateOrVinNumber(value: string): string {
        const cleanNumber: string = value.toString().replace(/[^a-zA-Z0-9]/g, '');

        return cleanNumber.toUpperCase().substr(0, Sanitizer.PlateOrVinNumberMaxDigits);
    }

    public static cleanVehicleOwnerIdentificationNumber(
        value: string,
        localeIso: string = new AppCountry().iso()
    ): string {
        let updatedValue: string = value;
        switch (localeIso) {
            case 'EE':
                updatedValue = Sanitizer.cleanCompanyRegistrationNumberEE(value);
                break;
            case 'LT':
                updatedValue = Sanitizer.cleanCompanyRegistrationNumberLT(value);
                break;
            case 'LV':
                updatedValue = Sanitizer.cleanVehicleOwnerIdentificationNumberLV(value);
                break;
            default:
        }

        return updatedValue;
    }

    public static cleanVehicleOwnerIdentificationNumberLV(value: string): string {
        const cleanIdentificationNumber: string = value.toString().replace(/[^a-zA-Z0-9-]/g, '');
        const substringMax: number = 12;

        return cleanIdentificationNumber.substr(0, substringMax);
    }

    public static cleanVehicleRegistrationNumber(value: string, localeIso: string = new AppCountry().iso()): string {
        let updatedValue: string = value;
        switch (localeIso) {
            case 'EE':
                updatedValue = Sanitizer.cleanVehicleRegistrationNumberEE(value);
                break;
            case 'LT':
                updatedValue = Sanitizer.cleanVehicleRegistrationNumberLT(value);
                break;
            case 'LV':
                updatedValue = Sanitizer.cleanVehicleRegistrationNumberLV(value);
                break;
            default:
        }
        return updatedValue;
    }

    public static cleanVehicleRegistrationNumberEE(value: string): string {
        return value.toString().replace(/[\D]/g, '').toUpperCase();
    }

    public static cleanVehicleRegistrationNumberLT(value: string): string {
        return value.toString().replace(/[^0-9a-zA-Z]/g, '').toUpperCase();
    }

    public static cleanVehicleRegistrationNumberLV(value: string): string {
        return value.toString().replace(/[^0-9a-zA-Z]/g, '').toUpperCase();
    }

    public static cleanCompanyRegistrationNumber(value: string, localeIso: string = new AppCountry().iso()): string {
        let updatedValue: string = value;
        switch (localeIso) {
            case 'EE':
                updatedValue = Sanitizer.cleanCompanyRegistrationNumberEE(value).toUpperCase();
                break;
            case 'LT':
                updatedValue = Sanitizer.cleanCompanyRegistrationNumberLT(value).toUpperCase();
                break;
            case 'LV':
                updatedValue = Sanitizer.cleanCompanyRegistrationNumberLV(value).toUpperCase();
                break;
            default:
        }
        return updatedValue;
    }

    public static cleanCompanyRegistrationNumberEE(value: string): string {
        return value.toString().replace(/[^0-9]/g, '').toUpperCase();
    }

    public static cleanCompanyRegistrationNumberLT(value: string): string {
        return value.toString().replace(/[^(1|)[0-9]{1}[0-9]{2,8}(?:x.+)?]$/g, '').toUpperCase();
    }

    public static cleanCompanyRegistrationNumberLV(value: string): string {
        return value.toString().replace(/[^0-9]/g, '').toUpperCase();
    }

    public static clearPersonCode(value: string): string {
        const localeIso: string = new AppCountry().iso();
        let updatedValue: string = value;
        switch (localeIso) {
            case 'EE':
                updatedValue = Sanitizer.clearPersonCodeEE(value);
                break;
            case 'LT':
                updatedValue = Sanitizer.clearPersonCodeLT(value);
                break;
            case 'LV':
                updatedValue = Sanitizer.clearPersonCodeLV(value);
                break;
            default:
        }

        return updatedValue;
    }

    public static clearPersonCodeEE(value: string): string {
        return value.toString()
            .replace(/[^0-9-]/g, '')
            .substring(0, Sanitizer.IdentityNumberMaxDigits);
    }

    public static clearPersonCodeLT(value: string): string {
        return value.toString()
            .replace(/[^0-9]/g, '')
            .substring(0, Sanitizer.IdentityNumberMaxDigits);
    }

    public static clearPersonCodeLV(value: string): string {
        return value.toString()
            .replace(/[^0-9-]/g, '')
            .substring(0, Sanitizer.IdentityNumberLvMaxDigits);
    }

    public static cleanDriversLicenseNumber(value: string): string {
        return value.toString()
            .replace(/[^A-Za-z0-9-]/g, '')
            .toUpperCase()
            .substring(0, Sanitizer.DriversLicenseNumberMaxDigits);

    }

    public static cleanSumInsured(value: string): string {
        return value.toString().replace(/[^0-9]/g, '');
    }

    public static cleanDisabilityCertificateNumber(value: string, localeIso: string = new AppCountry().iso()): string {
        return localeIso === 'LV' ? Sanitizer.cleanDisabilityCertificateNumberLV(value) : value;
    }

    public static cleanDisabilityCertificateNumberLV(value: string): string {
        return value.toString().replace(/[^0-9]$/g, '');
    }

    public static cleanInputNumber(value: string): string {
        return value.toString().replace(',', '.').replace(/[^0-9.]/, '');
    }

    public static cleanInputPositiveNumber(value: number, positiveOnly: boolean = false): number {
        const data = value.toString().replace(/[^0-9'".]/, '');
        let newValue: number = (data) ? Number(data) : 0;
        newValue = newValue < 0 ? 0 : newValue;

        return newValue;
    }

    public static cleanName(value: string): string {
        return value.toString()
            .replace(/(?:^\s+)|[0-9_!¡÷?¿/\\+=.,@#$%ˆ^&*"'`(){}|~<>;:[\]]/g, '')
            .replace(/\s{2,}/g, ' ')
            .replace(/(?![ -])[^\p{Script=Latin}]/gu, '');
    }

    public static cleanBicycleModel(value: string): string {
        const cleanValue: string = value.toString()
            .replace(/(?![ \-.,#(){}[\]'"0-9\\/])[^\p{Script=Latin}]/gu, '')
            .substring(0, Sanitizer.BicycleModelMaxSymbols);

        return useStrings().capitalizeAll(cleanValue);
    }

    public static cleanUppercaseName(value: string): string {
        return Sanitizer.cleanName(value).toUpperCase();
    }

    public static cleanNameLt(value: string): string {
        return value.toString().replace(
            /(?:^\s+)|[^ĄąĘęŪūČŠŽčšžĖėĮįŲųABCDEFGHIJKLMNOPRSTUVYZabcdefghijklmnoprstuvyz ]/g,
            ''
        ).replace(/\s{2,}/g, ' ');
    }

    public static cleanEmail(value: string): string {
        return value.toString().replace(/[^A-Za-z0-9._%+-@]/g, '').toLowerCase();
    }

    public static cleanPhone(value: string): string {
        return value.toString().replace(/[^0-9+]/g, '');
    }

    public static spreadTime(value: string): string {
        return value.length === 1 ? '0' + value : value;
    }

    public static cleanTime(value: string): string {
        return value.toString().replace(/[^0-9:]/g, '');
    }

    public static cleanTimeHours(value: string): string {
        return value.toString().replace(/(^[0-1]\D)|^((2[^0-3])|([3-9]\d))$/g, '');
    }

    public static cleanTimeMinutes(value: string): string {
        return value.toString().replace(/^([^0-5]?([^0-5]\d))$/g, '');
    }

    public static cleanPersonCode(value: string, localeIso: string = new AppCountry().iso()): string {
        return localeIso === 'LV' ? Sanitizer.cleanPersonCodeLV(value) : value;
    }

    public static cleanPersonCodeLV(value: string): string {
        let result: string = value.toString()
            .replace(/[^0-9-]/g, '');
        const match: RegExpMatchArray | null = result.match(new RegExp('.{1,6}', 'g'));
        if (match) {
            result = match.join('-').replace(/(-+)/g, '-');
        }

        return result.substring(0, Sanitizer.IdentityNumberLvMaxDigits);
    }

    public static cleanPostalCode(value: string): string {
        let cleanValue: string = Sanitizer.cleanInputInteger(value);
        const cleanValueMaxLength: number = 5;
        switch (new AppCountry().iso()) {
            case 'EE':
                if (cleanValue.charAt(0) === '0') {
                    cleanValue = cleanValue.substring(1);
                }
                if (cleanValue.length > cleanValueMaxLength) {
                    cleanValue = cleanValue.substring(0, cleanValueMaxLength);
                }
                break;
            default:
        }

        return cleanValue;
    }

    public static cleanInputInteger(value: string): string {
        const definedValue: string = typeof value !== 'undefined' && value !== null ? value : '';
        return definedValue.toString().replace(/[^\d]/g, '');
    }

    public static cleanNumber(value: string): string {
        const result: string = value ? value.toString()
            .replace(/,+/, '.')
            .replace(/\.+/, '.')
            .replace(/[^0-9.]|\.(?=.*\.)/, '')
            .replace(/^(\d+.?\d{0,2})\d*$/, '$1') : '';

        return result === '.' ? '0.00' : result;
    }

    public static cleanDay(value: string): string {
        return value.toString()
            .replace(/\D/g, '')
            .substr(0, Sanitizer.DayMaxDigits);
    }

    public static cleanMonth(value: string): string {
        return value.toString()
            .replace(/\D/g, '')
            .substr(0, Sanitizer.MonthMaxDigits);
    }

    public static cleanYear(value: string): string {
        const cleanYear: string = value.toString().replace(/[^0-9]$/g, '');
        const substringMax: number = Sanitizer.YearMaxDigits;

        return cleanYear.substr(0, substringMax);
    }

    public static cleanCardHolderName(value: string): string {
        const repeatableSpecialCharacters: RegExp = /([-.'`~ ])\1+/gm;
        const leadingSpecialCharacters: RegExp = /^[-.'`~ ]/gm;
        const invalidCharacters: RegExp = /(?![-.'`~ ])[^\p{Script=Latin}]/gu;

        return value.replace(repeatableSpecialCharacters, '$1')
            .replace(leadingSpecialCharacters, '')
            .replace(invalidCharacters, '');
    }

    public static cleanSearch(value: string): string {
        return value.toString().replace(/[_!¡?÷?¿\\+=@#$%ˆ^&*(){}|~<>;:[\]]/g, '');
    }

    public static cleanPolicyNumber(value: string): string {
        return value.toString().toUpperCase().replace(/[^A-Za-z-\d]/g, '');
    }

    public static cleanBankAccountNumber(value: string): string {
        return value.toString().replace(/[^A-Za-z0-9]+/g, '');
    }

    public static cleanIMEINumber(value: string): string {
        return value.toString()
            .replace(/[^0-9]+/g, '')
            .substring(0, Sanitizer.IMEIMaxSymbols);
    }

    public static cleanCaption(value: string): string {
        return value.toString()
            .substring(0, Sanitizer.CaptionMaxSymbols);
    }

    public static cleanFileName(value: string): string {
        return value.toString()
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\//g, '&#47;')
            .replace(/\\/g, '&#92;');
    }

    public static cleanIdentityNumberNonBaltic(value: string): string {
        return value.toString()
            .replace(/[^\p{Script=Latin}0-9-/+.\\ ]/gu, '')
            .substring(0, Sanitizer.IdentityNumberNonBalticMaxDigits);
    }
}
