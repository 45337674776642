import DataLayer from '@/services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useBase} from '@/Composables/Base';

export default class PropertyDataLayer {
    public pushDataLayer(newDataLayerParams: DynamicDictionary): void {
        const dataLayerParams: DynamicDictionary = newDataLayerParams;
        const dataLayer: DataLayer = DataLayer.getInstance();
        dataLayer
            .useCheckout()
            .addActionParam('step', useBase().step)
            .addActionParam('option', DataLayerType.ProductTypeProperty)
            .newProduct()
            .addProductParam('name', DataLayerType.PropertyInsurance)
            .addProductParam('id', DataLayerType.PropertyProductFakeId)
            .addProductParam('category', DataLayerType.PropertyInsurance)
            .addProductParam('coupon', '')
            .addProductParam('price', dataLayerParams.price)
            .addProductParam('quantity', dataLayerParams.quantity)
            .addProductParam('variant', dataLayerParams.variant)
            .addProductParam('dimension2', dataLayerParams.dimension2)
            .addProductParam('dimension9', dataLayerParams.dimension9)
            .addProductParam('dimension10', dataLayerParams.dimension10)
            .addProductParam('dimension14', dataLayerParams.dimension14)
            .addProductParam('dimension15', dataLayerParams.dimension15)
            .addProductParam('metric4', dataLayerParams.metric4)
            .buildAndPush();
    }
}
