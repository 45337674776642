<script setup lang="ts">
import {onMounted, ref, Ref} from 'vue';
import {useDefine} from '@/Composables/Define';

const props = defineProps({
    tabs: {type: String, default: ''},
    items: {type: String, default: ''},
    defaultSelect: {type: String, default: ''},

});
const ExternalUrl: string = 'www';
const selectedTab: Ref<string> = ref('');

onMounted((): void => {
    selectedTab.value = props.defaultSelect;
});

function onSelectTab(tabYear: string): void {
    selectedTab.value = tabYear;
}

function fileExtension(file: any): string {
    return useDefine().isExternalLink(file)
        ? ExternalUrl
        : file.split('.').pop();
}
</script>
<template>
    <div class="wrapper">
        <section class="tabs-buttons">
            <div v-if="tabs">
                <button class="tab"
                        v-for="tab in JSON.parse(tabs)"
                        :key="tab"
                        :class="{ active: selectedTab === tab}"
                        @click="onSelectTab(tab)">{{ tab }}
                </button>
            </div>
        </section>
        <section v-for="(items, year) in JSON.parse(items)"
                 class="tab-list"
                 :id="year"
                 :key="year"
                 v-show="year === selectedTab">
            <section v-for="(item, index) in items"
                     class="item"
                     :key="index">
                <div class="left">
                    <a :href="item.file" class="download">
                        <div class="icon">
                        <span v-if="item.file" class="file">
                            {{ fileExtension(item.file) }}
                        </span>
                            <span class="arrow">
                            <svg width="14"
                                 height="18"
                                 viewBox="0 0 14 18"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 11L7 17L13 11"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round">
                                </path>
                                <path d="M7 15V1" stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round">
                                </path>
                            </svg>
                        </span>
                        </div>
                        <div v-if="item.title" class="text opacity">
                        <span v-if="item.title">
                            {{ item.title }}
                        </span>
                            <span v-if="item.description" class="description">
                            {{ item.description }}
                        </span>
                        </div>
                    </a>
                </div>
            </section>
        </section>
    </div>
</template>
