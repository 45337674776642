import {MovableProperty} from '@/interfaces/resources/MovableProperties/MovablePropertyInterface';
import {CoverageRisk} from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';

export class CoveredPopupBuilder {
    private type: string = '';
    private title: string = '';
    private content: MovableProperty[] | null = null;
    private excludedRisks: string[] = [];
    private selectedRisks: CoverageRisk[] = [];
    private coveragePlanKey: number = 0;
    private mode: string = '';
    private onSelectClick!: Function;
    private translationType: string = '';

    public withCoveredType(type: string): CoveredPopupBuilder {
        this.type = type;

        return this;
    }

    public withCoveredTitle(title: string): CoveredPopupBuilder {
        this.title = title;

        return this;
    }

    public withContent(data: MovableProperty[]): CoveredPopupBuilder {
        this.content = data;

        return this;
    }

    public withExcludedRisks(risks: string[]): CoveredPopupBuilder {
        this.excludedRisks = risks;

        return this;
    }

    public withTranslationKey(key: string): CoveredPopupBuilder {
        this.translationType = key;

        return this;
    }

    public withCoveragePlanKey(key: number): CoveredPopupBuilder {
        this.coveragePlanKey = key;

        return this;
    }

    public withOnSelectClick(callback: Function): CoveredPopupBuilder {
        this.onSelectClick = callback;

        return this;
    }

    public withSelectedRisks(risks: CoverageRisk[]): CoveredPopupBuilder {
        this.selectedRisks = risks;

        return this;
    }

    public withMode(mode: string): CoveredPopupBuilder {
        this.mode = mode;

        return this;
    }

    public build(): CoveredPopupBuilder {
        return this;
    }
}
