import ProductBlockItem from '@/Apps/SolarPanels/Interfaces/ProductBlockItemInterface';
import DiscountTooltip from '@/Apps/SolarPanels/Interfaces/DiscountTooltipInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ProductBlockItemButton from '@/Apps/SolarPanels/Interfaces/ProductBlockItemButtonInterface';
import ProductBlockItemButtonType from '@/Apps/SolarPanels/Enums/ProductBlockItemButtonTypeEnum';
import ProductBlockItemButtonColor from '@/Apps/SolarPanels/Enums/ProductBlockItemButtonColorEnum';

export default class ProductBlockItemBuilder {
    private item: ProductBlockItem = new class implements ProductBlockItem {
        public id: string = '';
        public badgeText: string = '';
        public productName: string = '';
        public discountSum: number = 0;
        public coverageTitle: string = '';
        public discountTooltip: DiscountTooltip = {
            title: '',
            description: '',
        };
        public description: string = '';

        public buttonSelect: ProductBlockItemButton = new class implements ProductBlockItemButton {
            public title: string = '';
            public type: ProductBlockItemButtonType = ProductBlockItemButtonType.WithArrow;
            public color: ProductBlockItemButtonColor = ProductBlockItemButtonColor.Red;
        };
        public buttonCustom: ProductBlockItemButton = new class implements ProductBlockItemButton {
            public title: string = '';
            public type: ProductBlockItemButtonType = ProductBlockItemButtonType.WithShield;
            public color: ProductBlockItemButtonColor = ProductBlockItemButtonColor.Outside;
        };
        public dynamicContent: DynamicDictionary = {};
        public dynamicComponent: string = '';
        public defaultOnMobile: boolean = false;
    }

    public withProductId(id: string): this {
        this.item.id = id;

        return this;
    }

    public withBadgeText(badgeText: string): this {
        this.item.badgeText = badgeText;

        return this;
    }

    public withProductName(productName: string): this {
        this.item.productName = productName;

        return this;
    }

    public withDiscountSum(discountSum: number): this {
        this.item.discountSum = discountSum;

        return this;
    }

    public withDiscountTooltip(value: DiscountTooltip): this {
        this.item.discountTooltip = value;

        return this;
    }

    public withDynamicContent(component: string, data: DynamicDictionary | DynamicDictionary[]): this {
        this.item.dynamicComponent = component;
        this.item.dynamicContent = data;

        return this;
    }

    public withButtonSelectTitle(title: string): this {
        this.item.buttonSelect.title = title;

        return this;
    }

    public withButtonSelectColor(color: ProductBlockItemButtonColor): this {
        this.item.buttonSelect.color = color;

        return this;
    }

    public withButtonSelectType(type: ProductBlockItemButtonType): this {
        this.item.buttonSelect.type = type;

        return this;
    }

    public withButtonCustomTitle(title: string): this {
        this.item.buttonCustom.title = title;

        return this;
    }

    public withButtonCustomColor(color: ProductBlockItemButtonColor): this {
        this.item.buttonCustom.color = color;

        return this;
    }

    public withButtonCustomType(type: ProductBlockItemButtonType): this {
        this.item.buttonCustom.type = type;

        return this;
    }

    public withCoverageTitle(title: string): this {
        this.item.coverageTitle = title;

        return this;
    }

    public withDescription(description: string): this {
        this.item.description = description;

        return this;
    }

    public asDefaultOnMobile(): this {
        this.item.defaultOnMobile = true;

        return this;
    }

    public build(): ProductBlockItem {
        return this.item;
    }
}
