import FormField from './form-field';
import {merge, Subject, Subscription} from 'rxjs';

export default class Form {
    public onValidation: Subject<void> = new Subject();
    public onTouch: Subject<void> = new Subject();
    public onTouchChange: Subject<FormField> = new Subject();
    private localFields: FormField[] = [];
    private localIsInputLocked: boolean = false;
    private localIsReady: boolean = false;
    private formName: string = '';
    private fieldSubscriptions: { [fieldName: string]: Subscription } = {};

    public addField(formField: FormField): void {
        if (!this.exists(formField.name)) {
            this.localFields.push(formField);
            this.fieldSubscriptions[formField.name] = merge(
                formField.onTouch,
                formField.onUntouched,
                formField.onClear,
            ).subscribe((): void => {
                this.onTouchChange.next(formField);
            });
        }
    }

    public fields(): FormField[] {
        return this.localFields;
    }

    public removeFields(): void {
        Object.keys(this.fieldSubscriptions).forEach((fieldName: string): void => {
            this.fieldSubscriptions[fieldName]?.unsubscribe();
        })
        this.localFields = [];
    }

    public removeRow(rowIndex: number, indexDelimiter: string = '_'): void {
        this.removeFieldsByIndex(rowIndex, indexDelimiter).then((): void => {
            this.remapIndexedFieldNames(indexDelimiter);
        });
    }

    public invalidRows(indexDelimiter: string = '_'): number[] {
        const invalidRows: number[] = this.localFields
            .filter((field: FormField): boolean =>
                !field.isValid && field.isTouched && field.name.includes(indexDelimiter))
            .map((field: FormField): number => Number(field.name.substring(field.name.indexOf(indexDelimiter) + 1)));

        return [...new Set(invalidRows)];
    }

    public create(formName: string = ''): Form {
        this.formName = formName !== '' ? formName : 'form-' + String(Math.random()).replace('.', '');

        return this;
    }

    public field(fieldName: string): FormField {
        return this.fields().find(field => field.isThisField(fieldName)) || new FormField('', '', '');
    }

    public exists(fieldName: string): boolean {
        return this.fields().some(field => field.name === fieldName);
    }

    public isReady(): boolean {
        return this.localIsReady;
    }

    public get ready(): boolean {
        return this.localIsReady;
    }

    public isValid(): boolean {
        return !this.fields().some(field => !field.isValid);
    }

    public get valid(): boolean {
        return !this.fields().some(field => !field.isValid);
    }

    public isTouched(): boolean {
        return this.fields().some(field => field.isTouched);
    }

    public isInputLocked(): boolean {
        return this.localIsInputLocked;
    }

    public markAsUntouched(): Promise<void[]> {
        return Promise.all(this.fields().map(field => field.markAsUntouched()));
    }

    public markAsFresh(): Promise<void[]> {
        return Promise.all(this.fields().map(field => field.markAsFresh()));
    }

    public sanitize(): void {
        this.fields().forEach(field => field.sanitize());
    }

    public clear(): Promise<void> {
        return Promise.all(this.fields().map(field => field.clear()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {
            });
    }

    public setReady(): void {
        this.localIsReady = true;
    }

    public lockInput(): void {
        this.localIsInputLocked = true;
    }

    public unlockInput(): void {
        this.localIsInputLocked = false;
    }

    public validate(): Promise<void> {
        return Promise.all(this.fields().map(field => field.validate()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {
            });
    }

    public touch(): Promise<void> {
        return new Promise(resolve => {
            this.fields().forEach(field => field.touch());
            resolve();
        });
    }

    public get name(): string {
        return this.formName;
    }

    private removeFieldsByIndex(rowIndex: number, indexDelimiter: string): Promise<void> {
        return new Promise(resolve => {
            const fieldsToRemove: FormField[] = this.localFields
                .filter((field: FormField): boolean =>
                    field.name.substring(field.name.indexOf(indexDelimiter)) === indexDelimiter + rowIndex);
            fieldsToRemove.forEach((targetField: FormField): void => {
                this.fieldSubscriptions[targetField.name]?.unsubscribe();
                const indexToRemove: number = this.localFields
                    .findIndex((field: FormField): boolean => field.name === targetField.name);
                if (indexToRemove >= 0) {
                    this.localFields.splice(indexToRemove, 1);
                }
            });
            resolve();
        });
    }

    private remapIndexedFieldNames(indexDelimiter: string): void {
        const currentIndexes: string[] = this.localFields
            .filter((field: FormField): boolean => field.name.includes(indexDelimiter))
            .map((field: FormField) => field.name.substring(field.name.indexOf(indexDelimiter)));
        const uniqueIndexes: string[] = [...new Set(currentIndexes)];
        uniqueIndexes.forEach((uniqueIndex: string, index: number): void => {
            this.localFields
                .filter((field: FormField): boolean =>
                    field.name.substring(field.name.indexOf(indexDelimiter)) === uniqueIndex)
                .forEach((field: FormField): void => {
                    field.name = field.name.replace(uniqueIndex, indexDelimiter + index);
                });
        });
    }
}
