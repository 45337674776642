<script lang="ts">
import Vue, {computed, defineComponent, reactive, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {VueApp} from '@/Types/VueAppType';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import Translations from '@/services/translations.service';
import {Language} from '@/interfaces/LanguageInterface';
import {Subscription} from 'rxjs';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import moment from 'moment/moment';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useDefine} from '@/Composables/Define';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import FormField from '@/assets/libraries/form/form-field';
import {useClipboard} from '@/Composables/Clipboard';

const {copyToClipboard} = useClipboard();
export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const getInTouchModule: VueApp = new Vue(GetInTouchModule);

        const request: AxiosParams = useAxios();
        const {validResponse} = useDefine();

        const CurrentStep: number = 1;
        const Facility: string = 'flash-payment-generate';

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const linkCreated: Ref<boolean> = ref(false);
        const languages: Ref<Language[]> = ref([]);

        const clearButtonIsDisabled: Ref<boolean> = computed(() => {
            return form.field('documentNumber').isEmpty() &&
                form.field('paymentAmount').isEmpty() &&
                (
                    form.field('paymentTerm').isEmpty() ||
                    form.field('paymentTerm').value.startDate === ''
                );
        });

        function setupForm(): void {
            form.addField(new FormField('documentNumber', '', 'required'));
            form.addField(new FormField('paymentAmount', '', {
                oneOfFieldsIsNotEmpty: () => {
                    if (!form.field('paymentAmount').isEmpty()) {
                        const regexp: RegExp = /^\d+(\.\d{1,2}|)$/;
                        const amount: string = form.field('paymentAmount').value;
                        return regexp.test(amount);
                    }
                    return false;
                }
            }));
            form.addField(new FormField('paymentTerm', ''));
            form.addField(new FormField('paymentLanguage', 'ee', 'required'));
            form.addField(new FormField('paymentLink', ''));
            form.addField(new FormField('usePaymentCard'));
            form.setReady();
            formIsReady.value = true;
        }

        function setDefaultLanguage(): void {
            form.field('paymentLanguage').patch(Translations.getInstance().language);
        }

        function generateLink(): void {
            Vue.nextTick(() => {
                btaBase.lockInput(true);
                PopupService.getInstance().show(new OnePopup().withType().loading);
                linkCreated.value = false;
            });
            const params: Record<string, null | number | string> = {
                documentNumber: form.field('documentNumber').value.trim(),
                paymentAmount: form.field('paymentAmount').value,
                paymentLanguage: form.field('paymentLanguage').value,
                paymentTerm: form.field('paymentTerm').value
                    ? moment(form.field('paymentTerm').value.startDate).unix() : null,
                usePaymentCard: form.field('usePaymentCard').value,
            };
            request.post(Url.Ajax.flashPaymentsGenerate, params).then((value: any) => {
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
                if (validResponse(value) && value.data.data.status === 'OK') {
                    const link: string = value.data.data.body.paymentLink;
                    form.field('paymentLink').patch(link);
                    linkCreated.value = true;
                } else {
                    throw value;
                }
            }).catch((reason: any) => {
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
                btaBase.error.show(ErrorType.Error, 'flashPaymentGenerateLink', reason);
            });
        }

        function clear(): void {
            form.field('documentNumber').clear();
            form.field('paymentAmount').clear();
            form.field('paymentTerm').clear();
            form.field('paymentLink').clear();
            linkCreated.value = false;
        }

        const copyLink = (): void => {
            copyToClipboard(form.field('paymentLink').value);
        }

        function languagesInputOptions(): InputOption[] {
            return languages.value.map((item: Language) => new InputOptionBuilder()
                .setValue(item.id)
                .setName(item.title)
                .build());
        }

        function employeeLogin(url: string): void {
            window.location.href = url;
        }

        function employeeLogout(): void {
            Vue.nextTick(() => {
                btaBase.lockInput(true);
                PopupService.getInstance().show(new OnePopup().withType().loading);
                linkCreated.value = false;
            });
            request.post(Url.Ajax.flashPaymentsLogout, {}).then((value: any) => {
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
                if (validResponse(value) && value.data.data.status === 'OK') {
                    btaBase.reload();
                } else {
                    throw value;
                }
            }).catch((reason: any) => {
                btaBase.lockInput(false);
                PopupService.getInstance().hide();
                btaBase.error.show(ErrorType.Error, 'flashPaymentGenerateLink', reason);
            });
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                getInTouchModule,
                form,
                formIsReady,
                linkCreated,
                languages,
                setupForm,
                setDefaultLanguage,
                generateLink,
                clear,
                copyLink,
                languagesInputOptions,
                employeeLogin,
                employeeLogout,
                clearButtonIsDisabled,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        const subscription: Subscription = this.onExternalDataIsReady.subscribe(() => {
            this.setDefaultLanguage();
            subscription.unsubscribe();
        });
    }
});
</script>
