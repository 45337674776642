import Form from '@/assets/libraries/form/form';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {reactive, ref, Ref} from 'vue';

export const useAutoForms = (): AutoForms => {
    const form: UnwrapNestedRefs<Form> = reactive(new Form());
    const reminderForm: UnwrapNestedRefs<Form> = reactive(new Form());
    const vehicleProduct: UnwrapNestedRefs<Form> = reactive(new Form());
    const fieldsPopupForm: UnwrapNestedRefs<Form> = reactive(new Form());
    const missingParamsForm: UnwrapNestedRefs<Form> = reactive(new Form());
    const formsAreReady: Ref<boolean> = ref(false);

    return {
        form,
        reminderForm,
        vehicleProduct,
        fieldsPopupForm,
        missingParamsForm,
        formsAreReady,
    }
}

export interface AutoForms {
    form: UnwrapNestedRefs<Form>;
    reminderForm: UnwrapNestedRefs<Form>;
    vehicleProduct: UnwrapNestedRefs<Form>;
    fieldsPopupForm: UnwrapNestedRefs<Form>;
    missingParamsForm: UnwrapNestedRefs<Form>;
    formsAreReady: Ref<boolean>;
}
