<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import {ref, computed, onMounted, PropType, reactive, Ref, watch} from 'vue';
import Translations from '@/services/translations.service';
import OptionValue from '@/interfaces/option.value.interface';
import OptionsListItem from '@/interfaces/options.list.item.interface';
import Popup from '@/services/popup.service';
import VueEvent from '@/Classes/VueEventClass';
import Countries from '@/services/countries.service';
import Country from '@/interfaces/country.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import {CountryComponentParams} from '@/Components/InputCountry/CountryComponentParams';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppCountry from '@/assets/libraries/app/app-country';

const props = defineProps({
    placeholder: {type: String, default: () => ''},
    formField: {type: Object as PropType<FormField<CountryComponentParams>>, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    popupLabel: {type: String, default: ''},
    iconPattern: {type: String, default: '/images/one/flags/%.png'},
    showValue: {type: String, default: 'country'},
    showCustomPopup: {type: Boolean, default: true},
    patchDefaultCountry: {type: Boolean, default: true},
    includeDefaultCountry: {type: Boolean, default: true},
});

const emit = defineEmits(['change', 'input', 'close']);

const translations: Translations = Translations.getInstance();
const popup: Popup = Popup.getInstance();

const country: OptionValue = reactive({
    id: ':void:',
    value: '',
});
const isVisibleDropdown: Ref<boolean> = computed(() => {
    return typedValue.value !== '' || inputInFocus.value
});
const countryData: Ref<CountryComponentParams> = computed(() => {
    const parts: string[] = String(country.id).split(':');
    return {
        iso: parts[1] || '',
        phoneCode: parts[2] || '',
        ic: parts[0] || ''
    };
});
const isValueSelected: Ref<boolean> = computed(() => {
    return country.id && country.value === '';
});
const isEmptyValue: Ref<boolean> = computed(() => {
    return !props.formField.value.iso && !props.formField.value.phoneCode;
});
let visible: Ref<boolean> = ref(false);
let typedValue: Ref<string> = ref('');
let optionsTitleTipHeader: Ref<string> = ref('');
let optionsTitleTipBody: Ref<string> = ref('');
let options: Ref<OptionsListItem[]> = ref([]);
let index: number = 0;
let inputInFocus: Ref<boolean> = ref(false);
let countries: DynamicDictionary[] = Countries.getInstance().fetchCountries();

onMounted((): void => {
    applyCountryCode();
});

watch(() => country.id, () => {
    props.formField?.setValue(countryData.value);
    props.formField.touch();
});

watch(() => props.formField.value, () => {
    if (isEmptyValue.value) {
        props.formField?.setValue({
            ic: '',
            phoneCode: '',
            iso: '',
        });
    }
    applyCountryCode();
    props.formField.validate();
    emit('change');
});

function phoneFlagIcon(): string {
    return mappedIcon(country.id, props.iconPattern);
}

function showListSearch(event: VueEvent): void {
    if (!props.disabled) {
        prepareOptionsList(event, true);
    }
}

function isVisibleItem(current: OptionsListItem): boolean {
    let result: boolean = false;
    if (typedValue.value) {
        const normalizedTitle: string = Countries.normalizedSearchCountry(current.title);
        result = normalizedTitle.includes(Countries.normalizedSearchCountry(typedValue.value));
    } else if (inputInFocus.value) {
        result = current.index < options.value.length;
    }

    return result;
}

function onFocus(event: VueEvent): void {
    inputInFocus.value = true;
}

function onOptionSelectClick(event: VueEvent): void {
    country.id = event.data('id');
    country.value = event.data('value');
    close();
}

function closePopup(): void {
    close();
}

function mappedIcon(idValue: string, iconPattern: string): string {
    const valueParts: string[] = String(idValue).split(':');
    const countryIso: string = String(valueParts[1]).toLowerCase();
    const pathParts: string[] = iconPattern.split('%');

    return pathParts[0] + countryIso + pathParts[1];
}

function applyCountryCode(): void {
    let pattern: string = ':' + translations.countryIso + ':';
    if (!isEmptyValue.value) {
        if (props.formField.value.iso) {
            pattern = ':' + props.formField.value.iso + ':';
        } else if (props.formField.value.phoneCode) {
            pattern = ':' + parseInt(props.formField.value.phoneCode, 10) + ':';
        }
    }
    const localCountry: Country = getCountryByPartialPattern(pattern, countries);
    if (props.patchDefaultCountry) {
        updateLocalCountry(localCountry);
    } else {
        if (localCountry.iso !== new AppCountry().iso()) {
            updateLocalCountry(localCountry);
        }
    }
}

function updateLocalCountry(value: Country): void {
    country.id = value.code + ':' + value.iso + ':' + value.phoneCode;
    country.value = value.name;
}

function getCountryByPartialPattern(pattern: string, countries: DynamicDictionary[]): Country {
    let country: Country = new class implements Country {
        code: string = '';
        iso: string = '';
        name: string = '';
        phoneCode: string = '';
    };
    pattern = String(pattern).toUpperCase();
    for (let o in countries) {
        if (String(o).includes(pattern)) {
            let parts: string[] = String(o).split(':');
            country.code = parts[0];
            country.iso = parts[1];
            country.phoneCode = parts[2];
            country.name = String(countries[o]);
        }
    }

    return country;
}

function prepareOptionsList(event: VueEvent, withSearch: boolean = false): void {
    const tipHeader: string = event.data('tip-header') as string;
    const tipBody: string = event.data('tip-body') as string;
    clear();
    if (tipHeader && tipBody) {
        setTip(tipHeader, tipBody);
    }
    for (let o in countries) {
        let icon: string = mappedIcon(o, props.iconPattern);
        if (props.includeDefaultCountry) {
            addItem(o, String(countries[o]), icon);
        } else {
            if (!isDefaultCountry(o)) {
                addItem(o, String(countries[o]), icon);
            }
        }
    }
    typedValue.value = '';
    visible.value = true;
    if (props.showCustomPopup) {
        popup.showPopup(PopupType.CustomPopup);
    }
}

function clear(): void {
    optionsTitleTipHeader.value = '';
    optionsTitleTipBody.value = '';
    options.value = [];
    index = 0;
}

function addItem(id: string, title: string, icon: string = ''): void {
    const itemIndex: number = index++;
    options.value.push(
        new class implements OptionsListItem {
            id: string = id;
            title: string = title;
            icon: string = icon;
            index: number = itemIndex;
        }
    );
}

function setTip(header: string, body: string): void {
    optionsTitleTipHeader.value = header;
    optionsTitleTipBody.value = body;
}

function close(): void {
    visible.value = false;
    if (props.showCustomPopup) {
        popup.showPopup(PopupType.None);
    }
    emit('close');
}

function isDefaultCountry(country: string): boolean {
    let result: boolean;
    switch (countryIc(country)) {
        case '100000000':
            result = translations.countryIso === 'LV';
            break;
        case 'EST':
            result = translations.countryIso === 'EE';
            break;
        case 'LTU':
            result = translations.countryIso === 'LT';
            break;
        default:
            result = false;
    }

    return result;
}

function countryIc(fullCountryParams: string): string {
    const parts: string[] = fullCountryParams.split(':');

    return parts[0];
}

function onDropDownClickOutside(): void {
    if (inputInFocus.value) {
        inputInFocus.value = false;
    }
}
</script>

<template>
    <div class="input input-country"
         :id="formField.name"
         :class="formField.classes()"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div v-if="label" class="label informative">
            <p>{{ label }}</p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper select">
            <div class="current button cursor-pointer"
                 :id="formField.name + '-showListSearch'"
                 @click="showListSearch(new VueEvent($event))">
                <div class="flag">
                    <img v-if="formField.value.phoneCode !== undefined && !isValueSelected"
                         width="22"
                         height="16"
                         alt=""
                         :src="phoneFlagIcon()"
                    />
                </div>
                <div v-if="showValue === 'phone-code' && !isValueSelected" class="num">+
                    {{ formField.value.phoneCode || country.id.split(':')[2] }}
                </div>
                <div v-if="showValue === 'country' && !isValueSelected" class="text">{{ country.value }}</div>
                <div v-if="isValueSelected" class="text placeholder">{{ placeholder }}</div>
                <span class="icon arrow-icon">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
            </div>
        </div>
        <div class="popups">
            <app-popup v-if="visible" class="simple list-search" v-on:close="closePopup()">
                <div class="title">
                    {{ popupLabel || label }}
                    <app-tooltipster v-if="optionsTitleTipHeader || optionsTitleTipBody"
                                     :title="optionsTitleTipHeader"
                                     :description="optionsTitleTipBody">
                    </app-tooltipster>
                </div>
                <div class="elements">
                    <div class="field"
                         v-click-outside @click-outside="onDropDownClickOutside">
                        <div class="added"></div>
                        <div class="search">
                            <div class="icon">
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.48">
                                        <path d="M14 14L20 20" stroke="#9297A0" stroke-width="2"></path>
                                        <circle cx="8.5" cy="8.5" r="7.5" stroke="#9297A0"
                                                stroke-width="2"></circle>
                                    </g>
                                </svg>
                            </div>
                            <input
                                :id="formField.name + '-typedValue'"
                                v-model="typedValue"
                                @focus="onFocus(new VueEvent($event))"
                                class="text"
                                :placeholder="placeholder"/>
                        </div>
                        <div class="dropdown" :class="{hidden:!isVisibleDropdown}">
                            <button v-for="(item, index) in options"
                                    :key="index"
                                    class="country-item"
                                    :id="formField.name + '-dropdown-' + item.id"
                                    :class="{hidden:!isVisibleItem(item)}"
                                    :data-value="item.title"
                                    :data-id="item.id"
                                    @click="onOptionSelectClick(new VueEvent($event))">
                                <img class="icon"
                                     v-if="item.icon"
                                     :src="item.icon"
                                     alt="">
                                <span class="text">{{ item.title }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </app-popup>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-country {
    > .wrapper {
        > .current > .flag {
            margin-right: var(--size-pico);
        }

        .placeholder {
            opacity: .56;
            color: var(--text-color-subtlest);
        }
    }

    &.error {
        .current {
            border-color: var(--system-color-error-default);
        }
    }

    .popups .single-popup.list-search > .wrapper .elements .field .search .text {
        background-color: var(--component-color-background-base);
    }
}
</style>
