<script setup lang="ts">
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import LocationSearchTypes from '@/Enums/LocationSearchTypesEnum';
import FormField from '@/assets/libraries/form/form-field';
import Form from '@/assets/libraries/form/form';
import OptionWithToken from '@/interfaces/option.with.token.interface';
import axios, {CancelTokenSource} from 'axios';
import Address from '@/services/address.service';
import SettingsService from '@/services/settings.service';
import {Subscription} from 'rxjs';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import PropertyEnum from '@/Enums/PropertyEnum';
import {computed, onBeforeUpdate, onMounted, onUnmounted, reactive, ref, Ref, watch} from 'vue';
import {useDefine} from '@/Composables/Define';
import {useDebounce} from '@/Composables/Debounce';
import {useTranslate} from '@/Composables/Translate';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {InputOption} from '@/interfaces/InputOptionInterface';

const props = defineProps({
    componentName: {type: String, default: 'AddressFinder'},
    translationType: {type: String, default: 'components'},
    dataStoreDisabled: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    label: {type: String, default: ''},
    addressLabel: {type: String, default: ''},
    hintLabel: {type: String, default: ''},
    formField: {type: FormField, default: () => new FormField('')},
    disableCountry: {type: Boolean, default: false},
    skipOwnValidation: {type: Boolean, default: false},
    skipApartmentValidation: {type: Boolean, default: false},
    disableManualInput: {type: Boolean, default: false},
    receiverCallback: {type: Function, default: null},
    useSettlement: {type: Boolean, default: false},
    mode: {type: String, default: ''},
    mobileModeEnabled: {type: Boolean, default: false},
    propertyType: {type: String, default: ''},
});
const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
const emit = defineEmits(['edit-click', 'disabled-change']);
const elements: DynamicDictionary = ref([])
const {isSet} = useDefine();
const {debounce} = useDebounce();
const {translate} = useTranslate();
const debounceWait: number = 1700;
const additionalApartment: Ref<HTMLInputElement | null> = ref(null);
const additionalPostal: Ref<HTMLInputElement | null> = ref(null);
const onPostalKeyup: Function = debounce((value: string) => onPostalBlur(value), debounceWait);
const onApartmentKeyup: Function = debounce((value: string) => onApartmentBlur(value), debounceWait);
const onDetailedKeypress: Function = debounce((value: string) => onDetailedBlur(value), debounceWait);
const PatchUpdate: string = 'patch';
const AddressWithApartment: string = 'M';
const address: Address = Address.getInstance();
const settingsService: SettingsService = SettingsService.getInstance();
const locations: UnwrapNestedRefs<DynamicDictionary> = reactive(new class implements DynamicDictionary {
    'country': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'county': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'municipality': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'region': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'city': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'parish': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'district': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'village': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
    'postal': OptionWithToken = new class implements OptionWithToken {
        options: InputOption[] = [];
        cancelToken: CancelTokenSource | null = null;
        fetchIsInProgress: boolean = false;
    };
});
const simpleAddressValue: Ref<DynamicDictionary> = ref({});
const simpleAddressInput: Ref<boolean> = ref(true);
const detailedFieldsAvailable: Ref<Array<string>> = ref([]);
const isPostalMode: Ref<boolean> = computed((): boolean => {
    return props.mode === 'postal';
});
const isApartmentMode: Ref<boolean> = computed((): boolean => {
    return props.mode === 'apartment';
});
const isMixedMode: Ref<boolean> = computed((): boolean => {
    return props.mode === 'mixed';
});
const isStretchMode: Ref<boolean> = computed((): boolean => {
    return props.mode === '';
});
const locationFetchIsInProgress: Ref<boolean> = computed((): boolean => {
    return Object.keys(locations).some(key => locations[key].fetchIsInProgress);
});
const form: UnwrapNestedRefs<Form> = reactive(new Form());
const additionalApartmentIsDisabled: Ref<boolean> = ref(false);
const additionalPostalIsDisabled: Ref<boolean> = ref(false);

let onExternalDataIsReadySubscription!: Subscription;
let onFormFieldPatchSubscription!: Subscription;
let onFormFieldTouchSubscription!: Subscription;

watch(() => field.value, () => {
    if (simpleAddressInput.value) {
        simpleAddressValue.value = field.value;
    }
    revalidate();
    field.onTouch.next(field.value);
});

watch(() => simpleAddressInput.value, (value) => {
    if (value) {
        revalidate();
    } else {
        resetSelects();
        props.disableCountry ? useAppCountry() : fetch(LocationSearchTypes.Country);
    }
});

watch(() => props.skipOwnValidation, () => {
    revalidate();
});

watch(() => props.mode, () => {
    revalidate();
});

watch(() => field.isRestored, () => {
    updateFormFieldValue(PatchUpdate);
});

onMounted((): void => {
    if (settingsService.ready) {
        init();
    } else {
        onExternalDataIsReadySubscription = settingsService.onExternalDataIsReady.subscribe(() => {
            init();
        });
    }
});

onUnmounted((): void => {
    if (onExternalDataIsReadySubscription) {
        onExternalDataIsReadySubscription.unsubscribe();
    }
    if (onFormFieldPatchSubscription) {
        onFormFieldPatchSubscription.unsubscribe();
    }
    if (onFormFieldTouchSubscription) {
        onFormFieldTouchSubscription.unsubscribe();
    }
});

function init(): void {
    setDetailedFieldsAvailable();
    setupForm();
    updateFormFieldValue(PatchUpdate);
    onFormFieldPatchSubscription = field.onPatch.subscribe(() => {
        updateFormFieldValue(PatchUpdate);
    });
    onFormFieldTouchSubscription = field.onTouch.subscribe(() => {
        form.touch();
    });
}

function setupForm(): void {
    form.addField(new FormField('address', '', addressFieldValidators()));
    form.addField(new FormField('additional-postal', '', postalCodeValidator(), Sanitizer.cleanPostalCode));
    form.addField(new FormField('additional-apartment', '', additionalApartmentValidator()));
    if (isFieldAvailable(LocationSearchTypes.Country)) {
        form.addField(new FormField(LocationSearchTypes.Country, '', locationValidators(LocationSearchTypes.Country)));
    }
    if (isFieldAvailable(LocationSearchTypes.County)) {
        form.addField(new FormField(LocationSearchTypes.County, '', locationValidators(LocationSearchTypes.County)));
    }
    if (isFieldAvailable(LocationSearchTypes.Municipality)) {
        form.addField(new FormField(LocationSearchTypes.Municipality, '', locationValidators(LocationSearchTypes.Municipality)));
    }
    if (isFieldAvailable(LocationSearchTypes.Region)) {
        if (appCountry().iso === 'LV') {
            form.addField(new FormField(LocationSearchTypes.Region, '', regionCityValidators()));
            form.addField(new FormField(LocationSearchTypes.Region, '', regionCityValidators()));
        } else {
            form.addField(new FormField(LocationSearchTypes.Region, '', locationValidators(LocationSearchTypes.Region)));
        }
    }
    if (isFieldAvailable(LocationSearchTypes.City)) {
        if (appCountry().iso === 'LV') {
            form.addField(new FormField(LocationSearchTypes.City, '', regionCityValidators()));
        } else {
            form.addField(new FormField(LocationSearchTypes.City, '', cityParishValidators()));
        }
    }
    if (isFieldAvailable(LocationSearchTypes.Parish)) {
        if (appCountry().iso === 'LV') {
            form.addField(new FormField(LocationSearchTypes.Parish, '', cityParishDistrictValidators()));
        } else {
            form.addField(new FormField(LocationSearchTypes.Parish, '', cityParishValidators()));
        }
    }
    if (isFieldAvailable(LocationSearchTypes.District)) {
        if (appCountry().iso === 'LV') {
            form.addField(new FormField(LocationSearchTypes.District, '', cityParishDistrictValidators()));
        } else {
            form.addField(new FormField(LocationSearchTypes.District, '', locationValidators(LocationSearchTypes.District)));
        }
    }
    if (isFieldAvailable(LocationSearchTypes.Village)) {
        form.addField(new FormField(LocationSearchTypes.Village));
    }
    if (isFieldAvailable(LocationSearchTypes.Postal)) {
        form.addField(new FormField(LocationSearchTypes.Postal, '', locationValidators(LocationSearchTypes.Postal)));
    }
    if (isFieldAvailable('postalSuggest')) {
        form.addField(new FormField('postalSuggest', '', locationValidators('postalSuggest')));
    }
    if (isFieldAvailable('detailed')) {
        form.addField(new FormField('detailed', '', locationValidators('detailed')));
    }
    field.addValidators({
        childFormIsValid: () => {
            return form.isValid();
        }
    });
    form.setReady();
}

function emitEditClick() {
    emit('edit-click', field.value);
}

function emitDisabledChange(isLocked: boolean) {
    emit('disabled-change', isLocked);
}

function url(): string {
    return props.useSettlement ? address.urlByName('suggestSettlement') : address.urlByName('suggest');
}

function additionalParams(): Object {
    return props.propertyType !== ''
        ? {propertyType: props.propertyType === PropertyEnum.Type.House ? 'B' : 'M'}
        : {};
}

function isLockedSelect(type: string): boolean {
    return locations[type].options.length === 0 || locationFetchIsInProgress.value;
}

function isFieldVisible(fieldName: string): boolean {
    return form.exists(fieldName)
        && (locations[fieldName].options.length > 0
            || locationByTypeFetchIsInProgress(fieldName));
}

function isFieldAvailable(fieldName: string): boolean {
    return detailedFieldsAvailable.value.indexOf(fieldName) !== -1;
}

function cantFindClick(): void {
    simpleAddressInput.value = !simpleAddressInput.value;
    if (simpleAddressInput.value && isSet(simpleAddressValue.value)) {
        field.patch(simpleAddressValue.value);
        form.field('address').patch({
            fullCode: simpleAddressValue.value.addressCode,
            label: simpleAddressValue.value.label,
            value: simpleAddressValue.value,
        });
        form.field('additional-postal').patch(simpleAddressValue.value.postCode);
        form.field('additional-apartment').patch(simpleAddressValue.value.apartment);
    } else {
        updateFormFieldValue();
    }
}

function updateFormFieldValue(param: any = null): void {
    form.validate().then(() => {
        if (param === PatchUpdate) {
            if (field.value !== '') {
                form.field('address').patch({
                    fullCode: field.value.addressCode,
                    label: field.value.label,
                    value: field.value.value,
                });
                form.field('detailed').patch(field.value.detailed);
                if (props.mode !== '') {
                    form.field('additional-postal').patch(field.value.postCode);
                    form.field('additional-apartment').patch(field.value.apartment);
                    form.field('detailed').patch('');
                }
            }
        } else {
            const lastSelectedAddressCodeFieldName = detailedFieldsAvailable.value.slice(0).reverse()
                    .find(field => [LocationSearchTypes.Postal, 'postalSuggest', 'detailed'].indexOf(field) === -1
                        && !form.field(field).isEmpty())
                || LocationSearchTypes.Country;
            const lastSelectedAddressCodeField = form.field(lastSelectedAddressCodeFieldName).value.fullCode
                ? form.field(lastSelectedAddressCodeFieldName).value
                : locations[lastSelectedAddressCodeFieldName].options.find((option: InputOption) => {
                return option.value === form.field(lastSelectedAddressCodeFieldName).value;
            }) || {};
            let postalCode = form.exists(LocationSearchTypes.Postal)
                ? form.field(LocationSearchTypes.Postal).value
                : form.field('postalSuggest').value.value;
            const formFieldValue: any = form.field('address').value;
            field.value = {
                label: formFieldValue.label,
                value: simpleAddressInput.value ? formFieldValue.value : lastSelectedAddressCodeField.value,
                addressCode: simpleAddressInput.value ? formFieldValue.fullCode : lastSelectedAddressCodeField.fullCode,
                addressName: simpleAddressInput.value ? formFieldValue.label : lastSelectedAddressCodeField.fullName,
                countryId: simpleAddressInput.value ? '' : form.field(LocationSearchTypes.Country).value,
                postCode: simpleAddressInput.value ? (formFieldValue.postalCode ? formFieldValue.postalCode : '') : postalCode,
                detailed: (simpleAddressInput.value && !props.useSettlement || !form.exists('detailed')) ? '' : form.field('detailed').value,
                apartment: simpleAddressInput.value
                    ? (isSet(formFieldValue.level)
                        ? apartment(formFieldValue.level, formFieldValue.description)
                        : '')
                    : '',
            };
            if (props.mode !== '') {
                onAdditionalApartmentChange(field.value.apartment);
                onAdditionalPostalChange(field.value.postCode);
            }
        }
        revalidate();
    });
}

function onSelectChange(type: string): void {
    updateFormFieldValue();
    switch (type) {
        case LocationSearchTypes.Country:
            resetSelects([
                LocationSearchTypes.County,
                LocationSearchTypes.Municipality,
                LocationSearchTypes.Region,
                LocationSearchTypes.Parish,
                LocationSearchTypes.District,
                LocationSearchTypes.City,
                LocationSearchTypes.Village,
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.County, form.field(LocationSearchTypes.Country).value);
            fetch(LocationSearchTypes.Region, form.field(LocationSearchTypes.Country).value);
            if (appCountry().iso === 'LV') {
                fetch(LocationSearchTypes.City, '');
            }
            break;
        case LocationSearchTypes.Region:
            if (appCountry().iso === 'LV') {
                if (form.field(LocationSearchTypes.Region).value) {
                    resetSelects([
                        LocationSearchTypes.Parish,
                        LocationSearchTypes.District,
                        LocationSearchTypes.Village,
                        LocationSearchTypes.Postal,
                        'postalSuggest'
                    ]);
                    form.field(LocationSearchTypes.City).clear();
                    fetch(LocationSearchTypes.Parish, form.field(LocationSearchTypes.Region).value);
                }

            } else {
                resetSelects([
                    LocationSearchTypes.Parish,
                    LocationSearchTypes.District,
                    LocationSearchTypes.City,
                    LocationSearchTypes.Village,
                    LocationSearchTypes.Postal,
                    'postalSuggest'
                ]);
                fetch(LocationSearchTypes.Parish, form.field(LocationSearchTypes.Region).value);
                fetch(LocationSearchTypes.City, form.field(LocationSearchTypes.Region).value);
            }
            break;
        case LocationSearchTypes.County:
            resetSelects([
                LocationSearchTypes.Parish,
                LocationSearchTypes.District,
                LocationSearchTypes.City,
                LocationSearchTypes.Village,
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.Municipality, form.field(LocationSearchTypes.County).value);
            break;
        case LocationSearchTypes.Municipality:
            resetSelects([
                LocationSearchTypes.Parish,
                LocationSearchTypes.City,
                LocationSearchTypes.Village,
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.Parish, form.field(LocationSearchTypes.Municipality).value);
            fetch(LocationSearchTypes.City, form.field(LocationSearchTypes.Municipality).value);
            break;
        case LocationSearchTypes.Parish:
            resetSelects([
                LocationSearchTypes.District,
                LocationSearchTypes.Village,
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.District, form.field(LocationSearchTypes.Parish).value);
            fetch(LocationSearchTypes.Village, form.field(LocationSearchTypes.Parish).value);
            break;
        case LocationSearchTypes.City:
            if (appCountry().iso === 'LV') {
                if (form.field(LocationSearchTypes.City).value) {
                    resetSelects([
                        LocationSearchTypes.Parish,
                        LocationSearchTypes.District,
                        LocationSearchTypes.Postal,
                        'postalSuggest'
                    ]);
                    form.field(LocationSearchTypes.Region).clear();
                    fetch(LocationSearchTypes.Postal, form.field(LocationSearchTypes.City).value);
                }
            } else {
                resetSelects([
                    LocationSearchTypes.District,
                    LocationSearchTypes.Village,
                    LocationSearchTypes.Postal,
                    'postalSuggest'
                ]);
                fetch(LocationSearchTypes.District, form.field(LocationSearchTypes.City).value);
            }
            break;
        case LocationSearchTypes.Village:
            resetSelects([
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.Postal, form.field(LocationSearchTypes.Village).value);
            break;
        case LocationSearchTypes.District:
            resetSelects([
                LocationSearchTypes.Postal,
                'postalSuggest'
            ]);
            fetch(LocationSearchTypes.Postal, form.field(LocationSearchTypes.District).value);
            break;
    }
    fixCurrentLabel(type);
}

function onLockChange(isLocked: boolean): void {
    additionalPostalIsDisabled.value =
        isSet(form.field('additional-postal').value)
        && !form.field('additional-postal').isEmpty();
    additionalApartmentIsDisabled.value =
        isSet(form.field('additional-apartment').value)
        && !form.field('additional-apartment').isEmpty();
    emitDisabledChange(isLocked);
}

function onApartmentBlur(value: string): void {
    if (isSet(field.value) && !field.isEmpty()) {
        field.value.apartment = value;
    }
    if (value) {
        if (isSet(additionalApartment.value)) {
            Object(additionalApartment.value).lock();
        }
    }
    revalidate();
}

function onPostalBlur(value: string): void {
    if (isSet(field.value) && !field.isEmpty()) {
        field.value.postCode = value;
    }
    if (value) {
        if (isSet(additionalPostal.value)) {
            Object(additionalPostal.value).lock();
        }
    }
    revalidate();
}

function onDetailedBlur(value: string): void {
    form.field('detailed').patch(value);
    if (props.mode !== '') {
        form.field('additional-postal').patch(field.value.postCode);
        $(':focus').trigger('blur');
    }
    revalidate();
}

function onApartmentKeypress(): void {
    revalidate();
}

function onPostalKeypress(): void {
    revalidate();
}

function onAdditionalPostalChange(postalIndex: any = null): void {
    if (simpleAddressInput.value) {
        form.field('additional-postal').patch(postalIndex);
        if (!field.isEmpty()) {
            field.value.postCode = postalIndex;
        }
        onLockChange(false);
        if (additionalPostalIsDisabled.value) {
            if (isSet(additionalPostal)) {
                Object(additionalPostal.value).lock('any');
            }
        }
    }
}

function onAdditionalApartmentChange(apartment: string): void {
    if (simpleAddressInput.value) {
        if (apartment !== '') {
            form.field('additional-apartment').patch(apartment);
            if (!field.isEmpty()) {
                field.value.apartment = apartment;
            }
        }
        onLockChange(false);
        if (additionalApartmentIsDisabled.value) {
            if (isSet(additionalApartment.value)) {
                Object(additionalApartment.value).lock('any');
            }
        }
    }
}

function setDetailedFieldsAvailable(): void {
    switch (appCountry().iso) {
        case 'EE':
            detailedFieldsAvailable.value = [
                LocationSearchTypes.Country,
                LocationSearchTypes.Region,
                LocationSearchTypes.City,
                LocationSearchTypes.Parish,
                LocationSearchTypes.District,
                LocationSearchTypes.Postal,
                'detailed'
            ];
            break;
        case 'LT':
            detailedFieldsAvailable.value = [
                LocationSearchTypes.Country,
                LocationSearchTypes.County,
                LocationSearchTypes.Municipality,
                LocationSearchTypes.City,
                LocationSearchTypes.Parish,
                LocationSearchTypes.Village,
                'postalSuggest',
                'detailed'
            ];
            break;
        case 'LV':
            detailedFieldsAvailable.value = [
                LocationSearchTypes.Country,
                LocationSearchTypes.Region,
                LocationSearchTypes.City,
                LocationSearchTypes.Parish,
                LocationSearchTypes.District,
                LocationSearchTypes.Postal,
                'detailed'
            ];
            break;
        default:
            detailedFieldsAvailable.value = [];
            break;
    }
}

function revalidate(): void {
    form.validate().then(() => {
        field.validate().then();
    });
}

function useAppCountry(): void {
    locations.country.options = [{name: '', value: appCountry().id}];
    form.field(LocationSearchTypes.Country).patch(appCountry().id);
    onSelectChange(LocationSearchTypes.Country);
}

function postalCodeValidator(): object {
    return {
        'mustBeValid': () => {
            let result: boolean = true;
            switch (props.mode) {
                case 'postal':
                case 'mixed':
                    if (!props.skipOwnValidation) {
                        result = Validation.isValidPostalCode(form.field('additional-postal').value);
                    }
                    break;
                default:
            }

            return result;
        }
    };
}

function additionalApartmentValidator(): object {
    return {
        'mustNotBeEmpty': () => {
            let result: boolean = true;
            switch (props.mode) {
                case 'apartment':
                case 'mixed':
                    if (!props.skipOwnValidation && !props.skipApartmentValidation) {
                        result = simpleAddressInput.value
                            ? !form.field('additional-apartment').isEmpty()
                            : true;
                    }
                    break;
                default:
            }

            return result;
        }
    };
}

function addressFieldValidators(): object {
    return {
        'isNotEmptyOrIsManualSearch': () => {
            let result: boolean = true;
            if (!props.skipOwnValidation) {
                result = !simpleAddressInput.value || !form.field('address').isEmpty();
            }

            return result;
        }
    };
}

function locationValidators(fieldName: string): object {
    return {
        mustBeNotEmptyOnManualSearch: () => simpleAddressInput.value
            || form.field('country').value !== appCountry().id
            || !form.field(fieldName).isEmpty()
    };
}

function cityParishValidators(): object {
    return {
        oneMustBeNotEmptyOnManualSearch: () => simpleAddressInput.value
            || form.field('country').value !== appCountry().id
            || !form.field(LocationSearchTypes.City).isEmpty()
            || !form.field(LocationSearchTypes.Parish).isEmpty()
    };
}

function regionCityValidators(): object {
    return {
        oneMustBeNotEmptyOnManualSearch: () => simpleAddressInput.value
            || form.field('country').value !== appCountry().id
            || !form.field(LocationSearchTypes.Region).isEmpty()
            || !form.field(LocationSearchTypes.City).isEmpty()
    };
}

function cityParishDistrictValidators(): object {
    return {
        mustBeNotEmptyOnManualSearch: () => simpleAddressInput.value
            || form.field('country').value !== appCountry().id
            || !form.field(LocationSearchTypes.City).isEmpty()
            || (!form.field(LocationSearchTypes.Parish).isEmpty() && !form.field(LocationSearchTypes.District).isEmpty())
    };
}

function resetSelects(types: string[] = []): void {
    if (types.length === 0) {
        types = detailedFieldsAvailable.value;
    }
    types.forEach(type => {
        if (locations[type]) {
            locations[type].options = [];
        }
        if (form.exists(type)) {
            form.field(type).clear();
        }
    });
}

function fetch(type: string, parent: string = ''): void {
    if (!isFieldAvailable(type)) {
        return;
    }
    locations[type].options = [];
    if (locations[type].fetchIsInProgress) {
        locations[type].cancelToken.cancel();
    }
    locations[type].fetchIsInProgress = true;
    locations[type].cancelToken = axios.CancelToken.source();
    axios
        .get(address.urlByName(type) + parent, {cancelToken: locations[type].cancelToken.token})
        .then((value) => {
            value.data.filter((item: any) => item.value !== 0).map((item: any) => {
                locations[type].options.push({
                    name: item.label,
                    value: item.value,
                    fullCode: item.fullCode,
                    postalCode: item.postalCode,
                    fullName: item.fullName
                });
            });
            received(type);
            locations[type].fetchIsInProgress = false;
        })
        .catch((reason) => {
            Error.log(ErrorType.Error, 'fetch(' + type + ')', reason);
            form.field(type).clear();
            locations[type].fetchIsInProgress = false;
        }).finally(() => {
            revalidate();
        }
    );
}

function locationByTypeFetchIsInProgress(type: string): boolean {
    return locations[type].fetchIsInProgress;
}

function received(type: string): void {
    form.field(type).clear();
    switch (type) {
        case  LocationSearchTypes.Country:
            if (locations[LocationSearchTypes.Country].options.length > 0) {
                const firstElementValue: any = locations[LocationSearchTypes.Country].options[0].value;
                form.field(type).patch(firstElementValue);
                onSelectChange(LocationSearchTypes.Country);
            }
            break;
    }
}

function apartment(type: string, description: string): string {
    return type === AddressWithApartment
        ? description
        : form.field('additional-apartment').value;
}

function fixCurrentLabel(type: string): void {
    let formValue: DynamicDictionary = field.value;
    switch (type) {
        case LocationSearchTypes.Country:
        case LocationSearchTypes.Region:
        case LocationSearchTypes.Parish:
        case LocationSearchTypes.District:
            formValue.label = fullLabel(type, form.field(type).value);
            field.patch(formValue);
            break;
        default:
    }
}

function fullLabel(type: string, id: number): string {
    let result: string = '';
    locations[type].options.forEach((element: DynamicDictionary) => {
        if (element.value === id) {
            result = element.fullName;
        }
    });

    return result;
}

function appCountry(): { id: string, iso: string } {
    return {
        id: settingsService.value('USER_ADDRESS_COUNTRY').toString(),
        iso: settingsService.value('LOCALE_ISO').toString()
    };
}

function onOpenClick(id: string): void {
    Object.keys(elements.value).forEach((key: string) => {
        if (key !== id) {
            elements.value[key].close();
        }
    });
}

const functionRef = (el: DynamicDictionary, name: string) => {
    elements.value[name] = el;
}

onBeforeUpdate(() => {
    elements.value = []
})
</script>

<template>
    <div class="inputs-set address"
         :id="formField.name"
         :class="{'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <template v-if="form.isReady()">
            <div class="fields can-find-address" v-if="simpleAddressInput">
                <div class="fields-group">
                    <app-input-text-ajax
                        :class="{'address-stretched': isStretchMode}"
                        key="address"
                        :data-store-disabled="true"
                        :disable-predictive-text="mobileModeEnabled"
                        :type="'simple'"
                        :label="label"
                        :placeholder="addressLabel"
                        :form-field="form.field('address')"
                        :additional-params="additionalParams()"
                        :url="url()"
                        :receiver-callback="receiverCallback"
                        @lock-change="onLockChange"
                        @change="updateFormFieldValue"
                        @click="emitEditClick">
                        <template v-slot:app-tooltipster>
                            <slot name="app-tooltipster"></slot>
                        </template>
                    </app-input-text-ajax>
                    <div class="sub-group">
                        <app-input-text
                            v-show="isApartmentMode || isMixedMode"
                            ref="additionalApartment"
                            :data-store-disabled="true"
                            :class="{'apartment': isApartmentMode || isMixedMode}"
                            :form-field="form.field('additional-apartment')"
                            :label="translate('btar_apartment')"
                            :hint-label="hintLabel"
                            :is-lockable="true"
                            :locked="additionalApartmentIsDisabled"
                            :autocomplete="'off'"
                            @blur="onApartmentBlur($event)"
                            @keyup="onApartmentKeyup($event)"
                            @keypress="onApartmentKeypress()"
                            @change="onAdditionalApartmentChange($event)">
                            <template v-slot:app-tooltipster>
                                <slot name="app-apartment-tooltipster"></slot>
                            </template>
                        </app-input-text>
                        <app-input-text
                            v-show="isPostalMode || isMixedMode"
                            ref="additionalPostal"
                            :data-store-disabled="true"
                            :class="{'postal': isPostalMode || isMixedMode}"
                            :form-field="form.field('additional-postal')"
                            :label="translate('btar_postal')"
                            :hint-label="hintLabel"
                            :is-lockable="true"
                            :locked="additionalPostalIsDisabled"
                            :autocomplete="'off'"
                            @blur="onPostalBlur($event)"
                            @keyup="onPostalKeyup($event)"
                            @keypress="onPostalKeypress()"
                            @change="onAdditionalPostalChange">
                            <template v-slot:app-tooltipster>
                                <slot name="app-postal-tooltipster"></slot>
                            </template>
                        </app-input-text>
                        <app-input-text
                            v-if="form.exists('detailed') && useSettlement"
                            :class="'settlement-detailed-address'"
                            :label="translate('btar_address')"
                            :form-field="form.field('detailed')"
                            :hint-label="hintLabel"
                            @blur="onDetailedBlur"
                            @keyup="onDetailedKeypress($event)"
                            @change="updateFormFieldValue()">
                        </app-input-text>
                    </div>
                </div>
            </div>
            <div class="fields cant-field" v-if="!simpleAddressInput">
                <app-input-select
                    v-if="!disableCountry && form.exists('country')"
                    :label="translate('btar_country')"
                    :form-field="form.field('country')"
                    :options="locations['country'].options"
                    :disabled="isLockedSelect('country')"
                    :loading="locationByTypeFetchIsInProgress('country')"
                    @open="onOpenClick('country')"
                    :ref="el => functionRef(el, 'country')"
                    @change="onSelectChange('country')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('county')"
                    :label="translate('btar_county')"
                    :form-field="form.field('county')"
                    :options="locations['county'].options"
                    :disabled="isLockedSelect('county')"
                    :loading="locationByTypeFetchIsInProgress('county')"
                    @open="onOpenClick('county')"
                    :ref="el => functionRef(el, 'county')"
                    @change="onSelectChange('county')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('region')"
                    :label="translate('btar_region')"
                    :form-field="form.field('region')"
                    :options="locations['region'].options"
                    :disabled="isLockedSelect('region')"
                    :loading="locationByTypeFetchIsInProgress('region')"
                    @open="onOpenClick('region')"
                    :ref="el => functionRef(el, 'region')"
                    @change="onSelectChange('region')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('municipality')"
                    :label="translate('btar_municipality')"
                    :form-field="form.field('municipality')"
                    :options="locations['municipality'].options"
                    :disabled="isLockedSelect('municipality')"
                    :loading="locationByTypeFetchIsInProgress('municipality')"
                    @open="onOpenClick('municipality')"
                    :ref="el => functionRef(el, 'municipality')"
                    @change="onSelectChange('municipality')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('city')"
                    :label="translate('btar_city')"
                    :form-field="form.field('city')"
                    :options="locations['city'].options"
                    :disabled="isLockedSelect('city')"
                    :loading="locationByTypeFetchIsInProgress('city')"
                    @open="onOpenClick('city')"
                    :ref="el => functionRef(el, 'city')"
                    @change="onSelectChange('city')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('parish')"
                    :label="translate('btar_parish')"
                    :form-field="form.field('parish')"
                    :options="locations['parish'].options"
                    :disabled="isLockedSelect('parish')"
                    :loading="locationByTypeFetchIsInProgress('parish')"
                    @open="onOpenClick('parish')"
                    :ref="el => functionRef(el, 'parish')"
                    @change="onSelectChange('parish')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('district')"
                    :label="translate('btar_district')"
                    :form-field="form.field('district')"
                    :options="locations['district'].options"
                    :disabled="isLockedSelect('district')"
                    :loading="locationByTypeFetchIsInProgress('district')"
                    @open="onOpenClick('district')"
                    :ref="el => functionRef(el, 'district')"
                    @change="onSelectChange('district')">
                </app-input-select>
                <app-input-select
                    v-if="isFieldVisible('village')"
                    :label="translate('btar_village')"
                    :form-field="form.field('village')"
                    :options="locations['village'].options"
                    :disabled="isLockedSelect('village')"
                    :loading="locationByTypeFetchIsInProgress('village')"
                    @open="onOpenClick('village')"
                    :ref="el => functionRef(el, 'village')"
                    @change="onSelectChange('village')">
                </app-input-select>
                <app-input-text-ajax
                    v-if="form.exists('postalSuggest') && (!form.field('city').isEmpty() || !form.field('parish').isEmpty())"
                    :type="'simple'"
                    :label="translate('btar_postal')"
                    :form-field="form.field('postalSuggest')"
                    :url="address.urlByName('suggestPostal').replace('{parent}', form.field('municipality').value)"
                    @change="onSelectChange('postalSuggest')">
                </app-input-text-ajax>
                <app-input-select
                    v-if="isFieldVisible('postal')"
                    :label="translate('btar_postal')"
                    :form-field="form.field('postal')"
                    :options="locations['postal'].options"
                    :disabled="isLockedSelect('postal')"
                    :loading="locationByTypeFetchIsInProgress('postal')"
                    @open="onOpenClick('postal')"
                    :ref="el => functionRef(el, 'postal')"
                    @change="onSelectChange('postal')">
                </app-input-select>
                <app-input-text
                    v-if="form.exists('detailed')"
                    :class="'detailed'"
                    :label="translate('btar_detailed_address')"
                    :form-field="form.field('detailed')"
                    @blur="onDetailedBlur($event)"
                    @keyup="onDetailedKeypress($event)"
                    @change="updateFormFieldValue()">
                </app-input-text>
            </div>
        </template>
        <div class="wrapper" v-if="!locationFetchIsInProgress && !disableManualInput">
            <button class="change-address"
                    :id="formField.name + '-cantFindButton'"
                    @click="cantFindClick">
                {{ (simpleAddressInput ? translate('btar_cant_find_address') : translate('btar_simple_address_mode')) }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.address {
    width: 100%;
    scroll-margin-top: 4em;

    > .fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;
        margin-bottom: -24px;

        .fields-group {
            width: 100%;
            display: flex;
            flex-direction: column;

            .address-stretched {
                min-width: 100%;
            }

            #address {
                @include respond-above('sm') {
                    width: 50%;
                }
            }

            .sub-group {
                justify-content: space-between;
                display: flex;
                gap: var(--size-normal);

                @include respond-above('sm') {
                    width: 50%;
                    justify-content: flex-start;
                    gap: var(--size-nano);
                }
            }

            @include respond-above('sm') {
                flex-direction: row;
                gap: var(--size-nano);
            }

            .input-text {
                &.detailed {
                    width: 120px !important;
                }

                &.settlement-detailed-address {
                    width: 100% !important;
                }
            }
        }

        @include respond-above('sm') {
            margin-top: var(--size-normal);
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .input {
            width: 100%;
            margin-bottom: var(--size-normal);

            @include respond-above('lg') {
                &.full-width {
                    width: 100% !important;
                }
            }
        }

        &.cant-field {
            .input {
                @include respond-above('sm') {
                    width: calc(50% - 10px);
                }
            }
        }
    }

    .invisible-combo {
        visibility: hidden;
        height: 0;
    }

    .change-address,
    .change-address-simple {
        color: var(--text-color-link);
        font-size: var(--font-size-tiny);
        font-weight: 600;
        white-space: nowrap;
        margin-top: 18px;
        margin-left: 1px;

        &:hover {
            color: var(--text-color-link-hover);
        }
    }
}
</style>
