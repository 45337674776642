<script setup lang='ts'>
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import {onMounted, reactive, ref, Ref} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import VueRouter from 'vue-router';
import {useRouter} from 'vue-router/composables';
import {useTranslate} from '@/Composables/Translate';
import PopupService from '@/services/custom.popup.service';
import {CoveredPopupBuilder} from '@/Apps/Renters/Builders/CoveredPopupBuilder';
import ProductBlockItem from '@/Apps/Renters/Interfaces/ProductBlockItemInterface';
import OnePopup from '@/assets/libraries/popups/one.popup';
import RequestService from '@/services/request.service';
import {useDefine} from '@/Composables/Define';
import StepsGuard from '@/Apps/Renters/Services/StepsGuard';
import RentersService from '@/Apps/Renters/Services/RentersService';
import {useScroll} from '@/Composables/Scroll';
import {Renters} from '@/Apps/Renters/Interfaces/RentersInterface';
import ProductBlockItemBuilder from '@/Components/ProductBlock/Builders/ProductBlockItemBuilder';
import {CoverageRisk} from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

const router: VueRouter = useRouter();
const {translateForType} = useTranslate();
const form: UnwrapNestedRefs<Form> = reactive(new Form());
const popupService: PopupService = PopupService.getInstance();
const TranslationType: string = 'renters';
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const productBlocks: Ref<ProductBlockItem[]> = ref([]);
const BadgeTextSuffix: string = '_BADGE_TEXT';
const rentersService: RentersService = RentersService.getInstance();
const selectedProgram: Ref<string> = ref('');

let products: Renters[] = [];

defineExpose({
    coveredPopup,
});

onMounted(() => {
    rentersService.fields.additionalOptions = {};
    StepsGuard.getInstance(rentersService).init();
    if (shouldReroute()) {
        reRoute();
    } else {
        fetchProducts().then((): void => {
            setupForm();
            buildProductBlocks();
            buildCoveredPopup();
            useScroll().scrollToTop();
        });
    }
});

function setupForm(): void {
    form.addField(new FormField('product-panel-blocks'));
    form.setReady();
}

function fetchProducts(): Promise<void> {
    return new Promise(resolve => {
        RequestService.getInstance().get({
            uri: Url.Ajax.renters,
        }).then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                products = response.data.data.body.products;
                resolve();
            }
        })
    });
}

function buildCoveredPopup(): void {
    coveredPopup
        .withCoveredType('AppCoveredPopupRentersMulti')
        .withTranslationKey(TranslationType)
        .withCoveredTitle('covered_compare')
        .withOnSelectClick(onSelectProductClick as Function)
        .withContent(products);
}

 function onSelectProductClick(programs: string[]): void {
    selectedProgram.value = programs[0];
    prepareSubmit();
    router.push({name: 'renters-coverage'});
    popupService.hide();
}

function prepareSubmit(): void {
    rentersService.fields.programIc = selectedProgram.value;
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function buildProductBlocks(): void {
    products.forEach((policy: Renters): void => {
        const programIc: string = policy.id.toLowerCase();
        const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
        const productName: string = translateForType(programIc, TranslationType);
        const price: number = policy.price;
        const badgeText: string = translateForType(policy.id + BadgeTextSuffix, TranslationType);
        const customModule: string = 'AppProductBlockInnerRentersPolicy';
        const selectButtonText: string = translateForType('select_' + programIc, TranslationType);
        const customButtonText: string = translateForType('what_covered', TranslationType);
        const risks: CoverageRisk[] = policy.coveragePlans[0].risks as CoverageRisk[];
        const dynamicContent: DynamicDictionary = {
            insuredSum: policy.coveragePlans[0].insuredSum,
            risks: risks,
        };
        productBlockItemBuilder
            .withProductId(policy.id)
            .withProductName(productName)
            .withDiscountSum(price)
            .withDiscountTooltip({
                title: translateForType('price_from', TranslationType),
                description: translateForType('tooltip_product_block_description', TranslationType),
            })
            .withDynamicContent(customModule, dynamicContent)
            .withButtonSelectTitle(selectButtonText)
            .withButtonCustomTitle(customButtonText);
        if (policy.params.withBadge) {
            productBlockItemBuilder.withBadgeText(badgeText)
        }
        if (policy.params.defaultOnMobile) {
            productBlockItemBuilder.asDefaultOnMobile();
        }
        productBlocks.value.push(productBlockItemBuilder.build());
    });
}

function reRoute(): void {
    router.push(rentersService.store().currentRoute);
}

function shouldReroute(): boolean {
    return useDefine().isSet(rentersService.fields.programIc)
        && rentersService.store().currentRoute !== '';
}

</script>

<template>
    <div class="wrapper">
        <div class="content">
            <section class="header">
                <h2 class="title">{{ translateForType('title_renters', TranslationType) }}</h2>
                <div class="description">
                    {{ translateForType('subtitle_renters', TranslationType) }}
                </div>
                <div class="loading" v-if="!form.isReady()">
                    <app-content-loader></app-content-loader>
                </div>
            </section>
            <app-custom-form else :form="form" class="form">
                <app-product-block
                    :id="'renterProducts'"
                    :form-field="form.field('product-panel-blocks')"
                    :products="productBlocks"
                    :product-sum-type="'monthly'"
                    :mini-plans-component="'AppRentersMini'"
                    @selected="onSelectProductClick"
                    @custom="onShowCoveredPopupClick">
                </app-product-block>
            </app-custom-form>
        </div>
    </div>

</template>

<style lang="scss" scoped>

.wrapper {
    .content {
        .header {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 40px;

            .title {
                font-size: var(--size-medium);
                font-weight: 700;
                color: var(--text-color-default, #1d1f23);
                text-align: center;
                line-height: 120%;
                margin-bottom: 16px;
            }

            .description {
                font-size: var(--size-small);
                font-weight: 500;
                color: var(--text-color-subtle);
                text-align: center;
                line-height: 140%;
                margin-bottom: 44px;
            }
        }

        .loading {
            margin-top: 60px;
            left: 50%;
        }
    }
}
</style>
