<script setup lang="ts">
import { onMounted, PropType } from 'vue';
import FormField from '@/assets/libraries/form/form-field';

const props = defineProps({
    minValue: {type: Number, default: 0},
    maxValue: {type: Number, default: 100},
    formField: {type: Object as PropType<FormField<Number>>, default: () => new FormField('')},
    disabled: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    defaultValue: {type: Number, default: 0},
    increment: {type: Number, default: 1},
    label: {type: String, default: ''},
    required: {type: Boolean, default: false},
});

const emit = defineEmits(['change']);

onMounted(() => {
    applyDefaultValue();
});

function applyDefaultValue(): void {
    if (props.formField.isEmpty()) {
        props.formField.setValue(props.defaultValue);
    }
}

function increase(): void {
    if (canIncrease()) {
        const increasedValue: number = currentValue() + props.increment;
        props.formField.setValue(increasedValue);
        emit('change', props.formField.value)
    }
}

function decrease(): void {
    if (canDecrease()) {
        const decreasedValue: number = currentValue() - props.increment;
        props.formField.setValue(decreasedValue);
        emit('change', props.formField.value)
    }
}

function canIncrease(): boolean {
    return currentValue() < props.maxValue
        && currentValue() + props.increment <= props.maxValue;
}

function canDecrease(): boolean {
    return currentValue() > props.minValue
        && currentValue() - props.increment >= props.minValue;
}

function currentValue(): number {
    return Number(props.formField.value);
}
</script>

<template>
    <div class="input input-count"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : formField.value">
        <div class="label informative" v-if="label">
            <label :for="props.formField.name + '-text'">
                {{ label }}<span v-if="required" class="asterisk">&#42;</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="buttons-container">
            <button class="clickable"
                    :id="formField.name + '-decrease'"
                    :disabled="disabled"
                    @click="decrease()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="minus">
                        <path id="Vector" d="M5 12H19" stroke="#1D1F23" stroke-opacity="0.64" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </button>
            <div :id="formField.name + '-value'" class="value">{{ formField.value }}</div>
            <button class="clickable"
                    :id="formField.name + '-increase'"
                    :disabled="disabled"
                    @click="increase()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="plus">
                        <path id="Vector" d="M12 5V19" stroke="#1D1F23" stroke-opacity="0.64" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path id="Vector_2" d="M5 12H19" stroke="#1D1F23" stroke-opacity="0.64" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                </svg>
            </button>
        </div>
    </div>
</template>

<style lang='scss' scoped>
.input-count {
    position: relative;
    width: 100%;
    color: var(--component-color-text-value);
    display: flex;
    flex-direction: column;

    &.disabled {
        .buttons-container {
            background: var(--component-color-background-disabled);
            color: var(--component-color-text-disabled);
            pointer-events: none;
        }
    }

    .buttons-container {
        height: 52px;
        display: flex;
        justify-content: space-between;
        background: var(--white);
        border: 1px solid var(--component-color-border-default);
        border-radius: var(--size-femto);

        .clickable {
            width: 52px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                border-right: 1px solid var(--component-color-border-default);
            }

            &:last-child {
                border-left: 1px solid var(--component-color-border-default);
            }
        }

        .value {
            display: flex;
            flex: 1 0 0;
            align-items: center;
            justify-content: center;
            font-size: var(--font-size-tiny);
        }
    }
}
</style>
