<script setup lang="ts">
import {PropType} from 'vue';
import ListItem from '@/Components/ItemsListWithIcons/ListItem';
import {useTranslate} from '@/Composables/Translate';

const props = defineProps({
    title: {type: String, default: ''},
    multipleColumns: {type: Boolean, default: false},
    list: {type: Array as PropType<ListItem[]>, default: () => []},
    heading: {type: String, default: 'h2'}
});

const emit = defineEmits(['list-item-click']);

const {translate} = useTranslate();

function onListItemClick(callback: Function | undefined): void {
    if (callback !== undefined) {
        callback();
    } else {
        emit('list-item-click');
    }
}

function iconSrc(icon: string): string {
    return `images/one/components/items-list-with-icons/${icon}_black.svg`;
}
</script>
<template>
    <section class="items-list-with-icons">
        <component v-if="title" :is="heading" class="title">{{ translate(title) }}</component>
        <ul class="list"
            :class="{'multiple-columns':multipleColumns || list.length > 1}">
            <li class="list-item" v-for="(item) in list">
                <a class="list-item-content" target="_blank"
                   :class="{'centered':list.length === 1}"
                   v-if="item.url"
                   :href="item.url" v-reset-focus>
                <span class="left-part">
                    <img class="icon"
                         alt=""
                         width="24"
                         height="24"
                         :src="iconSrc(item.icon)">
                    <span class="name">{{ translate(item.name) }}</span>
                </span>
                    <span class="ext" v-if="item.extensionName">{{ item.extensionName }}</span>
                </a>
                <span class="list-item-content"
                      v-if="!item.url"
                      @click="onListItemClick(item.callback)">
                <img class="icon" alt="" width="24" height="24"
                     :src="iconSrc(item.icon)">
                <span class="name">{{ translate(item.name) }}</span>
            </span>
            </li>
        </ul>
    </section>
</template>
<style lang="scss" scoped>
.items-list-with-icons {
    width: 100%;

    .title {
        text-align: left;

        @include respond-above('sm') {
            text-align: center;
        }
    }

    .list {
        width: 100%;
        gap: 12px;
        margin-top: var(--size-small);
        margin-bottom: 20px;

        &.multiple-columns {
            display: grid;
            grid-template-columns: 100%;
        }

        .list-item {
            border-radius: 8px;

            .list-item-content {
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: var(--size-small);
                border-radius: 8px;
                outline: 1px solid var(--black-200);

                .left-part {
                    display: flex;

                    .icon {
                        display: block;
                        margin-right: var(--size-small);
                        filter: var(--to-color-green);
                    }

                    .name {
                        font-weight: 500;
                        font-size: var(--font-size-nano);

                        @include respond-above('sm') {
                            font-weight: 400;
                            font-size: var(--font-size-tiny);
                        }
                    }
                }

                .ext {
                    font-weight: 500;
                    font-size: 10px;
                }

                &.centered {
                    justify-content: center;
                }

                &:hover {
                    color: inherit;
                }
            }

            &:active {
                box-shadow: none;
            }

            &:hover,
            &:focus {
                box-shadow: 0 10px 20px rgb(0 0 0 / .19), 0 6px 6px rgb(0 0 0 / .23);

                .list-item-content {
                    outline-width: 2px;
                    outline-color: var(--brand-teal);
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
