import VueEvent from '@/Classes/VueEventClass';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {useDefine} from '@/Composables/Define';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';

const {isSet} = useDefine();
const {translateForType} = useTranslate();

export default class OptionsPartialDate {
    private readonly MinMonth: number = 1;
    private readonly MaxMonth: number = 12;

    private month: string[] = [];
    private years: string[] = [];
    private currentModel: string = '';

    public init(): void {
        this.prepareMonth();
        this.prepareYears();
    }

    public localizedMonthByIndex(index: number): string {
        let monthIndex: number = index;
        if (monthIndex < this.MinMonth) {
            monthIndex = this.MinMonth;
        }
        if (monthIndex > this.MaxMonth) {
            monthIndex = this.MaxMonth;
        }

        return isSet(this.month[monthIndex - 1]) ?
            this.month[monthIndex - 1] : translateForType('btar_month', '');
    }

    public validYears(): string[] {
        return this.years;
    }

    public openPartialDate(event: VueEvent): void {
        this.currentModel = event.data('model') as string;
        this.open('day');
    }

    public onPartialDateClick(event: VueEvent): void {
        (OneBaseService.getInstance() as DynamicDictionary)[this.currentModel].day = event.data('value');
        this.close();
    }

    public openPartialMonth(event: VueEvent): void {
        this.currentModel = event.data('model') as string;
        this.open('month');
    }

    public onPartialMonthClick(event: VueEvent): void {
        (OneBaseService.getInstance() as DynamicDictionary)[this.currentModel].month = event.data('value');
        this.close();
    }

    public openPartialYear(event: VueEvent): void {
        this.currentModel = event.data('model') as string;
        this.open('year');
    }

    public onPartialYearClick(event: VueEvent): void {
        (OneBaseService.getInstance() as DynamicDictionary)[this.currentModel].year = event.data('value');
        this.close();
    }

    public closeAll(): void {
        this.close();
    }

    private open(part: string): void {
        $('.dropdown[data-partial="' + part + '-opener-' + this.currentModel + '"]').show();
    }

    private close(): void {
        $('.dropdown[data-partial="day-opener-' + this.currentModel + '"]').hide();
        $('.dropdown[data-partial="month-opener-' + this.currentModel + '"]').hide();
        $('.dropdown[data-partial="year-opener-' + this.currentModel + '"]').hide();
    }

    private prepareMonth(): void {
        this.month.push(translateForType('btar_calendar_january', ''));
        this.month.push(translateForType('btar_calendar_february', ''));
        this.month.push(translateForType('btar_calendar_march', ''));
        this.month.push(translateForType('btar_calendar_april', ''));
        this.month.push(translateForType('btar_calendar_may', ''));
        this.month.push(translateForType('btar_calendar_june', ''));
        this.month.push(translateForType('btar_calendar_july', ''));
        this.month.push(translateForType('btar_calendar_august', ''));
        this.month.push(translateForType('btar_calendar_september', ''));
        this.month.push(translateForType('btar_calendar_october', ''));
        this.month.push(translateForType('btar_calendar_november', ''));
        this.month.push(translateForType('btar_calendar_december', ''));
    }

    private prepareYears(): void {
        for (let i: number = 1900; i < this.currentYearPlusOne(); i++) {
            this.years.push(String(i));
        }
        this.years.reverse();
    }

    private currentYearPlusOne(): number {
        const year: number = (new Date()).getFullYear();

        return year + 1;
    }
}
