import FormField from '@/assets/libraries/form/form-field';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {useStrings} from '@/Composables/Strings';
import {useTranslate} from '@/Composables/Translate';

export const useInputErrorMessage = (
    formField: UnwrapNestedRefs<FormField>,
    disableErrorText: boolean,
    texts: InputErrorMessageTexts
): InputErrorParams => {
    const {transformedToSnakeCase} = useStrings();
    const {translateForType} = useTranslate();
    const errorMessagePrefix: string = 'input_error_';

    const infoMessageIsVisible = (): boolean => {
        return (texts.feedbackMessage !== ''
                || texts.supportTextMessage !== '')
            && (formField.isTouched && !formField.isValid);
    }

    const infoMessage = (): string => {
        return formField.isTouched && !formField.isValid
            ? errorStringId()
            : texts.supportTextMessage;
    }

    const errorStringId = (): string => {
        let errorMessage: string = '';
        if (!disableErrorText) {
            errorMessage = translateForType(
                errorMessagePrefix +
                transformedToSnakeCase(formField.name)
                    .replace(/\d+$/, '')
                    .replace(/_+$/, '')
                , 'errors');
        }
        return texts.feedbackMessage !== '' ? texts.feedbackMessage : errorMessage;
    }

    return {
        infoMessageIsVisible,
        infoMessage,
    }
}

export interface InputErrorMessageTexts {
    feedbackMessage: string;
    supportTextMessage: string;
}

export interface InputErrorParams {
    infoMessageIsVisible: () => boolean;
    infoMessage: () => string;
}
