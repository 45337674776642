import AxiosCache from '@/services/axios.cache.service';
import axios, {AxiosResponse, CancelTokenSource} from 'axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {DefineParams, useDefine} from '@/Composables/Define';

export default class RequestService {
    private static instance: RequestService;
    private cache!: AxiosCache;
    private request: AxiosParams = useAxios();
    private define: DefineParams = useDefine();
    private axiosFetchCancelToken: CancelTokenSource | null = null;
    private axiosFetchIsInProgress: boolean = false;

    public constructor() {
        this.cache = AxiosCache.getInstance();
    }

    public static getInstance(): RequestService {
        if (!RequestService.instance) {
            RequestService.instance = new RequestService();
        }
        return RequestService.instance;
    }

    public async get(params: RequestParams): Promise<AxiosResponse> {
        this.startFetch();
        const cacheParams: DynamicDictionary = {...params};
        let result: Promise<AxiosResponse>;
        if (!this.cache.fetchCache(cacheParams)) {
            result = this.request
                .get(params.uri, {
                    params: params.content,
                    headers: params.headers,
                    cancelToken: this.axiosFetchCancelToken!.token
                })
                .then((response: AxiosResponse): Promise<AxiosResponse> => {
                    this.cache.storeCache(cacheParams, response);
                    return this.responsePromise(response);
                }).catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'RequestService::get', reason);
                }) as Promise<AxiosResponse>;
        } else {
            result = new Promise(resolve =>
                resolve((this.cache.fetchCache(cacheParams) as AxiosResponse)));
        }
        result.finally((): void => {
            this.stopFetch();
        })

        return result;
    }

    public async post(params: RequestParams): Promise<AxiosResponse> {
        this.startFetch();
        return this.request
            .post(params.uri, params.content || {}, {
                headers: params.headers,
                cancelToken: this.axiosFetchCancelToken!.token
            })
            .then((response: AxiosResponse): Promise<AxiosResponse> => {
                return this.responsePromise(response);
            }).catch((reason: DynamicDictionary): void => {
                Error.log(ErrorType.Error, 'RequestService::post', reason);
            }).finally((): void => {
                this.stopFetch();
            }) as Promise<AxiosResponse>;
    }

    private responsePromise(response: AxiosResponse): Promise<AxiosResponse> {
        return new Promise((resolve, reject): void => {
            if (!this.isValidResponse(response)) {
                Error.log(ErrorType.Error, 'RequestService::responsePromise', 'fetch_invalid_data');
                reject(response);
            } else {
                resolve(response);
            }
        });
    }

    private startFetch(): void {
        if (this.axiosFetchIsInProgress) {
            this.axiosFetchCancelToken!.cancel();
        }
        this.axiosFetchIsInProgress = true;
        this.axiosFetchCancelToken = axios.CancelToken.source();
    }

    private stopFetch(): void {
        this.axiosFetchIsInProgress = false;
    }

    private isValidResponse(response: AxiosResponse): boolean {
        return this.isValidSuccessResponse(response) || this.isValidErrorResponse(response);
    }

    private isValidSuccessResponse(response: AxiosResponse): boolean {
        return this.define.isSet(response.data) &&
            this.define.isSet(response.data.data) &&
            this.define.isSet(response.data.data.body);
    }

    private isValidErrorResponse(response: AxiosResponse): boolean {
        return this.define.isSet(response.data.errors);
    }
}

export interface RequestParams {
    uri: string,
    headers?: DynamicDictionary,
    content?: DynamicDictionary,
}
