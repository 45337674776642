<script setup lang="ts">
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import FormField from '@/assets/libraries/form/form-field';
    import {computed, ref, Ref} from 'vue';
    import Error from '@/services/error.service';
    import ErrorType from '@/Enums/ErrorTypeEnum';
    import {LimitedVariant} from '@/Types/LimitedVariantType';
    import {AxiosParams, useAxios} from '@/Composables/Axios';
    import {AxiosResponse, CancelTokenSource} from 'axios';
    import {useDefine} from '@/Composables/Define';
    import {useGenerator} from '@/Composables/Generator';

    const request: AxiosParams = useAxios();
    const {isSet} = useDefine();
    const {autoFieldId} = useGenerator();

    const props: DynamicDictionary = defineProps({
        label: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        formField: {type: FormField, default: () => new FormField('')},
        ariaLabel: {type: String, default: ''},
        autocomplete: {type: String, default: ''},
        dataStoreDisabled: {type: Boolean, default: false},
        url: {type: String, default: ''},
        manual: {type: Boolean, default: false},
        maxLength: {type: Number, default: 9999},
        loading: {type: Boolean, default: false},
        mode: {type: String, default: ''},
    });
    const emit = defineEmits(['term-change']);

    let term: Ref<string> = ref('');
    let fetchIsInProgress: Ref<boolean> = ref(false);

    let cancelToken: CancelTokenSource | null = null;

    defineExpose({
        onClearClick,
    });

    function onKeyUp(event: DynamicDictionary): void {
        if (!props.manual) {
            termChange();
        } else if (event.key === 'Enter' || event.keyCode === 13) {
            termChange();
        } else if (event.key === 'Escape' || event.keyCode === 27) {
            onClearClick();
        }
    }

    function onSearchIconClick(): void {
        if (props.manual) {
            termChange();
        }
    }

    function onClearClick(): void {
        term.value = '';
        emitTermChange(term.value);
    }

    function termChange(): void {
        if (!props.url) {
            emitTermChange(term.value);
        } else {
            if (fetchIsInProgress.value) {
                cancelToken!.cancel();
            }
            const combinedUrl: string = props.url + '?term=' + term.value;
            request.get(combinedUrl, {
                cancelToken: request.CancelToken.source().token
            }).then((response: AxiosResponse<DynamicDictionary>) => {
                if (useDefine().validResponse(response)) {
                    emitTermChange(response.data.data.body);
                } else {
                    Error.log(ErrorType.Error, 'input_search::termChange', 'response error:invalid response');
                }
            }).catch((reason: LimitedVariant) => {
                Error.log(ErrorType.Error, 'input_search::termChange', 'bind error: ' + JSON.stringify(reason));
            }).finally(() => {
                fetchIsInProgress.value = false;
            });
        }
    }

    function emitTermChange(params: DynamicDictionary | string): void {
        emit('term-change', params)
    }

    const isClearMode: Ref<boolean> = computed(() => {
        return props.mode === 'clear';
    });

    const isTermPassed: Ref<boolean> = computed(() => {
        return term.value !== '';
    });
</script>

<template>
    <div class="input input-search">
        <div class="input-search-wrapper">
            <button class="manual"
                    v-if="manual && !loading"
                    @click="onSearchIconClick">
                <svg v-if="!loading"
                     class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="#9297A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21.0004 20.9999L16.6504 16.6499" stroke="#9297A0" stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
            </button>
            <svg v-if="!manual && !loading"
                 class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                    stroke="#9297A0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M21.0004 20.9999L16.6504 16.6499" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
            <input type="text"
                   ref="input"
                   v-model="term"
                   v-bind:class="{'disabled': loading}"
                   v-bind:disabled="loading || disabled"
                   v-bind:id="autoFieldId()"
                   v-bind:placeholder="placeholder"
                   v-bind:maxlength="maxLength"
                   v-bind:autocomplete="autocomplete"
                   v-bind:aria-label="ariaLabel && !label ? ariaLabel : null"
                   @keyup="onKeyUp">
            <div class="loading" v-if="loading">
                   <app-content-loader :icon-type="'spinner'"></app-content-loader>
            </div>
            <button class="icon clear" v-if="isClearMode && isTermPassed" v-on:click="onClearClick()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18M6 6L18 18" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-search {
    max-width: 420px;
    width: 100%;

    .input-search-wrapper {
        position: relative;

        .icon {
            position: absolute;
            left: 14px;
            top: 9px;
        }

        .clear {
            height: 100%;
            width: 40px;
            position: absolute;
            top: 0;
            right: 0;
            left: auto;
        }

        .manual {
            display: block;

            &:hover {
                filter: var(--to-color-black);
            }
        }

        input {
            width: 100%;
            max-width: 420px;
            min-height: 40px;
            padding: 0 30px 0 50px;
            background-color: var(--component-color-background-base);
            font-size: var(--font-size-nano);
            color: var(--component-color-text-value);
            outline: 1px solid var(--component-color-border-default);

            &:hover {
                @include input-hover;
            }

            &:focus,
            &:active {
                @include input-focus;
            }

            &.disabled {
                color: var(--component-color-text-disabled);
            }
        }

        .loading {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            left: 13px;
        }
    }
}
</style>
