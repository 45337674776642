export default class ItemsBannerLandingSlick {
    private outerContainer: string = '.items-banner-landing-slick';
    private container: string = '.items-banner-landing-slick .items-wrapper:visible .carousel';
    private slickElement: any = null;
    private autoplaySpeed: number = 10000;
    private oldWidth: number = -1;

    public init(): void {
        const width: number = window.innerWidth;
        const breakPoint: number = 812;
        if ((this.oldWidth <= breakPoint && width >= breakPoint) ||
            (this.oldWidth >= breakPoint && width <= breakPoint) ||
            this.oldWidth === -1) {
            this.oldWidth = width;
            this.removeSlick();
            this.createSlick();
        }
    }

    private createSlick(): void {
        this.slickElement = $(this.container);
        if (this.slickElement.length > 0) {
            $(this.slickElement).on('init', (event, slick) => {
                document.addEventListener('click', (e: Event) => {
                    const outerContainerElement: HTMLElement = $(this.outerContainer)[0];
                    const eventTargetElement: HTMLElement = e.target as HTMLElement;
                    if (eventTargetElement !== null && eventTargetElement !== outerContainerElement &&
                        !$.contains(outerContainerElement, eventTargetElement)) {
                        this.slickElement.slick('resumeAutoPlay');
                    }
                });
            });
            this.autoplaySpeed = this.slickElement.data('autoplaySpeed');
            this.slickElement.slick({
                accessibility: true,
                focusOnSelect: true,
                arrows: false,
                infinite: true,
                slidesToScroll: 1,
                speed: 500,
                fade: true,
                cssEase: 'ease',
                lazyLoad: 'ondemand',
                dots: true,
                appendDots: $('.items-banner-landing-slick .items-wrapper:visible .slick-dots-container'),
                customPaging: (slider: any, i: any) => {
                    return '<button type="button" role="tab"><div class="bar hidden"></div></button>';
                },
                autoplay: true,
                pauseOnDotsHover: true,
                pauseOnDotClick: true,
                pauseOnHover: false,
                autoplaySpeed: this.autoplaySpeed,
                animateDots: true
            });
            this.slickElement.slick('slickPlay');
        }
    }

    private removeSlick(): void {
        if (this.slickElement !== null && this.slickElement.length > 0) {
            $(this.slickElement).off('init');
            this.slickElement.slick('unslick');
        }
    }
}
