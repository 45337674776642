<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import {onMounted, PropType, watch} from 'vue';
    import {InputOption} from '@/interfaces/InputOptionInterface';

    type RadioTypes = 'button' | 'radio' | 'sparse' | 'badge' | 'digits' | 'clear';
    type Size = 'small' | 'large';

    const props = defineProps({
      label: {type: String, default: ''},
      placeholder: {type: String, default: ''},
      disabled: {type: Boolean, default: false},
      formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
      dataStoreDisabled: {type: Boolean, default: false},
      options: {type: Array as PropType<InputOption[]>, default: () => []},
      size: {type: String as PropType<Size>, default: 'large'},
      type: {type: String as PropType<RadioTypes>, default: 'button'},
      vertical: {type: Boolean, default: false},
      customBorderRadius: {type: Boolean, default: false},
      mobileIcon: {type: String, default: ''},
    });

    const emit = defineEmits(['change']);

    watch(() => props.formField.value, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        emitChange();
      }
    });

    onMounted((): void => {
      props.formField.setIsValid(true);
    });

    function iconSrc(iconName: string): string {
      return `images/one/components/input-radio/${iconName}.svg`;
    }

    function select(option: InputOption): void {
      if (option.value !== props.formField.value) {
        props.formField.setValue(option.value as string);
      }
    }

    function emitChange(): void {
      props.formField.touch();
      props.formField.sanitize();
      props.formField.validate();
      emit('change', props.formField.value);
    }
</script>

<template>
  <div class="input input-radio"
       :data-type="(formField.name !== '' ? formField.name : 'radioButtons')"
       :class="{ ...formField.classes(), 'disabled': disabled, 'vertical': vertical}"
       :data-store="dataStoreDisabled ? '' : formField.name"
       :data-store-value="dataStoreDisabled ? '' : `${formField.value}`">
    <div v-if="label" class="label informative">
      <p>{{label}}</p>
      <slot name="app-tooltipster"></slot>
    </div>
    <div class="buttons"
         :class="{
            'button-sparse': type === 'sparse',
            'custom-button-sparse': mobileIcon !== '',
            'button-badge': type === 'badge' || type === 'digits' || type === 'clear',
            'button-digits' : type === 'digits'
        }">
      <button v-for="(option, index) in options"
              class="button outside white"
              :key="index"
              :class="{
                    'active': formField.value === option.value && !(option.disabled || disabled),
                    'hidden': option.hidden,
                    'size-large': size === 'large' && type !== 'badge' && type !== 'digits' && type !== 'clear',
                    'size-small': size === 'small',
                    'type-radio': type === 'radio',
                    'type-sparse': type === 'sparse',
                    'type-badge': type === 'badge' || type === 'digits',
                    'type-digits': type === 'digits',
                    'type-clear': type === 'clear',
                    'custom-type-sparse': mobileIcon !== '',
                    'custom-border-radius': customBorderRadius,
                }"
              :disabled="option.disabled || disabled"
              :data-type="(formField.name !== '' ? formField.name : 'radioButton') + '-' + index"
              :data-index="index"
              @click="select(option)">
        <img v-if="option.icon" class="option-icon"
             :class="{'active': formField.value === option.value}"
             :src="iconSrc(option.icon)"
             width=32 height=32 alt="option-icon">
        <span :class="{
                    'option-text-outside': mobileIcon !== ''
            }" v-html="option.name"></span>
        <img v-if="mobileIcon !== ''" class="mobile-icon"
             :class="{'active': formField.value === option.value}"
             :src="mobileIcon"
             width=32 height=32 alt="bricks">
        <div class="digits" v-if="type === 'digits' && option.custom !== '' && option.custom !== 0">
          {{option.custom}}
        </div>
      </button>
      <span class="title">
            <slot></slot>
        </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-radio {
  width: 100%;
  scroll-margin-top: 4em;

  .buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .button {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;

      .option-icon {
        margin-right: var(--size-nano);
        filter: contrast(1%);

        &.active {
          filter: var(--to-color-green);
        }
      }

      &.custom-border-radius {
        border-radius: 8px;

        &::before {
          border-radius: 8px;
        }
      }

      &.size-large {
        height: 52px;
        padding: 0 10px;

        &:disabled {
          box-shadow: none;

          &::before {
            opacity: .16;
          }
        }
      }

      &.size-small {
        height: 32px;
        padding: 0 12px;
        min-width: 50px;
        width: auto !important;
      }

      &.type-radio {
        $diameter: 1.5rem;
        background-color: transparent;
        line-height: $diameter;
        width: auto !important;

        &:disabled {
          cursor: default;
        }

        &.size-large,
        &.size-small {
          height: auto;
          padding-left: $diameter + 1rem;
        }

        &::before {
          border: solid 2px var(--component-color-border-default);
          border-radius: 50%;
          display: flex;
          height: $diameter;
          width: $diameter;
        }

        &:hover {
          box-shadow: none;
        }

        &.active {
          &::before {
            border-color: var(--brand-teal);
            left: 0;
            top: 0;
          }

          &::after {
            $diameter: .75rem;
            $offset: .375rem;
            background-color: var(--brand-teal);
            border-radius: 50%;
            content: '';
            height: $diameter;
            left: $offset;
            position: absolute;
            top: $offset;
            width: $diameter;
          }
        }
      }
    }

    .type-sparse {
      margin-left: 5px;
      margin-right: 5px;
      max-width: 73px;
    }

    .custom-type-sparse {
      margin: 0;
      max-width: 100%;

      @include respond-below('lg') {
        max-width: 103px;
      }

      .option-text-outside {
        @include respond-below('lg') {
          position: absolute;
          top: 110%;
        }
      }

      .mobile-icon {
        display: block;

        img {
          &.active {
            filter: brightness(10%);
          }
        }

        @include respond-above('sm') {
          display: none;
        }
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .type-badge {
      position: relative;
      height: 32px;
      font-size: var(--font-size-pico);
      padding-left: var(--size-tiny);
      padding-right: 28px;
      flex-grow: 0;
      border-radius: 16px;
      margin-right: var(--size-tiny);
      background-color: transparent;
      color: var(--component-color-text-value);

      &:last-child {
        margin-right: 0;
      }

      &::before {
        display: none;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        right: 14px;
        top: 50%;
        margin-top: -2px;
        background-color: var(--black-200);
      }

      &.active {
        background-color: var(--component-color-background-base);

        &::after {
          background-color: var(--brand-teal);
        }
      }

      &:disabled {
        color: var(--component-color-text-disabled);
      }
    }

    .type-digits {
      padding-right: var(--size-tiny);
      flex-shrink: 1;

      &::after {
        display: none;
      }

      .digits {
        font-size: var(--font-size-femto);
        color: var(--black-400);
        margin-left: var(--size-femto);
      }

      &.active {
        .digits {
          color: var(--brand-red);
        }
      }
    }

    .type-clear {
      position: relative;
      height: 32px;
      font-size: var(--font-size-pico);
      padding: 0 16px;
      flex-grow: 0;
      border-radius: 16px;
      margin-right: var(--size-tiny);
      background-color: transparent;
      color: var(--component-color-text-value);

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }

      &.active {
        background-color: var(--component-color-background-base);
      }
    }
  }

  .button-sparse {
    justify-content: flex-end;

    .button:not(.active) {
      &::before {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: 1px solid var(--component-color-border-default);
        border-radius: 3px;
        opacity: .48;
        transition: all .3s;
      }
    }
  }

  .custom-button-sparse {
    @include respond-below('lg') {
      justify-content: space-between;
    }
  }

  &.invalid {
    .buttons {
      .button {
        &::before {
          border-color: var(--brand-red);
          opacity: 1;
        }
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    margin-top: 2px;
    margin-left: var(--size-nano);
    color: var(--black-200);
    font-weight: 500;
    font-size: var(--font-size-nano);
    align-items: center;

    &:empty {
      display: none;
    }
  }

  &.vertical {
    width: auto;

    .buttons {
      flex-direction: column;

      button {
        flex-direction: unset;
        justify-content: left;
        margin: .5rem 0;
      }
    }
  }
}
</style>
