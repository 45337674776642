import InsurancePlan from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanItem from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';

export default class InsurancePlanBuilder {
    private insurancePlan: InsurancePlan = new class implements InsurancePlan {
        public title: string = '';
        public priceTitle: string = '';
        public price: number = 0;
        public discount: number = 0;
        public items: InsurancePlanItem[] = [];
        public paymentFrequency: string = ''
    }

    public withTitle(value: string): InsurancePlanBuilder {
        this.insurancePlan.title = value;

        return this;
    }

    public withPriceTitle(value: string): InsurancePlanBuilder {
        this.insurancePlan.priceTitle = value;

        return this;
    }

    public withPaymentFrequency(value: string): InsurancePlanBuilder {
        this.insurancePlan.paymentFrequency = value;

        return this;
    }

    public withPrice(value: number): InsurancePlanBuilder {
        this.insurancePlan.price = value;

        return this;
    }

    public withDiscount(value: number): InsurancePlanBuilder {
        this.insurancePlan.discount = value;

        return this;
    }

    public withItems(value: InsurancePlanItem[]): InsurancePlanBuilder {
        this.insurancePlan.items = value;

        return this;
    }

    public build(): InsurancePlan {
        return this.insurancePlan;
    }
}
