import FormField from '@/assets/libraries/form/form-field';
import Device from '@/pages/ItemsAndBelongings/Interfaces/DeviceInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Validation from '@/services/validation.service';
import Sanitizer from '@/services/sanitizer.service';
import { reactive } from 'vue';

export default class MovablePropertyDeviceField {
    private readonly device!: Device;
    private readonly defaultFieldLength: number = 10;

    public constructor(device: Device) {
        this.device = device;
    }

    public forCurrentDevice(): FormField {
        return this.deviceFields()[this.deviceFieldKey()];
    }

    private deviceFieldKey(): string {
        return Object.keys(this.deviceFields())
            .filter((fieldKey: string): boolean => this.device[fieldKey])[0];
    }

    private deviceFields(): DynamicDictionary {
        return {
            description: reactive(
                new FormField('description', '', this.minLengthValidator(), Sanitizer.cleanCaption)
            ),
            imei: reactive(
                new FormField('imei', '', this.imeiValidator(), Sanitizer.cleanIMEINumber)
            ),
            makeModel: reactive(
                new FormField('makeModel', '', this.minLengthValidator(), Sanitizer.cleanCaption)
            ),
            serialNumber: reactive(
                new FormField('serialNumber', '', this.minLengthValidator(), Sanitizer.cleanCaption)
            ),
        };
    }

    private minLengthValidator(): object {
        return {
            hasValidMinLength: (value: string): boolean =>
                Validation.hasValidLength(value, this.defaultFieldLength)
        }
    }

    private imeiValidator(): object {
        return {
            isValidImeiNumber: (value: string): boolean =>
                Validation.isValidIMEINumber(value)
        }
    }
}
