<script setup lang="ts">
    import FilterParams from '@/interfaces/filter.params.interface';
    import InputTagIcon from '@/Components/InputText/InputTagIcon';
    import {computed, onMounted, PropType, ref, Ref} from 'vue';
    import {useTranslate} from '@/Composables/Translate';
    import {useDefine} from '@/Composables/Define';
    import {useStrings} from '@/Composables/Strings';
    import AppCountry from '@/assets/libraries/app/app-country';
    import AppInputText from '@/Components/InputText/InputText.vue';

    const props = defineProps({
        filters: {type: Array as PropType<FilterParams[]>, default: () => []},
        defaultFilter: {type: String, default: ''},
        title: {type: String, default: 'popup_filter_filter_by_param'},
        searchInputPlaceholder: {type: String, default: 'popup_filter_search_placeholder'},
        toggleFilterLabel: {type: String, default: 'popup_filter_all_params'},
        multiple: {type: Boolean, default: false},
        translationType: {type: String, default: 'components'},
    });
    const emit = defineEmits(['apply-filters', 'close-filters-popup']);
    const {isSet} = useDefine();
    const {diacriticString} = useStrings();
    const {translateForType} = useTranslate();
    const isToggleFilterSelected: Ref<boolean> = computed((): boolean => {
        const filters: FilterParams[] = selectedFilters
            .value
            .filter((filter: FilterParams) => filter.selected && filter.type !== toggleFilterType);

        return filters.length === props.filters.length || filters.length === 0;
    });
    const toggleFilterType: string = '*';
    const leftIcon: InputTagIcon = {
        name: 'search',
        cssClass: 'black-to-grey',
    };
    const rightIcon: InputTagIcon = {
        name: 'reset_input',
        cssClass: 'input-reset',
    };
    let searchString: Ref<string> = ref('');
    let selectedFilters: Ref<FilterParams[]> = ref([]);

    onMounted((): void => {
        setupFilters();
    });

    function emitApplyFilters(): void {
        emit('apply-filters', selectedParams());
    }

    function emitClose(): void {
        emit('close-filters-popup');
    }

    function onSearchFilter(): void {
        const search: string = diacriticString(searchString.value);
        selectedFilters.value = props.filters.filter((filter: FilterParams) => {
            const param: string = diacriticString(filter.param);
            return param.match(search);
        });
    }

    function onSelectFilter(param: string): void {
        const filter: FilterParams = selectedFilters.value.find((filter: FilterParams) => filter.param === param) as FilterParams;
        props.multiple ? multiModeFilter(filter) : singleModeFilter(filter);
    }

    function onRemoveFilter(param: string): void {
        !props.multiple ? onSelectFilter(param) : onResetFilters();
    }

    function singleModeFilter(filter: FilterParams): void {
        clearFilters();
        filter.selected = !filter.selected;
    }

    function multiModeFilter(filter: FilterParams): void {
        const isToggleFilter: boolean = filter.type === toggleFilterType;
        filter.selected = !filter.selected;
        if (isToggleFilter) {
            setFiltersState(filter.selected);
        } else if (isToggleFilterSelected.value) {
            clearFilters();
            filter.selected = true;
        }
        setToggleFilterState();
    }

    function onClearSearchString(): void {
        searchString.value = '';
        clearFilters();
        onSearchFilter();
    }

    function onResetFilters(): void {
        setDefaultFilter();
        emitApplyFilters();
    }

    function onApplyFilters(): void {
        emitApplyFilters();
    }

    function onClose(): void {
        emitClose();
    }

    function inputIconLeft(): InputTagIcon {
        return leftIcon;
    }

    function inputIconRight(): InputTagIcon {
        return rightIcon;
    }

    function showClearSearchInputButton(): boolean {
        return searchString.value.trim() !== '';
    }

    function selectedParams(): FilterParams[] {
        return selectedFilters.value.filter((filter: FilterParams) => filter.selected);
    }

    function setupFilters(): void {
        const filters: FilterParams[] = JSON.parse(JSON.stringify(props.filters));
        const region: string = new AppCountry().iso().toLowerCase();
        const collator: Intl.Collator = new Intl.Collator(region, {
            ignorePunctuation: false,
            sensitivity: 'base',
            usage: 'sort'
        });
        filters.sort((param1: FilterParams, param2: FilterParams) =>
            param1.param === props.defaultFilter
                ? -1 : (param2.param === props.defaultFilter
                        ? 1
                        : collator.compare(param1.param, param2.param)
                ));
        const toggleFilter: FilterParams = {
            type: toggleFilterType,
            param: translateForType(props.toggleFilterLabel, props.translationType),
            selected: false
        };
        if (isSet(toggleFilter)) {
            selectedFilters.value.push(toggleFilter);
        }
        selectedFilters.value = selectedFilters.value.concat(filters);
        setToggleFilterState();
    }

    function setDefaultFilter(): void {
        clearFilters();
        if (selectedFilters.value.length > 0) {
            selectedFilters.value[1].selected = true;
        }
    }

    function setFiltersState(selected: boolean): void {
        selectedFilters.value.forEach((filter: FilterParams) => filter.selected = selected);
    }

    function setToggleFilterState(): void {
        const toggleFilter: FilterParams = selectedFilters.value.find((filter: FilterParams) => filter.type === toggleFilterType) as FilterParams;
        if (isSet(toggleFilter)) {
            toggleFilter.selected = isToggleFilterSelected.value;
        }
    }

    function clearFilters(): void {
        selectedFilters.value.forEach((filter: FilterParams) => filter.selected = false);
    }
</script>
<template>
    <div class="popup-filter">
        <div class="label header">
            <div class="title">{{ translateForType(title, translationType) }}</div>
            <button class="close-button"
                    @click="onClose()">
                <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L1 13M1 1L13 13" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <app-input-text
            name="search-box"
            class="search-box"
            v-model="searchString"
            :placeholder="translateForType(searchInputPlaceholder, translationType)"
            @keyup="onSearchFilter()"
            @keyup.native.enter="onSearchFilter()"
            :left-icon="inputIconLeft()"
            @left-icon-click="onSearchFilter()"
            :right-icon="showClearSearchInputButton() ? inputIconRight() : undefined"
            @right-icon-click="onClearSearchString()">
        </app-input-text>
        <div class="selected-filters">
            <div v-for="(filter, index) in selectedFilters" :key="index">
                <div v-if="(filter.selected && !isToggleFilterSelected)
            || (index === 0 && isToggleFilterSelected)"
                     class="filter">
                    {{ filter.param }}
                    <svg class="remove"
                         @click="onRemoveFilter(filter.param)" width="10" height="10" viewBox="0 0 10 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 1L1 9M1 1L9 9" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <ul class="search-results">
            <li v-for="(filter, index) in selectedFilters" :key="index"
                class="item"
                @click="onSelectFilter(filter.param)">
                <svg v-if="(filter.selected && !isToggleFilterSelected) || (index === 0 && isToggleFilterSelected)"
                     class="check" width="14" height="11" viewBox="0 0 14 11" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 6.3L4.42857 9.5L13 1.5" stroke="#00B4AD" stroke-width="1.5"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ filter.param }}
            </li>
        </ul>
        <div class="buttons">
            <button class="button outside reset" @click="onResetFilters()">
                {{ translateForType('map_filter_reset_button', translationType) }}
            </button>
            <button class="button red apply-filter"
                    v-bind:disabled="selectedFilters.length === 0"
                    @click="onApplyFilters()"
                    v-text="translateForType('map_filter_apply_button', translationType)">
            </button>
        </div>
    </div>
</template>
<style lang="scss" scoped>
$item-height: 57px;
$scroll-at-count: 6;

.popup-filter {
    display: flex;
    flex-direction: column;
    position: fixed;
    border: 1px solid var(--component-color-border-default);
    background-color: var(--white);
    top: 50%;
    left: 50%;
    width: 100%;
    border-radius: 12px;
    padding: var(--size-normal);
    transform: translate(-50%, -50%);
    z-index: 100;

    .header {
        display: flex;
        margin-top: 9px;
        margin-bottom: var(--size-normal);

        .title {
            font-size: var(--font-size-small);
            font-weight: bolder;
            width: 100%;
        }

        .close-button {
            width: 24px;
            height: 24px;

            &:hover {
                path {
                    stroke: var(--text-color-default);
                }
            }
        }
    }

    .search-box {
        display: flex;
    }

    .selected-filters {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        white-space: pre-wrap;
        text-indent: 10px;
        margin-top: var(--size-normal);

        .filter {
            display: flex;
            background-color: var(--black-50);
            font-size: var(--font-size-pico);
            align-items: center;
            border-radius: 14px;
            padding: 0 var(--size-pico) 0 6px;
            margin: 0 var(--size-pico) var(--size-pico) 0;

            .remove {
                margin-left: var(--size-pico);
                margin-right: 6px;
                cursor: pointer;
            }

            &:hover {
                path {
                    stroke: var(--brand-teal);
                }
            }
        }
    }

    .search-results {
        height: 340px;
        overflow-y: auto;
        margin-bottom: 18px;
        margin-top: 8px;

        .item {
            line-height: $item-height;
            cursor: pointer;

            &:not(:first-child) {
                border-top: 1px solid var(--black-50);
            }

            &:nth-child(#{$scroll-at-count - 1}) {
                ~ :last-child {
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    position: absolute;
                    content: '';
                    width: 80%;
                    height: 92px;
                    left: 0;
                    bottom: 80px;
                    background: linear-gradient(rgb(255 255 255 / .001), var(--white));
                }
            }

            .check {
                margin-right: 10px;
            }
        }
    }

    .buttons {
        display: flex;

        .reset {
            min-width: 75px;
        }

        .apply-filter {
            width: 100%;
            margin-left: var(--size-nano);
        }
    }

    @include respond-above('sm') {
        width: 440px;
    }
}
</style>
