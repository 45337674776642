<script setup lang="ts">
    import {onMounted, PropType, reactive, Ref, computed, ref} from 'vue';
    import {useTranslate} from '@/Composables/Translate';
    import Form from '@/assets/libraries/form/form';
    import FormField from '@/assets/libraries/form/form-field';
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import {UnwrapNestedRefs} from 'vue/types/v3-generated';
    import {useHtml} from '@/Composables/Html';
    import ImageTagAttributes from '@/interfaces/image.tag.attributes';
    import AppCreditCardDetails from '@/Components/CreditCardDetails/CreditCardDetails.vue'
    import AppInputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';

    const props = defineProps({
        formField: {type: Object as PropType<FormField<DynamicDictionary>>, default: () => new FormField('')},
        dataStoreDisabled: {type: Boolean, default: false},
        cardNumbers: {type: String, default: ''},
        cardStatus: {type: String, default: ''},
        showAgreeTerms: {type: Boolean, default: true},
        type: {type: String as PropType<'add' | 'update'>, default: ''},
    });

    const emit = defineEmits([
        'card-widget-submit',
    ]);

    const {translate} = useTranslate();
    const {imgTag} = useHtml();

    const form: UnwrapNestedRefs<Form> = reactive(new Form());
    const segmentMask: string = '&nbsp;&bull;&bull;&bull;&bull;&nbsp;';
    const cardDetailsWidget: Ref<AppCreditCardDetails | undefined> = ref();
    let showCardDetails: Ref<boolean> = ref(false);

    const warningIcon: Ref<string> = computed((): string => {
        return imgTag(warningIconAttributes());
    });

    const agreeText: Ref<string> = computed((): string => {
        return translate('credit_card_widget_agree');
    });

    const buttonText: Ref<string> = computed((): string => {
        const translationId: string = props.type === 'add' ?
            'credit_card_widget_add_card' : 'credit_card_widget_button_text';

        return translate(translationId);
    });

    const numberMask: Ref<string> = computed((): string => {
        return '<div class="card-mask">'
            + '<div class="masked-numbers">'
            + '<span class="mask">' + segmentMask + '</span>'
            + props.cardNumbers + '</div></div>';
    });

    const isActiveCard: Ref<boolean> = computed((): boolean => {
        return props.cardStatus === 'active';
    });

    onMounted((): void => {
        init();
    });

    function onUpdateCardClick(): void {
        if (showCardDetails.value) {
            validateAndSubmit();
        } else {
            showCardDetails.value = true;
        }

    }

    function validateAndSubmit(): void {
        addFormValidators().then((): void => {
            form.touch().then((): void => {
                form.validate().then((): void => {
                    if (cardDetailsWidget.value) {
                        (cardDetailsWidget.value as any).touchAndValidate().then();
                        emitAndClose();
                    }
                });
            });
        });
    }

    function emitAndClose(): void {
        if (form.isValid()) {
            emit('card-widget-submit', form.field('card-details').value);
            showCardDetails.value = false;
        }
    }

    function warningIconAttributes(): ImageTagAttributes {
        const source: string = 'images/one/circle-exclamation-mark.svg';
        return {
            class: '',
            src: source,
            width: 36,
            height: 24,
        }
    }

    function init(): void {
        setupForm();
        if (props.type === 'add') {
            showCardDetails.value = true;
        }
    }

    function setupForm(): void {
        form.addField(new FormField('card-details'));
        form.addField(new FormField('agreeTerms'));
    }

    function addFormValidators(): Promise<void> {
        return new Promise(resolve => {
            if (props.showAgreeTerms && showCardDetails.value) {
                requireTermsAgreement();
            }
            resolve();
        });
    }

    function requireTermsAgreement(): void {
        form.field('agreeTerms').addValidators('checkboxChecked');
    }
</script>

<template>
    <div class="credit-card-widget"
         :id="'credit-card-widget'">
        <div class="flex align-center heavier small"
             v-if="type === 'update'">
            <div class="icon" v-html="warningIcon"></div>
            {{ translate('credit_card_widget_title_warning') }}
        </div>
        <div class="flex align-center heavier small gap"
             v-if="type === 'add'">
            {{ translate('credit_card_widget_title_add') }}
        </div>
        <div class="padded flex align-center plain nano"
             v-if="type === 'update'">
            <p class="black-text" v-html="translate('credit_card_widget_sub_title_warning')"></p>
        </div>
        <div class="card-status border-radius-small"
             v-if="!showCardDetails"
             :class="{'red-background': !isActiveCard, 'grey-background': isActiveCard}">
            <img class="icon" src="images/one/bank/generic_card.svg" alt="card-icon"/>
            <div class="mask" v-html="numberMask"></div>
        </div>
        <app-credit-card-details
            ref="cardDetailsWidget"
            v-if="showCardDetails"
            :form-field="form.field('card-details')"
            :data-store-disabled="true"></app-credit-card-details>
        <app-input-checkbox
            class="agree"
            v-if="showAgreeTerms && showCardDetails"
            :form-field="form.field('agreeTerms')"
            :data-store-disabled="true"
        ><span v-html="agreeText"></span>
        </app-input-checkbox>
        <button class="button red margin-top border-radius-small full-width"
                :id="'cardWidgetSubmit'"
                @click="onUpdateCardClick()">{{ buttonText }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
.credit-card-widget {
    width: 100%;
    padding: var(--size-normal);
    border-radius: 16px;
    scroll-margin-top: 4em;
    background-color: var(--component-color-background-base);

    .agree {
        margin-top: var(--size-small);

        ::v-deep > input {
            width: 24px;
            height: auto;
            min-height: 24px;
            padding: 0;
            border-color: var(--black-500);
        }

        ::v-deep > label {
            color: var(--black-500);
        }

        &.invalid {
            ::v-deep > input {
                border-color: var(--system-color-error-default);
            }
        }

        &.valid.touched {
            ::v-deep > input {
                border-color: var(--brand-teal);
            }
        }
    }

    &.full-width {
        @include respond-above('sm') {
            width: 100%;

            ::v-deep .credit-card-details {
                .row {
                    justify-content: space-between;
                }
            }
        }
    }

    &.no-shadow {
        box-shadow: none;
    }

    .gap {
        margin-bottom: 20px;
    }

    @include respond-above('sm') {
        width: 327px;
        box-shadow: 0 1px 0 rgb(146 151 160 / .32);

        .credit-card-details {
            ::v-deep .card-details {
                flex-direction: column;

                .input-credit-card-number {
                    width: 100%;
                }

                .row {
                    width: 100%;
                }
            }

            ::v-deep .prefill {
                width: 100%;
            }
        }
    }

    .card-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 88px;
        padding: var(--size-small);

        ::v-deep .card-mask {
            .masked-numbers {
                .mask {
                    font-size: var(--font-size-medium);
                    vertical-align: sub;
                }
            }
        }
    }

    ::v-deep label {
        color: var(--text-color-default);
    }

    .padded {
        padding-bottom: 26px;

        &:not(:first-child) {
            padding-top: 26px;
        }
    }

    .red-background {
        background-color: rgb(227 6 19 / .05);

        img {
            filter: var(--to-color-red);
        }
    }

    .grey-background {
        background-color: var(--background-light);

        img {
            filter: var(--to-color-green);
        }
    }

    .red-text {
        color: var(--brand-red);
    }

    .grey-text {
        color: var(--black-200);
    }

    .black-text {
        color: var(--text-color-default);
    }

    .flex {
        display: flex;
    }

    .space-between {
        justify-content: space-between;
    }

    .align-center {
        align-items: center;
    }

    .plain {
        font-weight: initial;
    }

    .heavy {
        font-weight: 600;
    }

    .tiny {
        font-size: var(--font-size-tiny);
    }

    .small {
        font-size: var(--font-size-small);
    }

    .nano {
        font-size: var(--font-size-nano);
    }

    .heavier {
        font-weight: 700;
    }

    .full-width {
        width: 100%;
    }

    .border-radius-small {
        border-radius: 8px;
    }

    .margin-top {
        margin-top: var(--size-normal);
    }
}
</style>
