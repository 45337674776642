<script lang="ts">

import Vue, {defineComponent, reactive, ref, Ref} from 'vue';
import OneModule from '@/interfaces/OneModuleInterface';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import OneBaseService from '@/services/OneBaseService';
import FaqListItem from '@/interfaces/faq.list.item.interface';
import {useDefine} from '@/Composables/Define';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import OneModuleService from '@/services/OneModuleService';

const {isSet} = useDefine();

export default defineComponent({
    setup() {
        const ready: Ref<boolean> = ref(false);

        const module: OneModule = OneModuleService.getInstance();

        const faqModuleForm: UnwrapNestedRefs<Form> = reactive(new Form());

        let faqBlocks: Ref<DynamicDictionary> = ref({});

        const create = (): void => {
        }

        const beforeModuleMounted = () => {
        }

        const moduleMounted = () => {
            if (!ready.value) {
                ready.value = true;
                module.onModuleReady.subscribe((): void => {
                    Vue.nextTick((): void => {
                        init();
                        setupForm();
                        module.applyModuleType();
                    });
                });
            }
        }

        const setupForm = (): void => {
            Object.keys(faqBlocks.value).forEach((key: string) => {
                faqBlocks.value[key].forEach((item: FaqListItem) => {
                    faqModuleForm.addField(new FormField(item.badgeId, false));
                });
            });
            faqModuleForm.clear().then();
            faqModuleForm.setReady();
        }

        const init = (): void => {
            faqBlocks.value = [];
            $('.items-container[data-badge-id]').each((index: number, element: HTMLElement) => {
                const $element: JQuery = $(element);
                const badgeId: string | undefined = $element.data('badge-id');
                const badgeTitle: string | undefined = $element.data('badge-title');
                const badgeAlias: string | undefined = $element.data('badge-alias');
                if (badgeId && badgeTitle && badgeAlias) {
                    if (!isSet(faqBlocks.value[badgeAlias])) {
                        faqBlocks.value[badgeAlias] = [];
                    }
                    faqBlocks.value[badgeAlias].push({
                        badgeId: badgeId,
                        badgeTitle: badgeTitle,
                        element: $element,
                        selected: false
                    });
                }
            });
        }

        const onBadgeClick = (badge: DynamicDictionary): void => {
            let selected: string = '';
            faqBlocks.value[badge.params].forEach((item: FaqListItem) => {
                if (item.badgeId === badge.name) {
                    item.selected = badge.value;
                    selected = badge.name;
                }
            });
            if (!module.scrollModeEnabled()) {
                rebuildFaqBlocksVisibility();
            } else {
                const target: JQuery = $('[data-badge-id="' + selected + '"]');
                if (target.length > 0) {
                    const offsetTop = target.offset();
                    $('html,body').animate({
                        scrollTop: offsetTop ? offsetTop.top : 0
                    }, 0);
                }
            }
        }

        const rebuildFaqBlocksVisibility = (): void => {
            Object.keys(faqBlocks).forEach((key: string) => {
                const hasSelectedBadge: boolean = faqBlocks.value[key].some((item: FaqListItem) => {
                    return item.selected;
                });
                const slideSpeed: number = 300;
                faqBlocks.value[key].forEach((item: FaqListItem) => {
                    if (item.selected || !hasSelectedBadge) {
                        item.element.stop(true, false).slideDown(slideSpeed, function () {
                            $(this).css('height', 'auto');
                        });
                    } else {
                        item.element.stop(true, false).slideUp(slideSpeed, function () {
                            $(this).css('height', 'auto');
                        });
                    }
                });
            });
        }

        const blocks = (alias: string): FaqListItem[] => {
            return isSet(faqBlocks.value[alias]) ? faqBlocks.value[alias] : [];
        }

        return {
            ...module,
            ...{
                init,
                create,
                blocks,
                beforeModuleMounted,
                moduleMounted,
                onBadgeClick,
                faqModuleForm,
                faqBlocks,
            }
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    }
});
</script>