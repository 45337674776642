<script setup lang="ts">
import { PropType } from 'vue';
import SingleAppStepper from '@/Components/SingleAppSteps/Classes/SingleAppStepper';
import SingleAppStepsEmitters from '@/Components/SingleAppSteps/Enums/SingleAppStepsEmitters';

const props = defineProps({
    steps: {
        type: Object as PropType<SingleAppStepper>, default: () => {
        }
    },
});
const emit = defineEmits([
   SingleAppStepsEmitters.StepChange,
]);

function onStepClick(index: number): void {
    props.steps.selectStep(index);
    emit(SingleAppStepsEmitters.StepChange);
}

</script>

<template>
    <section class="single-app-steps"
             v-bind:class="{disabled: false}">
        <div class="wrapper">
            <div class="application-steps">
                <template v-for="(step, index) in props.steps.local.steps">
                    <div class="application-step"
                         :key="index"
                         :id="'stepper-' + step.index"
                         :class="{
                             'current': step.current,
                             'done': step.done,
                             'next': step.next,
                         }">
                        <div class="preview"
                             :class="{'icon':step.done}">
                            <svg v-if="step.done"
                                 width="26" height="26" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="20" height="20" rx="10" fill="#00B4AD" fill-opacity="0.08"/>
                                <path d="M14 7L8.5 13L6 10.2727" stroke="#00B4AD" stroke-width="1.5"
                                      stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span v-if="(step.current || step.next)">{{ step.index }}</span>
                        </div>
                        <div class="title">{{ step.title }}</div>
                        <div class="stepper-clicker" @click="onStepClick(step.index)"></div>
                    </div>
                    <aside class="line" v-if="step.index !== steps.local.steps.length"></aside>
                </template>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.single-app-steps {
    position: sticky;
    top: 0;
    margin-bottom: 70px;
    width: 100%;
    z-index: 3;

    .wrapper {
        width: 1200px;

        .application-steps {

            .application-step {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                position: relative;

                &.enabled:focus,
                &.done:focus,
                &.current:focus,
                &.enabled:hover,
                &.done:hover,
                &.current:hover {
                    .title {
                        cursor: pointer;
                        color: var(--brand-black);
                    }
                }

                &:focus,
                &:hover {
                    &.next.enabled {
                        .preview {
                            opacity: .48;
                            color: var(--brand-black);

                            &::before {
                                opacity: .48;
                                border-color: var(--component-color-border-focus);
                            }
                        }
                    }
                }

                .preview {
                    min-width: 24px;
                    min-height: 24px;

                    &:not(.icon) {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: var(--font-size-pico);
                        font-weight: 600;
                    }
                }

                .title {
                    display: none;

                    @include respond-above('sm') {
                        display: inline-flex;
                        margin-left: var(--size-nano);
                        font-size: var(--font-size-nano);
                        font-weight: 600;
                        color: var(--system-color-success-dark);
                    }
                }

                &.current {
                    .title {
                        color: var(--brand-black);
                    }

                    .preview {
                        background: var(--brand-black);
                        color: var(--white);
                        border-radius: 100%;
                    }
                }

                &.next {
                    .title {
                        color: var(--black-500);
                    }

                    .preview {
                        position: relative;
                        color: var(--text-color-subtlest);

                        &::before {
                            content: '';
                            position: absolute;

                            @include fullscreen;
                            border: solid 2px var(--black-500);
                            border-radius: 100%;
                            opacity: .32;
                        }
                    }
                }

                .stepper-clicker {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }
    }

    @include respond-above('md') {
        margin-bottom: 110px;
    }
}
</style>
