import Vue from 'vue';
import ActiveElement from '@/assets/libraries/Dom/ActiveElement';

export default Vue.directive('reset-focus', {
    bind: (element: HTMLElement): void => {
        element.addEventListener('mouseleave', (): void => {
            if (ActiveElement.is(element)) {
               ActiveElement.blur();
            }
        })
    }
});
