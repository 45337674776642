<script setup lang="ts">
    import {computed, onMounted, Ref, watch} from 'vue';
    import {useScroll} from '@/Composables/Scroll';
    import FormField from '@/assets/libraries/form/form-field';

    const props = defineProps({
        formField: {type: FormField, default: () => new FormField('')},
        showIcon: {type: Boolean, default: true},
        showExpanded: {type: Boolean, default: false},
    });
    const isExpanded: Ref<boolean> = computed(() => props.formField.value);
    const {scrollToLast} = useScroll();

    watch(isExpanded, async (currentValue, previousValue) => {
        if (currentValue
            && currentValue !== previousValue
            && props.showIcon) {
            scrollToLast('#collapse');
        }
    }, {deep: true, immediate: true});

    onMounted((): void => {
        if (props.formField.isEmpty()) {
            props.formField.patch(props.showExpanded);
        }
    });

    function toggle(): void {
        props.formField.patch(!isExpanded.value);
    }
</script>
<template>
    <div class="expandable-accordion container"
         :id="formField.name"
         :class="{'expanded': formField.value}"
         :data-store="formField.name"
         :data-store-value="formField.value"
         :show-expanded="showExpanded"
         :show-icon="showIcon">
        <div v-if="!isExpanded" class="main-item" @click="toggle()">
            <div v-if="showIcon" ref="iconElement" class="icon">
                <slot name="icon"></slot>
            </div>
            <slot name="expand"></slot>
            <div class="right-side">
                <slot name="right-side"></slot>
            </div>
        </div>
        <div v-if="isExpanded" class="expandable">
            <slot name="expandable"></slot>
        </div>
        <div id="collapse">
            <div v-if="isExpanded" class="main-item" @click="toggle()">
                <div ref="iconElement"
                     v-if="showIcon"
                     class="icon">
                    <slot name="icon"></slot>
                </div>
                <slot name="collapse"></slot>
                <div class="right-side">
                    <slot name="right-side"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.expandable-accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    border-radius: 3px;
    margin-top: var(--size-tiny);

    * {
        pointer-events: none;
    }

    &.expanded {
        .main-item {
            color: var(--brand-teal);
        }
    }

    .main-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;
        pointer-events: auto;
        color: var(--black-600);
        cursor: pointer;

        &:hover {
            color: var(--brand-teal);
        }

        @include respond-above('xs') {
            padding: var(--size-normal);
        }

        > .icon {
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: var(--size-tiny);

            @include respond-above('xs') {
                margin-right: var(--size-normal);
            }

            ::v-deep > :first-child {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        .right-side {
            padding-left: var(--size-pico);
            align-items: center;
            padding-bottom: 3px;
        }
    }

    .expandable {
        display: block;
        flex-direction: row;
        text-align: center;
        pointer-events: auto;
        overflow: hidden;
    }

    &.expanded .expandable {
        padding: 0 var(--size-small) var(--size-small);

        @include respond-above('xs') {
            padding: 0 var(--size-normal) var(--size-normal);
        }
    }
}
</style>
