export default class LocationSearchTypes {
    public static readonly BuildingAndApartment: string = 'buildingAndApartment';
    public static readonly City: string = 'city';
    public static readonly County: string = 'county';
    public static readonly Country: string = 'country';
    public static readonly District: string = 'district';
    public static readonly Municipality: string = 'municipality';
    public static readonly Parish: string = 'parish';
    public static readonly Postal: string = 'postal';
    public static readonly Region: string = 'region';
    public static readonly Street: string = 'street';
    public static readonly Village: string = 'village';
}
