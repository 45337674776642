import ModalType from '@/Enums/ModalTypeEnum';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupIcon from '@/Enums/PopupIconEnum';
import PopupService from '@/services/custom.popup.service';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import BottomNotification from '@/services/bottom.notification.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import CssClass from '@/Enums/CssClassEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import AddPersonTab from '@/pages/LegalPerson/Workbench/Common/Tabs/AddPersonTab';
import AddPersonsFromExcelTab from '@/pages/LegalPerson/Workbench/Common/Tabs/AddPersonsFromExcelTab';
import DeletePersonTab from '@/pages/LegalPerson/Workbench/Common/Tabs/DeletePersonTab';
import DeletePersonsFromExcelTab from '@/pages/LegalPerson/Workbench/Common/Tabs/DeletePersonsFromExcelTab';
import EditPersonTab from '@/pages/LegalPerson/Workbench/Common/Tabs/EditPersonTab';
import ViewPersonTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ViewPersonTab';
import ProcessingDetailsTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ProcessingDetailsTab';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {reactive} from 'vue';
import {useDefine} from '@/Composables/Define';

export default class BusinessModal {
    public fileUploadCallback: Function = this.fileUploadReceiver.bind(this);
    public fileUploadLoaderCallback: Function = this.fileUploadLoaderReceiver.bind(this);
    public currentLoadingFileName: string = '';

    private context: DynamicDictionary = {};
    private modalDataIsEdited: boolean = false;
    private modalState: LegalPersonModalState = LegalPersonModalState.None;
    private previousModalState: LegalPersonModalState[] = [];
    private personSelectedForEdit?: LegalInsuredPerson;
    private personsRemoved?: LegalInsuredPerson[];
    private personAfterEdit?: LegalInsuredPerson;
    private fieldsAreLocked: boolean = false;

    private modalTabInstance: UnwrapNestedRefs<ModalTab> = reactive(new ModalTab());

    public addContext(context: DynamicDictionary): void {
        this.context = context;
    }

    public blockClasses(block: string): string {
        const result: string[] = [block];
        switch (block) {
            case this.app.modalPopupState.AddPersonsFromExcel:
                if (this.modalStateIs(this.app.modalPopupState.PersonsProcessingExcel)) {
                    result.push(CssClass.Hidden);
                }
                break;
            case this.app.modalPopupState.DeletePersonsFromExcel:
                if (this.modalStateIs(this.app.modalPopupState.PersonsProcessingExcel)) {
                    result.push(CssClass.Hidden);
                }
                break;

            default:
        }

        return result.join(' ');
    }

    public blockIsVisible(block: string): boolean {
        let result: boolean;
        switch (block) {
            case LegalPersonModalState.AddPersonsManual:
                result = this.modalStateIs(block) ||
                    this.modalStateIs(LegalPersonModalState.AddPersonsProcessedList)
                break;

            case LegalPersonModalState.AddPersonsFromExcel:
                result = this.modalStateIs(block) || (
                    this.modalStateIs(LegalPersonModalState.PersonsProcessingExcel) &&
                    this.previousState(LegalPersonModalState.AddPersonsFromExcel)
                );
                break;

            case LegalPersonModalState.DeletePersonsFromExcel:
                result = this.modalStateIs(block) || (
                    this.modalStateIs(LegalPersonModalState.PersonsProcessingExcel) &&
                    this.previousState(LegalPersonModalState.DeletePersonsFromExcel)
                );
                break;

            default:
                result = this.modalStateIs(block);
        }

        return result;
    }

    public closeModalPopup(): void {
        if (this.modalDataIsEdited) {
            const confirmPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup
                .withTitle(this.app.translated('sure_to_quit'))
                .withDescription(this.app.translated('data_will_be_lost'))
                .withCancelButtonText(this.app.translated('cancel'))
                .withConfirmButtonText(this.app.translated('quit'))
                .withIcon(PopupIcon.AlertTriangle)
                .withConfirmCallback('lostDataConfirmCallback', this)
                .withCancelCallback('lostDataCancelCallback', this);
            PopupService.getInstance().show(confirmPopup);
        } else {
            this.hideModalPopup();
        }
    }

    public applyModalState(state: LegalPersonModalState): BusinessModal {
        this.modalState = state;
        this.setupModalTabInstance();

        return this;
    }

    public modalStateIs(state: LegalPersonModalState): boolean {
        return this.modalState === state;
    }

    public previousState(state: LegalPersonModalState): boolean {
        let result: boolean = false;
        if (this.previousModalState.length >= 1) {
            result = this.previousModalState[this.previousModalState.length - 1] === state;
        }

        return result;
    }

    public markDataAsEdited(): void {
        this.modalDataIsEdited = true;
    }

    public showModalPopup(): void {
        this.app.showModal(ModalType.LegalPerson);
    }

    public hideModalPopup(): void {
        this.tab.destroy();
        this.unlockFields();
        this.popModalQueue();
    }

    public onDownloadExcelClick(type: string): void {
        this.app.openUrlInNewTab(
            '/templates/legal/' + (new AppCountry()).iso().toLowerCase() + '/' + type + '.xlsx'
        );
    }

    public preserveModalQueue(state: string): void {
        if (!this.previousModalState.includes(this.modalState)) {
            this.previousModalState.push(this.modalState)
        }
        this.applyModalState(state);
    }

    public pushToModalQueue(state: string): void {
        if (!this.previousModalState.includes(state)) {
            this.previousModalState.push(state)
        }
    }

    public popModalQueue(): void {
        if (this.previousModalState.length > 0) {
            this.applyModalState(this.previousModalState.pop() as string);
        } else {
            this.app.showModal(ModalType.None);
        }
    }

    public fileUploadReceiver(data: DynamicDictionary): void {
        const bottomNotification: BottomNotification = BottomNotification.getInstance();
        if (useDefine().isSet(data.errors)) {
            const detailParts: string[] = [];
            String(data.errors.detail).split(' ').forEach((part: string) => {
                detailParts.push(this.app.localized(part));
            });
            const translatedError: string = detailParts.join(' ');
            bottomNotification.pushNotification(BottomNotificationLevel.Error, translatedError);
        } else {
            const message: string = this.app.localized('file_successfully_uploaded');
            bottomNotification.pushNotification(BottomNotificationLevel.Info, message + ': ' + data.parsed.name);
            this.tab.fileUploadReceiver(data.parsed.rows);
        }
    }

    public assignPersonForEdit(person: LegalInsuredPerson): BusinessModal {
        this.personSelectedForEdit = person;

        return this;
    }

    public assignRemovedPersons(persons: LegalInsuredPerson[]): BusinessModal {
        this.personsRemoved = persons;

        return this;
    }

    public assignEditedPerson(persons: LegalInsuredPerson): BusinessModal {
        this.personAfterEdit = persons;

        return this;
    }

    public showSimpleErrorPopup(): void {
        const simpleErrorPopup: PopupBase = new OnePopup()
            .withType()
            .simpleError;
        this.app.popupServices.show(simpleErrorPopup);
    }

    private fileUploadLoaderReceiver(loading: boolean): void {
        if (loading) {
            this.preserveModalQueue(LegalPersonModalState.PersonsProcessingExcel);
        } else {
            this.popModalQueue();
        }
    }

    public lockFields(): void {
        this.fieldsAreLocked = true;
    }

    public unlockFields(): void {
        this.fieldsAreLocked = false;
    }

    public get inputFieldsAreLocked(): boolean {
        return this.fieldsAreLocked;
    }

    public get app(): DynamicDictionary {
        return this.context;
    }

    public get personForEdit(): LegalInsuredPerson {
        return this.personSelectedForEdit as LegalInsuredPerson;
    }

    public get removedPersons(): LegalInsuredPerson[] {
        return this.personsRemoved as LegalInsuredPerson[];
    }

    public get editedPerson(): LegalInsuredPerson {
        return this.personAfterEdit as LegalInsuredPerson;
    }

    public get tab(): ModalTab {
        return this.modalTabInstance as ModalTab;
    }

    public get loadingFileName(): string {
        return this.currentLoadingFileName;
    }

    public get fileUploadExtensions(): string {
        return '.xlsx, .xls';
    }

    public get fileUploadUrl(): string {
        return Url.Ajax.legalPersonDocumentUpload;
    }

    private lostDataConfirmCallback(): void {
        this.modalDataIsEdited = false;
        this.hideModalPopup();
    }

    private lostDataCancelCallback(): void {
        PopupService.getInstance().hide();
    }

    private setupModalTabInstance(): void {
        switch (this.modalState) {
            case LegalPersonModalState.AddPersonsManual:
                this.modalTabInstance = AddPersonTab.getInstance();
                break;
            case LegalPersonModalState.AddPersonsFromExcel:
                this.modalTabInstance = AddPersonsFromExcelTab.getInstance();
                break;
            case LegalPersonModalState.DeletePersons:
                this.modalTabInstance = DeletePersonTab.getInstance();
                break;
            case LegalPersonModalState.DeletePersonsFromExcel:
                this.modalTabInstance = DeletePersonsFromExcelTab.getInstance();
                break;
            case LegalPersonModalState.EditPerson:
                if (this.personSelectedForEdit) {
                    this.modalTabInstance = EditPersonTab.getInstance();
                }
                break;
            case LegalPersonModalState.ViewPerson:
                if (this.personSelectedForEdit) {
                    this.modalTabInstance = ViewPersonTab.getInstance();
                }
                break;
            case LegalPersonModalState.ProcessingDetails:
                this.modalTabInstance = ProcessingDetailsTab.getInstance();
                break;

            default:
                console.debug('setupModalTabInstance:: Unknown modal state ' + this.modalState);
                break;
        }
        this.modalTabInstance.addContext(this);
        this.modalTabInstance.init();
    }
}
