import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FakePerson from '@/interfaces/FakePersonInterface';

export default class ExcelFaker {
    private readonly countryIso: string = '';

    public constructor(countryIso: string) {
        this.countryIso = countryIso;
    }

    public fakePerson(index: number): FakePerson {
        return ExcelFaker.fakePersonsByCountry()[this.countryIso][index];
    }

    private static fakePersonsByCountry(): DynamicDictionary {
        return {
            LV: [
                {
                    resident: 'N',
                    identityNumber: '101188-99999',
                    birthDate: '01.11.1988',
                    firstName: 'ASSAN',
                    lastName: 'YU',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '01.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'Y',
                    identityNumber: '150685-12345',
                    birthDate: '15.01.1985',
                    firstName: 'LINDA',
                    lastName: 'KANNIŅA',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '15.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'Y',
                    identityNumber: '321234-56789',
                    birthDate: '10.11.1979',
                    firstName: 'ANDIS',
                    lastName: 'OZOLS',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '01.04.2024',
                    endDate: '01.05.2024',
                },
            ],
            EE: [
                {
                    resident: 'Y',
                    identityNumber: '47101010033',
                    birthDate: '01.01.1971',
                    firstName: 'Marju',
                    lastName: 'Männik',
                    program: 'BTA3 Ambulatoorne ja statsionaarne ravi',
                    startDate: '01.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'Y',
                    identityNumber: '34912159771',
                    birthDate: '15.12.1949',
                    firstName: 'Tiit',
                    lastName: 'Kask',
                    program: 'BTA3 Ambulatoorne ja statsionaarne ravi',
                    startDate: '15.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'N',
                    identityNumber: '101188-99999',
                    birthDate: '10.11.1988',
                    firstName: 'An',
                    lastName: 'Ly',
                    program: 'BTA3 Ambulatoorne ja statsionaarne ravi',
                    startDate: '01.04.2024',
                    endDate: '01.05.2024',
                },
            ],
            LT: [
                {
                    resident: 'N',
                    identityNumber: '101188-99999',
                    birthDate: '01.11.1988',
                    firstName: 'ASSAN',
                    lastName: 'YU',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '01.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'Y',
                    identityNumber: '150685-12345',
                    birthDate: '15.01.1985',
                    firstName: 'LINDA',
                    lastName: 'KANNIŅA',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '15.04.2024',
                    endDate: '01.04.2024',

                },
                {
                    resident: 'Y',
                    identityNumber: '321234-56789',
                    birthDate: '10.11.1979',
                    firstName: 'ANDIS',
                    lastName: 'OZOLS',
                    program: 'V2 Ambulatorā un stacionārā aprūpe',
                    startDate: '01.04.2024',
                    endDate: '01.05.2024',
                },
            ],
        };
    }
}
