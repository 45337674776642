import TriggeredEvent = JQuery.TriggeredEvent;

export default class LinkOnHoverService {
    public init(): void {
        const elements: JQuery = $("[data-show-url]");
        if (elements.length > 0) {
            elements.each((index: number, element: HTMLElement) => {
                $(element).on("mouseover", (event: TriggeredEvent) => {
                if($(event.target).data('exclude-show-url') !== undefined) {
                        $(element).removeAttr("href");
                    } else {
                        const urlToShow: string = $(element).data('url');
                        $(element).attr('href', urlToShow);
                    }
                });
            });
        }
    }
}
