import VueRouter, {Route, RouteConfig} from 'vue-router';
import Vue, {nextTick} from 'vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';

export default class Router {
    constructor(private routes: BackendRoute[]) {
    }

    public vueRouter(): VueRouter {
        const router: VueRouter = new VueRouter({
            mode: 'history',
            routes: this.routesConfig(this.routes),
        });
        router.afterEach((to: Route): void => {
            nextTick((): void => {
                if (to.meta?.title) {
                    document.title = to.meta.title
                }
            });
        });
        return router;
    }

    private routesConfig(routes: BackendRoute[]): RouteConfig[] {
        return routes.map((route: BackendRoute): RouteConfig => {
            return {
                name: route.name,
                path: route.path,
                meta: {title: route.title},
                component: Vue.component(route.component),
            }
        });
    }
}
