<script lang="ts">

import Vue, {defineComponent, Ref, ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {useModule} from '@/Composables/Module';
import OneModule from '@/interfaces/OneModuleInterface';
import {Observable, of} from 'rxjs';
import Url from '@/Enums/UrlEnum';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default defineComponent({
    setup() {
        const module: OneModule = useModule();
        const request: AxiosParams = useAxios();

        const ready: Ref<boolean> = ref(false);
        const waitingInProgress: Ref<boolean> = ref(false);
        const requestInProgress: Ref<boolean> = ref(false);
        const paymentCompleted: Ref<boolean> = ref(false);
        const errorOccurred: Ref<boolean> = ref(false);
        const checkStatusEachSeconds: Ref<number> = ref(5);

        const renew: boolean = true;

        const create = (): void => {
        }

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ?
                    of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    Vue.nextTick((): void => {
                        waitingInProgress.value = true;
                        makeFinishWaitingRequest();
                        module.applyModuleType();
                    });
                });
            }
        }

        const moduleMounted = () => {
            module.moduleMounted();
        }

        const makeFinishWaitingRequest = (): void => {
            if (requestInProgress.value) {
                return;
            }
            requestInProgress.value = true;
            request.get(Url.PaymentsApi.paymentsFinishWaiting)
                .then((response: DynamicDictionary) => {
                    if (((response.data as WaitingFailedResponse).status || '') === 'failed') {
                        const failedResponse: WaitingFailedResponse = response.data as WaitingFailedResponse;
                        throw new Error(failedResponse.message);
                    }
                    requestInProgress.value = false;
                    processResponse(response.data as WaitingResponse);
                })
                .catch(() => {
                    errorOccurred.value = true;
                    waitingInProgress.value = false;
                    requestInProgress.value = false;
                });
        }

        const processResponse = (waitingResponse: WaitingResponse): void => {
            paymentCompleted.value = waitingResponse.paymentCompleted;
            if (waitingResponse.orderCompleted) {
                window.location.href = waitingResponse.url;
            } else if (waitingResponse.orderCancelled) {
                errorOccurred.value = true;
                waitingInProgress.value = false;
            } else {
                const msInOneSecond: number = 1000;
                const timeout: number = checkStatusEachSeconds.value * msInOneSecond;
                setTimeout(() => makeFinishWaitingRequest(), timeout);
            }
        }


        return {
            ...module,
            ...{
                renew,
                waitingInProgress,
                requestInProgress,
                paymentCompleted,
                errorOccurred,
                create,
                beforeModuleMounted,
                moduleMounted,
            }
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    }
});

interface WaitingResponse {
    paymentCompleted: boolean;
    orderCompleted: boolean;
    orderCancelled: boolean;
    url: string;
}

interface WaitingFailedResponse {
    message: string;
    status: 'failed';
}
</script>
