import PopupBase from '@/assets/libraries/popups/popup.base';
import PopupType from '@/Enums/PopupTypeEnum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import OneConfirmBackgroundColorType from '@/assets/libraries/popups/Enums/OneConfirmBackgroundColorTypeEnum';

export default class OneConfirm extends PopupBase {
    public backgroundColorType: OneConfirmBackgroundColorType = OneConfirmBackgroundColorType.Info;

    public constructor() {
        super();
        this.applyType(PopupType.OneConfirm);
    }

    public withTitle(value: string): OneConfirm {
        this.applyTitle(value);

        return this;
    }

    public withDescription(value: string): OneConfirm {
        this.applyDescription(value);

        return this;
    }

    public withButtons(value: ButtonWithCallbackParams[]): OneConfirm {
        this.applyButtons(value);

        return this;
    }

    public withDescriptionBackgroundColorType(type: OneConfirmBackgroundColorType): OneConfirm {
        this.backgroundColorType = type;

        return this;
    }
}
