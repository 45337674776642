import BusinessTab from '@/pages/LegalPerson/Workbench/Common/Business.tab';

export default class BusinessPrograms extends BusinessTab {
    private static instance: BusinessPrograms;

    public static getInstance(): BusinessPrograms {
        if (!BusinessPrograms.instance) {
            BusinessPrograms.instance = new BusinessPrograms();
        }

        return BusinessPrograms.instance;
    }

    public init(): void {
        this.app.legalPrograms.rebuildGrid();
    }
}
