import {validate} from 'vee-validate';
import {ValidationResult} from 'vee-validate/dist/types/types';
import {Subject} from 'rxjs';
import Error from '@/services/error.service';
import {isObject} from 'rxjs/internal/util/isObject';
import Value from '@/assets/libraries/form/value';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Debounce from '@/services/debounce.service';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';

export type SanitizerCallback = (value: string) => string;
type ValidatorCallback = (value: LimitedVariant) => boolean;

interface Validator { name: string; isValid: ValidatorCallback; }

export default class FormField<ValueType = LimitedVariant> {

    public value: any = '';
    public isValid: boolean = false;
    public isTouched: boolean = false;
    public isRestored: boolean = false;
    public name: string = '';
    public onPatch: Subject<any> = new Subject();
    public onTouch: Subject<void> = new Subject();
    public onUntouched: Subject<void> = new Subject();
    public onClear: Subject<void> = new Subject();
    public onRestore: Subject<any> = new Subject();

    private sanitizer?: SanitizerCallback;
    private localValidators: string = '';
    private localCustomValidators: Validator[] = [];
    private localErrors: string[] = [];
    private isLazy: boolean = false;
    private sanitizeDebounce: Function =
        Debounce.getInstance().applyDebounce(this.sanitizeDebounceCallback, this);

    public constructor(
        name: string,
        value: any = '',
        validators: string | Record<string, any> = '',
        sanitizer?: SanitizerCallback
    ) {
        this.name = name;
        this.value = value;
        this.sanitizer = sanitizer;
        this.addValidators(validators);
    }

    public classes(): {
        error: boolean;
        valid: boolean;
        invalid: boolean;
        touched: boolean;
        untouched: boolean;
    } {
        return {
            error: this.isTouched && !this.isValid,
            valid: this.isValid,
            invalid: !this.isValid,
            touched: this.isTouched,
            untouched: !this.isTouched
        };
    }

    public touch(): FormField {
        this.isTouched = true;
        this.onTouch.next();
        return this as FormField;
    }

    public makeLazy(): void {
        this.isLazy = true;
    }

    public setValue(value: ValueType): void {
        this.value = value;
        this.sanitize().validate().then();
    }

    public setIsValid(value: boolean): void {
        this.isValid = value;
        this.sanitize().validate().then();
    }

    public patch(value: ValueType, touch: boolean = true): void {
        this.value = value;
        if (touch) {
            this.touch().sanitize().validate().then();
            this.onPatch.next(this.value);
        } else {
            this.sanitize().validate().then();
        }
    }

    public markAsUntouched(): void {
        this.isTouched = false;
        this.onUntouched.next();
    }

    public markAsRestored(): void {
        this.isRestored = true;
        this.onRestore.next(this.value);
    }

    public markAsFresh(): void {
        this.isRestored = false;
        this.onRestore.next(this.value);
    }

    public isEmpty(): boolean {
        return new Value(this.value).isEmpty();
    }

    public isNotEmpty(): boolean {
        return !this.isEmpty();
    }

    public isThisField(fieldName: string): boolean {
        return this.name === fieldName;
    }

    public addValidators(validators: string | Record<string, any>): FormField {
        this.sortAndAppendValidators(validators);
        this.sanitize();
        this.validate().then();

        return this as FormField;
    }

    public addSanitizer(sanitizer: SanitizerCallback): FormField {
        this.sanitizer = sanitizer;

        return this as FormField;
    }

    private sanitizeDebounceCallback(): void {
        if (this.sanitizer) {
            this.value = this.sanitizer(this.value);
        }
    }

    public sanitize(): FormField {
        if (this.isLazy) {
            this.sanitizeDebounce();
        } else {
            this.sanitizeDebounceCallback()
        }

        return this as FormField;
    }

    public validate(): Promise<void> {
        return new Promise(resolve => {
            this.validateDebounceCallback().then(() => {
                resolve();
            });
        });
    }

    private validateDebounceCallback(): Promise<void> {
        this.localErrors = [];
        return new Promise(resolve => {
            validate(this.value, this.localValidators).then((validationResult: ValidationResult) => {
                this.isValidAllCustomValidators().then(isValidAllCustomValidators => {
                    this.isValid = validationResult.valid && isValidAllCustomValidators;
                    resolve();
                });
            }).catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, 'FormField::validate()', reason);
                this.isValid = false;
            });
        });
    }

    public clear(): Promise<void> {
        this.value = this.clearValue(this.value);
        this.isTouched = false;
        this.onClear.next();
        return this.validate().then();
    }

    public errors(): string[] {
        return this.localErrors;
    }

    public hasError(validatorName: string): boolean {
        return this.localErrors.find(
            (validator: string) => validator === validatorName
        ) !== undefined;
    }

    public clearCustomValidators(): void {
        this.localCustomValidators = [];
    }

    public clearValidators(): void {
        this.localValidators = '';
    }

    public clearSanitizer(): void {
        this.sanitizer = undefined;
    }

    private clearValue(value: any): object | string {
        let result: any = '';

        if (isObject(value)) {
            result = {};
            Object.keys(value).forEach(
                (propertyName: string) => result[propertyName] = this.clearValue(
                    value[propertyName]
                )
            );
        }

        return result;
    }

    private async isValidAllCustomValidators(): Promise<boolean> {
        return Promise.all(
            this.localCustomValidators.map((validator: Validator): boolean => {
                const isValid: boolean = validator.isValid(
                    this.value as LimitedVariant
                );
                if (!isValid) {
                    this.localErrors.push(validator.name);
                }
                return isValid;
            })
        ).then((
            results: boolean[]
        ): boolean => !results.some((result: boolean): boolean => !result));
    }

    private sortAndAppendValidators(
        validators: string | Record<string, string | ValidatorCallback>
    ): void {
        if (typeof validators === 'string') {
            this.appendLocalValidator(validators);
        } else {
            Object.keys(validators).forEach((validatorName: string): void => {
                const validator: string | ValidatorCallback = validators[validatorName];
                if (typeof validators[validatorName] === 'string') {
                    this.appendLocalValidator(validator as string);
                }
                if ({}.toString.call(validator) === '[object Function]') {
                    this.appendLocalCustomValidator({
                        name: validatorName,
                        isValid: validator as ValidatorCallback,
                    });
                }
            });
        }
    }

    private appendLocalValidator(validator: string): void {
        this.localValidators = this.localValidators.split('|').concat(validator.split('|'))
            .filter(currentValidator => currentValidator !== '')
            .join('|');
    }

    private appendLocalCustomValidator(validator: { name: string, isValid: Function }): void {
        this.localCustomValidators =
            this.localCustomValidators.filter(localValidator => localValidator.name !== validator.name);
        this.localCustomValidators.push(validator as Validator);
    }
}
