export default class AgreementTypes {
    public static readonly Accident: string = 'accident';
    public static readonly Casco: string = 'casco';
    public static readonly Health: string = 'health';
    public static readonly Liability: string = 'liability';
    public static readonly Mtpl: string = 'mtpl';
    public static readonly Other: string = 'other';
    public static readonly Property: string = 'property';
    public static readonly Travel: string = 'travel';
}
