import SettingsService from '@/services/settings.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Error from '@/services/error.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import CookieDate from '@/interfaces/cookie.date.interface';
import Vue from 'vue';

export default class CookiesNotifications {
    private static instance: CookiesNotifications;
    private readonly CookieName: string = 'cookiesOptions';
    private readonly RestrictedCookieName: string = 'cookiesRestricted';
    private readonly CookiePerformance: string = '#cookie-performance';
    private readonly CookieMarketing: string = '#cookie-marketing';
    private readonly CookieEditButton: string = 'cookie-options';
    private cookieIsSet: boolean = true;
    private showSettings = false;
    private disabled: boolean = false;

    public static getInstance(): CookiesNotifications {
        if (!CookiesNotifications.instance) {
            CookiesNotifications.instance = new CookiesNotifications();
        }

        return CookiesNotifications.instance;
    }

    public init(): Promise<void> {
        if (this.hasLegacyCookies()) {
            this.handleLegacyCookies();
        }
        if (!this.disabled) {
            const cookieOptionBtn: Element = document.getElementsByClassName(this.CookieEditButton)[0];
            if (cookieOptionBtn) {
                cookieOptionBtn.addEventListener("click", (e: Event) => this.editCookieSettings(e));
            }
            if (!(this.hasCookieSet(this.RestrictedCookieName) || this.hasCookieSet(this.CookieName))) {
                this.cookieIsSet = false;
                this.applyFocus();
            }
        }

        return Promise.resolve();
    }

    public get isSetCookie(): boolean {
        return this.cookieIsSet;
    }

    public get showModule(): boolean {
        const hasSessionEmbedMode: boolean = SettingsService.getInstance().value('VIEW_MODE') === 'embed';

        return !this.cookieIsSet && !hasSessionEmbedMode && !this.isSpecialViewMode();
    }

    public disableNotification(params: LimitedVariant): void {
        try {
            const decodedParams: DynamicDictionary = JSON.parse(params as string);
            if (typeof decodedParams.disable !== 'undefined') {
                this.disabled = decodedParams.disable;
            }
        } catch (reason) {
            Error.log(ErrorType.Error, 'disableCookiesNotification', reason as DynamicDictionary)
        }
    }

    private isSpecialViewMode(): boolean {
        const search: string = window.location.search;

        return search.includes('mode=embed') || search.includes('iframe=true');
    }

    private onOpenSettingsClick(): void {
        this.showSettings = true;
        this.applyFocus();
        if (this.hasCookieSet(this.CookieName)) {
            Vue.nextTick(() => {
                const cookie: undefined | string = document.cookie.split(';').find(
                    (item) => item.trim().startsWith(this.CookieName));
                if (cookie) {
                    const consents: { ad_storage: number, analytics_storage: number } =
                        JSON.parse(cookie.replace(this.CookieName + '=', ''));
                    if (consents.analytics_storage === 1) {
                        $(this.CookiePerformance + ' input').prop('checked', true);
                    }
                    if (consents.ad_storage === 1) {
                        $(this.CookieMarketing + ' input').prop('checked', true);
                    }
                }
            });
        }
    }

    private onCloseSettingsClick(): void {
        this.closeSettings();
    }

    private onCancelCookieClick(): void {
        const dates: CookieDate = this.cookieDates();
        document.cookie = this.RestrictedCookieName + '=true' +
            ';path=/;expires=' + dates.expireDate.toUTCString() + ';';
        if (this.hasCookieSet(this.CookieName)) {
            this.unsetCookie(this.CookieName);
        }
        this.cookieIsSet = true;
    }

    private onStoreCookiesClick(): void {
        let myObject: string = JSON.parse('{"ad_storage":1,"analytics_storage":1}');
        if (this.hasCookieSet(this.RestrictedCookieName)) {
            this.unsetCookie(this.RestrictedCookieName);
        }

        const daysToAdd: number = 14;
        const monthToAdd: number = 3;
        const dates: CookieDate = this.cookieDates();
        if (this.showSettings) {
            const performanceCookieConsent: number = $(this.CookiePerformance + ' input:checked').length;
            const marketingCookieConsent: number = $(this.CookieMarketing + ' input:checked').length;
            myObject = JSON.parse(
                '{"ad_storage":' + marketingCookieConsent + ',"analytics_storage":' + performanceCookieConsent + '}');
            if (performanceCookieConsent === 0 && marketingCookieConsent === 0) {
                dates.expireDate = new Date(dates.year, dates.month, dates.day + daysToAdd);
            } else if (performanceCookieConsent === 0 || marketingCookieConsent === 0) {
                dates.expireDate = new Date(dates.year + 1, dates.month + monthToAdd, dates.day);
            }
            if (!marketingCookieConsent) {
                const fbclid: string | null = new URLSearchParams(window.location.search).get('fbclid');
                if (fbclid !== null) {
                    this.saveFbcInSession(fbclid)
                }
            }
        }
        this.sendCookieConsentUpdate()
        document.cookie = this.CookieName + '=' + JSON.stringify(myObject) +
            ';path=/;expires=' + dates.expireDate.toUTCString() + ';';
        this.cookieIsSet = true;
        this.closeSettings();
    }

    private get settingsService(): SettingsService {
        return SettingsService.getInstance();
    }

    private closeSettings(): void {
        this.showSettings = false;
        this.applyFocus();
    }

    private hasCookieSet(name: string): boolean {
        return document.cookie.split(';').some((item) => item.trim().startsWith(name));
    }

    private cookieDates(): CookieDate {
        const today: Date = new Date();
        const year: number = today.getFullYear();
        const month: number = today.getMonth();
        const day: number = today.getDate();

        return new class implements CookieDate {
            public day: number = day;
            public month: number = month;
            public year: number = year;
            public expireDate: Date = new Date(year + 1, month, day);
        }
    }

    private saveFbcInSession(fbclid: string): void {
        const version: string = 'fb';
        const subDomainIndex: string = '1';
        sessionStorage.setItem('_fbc', [version, subDomainIndex, Math.floor(Date.now() / 1000).toString(), fbclid].join('.'));
    }

    private applyFocus(): void {
        Vue.nextTick(() => {
            $('.focus').find(':focusable').eq(0).trigger('focus');
        });
    }

    private editCookieSettings(e: Event): void {
        e.preventDefault();
        this.cookieIsSet = false;
        this.applyFocus();
    }

    private unsetCookie(name: string): void {
        document.cookie = name + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    }

    private sendCookieConsentUpdate(): void {
        const consentUpdateTimer: number = 500;
        const consentUpdatedTimer: number = 800;
        window.setTimeout(() => {
            (<DynamicDictionary>window).dataLayer.push({'event': 'consent-update'});
        }, consentUpdateTimer);
        window.setTimeout(() => {
            (<DynamicDictionary>window).dataLayer.push({'event': 'consent-updated'});
        }, consentUpdatedTimer);
    }

    private hasLegacyCookies(): boolean {
        const cookiesOptions: string = this.getCookieOptions();
        return cookiesOptions.includes('marketing') || cookiesOptions.includes('performance');
    }

    private handleLegacyCookies(): void {
        const cookiesOptions: string = this.getCookieOptions();
        const cookieTitle: string = 'cookiesOptions=';
        const optionsString: string = cookiesOptions.slice(cookiesOptions.indexOf(cookieTitle) + cookieTitle.length);
        const {performance, marketing} = JSON.parse(optionsString);
        if (performance && marketing) {
            this.overrideLegacyCookie();
        } else {
            this.deleteLegacyCookie();
        }
    }

    private overrideLegacyCookie(): void {
        const myObject: string = JSON.parse('{"ad_storage":1,"analytics_storage":1}');
        const dates: CookieDate = this.cookieDates();
        document.cookie = this.CookieName + '=' + JSON.stringify(myObject) +
            ';path=/;expires=' + dates.expireDate.toUTCString() + ';';
    }

    private deleteLegacyCookie(): void {
        const {year, month, day} = this.cookieDates();
        const expireDate: Date = new Date(year - 1, month, day);
        document.cookie = `${this.CookieName}=; Path=/; Expires=${expireDate.toUTCString()};`;
        this.cookieIsSet = false;
    }

    private getCookieOptions(): string {
        return document.cookie.split(';').find((item: string) => item.includes('cookiesOptions')) ?? '';
    }
}
