import DateFormat from '@/Enums/DateFormatEnum';
import moment from 'moment';
import AppCountry from '@/assets/libraries/app/app-country';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';

export const useDate = (): DateParams => {

    const date = (dateToFormat: Date): string => {
        return moment(dateToFormat).format(DateFormat.Default.Short);
    }

    const dateString = (
        dateToFormat: string,
        formatFrom: string = DateFormat.Default.Short,
        formatTo?: string
    ): string => {
        const hasTime: boolean = dateToFormat.includes(' ');
        const sourceFormat: string = hasTime && formatFrom === DateFormat.Default.Short
            ? DateFormat.Default.Long : formatFrom;
        let targetFormat: string | undefined = formatTo;
        if ((new AppCountry()).isLT() && targetFormat === undefined) {
            targetFormat = hasTime ? DateFormat.Lt.Long : DateFormat.Lt.Short;
        }
        return targetFormat !== undefined
            ? moment(dateToFormat, sourceFormat).format(targetFormat)
            : dateToFormat;
    }

    const age = (value: string | Date): number => {
        return moment().diff(moment(value), 'years');
    }

    const monthsDiff = (startDate: string | Date, endDate: string | Date): number => {
        return Math.ceil(new MomentBuilder().withInitialDate(endDate).build().startOf('day')
            .diff(new MomentBuilder().withInitialDate(startDate).build().startOf('day'), 'months', true));
    }

    return {
        date,
        dateString,
        age,
        monthsDiff,
    }
}

export interface DateParams {
    date: (dateToFormat: Date) => string;
    dateString: (dateToFormat: string, formatFrom?: string, formatTo?: string) => string;
    age: (value: string | Date) => number;
    monthsDiff: (startDate: string | Date, endDate: string | Date) => number;
}
