<script setup lang="ts">
import { PropType } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import MovablePropertyDeviceOption from '@/Components/OptionsFieldList/Interfaces/MovablePropertyDeviceOption';
import MovablePropertyDeviceOptionEmitters
    from '@/Components/OptionsFieldList/Enums/MovablePropertyDeviceOptionEmitters';

const props = defineProps({
    dataStoreDisabled: {type: Boolean, default: false},
    option: {
        type: Object as PropType<MovablePropertyDeviceOption>, default: () => {
        }
    },
});
const translationType: string = 'items_and_belongings';
const {translateForType} = useTranslate();
const emit = defineEmits([MovablePropertyDeviceOptionEmitters.Toggle]);

function onOptionSelected(): void {
    emit(MovablePropertyDeviceOptionEmitters.Toggle, {
        [props.option.device.optionIc]: {
            state: !props.option.enabled.value,
            value: props.option.field.value,
        },
    });
}

</script>

<template>
    <button class="movable-property-device-option block"
            :class="{'enabled': props.option.enabled.value}"
            @click="onOptionSelected()">
        <span class="radio-icon"></span>
        <span class="title">{{ translateForType(option.device.optionIc, translationType) }}</span>
    </button>
</template>

<style lang="scss" scoped>
.movable-property-device-option {
    background: var(--white);
    width: 100%;
    border-radius: var(--size-pico);
    padding: var(--size-small);
    border: 2px solid transparent;
    position: relative;
    font-size: var(--font-size-tiny);

     &.block {
         display: block;
     }

    &.enabled {
        border-color: var(--component-color-border-active);
        background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%), var(--white);

        .radio-icon {
            display: block;
        }

        &::before {
            background-color: var(--system-color-success-dark);
            border-color: var(--system-color-success-dark);
        }
    }

    .title {
        padding: 0 var(--size-small);
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -12px;
        margin-left: 14px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid var(--component-color-border-default);
        background-color: var(--white);
    }

    .radio-icon {
        display: none;
        position: absolute;
        top: 50%;
        margin-top: -4px;
        left: 22px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--white);
    }
}
</style>
