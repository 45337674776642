import Vue, {VNode} from 'vue';
import {DirectiveBinding} from 'vue/types/options';
import ClickOutsideElement from '@/interfaces/click.outside.element.interface';
import ClickOutsideNode from '@/interfaces/click.outside.node.interface';
import VueModel from '@/services/vue.model.service';
import VueEvent from '@/Classes/VueEventClass';
import TriggeredEvent = JQuery.TriggeredEvent;
import {LimitedVariant} from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useDefine} from '@/Composables/Define';

export default Vue.directive('click-outside', {
    inserted(el: HTMLElement, binding: DirectiveBinding, node: VNode): void {
    },

    bind(el: HTMLElement, binding: DirectiveBinding, node: VNode): void {
        if (!el.id) {
            console.debug('Directive[click-outside] binding element has no unique id!');
        }
        ClickOutsideEmitter.create(el, el.id || binding.name, binding, node);
    },

    unbind(el: HTMLElement, binding: DirectiveBinding, node: VNode): void {
        ClickOutsideEmitter.release(el, el.id || binding.name);
    }
});

class ClickOutsideEmitter {
    public static emitters: ClickOutsideElement = new class implements ClickOutsideElement {
        [key: string]: ClickOutsideNode;
    };

    public static create(el: HTMLElement, emitterName: string, binding: DirectiveBinding, node: VNode): void {
        this.emitters[emitterName] = new class implements ClickOutsideNode {
            public el: HTMLElement = el;
            public binding: DirectiveBinding = binding;
            public node: VNode = node;
            public onElementClick: LimitedVariant = (e: MouseEvent): void => {
                e.stopPropagation();
            };
            public onBodyClick: LimitedVariant = (e: TriggeredEvent): void => {
                const context: Vue | undefined = ClickOutsideEmitter.emitters[emitterName].node.context;
                const method: Function | undefined | string =
                    ClickOutsideEmitter.emitters[emitterName].binding.expression;
                if (method) {
                    if (useDefine().isCallable(method)) {
                        (method as unknown as Function)(new VueEvent(e));
                    } else {
                        VueModel.callMethodByName(method, context as DynamicDictionary, new VueEvent(e));
                    }
                } else {
                    const event: CustomEvent = new CustomEvent(ClickOutsideEmitter.emitters[emitterName].binding.name);
                    ClickOutsideEmitter.emitters[emitterName].el.dispatchEvent(event);
                }
            };
        };

        el.addEventListener('click', this.emitters[emitterName].onElementClick);
        document.body.addEventListener('click', this.emitters[emitterName].onBodyClick);
    }

    public static release(el: HTMLElement, emitterName: string): void {
        el.removeEventListener('click', this.emitters[emitterName].onElementClick);
        document.body.removeEventListener('click', this.emitters[emitterName].onBodyClick);
    }
}




