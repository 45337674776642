import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import BusinessTab from '@/pages/LegalPerson/Workbench/Common/Business.tab';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';

export default class BusinessInsuredPersons extends BusinessTab {
    private static instance: BusinessInsuredPersons;

    public static getInstance(): BusinessInsuredPersons {
        if (!BusinessInsuredPersons.instance) {
            BusinessInsuredPersons.instance = new BusinessInsuredPersons();
        }

        return BusinessInsuredPersons.instance;
    }

    public init(): void {
        this.app.insuredPersons.rebuildGrid();
    }

    public onPolicyChange(policy: string): void {
        this.app.insuredPersons.rebuildGrid();
    }

    public onShowAddPersonsModalClick(): void {
        this.app.businessModal
            .applyModalState(LegalPersonModalState.AddPersonsManual)
            .showModalPopup();
    }

    public onBulkRemovePersonsModalClick(): void {
        const hasSelectedRows: boolean = this.app.insuredPersons.selectedRowsCount > 0;
        const tab: LegalPersonModalState = hasSelectedRows ?
            LegalPersonModalState.DeletePersons : LegalPersonModalState.DeletePersonsFromExcel;
        if (hasSelectedRows) {
            this.app.businessModal.lockFields();
        }
        this.app.businessModal.applyModalState(tab);
        this.app.businessModal.showModalPopup();
    }

    public get removeButtonParams(): ButtonWithCallbackParams {
        const selectedCount: number = this.app.insuredPersons.selectedRowsCount;
        const title: string = selectedCount ?
            this.app.translated('remove_selected_count', {'%count%': selectedCount}) :
            this.app.translated('bulk_remove');

        return new class implements ButtonWithCallbackParams {
            [key: string]: string | undefined | boolean | DynamicDictionary;

            public title: string = title;
            public icon: string = ButtonIcon.Delete;
            public iconColor: string = ButtonIconColor.Red;
            public backgroundColorHover: string = ButtonIconColor.Grey;
        };
    }
}
