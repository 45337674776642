<script setup lang="ts">
import {computed, onBeforeUnmount, onMounted, PropType, reactive, ref, Ref, UnwrapNestedRefs, watch} from 'vue';
import {Subject, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import uuid from 'uuid';
import FormField from '@/assets/libraries/form/form-field';
import Translations from '@/services/translations.service';
import VueEvent from '@/Classes/VueEventClass';

const props = defineProps({
    minValue: {type: Number, default: 0},
    maxValue: {type: Number, default: 99999},
    formField: {type: Object as PropType<FormField<Number>>, default: () => new FormField('')},
    disabled: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    label: {type: String, default: ''},
    value: {type: Number, default: 0},
});

const emit = defineEmits(['change']);

const translations: Translations = Translations.getInstance();

const field: UnwrapNestedRefs<FormField<Number>> = reactive(props.formField);
const isDisabled: Ref<boolean> = ref(props.disabled);
const onInputSubject: Subject<void> = new Subject();
const id: string = uuid.v4();
let onInputSubjectSubscribe: Subscription | null = null;


const isDisabledMinus: Ref<boolean> = computed(() => {
    return field.value === props.minValue;
});

const isDisabledPlus: Ref<boolean> = computed(() => {
    return field.value === props.maxValue;
});


watch(() => props.value, (value, previousValue): void => {
    if (value !== previousValue) {
        field.patch(value);
        field.validate();
        emit('change', field.value);
    }
}, {immediate: true, deep: true});

watch(() => props.disabled, (): void => {
    checkDisabledStatus();
}, {immediate: true, deep: true});

watch(() => props.maxValue, (value, previousValue): void => {
    if (value !== previousValue) {
        if (field.value.value > value) {
            field.value.patch(value);
        }
    }
}, {immediate: true, deep: true});

onMounted(() => {
    onInputSubjectSubscribe = onInputSubject.pipe(debounceTime(10)).subscribe(() => {
        notifyAboutChangedValue();
    });
    checkDisabledStatus();
});

onBeforeUnmount((): void => {
    if (onInputSubjectSubscribe) {
        onInputSubjectSubscribe.unsubscribe();
    }
})

function ariaLabelText(mode: string): string {
    const translatedMode = translations.localized(mode);

    return props.label ? props.label + ' ' + translatedMode : translatedMode;
}

function increase(event: VueEvent): void {
    field.value = sanitize(parseInt(props.formField.value, 10) + 1);
    onInputSubject.next();
}

function decrease(event: VueEvent): void {
    field.value = sanitize(parseInt(props.formField.value, 10) - 1);
    onInputSubject.next();
}

function checkDisabledStatus(value: number | null = null): void {
    isDisabled.value = props.disabled || value !== null;
}

function notifyAboutChangedValue(): void {
    emit('change', field.value);
}

function sanitize(value: number): number {
    if (value < props.minValue) {
        value = props.minValue;
    }
    if (value > props.maxValue) {
        value = props.maxValue;
    }

    return value;
}
</script>

<template>
    <div class="input input-counter"
         :id="field.name"
         :class="{...field.classes(), 'disabled': isDisabled}"
         :data-store="dataStoreDisabled ? '' : field.name"
         :data-store-value="dataStoreDisabled ? '' : field.value">
        <div class="wrapper">
            <div :id="id" class="counter">
                <button class="icon icon-minus"
                        :id="field.name + '-decrease'"
                        :aria-label="ariaLabelText('decrease')"
                        @click="decrease(new VueEvent($event))">
                    <span class="minus"
                          :class="{'minus-disabled' : isDisabledMinus}"
                    ></span>
                </button>
                <div class="value">{{ field.value }}</div>
                <button class="icon icon-plus"
                        :id="field.name + '-increase'"
                        :aria-label="ariaLabelText('increase')"
                        @click="increase(new VueEvent($event))">
                    <span class="plus"
                          :class="{'plus-disabled' : isDisabledPlus}"
                    ></span>
                </button>
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
.input {
    &.invalid {
        &.input-counter {
            border-color: var(--system-color-error-default);
        }
    }

    &.input-counter {
        position: relative;
        width: 100%;
        max-width: 144px;
        height: 52px;
        border: 1px solid var(--black-200);
        border-radius: 3px;
        color: var(--black-200);

        .wrapper {
            .counter {
                position: relative;
            }

            .icon {
                position: absolute;
                width: 22px;
                height: 22px;
                top: 14px;

                &-minus {
                    left: 20px;
                }

                &-plus {
                    right: 20px;
                }
            }

            .minus {
                position: absolute;
                width: 18px;
                height: 2px;
                left: 2px;
                top: 10px;
                background: var(--brand-red);

                &-disabled {
                    background: var(--text-color-disabled);
                }
            }

            .plus {
                position: absolute;
                width: 18px;
                height: 2px;
                right: 2px;
                top: 10px;
                background: var(--brand-red);

                &::after {
                    position: absolute;
                    content: '';
                    width: 2px;
                    height: 18px;
                    left: 8px;
                    top: -8px;
                    background: var(--brand-red);
                }

                &-disabled {
                    background: var(--text-color-disabled);

                    &::after {
                        background: var(--text-color-disabled);
                    }
                }
            }

            .value {
                position: absolute;
                height: 24px;
                width: 40px;
                top: 13px;
                left: 45px;
                font-size: var(--font-size-tiny);
                color: var(--text-color-default);
                text-align: center;
                line-height: 24px;
                font-weight: 600;

                @include respond-below('sm') {
                    width: calc(100% - 88px);
                }
            }

            .label-container {
                position: absolute;
                left: 110px;
                width: 80px;
                height: 44px;
                top: 4px;
                display: flex;
                align-items: center;

                .label {
                    font-size: var(--font-size-nano);
                }
            }
        }
    }
}

.disabled {
    .wrapper {
        > .select {
            pointer-events: none;

            > button {
                background-color: var(--component-color-background-disabled);

                .text {
                    color: var(--component-color-text-value);
                }
            }
        }
    }
}

</style>
