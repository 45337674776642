import VueRouter from 'vue-router';
import Vue from 'vue';
import {ComponentPublicInstanceConstructor} from 'vue/types/v3-component-public-instance';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import Router from '@/Core/Routing/Router';
import { createPinia, Pinia } from 'pinia';

export default class VueApp {
    private routes: BackendRoute[] = [];

    constructor(private readonly appComponent: ComponentPublicInstanceConstructor) {
    }

    public withRoutes(routes: BackendRoute[]): this {
        this.routes = routes;
        return this;
    }

    public mount(selector: string): Vue {
        const pinia: Pinia = createPinia();
        const router: VueRouter = new Router(this.routes).vueRouter();
        return new Vue({...this.appComponent, router: router, pinia: pinia}).$mount(selector);
    }
}
