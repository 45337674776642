import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export const useSorting = (): SortingParams => {
    /**
     * @param value can be any simple object; nested objects & arrays are not sorted
     * @return original object with alphabetically sorted keys
     */
    const sortedObject = (value: DynamicDictionary): DynamicDictionary => {
        return Object.keys(value).sort().reduce(
            (copy: DynamicDictionary, key: string): DynamicDictionary => {
                copy[key] = value[key];
                return copy;
            },
            {}
        );
    }

    return {
        sortedObject,
    }
}

export interface SortingParams {
    sortedObject: (value: DynamicDictionary) => DynamicDictionary;
}
