import Vue from 'vue';
import VueRouter from 'vue-router';
import VueApp from '@/Core/Routing/VueApp';
import App from '@/Apps/SolarPanels/App.vue';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import InsurancePage from '@/Apps/SolarPanels/Pages/InsurancePage.vue';
import CoveragePage from '@/Apps/SolarPanels/Pages/CoveragePage.vue';
import AddressPage from '@/Apps/SolarPanels/Pages/AddressPage.vue';
import SummaryAndPaymentPage from '@/Apps/SolarPanels/Pages/SummaryAndPaymentPage.vue';
import CoveredPopupSolarPanelsMulti from '@/Components/CoveredPopup/CoveredPopupSolarPanelsMulti.vue';
import SolarPanelsMini from '@/Components/ProductBlock/Components/MiniPlans/SolarPanelsMini.vue';
import ProductBlockInnerSolarPanelsPolicy from '@/Components/ProductBlock/Components/ProductBlockInnerSolarPanelsPolicy.vue';
import TextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import ButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import PopupOpener from '@/Components/PopupOpener/PopupOpener.vue';
import InputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import InputIdentityNumber from '@/Components/InputIdentityNumber/InputIdentityNumber.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import InputText from '@/Components/InputText/InputText.vue';

// Routing
Vue.use(VueRouter);

// Pages
Vue.component('InsurancePage', InsurancePage);
Vue.component('CoveragePage', CoveragePage);
Vue.component('AddressPage', AddressPage);
Vue.component('SummaryAndPaymentPage', SummaryAndPaymentPage);

// Components
Vue.component('AppStepperLayout', StepperLayout);
Vue.component('AppRouteStepper', RouteStepper);
Vue.component('AppTextWithTip', TextWithTip);
Vue.component('AppButtonWithCallbac', ButtonWithCallback);
Vue.component('AppProductBlockInnerSolarPanelsPolicy', ProductBlockInnerSolarPanelsPolicy);
Vue.component('AppSolarPanelsMini', SolarPanelsMini);
Vue.component('AppCoveredPopupSolarPanelsMulti', CoveredPopupSolarPanelsMulti);
Vue.component('AppInputDateWithCalendar', InputDateWithCalendar);
Vue.component('AppPopupOpener', PopupOpener);
Vue.component('AppInputText', InputText);
Vue.component('AppInputCheckbox', InputCheckbox);
Vue.component('AppInputIdentityNumber', InputIdentityNumber);
Vue.component('AppInputEmail', InputEmail);
Vue.component('AppPhoneWithCountry', PhoneWithCountry);

export default class {
    public app(selector: string, routes: BackendRoute[]) {
        return new VueApp(App).withRoutes(routes).mount(selector);
    }
}
