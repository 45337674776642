<script setup lang="ts">
    import {onMounted, Ref, ref, computed} from 'vue';
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import {useTranslate} from '@/Composables/Translate';
    import SimpleError from '@/assets/libraries/popups/types/simple.error';
    import OnePopup from '@/assets/libraries/popups/one.popup';
    import PopupService from '@/services/custom.popup.service';
    import {AxiosResponse, CancelTokenSource} from 'axios';
    import ImageTagAttributes from '@/interfaces/image.tag.attributes';
    import Url from '@/Enums/UrlEnum';
    import BreakPoints from '@/Enums/BreakPointsEnum';
    import {useHtml} from '@/Composables/Html';
    import {AxiosParams, useAxios} from '@/Composables/Axios';
    import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
    import PaymentMethod from '@/Components/PaymentWidget/Interfaces/PaymentMethod.interface';
    import PaymentMethodBuilder from '@/Components/PaymentWidget/Builders/PaymentMethod.builder';

    const props = defineProps({
        notificationTitle: {type: String, default: ''},
        additionalButtonText: {type: String, default: ''},
        amount: {type: String, default: ''},
        showAllPaid: {type: Boolean, default: false},
        showPaymentMethods: {type: Boolean, default: true},
        showPayButton: {type: Boolean, default: true},
        invoiceCount: {type: Number, default: 0}
    });
    const emit = defineEmits(['process-payment-click', 'payment-widget-additional']);
    const request: AxiosParams = useAxios();
    const {translate} = useTranslate();
    const {imgTag} = useHtml();
    const paymentMethods: Ref<PaymentMethod[]> = ref([]);
    const isPaymentMethodsVisible: Ref<boolean> = computed((): boolean => {
        return props.showPaymentMethods && paymentMethodsOpen.value;
    });
    const isPayButtonVisible: Ref<boolean> = computed((): boolean => {
        return !isPaymentMethodsVisible.value && props.showPayButton;
    });
    const showAmount: Ref<boolean> = computed((): boolean => {
        return Number(props.amount) !== 0;
    });
    const hasInvoices: Ref<boolean> = computed((): boolean => {
        return props.invoiceCount !== 0;
    });
    const warningIcon: Ref<string> = computed((): string => {
        return imgTag(warningIconAttributes());
    });
    const invoicesPaidIcon: Ref<string> = computed((): string => {
        return imgTag(invoicesPaidIconAttributes());
    });
    const widgetNotificationTitle: Ref<string> = computed((): string => {
        let title: string = props.notificationTitle
            ? props.notificationTitle : 'payment_widget_invoice_count';
        if (!hasInvoices.value) {
            title = 'payment_widget_select_invoices';
        }

        return translate(title, {'count': props.invoiceCount});
    });
    const payButtonText: Ref<string> = computed((): string => {
        const buttonText: string = translate('payment_widget_pay_now');
        let sumText: string = '';
        if (showAmount.value) {
            sumText = [' &middot;', props.amount, '&euro;'].join(' ');
        }

        return props.additionalButtonText ? buttonText
            : buttonText + sumText;
    });
    const fetchIsInProgress: Ref<boolean> = ref(false);
    const cancelToken: Ref<CancelTokenSource | null> = ref(null);
    let selectedPaymentMethod: PaymentMethod | null = null;
    let paymentMethodsOpen: Ref<boolean> = ref(false);

    onMounted((): void => {
        init();
    });

    function emitProcessPaymentClick(): void {
        emit('process-payment-click', selectedPaymentMethod?.bankLinkId);
    }

    function emitPaymentWidgetAdditionalClick(): void {
        emit('payment-widget-additional');
    }

    function showPaymentInfo(): boolean {
        return paymentInfo() !== '';
    }

    function paymentInfo(): string {
        return selectedPaymentMethod
            ? translate(selectedPaymentMethod.informationText)
            : '';
    }

    function onPaymentMethodClick(bankLinkId: number): void {
        resetActiveStatus().then(() => {
            const method: PaymentMethod | undefined = paymentMethods.value
                .find((method: PaymentMethod) => method.bankLinkId === bankLinkId);
            if (typeof method !== 'undefined') {
                selectedPaymentMethod = method;
                selectedPaymentMethod.active = true;
            }
        });
    }

    function onPayClick(): void {
        try {
            validateBeforeEmit();
            emitProcessPaymentClick();
        } catch (reason) {
            const error: SimpleError = new OnePopup()
                .withType()
                .simpleError
                .withDescription(translate(reason as string));
            PopupService.getInstance().show(error);
        }
    }

    function onAdditionalClick(): void {
        emitPaymentWidgetAdditionalClick();
    }

    function openPaymentMethods(): void {
        paymentMethodsOpen.value = true;
    }

    function applyPaymentMethodsOpenInitialStatus(): void {
        paymentMethodsOpen.value = window.innerWidth > BreakPoints.Sm;
    }

    function warningIconAttributes(): ImageTagAttributes {
        const source: string = 'images/one/circle-exclamation-mark.svg';
        return {
            class: '',
            src: source,
            width: 36,
            height: 24,
        }
    }

    function invoicesPaidIconAttributes(): ImageTagAttributes {
        const source: string = 'images/one/smiley-background.svg';
        return {
            class: '',
            src: source,
            width: 40,
            height: 40,
        }
    }

    function resetActiveStatus(): Promise<void> {
        return new Promise(resolve => {
            paymentMethods.value.forEach((method: PaymentMethod): void => {
                method.active = false;
            });
            resolve();
        });
    }

    function init(): void {
        fetchPaymentMethods();
        applyPaymentMethodsOpenInitialStatus();
    }

    function validateBeforeEmit(): void {
        let result: string;
        switch (true) {
            case !hasInvoices.value:
                result = 'payment_widget_select_invoices';
                break;
            case selectedPaymentMethod === null:
                result = 'payment_widget_select_payment_method';
                break;
            default:
                result = '';
                break;
        }
        if (result) {
            throw result;
        }
    }

    function fetchPaymentMethods() {
        fetchIsInProgress.value = true;
        if (cancelToken.value) {
            cancelToken.value.cancel();
        }
        cancelToken.value = request.CancelToken.source();
        request.get(Url.Ajax.fetchPaymentMethods,
            {cancelToken: cancelToken.value.token}
        ).then((response: AxiosResponse): void => {
            const receivedMethods: DynamicDictionary[] = JSON.parse(response.data.data.body.paymentMethods);
            receivedMethods.forEach((method: DynamicDictionary): void => {
                paymentMethods.value.push(
                    (new PaymentMethodBuilder())
                        .withTitle(method.title)
                        .withInformationText(method.informationText)
                        .withIcon(method.imageMobile)
                        .withBankLinkId(method.bankLinkId)
                        .build()
                );
            });
        }).catch(() => {
        }).finally(() => {
            fetchIsInProgress.value = false;
        });
    }

</script>

<template>
    <div class="payment-widget"
         :id="'payment-widget'">
        <div class="paid-invoices" v-if="showAllPaid">
            <div class="flex align-center nano heavy">
                <div class="icon medium-margin-right" v-html="invoicesPaidIcon"></div>
                {{ translate('payment_widget_all_invoices_paid') }}
            </div>
        </div>
        <div v-if="!showAllPaid">
            <div class="padded with-border flex align-center heavier small icon-height">
                <div class="icon small-margin-right" v-html="warningIcon" v-if="!hasInvoices"></div>
                {{ widgetNotificationTitle }}
            </div>
            <div class="padded with-border flex space-between heavy tiny special-height">
                {{ translate('payment_widget_total_amount') }} <span class="red-text small widget-amount"
                                                                     v-if="showAmount">{{ amount }} &euro;</span>
            </div>
            <div class="payment-methods" :class="[isPaymentMethodsVisible ? 'visible' : 'hidden']">
                <div class="padded flex space-between heavy tiny" v-if="!additionalButtonText">
                    {{ translate('payment_widget_payment_method') }}
                </div>
                <app-content-loader class="medium-margin-top"
                                    v-if="paymentMethods.length === 0"></app-content-loader>
                <div class="padded heavy tiny grid-view" v-if="paymentMethods">
                    <button class="payment-method"
                            :class="{'active': item.active}"
                            v-for="(item, index) in paymentMethods"
                            :key="index"
                            @click="onPaymentMethodClick(item.bankLinkId)">
                        <img class="without-pointer-events" :src="item.icon" alt="'payment-method-icon'"/>
                    </button>
                </div>
            </div>
            <div v-if="showPaymentInfo()" class="payment-info heavy nano special-height" v-html="paymentInfo()"></div>
            <div class="buttons flex mobile-column">
                <button class="button red full-width tiny border-radius-small"
                        v-if="additionalButtonText"
                        @click="onAdditionalClick()">{{ additionalButtonText }}
                </button>
                <button class="button red full-width tiny border-radius-small"
                        :id="'paymentWidget-payNow'"
                        v-if="isPaymentMethodsVisible"
                        @click="onPayClick()" v-html="payButtonText">
                </button>
                <button class="button red full-width tiny border-radius-small"
                        v-if="isPayButtonVisible"
                        @click="openPaymentMethods()" v-html="translate('payment_widget_pay_now')">
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.payment-widget {
  width: 100%;
  padding: var(--size-normal);
  border-radius: 16px;
  scroll-margin-top: 4em;
  background-color: var(--component-color-background-base);

  .padded {
    padding-bottom: 26px;

    &:not(:first-child) {
      padding-top: 26px;
    }
  }

  .red-text {
    color: var(--brand-red);
  }

  .flex {
    display: flex;

    &.mobile-column {
      flex-direction: column;
      gap: 20px;

      @include respond-above('sm') {
        flex-direction: row;
      }
    }
  }

  .space-between {
    justify-content: space-between;
  }

  .align-center {
    align-items: center;
  }

  .special-height {
    line-height: var(--line-height-accented);
  }

  .icon-height {
    height: 50px;
  }

  .heavy {
    font-weight: 600;
  }

  .tiny {
    font-size: var(--font-size-tiny);
  }

  .small {
    font-size: var(--font-size-small);
  }

  .nano {
    font-size: var(--font-size-nano);
  }

  .heavier {
    font-weight: 700;
  }

  .full-width {
    width: 100%;
  }

  .with-border {
    border-bottom: 1px solid var(--black-100);
  }

  .border-radius-small {
    border-radius: 8px;
  }

  .grid-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;

    @include respond-above('sm') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .payment-methods {
    margin-bottom: 20px;

    .payment-method {
      width: 125px;
      height: 52px;
      border-radius: 8px;
      border: 1px solid var(--black-100);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 22px var(--size-normal);

      &.active {
        border-color: var(--brand-red);
        border-width: 1px;
      }

      img {
        width: 90px;
        height: 24px;

        &.without-pointer-events {
          pointer-events: none;
        }
      }
    }
  }

  .payment-info {
    margin-bottom: 20px;
    text-align: justify;

    ::v-deep a {
      color: var(--brand-teal);
    }
  }

  @include respond-above('sm') {
    width: 480px;
    box-shadow: 0 1px 0 rgb(146 151 160 / .32);

    &.full-width {
      width: 100%;
    }
  }

  .small-margin-right {
    margin-right: var(--size-pico);
  }

  .medium-margin-right {
    margin-right: var(--size-nano);
  }

  .large-margin-top {
    margin-top: var(--size-normal);
  }
}

.payment-methods {
  div:first-child {
    padding-top: 26px;
    padding-bottom: 0;
  }

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
}
</style>
