export class JSLoader {

    private filesUrlList: string[] = [];

    constructor(filesUrlList: string[]) {
        this.filesUrlList = filesUrlList;
    }

    public load(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.loadAllFilesSequentially().then(() => {
                resolve();
            }).catch(reason => {
                reject(reason);
            });
        });
    }

    private loadAllFilesSequentially(): Promise<void> {
        const scriptsElements: HTMLScriptElement[] = [].slice.call(document.getElementsByTagName('script'));
        const filesUrls = this.filesUrlList.filter(fileUrl => {
            return !scriptsElements.some(tag => tag.src.indexOf(fileUrl) !== -1)
        });
        return filesUrls.reduce(async (previousPromise, fileUrl) => {
            await previousPromise;
            return this.loadFile(fileUrl);
        }, Promise.resolve());
    }

    private loadFile(fileUrl: string): Promise<void> {
        const headElements = document.head;
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = fileUrl;
            scriptElement.onload = () => {
                resolve();
            };
            scriptElement.onerror = () => {
                reject('Can\'t load script: ' + fileUrl);
            };
            headElements.appendChild(scriptElement);
        });
    }
}
