export default class CaptionValidator {

    public isValidCaption(value: string): boolean {
        return value.length > 1;
    }

    public hasValidLength(value: string, length: number): boolean {
        return value.length >= length;
    }
}
