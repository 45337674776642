<script setup lang="ts">
import {onBeforeMount, onMounted} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

const props = defineProps({
    id: {type: String, default: ''},
    module: {
        type: [Object, Function],
        default: () => {
            return null;
        }
    },
});
const emit = defineEmits(['created', 'mounted']);

onBeforeMount(() => {
    if (props.module) {
        moduleSource().create();
        moduleSource().beforeModuleMounted();
        emit('created');
    }
});

onMounted(() => {
    if (props.module) {
        moduleSource().moduleMounted();
        emit('mounted');
    }
});

function moduleSource(): DynamicDictionary {
    return typeof props.module === 'function' ?
        props.module() : props.module;
}

</script>

<template>
    <section :id="'module-' + (id !== '' ? id : String(Math.random()).replace('.', ''))"
             class="module main-container">
        <slot :module="module"></slot>
    </section>
</template>

<style lang="scss" scoped>
</style>
