<script setup lang="ts">
import Popup from '@/services/popup.service';
import VueEvent from '@/Classes/VueEventClass';
import TriggeredEvent = JQuery.TriggeredEvent;
import {onBeforeUnmount, onMounted} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Dimensions from '@/interfaces/dimensions.interface';
import {Subscription} from 'rxjs';

const props = defineProps({
    disableClose: {type: Boolean, default: false},
    closeOnOverlayClick: {type: Boolean, default: false},
    styles: {type: [String, Object], default: ''},
});
const emit = defineEmits(['close', 'keydown']);

let onWindowResizeSubscription!: Subscription;
let originalMaxWidth: number = 0;


function close(): void {
    Popup.getInstance().hidePopup();
    emit('close');
}

function keydown(event: VueEvent): void {
    if ((event as unknown as TriggeredEvent).key === 'Escape') {
        close();
    }
    emit('keydown');
}

function onOverlayClick(): void {
    if (props.closeOnOverlayClick) {
        close();
    }
}

function visibilityChanged(isVisible: Boolean): void {
    if (isVisible) {
        $('.single-popup-focus').trigger('focus');
    }
}

function resize(): void {
    if (originalMaxWidth > 0) {
        const popupElement: JQuery = $('.single-popup');
        if (popupElement.length > 0) {
            const parent: JQuery<HTMLElement> = popupElement.parent();
            if (parent.length > 0) {
                const offset: JQueryCoordinates | undefined = parent.offset();
                const innerWidth: number | undefined = popupElement.innerWidth();
                const width: number = Math.min(window.innerWidth, window.outerWidth);
                if (offset && innerWidth) {
                    let newInnerWidth: number = originalMaxWidth;
                    if (innerWidth + offset.left > width - offset.left) {
                        newInnerWidth = width - (offset.left * 2);
                    }
                    popupElement.css('max-width', newInnerWidth + 'px');
                }
            }
        }
    }
}

onMounted(() => {
    const elementMaxWidth: number = parseInt($('.single-popup').css('max-width'), 10);
    if (elementMaxWidth > 0) {
        originalMaxWidth = elementMaxWidth;
        onWindowResizeSubscription =
            OneBaseService.getInstance().onWindowResize.subscribe((size: Dimensions): void => {
                close();
            })
        resize();
    }
});

onBeforeUnmount(() => {
    if (onWindowResizeSubscription) {
        onWindowResizeSubscription.unsubscribe();
    }
});
</script>

<template>
    <aside class="single-popup" v-observe-visibility="visibilityChanged">
        <aside class="overlay" @click="onOverlayClick()"></aside>
        <div class="wrapper single-popup-focus"
             tabindex="0"
             :style="styles"
             @keydown="keydown($event)">
            <button v-if="!disableClose" class="popup-close close" @click="close()">
                <svg style="width: 40px; height: 40px;" width="56" height="56" viewBox="0 0 56 56" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28" cy="28" r="28" fill="white"/>
                    <path opacity="0.48" d="M22 22L34 34M34 22L22 34" stroke="#9297A0" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <slot></slot>
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.popup-close.close svg {
    &:hover {
        box-shadow: 0 16px 32px rgb(146 151 160 / .32);
        border-radius: 20px;
    }
}
</style>
