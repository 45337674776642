import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useSorting } from '@/Composables/Sorting';
import Value from '@/assets/libraries/form/value';

export const useComparisons = (): ComparisonsParams => {
    /**
     * @param original can be any simple object
     * @param comparison can be any simple object
     * @return boolean comparison between 2 stringified objects (key => value pairs are sorted alphabetically)
     */
    const isSameObject = (original: DynamicDictionary, comparison: DynamicDictionary): boolean => {
        const sortedOriginal: DynamicDictionary = useSorting().sortedObject(original);
        const sortedComparison: DynamicDictionary = useSorting().sortedObject(comparison);

        return JSON.stringify(sortedOriginal) === JSON.stringify(sortedComparison);
    }

    /**
     * @param original can be any simple object
     * @param comparison can be any simple object
     * @return difference object (or empty object) compared to original by keys & values; key order ignored
     */
    const objectDiff = (original: DynamicDictionary, comparison: DynamicDictionary): DynamicDictionary => {
        const result: DynamicDictionary = {};
        if (!useComparisons().isSameObject(original, comparison)) {
            Object.keys(comparison)
                .forEach((keyToCompare: string): void => {
                    const originalHasKey: boolean = new Value(original[keyToCompare]).isNotEmpty();
                    const originalHasMatchingValue: boolean = JSON.stringify(original[keyToCompare]) === JSON.stringify(comparison[keyToCompare]);
                    if (!originalHasKey || !originalHasMatchingValue) {
                        result[keyToCompare] = comparison[keyToCompare];
                    }
                });
        }

        return result;
    }

    return {
        isSameObject,
        objectDiff,
    }
}

export interface ComparisonsParams {
    isSameObject: (original: DynamicDictionary, comparison: DynamicDictionary) => boolean;
    objectDiff: (original: DynamicDictionary, comparison: DynamicDictionary) => DynamicDictionary;
}
