import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import TravelInsuranceOptions from '@/pages/Travel/Enums/TravelInsuranceOptions';

export default class TravelInsuranceOptionComponents {
   public static forOptionRiskId(value: string): string {
       return TravelInsuranceOptionComponents.mapping()[value];
   }

   private static mapping(): DynamicDictionary {
       return {
           [TravelInsuranceOptions.RentalCarSecurityLV]: 'AppTravelOptionRentalCarSecurity',
           [TravelInsuranceOptions.RentalCarSecurityEE]: 'AppTravelOptionRentalCarSecurity',
       };
   }
}
