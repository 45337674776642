<script lang="ts">
import Vue, {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {VueApp} from '@/Types/VueAppType';
import PaymentsWaitingModule from '@/Modules/PaymentsWaiting/PaymentsWaitingModule.vue';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const paymentsWaitingModule: VueApp = new Vue(PaymentsWaitingModule);

        return {
            ...btaBase,
            ...{
                paymentsWaitingModule,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.paymentsWaitingModule.create();
        this.paymentsWaitingModule.beforeModuleMounted();
        this.paymentsWaitingModule.moduleMounted();
    },
});
</script>