import {Subject} from 'rxjs';
import SpaUrl from '@/Components/LanguageSwitcher/SpaUrl';

export default class SpaUrls {
    private static instance: SpaUrls;
    private urls: SpaUrl[] = [];
    public ready: boolean = false;
    public onExternalDataIsReady: Subject<void> = new Subject<void>();

    private constructor() {
    }

    public static getInstance(): SpaUrls {
        if (!SpaUrls.instance) {
            SpaUrls.instance = new SpaUrls();
        }
        return SpaUrls.instance;
    }

    public addUrls(value: SpaUrl[]): void {
        this.urls = value;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public all(): SpaUrl[] {
        return this.urls;
    }

    public byName(name: string): SpaUrl[] {
        return this.urls.filter((url: SpaUrl): boolean => url.name === name);
    }
}
