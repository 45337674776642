import Coordinates = JQuery.Coordinates;

export default class AlignedTooltip {
    private static instance: AlignedTooltip;
    private originId: string = '';
    private readonly tooltipClass: string = 'input-select-single-tooltip';
    private attachTarget: string = 'vue-app';

    public static getInstance(): AlignedTooltip {
        if (!AlignedTooltip.instance) {
            AlignedTooltip.instance = new AlignedTooltip();
        }

        return AlignedTooltip.instance;
    }

    public init(originId: string, attachTarget: string = ''): void {
        this.originId = originId;
        if (attachTarget !== '') {
            this.attachTarget = attachTarget;
        }
    }

    public show(message: string): void {
        const tooltipWidth: number = 420;
        const tooltipHeight: number = 58;
        const gap: number = 5;
        const origin: JQuery = $('#' + this.originId);
        const windowWidth: number | undefined = $(window).width();
        const originOffset: Coordinates | undefined = origin.offset();
        const attachTarget: JQuery = $('.' + this.attachTarget);
        const scrollTop: Coordinates | undefined = attachTarget.offset();
        if (origin.length === 1 && windowWidth && originOffset && scrollTop) {
            const left: number | undefined = originOffset.left;
            const top: number | undefined = originOffset.top + (scrollTop.top < 0 ? Math.abs(scrollTop.top) : 0);
            const tooltipRight: number = left + tooltipWidth + gap;
            let newLeft: number = tooltipRight > windowWidth ? (left - (tooltipRight - windowWidth) - gap) : left;
            if (newLeft < gap) {
                newLeft = gap;
            }
            const html: string = '<div class="' + this.originId + ' ' + this.tooltipClass + '" style="' +
                this.style(newLeft, top - tooltipHeight - gap, tooltipWidth) + '">' + message + '</div>';
            attachTarget.append(html);
        }
    }

    public hide(): void {
        $('.' + this.tooltipClass).remove();
    }

    private style(left: number, top: number, width: number): string {
        return 'position: absolute;height: 58px;left: ' + left + 'px;top:' + top + 'px;width:' + width + 'px;margin-bottom: 5px;padding: 6px 10px;border-radius: 6px;font-size: 12px;line-height: 14px;display: flex;align-items: center;background-color: #5448c8;color: #fff;overflow: hidden;white-space: break-spaces;z-index:1000;';
    }
}
