import VueRouter, {Route, RouteRecordPublic} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import {useRoute, useRouter} from 'vue-router/composables';
import Vue from 'vue';
import ClaimsValidRoutes from '@/Apps/ClaimsMtpl/Services/ClaimsValidRoutesService';

export default class GuardsService {
    private static instance: GuardsService;
    private route: Route = useRoute();
    private router: VueRouter = useRouter();

    public static getInstance(): GuardsService {
        if (!GuardsService.instance) {
            GuardsService.instance = new GuardsService();
            GuardsService.instance.prepareRoutes();
        }
        return GuardsService.instance;
    }

    public init(): void {
        this.guardRouteDirectAccess();
        this.router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
            const routeIndex: number = this.routeIndexByName(to.name as string);
            let hasInvalidRoutes: boolean = false;
            GuardsService.instance.router.getRoutes().forEach((route: RouteRecordPublic, index: number): void => {
                if (index < routeIndex && !ClaimsValidRoutes.getInstance().routeIsValid(index)) {
                    hasInvalidRoutes = true;
                }
            });
            if (hasInvalidRoutes) {
                next(false);
            } else {
                next();
            }
        });
        this.router.afterEach((to: Route, from: Route): void => {
            Vue.nextTick((): void => {
                $('html,body').animate(
                    {scrollTop: 0},
                    300,
                );
            });
        });
    }

    public applyStepValidity(step: number, isValid: boolean, clearAhead: boolean = false): void {
        ClaimsValidRoutes.getInstance().applyRoute(step, isValid);
        if (clearAhead) {
            GuardsService.instance.router.getRoutes().forEach((route: RouteRecordPublic, index: number): void => {
                if (index > step) {
                    ClaimsValidRoutes.getInstance().applyRoute(index, false);
                }
            });
        }
    }

    /**
     * Supposed to protect route from opening directly entering route path in address bar.
     * Planning to get rid of this method after migrating to vue3 - replacing with beforeRouteEnter event
     * @private
     */
    private guardRouteDirectAccess(): void {
        // TODO:...
    }

    private prepareRoutes(): void {
        GuardsService.instance.router.getRoutes().forEach((value: RouteRecordPublic, index: number): void => {
            ClaimsValidRoutes.getInstance().applyRoute(index, false);
        });
    }

    private routeIndexByName(routeName: string): number {
        let result: number = 0;
        GuardsService.instance.router.getRoutes().forEach((route: RouteRecordPublic, index: number): void => {
            if (route.name === routeName) {
                result = index;
            }
        });

        return result;
    }
}
