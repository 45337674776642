import VueRouter, { Route } from 'vue-router';
import {useRouter} from 'vue-router/composables';
import {useDefine} from '@/Composables/Define';
import {NavigationGuardNext} from 'vue-router/types/router';
import RentersService from '@/Apps/Renters/Services/RentersService';
import Steps from '@/Apps/Renters/Enums/Steps';
import Value from '@/assets/libraries/form/value';
import Validators from '@/Apps/Renters/Services/Validators';

const {isSet} = useDefine();

export default class StepsGuard {
    private static instance: StepsGuard;
    private router: VueRouter = useRouter();
    private service: RentersService;
    private readonly validators: Validators;

    private constructor(service: RentersService) {
        this.service = service;
        this.validators = Validators.getInstance();
    }

    public static getInstance(service: RentersService): StepsGuard {
        if (!StepsGuard.instance) {
            StepsGuard.instance = new StepsGuard(service);
        }

        return StepsGuard.instance;
    }

    public init(): void {
        this.preventRouteDirectAccess();
        this.hideAdditionalModules();
        this.router.beforeEach((to: Route, _from: Route, next: NavigationGuardNext): void => {
            this.service.destroyRoute();
            let isInvalidRoute: boolean = false;
            switch (to.name) {
                case Steps.Coverage:
                    if (!this.hasValidProgram()) {
                        isInvalidRoute = true;
                    }
                    break;
                case Steps.Options:
                    if (!isSet(this.service.fields.coverage)) {
                        isInvalidRoute = true;
                    }
                    break;
                case Steps.Address:
                    if (!isSet(this.service.fields.additionalOptions)) {
                        isInvalidRoute = true;
                    }
                    break;
                case Steps.Summary:
                    if (!this.validators.hasValidForm()
                        ||!this.hasValidAddress()
                        || this.hasValidBirthDate()
                        || this.service.fields.insuredFirstName === ''
                        || this.service.fields.insuredLastName === ''
                        || this.service.fields.insuredIdentityNumber === '') {
                        isInvalidRoute = true;
                    }
                    break;
                default:
            }

            isInvalidRoute ? next(false) : next();
        });
    }

    public hasValidBirthDate(): boolean {
        return this.requiresBirthDate() ? this.service.fields.insuredBirthDate === '' : false;
    }

    public requiresBirthDate(): boolean {
        return !this.service.fields.insuredIsResident
            && this.service.fields.insuredCountry.iso !== 'LT'
            && this.service.fields.insuredCountry.iso !== 'EE';
    }

    public hasValidProgram(): boolean {
        return isSet(this.service.fields.programIc) && new Value(this.service.fields.programIc).isNotEmpty();
    }

    public hasValidAddress(): boolean {
        return isSet(this.service.fields.insuredAddress) && new Value(this.service.fields.insuredAddress).isNotEmpty();
    }

    public preventRouteDirectAccess(): void {
        if (!isSet(this.service.fields.programIc) && this.router.currentRoute.name !== Steps.Insurance) {
            this.router.push({name: Steps.Insurance}).then();
            if (this.router.currentRoute.name === Steps.Summary && !isSet(this.service.fields.insuredAddress)) {
                this.router.push({name: Steps.Insurance}).then();
            }
        }
    }

    private hideAdditionalModules(): void {
        const isInitialStep: boolean = this.router.currentRoute.name !== Steps.Insurance;
        const additionalModules: JQuery<HTMLElement> = $('.page-one-renters').children('section');
        additionalModules.each((): void => {
            isInitialStep ? $(this).hide() : $(this).show();
        });
    }
}
