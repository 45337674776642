<script setup lang="ts">
import {onMounted, Ref, ref, watch} from 'vue';
import {useTranslate} from '@/Composables/Translate';

const props = defineProps({
    currentPage: {type: Number, default: 1},
    totalPages: {type: Number, default: 1},
});
const emit = defineEmits(['change']);
const {translate} = useTranslate();
const numberOfPagesAround: Ref<number> = ref(3);
const pages: Ref<number[]> = ref([]);

onMounted((): void => {
    makePages();
});

watch(() => props.currentPage, () => {
    makePages();
}, {deep: true, immediate: true});

watch(() => props.totalPages, () => {
    makePages();
}, {deep: true, immediate: true});

function select(page: number): void {
    emit('change', page)
}

function prev(): void {
    const page: number = props.currentPage - 1;
    select(page);
}

function next(): void {
    const page: number = props.currentPage + 1;
    select(page);
}

function makePages(): void {
    pages.value = [];
    const tempPages: number[] = [];
    const pagesEachSide: number = pagesOnEachSide();
    tempPages.push(1);
    tempPages.push(props.totalPages);
    for (let i: number = props.currentPage - pagesEachSide; i <= props.currentPage + pagesEachSide; i++) {
        if (i > 0 && i <= props.totalPages) {
            tempPages.push(i);
        }
    }
    pages.value = tempPages.filter((page, pos) => tempPages.indexOf(page) === pos).sort((a, b) => a - b);
}

function pagesOnEachSide(): number {
    let pagesEachSide = Math.ceil((numberOfPagesAround.value - 1) / 2);
    if (props.currentPage - pagesEachSide <= 0) {
        pagesEachSide += pagesEachSide - props.currentPage + 1;
    }
    if (props.currentPage + pagesEachSide > props.totalPages) {
        pagesEachSide += (props.currentPage + pagesEachSide) - props.totalPages;
    }
    return pagesEachSide;
}

function isSeparatorRequiredAfterPage(pageIndex: number): boolean {
    const currentPage = pages.value[pageIndex];
    const nextPage = pages.value[pageIndex + 1];
    return !!nextPage && nextPage - currentPage > 1;
}
</script>
<template>
    <div class="remote-paginator">
        <button class="remote-paginator-item remote-paginator-prev"
                @click="prev()"
                :aria-label="translate('paginator_previous')"
                :disabled="currentPage === 1">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1 7L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <template v-for="(page, index) in pages">
            <div v-if="page === currentPage"
                 class="remote-paginator-item remote-paginator-current">{{ page }}
            </div>
            <button v-if="page !== currentPage"
                    class="remote-paginator-item"
                    @click="select(page)">{{ page }}
            </button>
            <div v-if="isSeparatorRequiredAfterPage(index)" class="remote-paginator-item remote-paginator-separator">...
            </div>
        </template>
        <button class="remote-paginator-item remote-paginator-next"
                @click="next()"
                :aria-label="translate('paginator_next')"
                :disabled="currentPage === totalPages">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L1 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>
</template>
<style lang="scss" scoped>
.remote-paginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .remote-paginator-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        color: var(--black-500);
        stroke: var(--brand-red);
        font-size: var(--font-size-pico);
        border-radius: 50%;
        text-decoration: none;

        &.remote-paginator-current {
            background-color: var(--brand-red);
            color: var(--white);
        }

        &.remote-paginator-prev {
            display: none;
            margin-right: 15px;

            @include respond-above('xs') {
                display: flex;
                margin-right: var(--size-big);
            }
        }

        &.remote-paginator-next {
            display: none;
            margin-left: 15px;

            @include respond-above('xs') {
                display: flex;
                margin-left: var(--size-big);
            }
        }

        &:disabled {
            stroke: var(--black-500);
            opacity: .4;
            cursor: auto;
        }

        &:not(.remote-paginator-separator):not(:disabled):not(.remote-paginator-current):hover {
            color: var(--brand-red);
            cursor: pointer;
        }
    }
}
</style>
