import {AxiosError, AxiosResponse} from 'axios';
import BaseApi from '@/services/api/base.api';
import Url from '@/Enums/UrlEnum';
import UserPolicy from '@/interfaces/user.policy.interface';
import UserPolicyDetails from '@/interfaces/user.policy.details.interface';
import UserPolicies from '@/interfaces/user.policies.interface';
import UserPolicySubscription from '@/interfaces/user.policy.subscription.interface';
import UserPolicySubscriptionCancellation from '@/interfaces/user.policy.subscription.cancellation.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class AgreementApi extends BaseApi {
    private static instance: AgreementApi;
    private readonly policyTypeOffer: string = 'offer';

    public static getInstance(): AgreementApi {
        if (!AgreementApi.instance) {
            AgreementApi.instance = new AgreementApi();
        }

        return AgreementApi.instance;
    }

    public agreementDetails(agreement: UserPolicy): Promise<UserPolicyDetails> {
        const params: DynamicDictionary = {
            agreementId: agreement.agreement_id,
            agreementNumber: agreement.agreement_number,
            holderId: agreement.holder_id,
            owner: agreement.owner,
            offer: agreement.payType === this.policyTypeOffer,
            lang: this.language(),
            status: agreement.status,
            liteObjectId: agreement.liteObjectId
        };
        const headers: DynamicDictionary = {};
        const url: string = this.formattedUrl(Url.Ajax.dashboardGetPolicy);

        return this.axios.post(url, params, {headers: headers})
            .then((value: AxiosResponse<DynamicDictionary>) => {
                return value.data.data.body.policy;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public agreements(): Promise<UserPolicies> {
        const params: DynamicDictionary = {
            lang: this.language()
        };
        const headers: DynamicDictionary = {};
        const url: string = this.formattedUrl(Url.Ajax.dashboardGetPolicies);

        return this.axios.post(url, params, {headers: headers})
            .then((value: AxiosResponse<DynamicDictionary>) => {
                return value.data.data.body;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public cancelAgreementSubscription(
        subscription: UserPolicySubscription,
        reasonCode: string,
        isHolder: boolean
    ): Promise<UserPolicySubscriptionCancellation> {
        const params: DynamicDictionary = {
            lang: this.language(),
            subscriptionAgreementNumber: subscription.subscriptionNumber,
            subscriptionAgreementId: subscription.subscriptionId,
            terminationReasonIc: reasonCode,
            isHolder: isHolder ? 'true' : 'false'
        };
        const headers: DynamicDictionary = {};
        const url: string = this.formattedUrl(Url.Ajax.dashboardCancelSubscription);

        return this.axios.post(url, params, {headers: headers})
            .then((value: AxiosResponse) => {
                if (this.isSet(value.data.errors)) {
                    throw new Error(value.data.errors.code);
                }

                return value.data.data.body.cancellation as UserPolicySubscriptionCancellation;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }

    public terminateSubscription(
        agreementNumber: string,
        agreementId: string,
        reasonCode: string
    ): Promise<UserPolicySubscriptionCancellation> {
        const params: DynamicDictionary = {
            lang: this.language(),
            subscriptionAgreementNumber: agreementNumber,
            subscriptionAgreementId: agreementId,
            terminationReasonIc: reasonCode,
            isHolder: 'true'
        };
        const headers: DynamicDictionary = {};
        const url: string = this.formattedUrl(Url.Ajax.dashboardCancelSubscription);

        return this.axios.post(url, params, {headers: headers})
            .then((value: AxiosResponse) => {
                if (this.isSet(value.data.errors)) {
                    throw new Error(value.data.errors.code);
                }

                return value.data.data.body.cancellation as UserPolicySubscriptionCancellation;
            })
            .catch((error: AxiosError) => {
                throw error;
            });
    }
}
