<script setup lang="ts">

import {computed, Ref} from 'vue';
import {useTranslate} from '@/Composables/Translate';

const {translate} = useTranslate();

const props = defineProps({
    step: {type: Number, default: 1},
    label: {type: String, default: ''},
    stepNames: {
        type: Array, default: () => {
            return [];
        }
    },
    simplifyOnComplete: {type: Boolean, default: true},
});


const isComplete: Ref<boolean> = computed(() => {
    return props.step === props.stepNames.length - 1;
});

const hideOnCompleteIfSimplified: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (props.simplifyOnComplete) {
        result = isComplete.value;
    }

    return result;
});


function stepStatusClass(stepId: number): string {
    let result: string[] = [];
    if (stepId < props.step) {
        result.push('single-step-passed');
    }
    if (stepId == props.step) {
        result.push('single-step-current');
        result.push('single-step-current-' + stepId);
    }
    if (stepId > props.step) {
        result.push('single-step-future');
    }

    return result.join(' ');
}
</script>

<template>
    <div class="progress-steps"
         :class="{'progress-steps-simplified': hideOnCompleteIfSimplified}">
        <h5 class="label"
            v-if="!hideOnCompleteIfSimplified">{{ props.label }}</h5>
        <div class="complete-badge-wrapper"
             v-if="hideOnCompleteIfSimplified">
            <h5 class="label">{{ props.label }}</h5>
            <div class="complete-badge">{{ translate('decision_done') }}</div>
        </div>
        <div class="steps"
             v-if="!hideOnCompleteIfSimplified">
            <div class="single-step"
                 v-for="(name, index) of props.stepNames"
                 :class="stepStatusClass(index)"
                 :key="index">
                <div class="single-step-container">
                    <div class="single-step-icon-base">
                        <div class="single-step-icon"></div>
                    </div>
                    <div class="title">{{ name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.progress-steps {
    width: 100%;
    max-width: 620px;
    background-color: var(--component-color-background-base);
    border-radius: var(--size-tiny);
    max-height: 160px;
    padding: var(--size-medium);

    @include respond-below('xs') {
        max-width: 100%;
        max-height: 100%;
        padding: var(--size-small) var(--size-small) 0 var(--size-small);
    }

    &.progress-steps-simplified {
        padding: var(--size-small);
    }

    .label {
        margin-bottom: var(--size-small);
    }

    .complete-badge-wrapper {
        display: flex;
        justify-content: space-between;

        .label {
            margin: 0;
            display: flex;
            align-items: center;
        }

        .complete-badge {
            padding: 6px var(--size-nano);
            color: var(--system-color-success-dark);
            border-radius: var(--size-pico);
            background-color: var(--system-color-success-light-non-transparent);
            font-size: var(--font-size-nano);
            height: var(--size-medium);
        }
    }

    .steps {
        position: relative;
        display: flex;
        flex-direction: row;

        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: calc(100% - 120px);
            left: 60px;
            top: 10px;
            background-color: var(--component-color-background-disabled);
        }


        @include respond-below('xs') {
            flex-direction: column;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: calc(100% - 40px);
                left: 11px;
                top: 10px;
                background-color: var(--component-color-background-disabled);
            }
        }

        .single-step {
            position: relative;
            width: 25%;
            @include respond-below('xs') {
                width: 100%;
                display: flex;
                align-items: flex-start;
                height: 46px;
            }

            .single-step-container {
                position: relative;
                width: 100%;
                gap: 11px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include respond-below('xs') {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    flex-direction: row;
                }

                .single-step-icon-base {
                    background-color: var(--component-color-background-base);
                    display: flex;
                    position: relative;
                    justify-content: center;
                    width: 60%;

                    @include respond-below('xs') {
                        background-color: transparent;
                        width: auto;
                    }
                }

                .single-step-icon {
                    position: relative;
                    display: inline-flex;
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    border: 1px solid var(--component-color-border-default);

                    &::after {
                        position: absolute;
                        content: '';
                        display: block;
                        width: 4px;
                        height: 4px;
                        border-radius: 2px;
                        left: 50%;
                        top: 50%;
                        margin-top: -2px;
                        margin-left: -2px;
                        background-color: var(--component-color-border-default);
                    }
                }

                .title {
                    font-size: var(--font-size-nano);
                    margin: 0;
                    text-align: center;
                }
            }

            &-passed {
                color: var(--system-color-success-dark);

                .single-step-container {
                    .single-step-icon {
                        background-color: var(--system-color-success-light-non-transparent);
                        border-color: transparent;

                        &::before {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 5px;
                            height: 2px;
                            left: 6px;
                            top: 11px;
                            rotate: 45deg;
                            background-color: var(--system-color-success-default);
                            border-radius: 2px;
                        }

                        &::after {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 10px;
                            height: 2px;
                            left: 10px;
                            top: 12px;
                            rotate: -45deg;
                            background-color: var(--system-color-success-default);
                        }
                    }
                }
            }

            &-current-0,
            &-current-1 {
                color: var(--system-color-error-dark);

                .single-step-container {
                    .single-step-icon {
                        background-color: var(--system-color-error-default);
                        border-color: transparent;

                        &::before {
                            position: absolute;
                            content: '';
                            display: block;
                            left: -4px;
                            top: -4px;
                            width: 30px;
                            height: 30px;
                            border-radius: 14px;
                            background-color: var(--red-200);
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            display: block;
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            left: 50%;
                            top: 50%;
                            margin-top: -2px;
                            margin-left: -2px;
                            background-color: var(--white);
                        }
                    }
                }
            }

            &-current-2 {
                color: var(--system-color-warning-dark);

                .single-step-container {
                    .single-step-icon {
                        background-color: var(--system-color-warning-default);
                        border-color: transparent;

                        &::before {
                            position: absolute;
                            content: '';
                            display: block;
                            left: -4px;
                            top: -4px;
                            width: 30px;
                            height: 30px;
                            border-radius: 14px;
                            background-color: var(--orange-200);
                        }

                        &::after {
                            position: absolute;
                            content: '';
                            display: block;
                            width: 4px;
                            height: 4px;
                            border-radius: 2px;
                            left: 50%;
                            top: 50%;
                            margin-top: -2px;
                            margin-left: -2px;
                            background-color: var(--white);
                        }
                    }
                }
            }

            &-current-3 {
                color: var(--system-color-success-dark);

                .single-step-container {
                    .single-step-icon {
                        background-color: var(--system-color-success-light-non-transparent);
                        border-color: transparent;

                        &::before {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 5px;
                            height: 2px;
                            left: 6px;
                            top: 11px;
                            rotate: 45deg;
                            background-color: var(--system-color-success-default);
                            border-radius: 2px;
                        }

                        &::after {
                            position: absolute;
                            content: "";
                            display: block;
                            width: 10px;
                            height: 2px;
                            left: 10px;
                            top: 12px;
                            rotate: -45deg;
                            background-color: var(--system-color-success-default);
                        }
                    }
                }
            }

            &-future {
                color: var(--component-color-border-hover);

                .single-step-container {
                    .single-step-icon {
                        background-color: var(--component-color-background-base);
                    }
                }
            }
        }
    }
}
</style>