import CreditCardType from '@/interfaces/credit.card.type.interface';
import CreditCards from '@/Enums/CreditCardsEnum';

export default class MasterCard implements CreditCardType {
    public static MaxDigits: number = 16;
    public readonly icon: string = '<img src="images/one/bank/master_card.svg" width="52px" height="52px">';
    public readonly maxDigitsFormatted: number = 22;
    public readonly name: string = CreditCards.MasterCard;
    public readonly numberDigits: number[] = [MasterCard.MaxDigits];
    public readonly pattern: RegExp = /^(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)\d{12}$/;
    public readonly startDigit: number = 5;
    public readonly whitelistedNumbers: string[] = ['5437551000000014'];
}
