<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import Vue, {Ref, ref, onMounted, reactive, watch, computed, ComputedRef} from 'vue';
    import Translations from '@/services/translations.service';
    import {UnwrapNestedRefs} from 'vue/types/v3-generated';
    import Form from '@/assets/libraries/form/form';
    import Territories from '@/interfaces/territories.interface';
    import {AxiosResponse} from 'axios';
    import OptionValue from '@/interfaces/option.value.interface';
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import Error from '@/services/error.service';
    import ErrorType from '@/Enums/ErrorTypeEnum';
    import {useDefine} from '@/Composables/Define';
    import Countries from '@/services/countries.service';
    import {AxiosParams, useAxios} from '@/Composables/Axios';
    import {InputOption} from '@/interfaces/InputOptionInterface';
    import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';

    const props = defineProps({
        formField: {type: FormField, default: () => new FormField('')},
        label: {type: String, default: ''},
        disabled: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
        dataStoreDisabled: {type: Boolean, default: false},
        translationPrefix: {type: String, default: 'regions_multi'},
        message: {type: String, default: ''},
        url: {type: String, default: ''},
        urlParams: {
            type: Object, default: () => {
            }
        },
        iconPattern: {type: String, default: '/images/one/flags/%.png'},
        maxSuggest: {type: Number, default: 5},
    });

    const emit = defineEmits(['change', 'input', 'destination-type-updated', 'options-updated', 'region-details-updated']);

    const selectionPanelsComponent: Ref = ref(null);
    const translations: Translations = Translations.getInstance();
    const request: AxiosParams = useAxios();
    const {isSet} = useDefine();
    const form: UnwrapNestedRefs<Form> = reactive(new Form());
    const isVisibleCustomElement: ComputedRef<boolean> = computed(() => {
        return destinationType.value === 'countries';
    });
    const isVisibleDropDown: ComputedRef<boolean> = computed(() => {
        return foundItems.value.length > 0;
    });
    const hasSelectedRegion: ComputedRef<boolean> = computed(() => {
        return selectedRegion.value.length > 0;
    });
    const isVisiblePopup: ComputedRef<boolean> = computed(() => {
        return !isOpenedSearchPopup.value;
    });
    const selectedCountryIcon: ComputedRef<string> = computed(() => {
        let result: string = '';
        if (hasSelectedRegion.value && selectedRegion.value[0].icon) {
            result = selectedRegion.value[0].icon as string;
        }

        return result;
    });
    const selectedRegionTitle: ComputedRef<string> = computed(() => {
        let result: string = '';
        if (hasSelectedRegion.value) {
            result = selectedRegion.value[0].regionName as string;
        }

        return result;
    });

    let selectionPopupOptions: Ref<InputOption[]> = ref([]);
    let hiddenOptions: Ref<InputOption[]> = ref([]);
    let isLoading: Ref<boolean> = ref(false);
    let territories: Ref<Territories[]> = ref([]);
    let countries: Ref<Territories[]> = ref([]);
    let combinedTerritoriesItems: Ref<Territories[]> = ref([]);
    let foundItems: Ref<Territories[]> = ref([]);
    let selectedRegion: Ref<Territories[]> = ref([]);
    let isDisabled: Ref<boolean> = ref(false);
    let destinationType: Ref<string> = ref('');
    let isOpenedSearchPopup: Ref<boolean> = ref(false);
    let typedValue: Ref<string> = ref('');

    onMounted((): void => {
        checkDisabledStatus();
        Vue.nextTick(() => {
            setupForm();
            initialFetch();
        });
    });

    watch(() => props.urlParams, (newValue) => {
        if (clearUrlParams(newValue) !== '') {
            fetch(newValue);
        }
    });

    watch(() => props.formField.value, (newValue, oldValue) => {
        if (newValue !== '' || oldValue !== '' && newValue !== oldValue) {
            let valueToPatch: any = newValue !== '' ? newValue : oldValue;
            form.field('selector').patch(valueToPatch);
            emitRegionDetails();
        }
    });

    watch(() => typedValue.value, (newValue, oldValue) => {
        if (newValue !== oldValue) {
            buildFoundItems(newValue);
        }
    });

    watch(() => destinationType.value, () => {
        emitDestinationType();
    });

    function translatedStrings(id: string): string {
        const stringId: string = props.translationPrefix ? props.translationPrefix + '_' + id : id;

        return translations.ready ? translations.localized(stringId) : '';
    }

    function onOpenSearchClick(): void {
        typedValue.value = '';
        isOpenedSearchPopup.value = true;
    }

    function onClosePopupClick(): void {
        isOpenedSearchPopup.value = false;
        typedValue.value = '';
    }

    function onRegionChange(currentValue: OptionValue): void {
        props.formField.patch(currentValue);
        emitChange();
    }

    function onOptionSelectClick(selected: Territories): void {
        selectedRegion.value = [];
        selectedRegion.value.push(selected);
    }

    function onRemoveCountryClick(): void {
        selectedRegion.value = [];
        typedValue.value = '';
    }

    function onButtonApplyClick(): void {
        selectionPanelsComponent.value?.close();
        isOpenedSearchPopup.value = false;
        if (hasSelectedRegion.value) {
            const valueToPatch: string = selectedRegion.value[0].territoryIc as string;
            props.formField.patch(valueToPatch);
            form.field('selector').patch(valueToPatch);
        }
    }

    function emitOptionsUpdated(options: InputOption[]): void {
        emit('options-updated', options);
    }

    function emitDestinationType(): void {
        emit('destination-type-updated', destinationType.value);
    }

    function emitRegionDetails(): void {
        let result: DynamicDictionary = {};
        const icToSearch: string = props.formField.value;
        combinedTerritoriesItems.value.forEach((item: Territories) => {
            if (item.territoryIc === icToSearch) {
                result = item;
            }
        });
        emit('region-details-updated', result);
    }

    function emitChange(): void {
        props.formField.touch();
        props.formField.sanitize();
        props.formField.validate();
        emit('change', props.formField.value);
    }

    function checkDisabledStatus(): void {
        isDisabled.value = props.disabled || selectionPopupOptions.value.length === 0;
    }

    function setupForm(): void {
        form.addField(new FormField('selector', '', ''));
    }

    function initialFetch(): void {
        if (clearUrlParams(props.urlParams) !== '') {
            fetch(props.urlParams);
        }
    }

    function clearUrlParams(params: DynamicDictionary): string {
        let result: string = '';
        if (params && params.target !== undefined && params.target !== '') {
            result = params.target;
        }

        return result;
    }

    function fetch(urlParams: DynamicDictionary = {}): void {
        form.lockInput();
        isLoading.value = true;
        if (!props.url) {
            Error.log(ErrorType.Error, 'fetch(invalid url)', props.url);
        }
        request.get(props.url, {params: urlParams})
            .then((value: AxiosResponse) => {
                if (validResponse(value)) {
                    foundItems.value = [];
                    selectedRegion.value = [];
                    destinationType.value = value.data.data.body.destination;
                    territories.value = value.data.data.body.elements;
                    setupSearchItemsIcons(value.data.data.body.countries);
                    combinedTerritoriesItems.value = countries.value.concat(territories.value);
                    buildRegionsAndTerritories();
                } else {
                    Error.log(ErrorType.Error, 'fetch::validResponse(invalid structure received)', value);
                }
            })
            .catch((reason: any) => {
                Error.log(ErrorType.Error, 'fetch', reason);
            })
            .finally(() => {
                form.unlockInput();
                isLoading.value = false;
            });
    }

    function buildFoundItems(term: string): void {
        foundItems.value = [];
        if (typedValue.value.length > 0) {
            const normalizedTitle: string = Countries.normalizedSearchCountry(term);
            let count: number = 0;
            countries.value.forEach((item: Territories) => {
                const name: string = item.regionName!.toLowerCase();
                if (name.includes(normalizedTitle) && count < props.maxSuggest) {
                    foundItems.value.push(item);
                    count++;
                }
            });
        }
    }

    function setupSearchItemsIcons(countriesFetched: Territories[]): void {
        const patternParts: string[] = props.iconPattern.split('%');
        countries.value = [];
        countriesFetched.forEach((item: Territories) => {
            const countryIso: string = item.iso ? String(item.iso as string).toLowerCase() : '';
            item.icon = countryIso !== '' ? patternParts[0] + countryIso + patternParts[1] : '';
            countries.value.push(item);
            hiddenOptions.value.push(inputOption(item));
        });
    }

    function inputOption(item: Territories): InputOption {
        const name: string = transformRegionName(item.regionName as string);
        const value: string = item.territoryIc as string;
        const tipTitle: string = 'region_' + value.toLowerCase() + '_title';
        const tipDescription: string = 'region_' + value.toLowerCase() + '_description';
        return (new InputOptionBuilder())
            .setValue(value)
            .setName(name)
            .setTipster(translatedStrings(tipTitle), translatedStrings(tipDescription))
            .build();
    }

    function buildRegionsAndTerritories(): void {
        selectionPopupOptions.value = [];
        let defaultValue: string = '';
        territories.value.forEach((item: Territories) => {
            selectionPopupOptions.value.push(inputOption(item));
            if (item.isDefault === 'Y') {
                defaultValue = item.territoryIc as string;
            }
        });
        if (props.formField.isEmpty()) {
            if (defaultValue === '' && territories.value.length > 0) {
                defaultValue = territories.value[0].territoryIc as string;
            }
            props.formField.patch(defaultValue);
            form.field('selector').patch(defaultValue);
        } else {
            if (!territoriesHasSelectedValue() && combinedTerritoriesItems.value.length > 0) {
                defaultValue = territories.value[0].territoryIc as string;
                props.formField.patch(defaultValue);
                form.field('selector').patch(defaultValue);
            }
        }
        emitOptionsUpdated(selectionPopupOptions.value);
        emitRegionDetails();
        checkDisabledStatus();
    }

    function territoriesHasSelectedValue(): boolean {
        let result: boolean = false;
        combinedTerritoriesItems.value.forEach((item: Territories) => {
            if (item.territoryIc === props.formField.value) {
                result = true;
            }
        });

        return result;
    }

    function validResponse(response: any): boolean {
        return isSet(response.status)
            && response.status === 200
            && isSet(response.data)
            && isSet(response.data.data);
    }

    function transformRegionName(name: string): string {
        return name.split('/').join('<wbr>' + '/');
    }
</script>

<template>
    <div class="input country-regions-multi"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': isDisabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : formField.value">
        <app-input-selection-panels
                ref="selectionPanelsComponent"
                class="country-region-opener"
                :form-field="form.field('selector')"
                :label="label"
                :options="selectionPopupOptions"
                :hidden-options="hiddenOptions"
                :allow-empty-selection="false"
                :loading="isLoading"
                :data-store-disabled="true"
                :show-custom-element="isVisibleCustomElement"
                :is-visible-popup="isVisiblePopup"
                @change="onRegionChange">
            <template v-slot:panel-opener-label>
                <app-tooltipster
                        :title="translatedStrings('regions_opener_tip_title')"
                        :description="translatedStrings('regions_opener_tip_description')">
                </app-tooltipster>
            </template>
            <template v-slot:custom-element
                      v-if="isVisibleCustomElement">
                <button class="button item"
                        :id="formField.name + '-open-search'"
                        @click="onOpenSearchClick()">
                    <span class="label">{{ translatedStrings('select_country') }}</span>
                </button>
            </template>
        </app-input-selection-panels>
        <div class="popups">
            <app-popup v-if="isOpenedSearchPopup"
                       class="simple list-search"
                       v-on:close="onClosePopupClick">
                <div class="title">
                    {{ translatedStrings('select_destination_country') }}
                    <app-tooltipster
                            :title="translatedStrings('select_destination_country_tip_title')"
                            :description="translatedStrings('select_destination_country_tip_description')">
                    </app-tooltipster>
                </div>
                <div class="field"
                     v-if="hasSelectedRegion && false">
                    <div class="selected-region">
                        <button :id="formField.name + '-removeCountry'"
                                class="country-button"
                                @click="onRemoveCountryClick()">
                            <img class="icon"
                                 v-if="selectedCountryIcon !== ''"
                                 :src="selectedCountryIcon"
                                 alt="">
                            <span class="selected-region-text">{{ selectedRegionTitle }}</span>
                            <div class="remove">
                                <svg width="10" height="10" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L13 13M13 1L1 13" stroke="#9297A0" stroke-width="2"
                                          stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
                <div class="field"
                     v-if="!hasSelectedRegion && false">
                    <div class="search">
                        <div class="icon">
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.48">
                                    <path d="M14 14L20 20" stroke="#9297A0" stroke-width="2"></path>
                                    <circle cx="8.5" cy="8.5" r="7.5" stroke="#9297A0"
                                            stroke-width="2"></circle>
                                </g>
                            </svg>
                        </div>
                        <input v-model="typedValue"
                               :id="formField.name + '-typedValue'"
                               class="selected-region-text"
                               ref="inputSearch"
                               :placeholder="translatedStrings('type_to_search')"/>
                    </div>
                    <div class="search-dropdown"
                         :class="{'hidden':!isVisibleDropDown}">
                        <button v-for="(item, index) in foundItems"
                                :key="index"
                                class="country-item"
                                :id="formField.name + '-selectItem'"
                                :data-value="item.ic"
                                @click="onOptionSelectClick(item)">
                            <img class="icon"
                                 v-if="item.icon"
                                 :src="item.icon"
                                 alt="">
                            <span class="text">{{ item.regionName }}</span>
                        </button>
                    </div>
                </div>
                <div class="apply-button-container" v-if="hasSelectedRegion">
                    <button class="button red"
                            :id="formField.name + '-applyButton'"
                            @click="onButtonApplyClick()">{{ translatedStrings('continue') }}
                    </button>
                </div>
            </app-popup>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.country-regions-multi {
  .field {
    position: relative;
    width: 100%;
    min-height: 52px;
    border: 1px solid rgb(146 151 160 / .48);
    border-radius: 3px;
    display: flex;
    flex-flow: row wrap;

    .search {
      height: 52px;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      padding: 0 var(--size-small);

      .icon {
        margin-right: var(--size-tiny);
      }

      .selected-region-text {
        height: 100%;
        border: none;
        font-size: var(--font-size-tiny);
        font-weight: 600;
        color: var(--black-500);
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        padding-left: 10px;
      }
    }
  }

  .selected-region {
    min-height: 52px;
    display: inline-flex;
    align-items: center;
    flex-grow: 1;
    padding: var(--size-femto) var(--size-femto) 0;
    flex-flow: row wrap;
    height: auto;

    .country-button {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      height: 44px;
      flex-shrink: 0;
      background-color: var(--background-light);
      border-radius: 2px;
      margin-right: var(--size-femto);
      margin-bottom: var(--size-femto);

      .icon {
        width: 34px;
        height: 20px;
        margin-right: var(--size-nano);
      }

      .text {
        font-size: var(--font-size-tiny);
        font-weight: 600;
        color: var(--black-500);
      }

      .remove {
        height: 12px;
        margin-left: var(--size-nano);
      }

      .add {
        height: 16px;
        margin-right: var(--size-nano);

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .country-button-add {
      background-color: transparent;
      margin-left: var(--size-nano);
      margin-right: 0;
    }
  }

  .search-dropdown {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: -1px;
    right: -1px;
    margin-top: -2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--white);
    border: 1px solid rgb(146 151 160 / .48);
    border-radius: 0 0 3px 3px;
    box-shadow: 0 16px 32px rgb(146 151 160 / .32);
    overflow: auto;
    max-height: calc(45vh - 55px);
    height: auto;

    .country-item {
      position: relative;
      padding: 0 var(--size-small);
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 52px;
      flex-shrink: 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: var(--black-500);
        opacity: .24;
      }

      &:first-of-type {
        &::before {
          display: none;
        }
      }

      &.active,
      &:hover {
        .text {
          color: var(--brand-red);
        }
      }

      .icon {
        width: 34px;
        height: 20px;
        margin-right: var(--size-nano);
      }

      .text {
        font-weight: 600;
        font-size: var(--font-size-tiny);
        color: var(--black-500);
      }
    }
  }

  .country-region-opener {
    width: 100%;
  }

  > .popups {
    ::v-deep .single-popup {
      .wrapper {
        .title {
          color: var(--text-color-default);
        }

        width: 100%;

        @include respond-above('sm') {
          max-width: 462px;
        }

        .apply-button-container {
          margin-top: 99px;
        }
      }
    }
  }
}

@keyframes loader-rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
