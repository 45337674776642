<script lang="ts">
import Vue, {computed, defineComponent, reactive, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import {InputOption} from '@/interfaces/InputOptionInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Debounce from '@/services/debounce.service';
import DateRange from '@/interfaces/date.range.interface';
import moment from 'moment/moment';
import Url from '@/Enums/UrlEnum';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {AxiosResponse} from 'axios';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {useDefine} from '@/Composables/Define';
import {useTranslate} from '@/Composables/Translate';
import Translations from '@/services/translations.service';
import FormField from '@/assets/libraries/form/form-field';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import Coordinates = JQuery.Coordinates;

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translateForType} = useTranslate();
        const stepsSubmitter = useStepsSubmitter();
        const request: AxiosParams = useAxios();
        const {isSet, validResponse} = useDefine();

        const CurrentStep: number = 2;
        const Facility: string = 'one-claims-property';
        const findPropertiesWithDebounce: Ref<Function> = ref(() => {
            console.debug('findPropertiesWithDebounce in not set...');
        });
        const FormFields: string[] = [
            'select-event-date',
            'address-blocks',
            'on-this-address',
            'event-address-country',
            'event-address-text',
        ];

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const eventDate: Ref<string> = ref('');
        const isSearchInProgress: Ref<boolean> = ref(false);
        const propertyObjects: Ref<InputOption[]> = ref([]);
        const optionsHappenedAtThisAddress: Ref<InputOption[]> = ref([]);
        const showSecondPanel: Ref<boolean> = ref(false);
        const propertyObjectsResponse: Ref<DynamicDictionary[]> = ref([]);
        const propertyObjectResponse: Ref<DynamicDictionary> = ref({});

        const selectDate: Ref<string> = computed(() => {
            return translateForType('claims_select_date', Translations.getInstance().type);
        });

        const calendarMinDate: Ref<Date> = computed(() => {
            const minDateYears: number = 5;

            return moment().subtract(minDateYears, 'years').toDate();
        });

        const calendarMaxDate: Ref<Date> = computed(() => {
            return moment().toDate();
        });

        const objectFetchUrl: Ref<string> = computed(() => {
            return Url.Ajax.claimsPropertyObject;
        });

        const isVisibleSecondPanel: Ref<boolean> = computed(() => {
            return showSecondPanel.value;
        });

        const isSelectedPropertyObject: Ref<boolean> = computed(() => {
            return !form.field('address-blocks').isEmpty();
        });

        const eventHappenedOnOtherAddress: Ref<boolean> = computed(() => {
            return form.field('on-this-address').value === false;
        });

        const headerOffsetForScroll: Ref<number> = computed(() => {
            const smallOffset: number = 30;
            const bigOffset: number = 130;

            return btaBase.isVerticalMode.value ? smallOffset : bigOffset;
        });

        function setupForm(): void {
            FormFields.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function onEventDateChange(date: DateRange): void {
            eventDate.value = (moment(new Date(date.startDate)).format('YYYY-MM-DD HH:mm:ss'));
            findPropertiesWithDebounce.value();
            if (form.field('select-event-date').isTouched) {
                resetSecondPanel();
            }
        }

        function onTodayClick(): void {
            form.field('select-event-date').patch({
                    startDate: moment().toDate(),
                    endDate: ''
                }
            );
        }

        function onYesterdayClick(): void {
            form.field('select-event-date').patch(
                {
                    startDate: moment().subtract(1, 'days').toDate(),
                    endDate: ''
                }
            );
        }

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    prepareSubmit();
                    stepsSubmitter.proceedStep('', 0);
                }
            });
        }

        function findProperties(): void {
            isSearchInProgress.value = true;
            const params: Record<string, number | string> = {
                eventDate: eventDate.value
            };
            request.get(Url.Ajax.claimsPropertyObjects, {params})
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (validResponse(response)) {
                        propertyObjectsResponse.value = response.data.data.body.objects;
                        buildPropertyObjects();
                        scrollToDataOffset('where-event-happened');
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    btaBase.error.show(ErrorType.Error, 'date_and_place::findProperties', reason as DynamicDictionary);
                })
                .finally((): void => {
                    isSearchInProgress.value = false;
                });
        }

        function onFinderReceive(propertyObject: DynamicDictionary): void {
            propertyObjectResponse.value = propertyObject;
        }

        function insuredObjectTitle(item: DynamicDictionary): string {
            return translateForType(item.custom.type, Translations.getInstance().type);
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            })
        }

        function touchAndRequireNecessaryFields(): void {
            FormFields.forEach((field: string): void => {
                switch (field) {
                    case 'event-address-country':
                    case 'event-address-text':
                        if (form.field('on-this-address').value === false) {
                            form.field(field).addValidators('required').touch();
                        }
                        break;
                    case 'on-this-address':
                        form.field(field).addValidators({
                            hasSelected: () => {
                                return !form.field('on-this-address').isEmpty();
                            }
                        });
                        break;
                    default:
                        if (field !== 'on-this-address') {
                            form.field(field).addValidators('required').touch();
                        }
                }
            });
        }

        function buildEventHappenedOptions(): void {
            optionsHappenedAtThisAddress.value = [
                (new InputOptionBuilder)
                    .setName(
                        translateForType('claims_property_yes', Translations.getInstance().type)
                    )
                    .setValue(true)
                    .build(),
                (new InputOptionBuilder)
                    .setName(
                        translateForType('claims_property_no', Translations.getInstance().type)
                    )
                    .setValue(false)
                    .build(),
            ];
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('dateOfEvent', form.field('select-event-date').value.startDate);
            stepsSubmitter.addSubmitCustomParam('propertyObject', form.field('address-blocks').value.finder);
            stepsSubmitter.addSubmitCustomParam('differentAddress', !form.field('on-this-address').value);
            if (!form.field('on-this-address').value) {
                stepsSubmitter.addSubmitCustomParam('country', form.field('event-address-country').value)
                stepsSubmitter.addSubmitCustomParam('address', form.field('event-address-text').value);
            }
            applyObjectsDataParams();
        }

        function applyObjectsDataParams(): void {
            const objectId: string = form.field('address-blocks').value.object;
            let propertyObjectToExport: DynamicDictionary = {};
            propertyObjectsResponse.value.forEach((propertyObject: DynamicDictionary): void => {
                if (propertyObject.id === objectId) {
                    propertyObjectToExport = propertyObject;
                }
            });
            if (!isSet(propertyObjectToExport.id)) {
                if (isSet(propertyObjectResponse.value.id)) {
                    propertyObjectToExport = propertyObjectResponse.value;
                } else if (isSet(btaBase.userStorage.storageData)) {
                    propertyObjectToExport = btaBase.userStorage.storageData;
                }
            }
            stepsSubmitter.addSubmitCustomParam('object', propertyObjectToExport);
        }

        function resetSecondPanel(): void {
            propertyObjects.value = [];
            showSecondPanel.value = false;
            FormFields.forEach((field: string): void => {
                if (field !== 'select-event-date') {
                    form.field(field).clear().then();
                }
            });
        }

        function buildPropertyObjects(): void {
            showSecondPanel.value = true;
            propertyObjects.value = [];
            propertyObjectsResponse.value.forEach((object: DynamicDictionary): void => {
                if (!isSet(object.error)) {
                    propertyObjects.value.push(
                        (new InputOptionBuilder)
                            .setValue(object.id)
                            .setName(modifiedAddressValue(object.name))
                            .setCustom({
                                type: transformedObjectType(object.objectType),
                                policy: object.policyNumber,
                                insuredPersonFirstName: object.insuredPersonFirstName,
                                insuredPersonLastName: object.insuredPersonLastName,
                                insuredPersonIdentityNumber: object.insuredPersonIdentityNumber,
                            })
                            .build()
                    )
                }
            });
        }

        function scrollToDataOffset(target: string): void {
            Vue.nextTick((): void => {
                const $panel: JQuery = $('[data-scroll="' + target + '"]');
                if ($panel.length > 0) {
                    const scrollSpeed: number = 350;
                    const offset: Coordinates | undefined = $panel.offset();
                    const verticalOffset: number = offset ? offset.top - headerOffsetForScroll.value : 0;
                    if (offset && verticalOffset > 0) {
                        $('html,body').animate({scrollTop: verticalOffset}, scrollSpeed);
                    }
                }
            });
        }

        function transformedObjectType(objectType: string): string {
            const typeSeparator: string = '_O_';
            const parts: string[] = objectType.split(typeSeparator);

            return 'claims_property_' + String(parts[parts.length - 1]).toLowerCase();
        }

        function modifiedAddressValue(address: string | undefined): string {
            const regex: RegExp = /, LV-\d{4}, Latvija/gm;

            return address !== undefined ? address.replace(regex, '') : '';
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                eventDate,
                isSearchInProgress,
                propertyObjects,
                optionsHappenedAtThisAddress,
                showSecondPanel,
                propertyObjectsResponse,
                propertyObjectResponse,
                findPropertiesWithDebounce,
                setupForm,
                findProperties,
                buildEventHappenedOptions,
                onEventDateChange,
                onTodayClick,
                onYesterdayClick,
                onSubmitStep,
                onFinderReceive,
                insuredObjectTitle,
                resetForm,
                applyStepUrls,
                selectDate,
                calendarMinDate,
                calendarMaxDate,
                objectFetchUrl,
                isVisibleSecondPanel,
                isSelectedPropertyObject,
                eventHappenedOnOtherAddress,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims');
        const travelDateChangeReflowDebounce: number = 50;
        this.findPropertiesWithDebounce =
            Debounce.getInstance()
                .applyTimeout(travelDateChangeReflowDebounce)
                .applyDebounce(this.findProperties);
        this.buildEventHappenedOptions();
        this.setupForm();
    }
});
</script>
