export default class NumericArrayBuilder {
    public static readonly Zero: number = 0;
    public static readonly One: number = 1;
    public static readonly Two: number = 2;
    public static readonly Three: number = 3;
    public static readonly Four: number = 4;
    public static readonly Five: number = 5;
    public static readonly Six: number = 6;
    public static readonly Seven: number = 7;
    public static readonly Eight: number = 8;
    public static readonly Nine: number = 9;
    public static readonly Ten: number = 10;

    private from: number = 0;
    private to: number = 0;
    private concat: number[] = [];

    public withFrom(from: number): NumericArrayBuilder {
        this.from = from;

        return this;
    }

    public withTo(to: number): NumericArrayBuilder {
        this.to = to;

        return this;
    }

    public withConcat(concat: number[] | number): NumericArrayBuilder {
        this.concat = this.concat.concat(concat);

        return this;
    }

    public build(): number[] {
        const length: number = this.from === this.to ? 0 : this.to - this.from + 1;

        return Array(length)
            .fill(0)
            .map((_, index: number) => index + this.from)
            .concat(this.concat);
    }
}
