<script setup lang="ts">
import Sanitizer from '@/services/sanitizer.service';
import {computed, ComputedRef, onMounted, reactive, PropType} from 'vue';
import AppCountry from '@/assets/libraries/app/app-country';
import Validation from '@/services/validation.service';
import PlateNumberPlaceholder from '@/Enums/PlateNumberPlaceholderEnum';
import FormField from '@/assets/libraries/form/form-field';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {useInputErrorMessage} from '@/Composables/InputErrorMessage';

const props = defineProps({
    placeholder: {type: String, default: () => PlateNumberPlaceholder.ByCountry[new AppCountry().iso()]},
    obligatory: {type: Boolean, default: true},
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    feedbackMessage: {type: String, default: ''},
    supportTextMessage: {type: String, default: ''},
});

const emit = defineEmits(['change', 'keyup']);

const field: UnwrapNestedRefs<FormField<string>> = reactive(props.formField);

const fieldId: ComputedRef<string> = computed(() => {
    return field.name + '-with-eu';
});

const icon: ComputedRef<string> = computed(() => {
    return '/images/one/plate-no-' + new AppCountry().iso().toLowerCase() + '.svg';
});

const {infoMessageIsVisible, infoMessage} = useInputErrorMessage(field, props.disableErrorText, {
    supportTextMessage: props.supportTextMessage,
    feedbackMessage: props.feedbackMessage
});

onMounted(() => {
    field.addValidators(validators());
    field.addSanitizer(Sanitizer.cleanPlateNumber);
});

function onKeyUp(): void {
    field.touch();
    field.sanitize();
    field.validate();
    emit('keyup', field.value);
}

function onChange(): void {
    field.touch();
    field.sanitize();
    field.validate();
    emit('change', field.value);
}

function validators(): Record<string, any> {
    return props.obligatory
        ? {isValidPlateNumber: Validation.isValidPlateNumber}
        : {skipPlateNumberValidation: () => true};
}
</script>

<template>
    <div class="input input-plate-number"
         :id="field.name"
         :class="{...field.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : field.name"
         :data-store-value="dataStoreDisabled ? '' : field.value">
        <div v-if="label" class="label informative">
            <label :for="fieldId">{{ label }}</label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper with-eu">
            <div class="eu">
                <img :src="icon" width="23" height="38" alt="">
            </div>
            <input type="text"
                   :id="fieldId"
                   v-model="field.value"
                   :name="field.name"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   @change="onChange()"
                   @keyup="onKeyUp()"
                   v-uppercase>
            <div v-if="infoMessageIsVisible()" class="feedback" v-html="infoMessage()"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.feedback {
    top: 105%;
    width: 100%;
    color: var(--brand-red);
    font-size: var(--font-size-pico);
}
</style>
