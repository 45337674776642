<script lang="ts">
import Vue, {computed, defineComponent, reactive, ref, Ref, watch} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import InformationValidators from '@/pages/Property/Information/InformationValidators';
import {Subscription} from 'rxjs';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import AdditionalObject from '@/interfaces/property/additional.object.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PropertyEnum from '@/Enums/PropertyEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {StepsSubmitterParams, useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {ErrorInterface, useError} from '@/Composables/Error';
import Url from '@/Enums/UrlEnum';
import Method from '@/Enums/MethodEnum';
import moment from 'moment/moment';
import Sanitizer from '@/services/sanitizer.service';
import FormField from '@/assets/libraries/form/form-field';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import OneBase from '@/interfaces/OneBaseInterface';
import {useTransforms} from '@/Composables/Transforms';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();


        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const {translate, language, translateForType}: TranslateParams = useTranslate();
        const {logError}: ErrorInterface = useError();


        const CurrentStep: number = 2;
        const dataLayer: PropertyDataLayer = new PropertyDataLayer();
        const validators: InformationValidators = new InformationValidators();
        let onAfterFormRestoredSubscription!: Subscription;


        const addObjectEnabled: Ref<boolean> = ref(true);
        const removeObjectEnabled: Ref<boolean> = ref(true);
        const validateAddress: Ref<boolean> = ref(false);
        const houseAreaMinLimit: Ref<number> = ref(15);
        const apartmentAreaMinLimit: Ref<number> = ref(10);
        const saunaAreaMinLimit: Ref<number> = ref(5);
        const garageAreaMinLimit: Ref<number> = ref(5);
        const outhouseAreaMinLimit: Ref<number> = ref(5);
        const yearsOffset: Ref<number> = ref(100);
        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const additionalObjects: Ref<AdditionalObject[]> = ref([]);
        const additionalObjectFields: string[] = [
            'additionalObjects',
            'propertyArea',
            'buildingMaterial',
            'buildOrRenovate',
            'buildYear',
            'renovateYear',
        ];
        let stepsStorageData: UnwrapNestedRefs<DynamicDictionary> = reactive({});


        const houseAreaLimit: Ref<number> = computed((): number => {
            return houseAreaMinLimit.value;
        });

        const apartmentAreaLimit: Ref<number> = computed((): number => {
            return apartmentAreaMinLimit.value;
        });

        const saunaAreaLimit: Ref<number> = computed((): number => {
            return saunaAreaMinLimit.value;
        });

        const garageAreaLimit: Ref<number> = computed((): number => {
            return garageAreaMinLimit.value;
        });

        const outhouseAreaLimit: Ref<number> = computed((): number => {
            return outhouseAreaMinLimit.value;
        });

        const yearMinLimit: Ref<number> = computed((): number => {
            return yearsOffset.value;
        });

        const olderBuilding: Ref<string> = computed((): string => {
            return PropertyEnum.BuildingYear.Older;
        });

        const addressMode: Ref<string> = computed((): string => {
            return isHouseSelected.value ?
                'postal' : 'mixed';
        });

        const insuredObjects: Ref<AdditionalObject[]> = computed((): AdditionalObject[] => {
            return additionalObjects.value;
        });

        const isHouseSelected: Ref<boolean> = computed((): boolean => {
            return form.field('propertyType').value === PropertyEnum.Type.House;
        });

        const propertyTypeOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder()
                    .setValue(PropertyEnum.Type.Apartment)
                    .setName(translation('one_property_common_apartment'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.Type.House)
                    .setName(translation('one_property_common_house'))
                    .build(),
            ];
        });

        const buildingMaterialOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder()
                    .setValue(PropertyEnum.BuildingMaterial.Brick)
                    .setName(translation('one_property_common_stone'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.BuildingMaterial.Mixed)
                    .setName(translation('one_property_common_mixed'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.BuildingMaterial.Wood)
                    .setName(translation('one_property_common_wood'))
                    .build(),
            ];

        });

        const inhabitationOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder().setValue('Y').setName(translate('btar_yes')).build(),
                new InputOptionBuilder().setValue('N').setName(translate('btar_no')).build(),
            ];
        });

        const buildOrRenovateOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder()
                    .setValue(PropertyEnum.BuildingYear.Current).setName(yearWithCurrent()).build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.BuildingYear.Older).setName(yearWithTranslation()).build(),
            ];
        });

        const additionalObjectsOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            return [
                new InputOptionBuilder()
                    .setValue(PropertyEnum.AdditionalObjectType.Sauna)
                    .setName(translation('one_property_common_sauna'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.AdditionalObjectType.Garage)
                    .setName(translation('one_property_common_garage'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PropertyEnum.AdditionalObjectType.Outhouse)
                    .setName(translation('one_property_common_outhouse'))
                    .build(),
            ];
        });

        const disableAddObject: Ref<boolean> = computed((): boolean => {
            return !addObjectEnabled.value;
        });

        const disableRemoveObject: Ref<boolean> = computed((): boolean => {
            return !removeObjectEnabled.value;
        });

        const skipAddressValidation: Ref<boolean> = computed((): boolean => {
            return !validateAddress.value;
        });

        const propertyType: Ref<string> = computed((): string => {
            return form.field('propertyType').value;
        });

        const isMobileDevice: Ref<boolean> = computed((): boolean => {
            return btaBase.isMobile.value;
        });

        const localizedYear: Ref<DynamicDictionary> = computed((): DynamicDictionary => {
            return {
                LV: ' gadiem',
                EE: ' aastat',
                RU: ' лет',
                LT: ' metų',
                EN: ' years',
            };
        });


        watch(() => additionalObjects, () => {
            addObjectEnabled.value = additionalObjects.value
                .filter((additionalObject: AdditionalObject) => additionalObject.isVisible)
                .length !== PropertyEnum.AdditionalObjectsLimit;
            removeObjectEnabled.value = additionalObjects.value
                .filter((additionalObject: AdditionalObject) => !additionalObject.isVisible)
                .length !== PropertyEnum.AdditionalObjectsLimit;
        });

        function isRequiredField(fieldName: string): boolean {
            const requiredFields: string[] = [
                'propertyArea',
                'buildYear',
            ];
            return requiredFields.includes(fieldName);
        }

        function calculatedFeedbackMessage(fieldName: string, fieldIndex: string): string {
            let message: string;
            switch (fieldName) {
                case 'propertyArea':
                    message = propertyAreaFeedbackMessage(fieldIndex);
                    break;
                case 'buildYear':
                    message = yearRangeFeedbackMessage('buildYear', fieldIndex);
                    break;
                case 'renovateYear':
                    message = yearRangeFeedbackMessage('renovateYear', fieldIndex);
                    break;
                default:
                    message = '';
            }

            return message;
        }

        function addObject(): void {
            const nextInvisibleObject: AdditionalObject = additionalObjects.value
                .find(({isVisible}): boolean => !isVisible)!;
            $(nextInvisibleObject.element!).show();
            additionalObjects.value[nextInvisibleObject.index].isVisible = true;
            resetAdditionalObjectFields(nextInvisibleObject.index);
        }

        function removeObject(): void {
            const lastVisibleObject: AdditionalObject =
                additionalObjects.value.slice().reverse().find(({isVisible}) => isVisible)!;
            $(lastVisibleObject.element!).hide();
            additionalObjects.value[lastVisibleObject.index].isVisible = false;
            resetAdditionalObjectFields(lastVisibleObject.index);
        }

        function validateBeforeSubmit(): void {
            dataLayer.pushDataLayer(dataLayerParams());
            form.touch().then((): void => {
                form.validate().then((): void => {
                    if (form.isValid()) {
                        Vue.nextTick(() => {
                            btaBase.captcha.executeCaptcha(formSubmit).then().catch((reason: string) => {
                                logError(ErrorType.Error, 'validateBeforeSubmit', reason);
                            });
                        });
                    } else {
                        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                        if (invalidElements.length > 0) {
                            const scrollTarget: JQuery = invalidElements
                                .filter((index: number, element: HTMLElement) => element.getAttribute('style') !== 'display: none;');
                            scrollTarget[0].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
                        }
                    }
                });
            });
        }

        function formSubmit(token: string): void {
            prepareSubmit(token);
            stepsSubmitter.submitMethod(Method.Post)
            stepsSubmitter.submitStep(Url.Ajax.propertyInformation);
        }

        function prepare(): void {
            applyDefaultCmsValues();
            setupForm().then((): void => {
                validators.init(
                    form,
                    insuredObjects,
                    isHouseSelected,
                    houseAreaLimit,
                    apartmentAreaLimit,
                    saunaAreaLimit,
                    garageAreaLimit,
                    outhouseAreaLimit,
                    yearMinLimit
                );
            });
            applyFormFieldSubscriptions();
        }

        function propertyAreaFeedbackMessage(fieldIndex: string = ''): string {
            let message: string = '';
            if (
                !fieldIndex
                && !form.field('propertyArea').isValid
                && !form.field('propertyArea').isEmpty()
            ) {
                message = translation('one_property_information_area_error');
                message += isHouseSelected.value ?
                    houseAreaLimit.value : apartmentAreaLimit.value;
            }
            if (
                fieldIndex
                && !form.field('propertyArea' + fieldIndex).isValid
                && !form.field('propertyArea' + fieldIndex).isEmpty()
            ) {
                message = translation('one_property_information_area_error');
                switch (form.field('additionalObjects' + fieldIndex).value) {
                    case PropertyEnum.AdditionalObjectType.Sauna:
                        message += saunaAreaLimit.value;
                        break;
                    case PropertyEnum.AdditionalObjectType.Garage:
                        message += garageAreaLimit.value;
                        break;
                    case PropertyEnum.AdditionalObjectType.Outhouse:
                        message += outhouseAreaLimit.value;
                        break;
                    default:
                }
            }

            return message;
        }

        function yearRangeFeedbackMessage(fieldName: string, fieldIndex: string = ''): string {
            let message: string = '';
            const formFieldName: string = fieldName + fieldIndex;
            if (!form.field(formFieldName).isValid
                && !form.field(formFieldName).isEmpty()) {
                switch (fieldName) {
                    case 'buildYear':
                        message = buildYearMessageByError(formFieldName)
                        break;
                    case 'renovateYear':
                        message = renovateYearMessageByError(formFieldName)
                        break;
                    default:
                }
            }

            return message;
        }

        function buildYearMessageByError(fieldName: string): string {
            return validators.yearIsGreaterThanCmsLimit(fieldName)
                ? translation('one_property_information_build_year_error')
                : translation('one_property_information_build_year_limit') + yearLimitLocalized();
        }

        function renovateYearMessageByError(fieldName: string): string {
            return validators.yearIsGreaterThanCmsLimit(fieldName)
                ? translation('one_property_information_renovate_year_error')
                : translation('one_property_information_renovate_year_limit') + yearLimitLocalized();
        }

        function yearLimitLocalized(): string {
            const locale: string = String(language()).toUpperCase();

            return ' ' + yearMinLimit.value + localizedYear.value[locale];
        }

        function yearWithCurrent(): string {
            return String(moment().subtract(PropertyEnum.BuildingYear.OffsetCurrent, 'years').year())
                + PropertyEnum.BuildingYear.DashSeparator
                + moment().year();
        }

        function yearWithTranslation(): string {
            return String(moment().subtract(PropertyEnum.BuildingYear.OffsetOlder, 'years').year()) +
                PropertyEnum.BuildingYear.SpaceSeparator +
                translation('one_property_information_older');
        }

        function resetAdditionalObjectFields(fieldIndex: number): void {
            additionalObjectFields.forEach((fieldName: string): void => {
                form.field(fieldName + fieldIndex).clear().then();
            })
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
            const propertyAddress = form.field('propertyAddress').value;
            const detailedPropertyAddress = propertyAddress.detailed !== '' ?
                [propertyAddress.addressName, propertyAddress.detailed].join(', ') : '';
            stepsSubmitter.addSubmitCustomParam('propertyAddress', propertyAddress);
            stepsSubmitter.addSubmitCustomParam('policy[checkUserRights]', 'true');
            if (form.field('propertyType').value === PropertyEnum.Type.Apartment) {
                stepsSubmitter.addSubmitCustomParam('propertyAddress[flatNumber]', form.field('propertyAddress').value.apartment)
            }
            stepsSubmitter.addSubmitCustomParam('propertyAddress[searchData]', form.field('propertyAddress').value.addressCode)
            stepsSubmitter.addSubmitCustomParam('propertyAddress[postalCode]', form.field('propertyAddress').value.postCode)
            stepsSubmitter.addSubmitCustomParam('propertyAddress[detailedAddress]', detailedPropertyAddress)
            stepsSubmitter.addSubmitCustomParam('properties[0][type]', form.field('propertyType').value)
            stepsSubmitter.addSubmitCustomParam('properties[0][material]', form.field('buildingMaterial').value)
            stepsSubmitter.addSubmitCustomParam('properties[0][permanentlyInhabited]', form.field('inhabitation').value === 'Y')
            stepsSubmitter.addSubmitCustomParam('properties[0][constructionYear]', constructionYear())
            stepsSubmitter.addSubmitCustomParam('properties[0][renovationYear]', renovationYear())
            stepsSubmitter.addSubmitCustomParam('properties[0][totalArea]', parseFloat(form.field('propertyArea').value));
            if (form.field('propertyType').value === PropertyEnum.Type.House) {
                additionalObjects.value.forEach((additionalObject: AdditionalObject): void => {
                    if (additionalObject.isVisible) {
                        const paramNamePrefix: string = 'properties[' + (additionalObject.index + 1) + ']';
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix +
                            '[material]', form.field('buildingMaterial' + additionalObject.index).value);
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix + '[totalArea]',
                            form.field('propertyArea' + additionalObject.index).value);
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix +
                            '[constructionYear]', constructionYear(additionalObject.index));
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix +
                            '[renovationYear]', renovationYear(additionalObject.index));
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix +
                            '[type]', PropertyEnum.Type.AdditionalObject);
                        stepsSubmitter.addSubmitCustomParam(paramNamePrefix +
                            '[subType]', form.field('additionalObjects' + additionalObject.index).value);
                    }
                })
            }
            stepsSubmitter.addSubmitCustomParam('dataLayerParams', dataLayerParams());
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }

        function dataLayerParams(): DynamicDictionary {
            const NoAdditionalProperties: string = 'NA';
            const ModernProperty: string = 'YearNew';
            const OldProperty: string = 'YearOld';
            const dataLayer: DynamicDictionary =
                useTransforms().deepClonedObjectWithoutVueReactivity(btaBase.userStorage.stepStorageData.dataLayerParams);
            let inhabitant: string = 'Inh' + form.field('inhabitation').value;
            inhabitant += '|Mat' + titleCase(form.field('buildingMaterial').value);
            inhabitant += '|' + (form.field('buildOrRenovate').value === 'current' ? ModernProperty : OldProperty);
            dataLayer.dimension14 = inhabitant;
            const subElements: string[] = [];
            if (form.field('propertyType').value === PropertyEnum.Type.House) {
                additionalObjects.value.forEach((additionalObject: AdditionalObject): void => {
                    if (additionalObject.isVisible) {
                        subElements.push(transformSubPropertyType(
                            form.field('additionalObjects' + additionalObject.index).value));
                    }
                });
            }
            if (subElements.length === 0) {
                subElements.push(NoAdditionalProperties);
            }
            dataLayer.dimension15 = subElements.join(',');

            return dataLayer;
        }

        function transformSubPropertyType(id: string): string {
            const property: DynamicDictionary = new class implements DynamicDictionary {
                public 'BLDA01': string = 'Sauna';
                public 'BLDF10': string = 'Garage';
                public 'BLDA04': string = 'Barn';
            }

            return property[id];
        }

        function titleCase(source: string) {
            return source[0].toUpperCase() + source.slice(1).toLowerCase();
        }

        function constructionYear(fieldIndex?: number): string {
            let constructionYear: string;
            const yearFromForm: string = form.field(formFieldName('buildOrRenovate', fieldIndex)).value;
            switch (yearFromForm) {
                case PropertyEnum.BuildingYear.Current:
                    constructionYear = String(moment().year());
                    break;
                case PropertyEnum.BuildingYear.Older:
                    constructionYear = form.field(formFieldName('buildYear', fieldIndex)).value;
                    break;
                default:
                    constructionYear = '';
            }

            return constructionYear;
        }

        function renovationYear(fieldIndex?: number): string {
            return form.field(
                formFieldName('buildOrRenovate', fieldIndex)).value === PropertyEnum.BuildingYear.Older ?
                form.field(formFieldName('renovateYear', fieldIndex)).value ||
                form.field(formFieldName('buildYear', fieldIndex)).value : constructionYear(fieldIndex);
        }

        function formFieldName(name: string, fieldIndex?: number): string {
            return fieldIndex || fieldIndex === 0
                ? name + fieldIndex
                : name;
        }

        function setupForm(): Promise<void> {
            return new Promise(resolve => {
                form.addField(new FormField('propertyType'));
                form.addField(new FormField('propertyArea', '', '', Sanitizer.cleanNumber));
                form.addField(new FormField('propertyAddress'));
                form.addField(new FormField('inhabitation', 'Y', 'required'));
                form.addField(new FormField('buildingMaterial', PropertyEnum.BuildingMaterial.Brick,));
                form.addField(new FormField('buildOrRenovate', PropertyEnum.BuildingYear.Current));
                form.addField(new FormField('buildYear', '', validators.buildYearValidator(), Sanitizer.cleanYear));
                form.addField(
                    new FormField('renovateYear', '', validators.renovateYearValidator(), Sanitizer.cleanYear));
                addAdditionalObjectFields();
                form.setReady();
                resolve();
            });
        }

        function applyFormFieldSubscriptions(): void {
            form.field('propertyAddress').onTouch.subscribe((): void => {
                validateAddress.value = true;
            });
            form.field('buildYear').onTouch.subscribe((): void => {
                if (form.field('buildOrRenovate').value !== PropertyEnum.BuildingYear.Older &&
                    form.field('buildYear').isEmpty()
                ) {
                    form.field('buildYear').clear().then();
                }
            });
        }

        function addAdditionalObjectFields(): void {
            additionalObjectFields.forEach((fieldName: string): void => {
                for (let fieldIndex: number = 0; fieldIndex < PropertyEnum.AdditionalObjectsLimit; fieldIndex++) {
                    form.addField(new FormField(fieldName + fieldIndex, '', {}));
                }
            });
        }

        function onAfterFormRestored(restoredElementsCount: number): void {
            Vue.nextTick((): void => {
                setupAdditionalObjects();
                stepsStorageData = btaBase.userStorage.stepStorageData;
                patchValuesFromSteps();
                if (restoredElementsCount) {
                    resetDetailedAddress();
                }
                form.field('propertyArea').addValidators(validators.propertyAreaValidator());
                form.field('propertyAddress').addValidators(validators.propertyAddressValidator());
                applyAdditionalObjectValidators();
            });
        }

        function applyAdditionalObjectValidators(): void {
            additionalObjectFields.forEach((fieldName: string): void => {
                for (let fieldIndex: number = 0; fieldIndex < PropertyEnum.AdditionalObjectsLimit; fieldIndex++) {
                    const field: FormField = form.field(fieldName + fieldIndex);
                    if (fieldName !== 'buildYear' && fieldName !== 'renovateYear') {
                        field.addValidators(validators.additionalObjectValidator(fieldName, fieldIndex));
                    }
                    if (fieldName === 'propertyArea') {
                        field.addSanitizer(Sanitizer.cleanNumber);
                        field.addValidators(validators.additionalObjectAreaValidator('propertyArea', fieldIndex));
                    }
                    if (fieldName === 'renovateYear' || fieldName === 'buildYear') {
                        field.addSanitizer(Sanitizer.cleanYear);
                    }
                    if (fieldName === 'renovateYear') {
                        field.addValidators(validators.additionalObjectRenovateYearValidator(fieldIndex));
                    }
                    if (fieldName === 'buildYear') {
                        field.addValidators(validators.additionalObjectBuildYearValidator(fieldIndex));
                    }
                }
            });
        }

        function setupAdditionalObjects(): void {
            const currentAdditionalObjects: JQuery = $('[class^=additional-object]');
            currentAdditionalObjects.each((index: number, additionalObject: HTMLElement) => {
                const object: AdditionalObject = {isVisible: false, index: 0, element: null};
                if (additionalObject.style.display !== 'none') {
                    object.isVisible = true;
                }
                object.index = index;
                object.element = additionalObject;
                additionalObjects.value.push(object);
            })
        }

        function patchValuesFromSteps(): void {
            if (form.field('propertyType').isEmpty()) {
                form.field('propertyType').patch(stepsStorageData.propertyType);
            }
            if (form.field('propertyArea').isEmpty()) {
                form.field('propertyArea').patch(stepsStorageData.propertyArea);
            }
        }

        function resetDetailedAddress(): void {
            if (form.field('propertyAddress').value.detailed) {
                form.field('propertyAddress').value.detailed = '';
            }
        }

        function translation(key: string): string {
            return translateForType(key, PropertyEnum.TranslationType);
        }

        function applyDefaultCmsValues(): void {
            houseAreaMinLimit.value = btaBase.cmsFields.propertyArea.value.house;
            apartmentAreaMinLimit.value = btaBase.cmsFields.propertyArea.value.apartment;
            saunaAreaMinLimit.value = btaBase.cmsFields.propertyArea.value.sauna;
            garageAreaMinLimit.value = btaBase.cmsFields.propertyArea.value.garage;
            outhouseAreaMinLimit.value = btaBase.cmsFields.propertyArea.value.outhouse;
            yearsOffset.value = btaBase.cmsFields.buildOrRenovate.value.minLimit;
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        return {
            ...btaBase, ...{
                addObjectEnabled,
                removeObjectEnabled,
                validateAddress,
                houseAreaMinLimit,
                apartmentAreaMinLimit,
                saunaAreaMinLimit,
                garageAreaMinLimit,
                outhouseAreaMinLimit,
                yearsOffset,
                form,
                additionalObjects,
                stepsStorageData,
                additionalObjectFields,
                houseAreaLimit,
                apartmentAreaLimit,
                saunaAreaLimit,
                garageAreaLimit,
                outhouseAreaLimit,
                yearMinLimit,
                olderBuilding,
                addressMode,
                insuredObjects,
                isHouseSelected,
                propertyTypeOptions,
                buildingMaterialOptions,
                inhabitationOptions,
                buildOrRenovateOptions,
                additionalObjectsOptions,
                disableAddObject,
                disableRemoveObject,
                skipAddressValidation,
                propertyType,
                isMobileDevice,
                localizedYear,
                CurrentStep,
                prepare,
                onAfterFormRestored,
                isRequiredField,
                calculatedFeedbackMessage,
                addObject,
                removeObject,
                validateBeforeSubmit,
                formSubmit,
                applyStepUrls,
            }
        };
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-property');
        this.setStorageUsage(true);
        const onAfterFormRestoredSubscription =
            this.userStorage.onFormStorageDataIsReady.subscribe((count: number) => {
                this.onAfterFormRestored(count);
                onAfterFormRestoredSubscription.unsubscribe();
            });
        Vue.nextTick(() => {
            this.prepare();
        });
    },
});
</script>
