<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import {computed, onMounted, reactive, ref, Ref} from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import {useTranslate} from '@/Composables/Translate';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import DamagePanelsNames from '@/Apps/ClaimsMtpl/Enums/DamagePanelsNamesEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ObjectItem from '@/interfaces/ObjectItemInterface';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import {SubFlowEvent} from '@/Apps/ClaimsMtpl/Interfaces/SubFlowEvent';
import {useClaimsMtplHtml} from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import Translations from '@/services/translations.service';
import VueRouter from 'vue-router';
import {useRouter} from 'vue-router/composables';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import VehicleDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/VehicleDamageNames';
import BelongingsDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/BelongingsDamageNames';
import PersonDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PersonDamageNames';
import PropertyDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PropertyDamageNames';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import EventBus from '@/services/event.bus.service';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import {useScroll} from '@/Composables/Scroll';
import ClaimsMtplObject from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplObjectInterface';

const router: VueRouter = useRouter();
const {translate, translateForType} = useTranslate();
const {scrollToPanel} = useClaimsMtplHtml();

const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});
const form: UnwrapNestedRefs<Form> = reactive(new Form());
const forceProceedNextStep: Ref<boolean> = ref(false);
const subFlows: UnwrapNestedRefs<DynamicDictionary> = reactive({
    [DamageTypes.Vehicle]: {
        component: 'AppVehicleDamage',
        fields: VehicleDamageNames,
    },
    [DamageTypes.Belongings]: {
        component: 'AppBelongingsDamage',
        fields: BelongingsDamageNames,
    },
    [DamageTypes.Property]: {
        component: 'AppPropertyDamage',
        fields: PropertyDamageNames,
    },
    [DamageTypes.Person]: {
        component: 'AppPersonDamage',
        fields: PersonDamageNames,
    }
});

const Step: number = 1;
const optionSmartListSeparator: string = '@#@';
const agreedStatementProceedOptionValue: string = 'yes';
const accidentHandledAgreedStatementOptionValue: string = 'agreed_statement';
const objectListDataScroll: string = 'objectList';
const readOnlyFlowsAgreedStatementSelected: DamageTypes[] = [
    DamageTypes.Belongings, DamageTypes.Person, DamageTypes.Property
];
const subFlowOrder: DamageTypes[] = [
    DamageTypes.Vehicle, DamageTypes.Belongings, DamageTypes.Property, DamageTypes.Person,
];


const canProceedToNextStep: Ref<boolean> = computed(() => {
    return showObjectList.value || forceProceedNextStep.value;
});

const accidentHandledWithAgreedStatement: Ref<boolean> = computed(() => {
    return accidentHandledWithDigitalAgreedStatement.value
        || claimsMtplService.fields.howWasInvolvedHandled?.selected === accidentHandledAgreedStatementOptionValue
});

const accidentHandledWithDigitalAgreedStatement: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.weFoundElectronically?.selected === agreedStatementProceedOptionValue;
});

const objectListItems: Ref<ObjectItem[]> = computed(() => {
    return inputOptions[DamagePanelsNames.WhatWasDamaged].options
        .map((option: InputOption): ObjectItem => {
            return {
                id: String(option.value),
                title: localized(`damage_type_${option.value}`),
                value: claimsMtplService.damageStepSubFlows[String(option.value)].result ?
                    claimsMtplService.damageStepSubFlows[String(option.value)].result.description
                    : '',
            }
        })
        .filter((item: ObjectItem) => claimsMtplService.damageStepSubFlows[item.id].result !== null)
});

const whatWasDamagedSelectedOptions: Ref<string[]> = computed(() => {
    if (form.field(DamagePanelsNames.WhatWasDamaged).value !== '') {
        return form.field(DamagePanelsNames.WhatWasDamaged).value.selected
            ?.split(optionSmartListSeparator)
            .filter((option: string): boolean => option !== '');
    }

    return [];
});

const filteredDamageTypeOptions: Ref<string[]> = computed(() => {
    return claimsMtplService.damageTypeFilterData
        .filter((item: DynamicDictionary): boolean => {
            return item.claim_type === claimsMtplService.fields.typeOfClaim?.selected
                && item.guilty_party_branch === involvedVehicle.value.branch;
        })
        .map((item: DynamicDictionary): DamageTypes => item.damage_type)
        .filter((item: DamageTypes): boolean => agreedStatementDamageTypeFilters(item))
        .sort((a: DamageTypes, b: DamageTypes): number => subFlowOrder.indexOf(a) - subFlowOrder.indexOf(b));
});

const involvedVehicle: Ref<ClaimsMtplObject> = computed(() => {
    return claimsMtplService.insuredObjects.value
        .find((object: ClaimsMtplObject) => object.id === claimsMtplService.fields.whatVehicleWasInvolved!.object)!;
});

function agreedStatementDamageTypeFilters(type: DamageTypes): boolean {
    return !(accidentHandledWithAgreedStatement.value && readOnlyFlowsAgreedStatementSelected.includes(type))
}

const selectedSubFlows: Ref<DynamicDictionary> = computed(() => {
    const unorderedSelectedSubFlows = inputOptions[DamagePanelsNames.WhatWasDamaged].options
        .reduce((accumulator: DynamicDictionary, option: InputOption) => {
            if (whatWasDamagedSelectedOptions.value.some((selectedOption: string): boolean => selectedOption === option.value)) {
                accumulator[String(option.value)] = subFlows[String(option.value)].component;
            }

            return accumulator;
        }, {});

    return subFlowOrder.map((flowKey: DamageTypes): DynamicDictionary => {
        return {
            key: flowKey,
            flow: unorderedSelectedSubFlows[flowKey],
        } as DynamicDictionary;
    }).filter((item: DynamicDictionary): boolean => item.flow)
        .filter((item: DynamicDictionary): boolean => isSubFlowVisible(item.key));
});

const showObjectList: Ref<boolean> = computed(() => {
    const subFlowsCompleted: boolean = whatWasDamagedSelectedOptions.value.every((subFlowLabel: string): boolean =>
        claimsMtplService.damageStepSubFlows[subFlowLabel].result && !claimsMtplService.damageStepSubFlows[subFlowLabel].editing
    );

    return subFlowsCompleted && whatWasDamagedSelectedOptions.value.length !== 0;
});


function preparePanels(): void {
    Object.keys(DamagePanelsNames).forEach((key: string) => {
        const panelName: string = DamagePanelsNames[key as keyof DamagePanelsNames];
        inputOptions[panelName] = reactive(new class ClaimsMtplOptions {
            public enabled: boolean = true;
            public passed: boolean = false;
            public visible: boolean = false;
            public value: LimitedVariant = '';
            public options: InputOption[] = [];
        });
    });
}

function buildWhatWasDamagedOptions(): void {
    inputOptions[DamagePanelsNames.WhatWasDamaged].options = filteredDamageTypeOptions.value.map((subFlow: string): InputOption => {
        return (new InputOptionBuilder)
            .setName(localized(`damage_type_${subFlow}`))
            .setValue(subFlow)
            .setTipster('', localized(`damage_type_${subFlow}_tooltip`))
            .build();
    });
}

function proceedButton(): ButtonWithCallbackParams {
    return {
        title: localized('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function setupForm(): void {
    form.addField(new FormField(DamagePanelsNames.WhatWasDamaged));
    form.setReady();
}

function restoreValues(): void {
    form.field(DamagePanelsNames.WhatWasDamaged).setValue(claimsMtplService.fields.whatWasDamaged as LimitedVariant);
}

function storeFormToService(): void {
    claimsMtplService.fields.whatWasDamaged = form.field(DamagePanelsNames.WhatWasDamaged).value;
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function isSubFlowVisible(flow: DamageTypes) {
    return claimsMtplService.damageStepSubFlows[flow].visible === true;
}

function setSubFlowVisibility(flow: DamageTypes, visibility: boolean) {
    claimsMtplService.damageStepSubFlows[flow].visible = visibility;
}

function isNotCompletedSubFlow(flow: DamageTypes): boolean {
    return claimsMtplService.damageStepSubFlows[flow].result === null || claimsMtplService.damageStepSubFlows[flow].editing;
}

function firstSubFlowDataScroll(): string {
    const firstUncompletedSubFlow: string | undefined = Object.keys(claimsMtplService.damageStepSubFlows)
        .sort((a: string, b: string): number => subFlowOrder.indexOf(a as DamageTypes) - subFlowOrder.indexOf(b as DamageTypes))
        .filter((subFlow: string): boolean => whatWasDamagedSelectedOptions.value.includes(subFlow))
        .filter((subFlow: string): boolean => isNotCompletedSubFlow(subFlow as DamageTypes))
        .at(0);
    if (firstUncompletedSubFlow && !subFlowSelectionRedirects()) {
        setSubFlowVisibility(firstUncompletedSubFlow as DamageTypes, true);
        resetOtherSubFlowVisibility(firstUncompletedSubFlow as DamageTypes);
    }

    return firstUncompletedSubFlow ?? '';
}

function resetOtherSubFlowVisibility(targetSubFlow: DamageTypes): void {
    Object.keys(claimsMtplService.damageStepSubFlows)
        .forEach((subFlow: string): void => {
            if (targetSubFlow !== subFlow) {
                setSubFlowVisibility(subFlow as DamageTypes, false);
            }
        });
}

function scrollToSubFlow(subFlow: string): void {
    const subFlowOffset: number = -30;
    useScroll().scrollToViewByDataScroll(subFlow, subFlowOffset);
}

function scrollToLastPanel(): void {
    let targetPanelName: string = '';
    let targetScrollIsSubFlow: boolean = true;

    const lastVisibleFlow: string | undefined = Object.keys(subFlows)
        .filter((subFlow: string): boolean => claimsMtplService.damageStepSubFlows[subFlow].visible)
        .at(-1);
    if (showObjectList.value) {
        targetPanelName = objectListDataScroll
        targetScrollIsSubFlow = false;
    } else if (lastVisibleFlow && lastVisibleFlow === DamageTypes.Vehicle) {
        if (claimsMtplService.damageStepLastVisiblePanel.value === '') {
            targetPanelName = lastVisibleFlow;
        } else {
            targetScrollIsSubFlow = false;
            targetPanelName = claimsMtplService.damageStepLastVisiblePanel.value;
        }
    } else if (lastVisibleFlow) {
        targetPanelName = lastVisibleFlow;
    } else {
        targetPanelName = DamagePanelsNames.WhatWasDamaged;
    }

    setTimeout((): void => {
        if (targetScrollIsSubFlow) {
            scrollToSubFlow(targetPanelName);
        } else {
            scrollToPanel(targetPanelName);
        }
    });
}

function onPanelProceedClick(panelName: string): void {
    if (subFlowSelectionRedirects()) {
        forceProceedNextStep.value = true;
        onChange();
        onProceed();
    } else if (panelName) {
        scrollToSubFlow(panelName);
    } else {
        scrollToPanel(objectListDataScroll);
    }
}

function onCompletedProcess(event: SubFlowEvent): void {
    const subFlow: DamageTypes = Object.keys(event)[0] as DamageTypes;
    claimsMtplService.damageStepSubFlows[subFlow].result = event[subFlow];
    claimsMtplService.damageStepSubFlows[subFlow].editing = false;
    scrollToNextPanel(subFlow);
    onChange();
}

function scrollToNextPanel(subFlowId: string): void {
    const optionValues: string[] = inputOptions[DamagePanelsNames.WhatWasDamaged].options
        .map((option: InputOption): string => option.value as string);
    const nextFlowIndex: number = optionValues.indexOf(subFlowId) + 1;
    const nextUncompletedSubFlow: DamageTypes | undefined = optionValues
        .slice(nextFlowIndex)
        .filter((subFlow: string): boolean => whatWasDamagedSelectedOptions.value.includes(subFlow))
        .filter((subFlow: string): boolean => isNotCompletedSubFlow(subFlow as DamageTypes))
        .at(0) as DamageTypes;
    setSubFlowVisibility(subFlowId as DamageTypes, false);
    if (nextUncompletedSubFlow && !subFlowSelectionRedirects()) {
        setSubFlowVisibility(nextUncompletedSubFlow, true);
        scrollToSubFlow(nextUncompletedSubFlow);
    } else if (showObjectList.value && !subFlowSelectionRedirects()) {
        setTimeout((): void => {
            scrollToPanel(objectListDataScroll);
        });
    }
}

function subFlowSelectionRedirects(): boolean {
    return whatWasDamagedSelectedOptions.value.length === 1 && whatWasDamagedSelectedOptions.value[0] === DamageTypes.Person;
}

function onEditListObject(targetSubFlow: string): void {
    setSubFlowVisibility(targetSubFlow as DamageTypes, true);
    claimsMtplService.damageStepSubFlows[targetSubFlow].editing = true;
    scrollToSubFlow(targetSubFlow);
}

function onRemoveListObject(subFlow: string): void {
    clearSubFlow(subFlow);
    form.field(DamagePanelsNames.WhatWasDamaged).value.selected = whatWasDamagedSelectedOptions.value
        .filter((option: string): boolean => option !== subFlow)
        .join(optionSmartListSeparator);
    onChangeWhatWasDamaged();
    handleDeletedLastElement();
}

function clearSubFlow(subFlow: string): void {
    claimsMtplService.damageStepSubFlows[subFlow].result = null;
    claimsMtplService.damageStepSubFlows[subFlow].editing = false;
    setSubFlowVisibility(subFlow as DamageTypes, false);
}

function handleDeletedLastElement(): void {
    if (Object.keys(subFlows)
        .filter((key: string): boolean => claimsMtplService.damageStepSubFlows[key].result !== null)
        .length === 0
    ) {
        scrollToSubFlow(DamagePanelsNames.WhatWasDamaged);
    }
}

function onChangeWhatWasDamaged(): void {
    clearSubFlowInputs();
    clearSubFlowsInService();
    EventBus.getInstance().emit('updateStepVisibility', [
        {
            stepName: MtplClaimsStepUid.ExtraDetails,
            isVisible: claimsMtplService.isExtraDetailsPageVisible(),
        },
        {
            stepName: MtplClaimsStepUid.IndemnityReceiver,
            isVisible: claimsMtplService.isExtraDetailsPageVisible(),
        }
    ]);
}

function clearSubFlowInputs(): void {
    Object.keys(subFlows)
        .filter((subFlow: string): boolean => !whatWasDamagedSelectedOptions.value.includes(subFlow))
        .forEach((subFlow: string): void => {
            Object.keys(subFlows[subFlow].fields).forEach((field: string): void => {
                const serviceKey: string = subFlow + field.charAt(0).toUpperCase() + field.slice(1);
                const storedValues: DynamicDictionary = claimsMtplService.fields;

                storedValues[serviceKey] = '';
            });
        });
}

function clearSubFlowsInService(): void {
    Object.keys(claimsMtplService.damageStepSubFlows)
        .forEach((subFlow: string): void => {
            const isSubFlowSelected = whatWasDamagedSelectedOptions.value.includes(subFlow);
            if (!isSubFlowSelected) {
                clearSubFlow(subFlow);
            }
        });
}

function onProceedAgreedStatementChange(selected: string): void {
    if (selected === 'yes') {
        forceProceedNextStep.value = true;
        onChange();
        onProceed();
    }
}

function onLastPanel(lastPanel: string): void {
    claimsMtplService.damageStepLastVisiblePanel.value = lastPanel;
}

function onChangeSubFlow(): void {
    onChange();
}

function onProceed(): void {
    storeFormToService();
    router.push({name: MtplClaimsStepUid.AccidentDetails});
}

function onChange(): void {
    storeFormToService();
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
}


preparePanels();
buildWhatWasDamagedOptions();

onMounted(() => {
    setupForm();
    restoreValues();
    form.validate().then(() => {
        scrollToLastPanel();
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, false);
    });
});
defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            @change="onChange()"
            class="form">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link
                    class="back back-margin"
                    :to="{name: MtplClaimsStepUid.AccidentData}"
                    :tag="'button'"
                    :data-scroll="DamagePanelsNames.WhatWasDamaged"
                    :disabled="!form.isValid()">
                    <img src="images/one/arrow-left.svg" alt="back">
                    <span>{{ translate('back_button') }}</span>
                </router-link>
                <label>{{ localized('damage_title') }}</label>
                <div class="whiteboard">
                    <h4 class="title">{{ localized('what_was_damaged_title') }}</h4>
                    <app-options-smart-list
                        :options="inputOptions[DamagePanelsNames.WhatWasDamaged].options"
                        :option-class="'filled'"
                        :skip-options-change-form-reset="true"
                        :form-field="form.field(DamagePanelsNames.WhatWasDamaged)"
                        @change="onChangeWhatWasDamaged">
                    </app-options-smart-list>
                    <app-button-with-callback class="button"
                                              v-bind="proceedButton()"
                                              @button-callback-click="onPanelProceedClick(firstSubFlowDataScroll())"
                                              :disabled="whatWasDamagedSelectedOptions.length === 0">
                    </app-button-with-callback>
                </div>
            </div>
            <component
                v-for="(item, key) in selectedSubFlows"
                :key="key"
                :dataScroll="item.key"
                :is="item.flow"
                @lastPanel="onLastPanel"
                @change="onChangeSubFlow"
                @proceedAgreedStatementChange="onProceedAgreedStatementChange"
                @completed="onCompletedProcess">
            </component>
            <div class="whiteboard-panel">
                <div class="whiteboard"
                     v-if="showObjectList"
                     :data-scroll="objectListDataScroll">
                    <h4 class="title">{{ localized('what_was_damaged_title') }}</h4>
                    <app-object-list
                        @edit="onEditListObject"
                        @remove="onRemoveListObject"
                        :items="objectListItems">
                    </app-object-list>
                    <app-button-with-callback class="button"
                                              v-bind="proceedButton()"
                                              @button-callback-click="onProceed()">
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    ::v-deep .input {
        .label label {
            color: var(--text-color-default);
            font-weight: 600;
        }
    }

    .button {
        margin-top: var(--size-medium);
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }
}
</style>
