import VueRouter, {Route} from 'vue-router';
import {NavigationGuardNext} from 'vue-router/types/router';
import {useRoute, useRouter} from 'vue-router/composables';
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import EventBus from '@/services/event.bus.service';

export default class GuardsService {
    private static instance: GuardsService;
    private payByLinkService: PayByLinkService = PayByLinkService.getInstance();
    private route: Route = useRoute();
    private router: VueRouter = useRouter();

    public static getInstance(): GuardsService {
        if (!GuardsService.instance) {
            GuardsService.instance = new GuardsService();
        }
        return GuardsService.instance;
    }

    public init(): void {
        this.guardRouteDirectAccess();
        this.router.beforeEach((to: Route, from: Route, next: NavigationGuardNext): void => {
            switch (to.name) {
                case 'pay-by-link-pay-summary':
                    this.canProceedToSummaryPage() ? next() : next(false);
                    break;
                case 'pay-by-link-pay-edit':
                    if (this.canProceedToEditPage()) {
                        next();
                    } else {
                        next(false);
                        EventBus.getInstance().emit('offer-failed', {});
                    }
                    break;
                default:
                    next();
            }
        });
    }

    /**
     * Supposed to protect route from opening directly entering route path in address bar.
     * It seems that it now works only on /en version routes.
     * Planning to get rid of this method after migrating to vue3 - replacing with beforeRouteEnter event
     * @private
     */
    private guardRouteDirectAccess(): void {
        if (String(this.route.path).endsWith('/summary') && !this.canProceedToSummaryPage()) {
            const parts: string[] = this.route.path.split('/');
            const redirectUrl: string = String(this.router.options.routes![1].path).replace(':any', parts[3]);
            this.router.replace({path: redirectUrl}).then();
        }
    }

    private canProceedToSummaryPage(): boolean {
        return true;
    }

    private canProceedToEditPage(): boolean {
        return !this.payByLinkService.hasCalculationError.value;
    }
}
