<script lang="ts" setup>
import {onMounted, ref, Ref} from 'vue' ;
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useTranslate} from '@/Composables/Translate';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import User from '@/services/user.service';
import NextBestActionCustomer from '@/interfaces/next.best.action.customer.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import PopupService from "@/services/custom.popup.service";
import OnePopup from '@/assets/libraries/popups/one.popup';
import moment from 'moment';

const {translateForType} = useTranslate();
const userInstance: User = User.getInstance();
const popupService: PopupService = PopupService.getInstance();
const popupInstanceTypeIs = (type: string): boolean => {
    return popupService.activePopupType === type;
}
const request: AxiosParams = useAxios();
const componentIsReady: Ref<boolean> = ref(false);
const nbaCustomer: Ref<NextBestActionCustomer | null> = ref(null);

let timer: NodeJS.Timeout;

onMounted((): void => {
    fetchItems().then(() => {
        componentIsReady.value = true;
        if (needToShowPopup()) {
            timer = setTimeout(forceShowPopup,
                nbaCustomer.value!.delay * 1000);
        }
    });
});

async function fetchItems(): Promise<void> {
    const params: DynamicDictionary = {
        data: {
            customerId: userInstance.current.personId
        }
    };
    await request.post(Url.Ajax.nextBestAction, params)
        .then((response: AxiosResponse): void => {
            if (response.data?.data?.body) {
                nbaCustomer.value = Object.assign({}, response.data.data.body);
                nbaCustomer.value!.start_date_short = OneDate.short(nbaCustomer.value!.start_date);
                const date: moment.Moment = moment(nbaCustomer.value!.start_date).add(nbaCustomer.value!.duration, 'days');
                nbaCustomer.value!.due_date = OneDate.short(date.toDate());
            }
        })
        .catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'app-next-best-action', reason);
        });
}

function needToShowPopup(): boolean {
    const popupShowAt = new Date(nbaCustomer.value!?.popup_shown_at);
    if (nbaCustomer.value?.id && isNaN(popupShowAt.getTime())) {
        return true;
    } else {
        const nextPopupShowTime = popupShowAt;
        nextPopupShowTime.setDate(nextPopupShowTime.getDate() + nbaCustomer.value!?.break);
        return Date.now() > nextPopupShowTime.getTime();
    }
}

function forceShowPopup() {
    showPopup();
    updatePopupShownAt();
}

function showPopup() {
    PopupService.getInstance().show(
        new OnePopup()
            .withType()
            .nextBestAction
            .withData(nbaCustomer.value)
    );
    updatePopupShownAt();
    clearInterval(timer);
}

function updatePopupShownAt(): void {
    const params: DynamicDictionary = {
        data: {
            id: nbaCustomer.value?.id,
        }
    };
    request.post(Url.Ajax.nextBestActionUpdatePopupShownAt, params)
        .then((response: AxiosResponse): void => {
            nbaCustomer.value = Object.assign({}, response.data.data.body);
        })
        .catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'app-next-best-action-update-popup-shown-at', reason);
        });
}
</script>

<template>
    <div v-if="componentIsReady && nbaCustomer?.id" class="next-best-action-notification">
        <div class="content-wrapper">
            <div class="nba-container">
                <div class="status-block">
                    <div class="status">
                        {{ translateForType('upgrade_offer', 'nba') }}
                    </div>
                    <div>
                        {{
                            translateForType('btar_one_dashboard_due', 'dashboard') + ' ' + nbaCustomer.due_date
                        }}
                    </div>
                </div>
                <p class="heading">{{ nbaCustomer.nba.heading }}</p>
                <a class="view-offer" @click="showPopup()">
                    {{ translateForType('btar_dashboard_view_offer', 'dashboard') }}
                    <svg class="icon-right" fill="none" height="24" viewBox="0 0 24 24" width="24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18L15 12L9 6" stroke="#007A76" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.next-best-action-notification {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: -40px 0px 40px;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: var(--size-big);
        background-color: #e2f1f3;
        border-radius: 0 0 var(--size-tiny) var(--size-tiny);
        padding: var(--size-small);

        @include respond-above('lp') {
            max-width: 1368px;
        }

        .nba-container {
            display: flex;
            flex-direction: column;
            gap: var(--size-pico);
            font-weight: 600;

            @include respond-above('lp') {
                flex-direction: row;
                align-items: center;
            }

            .status-block {
                display: flex;
                align-items: center;
                flex-direction: row;
                font-size: var(--size-nano);

                @include respond-above('lp') {
                    padding-right: var(--size-small);
                    border-right: 1px solid var(--black-100);
                    margin-right: 10px;
                }
            }

            .status {
                padding: 6px var(--size-tiny);
                color: var(--system-color-success-dark);
                border-radius: var(--size-normal);
                background-color: var(--teal-100);
                margin-right: var(--size-nano);
                font-size: 14px;
                font-weight: 600;
            }

            .heading {
                font-size: var(--size-tiny);
                color: var(--text-color-default);
                font-weight: 600;
            }

            .view-offer {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--system-color-success-dark);
                font-size: 14px;
                cursor: pointer;

                @include respond-above('lp') {
                    margin-left: auto;
                }

                .icon-right {
                    margin-left: var(--size-nano);
                }
            }
        }
    }
}

</style>
