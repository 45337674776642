<script lang="ts">
import Vue, {computed, defineComponent, reactive, ref, Ref, watch} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import Translations from '@/services/translations.service';
import {useTranslate} from '@/Composables/Translate';
import {Auto} from '@/pages/Auto/Composables/Auto';
import AutoService from '@/pages/Auto/Services/AutoService';
import {AutoStorage} from '@/pages/Auto/Composables/AutoStorage';
import AutoStorageService from '@/pages/Auto/Services/AutoStorageService';
import {AutoForms} from '@/pages/Auto/Composables/AutoForms';
import AutoFormsService from '@/pages/Auto/Services/AutoFormsService';
import {useDefine} from '@/Composables/Define';
import {usePrice} from '@/Composables/Price';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import FormField from '@/assets/libraries/form/form-field';
import CssClass from '@/Enums/CssClassEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import PolicyPeriodStrings from '@/Enums/PolicyPeriodStringsEnum';
import PaymentType from '@/Enums/PaymentTypeEnum';
import VueEvent from '@/Classes/VueEventClass';
import PolicyPeriods from '@/Enums/PolicyPeriodsEnum';
import PolicySum from '@/interfaces/policy.sum.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DataLayer from '@/services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import {useNavigate} from '@/Composables/Navigate';
import Url from '@/Enums/UrlEnum';
import {useTransforms} from '@/Composables/Transforms';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {useFormatter} from '@/Composables/Formatter';
import SettingsService from '@/services/settings.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const auto: Auto = AutoService.getInstance();
        const autoStorage: AutoStorage = AutoStorageService.getInstance();
        const forms: AutoForms = AutoFormsService.getInstance();


        const {isSet, validResponse, arrayContains, assocArrayLength} = useDefine();
        const {translate} = useTranslate();
        const {deepClonedObjectWithoutVueReactivity} = useTransforms();
        const {numberToFixedNoRounding} = useFormatter();
        const {formattedCurrency, currencyEuroOrPercentPostfix} = usePrice();
        const stepsSubmitter = useStepsSubmitter();
        const request: AxiosParams = useAxios();


        const CurrentStep: number = 3;
        const NextStep: number = 4;
        const Facility: string = 'auto-policy';
        const noAdditionalRisks: string = 'none';


        const vehicleProduct: Ref<string> = ref('');
        const damageDeductiblesOptions: Ref<InputOption[]> = ref([]);
        const glassDeductiblesOptions: Ref<InputOption[]> = ref([]);
        const currentDeductibleOptions: Ref<string> = ref('');
        const footerFullSum: Ref<LimitedVariant> = ref(0);
        const footerDiscountSum: Ref<LimitedVariant> = ref(0);
        const additionalOptionsPricesIncludesProductPrice: Ref<boolean> = ref(false);
        const reloadMtplSums: Ref<boolean> = ref(false);
        const isAllowedToResetGlassDeductibles: Ref<boolean> = ref(false);
        const recalculationInProgress: Ref<boolean> = ref(false);
        let risksForFetch: UnwrapNestedRefs<DynamicDictionary> = reactive({});


        const productIsBundle: Ref<boolean> = computed(() => {
            return vehicleProduct.value === VehicleProduct.Bundle;
        });

        const hasDeductiblesOptions: Ref<boolean> = computed(() => {
            let hasDeductibleOptions = !isMtplProduct.value;
            if(hasDeductibleOptions && isSet(storageAttributes()?.vehicleProducts?.CASCO?.damageDeductiblePremiums)) {
                let deductiblePremiums = storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums;
                hasDeductibleOptions = Object.keys(deductiblePremiums).length > 1;
            } else {
                hasDeductibleOptions = hasDeductibleOptions && isSet(storageAttributes().vehicleDeductibleOptions);
            }
            return hasDeductibleOptions
        });

        const isStaticDeductibles: Ref<boolean> = computed(() => {
            return hasDeductiblesOptions.value
                && (
                    (isSet(storageAttributes().vehicleDeductibleOptions) && storageAttributes().vehicleDeductibleOptions.damageDeductibleIc.length === 1)
                    ||(
                        isSet(storageAttributes()?.vehicleProducts?.CASCO?.damageDeductiblePremium) &&
                        Object.keys(storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums).length === 1
                    )
                );
        });

        const isStaticDeductiblesLv: Ref<boolean> = computed(() => {
            return hasSpecificDeductiblesLv.value && damageDeductibleLv.value.length === 1;
        });

        const territoryCode: Ref<string> = computed(() => {
            const DefaultLvTerritory: string = 'EU';

            return isSet(btaBase.userStorage.storageData?.attributes?.territoryCode) ?
                btaBase.userStorage.storageData.attributes.territoryCode : DefaultLvTerritory;
        });

        const damageDeductibleLv: Ref<string[]> = computed(() => {
            let result: string[] = [];
            if (hasSpecificDeductiblesLv.value) {
                if (isSet(btaBase.userStorage.storageData.attributes.vehicleDeductibleOptions
                    .damageDeductibleIc[territoryCode.value])) {
                    result = btaBase.userStorage.storageData.attributes.vehicleDeductibleOptions
                        .damageDeductibleIc[territoryCode.value];
                } else if (Array.isArray(btaBase.userStorage.storageData
                    .attributes.vehicleDeductibleOptions.damageDeductibleIc)) {
                    result = btaBase.userStorage.storageData.attributes
                        .vehicleDeductibleOptions.damageDeductibleIc;
                } else if(issetDeductiblePremiums.value){
                    result = Object.keys(storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums);
                }
            }

            return result;
        });

        const hasSpecificDeductiblesLv: Ref<boolean> = computed(() => {
            let result: boolean = false;
            if (SettingsService.getInstance().isReady.value || SettingsService.getInstance().ready) {
                const country: AppCountry = new AppCountry();
                if (country.ready && country.isLV()) {
                    if (issetDeductiblesIc.value && isSet(btaBase.userStorage.storageData.attributes.vehicleDeductibleOptions
                        .damageDeductibleIc[territoryCode.value])) {
                        result = btaBase.userStorage.storageData.attributes.vehicleDeductibleOptions
                            .damageDeductibleIc[territoryCode.value].length > 0;
                    } else if (issetDeductiblePremiums.value){
                        result = Object.keys(storageAttributes().vehicleProducts.CASCO.damageDeductiblePremiums).length > 0;
                    }
                }
            }

            return result;
        });

        const issetDeductiblePremiums: Ref<boolean> = computed(()=>{
            return !isMtplProduct.value &&
                isSet(storageAttributes()?.vehicleProducts?.CASCO?.damageDeductiblePremiums)
        })

        const isCascoProduct: Ref<boolean> = computed(()=>{
            return vehicleProduct.value === VehicleProduct.Casco;
        })
        const isMtplProduct: Ref<boolean> = computed(()=>{
            return vehicleProduct.value === VehicleProduct.Mtpl;
        })

        const issetDeductiblesIc: Ref<boolean> = computed(()=>{
            return !isMtplProduct.value &&
                isSet(btaBase.userStorage.storageData?.attributes?.vehicleDeductibleOptions?.damageDeductibleIc) &&
                Object.keys(btaBase.userStorage.storageData.attributes.vehicleDeductibleOptions
                    .damageDeductibleIc).length > 0
        })

        const pricePaymentPeriodText: Ref<string> = computed(() => {
            const prefix: string = '/';
            let result: string = translate('btar_month').toLowerCase();
            if (!paymentTypeIsMonthly.value) {
                result = new AppCountry().isLV() ?
                    '' : translate('btar_year').toLowerCase();
            }

            return result === '' ? result : prefix + result;
        });

        const vehicleProductLocalized: Ref<string> = computed(() => {
            return vehicleProductIsSelected.value ?
                translate('btar_product_' + String(vehicleProduct.value).toLowerCase()) : '';
        });

        const vehicleProductIsSelected: Ref<boolean> = computed(() => {
            return vehicleProduct.value !== '';
        });

        const isFixedPaymentType: Ref<boolean> = computed(() => {
            return !paymentTypeIsMonthly.value;
        });

        const paymentTypeIsMonthly: Ref<boolean> = computed(() => {
            let paymentType: string;
            if (auto.isSubscription.value) {
                paymentType = 'monthly';
            } else {
                paymentType = btaBase.userStorage.stepStorageData.paymentType ===
                PaymentType.Fixed ? 'fix' : 'monthly';
            }

            return paymentType === 'monthly';
        });

        const footerDiscountSumFormatted: Ref<string> = computed(() => {
            return Number(footerDiscountSum.value).toFixed(2);
        });

        const footerFullSumFormatted: Ref<string> = computed(() => {
            return Number(footerFullSum.value).toFixed(2);
        });

        const showFooterDiscountBlock: Ref<boolean> = computed(() => {
            let show: boolean;
            if (footerFullSum.value && footerDiscountSum.value) {
                show = parseFloat(Number(footerFullSum.value).toFixed(2)) >
                    parseFloat(Number(footerDiscountSum.value).toFixed(2));
            } else {
                show = false;
            }

            return show;
        });

        const footerSaveSumFormatted: Ref<string> = computed(() => {
            return Number(Number(footerFullSum.value) - Number(footerDiscountSum.value)).toFixed(2);
        });

        const showAdditionalOptionsText: Ref<boolean> = computed(() => {
            return auto.additionalOptionsCombined.value !== '';
        });

        const deductiblesAreVisible: Ref<boolean> = computed(() => {
            return isSet(btaBase.userStorage.storageData.attributes.vehicleDeductibles);
        });

        const coveredInfoTextMtplWithRoadAssistance: Ref<string> = computed(() => {
            /* INFO:
                only specific hardcoded info text while MTPL has no items (for now)
            */
            return isSet(auto.coveredPopup.insuredRisksMTPL) &&
            isSet(auto.coveredPopup.insuredRisksMTPL.TE_R_ROAD_ASSISTANCE) ?
                translate('btar_covered_info_block_mtpl_with_road_assistance') : '';
        });


        watch(() => recalculationInProgress.value, () => {
                if (!recalculationInProgress.value) {
                    showAdditionalFooter();
                }
            },
            {
                immediate: true,
            });

        watch(() => auto.mtplDiscountPercent.value, (newValue) => {
                if (newValue === auto.NoDiscountInDatabase) {
                    auto.mtplDiscountPercent.value = 0;
                    auto.fetchMtplDiscountFromJson.value = true;
                }
            },
            {
                immediate: true,
            });

        watch(() => auto.cascoDiscountPercent.value, (newValue) => {
                if (newValue === auto.NoDiscountInDatabase) {
                    auto.cascoDiscountPercent.value = 0;
                    auto.fetchCascoDiscountFromJson.value = true;
                }
            },
            {
                immediate: true,
            });

        watch(() => auto.additionalOptionsCombined.value, (newValue) => {
                const values: string[] = String(newValue).split(',');
                if (values.length > 0 && !(values.length === 1 && values[0] === '')) {
                    values.forEach((option: string) => {
                        auto.additionalOptions.value[option] = true;
                    });
                }
            },
            {
                immediate: true,
            });


        function onUserStorageReady(): void {
            btaBase.userStorage.applyAdditionalOptionsStorage(deepClonedObjectWithoutVueReactivity(btaBase.userStorage.storageData));
            if(isSet(btaBase.userStorage.stepStorageData.cachedRequests)){
                auto.cachedRequests.value = btaBase.userStorage.stepStorageData.cachedRequests;
            }
            buildDeductibles();
        }

        function buildDeductibles(): void {
            if (hasDeductiblesOptions.value) {
                const attributes: DynamicDictionary = storageAttributes();
                buildDamageDeductibleOptions(attributes);
                buildGlassDeductibleOptions(attributes);
            }
        }

        function setupForm(): void {
            btaBase.setStep(CurrentStep);
            forms.form.addField(new FormField('additionalOptionsCombined'));
            forms.form.addField(new FormField('damageDeductibles'));
            if (!hasSpecificDeductiblesLv.value) {
                forms.form.addField(new FormField('glassDeductibles'));
            }
            forms.form.setReady();
            forms.formsAreReady.value = true;
        }

        function onAfterFormRestored(): void {
            recalculationInProgress.value = true;
            vehicleProduct.value = btaBase.userStorage.stepStorageData.vehicleProduct;
            auto.vehicleProduct.value = btaBase.userStorage.stepStorageData.vehicleProduct;
            initApplicationSteps();
            Vue.nextTick((): void => {
                if (mustShowAdditionalRiskStep()) {
                    fetchRiskPrices().then((): void => {
                        PopupService.getInstance().hide();
                        selectDamageDeductible();
                        forms.form.setReady();
                        isAllowedToResetGlassDeductibles.value = true;
                        applyDeductiblesOnAutoProductSums();
                        applyFinalSums();
                    });
                } else {
                    applyFinalSums();
                    submit();
                }
            });
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous, '');
        }

        function applyRisksForFetch(value: DynamicDictionary): void {
            risksForFetch = value;
        }

        function applyReloadMtplSumsStatus(value: boolean): void {
            reloadMtplSums.value = value;
        }

        function isVisibleRisk(riskIc: string, mtplPeriod: string): boolean {
            let result: boolean = false;
            if (vehicleProductIsSelected.value) {
                const risk: any = mappedRisk(riskIc);
                if (isSet(risk.risk_ic)) {
                    if (mtplPeriod !== '') {
                        result = risk.mtpl_period === mtplPeriod;
                    } else {
                        result = true;
                    }
                }
            }

            return result;
        }

        function additionalContainerClasses(product: string): string {
            let result: string;
            if (vehicleProductIsSelected.value) {
                result = vehicleProduct.value === product || productIsBundle.value ? '' : CssClass.Hidden;
            } else {
                result = CssClass.Hidden;
            }

            return result;
        }

        function additionalOptionsItemClasses(vehicleProductId: string, additionalOptionId: string): string {
            const result: string[] = [];
            if (isSet(auto.additionalOptions.value[additionalOptionId]) &&
                auto.additionalOptions.value[additionalOptionId] === true
            ) {
                result.push(CssClass.Active);
            }
            result.push(additionalOptionsProductSpecificVisibilityClass(vehicleProductId, additionalOptionId));

            return result.join(' ');
        }

        function applyDiscount(product: string, value: number): void {
            switch (product) {
                case 'mtpl':
                    auto.mtplDiscountPercent.value = value;
                    break;
                case 'casco':
                    auto.cascoDiscountPercent.value = value;
                    break;
                default:
            }
        }

        function bundleRiskIsVisible(additionalOptionId: string): boolean {
            const risk: DynamicDictionary = mappedRisk(additionalOptionId);

            return isSet(risk.risk_ic) ? risk.bundle === '1' : false;
        }

        function additionalOptionsButtonClasses(riskId: string): string {
            return isSet(auto.additionalOptions.value[riskId]) && auto.additionalOptions.value[riskId] === true ?
                CssClass.Outside : CssClass.Red;
        }

        function additionalOptionsButtonText(riskId: string): string {
            return isSet(auto.additionalOptions.value[riskId]) && auto.additionalOptions.value[riskId] === true ?
                translate('btar_policy_additional_button_remove') :
                translate('btar_policy_additional_button_add');
        }

        function priceOfAdditionalItemIsVisible(vehicleProductId: string, additionalOptionId: string): boolean {
            const renderPriceForBundle: boolean = new AppCountry().isLT() || !productIsBundle.value;
            const parsed: number = parseFloat(priceOfAdditionalItem(vehicleProductId, additionalOptionId));

            return isNaN(parsed) ?
                false : parsed > 0 && renderPriceForBundle;
        }

        function priceOfAdditionalItem(vehicleProductId: string, additionalOptionId: string): string {
            let period: string = policyPeriodString();
            if (new AppCountry().isLT() &&
                productIsBundle.value &&
                vehicleProductId === VehicleProduct.Casco) {
                period = PolicyPeriodStrings.OneYearPayment
            }
            const risks: DynamicDictionary = new AppCountry().isLT() ?
                autoStorage.additionalStorageRisks() :
                autoStorage
                    .storageDataPreCalculatedAdditionalPricesByUserType(vehicleProductId,
                        btaBase.user.isLogged());
            const requestedRisk: DynamicDictionary =
                risks.find((risk: DynamicDictionary) => risk.id === additionalOptionId && risk.period === period);
            const requestedRiskPrice: number = requestedRisk ? requestedRisk.price : 0;
            const productPrice: number = productPriceForSelectedPeriod(vehicleProductId);
            const fixedDigitsCount: number = 2;

            return additionalOptionsPricesIncludesProductPrice.value ?
                numberToFixedNoRounding(requestedRiskPrice - productPrice, fixedDigitsCount) :
                numberToFixedNoRounding(requestedRiskPrice, fixedDigitsCount);
        }

        function productPriceForSelectedPeriod(vehicleProduct: string): number {
            const period: string = policyPeriodString();
            const prices: DynamicDictionary = autoStorage.storageDataPrices(vehicleProduct,damageDeductiblePrice());
            let productPrice: number = 0;
            if (prices) {
                if (auto.isSubscription.value) {
                    productPrice = prices.subscriptionPayment;
                } else {
                    productPrice = isFixedPaymentType.value ?
                        prices.fixedPeriodPayment[period] : prices.monthlyPayment;
                }
            }

            return productPrice;
        }

        function calculatePrices(): void {
            auto.calculatePrices();
            updateFooterSums();
            updateFinalSums();
        }

        function additionalFooterCoveredButton(): void {
            auto.coveredPopup.contentID = vehicleProduct.value;
            prepareCoveredPopup(vehicleProduct.value, true);
            PopupService.getInstance().show(
                new OnePopup().withType().covered
            );
            Vue.nextTick((): void => {
                btaBase.initPopupTooltips();
            });
        }

        function additionalItemButton(event: VueEvent): void {
            if (isSet(auto.additionalOptions.value[event.params.id]) &&
                auto.additionalOptions.value[event.params.id] === true) {
                auto.additionalOptions.value[String(event.params.id)] = null;
                delete auto.additionalOptions.value[String(event.params.id)];
            } else {
                auto.additionalOptions.value[String(event.params.id)] = true;
            }
            if (assocArrayLength(auto.additionalOptions.value) === 0) {
                auto.additionalOptions.value = [];
                if (!new AppCountry().isLT()) {
                    autoStorage.applyAdditionalOptionsSum(null);
                }
            }
            forms.form.field('additionalOptionsCombined').patch(combinedAdditionalOptions());
            applyFinalSums();
        }

        function additionalFooterSubmit(): void {
            submit();
        }

        function additionalRiskOpenerClick(event: VueEvent, id: string): void {
            event.sender.toggleClass(CssClass.Opened);
            $('.description-opener[data-opener="' + id + '"]').toggleClass(CssClass.Visible);
        }

        function bundleProductSum(product: string): string {
            let sum: number;
            switch (product) {
                case 'mtpl':
                    sum = btaBase.user.isLogged() ?
                        auto.productSums.authenticated.mtplBundlePrice :
                        auto.productSums.guest.mtplBundlePrice;
                    break;
                case 'casco':
                    sum = btaBase.user.isLogged() ?
                        auto.productSums.authenticated.cascoBundlePrice :
                        auto.productSums.guest.cascoBundlePrice;
                    break;
                default:
                    sum = 0;
            }

            return formattedCurrency(sum);
        }

        function productSum(product: string): string {
            let sum: number;
            switch (product) {
                case 'mtpl':
                    if (auto.isSubscription.value) {
                        if (btaBase.user.isLogged()) {
                            sum = auto.productSums.authenticated.mtplSubscriptionPrice;
                        } else {
                            sum = auto.productSums.guest.mtplSubscriptionPrice;
                        }
                    } else {
                        if (btaBase.user.isLogged()) {
                            sum = auto.productSums.authenticated.mtplDiscountSum;
                        } else {
                            sum = auto.productSums.guest.mtplDiscountSum;
                        }
                    }
                    break;
                case 'casco':
                    if (auto.isSubscription.value) {
                        if (btaBase.user.isLogged()) {
                            sum = auto.productSums.authenticated.cascoSubscriptionPrice;
                        } else {
                            sum = auto.productSums.guest.cascoSubscriptionPrice;
                        }
                    } else {
                        if (btaBase.user.isLogged()) {
                            sum = auto.productSums.authenticated.cascoDiscountSum;
                        } else {
                            sum = auto.productSums.guest.cascoDiscountSum;
                        }
                    }
                    break;
                default:
                    sum = 0;
            }

            return formattedCurrency(sum);
        }

        function glassDeductiblePrice(): number {
            const price: number = forms.form.field('glassDeductibles').isEmpty() ?
                '0P' : forms.form.field('glassDeductibles').value;

            return hasDeductiblesOptions.value ? price : 0;
        }

        function damageDeductiblePrice(): number {
            const price: number = forms.form.field('damageDeductibles').isEmpty() ?
                0 : parseFloat(forms.form.field('damageDeductibles').value);

            return hasDeductiblesOptions.value ? price : 0;
        }

        function onDamageDeductiblesChange(): void {
            if (!isStaticDeductibles.value &&
                !hasSpecificDeductiblesLv.value &&
                hasDeductiblesOptions.value) {
                const value: string = String(forms.form.field('damageDeductibles').value);
                const transformedPrice: string = currencyEuroOrPercentPostfix(value);
                glassDeductiblesOptions.value[1].value = parseFloat(value);
                glassDeductiblesOptions.value[1].name = transformedPrice;
                if (isAllowedToResetGlassDeductibles.value) {
                    selectFirstGlassDeductible();
                    onGlassDeductiblesChange();
                }
            } else if (hasSpecificDeductiblesLv.value && !isStaticDeductibles.value) {
                applyFinalSums();
            }
        }

        function onGlassDeductiblesChange(): void {
            applyDeductiblesOnAutoProductSums();
            if (hasDeductiblesOptions.value && !isStaticDeductibles.value) {
                applyFinalSums();
            }
        }

        function mappedRisk(riskId: string): DynamicDictionary {
            let result: DynamicDictionary = {};
            auto.vehicleProductAdditionalRisksMapping.value.forEach((risk: DynamicDictionary): void => {
                if (risk.risk_ic === riskId) {
                    if (risk.mtpl_period !== '' && risk.mtpl === '1') {
                        const period: string = transformedMtplPeriod();
                        if (risk.mtpl_period === period) {
                            result = risk;
                        }
                    } else {
                        result = risk;
                    }
                }
            });

            return result;
        }

        function transformedMtplPeriod(): string {
            const selectedPeriod: string = transformedPeriod();

            return selectedPeriod === '12' ? '1YEAR' : selectedPeriod + 'MON';
        }

        function transformedPeriod(): string {
            return isFixedPaymentType.value ?
                btaBase.userStorage.stepStorageData.policyPeriod : PolicyPeriods.OneYearPayment;
        }

        function applyVehicleProductAdditionalRisksMapping(risks: DynamicDictionary[]): void {
            auto.vehicleProductAdditionalRisksMapping.value = risks;
        }

        function additionalOptionsProductSpecificVisibilityClass(
            vehicleProductId: string,
            additionalOptionId: string
        ): string {
            let result: string[] = [];
            if (vehicleProductId === VehicleProduct.Mtpl) {
                result.push(mtplSpecificRiskIsVisible(additionalOptionId) ? '' : CssClass.Hidden);
            }
            if (productIsBundle.value) {
                result.push(bundleSpecificRiskIsVisible(additionalOptionId) ? '' : CssClass.Hidden);
            }
            result = result.filter((v: string, i: number, a: string[]) => a.indexOf(v) === i);

            return result.join(' ');
        }

        function mtplSpecificRiskIsVisible(additionalOptionId: string): boolean {
            /**
             * NOTES: BTA MTPL specific: hardcoded additional options condition
             *   now: additional option is shown if: if current period is found in prices[] && price > 0
             *   future: possibly will be changed to dynamic mode
             */
            let result: boolean = true;
            if (vehicleProduct.value !== VehicleProduct.Casco) {
                const policyPeriod: string = policyPeriodString();
                const mtplAdditionalItemsPrices: DynamicDictionary = autoStorage
                    .storageDataPreCalculatedAdditionalPricesByUserType(VehicleProduct.Mtpl, btaBase.user.isLogged());
                const riskPriceItems: DynamicDictionary[] = mtplAdditionalItemsPrices
                    .filter((item: DynamicDictionary) => item.id === additionalOptionId);
                result = riskPriceItems.length === 0 || riskPriceItems.some((item: DynamicDictionary) => {
                    return (item.period === policyPeriod || paymentTypeIsMonthly.value) && item.price !== 0;
                });
            }

            return result;
        }

        function policyPeriodString(): string {
            return btaBase.userStorage.stepStorageData.policyPeriodString;
        }

        function bundleSpecificRiskIsVisible(additionalOptionId: string): boolean {
            return productIsBundle.value ? bundleRiskIsVisible(additionalOptionId) : true;
        }

        function applyAdditionalOptionsCombined(): void {
            auto.additionalOptionsCombined.value = forms.form.field('additionalOptionsCombined').value;
            Vue.nextTick((): void => {
                applyFinalSums();
            });
        }

        function combinedAdditionalOptions(): string {
            return Object.keys(auto.additionalOptions.value).join(',');
        }

        function applyFinalSums(): void {
            calculatePrices();
            if (needToFetchAdditionalOptions() && !new AppCountry().isLT()) {
                currentDeductibleOptions.value = forms.form.field('additionalOptionsCombined').value;
                PopupService.getInstance().show(new OnePopup().withType().loading);
                fetchSumWithAdditionalOptions().then((): void => {
                    PopupService.getInstance().hide();
                    recalculationInProgress.value = false;
                });
                forms.form.touch().then();
            } else {
                recalculationInProgress.value = false;
            }
        }

        async function fetchSumWithAdditionalOptions(): Promise<void> {
            if (isMtplProduct.value && !reloadMtplSums.value) {
                autoStorage.applyAdditionalOptionsSum(applyMtplAdditionalOptionsPreCalculatedPrices());
                calculatePrices();
                return Promise.resolve();
            }
            const params: DynamicDictionary = prepareFormParams(forms.form);

            await auto.fetchSumWithAdditionalOptions(params, reloadMtplSums.value).then((): void => {
                calculatePrices();
            });
        }

        function needToFetchAdditionalOptions(): boolean {
            return new AppCountry().isLV() && isCascoProduct.value
                ? needToRecalculateLatviaCasco()
                : assocArrayLength(auto.additionalOptions.value) > 0 || needToRecalculateWithDeductibles();
        }

        function needToRecalculateLatviaCasco(): boolean{
            return currentDeductibleOptions.value != forms.form.field('additionalOptionsCombined').value;
        }
        function prepareFormParams(form: UnwrapNestedRefs<Form>): DynamicDictionary {
            const params: DynamicDictionary = {};
            if (form.exists('territory')) {
                params['territory'] = btaBase.userStorage.stepStorageData.territoryCode;
            }
            if (form.exists('youngestDriver')) {
                params['youngestDriverAge'] = btaBase.userStorage.stepStorageData.youngestDriver;
            }
            if (form.exists('drivingExperience')) {
                params['drivingExperience'] = btaBase.userStorage.stepStorageData.drivingExperience;
            }
            if (form.exists('drivingExperienceLessThanThreeYears')) {
                params['experienceLessThanThreeYears'] =
                    btaBase.userStorage.stepStorageData.drivingExperienceLessThanThreeYears ? 'Y' : 'N';
            }
            if (form.exists('youngestDriver')) {
                params['youngestDriver'] = btaBase.userStorage.stepStorageData.youngestDriver;
            }
            if (form.exists('drivingExperience')) {
                params['drivingExperience'] = btaBase.userStorage.stepStorageData.drivingExperience;
            }
            if (needToRecalculateWithDeductibles()) {
                if(!(new AppCountry().isLV() && isCascoProduct.value)) {
                    params['damageDeductibleIc'] = damageDeductiblePrice();
                }
                params['glassDeductibleIc'] = glassDeductiblePrice();
            }

            return params;
        }

        function needToRecalculateWithDeductibles(): boolean {
            return hasDeductiblesOptions.value &&
                !isStaticDeductibles.value &&
                damageDeductiblePrice() !== 0 &&
                (
                    (new AppCountry().isLV() && isCascoProduct.value) ||
                    damageDeductiblePrice() !== storageAttributes().vehicleDeductibles.damageDeductibleIc ||
                    glassDeductiblePrice() !== glassDeductiblesOptions.value[0].value
                );
        }

        function applyDeductiblesOnAutoProductSums(): void {
            auto.applyDeductibles(needToRecalculateWithDeductibles());
        }

        function applyMtplAdditionalOptionsPreCalculatedPrices(): DynamicDictionary {
            /**
             * NOTES: BTA MTPL specific: hardcoded condition
             *   now: only if [MTPL + oneYearPayment]
             *   future: possibly will be changed to dynamic mode
             */
            return auto.applyAdditionalOptionsPrice();
        }

        function updateFooterSums(): void {
            const sumType: PolicySum = btaBase.user.isLogged() ?
                auto.productSums.authenticated : auto.productSums.guest;
            const productType: string = vehicleProduct.value.toLowerCase();
            if (!additionalOptionsPricesIncludesProductPrice.value &&
                vehicleProduct.value.toUpperCase() === VehicleProduct.Bundle) {
                updateAdditionalOptionsMtplPrice(sumType);
            }
            if (auto.isSubscription.value) {
                switch (productType) {
                    case 'mtpl':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.mtplSubscriptionFullPrice :
                            auto.productSums.guest.mtplSubscriptionFullPrice;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.mtplSubscriptionPrice :
                            auto.productSums.guest.mtplSubscriptionPrice;
                        break;
                    case 'casco':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.cascoSubscriptionFullPrice :
                            auto.productSums.guest.cascoSubscriptionFullPrice;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.cascoSubscriptionPrice :
                            auto.productSums.guest.cascoSubscriptionPrice;
                        break;
                    case 'bundle':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.bundleSubscriptionFullPrice :
                            auto.productSums.guest.bundleSubscriptionFullPrice;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.bundleSubscriptionPrice :
                            auto.productSums.guest.bundleSubscriptionPrice;
                        break;
                    default:
                }
            } else {
                switch (productType) {
                    case 'mtpl':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.mtplFullSum :
                            auto.productSums.guest.mtplFullSum;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.mtplDiscountSum :
                            auto.productSums.guest.mtplDiscountSum;
                        break;
                    case 'casco':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.cascoFullSum :
                            auto.productSums.guest.cascoFullSum;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.cascoDiscountSum :
                            auto.productSums.guest.cascoDiscountSum;
                        break;
                    case 'bundle':
                        footerFullSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.bundleFullSum :
                            auto.productSums.guest.bundleFullSum;
                        footerDiscountSum.value = btaBase.user.isLogged() ?
                            auto.productSums.authenticated.bundleDiscountSum :
                            auto.productSums.guest.bundleDiscountSum;
                        break;
                    default:
                }
            }
        }

        function updateAdditionalOptionsMtplPrice(sumType: PolicySum): void {
            const mtplAdditionalOptionsPrice: number
                = auto.mtplAdditionalOptionsPrice(btaBase.user.isLogged());
            sumType.mtplSubscriptionBundlePrice = sumType.mtplSubscriptionBundlePrice + mtplAdditionalOptionsPrice;
            sumType.bundleSubscriptionFullPrice = sumType.bundleSubscriptionFullPrice + mtplAdditionalOptionsPrice;
            sumType.bundleSubscriptionPrice = sumType.bundleSubscriptionPrice + mtplAdditionalOptionsPrice;
            sumType.mtplFullSum = sumType.mtplFullSum + mtplAdditionalOptionsPrice;
            sumType.mtplDiscountSum = sumType.mtplDiscountSum + mtplAdditionalOptionsPrice;
            sumType.bundleFullSum = sumType.bundleFullSum + mtplAdditionalOptionsPrice;
            sumType.bundleDiscountSum = sumType.bundleDiscountSum + mtplAdditionalOptionsPrice;
            sumType.mtplBundlePrice = sumType.mtplBundlePrice + mtplAdditionalOptionsPrice;
        }

        function updateFinalSums(): void {
            auto.productSums.guest.finalFullSum = finalFullSumWithApprovalCase(false);
            auto.productSums.guest.finalDiscountSum = finalDiscountSumWithApprovalCase(false);
            auto.productSums.authenticated.finalFullSum = finalFullSumWithApprovalCase(true);
            auto.productSums.authenticated.finalDiscountSum = finalDiscountSumWithApprovalCase(true);
        }

        function finalFullSumWithApprovalCase(logged: boolean): number {
            const sumType: DynamicDictionary = logged ?
                auto.productSums.authenticated : auto.productSums.guest;
            let result: number;
            switch (vehicleProduct.value.toLowerCase()) {
                case 'mtpl':
                    if (logged) {
                        result = auto.productSums.authenticated.mtplFullSum;
                    } else {
                        result = auto.productSums.guest.mtplFullSum;
                    }
                    break;
                case 'casco':
                    if (logged) {
                        result = auto.productSums.authenticated.cascoFullSum;
                    } else {
                        result = auto.productSums.guest.cascoFullSum;
                    }
                    break;
                default:
                    result = 0;
            }
            if (isBundleWithApprovalCase()) {
                result = sumType.mtplFullSum;
            }

            return result;
        }

        function finalDiscountSumWithApprovalCase(logged: boolean): number {
            let result: LimitedVariant;
            switch (vehicleProduct.value.toLowerCase()) {
                case 'mtpl':
                    if (logged) {
                        result = auto.productSums.authenticated.mtplDiscountSum;
                    } else {
                        result = auto.productSums.guest.mtplDiscountSum;
                    }
                    break;
                case 'casco':
                    if (logged) {
                        result = auto.productSums.authenticated.cascoDiscountSum;
                    } else {
                        result = auto.productSums.guest.cascoDiscountSum;
                    }
                    break;
                default:
                    result = 0;
            }
            if (isBundleWithApprovalCase()) {
                const policyPeriod: string = policyPeriodString();
                const prices: DynamicDictionary =
                    autoStorage.storageDataPricesByUserType(VehicleProduct.Bundle, logged, damageDeductiblePrice());
                if (prices) {
                    result = isFixedPaymentType.value ?
                        prices.mtpl.fixedPeriodPayment[policyPeriod as string] :
                        prices.mtpl.monthlyPayment;
                } else {
                    result = 0;
                }
            }

            return Number(result);
        }

        function isBundleWithApprovalCase(): boolean {
            return productIsBundle.value && isApprovalCaseNecessary();
        }

        function isApprovalCaseNecessary(): boolean {
            const cascoProductFromStorage: DynamicDictionary =
                btaBase.userStorage.storageData.attributes.vehicleProducts.CASCO;
            let isApprovalCaseNecessary: string[] = [];
            if (cascoProductFromStorage.isApprovalCaseNecessary) {
                isApprovalCaseNecessary = [cascoProductFromStorage.isApprovalCaseNecessary];
            }
            if (cascoProductFromStorage.isApprovalCaseNecessaryByPaymentPeriod) {
                isApprovalCaseNecessary =
                    Object.values(cascoProductFromStorage.isApprovalCaseNecessaryByPaymentPeriod);
            }
            return isApprovalCaseNecessary.includes('Y') ||
                isApprovalCaseNecessary.includes('N/A');
        }

        function popupsDamageDeductible(vehicleDeductibles: DynamicDictionary): string {
            let result: string;
            if (hasDeductiblesOptions.value) {
                result = String(damageDeductiblePrice());
            } else {
                result = vehicleDeductibles.modifiedDamageDeductibleIc ?
                    vehicleDeductibles.modifiedDamageDeductibleIc :
                    vehicleDeductibles.damageDeductibleIc;
            }

            return result;
        }

        function popupGlassDeductible(vehicleDeductibles: DynamicDictionary): string {
            return hasDeductiblesOptions.value ?
                String(glassDeductiblePrice()) : vehicleDeductibles.glassDeductibleIc;
        }

        function prepareCoveredPopup(productId: string, allRisks: boolean): void {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;
            auto.coveredPopup.contentID = productId === VehicleProduct.Bundle ?
                VehicleProduct.Casco : productId;
            auto.coveredPopup.default.setValue(auto.coveredPopup.contentID);
            auto.coveredPopup.title = translate('btar_what_covered');
            auto.coveredPopup.descriptionMtpl = translate('btar_what_covered_description_mtpl');
            auto.coveredPopup.descriptionCasco = translate('btar_what_covered_description_casco');
            if (isSet(storage.attributes.vehicleDeductibles)) {
                const vehicleDeductibles: DynamicDictionary = storage.attributes.vehicleDeductibles;
                const damageDeductible: string = popupsDamageDeductible(vehicleDeductibles);
                if (damageDeductible) {
                    damageDeductible !== vehicleDeductibles.totalLossDeductibleIc ?
                        auto.coveredPopup.deductibles.btar_damage_deductible =
                            currencyEuroOrPercentPostfix(damageDeductible) :
                        auto.coveredPopup.deductibles.btar_vehicle_damage_total_loss_deductible =
                            currencyEuroOrPercentPostfix(damageDeductible);
                }
                if (vehicleDeductibles.theftAndTotalLossDeductibleIc) {
                    auto.coveredPopup.deductibles.theft_and_total_loss_deductible =
                        currencyEuroOrPercentPostfix(vehicleDeductibles.theftAndTotalLossDeductibleIc);
                } else {
                    if (vehicleDeductibles.theftDeductibleIc) {
                        auto.coveredPopup.deductibles.btar_theft_deductible =
                            currencyEuroOrPercentPostfix(vehicleDeductibles.theftDeductibleIc);
                    }
                    if (vehicleDeductibles.totalLossDeductibleIc &&
                        vehicleDeductibles.damageDeductibleIc !== vehicleDeductibles.totalLossDeductibleIc
                    ) {
                        auto.coveredPopup.deductibles.btar_total_loss_deductible =
                            currencyEuroOrPercentPostfix(vehicleDeductibles.totalLossDeductibleIc);
                    }
                }
                if (vehicleDeductibles.glassDeductibleIc) {
                    auto.coveredPopup.deductibles.btar_glass_deductible =
                        currencyEuroOrPercentPostfix(popupGlassDeductible(vehicleDeductibles));
                }
                if (vehicleDeductibles.deductibleIc) {
                    auto.coveredPopup.deductibles.btar_deductible =
                        currencyEuroOrPercentPostfix(vehicleDeductibles.deductibleIc);
                }
            }
            let applyFromProducts: string[] = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            auto.coveredPopup.insuredRisksMTPL = {};
            auto.coveredPopup.insuredRisksCASCO = {};
            for (let i: number = 0; i < applyFromProducts.length; i++) {
                auto.appendBasicRisksToCoveredPopup(applyFromProducts[i]);
            }
            if (allRisks) {
                applyFromProducts = applyFromThisProducts();
                for (let i: number = 0; i < applyFromProducts.length; i++) {
                    appendSelectedRisksToCoveredPopup(applyFromProducts[i]);
                }
            }
        }

        function applyFromThisProducts(): string[] {
            return productIsBundle.value ?
                [VehicleProduct.Mtpl, VehicleProduct.Casco] : [vehicleProduct.value];
        }

        function appendSelectedRisksToCoveredPopup(product: string): void {
            for (const o in auto.additionalOptions.value) {
                if (productHasRisk(product, o)) {
                    auto.coveredPopup['insuredRisks' + product][o] = o + '_DESCRIPTION';
                    auto.appendRiskChildren(product, o);
                }
            }
        }

        function productHasRisk(product: string, risk: string): boolean {
            let result: boolean = false;
            const vehicleProduct: DynamicDictionary =
                btaBase.userStorage.storageData.attributes.vehicleProducts[product];
            if (isSet(vehicleProduct.risks) &&
                (isSet(vehicleProduct.risks.isSelected ||
                    isSet(vehicleProduct.risks.notSelected)))
            ) {
                result = arrayContains(vehicleProduct.risks.isSelected, risk)
                    || arrayContains(vehicleProduct.risks.notSelected, risk);
            }

            return result;
        }

        function submit(): void {
            if (!forms.form.isInputLocked()) {
                PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                forms.form.lockInput();
                btaBase.userStorage.saveFormsPromise().then((): void => {
                    pushDataLayer();
                    Vue.nextTick((): void => {
                        submitStep3().then();
                    });
                }).catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    forms.form.unlockInput();
                    btaBase.error.show(ErrorType.Error, 'submit::saveFormsPromise', reason);
                });
            }
        }

        function pushDataLayer(): void {
            const territoryCode: string = btaBase.userStorage.stepStorageData.territoryCode;
            const dataLayerInstance: DataLayer = DataLayer.getInstance()
                .useCheckout()
                .addActionParam('step', CurrentStep)
                .addActionParam('option', DataLayerType.ProductTypeVehicle);
            let products: string[] = [vehicleProduct.value];
            if (productIsBundle.value) {
                products = [VehicleProduct.Mtpl, VehicleProduct.Casco];
            }
            products.forEach((product: string) => {
                const productName: string = String(product).toLowerCase();
                const productPrice: string = productIsBundle.value ?
                    bundleProductSum(productName) :
                    productSum(productName);
                dataLayerInstance
                    .newProduct()
                    .addProductParam('name', DataLayerType.AutoInsurance)
                    .addProductParam('id', DataLayerType.AutoProductFakeId)
                    .addProductParam('price', productPrice)
                    .addProductParam('category', DataLayerType.AutoInsurance)
                    .addProductParam('quantity', 1)
                    .addProductParam('variant', product)
                    .addProductParam('coupon', '')
                    .addProductParam('dimension2',
                        transformedPeriod() + DataLayerType.AutoProductPolicyPeriodPostfix
                    )
                    .addProductParam('dimension8', territoryCode)
                    .addProductParam('dimension10', dataLayerTransformedPaymentCount())
                    .addProductParam('dimension11', productIsBundle.value ?
                        DataLayerType.AutoProductBundle :
                        DataLayerType.AutoProductStandalone
                    );
            });
            dataLayerInstance.buildAndPush();
        }

        function dataLayerTransformedPaymentCount(): string {
            let result: string;
            if (auto.isSubscription.value) {
                result = DataLayerType.AutoProductSubscription;
            } else {
                result = paymentTypeIsMonthly.value ?
                    DataLayerType.AutoProductMonthly : DataLayerType.AutoProductOnce;
            }
            return result;
        }

        async function submitStep3(): Promise<void> {
            if (!needToFetchAdditionalOptions()) {
                // INFO: in case some options have been chosen and then removed,
                // casco bundle price needs to be reset to default
                auto.resetCascoBundlePrice();
            }
            const params: DynamicDictionary = assembleStep3();
            await request.post(Url.Ajax.stepsStore, params).then((value: DynamicDictionary): void => {
                if (validResponse(value) && value.data.data.status === 'OK') {
                    useNavigate().navigate(stepsSubmitter.nextStep());
                } else {
                    PopupService.getInstance().hide();
                    forms.form.unlockInput();
                    btaBase.error.show(ErrorType.Error, 'submitStep3', value);
                }
            }).catch((reason: DynamicDictionary) => {
                PopupService.getInstance().hide();
                forms.form.unlockInput();
                btaBase.error.show(ErrorType.Error, 'submitStep3', reason);
            });
        }

        function assembleStep3(): DynamicDictionary {
            auto.coveredPopup.contentID = vehicleProduct.value;
            prepareCoveredPopup(vehicleProduct.value, true);
            const json: DynamicDictionary = {
                data: deepClonedObjectWithoutVueReactivity(btaBase.userStorage.stepStorageData)
            };
            json.data.coveredPopup = auto.coveredPopup;
            json.data.additionalOptions = additionalOptionsForVehicleProduct();
            json.data.sums = auto.productSums;
            if (hasDeductiblesOptions.value) {
                json.data.variatedDeductibles = {
                    damageDeductibles: String(forms.form.field('damageDeductibles').value),
                    glassDeductibles: String(forms.form.field('glassDeductibles').value),
                };
            }

            return {
                data: {
                    uid: btaBase.userStorage.uUid,
                    json: JSON.stringify(json),
                    facility: Facility,
                    step: NextStep
                }
            };
        }

        function additionalOptionsForVehicleProduct(join: string = ','): string {
            const optionsForSelectedPolicyPlan: string[] = [];
            const explodedOptions: string[] = auto.additionalOptionsCombined.value.split(',');
            explodedOptions.forEach((option: string): void => {
                const risk: DynamicDictionary = mappedRisk(option);
                if (isSet(risk.risk_ic) && risk[vehicleProduct.value.toLowerCase()]) {
                    optionsForSelectedPolicyPlan.push(option);
                }
            });

            return optionsForSelectedPolicyPlan.length > 0 ?
                optionsForSelectedPolicyPlan.join(join) : noAdditionalRisks;
        }

        function selectDamageDeductible(): void {
            if (hasDeductiblesOptions.value && !hasSpecificDeductiblesLv.value) {
                if (forms.form.field('damageDeductibles').isEmpty()) {
                    for (const item of storageAttributes().vehicleDeductibleOptions.damageDeductibleIc) {
                        if (item === storageAttributes().vehicleDeductibles.damageDeductibleIc) {
                            forms.form.field('damageDeductibles').patch(parseFloat(item));
                        }
                    }
                }
            } else if (hasSpecificDeductiblesLv.value) {
                let defaultDeductible: string = autoStorage.defaultDeductibles(territoryCode.value);
                if (new AppCountry().isLV() && defaultDeductible !== '') {
                    forms.form.field('damageDeductibles').patch(parseFloat(defaultDeductible));
                } else {
                    let compareValue: number = btaBase.settings.defaultDeductible() === 0 ?
                        Number.MAX_SAFE_INTEGER : 0;
                    let targetIndex: number = 0;
                    damageDeductibleLv.value.forEach((value: string, index: number): void => {
                        if (btaBase.settings.defaultDeductible() === 0) {
                            if (compareValue > parseFloat(value)) {
                                compareValue = parseFloat(value);
                                targetIndex = index;
                            }
                        } else {
                            if (compareValue < parseFloat(value)) {
                                compareValue = parseFloat(value);
                                targetIndex = index;
                            }
                        }
                    });
                    const item: string = damageDeductibleLv.value[targetIndex];
                    forms.form.field('damageDeductibles').patch(parseFloat(item));
                }
            }
            onDamageDeductiblesChange();
            if (isStaticDeductibles.value) {
                selectFirstGlassDeductible();
            }
        }

        function selectFirstGlassDeductible(): void {
            if (!hasSpecificDeductiblesLv.value) {
                forms.form.field('glassDeductibles').patch(
                    storageAttributes().vehicleDeductibleOptions.glassDeductibleIc
                );
            }
        }

        function buildDamageDeductibleOptions(attributes: DynamicDictionary): void {
            const vehicleDeductibleOptions: DynamicDictionary = hasSpecificDeductiblesLv.value ?
                damageDeductibleLv.value : attributes.vehicleDeductibleOptions.damageDeductibleIc;
            vehicleDeductibleOptions.forEach((price: string) => {
                const transformedPrice: string = currencyEuroOrPercentPostfix(price);
                damageDeductiblesOptions.value.push((new InputOptionBuilder())
                    .setValue(parseFloat(price))
                    .setName(transformedPrice)
                    .build());
            });
        }

        function buildGlassDeductibleOptions(attributes: DynamicDictionary): void {
            addGlassDeductible(attributes.vehicleDeductibleOptions.glassDeductibleIc);
            if (attributes.vehicleDeductibleOptions.damageDeductibleIc.length > 1) {
                addGlassDeductible(attributes.vehicleDeductibleOptions.damageDeductibleIc[0]);
            }
        }

        function addGlassDeductible(price: string): void {
            const transformedPrice: string = currencyEuroOrPercentPostfix(price);
            const value: string | number = transformedPrice.includes('%') ?
                parseFloat(price) + 'P' : parseFloat(price);
            glassDeductiblesOptions.value.push((new InputOptionBuilder)
                .setValue(value)
                .setName(transformedPrice)
                .build());
        }

        function initApplicationSteps(): void {
            const titleElement: JQuery = $('div.application-step-subtitle0').first();
            if (titleElement.length > 0) {
                const resultText: string = applicationFirstStepDynamicTitle();
                if (resultText.length > 0) {
                    titleElement.text(resultText);
                }
            }
        }

        function applicationFirstStepDynamicTitle(): string {
            const attributes: DynamicDictionary = storageAttributes();
            let title: string = '';
            if (isSet(attributes.vehicleMake)) {
                title += attributes.vehicleMake;
            }
            if (isSet(attributes.modelModification)) {
                title += ' ' + attributes.modelModification;
            }
            if (isSet(attributes.technicalCertificateNumber)) {
                title += ' / ' + attributes.modelModification;
            }

            return title;
        }

        function storageAttributes(): DynamicDictionary {
            const storage: DynamicDictionary = btaBase.userStorage.storageData;

            return isSet(storage.attributes) ? storage.attributes : {};
        }

        function mustShowAdditionalRiskStep(): boolean {
            const availableRisksCount: number = $('.additional-list')
                .not('.hidden')
                .children('.additional-item')
                .not('.hidden').length;

            return availableRisksCount > 0 || hasDeductiblesOptions.value;
        }

        function showAdditionalFooter(): void {
            $('.additional-footer').fadeIn();
        }

        function updateContentID(): void {
            auto.coveredPopup.contentID = auto.coveredPopup.default.value;
        }

        function fetchRiskPrices(): Promise<void> {
            return new Promise(resolve => {
                if (new AppCountry().isLT()) {
                    auto.fetchRiskPrices(risksForFetch).then((): void => {
                        calculatePrices();
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        }

        return {
            ...btaBase,
            ...forms,
            ...auto,
            ...{
                CurrentStep,
                Facility,
                productIsBundle,
                hasDeductiblesOptions,
                isStaticDeductibles,
                isStaticDeductiblesLv,
                territoryCode,
                hasSpecificDeductiblesLv,
                pricePaymentPeriodText,
                vehicleProductLocalized,
                vehicleProductIsSelected,
                isFixedPaymentType,
                footerDiscountSumFormatted,
                footerFullSumFormatted,
                showFooterDiscountBlock,
                footerSaveSumFormatted,
                showAdditionalOptionsText,
                deductiblesAreVisible,
                coveredInfoTextMtplWithRoadAssistance,
                damageDeductiblesOptions,
                glassDeductiblesOptions,
                updateContentID,
                assocArrayLength,
                onUserStorageReady,
                applyStepUrls,
                setupForm,
                onAfterFormRestored,
                applyRisksForFetch,
                applyReloadMtplSumsStatus,
                storageAttributes,
                isVisibleRisk,
                additionalContainerClasses,
                additionalOptionsItemClasses,
                applyDiscount,
                bundleRiskIsVisible,
                additionalOptionsButtonClasses,
                additionalOptionsButtonText,
                priceOfAdditionalItemIsVisible,
                priceOfAdditionalItem,
                productPriceForSelectedPeriod,
                calculatePrices,
                additionalFooterCoveredButton,
                additionalItemButton,
                additionalFooterSubmit,
                additionalRiskOpenerClick,
                bundleProductSum,
                productSum,
                glassDeductiblePrice,
                damageDeductiblePrice,
                onDamageDeductiblesChange,
                onGlassDeductiblesChange,
                mappedRisk,
                applyVehicleProductAdditionalRisksMapping,
                mtplSpecificRiskIsVisible,
                popupsDamageDeductible,
                popupGlassDeductible,
                applyAdditionalOptionsCombined,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.CurrentStepAuto = this.CurrentStep;
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady.subscribe((): void => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onAfterFormRestoredSubscription =
            this.userStorage.onFormStorageDataIsReady.subscribe(() => {
                this.onAfterFormRestored();
                onAfterFormRestoredSubscription.unsubscribe();
            });
        Translations.getInstance().onExternalDataIsReady.subscribe((): void => {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
        });
        this.setupForm();
    }
});
</script>
