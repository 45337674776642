<script setup lang="ts">
    import {computed, onBeforeUnmount, onMounted, ref, Ref, watch} from 'vue';
    import {useDefine} from '@/Composables/Define';

    const props = defineProps({
        active: {type: Boolean, default: false},
        callback: {type: Function, default: undefined},
        targetSelector: {type: String, default: null},
        title: {type: String, default: ''},
    });
    const {isSet} = useDefine();
    const target: Ref<HTMLElement | null> = ref(null);
    const wrapper: Ref<HTMLElement | null> = ref(null);
    const button: Ref<HTMLElement | null> = ref(null);
    const isActive: Ref<boolean> = computed((): boolean => {
        return props.active && !!props.callback;
    });
    const targetElementTop: Ref<number | null> = computed((): number | null => {
        const overlayButton: HTMLElement = button.value!;
        const componentRootElement: HTMLElement | null = overlayButton.nextSibling
            ? overlayButton.nextSibling as HTMLElement
            : null;
        return target.value !== null ? target.value!.offsetTop + (
            componentRootElement !== null ? componentRootElement.offsetTop : 0
        ) : null;
    });
    let style: string = '';
    const observer: Ref<ResizeObserver | null> = ref(null);


    watch(() => isActive.value, async () => {
        setTabIndexForTargetInteractiveElements();
    }, {deep: true, immediate: true});

    onMounted((): void => {
        target.value = wrapper.value!.querySelector(props.targetSelector);
        setTabIndexForTargetInteractiveElements();
        initObserver();
        setDimensionsAndPosition();
    });

    onBeforeUnmount((): void => {
        if (isSet(observer.value)) {
            observer.value!.disconnect();
        }
    });

    function initObserver(): void {
        if (isSet(target.value) && isSet(button.value)) {
            observer.value = new window.ResizeObserver((entries: ResizeObserverEntry[]) => {
                if (entries.length > 0) {
                    setDimensionsAndPosition();
                }
            });
            observer.value.observe(target.value!);
        }
    }

    function setDimensionsAndPosition(): void {
        if (isSet(target.value) && isSet(button.value)) {
            button.value!.style.height = `${target.value!.offsetHeight}px`;
            button.value!.style.left = `${target.value!.offsetLeft}px`;
            button.value!.style.top = targetElementTop.value !== null
                ? `${targetElementTop.value}px` : '';
            button.value!.style.width = `${target.value!.offsetWidth}px`;
        }
    }

    function setTabIndexForTargetInteractiveElements(): void {
        if (isSet(target.value)) {
            Array.from(target.value!.querySelectorAll('a, button')).forEach((element: Element) => {
                (element as HTMLElement).tabIndex = isActive.value ? -1 : 0;
            });
        }
    }
</script>
<template>
    <div class="overlay-button-wrapper" ref="wrapper">
        <button class="overlay-button"
                v-if="isActive"
                ref="button"
                :aria-label="title"
                @click="callback"></button>
        <slot></slot>
    </div>
</template>
<style lang="scss" scoped>
.overlay-button-wrapper {
    position: relative;

    > button {
        position: absolute;
        z-index: 2;

        &:active {
            + .input {
                .buttons,
                .wrapper {
                    button:not(.active) {
                        @include button-active;
                    }
                }
            }
        }

        &:focus,
        &:hover {
            + .input {
                input {
                    @include input-hover;
                }

                button:not(.active):not([disabled]) {
                    @include button-hover;

                    &.outside {
                        &::before {
                            @include button-outside-hover-before;
                        }
                    }
                }
            }
        }
    }

    ::v-deep .input-number,
    ::v-deep .input-select {
        .label {
            margin-top: 10px;
        }
    }
}
</style>
