import DataLayer from '@/services/data.layer.service';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import {useDefine} from '@/Composables/Define';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import Vue, {computed, reactive, Ref, ref} from 'vue';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Popup from '@/services/popup.service';
import Error from '@/services/error.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import User from '@/services/user.service';
import moment from 'moment/moment';
import SettingsService from '@/services/settings.service';
import Captcha from '@/services/captcha.service';
import Url from '@/Enums/UrlEnum';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useScroll } from '@/Composables/Scroll';

const {isSet, validResponse} = useDefine();
const {translate, translateForType} = useTranslate();
const request: AxiosParams = useAxios();

export const useClaimSubmission = (): ClaimSubmissionParams => {

    const error: Error = Error.getInstance();
    const popup: Popup = Popup.getInstance();
    const user: User = User.getInstance();
    const settingsService = SettingsService.getInstance();


    const userForm: UnwrapNestedRefs<Form> = reactive(new Form());
    const claimForm: UnwrapNestedRefs<Form> = reactive(new Form());
    const isUploadRestricted: Ref<boolean> = ref(false);
    const filesRequired: Ref<boolean> = ref(true);
    const currentStep: Ref<number> = ref(1);


    const TwoDays: number = 2;
    const minDate: Date = moment().add(-TwoDays, 'year').toDate();


    const filesUploader: Ref<HTMLDivElement | null> = ref(null);


    const pushDataLayer = (formType: string, isSuccessfulSubmit: boolean = false): void => {
        DataLayer.getInstance()
            .addRootParam(
                'event',
                isSuccessfulSubmit
                    ? DataLayerType.ClaimSuccess
                    : String(DataLayerType.ClaimStep).split('@').join(String(currentStep.value))
            )
            .addRootParam('form', formType)
            .buildAndPush();
    }

    const getPhoneCountry = (): string => {
        let countryCode: string;
        if (isSet(userForm.field('phone').value.country)) {
            countryCode = userForm.field('phone').value.country;
        } else if (appCountry().iso === 'EE') {
            countryCode = '372';
        } else if (appCountry().iso === 'LT') {
            countryCode = '370';
        } else if (appCountry().iso === 'LV') {
            countryCode = '371';
        } else {
            countryCode = '';
        }

        return countryCode;
    }

    const showHealthClaimFailure = (message: string = ''): void => {
        const messageToShow: string = message !== ''
            ? message
            : 'one_health_claim_failure';
        error.show(ErrorType.Error, 'submitClaim', messageToShow);
    }

    const showFailure = (reason: DynamicDictionary): void => {
        error.show(ErrorType.Error, 'submitClaim', reason);
    }

    const showSuccess = (): void => {
        PopupService.getInstance().show(
            new OnePopup()
                .withType()
                .claimSuccess
                .withTitle(useTranslate().translate('btar_success'))
                .withDescription(useTranslate().translate('btar_claim_submitted'))
        );
    }

    const scrollToInvalidField = (): void => {
        const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
        if (invalidElements.length > 0) {
            window.scroll({top: invalidElements[0].offsetTop, behavior: 'smooth'});
        }
    }

    const patchUserFields = (): void => {
        if (user.isLogged()) {
            userForm.field('firstName').patch(user.current.firstname);
            userForm.field('lastName').patch(user.current.lastname);
            userForm.field('identityNumber').patch(user.current.personCode);
            userForm.field('email').patch(user.current.email);
            userForm.field('phone').patch({
                country: user.current.phoneCode,
                phone: user.current.phone
            });
        }
    }

    const steps = (): string[] => {
        return [
            translate('btar_policy_holder_data'),
            translate('btar_details_and_photos'),
        ];
    }

    const selectStep = (stepNumber: number): void => {
        userForm.touch()
            .then(() => {
                if (stepNumber === TwoDays && !userForm.isValid()) {
                    scrollToInvalidField();
                } else {
                    currentStep.value = stepNumber;
                }
            })
            .catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, 'ClaimSubmission::selectStep(' + stepNumber + ')', reason, true);
            });
    }

    const appCountry = (): { id: string, iso: string } => {
        return {
            id: settingsService!.value('USER_ADDRESS_COUNTRY').toString(),
            iso: settingsService!.value('LOCALE_ISO').toString()
        };
    }

    const submit = (params: Record<string, unknown>): void => {
        const fileSubmitInProgress: boolean = (filesUploader as DynamicDictionary).uploadInProgress;
        claimForm.touch().then();
        const captcha: Captcha = Captcha.getInstance();
        if (!userForm.isValid() || !claimForm.isValid() || fileSubmitInProgress ||
            (filesRequired.value && (filesUploader as DynamicDictionary).filesNotUploaded)
        ) {
            if (fileSubmitInProgress) {
                popup.applyErrorTitle(translateForType('file_upload_in_progress_title', 'error'));
                popup.applyErrorDetails(
                    translateForType('file_upload_in_progress_description', 'error'));
                Vue.nextTick((): void => {
                    PopupService.getInstance().show(new OnePopup().withType().error);
                });
            } else {
                scrollToInvalidField();
            }
            captcha.resetCaptcha();

            return;
        }
        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
        request.post(Url.Ajax.submitClaim, params).then((value: DynamicDictionary): void => {
            PopupService.getInstance().hide();
            if (validResponse(value) && value.data.data.status === 'OK') {
                pushDataLayer(Object(params).claimType, true);
                claimForm.clear();
                selectStep(1);
                useScroll().scrollToView('.claims-submission-form');
                showSuccess();
            } else {
                captcha.resetCaptcha();
                throw value;
            }
        }).catch((reason: DynamicDictionary): void => {
            captcha.resetCaptcha();
            showFailure(reason);
        });
    }

    const isUploaded: Ref<boolean> = computed(() => {
        return filesUploader.value ?
            !(filesUploader.value as DynamicDictionary).filesNotUploaded &&
            (filesUploader.value as DynamicDictionary).uploadsSuccessful :
            false;
    });

    const filesCount: Ref<number> = computed(() => {
        return filesUploader.value ?
            (filesUploader.value as DynamicDictionary).filesCount : 0;
    });


    return {
        isUploadRestricted,
        filesRequired,
        userForm,
        claimForm,
        currentStep,
        minDate,
        filesUploader,
        isUploaded,
        filesCount,
        pushDataLayer,
        getPhoneCountry,
        showHealthClaimFailure,
        showFailure,
        showSuccess,
        scrollToInvalidField,
        patchUserFields,
        steps,
        selectStep,
        submit,
    }
}

export interface ClaimSubmissionParams {
    userForm: UnwrapNestedRefs<Form>;
    claimForm: UnwrapNestedRefs<Form>;
    currentStep: Ref<number>;
    isUploadRestricted: Ref<boolean>;
    filesRequired: Ref<boolean>;
    pushDataLayer: (formType: string, isSuccessfulSubmit: boolean) => void;
    getPhoneCountry: () => string;
    showHealthClaimFailure: (message: string) => void;
    showFailure: (reason: DynamicDictionary) => void;
    showSuccess: () => void;
    scrollToInvalidField: () => void;
    patchUserFields: () => void;
    steps: () => void;
    selectStep: (stepNumber: number) => void;
    submit: (params: Record<string, unknown>) => void;
    minDate: Date;
    filesUploader: Ref<HTMLDivElement | null>;
    isUploaded: Ref<boolean>;
    filesCount: Ref<number>;
}
