<script lang="ts">
import Vue, {defineComponent, reactive, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {VueApp} from '@/Types/VueAppType';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import SrsReport from '@/pages/OneDashboard/SrsStatement/Interfaces/SrsReportInterface';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {Subscription} from 'rxjs';
import {OneDashboardInterface, useOneDashboard} from '@/pages/OneDashboard/Composables/OneDashboard';
import Url from '@/Enums/UrlEnum';
import {useTranslate} from '@/Composables/Translate';
import {useAxios} from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import PopupService from '@/services/custom.popup.service';
import {AxiosResponse} from 'axios';
import FormField from '@/assets/libraries/form/form-field';
import PopupType from '@/Enums/PopupTypeEnum';
import moment from 'moment/moment';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import OnePopup from '@/assets/libraries/popups/one.popup';
import Popup from '@/services/popup.service';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const getInTouchModule: VueApp = new Vue(GetInTouchModule);

        const oneDashboard: OneDashboardInterface = useOneDashboard();
        const {translate, language} = useTranslate();

        const CurrentStep: number = 1;
        const Facility: string = 'my-facility';

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const previousYearsCount: Ref<number> = ref(3);
        const report: Ref<SrsReport> = ref(new class implements SrsReport {
            public year: string = '';
            public status: boolean = false;
            public error: boolean = false;
        });
        const yearOptions: Ref<InputOption[]> = ref([]);


        function setupForm(): void {
            form.addField(new FormField('name', btaBase.user.current.name));
            form.addField(
                new FormField('personalCode', btaBase.user.current.personCode)
            );
            form.addField(new FormField('year', ''));
            form.setReady();
            formIsReady.value = true;
        }

        function setYear(value: string): void {
            report.value.year = value;
            checkReport();
        }

        function showMark(): void {
            yearOptions.value.forEach(
                (yearOption: InputOption): void => {
                    if (yearOption.value === report.value.year && !yearOption.disabled) {
                        yearOption.disabled = true;
                        yearOption.name = yearOption.name + ' !';
                    }
                }
            );
            hideLoadingModal();
        }

        function submit(): void {
            if (report.value.status) {
                btaBase.openUrlInNewTab(
                    language() + Url.SrsStatement.submit + '/' + report.value.year
                );
            }
        }

        function submitButtonVisible(): boolean {
            return !report.value.error && report.value.status;
        }

        function checkReport(): void {
            if (report.value.year !== '') {
                showLoadingModal();
                const data: SrsReport = JSON.parse(JSON.stringify({report: report.value}));
                useAxios().post(Url.SrsStatement.checkReport, data)
                    .then(processCheckReportResponse)
                    .catch((reason: DynamicDictionary): void => {
                        btaBase.error.show(ErrorType.Error, 'checkReport::post', reason);
                    });
            } else {
                btaBase.error.show(ErrorType.Error, 'checkReport', translate('error_missing_data'));
            }
        }

        function hideLoadingModal(): void {
            PopupService.getInstance().hide();
        }

        function processCheckReportResponse(response: AxiosResponse): void {
            if (typeof response.data.error !== 'undefined') {
                report.value.error = true;
                showMark();
                showNoticeModal();
            } else if (typeof response.data.status !== 'undefined' &&
                response.data.status === 'OK'
            ) {
                report.value.error = false;
                report.value.status = true;
                hideLoadingModal();
            }
        }

        function showLoadingModal(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
        }

        function showNoticeModal(): void {
            const popup: Popup = Popup.getInstance();
            popup.applyTextDescription(useTranslate().translate('vid_report_not_found'))
            PopupService.getInstance().show(new OnePopup().withType().info);
        }

        function yearInputOptions(): InputOption[] {
            for (let yearsBack: number = 1; yearsBack <= previousYearsCount.value; yearsBack++) {
                const year: string = moment().subtract(yearsBack, 'years').format('YYYY');
                yearOptions.value.unshift(
                    new InputOptionBuilder()
                        .setName(year)
                        .setValue(year)
                        .build()
                );
            }

            return yearOptions.value;
        }


        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                getInTouchModule,
                form,
                formIsReady,
                oneDashboard,
                setupForm,
                setYear,
                showMark,
                submit,
                submitButtonVisible,
                checkReport,
                yearInputOptions,
                yearOptions,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        const externalDataSubscription: Subscription = this.onExternalDataIsReady.subscribe((): void => {
            this.setupForm();
            this.yearInputOptions();
            this.oneDashboard.buildNavigationBelt();

            this.setOffersCount();
            externalDataSubscription.unsubscribe();
        });
    }
});
</script>

















