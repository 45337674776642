<script setup lang="ts">
import {computed, ref, onMounted, Ref} from 'vue';
import PaginatorPage from '@/assets/libraries/paginator/paginator-page';
import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import {useTranslate} from '@/Composables/Translate';

const props = defineProps({
    component: {type: String, default: ''},
    items: {type: Array, default: () => []},
    label: {type: String, default: ''},
    ariaLabel: {type: String, default: ''},
    showCount: {type: Number, default: 3},
    loadMoreCount: {type: Number, default: 3},
    totalCount: {type: Number, default: 3},
    externalShowButton: {type: Boolean, default: true},
});

const emit = defineEmits(['init', 'load-more']);

const {translate} = useTranslate();
const page: Ref<number> = ref(1);
const componentRefs: Ref<Vue[]> = ref([]);

const itemsToShow: Ref<any[]> = computed(() => {
    return props.items.filter(item => !!item).slice(0, showingItemsCount.value);
});

const showLoading: Ref<boolean> = computed((): boolean => {
    return showingItemsCount.value > itemsToShow.value.length && itemsToShow.value.length < props.totalCount
});

const showItemsCount: Ref<number> = computed((): number => {
    return props.showCount > props.totalCount ? props.totalCount : props.showCount;
});

const showingItemsCount: Ref<number> = computed((): number => {
    return showItemsCount.value + (props.loadMoreCount * (page.value - 1));
});

const showLoadMore: Ref<boolean> = computed((): boolean => {
    return showingItemsCount.value < props.totalCount && !showLoading.value;
});

const componentAriaLabel = computed(() => {
    const firstComponentAriaLabel: string | undefined = componentRefs.value.length > 0
        ? componentRefs.value[0].$props.ariaLabel
        : undefined;
    return firstComponentAriaLabel !== undefined
        ? props.ariaLabel || props.label
        : null;
});

onMounted((): void => {
    emit('init', currentPage());
});

function showMore(): void {
    page.value++;
    if (showingItemsCount.value > props.items?.length) {
        emit('load-more', currentPage());
    }
}

function currentPage(): PaginatorPage {
    return new PaginatorPage(page.value, props.loadMoreCount, showItemsCount.value);
}
</script>

<template>
    <div v-if="totalCount > 0" class="load-more-list">
        <div v-if="label" class="label informative"><p>{{ label }}</p></div>
        <div v-if="component" class="wrapper list">
            <component ref="componentRefs"
                       v-for="(item, index) in itemsToShow"
                       v-bind="$attrs"
                       :item="item"
                       :aria-label="componentAriaLabel ? `${componentAriaLabel} ${index + 1}`: null"
                       :key="index"
                       :is="component"
                       :external-show-button="externalShowButton">
            </component>
        </div>
        <app-content-loader v-if="showLoading"></app-content-loader>
        <div class="buttons-container">
            <button class="button outside" v-if="showLoadMore" @click="showMore">
                {{ translate('hidden_list_show_more') }}
                <span class="icon">
                    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path
                        d="M7 1.5L4 4.5L1 1.5" stroke="#9297A0" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round"/>
                    </svg>
                </span>
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.load-more-list {
    width: 100%;

    > .label {
        color: var(--text-color-default);
        font-size: var(--font-size-small);

        &:not(:empty) {
            margin-bottom: var(--size-normal);
        }
    }

    > .wrapper {
        width: 100%;

        &.list {
            display: flex;
            flex-direction: column;

            > *:not(:last-child) {
                margin-bottom: var(--size-nano);

                @include respond-above('sm') {
                    margin-bottom: var(--size-femto);
                }
            }
        }
    }

    > .buttons-container {
        display: flex;
        flex-direction: row;
        margin-top: var(--size-normal);
        margin-bottom: 0;

        &:empty {
            display: none;
        }

        > .button {
            .icon {
                padding-left: 10px;
            }
        }
    }
}
</style>
