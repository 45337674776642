<script setup lang="ts">
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import { UnwrapNestedRefs } from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import Vue, { computed, onMounted, reactive, ref, Ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import Value from '@/assets/libraries/form/value';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import OneDate from '@/assets/libraries/Date/OneDate';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import AdditionalOptionsMap from '@/Apps/PayByLink/Classes/AdditionalOptionsMap';
import { useNumbers } from '@/Composables/Numbers';
import { usePrice } from '@/Composables/Price';
import { useComparisons } from '@/Composables/Comparisons';
import { useSorting } from '@/Composables/Sorting';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import Url from '@/Enums/UrlEnum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import CardDetails from '@/Components/CreditCardDetails/CardDetails';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useNavigate } from '@/Composables/Navigate';
import Error from '@/services/error.service';
import VueRouter from 'vue-router';
import { useRouter } from 'vue-router/composables';
import { useScroll } from '@/Composables/Scroll';
import RequestService from '@/services/request.service';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import Validation from '@/services/validation.service';
import { useDate } from '@/Composables/Date';

const {translate, translateForType} = useTranslate();
const {sparse} = usePrice();
const {validResponse} = useDefine();
const router: VueRouter = useRouter();

const payByLinkService: PayByLinkService = PayByLinkService.getInstance();

const TranslationType: string = 'mtpl_renewal';
const Facility: string = 'pay-by-link-pay';


const form: UnwrapNestedRefs<Form> = reactive(new Form());
const bankLinkIdError: Ref<boolean> = ref(false);
const bankLinkInfo: Ref<string> = ref('');
const bankLinkId: Ref<number> = ref(0);
const viewIsReady: Ref<boolean> = ref(false);
const cardDetailsPbl: Ref<HTMLDivElement | null> = ref(null);


const payByCard: Ref<boolean> = computed((): boolean => {
    return payByLinkService.payByLink.value.useCard;
});

const bankIsSelected: Ref<boolean> = computed(() => {
    return bankLinkId.value !== 0;
});

onMounted(() => {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    fetchOffer().then((): void => {
        viewIsReady.value = true;
        setupForm();
        restoreValues();
        useScroll().scrollToTop();
        PopupService.getInstance().hide();
    });
});

defineExpose({
    cardDetailsPbl,
    bankIsSelected,
});

async function fetchOffer(): Promise<void> {
    return RequestService.getInstance().get({
            uri: Url.Ajax.mtplRenewalFetchOffer,
            content: {
                hash: payByLinkService.payByLink.value.id,
            },
        }).then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                TransferStateService.getInstance().set('mtplOffer', response.data.data.body.offer);
                payByLinkService.init();
            }
        });
}

function setupForm(): void {
    form.addField(new FormField('agree-terms', '', 'checkboxChecked'));
    form.addField(new FormField('marketing-consents'));
    form.addField(new FormField('card-details'));
    form.addField(new FormField('holder-email', holderEmail(), 'required'));
    form.addField(new FormField('holder-full-name', payByLinkService.payByLink.value.holderName));
    form.setReady();
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = payByLinkService.fields;
    const fieldsMap: DynamicDictionary = {
        agreeTerms: 'agree-terms',
        marketingConsents: 'marketing-consents',
        holderEmail: 'holder-email',
    };

    Object.keys(fieldsMap).forEach((key: string): void => {
        if (useDefine().isSet(storedValues[key]) && new Value(storedValues[key]).isNotEmpty()) {
            form.field(fieldsMap[key]).setValue(storedValues[key]);
        }
    });
}

function storeValues(): void {
    const existingValues: DynamicDictionary = JSON.parse(JSON.stringify(payByLinkService.fields));
    Object.assign(payByLinkService.fields, {
        ...existingValues,
        agreeTerms: form.field('agree-terms').value,
        marketingConsents: form.field('marketing-consents').value,
        holderEmail: form.field('holder-email').value,
    });
}

function holderEmail(): string {
    return Validation.isValidEmail(payByLinkService.payByLink.value.holderEmail)
        ? payByLinkService.payByLink.value.holderEmail : '';
}

function offerPremium(): number {
    const periodIc: string = payByLinkService.fields.offerPeriod || offerPeriodIc();

    return payByLinkService.mtplOffer.value ?
        payByLinkService.mtplOffer.value.prices[periodIc].premium : payByLinkService.payByLink.value.paymentAmount;
}

function riskPremium(ic: string): number {
    const periodIc: string = payByLinkService.fields.offerPeriod || offerPeriodIc();
    const riskKey: string = new AdditionalOptionsMap().keyByIc(ic) + 'Premium';

    return payByLinkService.mtplOffer.value.prices[periodIc][riskKey];
}

function totalPremium(): number {
    return useNumbers().arraySum(
        [
            offerPremium(),
            useNumbers().arraySum(includedRiskIcs().map((ic: string): number => riskPremium(ic))),
        ]
    );
}

function includedRiskIcs(): string[] {
    return offerHasChanges()
        ? Object.keys(currentOffer().risks)
            .filter((key: string): boolean => currentOffer().risks[key])
        : payByLinkService.payByLink.value.offerRisks
            .filter((risk: OfferRisk): boolean => risk.included)
            .map((risk: OfferRisk): string => risk.ic);
}

function offerPeriodLabel(): string {
    const map: AgreementPeriodMap = new AgreementPeriodMap();
    const periodIc: string = payByLinkService.fields.offerPeriod || offerPeriodIc();
    const months: number = map.monthsByIc(periodIc);
    const monthsLabel: string = map.monthsLabelByIc(periodIc);
    const offerPeriod: string[] = payByLinkService.payByLink.value.insurancePeriod.split(' - ');
    const startDate: Date = new MomentBuilder().withInitialDate(offerPeriod[0]).build()
        .toDate();
    const endDate = new MomentBuilder().withInitialDate(startDate).build()
        .add(months, 'months')
        .subtract(1, 'day')
        .toDate();

    return [[String(months), monthsLabel]
        .join(' '), [OneDate.short(startDate), OneDate.short(endDate)].join(' - ')]
        .join(' / ');
}

function offerPeriodIc(): string {
    const offerPeriod: string[] = payByLinkService.payByLink.value.insurancePeriod.split(' - ');

    return new AgreementPeriodMap().icByMonths(useDate().monthsDiff(offerPeriod[0], offerPeriod[1]));
}

function payByLinkOffer(): DynamicDictionary {
    const tempValue: DynamicDictionary = {};
    payByLinkService.payByLink.value.offerRisks
        .forEach((risk: OfferRisk): void => {
            tempValue[risk.ic] = risk.included;
        });
    const sortedRisks: DynamicDictionary = useSorting().sortedObject(tempValue);

    return {
        periodIc: offerPeriodIc(),
        risks: sortedRisks,
    };
}

function currentOffer(): {periodIc: string, risks: DynamicDictionary} {
    const sortedRisks: DynamicDictionary = useSorting()
        .sortedObject(payByLinkService.fields.offerOptions[payByLinkService.fields.offerPeriod] ?? {});

    return {
        periodIc: payByLinkService.fields.offerPeriod,
        risks: sortedRisks,
    };
}

function offerHasChanges(): boolean {
    return payByLinkService.fields.offerOptions && payByLinkService.fields.offerPeriod
        ? !useComparisons().isSameObject(payByLinkOffer(), currentOffer()) : false;
}

function onPaymentMethodClick(value: DynamicDictionary): void {
    bankLinkIdError.value = false;
    bankLinkInfo.value = value.bankLinkInfo;
    bankLinkId.value = value.bankLinkId;
}

function onMakePaymentClick(): void {
    validateForm();
    if (payByCard.value) {
        validateCardDetails();
    }
    validateBanklink();
    if (bankLinkIdError.value) {
        onSubmitShowBankPaymentError();
    } else {
        submitPayment();
    }
}

function onSubmitShowBankPaymentError(): void {
    scrollToBanksList();
    const errorTitle: string = translate('btar_error_payment_method_not_selected_title');
    const errorDescription: string = translate('btar_error_payment_method_not_selected_description');
    PopupService.getInstance().show(new OnePopup()
        .withType()
        .simpleError
        .withTitle(errorTitle)
        .withDescription(errorDescription));
}

function showOfferErrorPopup(): void {
    PopupService.getInstance().show(new OnePopup()
        .withType()
        .simpleError);
}

function validateCardDetails(): void {
    Object(cardDetailsPbl.value)
        .touchAndValidate()
        .then(() => {
            bankLinkId.value = RecurringPaymentBanklink.ByCountry[(new AppCountry).iso()];
        });
}

function validateForm(): void {
    form.touch().then((): void => {
        form.validate().then((): void => {
            if (!form.isValid()) {
                scrollToInvalidElement();
            }
        });
    });
}

function submitPayment(): void {
    if (form.isValid()) {
        Vue.nextTick((): void => {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            if (offerHasChanges()) {
                useAxios().post(Url.Ajax.mtplRenewalEditOffer, editOfferContent()).then((response: AxiosResponse): void => {
                    if (validResponse(response)) {
                        payByLinkService.renewedOfferId.value = response.data.data.body.offer.id;
                        postPayment();
                    } else {
                        showOfferErrorPopup();
                    }
                });
            } else {
                postPayment();
            }
        });
    } else {
        scrollToInvalidElement();
    }
}

function postPayment(): void {
    const params: Record<string, Record<string, string | unknown>> = assemblePaymentData();
    useAxios().post(Url.Ajax.payByLink, params)
        .then((value: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(value) && value.data.data.status === 'OK') {
                useNavigate().navigate(value.data.data.redirectUrl);
            } else {
                PopupService.getInstance().hide();
                Error.getInstance().show(ErrorType.Error, 'submitPayment', value);
            }
        }).catch((reason: LimitedVariant): void => {
        PopupService.getInstance().hide();
        Error.getInstance().show(ErrorType.Error, 'submitPayment', reason as DynamicDictionary);
    });
}

function assemblePaymentData(): Record<string, Record<string, string | unknown>> {
    const paymentCardDetails: CardDetails = form.field('card-details').value;
    const json: Record<string, Record<string, number | string>> = {
        data: {}
    };
    json.data.id = payByLinkService.payByLink.value.id;
    json.data.bankLinkId = bankLinkId.value;
    let cardDetails: string = '';
    if (payByCard.value) {
        cardDetails = transformedCardDetails(paymentCardDetails);
    }

    return {
        data: {
            json: JSON.stringify(json),
            facility: Facility,
            extra: cardDetails,
            paymentFailRedirectUrl: router.currentRoute.path,
            policyReceiverEmail: payByLinkService.fields.holderEmail,
        }
    };
}

function transformedCardDetails(details: CardDetails): string {
    return JSON.stringify({
        holder: details.holderName,
        number: details.number,
        expiryYear: details.validThru.year,
        expiryMonth: details.validThru.month,
        cvc: details.cvc,
    });
}

function scrollToInvalidElement(): void {
    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
    if (invalidElements.length > 0) {
        invalidElements[0].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
    }
}

function scrollToBanksList(): void {
    useScroll().scrollToView('#banks-list');
}

function validateBanklink(): void {
    bankLinkIdError.value = bankLinkId.value === 0;
}

function offerId(): string {
    return payByLinkService.renewedOfferId.value !== ''
        ? payByLinkService.renewedOfferId.value : payByLinkService.payByLink.value.documentId;
}

function editOfferContent(): DynamicDictionary {
    return {
        ...useComparisons().objectDiff(payByLinkOffer(), currentOffer()),
        accidentTypeIc: payByLinkService.mtplOffer.value.accidentTypeIc,
        placeCount: payByLinkService.mtplOffer.value.placeCount,
        risks: transformedRisks(),
        id: offerId(),
        number: payByLinkService.payByLink.value.documentNumber,
    };
}

function transformedRisks(): DynamicDictionary {
    const result: DynamicDictionary = {};
    const risks: DynamicDictionary = currentOffer().risks;
    Object.keys(currentOffer().risks).forEach((ic: string): void => {
        result[new AdditionalOptionsMap().keyByIc(ic)] = risks[ic];
    });

    return result;
}

function confirmAndPayButtonParams(): ButtonWithCallbackParams {
    const buttonTitle: string = translateForType('summary_confirm_and_pay', TranslationType) +
        ' · ' + sparse(totalPremium(), false) + ' &euro;'

    return {
        title: buttonTitle,
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
    };
}

</script>

<template>
    <div class="step-container" v-if="viewIsReady">
        <app-custom-form v-if="form.isReady()" :form="form" @change="storeValues()" class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <section class="header">
                <h2 class="title">{{ translateForType('summary_view_title', TranslationType) }}</h2>
                <div class="description">{{ translateForType('summary_view_description', TranslationType) }}</div>
            </section>
            <div class="summary-form">
                <section class="whiteboard" id="whiteboard-0">
                    <div class="whiteboard-title">{{ translateForType('policy_details', TranslationType) }}</div>
                    <ul class="list details">
                        <li class="item" id="whiteboard-item-0-0">
                            <span>{{ translateForType('insured_object', TranslationType) }}</span>
                            <span class="item-name">{{ translateForType('insured_vehicle', TranslationType) }}</span>
                        </li>
                        <li class="item" id="whiteboard-item-0-1">
                            <span>{{ translateForType('insured_vehicle', TranslationType) }}</span>
                            <span class="item-name">{{ payByLinkService.payByLink.value.objectName }}</span>
                        </li>
                    </ul>
                </section>
                <section class="whiteboard" id="whiteboard-1">
                    <div class="whiteboard-title">{{ translateForType('mtpl_period', TranslationType) }}</div>
                    <ul class="list details">
                        <li class="item" id="whiteboard-item-1-0">
                            <span>{{ translateForType('policy_period', TranslationType) }}</span>
                            <span class="item-name">{{ offerPeriodLabel() }}</span>
                        </li>
                    </ul>
                </section>
                <section class="whiteboard" id="whiteboard-2">
                    <div class="whiteboard-title">{{ translateForType('payment_details', TranslationType) }}</div>
                    <ul class="list details">
                        <li class="item" id="whiteboard-item-2-0">
                            <span>{{ translateForType('mtpl_policy', TranslationType) }}</span>
                            <span class="item-name">{{ sparse(offerPremium(), false) }}&nbsp;&euro;</span>
                        </li>
                        <li class="item test" v-for="(ic, index) in includedRiskIcs()" :key="index" :id="'whiteboard-item-2-' + (index + 1)">
                            <span>{{ translateForType(ic, TranslationType) }}</span>
                            <span class="item-name">{{ sparse(riskPremium(ic), false) }}&nbsp;&euro;</span>
                        </li>
                        <li class="item total inline" :id="'whiteboard-item-2-' + (includedRiskIcs().length + 1)">
                            <span class="item-name payment">{{ translateForType('sum_total', TranslationType) }}</span>
                            <span class="item-total">{{ sparse(totalPremium(), false) }}&nbsp;&euro;</span>
                        </li>
                    </ul>
                </section>
                <section class="whiteboard" id="whiteboard-3">
                    <div class="whiteboard-title">{{ translateForType('policy_holder', TranslationType) }}</div>
                    <div class="full-width flex column">
                        <app-input-text class="full-width"
                                        :form-field="form.field('holder-full-name')"
                                        :label="translate('one_full_name')"
                                        :disabled="true"
                                        :data-store-disabled="true">
                        </app-input-text>
                        <app-input-email class="full-width"
                                         :form-field="form.field('holder-email')"
                                         :label="translate('btar_email')">
                        </app-input-email>
                    </div>
                </section>
                <section class="whiteboard" id="whiteboard-4">
                    <div class="whiteboard-title">{{
                            translateForType('summary_terms_and_agreements', TranslationType)
                        }}
                    </div>
                    <div class="purchase-terms"
                         v-html="translateForType('description_terms_and_agreements', TranslationType)"></div>
                    <hr class="line-break"/>
                    <app-input-checkbox class="terms-checkbox"
                                        :form-field="form.field('agree-terms')">
                        {{ translateForType('checkbox_agree_terms', TranslationType) }}
                    </app-input-checkbox>
                    <hr class="line-break"/>
                    <app-consents-list
                        :form-field="form.field('marketing-consents')">
                    </app-consents-list>
                </section>
                <app-payment-methods
                    v-if="!payByCard"
                    :id="'banks-list'"
                    :error-state="bankLinkIdError"
                    @payment-method-click="onPaymentMethodClick($event)">
                </app-payment-methods>
                <section class="whiteboard"
                         v-if="payByCard">
                    <app-credit-card-details
                        ref="cardDetailsPbl"
                        :title="'btar_credit_card_details'"
                        :form-field="form.field('card-details')"
                        :data-store-disabled="true">
                    </app-credit-card-details>
                </section>
            </div>
        </app-custom-form>
        <footer class="additional-footer popup-right-padding" v-if="bankIsSelected">
            <div class="wrapper">
                <div class="container">
                    <div class="content" v-html="bankLinkInfo"></div>
                </div>
                <app-button-with-callback
                    class="button"
                    v-bind="confirmAndPayButtonParams()"
                    @button-callback-click="onMakePaymentClick()">
                </app-button-with-callback>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);
    margin-top: calc(56px + var(--size-big));

    .header {
        padding: 0 var(--size-small);

        @include respond-above('sm') {
            padding: 0 var(--size-big);
        }

        .title,
        .description {
            text-align: center;
        }

        .title {
            font-size: var(--font-size-big);
        }

        .description {
            margin-top: var(--size-tiny);
            color: var(--text-color-subtlest);
            font-size: var(--font-size-tiny);
        }
    }

    .summary-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);
        margin-top: var(--size-big);
        padding: var(--size-small);

        @include respond-above('sm') {
            gap: var(--size-normal);
            align-items: center;
        }

        .button-with-callback {
            width: 100%;
            height: 52px;
        }

        .info {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: var(--text-color-subtlest);
        }

        .whiteboard {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);
            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-small);
                font-weight: 700;
            }

            .purchase-terms {
                font-size: var(--font-size-nano);

                ::v-deep a {
                    color: var(--brand-teal) !important;
                }
            }

            .terms-checkbox {
                ::v-deep label {
                    color: var(--text-color-default);
                    font-weight: 400;
                }
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--component-color-border-default);
            }

            .list {
                width: 100%;
                border-radius: var(--size-pico);
                border: 1px solid var(--black-100);
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    padding: var(--size-small);
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: var(--size-pico);
                    align-self: stretch;
                    font-size: var(--font-size-nano);

                    @include respond-above('sm') {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: var(--size-small);
                    }

                    &.inline {
                        display: flex;
                        padding: var(--size-small);
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: var(--size-pico);
                        align-self: stretch;
                        font-size: var(--font-size-nano);
                        text-align: left;
                    }

                    &.total {
                        background: var(--system-color-success-light);
                    }

                    .item-total {
                        font-size: var(--font-size-medium);
                        font-weight: 700;
                    }

                    .currency {
                        font-size: var(--font-size-tiny);
                        font-weight: 600;
                    }

                    .item-name {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: 50%;
                            text-align: right;
                        }

                        &.payment {
                            text-align: left;
                        }
                    }

                    &:not(:last-child) {
                        border-bottom: 1px solid var(--black-100);
                    }
                }
            }
        }
    }

    @include respond-above('sm') {
        padding: 0 var(--size-big);
        margin-bottom: var(--size-huge);
    }

    .full-width {
        width: 100%;
    }

    .half-children {
        .input {
            @include respond-above('sm') {
                width: 50%;
            }
        }
    }

    .flex {
        display: flex;
        gap: var(--size-small);

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;

            @include respond-above('sm') {
                flex-direction: row;
            }
        }
    }

    .additional-footer {
        .wrapper {
            max-width: 840px;
            flex-direction: column-reverse;

            .button {
                margin-top: 0;
                margin-bottom: var(--size-normal);
                border-radius: 8px;
            }

            .content {
                max-width: 509px;
                display: block;
                margin-bottom: var(--size-normal);
                padding-right: var(--size-huge);
                font-size: var(--font-size-nano);

                ::v-deep a {
                    color: var(--text-color-link);
                    font-weight: bold;

                    &:hover {
                        color: var(--text-color-link-hover);
                    }
                }

                @include respond-above('sm') {
                    margin-bottom: 0;
                    padding-right: 0;
                }
            }

            @include respond-above('sm') {
                flex-direction: row;

                .button {
                    margin: 0;
                }
            }
        }
    }
}
</style>
