<script lang="ts">

import Vue, { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import VueRouter, { RouteConfig } from 'vue-router';
import RoutStepperStep from '@/Components/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import { useRouter } from 'vue-router/composables';
import { CoveredPopupBuilder } from '@/Apps/Renters/Builders/CoveredPopupBuilder';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const router: VueRouter = useRouter();
        const CurrentStep: number = 1;
        const Facility: string = 'solar-panels-insurance';
        const appPreparedAndReady: Ref<boolean> = ref(false);
        const initialStepUrl: Ref<string> = ref('');
        const steps: RoutStepperStep[] = (router.options.routes || []).map((routeConfig: RouteConfig): RoutStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title,
            }
        });
        const coveredPopup: Ref<CoveredPopupBuilder> = computed(() => {
            return btaBase.spa().coveredPopup;
        });

        function applyInitialStepUrl(url: string): void {
            initialStepUrl.value = url;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                steps,
                applyInitialStepUrl,
                appPreparedAndReady,
                coveredPopup,
            }
        }
    },
    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.appPreparedAndReady = true;
                Vue.nextTick(() => {
                    this.sticky.init();
                });
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>

