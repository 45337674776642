import Translation from '@/interfaces/translations.interface';
import {Subject} from 'rxjs';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import OneLocalization from '@/assets/libraries/localization/localization';

export default class Translations {
    private readonly translations: Translation = new class implements Translation {
        [key: string]: string;
    };
    private static instance: Translations;
    private translationType: string = '';
    private pageLanguage: string = '';
    private pageCountryIso: string = '';
    private translationsReady: boolean = false;
    public onExternalDataIsReady: Subject<void> = new Subject<void>();

    private constructor() {
    }

    public static getInstance(): Translations {
        if (!Translations.instance) {
            Translations.instance = new Translations();
        }

        return Translations.instance;
    }

    public addType(value: string): void {
        this.translationType = value;
    }

    public addLanguage(language: string): void {
        this.pageLanguage = language;
        this.checkReadyStatus();
    }

    public get language(): string {
        return this.pageLanguage;
    }

    public get type(): string {
        return this.translationType;
    }

    public addCountryIso(countryIso: string): void {
        this.pageCountryIso = countryIso;
    }

    public get countryIso(): string {
        return this.pageCountryIso;
    }

    public buildTranslations(): void {
        const localizations: OneLocalization = new OneLocalization();
        const translations: DynamicDictionary = localizations.translations();
        for (const o in translations) {
            this.translations[o] = (translations[o].text === null ? (translations[o].type ? translations[o].type
                + '.' : '') + translations[o].key : translations[o].text);
        }
        localizations.clear();
        this.translationsReady = true;
        this.checkReadyStatus();
    }

    public ready(): boolean {
        return this.translationsReady && this.pageLanguage !== '';
    }

    public hasLocalization(stringUid: string, type: string = ''): boolean {
        const index: string = type !== '' ? type + '.' + stringUid : stringUid;

        return typeof this.translations !== 'undefined' && typeof this.translations[index] !== 'undefined' &&
            this.translations[index] !== null;
    }

    public localized(stringUid: string, type: string = '', replacements?: DynamicDictionary): string {
        let result: string = (type !== '' ? type + '.' : '') + stringUid;
        if (this.hasLocalization(stringUid, type)) {
            const index: string = type !== '' ? type + '.' + stringUid : stringUid;
            result = this.translations[index];
        }
        if (replacements) {
            result = result.replace(/%\w+%/g, (all: string) => {
                return replacements[all] || all;
            });
            Object.keys(replacements).forEach(key => {
                if (isNaN(parseInt(key, 10))) {
                    result = result.replace(new RegExp('{' + key + '}', 'g'), String(replacements[key]))
                }
            });
        }

        return result;
    }

    private checkReadyStatus(): void {
        if (this.ready()) {
            this.onExternalDataIsReady.next();
        }
    }
}
