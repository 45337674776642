<script lang="ts">

import Vue, {computed, defineComponent, reactive, Ref, ref} from 'vue';
import Error from '@/services/error.service';
import OneBaseService from '@/services/OneBaseService';
import {ClaimSubmissionParams, useClaimSubmission} from '@/Modules/ClaimsSubmissionForm/Composables/ClaimSubmission';
import {useModule} from '@/Composables/Module';
import OneModule from '@/interfaces/OneModuleInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Captcha from '@/services/captcha.service';
import moment from 'moment/moment';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import {Observable, of} from 'rxjs';
import {HealthCard} from '@/interfaces/resources/health.card.interface';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTranslate} from '@/Composables/Translate';
import PopupType from '@/Enums/PopupTypeEnum';
import Popup from '@/services/popup.service';
import User from '@/services/user.service';
import Url from '@/Enums/UrlEnum';
import {AxiosResponse} from 'axios';
import {useDefine} from '@/Composables/Define';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import Validation from '@/services/validation.service';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {useCore} from '@/Composables/Core';

const {translate, language} = useTranslate();
const {isSet, validResponse} = useDefine();
const {updateReactiveObject} = useCore();

export default defineComponent({
    setup() {
        const captcha: Captcha = Captcha.getInstance();
        const popup: Popup = Popup.getInstance();
        const error: Error = Error.getInstance();
        const user: User = User.getInstance();


        const module: OneModule = useModule();
        const claimSubmission: ClaimSubmissionParams = useClaimSubmission();
        const request: AxiosParams = useAxios();


        const ready: Ref<boolean> = ref(false);
        const userHealthCards: Ref<HealthCard[]> = ref([]);
        const isLoading: Ref<boolean> = ref(false);
        const claimCaseNumber: Ref<string> = ref('');
        const searching: Ref<boolean> = ref(false);
        const redirectUrl: Ref<string> = ref('');
        const otherApplicant: UnwrapNestedRefs<DynamicDictionary> = reactive({
            name: '',
            surname: '',
            identityNumber: '',
            phoneNumber: '',
            email: '',
        });
        let selectedHealthCard: UnwrapNestedRefs<HealthCard> = reactive({
            id: '',
            type: '',
            cardNumber: '',
            personId: '',
            dateFrom: '',
            dateTo: '',
            cardHolder: '',
            policyNumber: '',
        });
        let personHealthCards: Ref<HealthCard[]> = ref([]);


        const countrySelector: Ref<HTMLDivElement | null> = ref(null);


        const EasingSpeed: number = 300;
        const SecondStep: number = 2;
        const claimType: string = 'health';
        const uploader: string = 'health';
        const renew: boolean = true;


        const create = (): void => {
        }

        const beforeModuleMounted = () => {
            module.beforeModuleMounted();
            if (!ready.value) {
                ready.value = true;
                const onExternalDataIsReady: Observable<void> = module.ready.value ?
                    of(void 0) : module.onModuleReady;
                onExternalDataIsReady.subscribe((): void => {
                    Vue.nextTick((): void => {
                        setupUserForm();
                        setupClaimForm();
                        claimSubmission.pushDataLayer(claimType, false);
                        claimSubmission.filesRequired.value = false;
                        claimSubmission.patchUserFields();
                        module.applyModuleType();
                        captcha.executeParametricCaptcha(fetchUserHealthCards, 'fetch');
                    });
                });
            }
        }

        const moduleMounted = () => {
            module.moduleMounted();
        }

        const secondStep = (): void => {
            claimSubmission.selectStep(SecondStep);
            claimSubmission.pushDataLayer(claimType, false);
        }

        const steps = (): string[] => {
            return [
                translate('submit_health_claim_insured_person'),
                translate('submit_health_claim_claim_case'),
            ];
        }

        const prepareAndSubmit = (): void => {
            captcha.executeParametricCaptcha(doSubmit, 'submit');
        }

        const doSubmit = (token: string): void => {
            if (claimSubmission.filesCount.value === 0) {
                scrollToUploader();

                return;
            }
            const params: Record<string, unknown> = {
                claimType: claimType,
                uploader: uploader,
                cardNumber: claimSubmission.claimForm.field('cardNumber').value,
                policyNumber: claimSubmission.claimForm.field('policyNumber').value,
                personId: claimSubmission.claimForm.field('personId').value,
                personCode: claimSubmission.userForm.field('identityNumber').value,
                birthDate: claimSubmission.userForm.field('applicantIsDifferentPerson').value
                && !claimSubmission.userForm.field('applicantIsResident').value ?
                    moment(claimSubmission.userForm.field('birthDate').value).format('YYYY-MM-DD') :
                    user.current.birthDate,
                BankAccount: claimSubmission.claimForm.field('bankAccount').value,
                applicant: {
                    Email: claimSubmission.userForm.field('email').value,
                    Phone: '+' + claimSubmission.getPhoneCountry() + claimSubmission.userForm.field('phone').value.phone
                },
                beneficiary: {
                    PersonCode: claimSubmission.claimForm.field('beneficiaryIdentityNumber').value,
                    Resident: claimSubmission.claimForm.field('beneficiaryIsResident').value ? 'Y' : 'N',
                    PersonFirstName: claimSubmission.claimForm.field('beneficiaryFirstName').value,
                    PersonLastName: claimSubmission.claimForm.field('beneficiaryLastName').value,
                    PersonBirthDate: claimSubmission.claimForm.field('beneficiaryIsResident').value ?
                        '' :
                        moment(claimSubmission.claimForm.field('beneficiaryBirthDate').value).format('DD.MM.YYYY'),
                    ResidenceCountry: claimSubmission.claimForm.field('beneficiaryCountry').value.iso,
                },
                portalUserPersonCode: user.current.personCode,
                caseDescription: claimSubmission.claimForm.field('caseDescription').value,
                'g-recaptcha-response': token
            };
            submitHealthClaim(params);
        }


        const submitHealthClaim = (params: Record<string, unknown>): void => {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            request.post(Url.Ajax.submitHealthClaim, params)
                .then((value: DynamicDictionary): void => {
                    if (validResponse(value) && value.data.data.status === 'OK') {
                        claimSubmission.pushDataLayer(Object(params).claimType, true);
                        claimCaseNumber.value = value.data.data.body.ClaimCaseNo;
                        (claimSubmission.filesUploader.value as DynamicDictionary).clearUploads();
                        window.location.href = new UrlBuilder()
                            .withLanguage(language())
                            .withUri(redirectUrl.value)
                            .build();

                    } else {
                        throw value;
                    }
                }).catch((reason: AxiosResponse): void => {
                PopupService.getInstance().hide();
                const message: string = isSet(reason.data.errors.code) ? reason.data.errors.code : '';
                claimSubmission.showHealthClaimFailure(message);
                captcha.resetCaptcha();
            });
        }

        const setupUserForm = (): void => {
            claimSubmission.userForm.addField(new FormField('applicantIsDifferentPerson', false));
            claimSubmission.userForm.addField(new FormField('applicantIsResident', false));
            claimSubmission.userForm.addField(
                new FormField('firstName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.userForm.addField(
                new FormField('lastName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.userForm.addField(
                new FormField('identityNumber', '', applicantPersonCodeValidator(), cleanPersonCode));
            claimSubmission.userForm.addField(
                new FormField('birthDate', '', birthDateRequiredForApplicantValidator()));
            claimSubmission.userForm.addField(new FormField('phone', '', 'required'));
            claimSubmission.userForm.addField(new FormField('email', '', 'required'));
            claimSubmission.userForm.addField(
                new FormField('healthCardNumber', '', cardNumberLengthIsWithinLimitsValidator()));
            claimSubmission.userForm.addField(new FormField('healthCardPersonId', ''));
            claimSubmission.userForm.setReady();
        }

        const setupClaimForm = (): void => {
            claimSubmission.claimForm.addField(
                new FormField('beneficiaryFirstName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.claimForm.addField(
                new FormField('beneficiaryLastName', '', personNameValidator(), Sanitizer.cleanName));
            claimSubmission.claimForm.addField(
                new FormField('beneficiaryIdentityNumber', '', beneficiaryPersonCodeValidator()));
            claimSubmission.claimForm.addField(new FormField('beneficiaryCountry'));
            claimSubmission.claimForm.addField(
                new FormField('beneficiaryBirthDate', '', birthDateRequiredForBeneficiary()));
            claimSubmission.claimForm.addField(new FormField('beneficiaryIsResident', true));
            claimSubmission.claimForm.addField(new FormField('claimRecipient'));
            claimSubmission.claimForm.addField(new FormField('cardHolder', '', 'required'));
            claimSubmission.claimForm.addField(new FormField('cardNumber', '', 'required'));
            claimSubmission.claimForm.addField(new FormField('policyNumber'));
            claimSubmission.claimForm.addField(new FormField('personId', ''));
            claimSubmission.claimForm.addField(new FormField('bankAccount', '', 'required'));
            claimSubmission.claimForm.addField(new FormField('caseDescription'));
            claimSubmission.claimForm.setReady();
        }

        const patchClaimForm = (): void => {
            claimSubmission.claimForm.field('claimRecipient').patch(person());
            claimSubmission.claimForm.field('cardNumber')
                .patch(selectedHealthCard ? selectedHealthCard.cardNumber : '');
            claimSubmission.claimForm.field('policyNumber')
                .patch(selectedHealthCard ? selectedHealthCard.policyNumber : '');
            claimSubmission.claimForm.field('personId')
                .patch(selectedHealthCard ? selectedHealthCard.personId : '');
            claimSubmission.claimForm.field('cardHolder')
                .patch(selectedHealthCard ? selectedHealthCard.cardHolder : '');
            claimSubmission.claimForm.field('beneficiaryCountry')
                .patch({iso: claimSubmission.userForm.field('phone').value.code});
            onClaimRecipientSwitch();
        }

        const patchBeneficiaryForUser = (): void => {
            claimSubmission.claimForm.field('beneficiaryFirstName').patch(user.current.firstname);
            claimSubmission.claimForm.field('beneficiaryLastName').patch(user.current.lastname);
            claimSubmission.claimForm.field('beneficiaryIdentityNumber').patch(user.current.personCode);
            claimSubmission.claimForm.field('bankAccount').patch(user.current.bank);
        }

        const patchBeneficiaryForOther = (): void => {
            claimSubmission.claimForm.field('beneficiaryFirstName').patch('');
            claimSubmission.claimForm.field('beneficiaryLastName').patch('');
            claimSubmission.claimForm.field('beneficiaryIdentityNumber').patch('');
            claimSubmission.claimForm.field('beneficiaryBirthDate').patch('');
            claimSubmission.claimForm.field('bankAccount').patch('');
        }

        const patchBeneficiaryForInjured = (): void => {
            claimSubmission.claimForm.field('beneficiaryFirstName')
                .patch(claimSubmission.userForm.field('firstName').value);
            claimSubmission.claimForm.field('beneficiaryLastName')
                .patch(claimSubmission.userForm.field('lastName').value);
            claimSubmission.claimForm.field('beneficiaryIdentityNumber')
                .patch(claimSubmission.userForm.field('identityNumber').value);
            claimSubmission.claimForm.field('beneficiaryBirthDate')
                .patch(claimSubmission.userForm.field('birthDate').value);
            claimSubmission.claimForm.field('bankAccount').patch('');
        }

        const patchDifferentPerson = (): void => {
            claimSubmission.userForm.field('firstName').patch(otherApplicant.name);
            claimSubmission.userForm.field('lastName').patch(otherApplicant.surname);
            claimSubmission.userForm.field('identityNumber').patch(otherApplicant.identityNumber);
            claimSubmission.userForm.field('email').patch(otherApplicant.email);
            claimSubmission.userForm.field('phone').patch({
                country: user.current.phoneCode,
                phone: otherApplicant.phoneNumber
            });
        }

        const fetchUserHealthCards = (token: string): void => {
            isLoading.value = true;
            const searchParams: Record<string, string> = {
                'g-recaptcha-response': token
            };
            request.post(Url.Ajax.healthCards, {searchParams})
                .then((value: DynamicDictionary): void => {
                    if (validResponse(value)) {
                        userHealthCards.value = value.data.cards;
                    } else {
                        claimSubmission.showFailure(value.data.errors.code);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    claimSubmission.showFailure(reason);
                })
                .finally((): void => {
                    isLoading.value = false;
                });
        }

        const fetchPersonHealthCardsCaptchaCallback = (token: string): void => {
            fetchPersonHealthCards(token).then((): void => {
                if (personHealthCards.value.length === 1) {
                    if (claimSubmission.userForm.isValid()) {
                        onUserFormSubmit();
                    } else {
                        claimSubmission.scrollToInvalidField();
                    }
                }
            });
        }

        const fetchPersonHealthCards = (token: string): Promise<string> => {
            return new Promise((resolve, reject): void => {
                searching.value = true;
                const searchParams: Record<string, string> = healthCardSearchParams();
                searchParams['g-recaptcha-response'] = token;
                request.post(Url.Ajax.healthCards,
                    {searchParams})
                    .then((value: DynamicDictionary): void => {
                        if (validResponse(value)) {
                            personHealthCards.value = value.data.cards;
                        } else {
                            reject('error');
                            claimSubmission.showFailure(value.data.errors.code);
                        }
                    })
                    .catch((reason: DynamicDictionary): void => {
                        reject('error');
                        claimSubmission.showFailure(reason);
                    })
                    .finally((): void => {
                        searching.value = false;
                        resolve('success');
                    });
            });
        }

        const onApplicantSwitch = (): void => {
            claimSubmission.userForm.field('applicantIsResident').patch(true);
            if (loggedUserIsApplicant.value) {
                saveOtherApplicant().then((): void => {
                    claimSubmission.patchUserFields();
                });
            } else {
                patchDifferentPerson();
                updateReactiveObject(selectedHealthCard, emptyCard());
                claimSubmission.userForm.field('healthCardNumber').clear().then();
            }
        }

        const onClaimRecipientSwitch = (): void => {
            switch (claimRecipient()) {
                case 'me':
                    patchBeneficiaryForUser();
                    claimSubmission.claimForm.field('beneficiaryIsResident').value = true;
                    break;
                case 'other':
                    patchBeneficiaryForOther();
                    claimSubmission.claimForm.field('beneficiaryIsResident').value = true;
                    break;
                case 'injured':
                    patchBeneficiaryForInjured();
                    claimSubmission.claimForm.field('beneficiaryIsResident').value =
                        claimSubmission.userForm.field('applicantIsResident').value;
                    break;
                default:
            }
        }

        const claimRecipient = (): string => {
            return claimSubmission.claimForm.field('claimRecipient').value;
        }

        const changeActiveCard = (activeCard: HealthCard): void => {
            updateReactiveObject(selectedHealthCard, activeCard);
            patchClaimForm();
        }

        const onSearchOrProceed = (): void => {
            if (needToSearchCard.value) {
                if (!cardSearchDisabled.value) {
                    captcha.executeParametricCaptcha(fetchPersonHealthCardsCaptchaCallback, 'search');
                } else {
                    showSearchDisabledReason();
                }
            } else {
                onUserFormSubmit();
            }
        }

        const onHealthCardNumberChange = (): void => {
            personHealthCards.value = [];
            updateReactiveObject(selectedHealthCard, emptyCard());
        }

        const onUserFormSubmit = (): void => {
            personHealthCards.value = [];
            claimSubmission.selectStep(SecondStep);
        }

        const applyRedirectUrl = (url: string): void => {
            redirectUrl.value = url;
        }

        const showSearchDisabledReason = (): void => {
            popup.showPopup(PopupType.None);
            const messageToShow: string = translate('submit_health_claim_search_params_required');
            error.show(ErrorType.Error, 'onHealthCardSearch', messageToShow);
        }

        const saveOtherApplicant = (): Promise<string> => {
            return new Promise(resolve => {
                otherApplicant.name = claimSubmission.userForm.field('firstName').value;
                otherApplicant.surname = claimSubmission.userForm.field('lastName').value;
                otherApplicant.identityNumber = claimSubmission.userForm.field('identityNumber').value;
                otherApplicant.phoneNumber = claimSubmission.userForm.field('phone').value.phone;
                otherApplicant.email = claimSubmission.userForm.field('email').value;
                resolve('');
            });
        }

        const emptyCard = (): HealthCard => {
            return {
                id: '',
                type: '',
                cardNumber: '',
                personId: '',
                dateFrom: '',
                dateTo: '',
                cardHolder: '',
                policyNumber: '',
            }
        }

        const person = (): string => {
            return loggedUserIsApplicant.value ? 'me' : 'injured';
        }

        const healthCardSearchParams = (): Record<string, string> => {
            return {
                'personCode': claimSubmission.userForm.field('identityNumber').value,
                'healthCardNumber': claimSubmission.userForm.field('healthCardNumber').value
            };
        }

        const scrollToUploader = (): void => {
            const target: JQuery = $('[data-alias="files-uploader"]');
            $('html,body').animate({scrollTop: target.offset()!.top}, EasingSpeed);
        }

        const cleanPersonCode = (value: string): string => {
            let result: string = value;
            const isResident: boolean = claimSubmission.userForm.field('applicantIsResident').value;
            if (isResident) {
                result = Sanitizer.cleanPersonCode(value);
            }

            return result;
        }

        const personNameValidator = (): object => {
            return {
                validPersonName: (value: string) => Validation.isValidCaption(value)
            }
        }

        const applicantPersonCodeValidator = (): object => {
            return {
                validPersonCode: (value: string) => Validation.isValidNaturalPersonCode(value) ||
                    !claimSubmission.userForm.field('applicantIsResident').value
            }
        }

        const birthDateRequiredForApplicantValidator = (): object => {
            return {
                requireBirthDate: () => {
                    return claimSubmission.userForm.field('applicantIsDifferentPerson').value ?
                        claimSubmission.userForm.field('applicantIsResident').value ||
                        claimSubmission.userForm.field('birthDate').value !== '' : true;
                }
            }
        }

        const beneficiaryPersonCodeValidator = (): object => {
            return {
                validBeneficiaryPersonCode: (value: string) => Validation.isValidNaturalPersonCode(value) ||
                    !claimSubmission.claimForm.field('beneficiaryIsResident').value
            }
        }

        const birthDateRequiredForBeneficiary = (): object => {
            return {
                requireBirthDateForBeneficiary: () => {
                    return claimRecipient() === 'me' ?
                        true : claimSubmission.claimForm.field('beneficiaryIsResident').value ||
                        claimSubmission.claimForm.field('beneficiaryBirthDate').value !== '';
                }
            }
        }

        const cardNumberLengthIsWithinLimitsValidator = (): object => {
            return {
                cardNumberLengthIsWithinLimits: () => {
                    const cardNumberMaxLength: number = 5;
                    return claimSubmission.userForm.field('applicantIsDifferentPerson').value ?
                        claimSubmission.userForm.field('healthCardNumber').value.length >= cardNumberMaxLength :
                        true;
                }
            }
        }


        const userFormSubmitButtonText: Ref<string> = computed(() => {
            let result: string = needToSearchCard.value
                ? translate('submit_health_claim_search_card')
                : translate('btar_proceed');
            if (personHealthCards.value.length > 0) {
                result = translate('btar_proceed');
            }

            return result;
        });

        const needToSearchCard: Ref<boolean> = computed(() => {
            return !loggedUserIsApplicant.value
                && isSelectedCardEmpty.value
                && !otherPersonHasHealthCards.value;
        });

        const loggedUserIsApplicant: Ref<boolean> = computed(() => {
            return claimSubmission.userForm.field('applicantIsDifferentPerson').value === false;
        });

        const showHealthCards: Ref<boolean> = computed(() => {
            return loggedUserIsApplicant.value ?
                userHasHealthCards.value : otherPersonHasHealthCards.value;
        });

        const searchInProgress: Ref<boolean> = computed(() => {
            return searching.value;
        });

        const cardSearchDisabled: Ref<boolean> = computed(() => {
            return claimSubmission.userForm.field('healthCardNumber').isEmpty()
                || claimSubmission.userForm.field('identityNumber').isEmpty();
        });

        const isSelectedCardEmpty: Ref<boolean> = computed(() => {
            return selectedHealthCard.cardNumber === '';
        });

        const userHasHealthCards: Ref<boolean> = computed(() => {
            return userHealthCards.value.length !== 0;
        });

        const otherPersonHasHealthCards: Ref<boolean> = computed(() => {
            return personHealthCards.value.length !== 0;
        });

        const healthCardsList: Ref<HealthCard[]> = computed(() => {
            return loggedUserIsApplicant.value ?
                userHealthCards.value : personHealthCards.value;
        });

        const claimRecipientOptions: Ref<InputOption[]> = computed(() => {
            const options: InputOption[] = [
                new InputOptionBuilder()
                    .setValue('me')
                    .setName(translate('submit_health_claim_me'))
                    .build(),
                new InputOptionBuilder()
                    .setValue('other')
                    .setName(translate('submit_health_claim_other'))
                    .build(),
            ];
            if (claimSubmission.userForm.field('applicantIsDifferentPerson').value) {
                options.push(new InputOptionBuilder()
                    .setValue('injured')
                    .setName(translate('submit_health_claim_injured'))
                    .build());
            }

            return options;
        });

        const currencyOptions: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder().setValue('EUR').setName('EUR').build(),
                new InputOptionBuilder().setValue('USD').setName('USD').build(),
            ];
        });

        const userFormSubmitDisabled: Ref<boolean> = computed(() => {
            return loggedUserIsApplicant.value ?
                !canContinueToSecondStep.value :
                !needToSearchCard.value && !canContinueToSecondStep.value;
        });

        const canContinueToSecondStep: Ref<boolean> = computed(() => {
            return !isSelectedCardEmpty.value;
        });

        const canSubmitForm: Ref<boolean> = computed(() => {
            return claimSubmission.claimForm.valid
                && (claimSubmission.claimForm.field('beneficiaryIsResident').value
                    || isSet(claimSubmission.claimForm.field('beneficiaryCountry').value.iso))
                && claimSubmission.filesCount.value > 0;
        });

        return {
            ...module,
            ...{
                renew,
                SecondStep,
                claimType,
                uploader,
                claimSubmission,
                userHealthCards,
                personHealthCards,
                isLoading,
                claimRecipientOptions,
                selectedHealthCard,
                countrySelector,
                healthCardsList,
                currencyOptions,
                userFormSubmitButtonText,
                needToSearchCard,
                loggedUserIsApplicant,
                showHealthCards,
                searchInProgress,
                cardSearchDisabled,
                isSelectedCardEmpty,
                userHasHealthCards,
                otherPersonHasHealthCards,
                create,
                beforeModuleMounted,
                moduleMounted,
                secondStep,
                steps,
                prepareAndSubmit,
                onApplicantSwitch,
                onClaimRecipientSwitch,
                claimRecipient,
                changeActiveCard,
                canContinueToSecondStep,
                onSearchOrProceed,
                onHealthCardNumberChange,
                showSearchDisabledReason,
                onUserFormSubmit,
                canSubmitForm,
                applyRedirectUrl,
                userFormSubmitDisabled,
            }
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    }
});
</script>
