export default class ClaimsUid {
    public static readonly Accident: string = 'menu-uid:claims-accident-form';
    public static readonly Casco: string = 'menu-uid:claims-casco';
    public static readonly Health: string = 'menu-uid:claims-health';
    public static readonly Liability: string = 'menu-uid:claims-other';
    public static readonly Mtpl: string = 'menu-uid:claims-mtpl';
    public static readonly Other: string = 'menu-uid:claims-other';
    public static readonly Property: string = 'menu-uid:claims-property-date-and-place';
    public static readonly Travel: string = 'menu-uid:claims-travel-insurance';
}
