import OneBase from '@/interfaces/OneBaseInterface';
import UserStorage from '@/services/user.storage.service';
import {Subject, Subscription} from 'rxjs';
import Dimensions from '@/interfaces/dimensions.interface';
import CookiesNotifications from '@/services/cookies.notifications.service';
import Debounce from '@/services/debounce.service';
import DynamicStepper from '@/services/dynamic.stepper.service';
import Stepper from '@/services/stepper.service';
import ItemsBannerVimeo from '@/services/items.banner.vimeo.service';
import VueAppPageElement from '@/services/vue.app.page.element.service';
import OptionsPartialDate from '@/services/options.partial.date.service';
import User from '@/services/user.service';
import Vehicles from '@/services/vehicles.service';
import Banks from '@/services/banks.service';
import Address from '@/services/address.service';
import SessionTimeout from '@/services/session.timeout.service';
import Offers from '@/services/offers.service';
import Sticky from '@/services/sticky.service';
import Captcha from '@/services/captcha.service';
import Video from '@/services/video.service';
import Modal from '@/services/modal.service';
import PopupService from '@/services/custom.popup.service';
import Popup from '@/services/popup.service';
import Sanitizer from '@/services/sanitizer.service';
import SettingsService from '@/services/settings.service';
import Translations from '@/services/translations.service';
import Iframe from '@/services/iframe.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import axios, {AxiosRequestConfig, AxiosResponse, CancelTokenSource} from 'axios';
import DrawerService from '@/services/drawer.service';
import Error from '@/services/error.service';
import Validation from '@/services/validation.service';
import Terms from '@/services/terms.service';
import Vue, {computed, reactive, Ref, ref, watch} from 'vue';
import Libraries from '@/services/libraries.service';
import Banner from '@/services/banner.service';
import MegaMenu from '@/services/mega.menu.service';
import ItemsBannerLandingSlick from '@/services/items.banner.landing.slick.service';
import ItemsHorizontalSlick from '@/services/items.horizontal.slick.service';
import ItemsBannerResizes from '@/services/items.banner.resizes.service';
import LinkOnHoverService from '@/services/link.on.hover.service';
import {SlickPlugin} from '@/assets/libraries/slick/slick.plugin';
import {ToastrPlugin} from '@/assets/libraries/toastr/toastr.plugin';
import BottomNotification from '@/services/bottom.notification.service';
import LazyLoader from '@/services/lazy.loader.service';
import Countries from '@/services/countries.service';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import TriggeredEvent = JQuery.TriggeredEvent;
import ColorBoxesService from '@/services/color.boxes.service';
import {BankInstitution} from '@/interfaces/resources/bank.institution.interface';
import {Vehicle} from '@/interfaces/resources/vehicle.interface';
import Url from '@/assets/libraries/url/url';
import Urls from '@/Enums/UrlEnum';
import Transform from '@/services/TransformService';
import UserCredentials from '@/interfaces/user.credentials.interface';
import BreakPoints from '@/Enums/BreakPointsEnum';
import VueEvent from '@/Classes/VueEventClass';
import {useDefine} from '@/Composables/Define';
import PopupType from '@/Enums/PopupTypeEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import Method from '@/Enums/MethodEnum';
import ModalType from '@/Enums/ModalTypeEnum';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import DataLayer from '@/services/data.layer.service';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import SentryLibrary from '@/Libraries/SentryLibrary';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import VueDom from '@/Classes/VueDomClass';
import moment from 'moment/moment';
import PartialDate from '@/interfaces/partial.date.interface';
import VueModel from '@/services/vue.model.service';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import OnePopup from '@/assets/libraries/popups/one.popup';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import {useUrl} from '@/Composables/Url';
import {useNavigate} from '@/Composables/Navigate';
import {useBroadcaster} from '@/Composables/Broadcaster';
import {useTransforms} from '@/Composables/Transforms';
import {useChat} from '@/Composables/Chat';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import PaymentMethod from '@/Components/PaymentWidget/Interfaces/PaymentMethod.interface';
import PaymentMethods from '@/services/PaymentMethods.service';
import SpaUrls from '@/services/SpaUrls.service';
import SpaUrl from '@/Components/LanguageSwitcher/SpaUrl';
import ModuleActions from '@/services/module.actions.service';
import TwoWayModal from '@/Apps/TwoWayCommunication/Services/TwoWayModalService';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import Communication from '@/Apps/TwoWayCommunication/Interfaces/CommunicationInterface';

export const useBase = (): OneBase => {
    let app: DynamicDictionary | null = null;
    const spaApp: Ref<DynamicDictionary> = ref({});

    const isSet = useDefine().isSet;
    const request: AxiosParams = useAxios();

    const onExternalDataIsReady: Subject<void> = new Subject<void>();
    const onWindowResize: Subject<Dimensions> = new Subject<Dimensions>();
    const onAppIsPreparedAndReady: Subject<void> = new Subject<void>();

    const userStorage: UnwrapNestedRefs<UserStorage> = reactive(UserStorage.getInstance());
    const drawer: DrawerService = DrawerService.getInstance();
    const cookiesNotifications: UnwrapNestedRefs<CookiesNotifications> = reactive(CookiesNotifications.getInstance());
    const itemsBannerVimeo: ItemsBannerVimeo = new ItemsBannerVimeo();
    const vueAppPageElement: VueAppPageElement = VueAppPageElement.getInstance();
    const error: Error = Error.getInstance();
    const partialDate: OptionsPartialDate = new OptionsPartialDate();
    const user: User = User.getInstance();
    const vehicles: Vehicles = Vehicles.getInstance();
    const banks: Banks = Banks.getInstance();
    const paymentMethods: PaymentMethods = PaymentMethods.getInstance();
    const spaUrls: SpaUrls = SpaUrls.getInstance();
    const translations: Translations = Translations.getInstance();
    const settings: SettingsService = SettingsService.getInstance();
    const address: Address = Address.getInstance();
    const sanitizer: Sanitizer = Sanitizer;
    const popup: Popup = Popup.getInstance();
    const popupService: PopupService = PopupService.getInstance();
    const modal: Modal = Modal.getInstance();
    const video: Video = new Video();
    const sticky: Sticky = new Sticky();
    const moduleActions: ModuleActions = new ModuleActions();
    const offers: Offers = Offers.getInstance();
    const captcha: Captcha = Captcha.getInstance();
    const stepper: Stepper = Stepper.getInstance();
    const iframe: Iframe = Iframe.getInstance();
    const sessionTimeout: SessionTimeout = new SessionTimeout();
    const terms: Terms = Terms.getInstance();
    const libraries: Libraries = new Libraries();
    const banner: Banner = new Banner();
    const colorBoxes: ColorBoxesService = new ColorBoxesService()
    const itemsHorizontalSlick: ItemsHorizontalSlick = new ItemsHorizontalSlick();
    const itemsBannerLandingSlick: ItemsBannerLandingSlick = new ItemsBannerLandingSlick();
    const megaMenu: UnwrapNestedRefs<MegaMenu> = reactive(new MegaMenu());
    const itemsBannerResizes: ItemsBannerResizes = new ItemsBannerResizes();
    const linkOnHoverService: LinkOnHoverService = new LinkOnHoverService();
    const showSessionTimeoutLogoffHash: string = '#sessionTimeout';
    const showLoginPopupHash: string = '#loginPopup';
    const accordionElementSelector: string = '.accordion';
    const accordionOpenClassName: string = 'opened';
    const scrollSpeed: number = 600;
    const guestHash: string = '#guest';
    const resizeWindowDebounce: Function = Debounce.getInstance().applyDebounce(
        (value: Dimensions) => onWindowResize.next(value)
    );
    const countries: Countries = Countries.getInstance();
    const cmsFields: UnwrapNestedRefs<DynamicDictionary> = reactive({});
    let axiosFetchCancelToken: CancelTokenSource | null = null;
    let errorDetails: DynamicDictionary = {};
    let initialized: boolean = false;
    let created: boolean = false;

    const dynamicStepper: UnwrapNestedRefs<DynamicStepper> = reactive(DynamicStepper.getInstance());
    const platform: Ref<string> = ref('');
    const componentRefs: Ref<Vue[]> = ref([]);
    const axiosFetchIsInProgress: Ref<boolean> = ref(false);
    const axiosIsPrepared: Ref<boolean> = ref(false);
    const isInputLocked: Ref<boolean> = ref(false);
    const scrollbarWidth: Ref<number> = ref(0);
    const pendingRequestsCount: Ref<number> = ref(0);
    const width: Ref<number> = ref(0);
    const height: Ref<number> = ref(0);
    const correlationId: Ref<string> = ref('');
    const guestQueryParam: Ref<string> = ref('guest');
    const translationType: Ref<string> = ref('');
    const isReady: Ref<boolean> = ref(false);
    const useStorage: Ref<boolean> = ref(true);
    const step: Ref<number> = ref(0);
    const stepFacility: Ref<string> = ref('');
    const stepDataType: Ref<string> = ref('');
    const tokenName: Ref<string> = ref('');
    const tokenHash: Ref<string> = ref('');
    const urlCurrent: Ref<string> = ref('');
    const legalPersonRoutes: UnwrapNestedRefs<DynamicDictionary> = reactive({
        routes: [],
    });


    const applyApp = (newApp: DynamicDictionary): void => {
        app = newApp;
    }

    const currentApp = (): DynamicDictionary | null => {
        return app;
    }

    const applySpa = (newSpa: DynamicDictionary): void => {
        spaApp.value = newSpa;
    }

    const spa = (): DynamicDictionary => {
        return spaApp.value;
    }

    const create = (): void => {
        if (!created) {
            new Validation();
            drawer.onVisibilityChange.subscribe(changeBodyVerticalScrollState);

            const translationsSubscription: Subscription = translations.onExternalDataIsReady.subscribe((): void => {
                checkExternalDataStatus();
                translationsSubscription.unsubscribe();
            });

            const userSubscription: Subscription = user.onExternalDataIsReady.subscribe((): void => {
                checkExternalDataStatus();
                userSubscription.unsubscribe();
            });

            const settingsSubscription: Subscription = settings.onExternalDataIsReady.subscribe((): void => {
                checkExternalDataStatus();
                settingsSubscription.unsubscribe();
            });

            const countriesSubscription: Subscription = countries.onExternalDataIsReady.subscribe((): void => {
                checkExternalDataStatus();
                countriesSubscription.unsubscribe();
            });

            created = true;
        }
    }

    const initBtaBase = (): void => {
        if (!initialized) {
            initialized = true;
            translations.buildTranslations();
            Vue.nextTick((): void => {
                setupAxios();
                applyAxiosPendingCounter();
                libraries.init().then((): void => {
                    $((): void => {
                        initExternalValues();
                        useBroadcaster().initBroadcasters();
                        attachAccordion();
                        calculateScrollbarWidth();
                        preservePopupAffectedOriginalOffset();
                        partialDate.init();
                        userStorage
                            .fetchFormStorage()
                            .applyFormSaveOnIdle()
                            .applyFormsSaveOnPageUnload()
                            .applyFormsAutoSaveAfterInterval();
                        setupResizeListener();
                        megaMenu.init();
                        sessionTimeout.init();
                        sticky.init();
                        moduleActions.init();
                        banner.init();
                        vueAppPageElement.init();
                        scrollToModuleAlias();
                        itemsBannerResizes.init();
                        itemsBannerVimeo.init();
                        SlickPlugin.init().then((): void => {
                            colorBoxes.init();
                            itemsHorizontalSlick.init();
                            itemsBannerLandingSlick.init();
                        });
                        ToastrPlugin.init().then((): void => {
                            BottomNotification.getInstance().setReady();
                        });
                        cookiesNotifications.init().then((): void => {
                            changeBodyVerticalScrollState();
                        });
                        iframeReferenceCode();
                        linkOnHoverService.init();
                        useChat().addChat();
                        const onAppIsPreparedAndReadySubscription: Subscription =
                            onAppIsPreparedAndReady.subscribe((): void => {
                                terms.checkAgreeStatus();
                                resetSelectedLegalEntityInSession();
                                onAppIsPreparedAndReadySubscription.unsubscribe();
                            });
                        new LazyLoader().init();
                    });
                });
            });
            addPopupCloseOnHistoryBack();
            addScrollToAliasOnHashChange();
        }
    }

    const addPlatform = (newPlatform: string): void => {
        platform.value = newPlatform;
    }

    const iframeReferenceCode = (): void => {
        const referenceCode: string = window.location.search;
        const IframeReferenceCodeDom: JQuery = $('iframe.reference-code');
        const dataSourceAttribute: string | undefined = IframeReferenceCodeDom.attr('data-source');
        if (referenceCode !== '') {
            IframeReferenceCodeDom.attr('src', dataSourceAttribute + referenceCode.replace('?', '&'));
        } else {
            IframeReferenceCodeDom.attr('src', dataSourceAttribute as string);
        }
    }

    const externalDataIsReady = (): boolean => {
        return translations.ready() &&
            user.ready &&
            settings.ready &&
            countries.ready;
    }

    const getAge = (date: string): number => {
        const today: Date = new Date();
        const birthDate: Date = moment(date).toDate();
        let age: number = today.getFullYear() - birthDate.getFullYear();
        const month: number = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    const attachAccordion = (): void => {
        $(accordionElementSelector + ' .header').on('click', (event: TriggeredEvent): void => {
            const currentTarget: JQuery = $(event.currentTarget);
            const parent: JQuery = currentTarget.parents(accordionElementSelector);
            currentTarget.trigger('blur');
            if (parent.hasClass(accordionOpenClassName)) {
                parent.removeClass(accordionOpenClassName);
            } else {
                parent.addClass(accordionOpenClassName);
            }
        });
    }

    const disableCookiesNotification = (params: DynamicDictionary): void => {
        cookiesNotifications.disableNotification(params);
    }

    const setStep = (newStep: number): void => {
        step.value = newStep;
        dynamicStepper.applyCurrent(newStep);
    }

    const setFacility = (newFacility: string): void => {
        stepFacility.value = newFacility;
    }

    const applyDynamicSteps = (steps: string): void => {
        dynamicStepper.applyDynamicSteps(steps);
    }

    const applySteps = (steps: string, newCurrentStep: number): void => {
        stepper.applySteps(steps, newCurrentStep);
    }

    const setStorageUsage = (newUserStorage: boolean): void => {
        useStorage.value = newUserStorage;
    }

    const addTokens = (newTokenName: string, newTokenHash: string): void => {
        tokenName.value = newTokenName;
        tokenHash.value = newTokenHash;
    }

    const addBanks = (json: BankInstitution[]): void => {
        banks.addBanks(json);
    }

    const addPaymentMethods = (json: PaymentMethod[]): void => {
        paymentMethods.addOptions(json);
    }

    const addSpaUrls = (json: SpaUrl[]): void => {
        spaUrls.addUrls(json);
    }

    const addVehicles = (json: Vehicle[]): void => {
        vehicles.addVehicles(json);
    }

    const addUserStoreUid = (uid: string): void => {
        userStorage.addUserStoreUid(uid);
    }

    const addUser = (json: DynamicDictionary): void => {
        user.addUser(json);
    }

    const addLegalRoutes = (routes: string[]): void => {
        legalPersonRoutes.routes = routes;
    }

    const addFormStoragesFormUid = (uid: string): void => {
        userStorage.applyFormUid(useTransforms().transformedVueSafeString(uid));
    }

    const addFormStoragesJson = (json: string): void => {
        userStorage.applyFormStoragesJson(useTransforms().transformedVueSafeString(json));
    }

    const addStoragesJson = (json: string): void => {
        userStorage.applyStoragesJson(useTransforms().transformedVueSafeString(json));
    }

    const addCaptchaSiteKey = (key: string, enabled: boolean): void => {
        captcha.addSiteKey(key, enabled);
    }

    const setReady = (): void => {
        isReady.value = true;
    }

    const sessionId = (): string | null => {
        return new Url().queryParam('sessionId')
    }

    const applyTranslationType = (type: string): void => {
        translationType.value = type;
    }

    const hideAdditionalFooter = (selectedProduct: string): boolean => {
        return selectedProduct === '' || megaMenu.deviceMenuIsVisible;
    }

    const isLessThan = (breakPoint: number): boolean => {
        return width.value < breakPoint;
    }

    const localized = (stringUid: string, type: string = '', replacements?: DynamicDictionary): string => {
        return translations.localized(stringUid, type, replacements);
    }

    const preservePopupAffectedOriginalOffset = (): void => {
        $('.popup-right-padding').each((index: number, dom: HTMLElement): void => {
            const paddingRight: string = $(dom).css('padding-right');
            $(dom).data('original-padding-right', paddingRight);
        });
        $('.popup-right-margin').each((index: number, dom: HTMLElement): void => {
            const marginRight: string = $(dom).css('margin-right');
            $(dom).data('original-margin-right', marginRight);
        });
    }

    const changeElementZIndexes = (): void => {
        const container: HTMLElement = document.getElementsByTagName("main")[0];
        const header: HTMLElement = document.getElementsByTagName("header")[0];
        if (modal.type !== ModalType.None && container !== undefined && header !== undefined) {
            container.style.zIndex = window.getComputedStyle(header).getPropertyValue('z-index');
        } else if (modal.type === ModalType.None && container !== undefined) {
            container.style.zIndex = '';
        }
    }

    const appendCmsFields = (fieldsSafeJson: string): void => {
        Object.assign(cmsFields, JSON.parse(useTransforms().transformedVueSafeString(fieldsSafeJson)));
    }

    const requestCallback = (event: VueEvent): void => {
        scrollToAlias(event);
    }

    const scrollToAlias = (event: VueEvent): void => {
        if (isSet(event.params.scrollTarget)) {
            scrollToAliasByName(event.params.scrollTarget);
        }
    }

    const scrollToHash = (hash: string): void => {
        window.location.href = '#' + hash;
        Vue.nextTick(() => {
            scrollToModuleAlias();
        });
    }

    const scrollToAliasByName = (hash: string): void => {
        const splitHash = hash.split(':');
        const target: JQuery = $('[data-alias="' + splitHash[0] + '"]');
        const targetSubItemId: string = splitHash.length > 1 ? splitHash[1] : '';
        const targetSubItemExists: boolean = targetSubItemId !== '' && $('#' + targetSubItemId).length > 0;
        const SplitHashValidLength: number = 2;
        if (target.length && (splitHash.length < SplitHashValidLength || !targetSubItemExists)) {
            animateScrollTopFromMegaMenuToOffset(target.offset() as DynamicDictionary);
        }
        if (targetSubItemExists) {
            openItemById(targetSubItemId);
        }
    }

    const openOrScroll = (event: VueEvent): void => {
        const target: string = event.params.url;
        const targetSplit: string = target.substring(0, target.indexOf('#'));
        const targetPathLastItem: string = targetSplit.split('/')[targetSplit.split('/').length - 1]
        const currentUrlPath: string[] = window.location.pathname.split('/');
        const currentUrlPathLastItem: string = currentUrlPath[currentUrlPath.length - 1];
        if (currentUrlPathLastItem.normalize() === targetPathLastItem.normalize()) {
            scrollToAlias(event);
        } else {
            openUrl(event);
        }
    }

    const openUrl = (event: VueEvent): void => {
        const isSelf: boolean = !isSet(event.params.target) ||
            (event.params.target === '_self' || event.params.target === '');
        if (useDefine().isExternalLink(event.params.url)) {
            const url: string = event.params.url.includes('http:') || event.params.url.includes('https:')
                ? event.params.url
                : '//' + event.params.url;
            if (isSelf) {
                window.location.href = url;
            } else {
                const newTab: Window | null = openUrlInNewTab(url);
                if (newTab) {
                    newTab.focus();
                }
            }
        } else {
            navigate(event.params.url);
        }
    }

    const navigate = (url: string): void => {
        window.location.href = new UrlBuilder()
            .withLanguage(language.value)
            .withUri(url)
            .build();
    }

    const openUrlInNewTab = (url: string): Window | null => {
        const newWindow: Window | null = window.open(url, '_blank', 'noopener');
        if (newWindow !== null) {
            newWindow.opener = null;
        }

        return newWindow;
    }

    const openUrlInExternalWindow = (url: string): Window | null => {
        const params: string[] = [
            'width=' + screen.availWidth, 'height=' + screen.availHeight,
            'resizable=1', 'location=1', 'top=0', 'scrollbars=1', 'noreferrer',
            'noopener'
        ]
        const externalWindow: Window | null = window.open(url, '_blank', params.join(','));
        if (externalWindow !== null) {
            externalWindow.opener = null;
        }

        return externalWindow;
    }

    const singUpOption = (event: VueEvent, needGuestHash: boolean): void => {
        if (event.params.id === 'guest') {
            popupService.hide().then((): void => {
                showPopup(PopupType.None);
                useUrl().applyGuestMode();
            });
        } else {
            useNavigate().redirect(event.params.url);
        }
    }

    const enableSwitchableField = (fieldName: string): void => {
        if (isSet(cmsFields[fieldName]) && isSet(cmsFields[fieldName].enabled)) {
            cmsFields[fieldName].enabled = true;
            cmsFields[fieldName].valid = false;
        }
    }

    const errorLog = (newError: string, message: string): void => {
        Error.log(ErrorType.Error, newError, message);
    }

    const applyErrorNotification = (title: string, message: string): void => {
        errorDetails.title = title;
        errorDetails.message = message;
    }

    const lockInput = (lock: boolean = true): void => {
        isInputLocked.value = lock;
    }

    const formattedUrl = (route: string): string => {
        let formattedRoute: string = new Url(route).segments()[0] === language.value
            ? route
            : '/' + language.value + route;
        const newSessionId: string | null = sessionId();
        if (newSessionId) {
            const url: Url = new Url(formattedRoute);
            url.setQueryParam('sessionId', newSessionId);
            formattedRoute = url.pathWithParams();
        }

        return formattedRoute;
    }

    const axiosFetch = (url: string,
                        params: DynamicDictionary = {},
                        methodName: string = Method.Get,
                        lock: boolean = true): Promise<DynamicDictionary> => {
        if (!axiosIsPrepared.value) {
            const axiosSetupErrorMessage: string = 'axios call before axios setup: possible reason - axios request is to early (for example in: onExternalDataIsReady), try to move it to onAppIsPreparedAndReady';
            Error.log(ErrorType.Error, 'axiosFetch', axiosSetupErrorMessage, true);
            return Promise.reject(axiosSetupErrorMessage);
        }
        if (lock) {
            if (inputIsLocked.value) {
                return Promise.reject('input is locked');
            }
            lockInput(true);
        } else {
            if (axiosFetchIsInProgress.value && axiosFetchCancelToken) {
                axiosFetchCancelToken.cancel();
            }
            axiosFetchCancelToken = axios.CancelToken.source();
        }
        PopupService.getInstance().show(new OnePopup().withType().loading);
        const newFormattedUrl: string = formattedUrl(url);
        const isGet: boolean = methodName === Method.Get;
        return (isGet ?
                axios.get(newFormattedUrl, {params: params, cancelToken: axiosFetchCancelToken?.token}) :
                axios.post(newFormattedUrl, params, {cancelToken: axiosFetchCancelToken?.token})
        ).then((response: AxiosResponse<DynamicDictionary>) => {
            if (useDefine().validResponse(response)) {
                return response.data.data.body;
            } else {
                error.show(ErrorType.Error, 'axiosFetch', 'fetch_invalid_data');
                return response;
            }
        }).catch((reason: DynamicDictionary): void => {
            error.show(ErrorType.Error, 'axiosFetch', reason);
        }).finally((): void => {
            if (lock) {
                lockInput(false);
            }
            PopupService.getInstance().hide();
        });
    }

    const changeBodyVerticalScrollState = (): void => {
        if (useDefine().isVisibleVerticalScrollbar()) {
            if ((popup.type === PopupType.None &&
                !popupService.isActive &&
                !PopupService.getInstance().active &&
                !TwoWayModal.getInstance().isVisible.value &&
                modal.type === ModalType.None &&
                !drawer.isVisibleComponent &&
                !megaMenu.deviceMenuIsVisible) && !cookiesNotifications.showModule) {
                applyPaddingOffset('padding', false);
                applyPaddingOffset('margin', false);
            } else {
                applyPaddingOffset('padding', true);
                applyPaddingOffset('margin', true);
            }
        }
    }

    function applyPaddingOffset(type: string, apply: boolean): void {
        if (apply) {
            $('html, body').css('overflow', 'hidden');
            $('.popup-right-' + type).each((index: number, dom: HTMLElement): void => {
                const offsetRight: string = $(dom).data('original-' + type + '-right');
                const offsetRightCombined: number = parseInt(offsetRight, 10) + scrollbarWidth.value;
                $(dom).css(type + '-right', offsetRightCombined + 'px');
            });
        } else {
            $('html, body').css('overflow', 'visible');
            $('.popup-right-' + type).each((index: number, dom: HTMLElement): void => {
                const offsetRight: string = $(dom).data('original-' + type + '-right');
                $(dom).css(type + '-right', offsetRight);
            });
        }
    }

    const domRef = (refName: string): DynamicDictionary | undefined => {
        return (componentRefs.value as DynamicDictionary)[refName];
    }

    const cmsFieldIsEnabled = (name: string): boolean => {
        const result: LimitedVariant = isSet(cmsFields[name]) && cmsFields[name].enabled;

        return typeof result === 'boolean' ? result : result === '1';
    }

    const initPopupTooltips = (): void => {
        initTooltipster('.tooltipster-popup');
    }

    const setOffersCount = (): void => {
        offers.fetchOffersCount();
    }

    const addPopupCloseOnHistoryBack = (): void => {
        window.onpopstate = (): void => {
            if (popupInstanceTypeIs(PopupType.Login)) {
                popupService.hide().then();
            }
        };
    }

    const addScrollToAliasOnHashChange = (): void => {
        window.addEventListener('hashchange', (): void => {
            if (window.location.hash !== '') {
                scrollToAliasByName(window.location.hash.replace(/^#/, ""));
            }
        }, false);
    }

    const checkExternalDataStatus = (): void => {
        if (externalDataIsReady()) {
            onExternalDataReady();
            onExternalDataIsReady.next();
        }
    }

    const onExternalDataReady = (): void => {
        initSentry();
        if (user.loginStatus !== User.LoginStatusNoChanges) {
            let userId: string = DataLayerType.LoginFail;
            if (user.isLogged()) {
                userId = String(user.current.personId);
            }
            DataLayer.getInstance()
                .addRootParam('event', 'auth')
                .addRootParam('userId', userId)
                .buildAndPush();
        }
    }

    const initSentry = (): void => {
        if (settings.sentryIsEnabled()) {
            (new SentryLibrary()).init(
                settings.sentryDsn(),
                settings.sentryTargets(),
                correlationId.value
            );
        }
    }

    const setupResizeListener = (): void => {
        window.addEventListener('resize', updateWindowDimensions);
        window.addEventListener('resize', reInitBannersSlick);
        Vue.nextTick((): void => {
            updateWindowDimensions();
            changeBodyVerticalScrollState();
        });
    }

    const reInitBannersSlick = (): void => {
        itemsBannerLandingSlick.init();
    }

    const scrollToModuleAlias = (): void => {
        if (window.location.hash && String(window.location.hash).length > 1) {
            const hash: string = String(window.location.hash).substr(1);
            if (!user.isLogged() &&
                ('#' + hash === showSessionTimeoutLogoffHash || '#' + hash === showLoginPopupHash)) {
                window.history.replaceState({}, document.title, "/" + translations.language);
                if ('#' + hash === showSessionTimeoutLogoffHash) {
                    const popupSessionEnded: SimpleError = new OnePopup()
                        .withType()
                        .simpleError
                        .withTitle(translations.localized('btar_session_ended'))
                        .withDescription(translations.localized('btar_session_ended_reason'));
                    PopupService.getInstance().show(popupSessionEnded);
                } else if ('#' + hash === showLoginPopupHash) {
                    popupService.show(new OnePopup().withType().login);
                }
            } else {
                scrollToAliasByName(hash);
            }
        }
    }

    const showPopup = (type: string): void => {
        popup.showPopup(type);
    }

    const openItemById = (id: string): void => {
        const targetItem: JQuery = $('#' + id);
        if (targetItem.length) {
            const parentAccordion: JQuery<HTMLElement> = targetItem.parent(accordionElementSelector);
            animateScrollTopFromMegaMenuToOffset(targetItem.offset() as DynamicDictionary);
            if (parentAccordion.length
                && !parentAccordion.hasClass(accordionOpenClassName)
            ) {
                targetItem.trigger('click');
            }
        }
    }

    const updateWindowDimensions = (): void => {
        width.value = window.innerWidth;
        height.value = window.innerHeight;
        resizeWindowDebounce({
            width: width.value,
            height: height.value,
        });
    }

    const setupAxios = () => {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = tokenHash;
        axiosIsPrepared.value = true;
    }

    const applyAxiosPendingCounter = (): void => {
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            pendingRequestsCount.value++;
            return config;
        }, (err: DynamicDictionary) => {
            return Promise.reject(err);
        });
        axios.interceptors.response.use((config: AxiosResponse) => {
            pendingRequestsCount.value--;
            return config;
        }, (err: DynamicDictionary) => {
            pendingRequestsCount.value--;
            return Promise.reject(err);
        });
    }

    const applyExternalValueOnModel = (modelName: string, value: LimitedVariant): void => {
        VueModel.setModelValue(modelName, value);
    }

    const updateModel = (domElement: JQuery): void => {
        const model: string = domElement.data('model-update');
        if (model) {
            VueModel.setModelValue(model, useDefine().validNumber(domElement.val() as DynamicDictionary));
        }
    }

    const initExternalValues = (): void => {
        $('[data-model-name]').each((i: number, dom: HTMLElement): void => {
            const domElement: JQuery = $(dom);
            const modelName: string = $(dom).data('model-name');
            const safeMode: string | undefined = $(dom).data('model-safe');
            let modelValue: string = $(dom).data('model-value');
            if (safeMode) {
                modelValue = JSON.parse(modelValue);
            }
            if (modelName && modelValue) {
                VueModel.setModelValue(modelName, modelValue);
                domElement.removeAttr('data-model-name')
                    .removeAttr('data-model-value')
                    .removeAttr('data-model-safe');
            }
        });
    }

    const tooltipsterInstance = (popupClass: string): DynamicDictionary => {
        return $(popupClass) as DynamicDictionary;
    }

    const vueEvent = (target: TriggeredEvent): VueEvent => {
        return new VueEvent(target);
    }

    const vueDom = (domElement: TriggeredEvent): VueDom => {
        return new VueDom(domElement);
    }

    const initTooltipster = (popupClass: string): void => {
        tooltipsterInstance(popupClass).tooltipster({
            theme: 'tooltipster-shadow',
            arrow: false,
            interactive: true,
            animationDuration: 200,
            minWidth: 320,
            maxWidth: 320,
            repositionOnScroll: false,
            side: ['right', 'bottom', 'left', 'top'],
            trigger: 'custom',
            triggerOpen: {
                click: true,
                tap: true
            },
            triggerClose: {
                click: true,
                tap: true
            },
            functionPosition: (instance: DynamicDictionary, helper: DynamicDictionary, position: DynamicDictionary) => {
                const windowHeight: number | undefined = $(window).height();
                const tooltipHeight: number = position.size.height;
                const tooltipTop: number = position.coord.top;
                const halfDivider: number = 0.5;
                if (windowHeight && (tooltipHeight + tooltipTop > windowHeight)) {
                    let newTop: number = windowHeight * halfDivider - tooltipHeight * halfDivider;
                    const topSafeOffset: number = 10;
                    if (newTop < topSafeOffset) {
                        newTop = topSafeOffset;
                    }
                    position.coord.top = newTop;
                }

                return position;
            },
            functionReady: (): void => {
                $(popupClass + '-close').on('click', (): void => {
                    tooltipsterInstance(popupClass).tooltipster('hide');
                });
            }
        });
    }

    const initTooltips = (): void => {
        initTooltipster('.tooltipster');
    }

    const animateScrollTopFromMegaMenuToOffset = (offset: DynamicDictionary | null): void => {
        $('html,body').animate(
            {scrollTop: offset ? offset.top : 0},
            scrollSpeed,
            (): void => {
                megaMenu.closeMenu();
            }
        );
    }

    const appIsPreparedAndReady = (): void => {
        initTooltips();
        Vue.nextTick((): void => {
            if (useDefine().assocArrayLength(errorDetails) > 0) {
                popup.applyErrorTitle(translations.localized(errorDetails.title));
                popup.applyErrorDetails(translations.localized(errorDetails.message));
                errorDetails = {};
                PopupService.getInstance().show(new OnePopup().withType().error)
            }
            onAppIsPreparedAndReady.next();
        });
    }

    const resetSelectedLegalEntityInSession = (): void => {
        if (isNotLegalRoute() && legalEntitySelected()) {
            user.current.selectedEntity = null;
            lockInput();
            request.post('/ajax/SelectAccount/removeEntity', {}, undefined)
                .then((): void => {
                })
                .catch((reason: DynamicDictionary): void => {
                    error.show(ErrorType.Error, 'resetSelectedLegalEntity', reason);
                });
        }
    }

    const legalEntitySelected = (): boolean => {
        return user.current.selectedEntity !== null
            && typeof user.current.selectedEntity !== 'undefined';
    }

    const isNotLegalRoute = (): boolean => {
        return !isLegalRoute();
    }

    const isLegalRoute = (): boolean => {
        return legalPersonRoutes.routes.some((route: string): boolean => currentUrl.value.includes(route));
    }

    const addCurrentUrl = (url: string): void => {
        SubmitterUrls.getInstance().applyCurrentUrl(url);
    }

    const updated = (): void => {
        new LazyLoader().init();
    }

    const addCorrelationId = (id: string): void => {
        correlationId.value = id;
    }

    const getCorrelationId = (): string => {
        return correlationId.value;
    }

    const addSettings = (json: DynamicDictionary): void => {
        settings.addSettings(json);
    }

    const getSetting = (name: string): unknown => {
        return settings.value(name);
    }

    const addLanguage = (newLanguage: string, newCountryIso: string): void => {
        translations.addLanguage(newLanguage);
        translations.addCountryIso(newCountryIso);
        address.addLanguage(newLanguage);
    }

    const addCountries = (
        newCountries: DynamicDictionary[],
        popularCountries: DynamicDictionary[],
    ): void => {
        Countries.getInstance().addCountries(newCountries, popularCountries);
    }

    const momentLocalized = (startDate: string | Date): moment.Moment => {
        const newLanguage: string = MomentBuilder.mappedLanguage();
        return moment(startDate).locale(newLanguage);
    }

    const calculateScrollbarWidth = (): void => {
        const outer: HTMLDivElement = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        document.body.appendChild(outer);
        outer.style.overflowY = 'scroll';
        const inner: HTMLDivElement = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);
        const widthOfTemporaryElement: number = 100;
        scrollbarWidth.value = widthOfTemporaryElement - inner.offsetWidth;
        outer.removeChild(inner);
        document.body.removeChild(outer);
    }

    const clearHash = (): void => {
        history.replaceState('', document.title, window.location.pathname + window.location.search);
    }

    const clearGet = (): void => {
        history.replaceState('', document.title, window.location.pathname);
    }

    const openPhone = (event: VueEvent): void => {
        const url: string = event.params['url'];
        window.open('tel:' + url, '_self');
    }

    const openMailto = (event: VueEvent): void => {
        const url: string = event.params['url'];
        window.open('mailto:' + url);
    }

    const popupInstanceTypeIs = (type: string): boolean => {
        return popupService.activePopupType === type;
    }

    const modalTypeIs = (type: string): boolean => {
        return modal.type === type;
    }

    const popupTypeIs = (type: string): boolean => {
        return popup.type === type;
    }

    const loginPopup = (): void => {
        popupService.show(new OnePopup().withType().login);
    }

    const showModal = (type: string): void => {
        modal.showModal(type);
        changeBodyVerticalScrollState();
    }

    const redirect = (event: VueEvent): void => {
        navigate(event.params.route);
    }

    const portalLocaleIso = (): string => {
        return settings.localeIso();
    }

    const prevStep = (): number => {
        const prev: number = step.value - 1;

        return prev < 0 ? 0 : prev;
    }

    const currentStep = (): number => {
        return step.value;
    }

    const nextStep = (): number => {
        return step.value + 1;
    }

    const facility = (): string => {
        return stepFacility.value;
    }

    const dataType = (): string => {
        return stepDataType.value;
    }

    const applyPopupReturn = (returnPopup: string): void => {
        popup.applyReturn(returnPopup);
    }

    const applyCommunications = (communications: string, name: string): void => {
        TwoWayCommunication.getInstance().applyCommunications(communications);
        TwoWayCommunication.getInstance().applyName(name);
    }

    const toggleOpened = (event: VueEvent): void => {
        event.sender.toggleClass(accordionOpenClassName);
    }

    const closePopup = (): void => {
        popup.showPopup(PopupType.None);
    }

    const closeModal = (): void => {
        modal.closeModal();
        changeBodyVerticalScrollState();
    }

    const reload = (): void => {
        window.location.reload();
    }

    const partialDateInitialState = (): PartialDate => {
        return new class implements PartialDate {
            public day: string = localized('btar_day');
            public month: string = localized('btar_month');
            public year: string = localized('btar_year');
        };
    }


    const isAxiosPrepared: Ref<boolean> = computed(() => {
        return axiosIsPrepared.value;
    });

    const displayAsset: Ref<boolean> = computed(() => {
        return user.current.selectedEntity === null;
    });

    const urls: Ref<typeof Urls> = computed(() => {
        return Urls;
    });

    const transform: Ref<typeof Transform> = computed(() => {
        return Transform;
    });

    const currentUser: Ref<UserCredentials> = computed(() => {
        return user.current;
    });

    const language: Ref<string> = computed(() => {
        return Translations.getInstance().language;
    });

    const countryIso: Ref<string> = computed(() => {
        return translations.countryIso;
    });

    const combinedLanguageId: Ref<string> = computed(() => {
        return translations.language + '-' + translations.countryIso;
    });

    const inputIsLocked: Ref<boolean> = computed(() => {
        return isInputLocked.value;
    });

    const applicationIsReady: Ref<boolean> = computed(() => {
        return isReady.value;
    });

    const axiosIsPending: Ref<boolean> = computed(() => {
        return pendingRequestsCount.value > 0;
    });

    const windowWidth: Ref<number> = computed(() => {
        return width.value;
    });

    const isVerticalMode: Ref<boolean> = computed(() => {
        return width.value <= BreakPoints.Sm;
    });

    const currentUrl: Ref<string> = computed(() => {
        return SubmitterUrls.getInstance().currentStep();
    });

    const currentPlatform: Ref<string> = computed(() => {
        return platform.value;
    });

    const legalRoutes: Ref<string[]> = computed(() => {
        return legalPersonRoutes.routes;
    });

    const userHasSelectedLegalEntity: Ref<boolean> = computed(() => {
        return user.current.selectedEntity !== null;
    });

    const isMobile: Ref<boolean> = computed(() => {
        return currentPlatform.value === 'device';
    });

    const isSpa: Ref<boolean> = computed(() => {
        return Object.keys(spa()).length !== 0;
    });

    const translationTypeValue: Ref<string> = computed(() => {
        return translationType.value;
    });

    watch(() =>
        [
            popup.type,
            modal.type,
            cookiesNotifications.showModule,
            megaMenu.desktopMenuIsVisible,
            PopupService.getInstance().active,
        ], () => {
        changeElementZIndexes();
        changeBodyVerticalScrollState();
    });


    return {
        userStorage,
        onExternalDataIsReady,
        onWindowResize,
        onAppIsPreparedAndReady,
        drawer,
        cookiesNotifications,
        itemsBannerVimeo,
        vueAppPageElement,
        guestQueryParam,
        error,
        partialDate,
        user,
        vehicles,
        banks,
        paymentMethods,
        translations,
        settings,
        address,
        sanitizer,
        popup,
        popupService,
        modal,
        video,
        sticky,
        offers,
        captcha,
        stepper,
        dynamicStepper,
        iframe,
        sessionTimeout,
        terms,
        libraries,
        banner,
        colorBoxes,
        itemsHorizontalSlick,
        itemsBannerLandingSlick,
        megaMenu,
        itemsBannerResizes,
        linkOnHoverService,
        showSessionTimeoutLogoffHash,
        showLoginPopupHash,
        cmsFields,
        accordionElementSelector,
        accordionOpenClassName,
        scrollSpeed,
        axiosFetchCancelToken,
        axiosFetchIsInProgress,
        axiosIsPrepared,
        isInputLocked,
        scrollbarWidth,
        pendingRequestsCount,
        width,
        height,
        errorDetails,
        guestHash,
        translationType,
        isReady,
        useStorage,
        step,
        stepFacility,
        stepDataType,
        tokenName,
        tokenHash,
        correlationId,
        urlCurrent,
        displayAsset,
        urls,
        transform,
        currentUser,
        language,
        countryIso,
        combinedLanguageId,
        inputIsLocked,
        applicationIsReady,
        axiosIsPending,
        windowWidth,
        isVerticalMode,
        currentUrl,
        currentPlatform,
        legalRoutes,
        userHasSelectedLegalEntity,
        isMobile,
        isSpa,
        translationTypeValue,
        isAxiosPrepared,
        legalPersonRoutes,
        countries,
        spaUrls,
        applyApp,
        currentApp,
        applySpa,
        spa,
        create,
        initBtaBase,
        externalDataIsReady,
        resizeWindowDebounce,
        disableCookiesNotification,
        setStep,
        setFacility,
        applyDynamicSteps,
        applySteps,
        setStorageUsage,
        addTokens,
        addBanks,
        addPaymentMethods,
        addSpaUrls,
        addVehicles,
        addUserStoreUid,
        addUser,
        addLegalRoutes,
        addFormStoragesFormUid,
        addFormStoragesJson,
        addStoragesJson,
        addCaptchaSiteKey,
        setReady,
        sessionId,
        addCurrentUrl,
        applyTranslationType,
        hideAdditionalFooter,
        isLessThan,
        localized,
        requestCallback,
        scrollToAlias,
        scrollToHash,
        scrollToAliasByName,
        openOrScroll,
        singUpOption,
        enableSwitchableField,
        errorLog,
        applyExternalValueOnModel,
        applyErrorNotification,
        lockInput,
        formattedUrl,
        axiosFetch,
        changeBodyVerticalScrollState,
        domRef,
        appendCmsFields,
        cmsFieldIsEnabled,
        updateModel,
        initPopupTooltips,
        setOffersCount,
        addPopupCloseOnHistoryBack,
        addScrollToAliasOnHashChange,
        checkExternalDataStatus,
        onExternalDataReady,
        setupResizeListener,
        scrollToModuleAlias,
        openItemById,
        reInitBannersSlick,
        showPopup,
        openUrl,
        updated,
        initSentry,
        navigate,
        vueDom,
        updateWindowDimensions,
        openUrlInNewTab,
        openUrlInExternalWindow,
        calculateScrollbarWidth,
        setupAxios,
        applyAxiosPendingCounter,
        initExternalValues,
        vueEvent,
        addPlatform,
        addCorrelationId,
        getCorrelationId,
        tooltipsterInstance,
        initTooltipster,
        addSettings,
        getSetting,
        initTooltips,
        popupTypeIs,
        addCountries,
        momentLocalized,
        addLanguage,
        animateScrollTopFromMegaMenuToOffset,
        appIsPreparedAndReady,
        resetSelectedLegalEntityInSession,
        legalEntitySelected,
        preservePopupAffectedOriginalOffset,
        popupInstanceTypeIs,
        modalTypeIs,
        clearHash,
        clearGet,
        openPhone,
        openMailto,
        loginPopup,
        showModal,
        redirect,
        portalLocaleIso,
        prevStep,
        currentStep,
        nextStep,
        facility,
        dataType,
        getAge,
        isNotLegalRoute,
        iframeReferenceCode,
        isLegalRoute,
        applyPopupReturn,
        applyCommunications,
        toggleOpened,
        closePopup,
        closeModal,
        reload,
        partialDateInitialState,
        changeElementZIndexes,
        attachAccordion,
    }
};

