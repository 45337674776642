import {StepsAssemblerParams, useStepsAssembler} from '@/Composables/StepsAssembler';

export default class StepAssemblerService {
    private static instance: StepsAssemblerParams;

    private constructor() {
    }

    public static getInstance(): StepsAssemblerParams {
        if (!StepAssemblerService.instance) {
            StepAssemblerService.instance = useStepsAssembler();
        }

        return StepAssemblerService.instance;
    }
}
