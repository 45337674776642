import StringDictionary from '@/interfaces/string.dictionary.interface';

export default class DateFormat {
    public static readonly Default: StringDictionary = {
        Long: 'DD.MM.YYYY HH:mm',
        Short: 'DD.MM.YYYY'
    };
    public static readonly Lt: StringDictionary = {
        Long: 'YYYY.MM.DD HH:mm',
        Short: 'YYYY.MM.DD'
    };
}
