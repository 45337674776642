<script setup lang="ts">
import {computed, PropType, ref, Ref} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import EventBus from '@/services/event.bus.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PolicyDetails from '@/Components/PolicyDetails/Services/PolicyDetailsService';
import PolicyState from '@/Enums/OnePolicy/PolicyStateEnum';
import Clickable from '@/Components/PolicyDetailsInvoices/Enums/ClickableEnum';
import InvoiceDocumentBlock from '@/interfaces/one_policy/invoice.document.block.interface';
import {useCore} from '@/Composables/Core';
import Invoice from '@/Components/PolicyBlock/Interfaces/InvoiceInterface';

const {translate} = useTranslate();
const {forceUpdate} = useCore();

const props = defineProps({
    content: {
        type: Object, default: () => {
            return {};
        }
    },
    clickableType: {type: String, default: ''},
    disabledTooltip: {type: String, default: ''},
    iconType: {type: String as PropType<'checkbox' | 'radio'>, default: 'checkbox'},
});
const emit = defineEmits(['invoice-click']);
const isVisibleAllBlocks: Ref<boolean> = ref(false);

function emitInvoiceClick(invoice: Invoice): void {
    return emit('invoice-click', invoice);
}

function isVisibleDocument(blockIndex: number, documentIndex: number, visible?: boolean): boolean {
    let result: boolean;
    if (visible !== undefined) {
        result = visible;
    } else {
        result = isVisibleAllBlocks.value ||
            !documentsVisibility.value.outOfBounds || blockIndex < documentsVisibility.value.block ||
            (blockIndex === documentsVisibility.value.block && documentIndex < documentsVisibility.value.document);
    }

    return result;
}

function onClickEvent(invoice: Invoice): void {
    emitInvoiceClick(invoice);
    EventBus.getInstance().emit('onPolicyDetailsDocumentClick', invoice);
}

function isDisabled(invoice: Invoice): boolean {
    return invoice.disabled! && (invoice.disabled as boolean);
}

function isHovered(invoice: Invoice): boolean {
    return invoice.hovered! && (invoice.hovered as boolean);
}

function onMouseEnter(invoice: Invoice, event: DynamicDictionary): void {
    if (isDisabled(invoice) && props.disabledTooltip !== '') {
        const target: JQuery = $(event.originalTarget || event.target);
        const scrollTop: number | undefined = $('html').scrollTop();
        if (target.offset() && scrollTop !== undefined) {
            const topOffset: number = 64;
            invoice.tooltipOffset = target.offset();
            invoice.tooltipOffset!.top -= (scrollTop + topOffset);
            invoice.hovered = true;
        }
    }
}

function onMouseLeave(invoice: Invoice): void {
    invoice.hovered = false;
    invoice.tooltipOffset = undefined;
}

function tooltipLeft(invoice: Invoice): string {
    let result: string = '';
    if (invoice.tooltipOffset) {
        result = invoice.tooltipOffset.left + 'px';
    }

    return result;
}

function tooltipBottom(invoice: Invoice): string {
    let result: string = '';
    if (invoice.tooltipOffset) {
        result = invoice.tooltipOffset.top + 'px';
    }

    return result;
}

function onProcessPaymentClick(bankLinkId: number): void {
    PolicyDetails.getInstance().processPayment(bankLinkId);
}

function showAmount(document: Invoice): boolean {
    let result: boolean = true;
    if (!document.showZeroAmount && document.amount === 0) {
        result = false;
    }

    return result;
}

function showDocumentState(document: Invoice): boolean {
    return document.coverageDate
        ? document.coverageDate.date !== ''
        : document.dueDate.date !== '';
}

function documentAmount(amount: number): string {
    return isNaN(amount) ?
        '' : Number(amount).toFixed(2) + '&nbsp;' + translate('btar_currency');
}

function documentStateClass(state: PolicyState, isPartialPayment: boolean): string {
    let result: string = '';
    switch (state) {
        case PolicyState.Unpaid:
            result = isPartialPayment ? 'state-partial' : 'state-unpaid';
            break;
        case PolicyState.Late:
            result = isPartialPayment ? 'state-partial' : 'state-late';
            break;
        case PolicyState.Pending:
            result = 'state-pending';
            break;
        case PolicyState.Changes:
            result = 'state-changes';
            break;
        default:
            result = 'state-paid';
    }

    return result;
}

function documentPayDate(document: Invoice): string {
    let prefix: string = '';
    const date: string = document.coverageDate ? document.coverageDate.date : document.dueDate.date
    switch (document.status) {
        case PolicyState.Late:
            document.partial
                ? prefix = translate('btar_dashboard_insurances_partial')
                : prefix = translate('btar_dashboard_insurances_due');
            break;
        case PolicyState.Paid:
            prefix = translate('btar_dashboard_insurances_paid');
            break;
        case PolicyState.Pending:
            prefix = translate('btar_dashboard_insurances_pending');
            break;
        case PolicyState.Changes:
            prefix = translate('btar_dashboard_insurances_changes');
            break;
        case PolicyState.Unpaid:
            if (document.partial) {
                prefix = translate('btar_dashboard_insurances_partial');
            }
            break;
        default:
            break;
    }

    return prefix + ' ' + date;
}

function onShowMoreClick(): void {
    isVisibleAllBlocks.value = !isVisibleAllBlocks.value;
}

function onShowAllInCategoryClick(blockIndex: number): void {
    if (props.content) {
        (props as DynamicDictionary).content.documentBlocks[blockIndex].opened =
            !props.content.documentBlocks[blockIndex].opened;
        props.content.documentBlocks[blockIndex].documents
            .forEach((document: Invoice, index: number): void => {
                if (index !== 0) {
                    document.visible = !document.visible;
                }
            });
    }
    forceUpdate();
}

function documentTitle(document: Invoice): string {
    let result: string = document.title;
    if (document.title === '' && document.agreementNumber !== '') {
        result = translate('policy_no') + ' ' + document.agreementNumber;
    }

    return result;
}

function due(document: Invoice): string {
    return translate('policy_due_to') + ' ' + document.dueTo;
}

function iconClasses(document: Invoice, blockIndex: number | '' = '', documentIndex: number | '' = ''): DynamicDictionary {
    const classes: DynamicDictionary = {
        'clickable': true,
        'selected': document.selected,
        'radio-icon': false,
        'checkbox-icon': false,
    };
    if (props.iconType) {
        classes[props.iconType + '-icon'] = true;
    } else {
        classes['radio-icon'] = isRadioClickable(document);
        classes['checkbox-icon'] = isCheckboxClickable(document);
    }
    classes['clickable-' + blockIndex + '-' + documentIndex] = true;

    return classes;
}

function printoutUrl(invoice: Invoice): string {
    return 'Policies/getPrintout?' + new URLSearchParams({
        type: 'PRINTOUT_INV',
        id: invoice.agreementId,
        nr: invoice.agreementNumber,
        sh: invoice.paymentNumber,
    }).toString();
}

function printoutPolicyOrDocumentUrl(agreement: Invoice): string {
    let searchParams: DynamicDictionary = {
        id: agreement.agreementId,
        nr: agreement.agreementNumber,
    };
    if (agreement.isAdjustment) {
        searchParams.type = 'PRINTOUT_ADJ';
        searchParams.adjId = agreement.id;
    } else {
        searchParams.type = 'PRINTOUT_AGR';
    }

    return 'Policies/getPrintout?' + new URLSearchParams(searchParams).toString();
}

function showClickable(invoice: Invoice): boolean {
    return props.clickableType !== '' || invoice.clickableType !== '';
}

function isCheckboxClickable(invoice: Invoice): boolean {
    return props.clickableType === Clickable.Checkbox || invoice.clickableType === Clickable.Checkbox;
}

function isRadioClickable(invoice: Invoice): boolean {
    return props.clickableType === Clickable.Radio || invoice.clickableType === Clickable.Radio;
}

function categoryOpenerText(blockIndex: number): string {
    let result: string = '';
    if (props.content) {
        result = props.content.documentBlocks[blockIndex].opened
            ? translate('show_less')
            : translate('show_more')
    }

    return result;
}

function assertPrintoutUrl(document: Invoice): string {
    let url: string = printoutUrl(document);
    if (PolicyDetails.getInstance().hasTabs) {
        switch (PolicyDetails.getInstance().activeTab) {
            case 'history':
            case 'documents':
                url = printoutPolicyOrDocumentUrl(document);
                break;
            default:
                break;
        }
    }

    return url;
}

const showMoreButtonText: Ref<string> = computed(() => {
    return isVisibleAllBlocks.value ?
        translate('show_less') : translate('show_more');
});

const hiddenBlocksArePresent: Ref<boolean> = computed(() => {
    return documentsVisibility.value.outOfBounds;
});

const showMoreButtonVisible: Ref<boolean> = computed(() => {
    let result: boolean;
    if (props.content && !!props.content.showMoreDisabled) {
        result = false;
    } else {
        result = documentsVisibility.value.outOfBounds;
    }

    return result;
});

const invoices: Ref<Invoice[]> = computed(() => {
    let result: Invoice[] = [];
    if (props.content) {
        props.content.documentBlocks.forEach((block: InvoiceDocumentBlock): void => {
            result = result.concat(block.documents);
        });
    }

    return result.flat();
});

const invoicesToPay: Ref<Invoice[]> = computed(() => {
    return invoices.value.filter((invoice: Invoice): boolean =>
        invoice.status === PolicyState.Late || invoice.status === PolicyState.Unpaid);
});

const allInvoicesPaid: Ref<boolean> = computed(() => {
    return invoicesToPay.value.length === 0;
});

const selectedInvoices: Ref<Invoice[]> = computed(() => {
    return invoices.value.filter((invoice: Invoice): boolean => invoice.selected);
});

const selectedInvoiceCount: Ref<number> = computed(() => {
    return selectedInvoices.value.length;
});

const selectedInvoicesSum: Ref<string> = computed(() => {
    return String(selectedInvoices.value
        .map((invoice: Invoice): number => invoice.amount)
        .reduce((partial: number, next: number): number => partial + next, 0)
        .toFixed(2));
});

const documentsVisibility: Ref<{ outOfBounds: boolean, block: number, document: number }> = computed(() => {
    const maxDocuments: number = 10;
    let lastBlock: number = 0;
    let lastDocument: number = 0;
    let outOfBounds: boolean = false;
    if (props.content) {
        let documentsCount: number = 0;
        props.content.documentBlocks.forEach((item: InvoiceDocumentBlock, blockIndex: number) => {
            item.documents.forEach((document: Invoice, documentIndex: number) => {
                documentsCount++;
                if (documentsCount >= maxDocuments && !outOfBounds) {
                    outOfBounds = true;
                    lastBlock = documentIndex === 0 ? blockIndex - 1 : blockIndex;
                    lastDocument = documentIndex;
                }
            });
        });
    }

    return {
        outOfBounds: outOfBounds,
        block: lastBlock,
        document: lastDocument,
    };
});

const isHistoryTab: Ref<boolean> = computed(() => {
    return PolicyDetails.getInstance().isValueCurrentActiveTab('history');
});

const isDocumentsTab: Ref<boolean> = computed(() => {
    return PolicyDetails.getInstance().isValueCurrentActiveTab('documents');
});
</script>

<template>
    <div class="policy-details-invoices"
         v-if="content">
        <div class="block-header flex">
            <h5 v-if="content.title !== ''">{{ content.title }}</h5>
            <slot name="actions"></slot>
        </div>
        <app-payment-widget class="full-width"
                            v-if="content.paymentWidget.enabled"
                            v-bind:show-all-paid="allInvoicesPaid"
                            v-bind:invoice-count="selectedInvoiceCount"
                            v-bind:amount="selectedInvoicesSum"
                            v-on:process-payment-click="onProcessPaymentClick">
        </app-payment-widget>
        <div class="documents-blocks"
             v-for="(block, blockIndex) in content.documentBlocks">
            <div class="block-date"
                 v-if="isVisibleDocument(blockIndex, 0) && block.category === ''">{{ block.dateFrom }}
            </div>
            <div class="category-header flex" v-if="block.category !== ''">
                <div class="flex align-center">
                    <h5>{{ block.category }}</h5>
                    <span class="count-badge">{{ block.documents.length }}</span>
                </div>
                <button class="button show-all-in-category"
                        v-if="block.documents.length > 1"
                        @click="onShowAllInCategoryClick(blockIndex)">
                    <span class="margin-right-small">{{ categoryOpenerText(blockIndex) }}</span>
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L4 4L1 7" stroke="#9297a0" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="block-document flex"
                 :key="documentIndex"
                 v-for="(document, documentIndex) in block.documents"
                 v-if="isVisibleDocument(blockIndex, documentIndex, document.visible)">
                <div class="document-left">
                    <div @mouseenter="onMouseEnter(document, $event)"
                         @mouseleave="onMouseLeave(document)" v-if="showClickable(document)">
                        <button v-if="showClickable(document)"
                                v-bind:class="iconClasses(document, blockIndex, documentIndex)"
                                v-bind:disabled="isDisabled(document)"
                                @click="onClickEvent(document)">
                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 5.8L4.42857 9L13 1"
                                      stroke="white"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"/>
                            </svg>
                            <div class="disabled-tooltip"
                                 v-bind:style="{left:tooltipLeft(document), top:tooltipBottom(document)}"
                                 v-if="isHovered(document)">
                                <span class="info-icon">i</span>
                                <span>{{ disabledTooltip }}</span>
                            </div>
                        </button>
                    </div>
                    <div class="document-title" v-html="documentTitle(document)" v-mask-analytics></div>
                </div>
                <div class="document-right flex">
                <span class="wide">
                    <div class="document-period"
                         v-if="!content.useBadge">{{ document.policyPeriod }}
                    </div>
                    <div class="document-amount flex"
                         v-bind:data-type="'amount-' + blockIndex + '-' +documentIndex"
                         v-bind:class="{'short-gap':document.dueDate.date !== '' || document.dueTo !== ''}"
                         v-if="showAmount(document)"
                         v-html="documentAmount(document.amount)">
                    </div>
                    <div class="document-due-to"
                         v-if="document.dueTo !== ''">{{ due(document) }}
                    </div>
                    <div class="document-state"
                         v-bind:class="documentStateClass(document.status, document.partial)"
                         v-if="showDocumentState(document) && content.useBadge">
                        {{ documentPayDate(document) }}
                    </div>
                    <app-button-with-callback
                        v-bind:class="'small document-download'"
                        v-bind="{
                            title: translate('bta_file_pdf'),
                            icon: 'arrow-right',
                            iconPosition: 'right',
                            iconColor: 'grey',
                            backgroundColorHover: 'grey',
                            url: assertPrintoutUrl(document),
                        }">
                    </app-button-with-callback>
                </span>
                    <span class="small flex">
                    <div class="document-amount flex align-center"
                         v-bind:class="{'short-gap':document.dueDate.date !== '' || document.dueTo !== ''}"
                         v-if="showAmount(document)"
                         v-html="documentAmount(document.amount)">
                    </div>
                </span>
                </div>
            </div>
        </div>
        <button class="button outside show-more"
                v-if="showMoreButtonVisible"
                @click="onShowMoreClick">{{ showMoreButtonText }}
        </button>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-invoices {
    .block-header {
        justify-content: space-between;
    }

    .category-header {
        justify-content: space-between;
        margin: var(--size-small) 0;

        .count-badge {
            font-size: var(--font-size-nano);
            margin-left: var(--size-pico);
            color: var(--black-500);
        }

        .show-all-in-category {
            font-size: var(--font-size-pico);
            align-self: flex-end;
            height: 20px;

            &:hover,
            &:active,
            &:visited,
            &:focus {
                box-shadow: none;
            }

            svg {
                transform: rotate(90deg);
            }
        }
    }

    .documents-blocks {
        .block-date {
            font-size: var(--font-size-pico);
            margin-top: var(--size-small);
            margin-bottom: 20px;
            color: var(--black-500);
        }

        .block-document {
            font-size: var(--font-size-nano);
            background-color: var(--white);
            border-radius: 8px;
            height: 80px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: var(--size-nano);
            padding: var(--size-normal);

            .document-left {
                display: flex;
                position: relative;
                align-items: center;
                gap: 12px;

                @include respond-above('sm') {
                    width: 50%;
                }

                .clickable {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid var(--component-color-border-default);
                    background-color: transparent;
                    min-width: 24px;
                    min-height: 24px;
                    cursor: pointer;

                    &.checkbox-icon {
                        border-radius: 4px;
                    }

                    &.radio-icon {
                        border-radius: 50%;
                    }

                    &.selected {
                        border-color: var(--brand-red);
                        background-color: var(--brand-red);
                    }
                }

                button:disabled {
                    border: 2px solid var(--component-color-border-default);
                    background-color: var(--black-200);

                    svg {
                        display: none;
                    }

                    .disabled-tooltip {
                        position: fixed;
                        background-color: var(--brand-blue);
                        color: var(--white);
                        border-radius: 4px;
                        padding: 13px;
                        display: flex;
                        gap: 12px;
                        z-index: 1;
                        align-items: center;
                        max-width: calc(100% - 44px);

                        @include respond-below('lg') {
                            max-width: calc(100% - 88px);
                        }

                        .info-icon {
                            background-color: var(--white);
                            color: var(--brand-blue);
                            width: 24px;
                            min-width: 24px;
                            height: 24px;
                            border-radius: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-weight: 600;
                        }
                    }
                }

                .document-title {
                    font-weight: 600;

                    @include respond-above('sm') {
                        margin-right: var(--size-small);
                    }
                }
            }

            .document-right {
                gap: 12px;

                & > .wide {
                    display: flex;
                    align-items: center;

                    .document-period {
                        color: var(--black-500);
                        margin-right: var(--size-big);
                    }

                    .document-due-to,
                    .document-amount {
                        margin-right: var(--size-big);

                        &.short-gap {
                            margin-right: var(--size-small);
                        }
                    }

                    .document-state {
                        font-weight: 600;
                        font-size: var(--font-size-pico);
                        margin-right: var(--size-small);
                        padding: 9px var(--size-nano);
                        border-radius: 8px;

                        &.state-late {
                            color: var(--brand-red);
                            background-color: var(--red-100);
                        }

                        &.state-unpaid {
                            color: var(--text-color-default);
                            background-color: var(--black-200);
                        }

                        &.state-partial {
                            color: var(--brand-red);
                            background-color: var(--black-200);
                        }

                        &.state-paid {
                            color: var(--brand-teal);
                            background-color: var(--teal-100);
                        }

                        &.state-pending {
                            color: var(--orange-500);
                            background-color: var(--orange-50);
                        }

                        &.state-changes {
                            color: var(--brand-blue);
                            background-color: var(--blue-300);
                        }
                    }
                }

                & > .small {
                    @include respond-above('sm') {
                        display: none;
                    }
                }
            }

            @include respond-below('sm') {
                flex-direction: column;
                align-items: flex-start;
                height: auto;

                .document-left {
                    margin-bottom: 20px;
                }

                .document-right {
                    width: 100%;
                    justify-content: space-between;

                    .wide {
                        .document-amount {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .show-more {
        margin-top: 30px;
        margin-bottom: var(--size-big);
    }

    .flex {
        display: flex;

        &.align-center {
            align-items: center;
        }
    }

    .margin-right-small {
        margin-right: 6px;
    }
}
</style>
