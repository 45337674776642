import AppCountry from '@/assets/libraries/app/app-country';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Url from '@/assets/libraries/url/url';
import {LimitedVariant} from '@/Types/LimitedVariantType';

export default class UrlBuilder {
    private baseUrl: string = '';
    private language: string = '';
    private uri: string = '';
    private getParams: DynamicDictionary = {};
    private fragment: string = '';
    private sessionId: string = '';
    private forceLanguage: boolean = false;

    public withLanguage(value: string): UrlBuilder {
        this.language = this.cleanLanguage(value);

        return this;
    }

    public withForcedLanguage(): UrlBuilder {
        this.forceLanguage = true;

        return this;
    }

    public withBaseUrl(value: string): UrlBuilder {
        this.baseUrl = this.cleanBaseUrl(value);

        return this;
    }

    public withUri(value: string): UrlBuilder {
        this.uri = this.cleanUri(value);

        return this;
    }

    public withGetParams(value: DynamicDictionary): UrlBuilder {
        this.getParams = value;

        return this;
    }

    public withFragment(value: string) {
        this.fragment = value;

        return this;
    }

    public withSessionId() {
        this.sessionId = new Url().queryParam('sessionId') || '';

        return this;
    }

    public build(): string {
        const result: string[] = [this.baseUrl];
        if (this.forceLanguage) {
            result.push(this.language);
        } else {
            if (this.urlLanguage()) {
                result.push(this.urlLanguage());
            }
        }
        result.push(this.uri);

        return result.join('/') + this.queryParams() + this.queryFragment();
    }

    private urlLanguage(): string {
        return (this.isDefaultLanguage() || this.isLanguageIncludedInUri())
            ? '' : this.language;
    }

    private cleanLanguage(value: string): string {
        return value.replace(/\/+/g, '');
    }

    private cleanBaseUrl(value: string): string {
        return value.replace(/\/$/g, '');
    }

    private cleanUri(value: string): string {
        return value.replace(/^\/|\/$/g, '');
    }

    private queryParams(): string {
        const params: DynamicDictionary = this.getParams;
        if (this.sessionId !== '') {
            params.sessionId = this.sessionId;
        }
        const result: string = this.objectToQueryString(params);
        const prefix: '?' | '&' = this.uri.includes('?') ? '&' : '?';

        return result ? prefix + result : '';
    }

    private isDefaultLanguage(): boolean {
        return this.language.toUpperCase() === new AppCountry().iso();
    }

    private isLanguageIncludedInUri(): boolean {
        return this.uri.includes(this.language + '/');
    }

    private queryFragment(): string {
        return this.fragment !== '' ? '#' + this.fragment : '';
    }

    private objectToQueryString(params: DynamicDictionary, parentKey?: string): string {
        return Object.keys(params)
            .map((key: string): string => {
                const queryKey: string = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);
                const value: LimitedVariant = params[key];
                return typeof value === 'object' && value !== null
                    ? this.objectToQueryString(value, key)
                    : `${queryKey}=${encodeURI(value!)}`
            })
            .join('&');
    }
}
