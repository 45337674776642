import {LimitedVariant} from '@/Types/LimitedVariantType';
import {StepsAssemblerParams, useStepsAssembler} from '@/Composables/StepsAssembler';
import Method from '@/Enums/MethodEnum';
import BaseUrl from '@/assets/libraries/url/url';
import AxiosCache from '@/services/axios.cache.service';
import Form from '@/assets/libraries/form/form';
import {SubmitParam} from '@/Types/SubmitParamType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Url from '@/Enums/UrlEnum';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {AxiosResponse} from 'axios';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupBase from '@/assets/libraries/popups/popup.base';
import {useDefine} from '@/Composables/Define';
import {useTransforms} from '@/Composables/Transforms';
import {reactive, ref, Ref} from 'vue';

export const useStepsSubmitter = (): StepsSubmitterParams => {
    const btaBase: OneBase = OneBaseService.getInstance();

    const assembler: StepsAssemblerParams = useStepsAssembler();
    const request: AxiosParams = useAxios();

    const baseUrl: BaseUrl = new BaseUrl();
    const axiosCache: AxiosCache = AxiosCache.getInstance();

    let form: UnwrapNestedRefs<Form> = reactive(new Form());
    const method: Ref<string> = ref(Method.Post);

    let additionalRequestCallback: Function | null = null;
    let ajaxResponseCallbackAfter: Function | null = null;
    let ajaxResponseCallbackBefore: Function | null = null;

    const addForm = (newForm: UnwrapNestedRefs<Form>): void => {
        form = newForm;
    }

    const applyStepUrls = (next: string, previous: string, initial: string = ''): void => {
        SubmitterUrls.getInstance().applyStepUrls(next, previous, initial);
    }

    const previousStep = (): string => {
        return SubmitterUrls.getInstance().previousStep();
    }

    const nextStep = (): string => {
        return SubmitterUrls.getInstance().nextStep();
    }

    const initialStep = (): string => {
        return SubmitterUrls.getInstance().initialStep();
    }

    const addSubmitParam = (key: string, value: SubmitParam, saveToStepStorage: boolean = false): void => {
        assembler.addParam(key, value);
        if (saveToStepStorage) {
            assembler.addCustomParam(key, value);
        }
    }

    const addSubmitParams = (value: DynamicDictionary, saveToStepStorage: boolean = false): void => {
        assembler.addParams(value);
        if (saveToStepStorage) {
            assembler.addCustomParams(value);
        }
    }

    const addSubmitFormParam = (key: string, saveToStepStorage: boolean = false): void => {
        const formValue: SubmitParam = form.field(key).value;
        assembler.addParam(key, form.field(key).value);
        if (saveToStepStorage) {
            assembler.addCustomParam(key, formValue);
        }
    }

    const addSubmitCustomParam = (key: string, value: SubmitParam): void => {
        assembler.addCustomParam(key, value);
    }

    const addSubmitCustomParams = (params: DynamicDictionary): void => {
        assembler.addCustomParams(params);
    }

    const addSubmitFormCustomParam = (key: string): void => {
        assembler.addCustomParam(key, form.field(key).value);
    }

    const clearParams = (): void => {
        assembler.clearParams();
    }

    const clearCustomParams = (): void => {
        assembler.clearCustomParams();
    }

    const clearSubmitter = (): void => {
        clearParams();
        clearCustomParams();
    }

    const submitMethod = (newMethod: string): void => {
        method.value = newMethod;
    }

    const addAjaxResponseCallbackBeforeStepsStorage = (callback: Function): void => {
        ajaxResponseCallbackBefore = callback;
    }

    const addAjaxResponseCallbackAfterStepsStorage = (callback: Function): void => {
        ajaxResponseCallbackAfter = callback;
    }

    const addAdditionalRequestCallback = (callback: Function): void => {
        additionalRequestCallback = callback;
    }

    const submitStep = (url: string): void => {
        if (!navigator.cookieEnabled && !btaBase.sessionId()) {
            btaBase.showPopup(PopupType.CookiesDisabled);
        } else {
            form.touch().then((): void => {
                if (form.isValid()) {
                    btaBase.userStorage.saveFormsPromise().then((): void => {
                        doSubmit(url);
                    }).catch((reason: DynamicDictionary): void => {
                        form.unlockInput();
                        btaBase.error.show(ErrorType.Error, 'saveFormsPromise', reason);
                    });
                }
            });
        }
    }

    const saveParamsToStorageWithStep = (targetStep: number, targetFacility?: string): Promise<void> => {
        addSubmitCustomParams(btaBase.userStorage.stepStorageData);
        const params: DynamicDictionary = {
            data: {
                uid: btaBase.userStorage.uUid,
                json: JSON.stringify({data: assembler.assembledParams()}),
                facility: targetFacility || btaBase.facility(),
                step: targetStep
            }
        };
        form.lockInput();
        PopupService.getInstance().show(new OnePopup().withType().loadingWait);

        return request.post(Url.Ajax.stepsStore, params)
            .then((): void => {
            })
            .catch((reason: DynamicDictionary): void => {
                btaBase.error.show(
                    ErrorType.Error,
                    btaBase.facility() + '::saveParamsToStorageWithStep(' + targetStep + ')',
                    reason
                );
            })
            .finally((): void => {
                form.unlockInput();
            });
    }

    const proceedStep = (customUrl: string = '', customTargetStep: number = 0): void => {
        if (!navigator.cookieEnabled && !btaBase.sessionId()) {
            btaBase.showPopup(PopupType.CookiesDisabled);
        } else {
            form.touch().then((): void => {
                if (form.isValid()) {
                    btaBase.userStorage.saveFormsPromise()
                        .then((): void => {
                            saveStepStorageAndContinue(null, customUrl, customTargetStep);
                        })
                        .catch((reason: DynamicDictionary): void => {
                            form.unlockInput();
                            btaBase.error.show(ErrorType.Error, 'saveFormsPromise', reason);
                        });
                }
            });
        }
    }

    const submitAdditionalRequest = (url: string, validateForm: boolean = false): void => {
        if (!navigator.cookieEnabled && !btaBase.sessionId()) {
            PopupService.getInstance().show(new OnePopup().withType().cookiesDisabled);
        } else {
            form.touch().then((): void => {
                if (form.isValid() || !validateForm) {
                    doSubmit(url, true);
                }
            });
        }
    }

    const getAllParams = (): DynamicDictionary => {
        return assembler.assembledParams();
    }

    const storeInitialCacheForUrl = (url: string): void => {
        const params: DynamicDictionary = {
            method: method,
            url: btaBase.formattedUrl(url),
        };
        if (method.value === Method.Post) {
            params.data = assembler.assembledParams();
        } else {
            params.params = assembler.assembledParams();
        }
        axiosCache.storeCache(params, {
            data: useTransforms().deepClonedObjectWithoutVueReactivity(btaBase.userStorage.storageData)
        });
    }

    const saveStepStorageAndContinue = (callbackParams: DynamicDictionary | null = null,
                                        customUrl: string = '',
                                        customTargetStep: number = 0): void => {
        form.lockInput();
        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
        callbackBeforeStepsStore(callbackParams);
        const params: DynamicDictionary = {
            data: {
                uid: btaBase.userStorage.uUid,
                json: JSON.stringify({data: assembler.assembledCustomParams()}),
                facility: btaBase.facility(),
                step: customTargetStep > 0 ? customTargetStep : btaBase.nextStep()
            }
        };
        request.post(Url.Ajax.stepsStore, params)
            .then((): void => {
                const result: boolean = callbackAfterStepsStore(callbackParams);
                if (!result) {
                    const url: string = customUrl === '' ? SubmitterUrls.getInstance().nextStep() : customUrl;
                    btaBase.navigate(btaBase.formattedUrl(url));
                }
            })
            .catch((reason: DynamicDictionary): void => {
                form.unlockInput();
                btaBase.error.show(
                    ErrorType.Error,
                    btaBase.facility() + '::saveStepStorageAndContinue(' + btaBase.step + ')',
                    reason
                );
            });
    }

    const doSubmit = (url: string, additional: boolean = false): void => {
        if (form.isInputLocked()) {
            return;
        }
        const params: DynamicDictionary = {
            method: method,
            url: url,
        };
        if (method.value === Method.Post) {
            params.data = assembler.assembledParams();
        } else {
            params.params = assembler.assembledParams();
        }
        const cache: DynamicDictionary = axiosCache.fetchCache(String(params)) as DynamicDictionary;
        if (additional && cache) {
            callbackOnAdditionalRequest(cache);
        } else {
            form.lockInput();
            const popupType: PopupBase = additional
                ? new OnePopup().withType().loading : new OnePopup().withType().loadingWait;
            PopupService.getInstance().show(popupType);
            const requestPromise: Function = method.value === Method.Post ? request.post : request.get;
            requestPromise(params.url, params)
                .then((value: AxiosResponse<DynamicDictionary>): void => {
                    form.unlockInput();
                    if (useDefine().validResponse(value)) {
                        if (!additional) {
                            saveStepStorageAndContinue(value.data.data);
                        } else {
                            const body: DynamicDictionary = value.data.data.body;
                            axiosCache.storeCache(params, body)
                            callbackOnAdditionalRequest(body);
                            PopupService.getInstance().hide();
                        }
                    } else {
                        btaBase.captcha.resetCaptcha();
                        throw value;
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    PopupService.getInstance().hide();
                    btaBase.captcha.resetCaptcha();
                    form.unlockInput();
                    btaBase.error.show(
                        ErrorType.Error,
                        btaBase.facility() + '::submitStep(' + btaBase.step + ')',
                        reason
                    );
                });
        }
    }

    const callbackBeforeStepsStore = (value: LimitedVariant = null): void => {
        if (ajaxResponseCallbackBefore) {
            ajaxResponseCallbackBefore(value);
        }
    }

    const callbackAfterStepsStore = (value: LimitedVariant = null): boolean => {
        if (ajaxResponseCallbackAfter) {
            ajaxResponseCallbackAfter(value);
        }

        return !!ajaxResponseCallbackAfter;
    }

    const callbackOnAdditionalRequest = (value: LimitedVariant = null): void => {
        if (additionalRequestCallback) {
            additionalRequestCallback(value);
        }
    }

    return {
        baseUrl,
        axiosCache,
        addForm,
        applyStepUrls,
        previousStep,
        nextStep,
        initialStep,
        addSubmitParam,
        addSubmitParams,
        addSubmitFormParam,
        addSubmitCustomParam,
        addSubmitCustomParams,
        addSubmitFormCustomParam,
        clearParams,
        clearCustomParams,
        clearSubmitter,
        submitMethod,
        addAjaxResponseCallbackBeforeStepsStorage,
        addAjaxResponseCallbackAfterStepsStorage,
        addAdditionalRequestCallback,
        submitStep,
        saveParamsToStorageWithStep,
        proceedStep,
        submitAdditionalRequest,
        getAllParams,
        storeInitialCacheForUrl,
    }
}

export interface StepsSubmitterParams {
    baseUrl: BaseUrl;
    axiosCache: AxiosCache;
    addForm: (newForm: UnwrapNestedRefs<Form>) => void;
    applyStepUrls: (next: string, previous: string, initial: string) => void;
    previousStep: () => string;
    nextStep: () => string;
    initialStep: () => string;
    addSubmitParam: (key: string, value: SubmitParam, saveToStepStorage: boolean) => void;
    addSubmitParams: (value: DynamicDictionary, saveToStepStorage: boolean) => void;
    addSubmitFormParam: (key: string, saveToStepStorage: boolean) => void;
    addSubmitCustomParam: (key: string, value: SubmitParam) => void;
    addSubmitCustomParams: (params: DynamicDictionary) => void;
    addSubmitFormCustomParam: (key: string) => void;
    clearParams: () => void;
    clearCustomParams: () => void;
    clearSubmitter: () => void;
    submitMethod: (newMethod: string) => void;
    addAjaxResponseCallbackBeforeStepsStorage: (callback: Function) => void;
    addAjaxResponseCallbackAfterStepsStorage: (callback: Function) => void;
    addAdditionalRequestCallback: (callback: Function) => void;
    submitStep: (url: string) => void;
    saveParamsToStorageWithStep: (targetStep: number, targetFacility?: string) => Promise<void>;
    proceedStep: (customUrl: string, customTargetStep: number) => void;
    submitAdditionalRequest: (url: string, validateForm: boolean) => void;
    getAllParams: () => DynamicDictionary;
    storeInitialCacheForUrl: (url: string) => void;
}
