import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class ClaimsValidRoutes {
    private validRoutes: boolean[] = [];

    private static instance: ClaimsValidRoutes;

    public static getInstance(): ClaimsValidRoutes {
        if (!ClaimsValidRoutes.instance) {
            ClaimsValidRoutes.instance = new ClaimsValidRoutes();
        }

        return ClaimsValidRoutes.instance;
    }

    public applyRoute(routeId: number, isValid: boolean): void {
        this.validRoutes[routeId] = isValid;
    }

    public routeIsValid(routeId: number): boolean {
        return this.validRoutes[routeId];
    }

    public routes(): DynamicDictionary {
        return {
            routes: this.validRoutes,
        }
    }
}