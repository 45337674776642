<script lang="ts">
import Vue, {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import SubmissionFormOther from '@/Modules/ClaimsSubmissionForm/SubmissionFormOther.vue';
import {VueApp} from '@/Types/VueAppType';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const claimsOtherSubmissionFormModule: VueApp = new Vue(SubmissionFormOther);

        return {
            ...btaBase,
            ...{
                claimsOtherSubmissionFormModule
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.claimsOtherSubmissionFormModule.create();
        this.claimsOtherSubmissionFormModule.beforeModuleMounted();
        this.claimsOtherSubmissionFormModule.moduleMounted();
    },
});
</script>