<script lang="ts">
import {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import Google from '@/Enums/GoogleEnum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import { useTranslate } from '@/Composables/Translate';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const serviceAddressOnMap = (address: string): string => {
            const cleanAddress: string = address.replace(',', '');
            const splitAddress: string[] = cleanAddress.split(' ');
            return Google.Maps + splitAddress.join('+');
        }

        function evacuatorButtonParamsCasco(): ButtonWithCallbackParams {
            return {
                title: useTranslate().translateForType('claims_thank_you_need_evacuator', 'claims'),
                url: useTranslate().translateForType('btar_one_header_contacts_phone_num', 'tel'),
                textColor: ButtonTextColor.Green,
                backgroundColor: ButtonBackground.White,
                backgroundColorHover: ButtonBackground.White,
                textColorHover: ButtonTextColor.Black,
                icon: ButtonIcon.Evacuator,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Transparent,
                iconWidth: 40,
            };
        }

        function evacuatorButtonParamsMtpl(): ButtonWithCallbackParams {
            return {
                title: useTranslate().translateForType('claims_thank_you_need_evacuator', 'claims'),
                url: useTranslate().translateForType('btar_one_header_contacts_phone_num', 'tel'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
                borderColor: ButtonBorder.Transparent,
            };
        }

        return {
            ...btaBase,
            ...{
                serviceAddressOnMap,
                evacuatorButtonParamsCasco,
                evacuatorButtonParamsMtpl,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
    },
});
</script>
