import Vue, {computed, nextTick, ref, Ref} from 'vue';
import VueEvent from '@/Classes/VueEventClass';
import {useDefine} from '@/Composables/Define';
import Coordinates = JQuery.Coordinates;
import OneBaseService from '@/services/OneBaseService';

const {isSet} = useDefine();

export const useScroll = (): ScrollParams => {
    const defaultScrollSpeed: number = 600;
    const lastScrollTarget: Ref<string> = ref('');

    const scrollToLast = (selector: string, scrollSpeed?: number): Promise<void> => {
        const target: JQuery = $(selector).last();
        const offset: JQuery.Coordinates | undefined = target.offset();
        return new Promise(resolve => {
            if (offset) {
                $('html,body').animate(
                    {scrollTop: offset.top},
                    scrollSpeed ?? defaultScrollSpeed,
                    (): void => {
                        resolve();
                    }
                );
            }
        });
    }

    const scrollToViewByDataScroll = (id: string, verticalOffset: number = 0): void => {
        nextTick((): void => {
            const target: JQuery = $('[data-scroll="' + id + '"]');
            const offset: JQuery.Coordinates | undefined = target.offset();
            if (offset) {
                $('html,body').animate(
                    {scrollTop: offset.top - verticalOffset},
                    300
                );
            }
        });
    }

    const scrollToView = (targetDomPath: string, block: ScrollLogicalPosition = 'start'): void => {
        Vue.nextTick((): void => {
            const target: JQuery = $(targetDomPath);
            if (target.length > 0) {
                target[0].scrollIntoView({
                    behavior: 'smooth',
                    block: block
                });
            }
        });
    }

    const scrollToId = (elementId: string): Promise<void> => {
        return new Promise(resolve => {
            const target: HTMLElement | undefined = $('#' + elementId)[0];
            if (target) {
                target.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                    }
                );
            }
            resolve();
        });
    }

    const scrollToTop = (): Promise<void> => {
        return new Promise(resolve => {
            window.scrollTo(0, 0);
            resolve();
        });
    }

    const scrollTo = (event: VueEvent): void => {
        if (isSet(event.params.scrollTarget)) {
            const targets: JQuery[] = [
                $('#' + event.params.scrollTarget),
                $(`[data-alias="${event.params.scrollTarget}"]`),
            ];
            const target: JQuery | undefined = targets.find((element: JQuery): boolean => element.length > 0);
            if (target) {
                const offsetTop = target.offset();
                $('html,body').animate({
                    scrollTop: offsetTop ? offsetTop.top : 0
                }, 0);
            }
        }
    }

    const scrollToOffset = (event: VueEvent): void => {
        if (isSet(event.params.offset)) {
            $('html,body').animate({
                scrollTop: event.params.offset
            }, defaultScrollSpeed);
        }
    }

    const scrollToDataOffset = (target: string, forced: boolean = false): void => {
        if (lastScrollTarget.value !== target || forced) {
            lastScrollTarget.value = target;
            Vue.nextTick((): void => {
                const $panel: JQuery = $('[data-scroll="' + target + '"]');
                if ($panel.length > 0) {
                    const scrollSpeed: number = 350;
                    const offset: Coordinates | undefined = $panel.offset();
                    const verticalOffset: number = offset ? offset.top - headerOffsetForScroll.value : 0;
                    if (offset && verticalOffset > 0) {
                        $('html,body')
                            .stop(true, false)
                            .animate({scrollTop: verticalOffset}, scrollSpeed);
                        const textField: JQuery = $panel.find('input[type=text]').first();
                        const textFieldInFocus: boolean = textField.is(":focus");
                        if (textField && !textFieldInFocus) {
                            textField.trigger('focus');
                        }
                    }
                }
            });
        }
    }

    const headerOffsetForScroll: Ref<number> = computed((): number => {
        const smallOffset: number = 30;
        const bigOffset: number = 130;

        return OneBaseService.getInstance().isVerticalMode.value ? smallOffset : bigOffset;
    });

    return {
        scrollToLast,
        scrollToView,
        scrollToId,
        scrollToTop,
        scrollTo,
        scrollToOffset,
        scrollToViewByDataScroll,
        scrollToDataOffset,
    }
}

export interface ScrollParams {
    scrollToLast: (selector: string, scrollSpeed?: number) => Promise<void>;
    scrollToView: (targetDomPath: string, block?: ScrollLogicalPosition) => void;
    scrollToId: (selector: string, scrollSpeed?: number) => Promise<void>;
    scrollToTop: () => Promise<void>;
    scrollTo: (event: VueEvent) => void;
    scrollToOffset: (event: VueEvent) => void;
    scrollToViewByDataScroll: (id: string, verticalOffset: number) => void;
    scrollToDataOffset: (target: string, forced: boolean) => void;
}

