<script setup lang="ts">
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import {computed, onMounted, reactive, ref, Ref, watch} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import Translations from '@/services/translations.service';
import {useTranslate} from '@/Composables/Translate';
import {useDefine} from '@/Composables/Define';
import VueRouter from 'vue-router';
import {useRouter} from 'vue-router/composables';
import FormField from '@/assets/libraries/form/form-field';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import UserCredentials from '@/interfaces/user.credentials.interface';
import User from '@/services/user.service';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import Steps from '@/Apps/SolarPanels/Enums/Steps';

const {translate, translateForType} = useTranslate();
const {isSet} = useDefine();
const form: UnwrapNestedRefs<Form> = reactive(new Form());
const router: VueRouter = useRouter();
const storage: Storage = Storage.getInstance();
const user: User = User.getInstance();
const addressFinderMode: Ref<string> = ref('mixed');

const isAuthenticatedUser: Ref<boolean> = computed(() => {
    return user.isLogged();
});

watch(() => form.field('address').value, (): void => {
    storage.fields.address = form.field('address').value;
});

onMounted(() => {
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    Translations.getInstance().addType('solar_panels_address');
    setupForm();
});

function setupForm(): void {
    form.addField(new FormField('address').addValidators('required'));
    if (isAuthenticatedUser.value && !isSet(storage.fields.address)) {
        patchUserFields();
        addressFinderMode.value = '';
    } else {
        if (isSet(storage.fields.address) && isSet(storage.fields.address!.addressCode)) {
            form.field('address').value = storage.fields.address;
            if (!isSet(form.field('address').value.apartment)
                || form.field('address').value.apartment === '') {
                addressFinderMode.value = '';
            }
        }
    }
    form.setReady();
}

function patchUserFields(): void {
    const currentUser: UserCredentials = user.current;
    form.field('address').patch({
        addressCode: currentUser.addressCode,
        addressName: currentUser.address,
        label: currentUser.address,
        value: '',
        additional: currentUser.addressExtra,
        postCode: currentUser.postCode,
    });
    storage.fields.address = form.field('address').value;
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function proceedButton(): DynamicDictionary {
    return {
        title: localized('confirm_address'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function onProceed(): void {
    updateAddress();
    router.push({name: Steps.SummaryAndPayment});
}

function updateAddress(): void {
    storage.fields.address = form.field('address').value;
    storage.fields.address!.apartment = form.field('address').value.apartment ?? '';
    storage.fields.address!.detailed = form.field('address').value.detailed ?? '';
    if (form.field('address').value.detailed !== '') {
        transformAddressLabel();
    } else {
        storage.fields.address!.label = form.field('address').value.addressName;
    }
    storage.fields.address = form.field('address').value;
}

function transformAddressLabel(): void {
    storage.fields.address!.label = [form.field('address').value.addressName, form.field('address').value.detailed].join(', ');
}

function onAddressEditClick(): void {
    addressFinderMode.value = 'mixed';
}
</script>

<template>
    <div class="container">
        <app-custom-form
            v-if="form.isReady()"
            :form="form"
            class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <h1 class="page-title">{{ localized('title') }}</h1>
            <div class="whiteboard" id="whiteboard-0">
                <app-address-finder
                    :form-field="form.field('address')"
                    :label="localized('address')"
                    :mobile-mode-enabled="true"
                    :skip-own-validation="false"
                    :skip-apartment-validation="true"
                    :reset-on-enable="true"
                    :mode="addressFinderMode"
                    @edit-click="onAddressEditClick">
                </app-address-finder>
                <app-button-with-callback class="button"
                                          v-bind="proceedButton()"
                                          :disabled="!form.field('address').isValid"
                                          v-on:button-callback-click="onProceed()">
                </app-button-with-callback>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.container {
    .whiteboard {
        button.button-with-callback.button.background-red {
            width: 100%;
            min-height: 52px;
        }
    }
}
</style>
