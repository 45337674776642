<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { UnwrapNestedRefs } from 'vue/types/v3-generated';
import { reactive } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AdditionalOptionEmitters from '@/Components/AdditionalOptionsList/Enums/AdditionalOptionEmitters';

const props = defineProps({
    formField: {type: FormField, default: () => new FormField('')},
    dataStoreDisabled: {type: Boolean, default: false},
    options: {type: Array, default: () => []},
});
const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
const emit = defineEmits([
    AdditionalOptionEmitters.Toggle,
    AdditionalOptionEmitters.Mounted,
    AdditionalOptionEmitters.Confirm,
    AdditionalOptionEmitters.Edit,
]);

function onOptionToggle(optionEmit: DynamicDictionary): void {
    emit(AdditionalOptionEmitters.Toggle, optionEmit);
}

function onOptionMounted(optionEmit: DynamicDictionary): void {
    emit(AdditionalOptionEmitters.Mounted, optionEmit);
}

function onOptionConfirm(): void {
    emit(AdditionalOptionEmitters.Confirm);
}

function onOptionCancel(): void {
    emit(AdditionalOptionEmitters.Cancel);
}

function onOptionEdit(): void {
    emit(AdditionalOptionEmitters.Edit);
}

function onOptionClick(): void {
    emit(AdditionalOptionEmitters.Click);
}

</script>

<template>
    <div class="additional-options-list"
         :id="field.name"
         :class="{...field.classes()}"
         :data-store="dataStoreDisabled ? '' : field.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(field.value)">
        <div class="list-container">
            <component
                :key="index"
                v-for="(option, index) in options"
                :is="option.component"
                :id="'additional-option-' + index"
                :option="option"
                :data-store-disabled="true"
                @option-toggle="onOptionToggle($event)"
                @option-mounted="onOptionMounted($event)"
                @option-confirm="onOptionConfirm"
                @option-cancel="onOptionCancel"
                @option-edit="onOptionEdit"
                @option-click="onOptionClick">
            </component>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.additional-options-list {
    width: 100%;

    .list-container {
        gap: var(--size-normal);
        display: flex;
        flex-direction: column;
    }
}
</style>
