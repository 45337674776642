<script setup lang="ts">

import PolicyDetails from '@/Components/PolicyDetails/Services/PolicyDetailsService';
import RenewPolicy from '@/pages/OneDashboard/Insurances/Services/RenewPolicyService';
import ClaimsUid from '@/pages/OneDashboard/Insurances/Enums/ClaimsUidEnums';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {AxiosResponse, CancelTokenSource} from 'axios';
import Error from '@/services/error.service';
import Confirm from '@/assets/libraries/popups/types/confirm';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import SubscriptionCancellationReason from '@/interfaces/one_policy/subsciption.cancelation.reason.interface';
import SettingsService from '@/services/settings.service';
import AgreementApi from '@/services/api/agreement.api.service';
import UserPolicySubscriptionCancellation from '@/interfaces/user.policy.subscription.cancellation.interface';
import BottomNotification from '@/services/bottom.notification.service';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import CardDetails from '@/Components/CreditCardDetails/CardDetails';
import AppCountry from '@/assets/libraries/app/app-country';
import AddCardParams from '@/Components/PolicyDetails/Interfaces/AddCardParamsInterface';
import {useTranslate} from '@/Composables/Translate';
import {computed, ref, Ref} from 'vue';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {useScroll} from '@/Composables/Scroll';
import InsurancesTypes from '@/pages/OneDashboard/Insurances/Enums/InsurancesTypesEnum';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import {useHtml} from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import AgreementTypes from '@/Components/PolicyBlock/Enums/AgreementTypesEnum';
import Agreement from '@/Components/PolicyBlock/Interfaces/AgreementInterface';
import ListItem from '@/Components/ItemsListWithIcons/ListItem';
import SubscriptionStatus from '@/Components/PolicyBlock/Enums/SubscriptionStatusEnum';
import AppPaymentWidget from '@/Components/PaymentWidget/PaymentWidget.vue';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import AppItemsListWithIcons from '@/Components/ItemsListWithIcons/ItemsListWithIcons.vue';
import {usePrice} from '@/Composables/Price';
import AgreementTypeMap from '@/Components/PolicyBlock/Classes/AgreementTypeMap';
import {HealthCard} from '@/interfaces/resources/health.card.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import PolicyLimit from '@/interfaces/one_policy/PolicyLimit';
import SubscriptionState from '@/Enums/OnePolicy/SubscriptionStateEnum';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import {InputOption} from '@/interfaces/InputOptionInterface';
import PopupInput from '@/Enums/PopupInputEnum';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import SubscriptionDocument from '@/Enums/OnePolicy/SubscriptionDocumentInterface';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import moment, {Moment} from 'moment';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';

const request: AxiosParams = useAxios();
const {translateForType, translate} = useTranslate();
const {scrollToView} = useScroll();
const {imgTag} = useHtml();
const {price} = usePrice();

const props = defineProps({
    translationsType: {type: String, default: 'dashboard'},
    content: {
        type: Object, default: () => {
            return {};
        }
    },
});

const fetchIsInProgress: Ref<boolean> = ref(false);
const cancelToken: Ref<CancelTokenSource | null> = ref(null);

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationsType, replacements);
}

function onProcessPaymentClick(bankLinkId: number): void {
    PolicyDetails.getInstance().processPayment(bankLinkId);
}

function onPaymentWidgetAdditionalClick(): void {
    PolicyDetails.getInstance().activateTab('invoices');
}

function statusText(status: SubscriptionState): string {
    return translateForType(status as string, props.translationsType);
}

function onRenewClick(agreement: Agreement): void {
    RenewPolicy.getInstance().onRenewPolicyClick(agreement);
}

function onSubmitClaimClick(): void {
    fetchIsInProgress.value = true;
    if (cancelToken.value) {
        cancelToken.value.cancel();
    }
    cancelToken.value = request.CancelToken.source();
    let uid: string = 'wrong-claim-page';
    switch (PolicyDetails.getInstance().policyType) {
        case AgreementTypes.Accident:
            uid = ClaimsUid.Accident;
            break;
        case AgreementTypes.Casco:
            uid = ClaimsUid.Casco;
            break;
        case AgreementTypes.Health:
            uid = ClaimsUid.Health;
            break;
        case AgreementTypes.Liability:
            uid = ClaimsUid.Liability;
            break;
        case AgreementTypes.Mtpl:
            uid = ClaimsUid.Mtpl;
            break;
        case AgreementTypes.Other:
            uid = ClaimsUid.Other;
            break;
        case AgreementTypes.Property:
            uid = ClaimsUid.Property;
            break;
        case AgreementTypes.Travel:
            uid = ClaimsUid.Travel;
            break;
    }
    const params: DynamicDictionary = {
        uid: uid
    };
    request.get(Url.Ajax.menuUrl, {cancelToken: cancelToken.value.token, params})
        .then((value: AxiosResponse) => {
            window.location.href = value.data.data.body;
        })
        .catch((reason: DirectionSetting) => {
            Error.log(ErrorType.Error, 'AppPolicyDetailsOverview::onSubmitClaimClick', reason);
        })
        .finally(() => {
            fetchIsInProgress.value = false;
        });
}

function onCancelPolicyClick(): void {
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const isUpcomingPayment: boolean = policyDetails.currentAgreement.isUpcoming;
    const isLatePayment: boolean = policyDetails.currentAgreement.payment.isLate
    if (isUpcomingPayment) {
        onSubscriptionCancellationError('description_cancel_upcoming_subscription_error');
    } else {
        const popup: Confirm = new OnePopup()
            .withType()
            .confirmPopup
            .withTitle(translated('title_cancel_subscription'))
            .withCancelButtonText(translated('button_continue_subscription'))
            .withCancelCallback(onSubscriptionCancellationCancel);
        if (isLatePayment) {
            popup
                .withDescription(translated('description_cancel_subscription__with_late_payment_error'))
                .withConfirmButtonText(translated('button_pay_late_payment_subscription'))
                .withConfirmCallback(onPayLatePayment);
        } else {
            popup
                .withDescription(translated('description_cancel_subscription'))
                .withConfirmButtonText(translated('button_cancel_subscription'))
                .withConfirmCallback(onSubscriptionCancellationConfirm);
        }
        PopupService.getInstance().show(popup);
    }
}

function onSubscriptionCancellationConfirm(): void {
    const cancellationReasons: SubscriptionCancellationReason[] = JSON.parse(SettingsService.getInstance().subscriptionCancellationReasons());
    const options: InputOption[] = cancellationReasons.map((item: SubscriptionCancellationReason) => new InputOptionBuilder()
        .setValue(item.value)
        .setName(translated(item.name))
        .build());
    PopupService.getInstance().hide().then(() => {
        const popup: Confirm = new OnePopup()
            .withType()
            .confirmPopup
            .withInput(PopupInput.CheckboxList)
            .withTitle(translated('title_cancel_subscription_reason'))
            .withOptionsInput(options)
            .withoutCancelButton()
            .withConfirmButtonText(translated('button_cancel_subscription'))
            .withConfirmCallback(onSubscriptionCancellationReasonConfirm);
        PopupService.getInstance().show(popup);
    });
}

function onPayLatePayment(): void {
    PolicyDetails.getInstance().activateTab('invoices');
 }


function onSubscriptionCancellationReasonConfirm(reasons: DynamicDictionary = {}): void {
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const subscriptionNumber: string = policyDetails.currentAgreement.subscriptionNumber ?? '';
    const subscriptionId: string = policyDetails.currentAgreement.subscriptionId ?? '';
    PopupService.getInstance().show(new OnePopup().withType().loading);
    AgreementApi.getInstance().terminateSubscription(subscriptionNumber, subscriptionId, reasons[0])
        .then((cancellation: UserPolicySubscriptionCancellation) => {
            PopupService.getInstance().hide();
            PolicyDetails.getInstance().currentAgreement.isActive = false;
            props.content!.subscriptionsBlock.statusBadges = [SubscriptionState.Inactive];
            BottomNotification.getInstance()
                .pushNotification(BottomNotificationLevel.Success,
                    translated('canceled_subscription_policy_period') + ' ' + cancellation.date
                );
        })
        .catch(() => {
            onSubscriptionCancellationError('description_cancel_subscription_error');
        });
}

function onSubscriptionCancellationError(errorMessage: string): void {
    const popup: SimpleError = new OnePopup()
        .withType()
        .simpleError
        .withDescription(translated(errorMessage));
    PopupService.getInstance().show(popup);
}

function onSubscriptionCancellationCancel(): void {
    BottomNotification.getInstance()
        .pushNotification(BottomNotificationLevel.Info, translated('info_subscription_not_canceled'));
}

function onStartRecurringPaymentsClick(): void {
    props.content!.addPaymentCardBlock.enabled = true;
    scrollToView('.add-payment-card-block');
}

function onAddPaymentCard(details: CardDetails): void {
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const bankLinkId: number = RecurringPaymentBanklink.ByCountry[(new AppCountry()).iso()];
    const LastDigits: string = cardLastDigits(details);
    PopupService.getInstance().show(new OnePopup().withType().loading);
    props.content!.addPaymentCardBlock.enabled = false;
    const params: AddCardParams = {
        banklinkId: bankLinkId,
        policyType: policyDetails.currentAgreement.type,
        agreementId: policyDetails.currentAgreement.id ?? '',
        agreementNumber: policyDetails.currentAgreement.agreementNumber ?? '',
        numberPart: LastDigits,
        extra: transformedCardDetails(details),
    };
    request.post(Url.Ajax.dashboardAddCard, params)
        .then((value: DynamicDictionary) => {
            if (value.data.data.status === 'OK') {
                PopupService.getInstance().hide();
                PolicyDetails.getInstance().currentAgreement.cardLastDigits = LastDigits;
                policyDetails.processPayment(bankLinkId, true);
            } else {
                throw value.data.errors.code;
            }
        }).catch((reason: DynamicDictionary) => {
        Error.getInstance().show(ErrorType.Error, 'AppPolicyDetailsOverview::onAddPaymentCard', reason);
    });
}

function onPurchaseOfferClick(): void {
    if (!props.content?.subscriptionsBlock.isSubscription) {
        PolicyDetails.getInstance().activateTab('invoices');
    } else {
        props.content!.addPaymentCardBlock.enabled = true;
        scrollToView('.add-payment-card-block');
    }
}

function onHealthCardChange(value: HealthCard): void {
    currentCardNumber.value = value.cardNumber;
    currentCardPeriod.value = OneDate.short(value.dateFrom) + ' - ' + OneDate.short(value.excludeDate || value.dateTo);
}

function defaultHealthCardCallback(cards: HealthCard[]): HealthCard | undefined {
    return moment(PolicyDetails.getInstance().currentAgreement.validTo.date).isAfter(moment()) ?
        activeCard(cards) :
        inactiveCard(cards);
}

function activeCard(cards: HealthCard[]): HealthCard | undefined {
    return cards.find(card => new MomentBuilder().withInitialDate(card.dateTo).build().isAfter(moment()));
}

function inactiveCard(cards: HealthCard[]): HealthCard | undefined {
    const agreement: Agreement = PolicyDetails.getInstance().currentAgreement;
    const agreementValidFrom: Moment = moment(agreement.validFrom.date);
    const agreementValidTo: Moment = moment(agreement.validTo.date);
    return cards.find(card => {
        const cardDateFrom: Moment = new MomentBuilder().withInitialDate(card.dateFrom).build();
        const cardDateTo: Moment = new MomentBuilder().withInitialDate(card.excludeDate!).build();

        return cardDateFrom.isSame(agreementValidFrom, 'day') && cardDateTo.isSame(agreementValidTo, 'day');
    });
}

function showSubscriptionCancellationButton(): boolean {
    let result: boolean = false;
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const currentAgreement: Agreement = policyDetails.currentAgreement;
    if (currentAgreement) {
        const conditions: boolean[] = [
            currentAgreement.isActive,
            currentAgreement.isSubscription,
            !!currentAgreement.holder,
            !!currentAgreement.subscriptionStatus,
            currentAgreement.subscriptionStatus === SubscriptionStatus.Approved,
        ];
        result = conditions.every(Boolean);
    }

    return result;
}

function cardLastDigits(details: CardDetails): string {
    return details.number.slice(-4);
}

function transformedCardDetails(details: CardDetails): string {
    const result: DynamicDictionary = {
        holder: details.holderName,
        number: details.number,
        expiryYear: details.validThru.year,
        expiryMonth: details.validThru.month,
        cvc: details.cvc,
    };

    return JSON.stringify(result);
}

function subscriptionStatusText(): string {
    const currentAgreement: Agreement = PolicyDetails.getInstance().currentAgreement;
    const translationPrefix: string = 'one_dashboard_subscription_status_';

    return currentAgreement.subscriptionStatus
        ? translated(translationPrefix + (currentAgreement.subscriptionStatus as string).toLowerCase())
        : '';
}

const subscriptionTitle: Ref<string> = computed(() => {
    const insuranceType: InsurancesTypes = props.content?.subscriptionsBlock.insuranceType!;
    let key: string = '';
    if (insuranceType === InsurancesTypes.Offers) {
        key = 'offer_details';
    } else if (insuranceType === InsurancesTypes.Policies &&
        props.content?.subscriptionsBlock.isSubscription
    ) {
        key = 'your_x_subscription';
    } else if (insuranceType === InsurancesTypes.Policies) {
        key = 'your_x_policy';
    }

    return translateForType(key, props.translationsType,
        {'%type%': String(props.content?.subscriptionsBlock.policyType).toUpperCase()}
    );
});

const renewPolicyButtonOptions: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translated('renew_policy'),
        backgroundColor: ButtonBackground.Green,
        textColor: ButtonTextColor.White,
    };
});

const cancelPolicyButtonOptions: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translated('cancel_policy'),
        backgroundColor: 'transparent',
        textColor: 'black',
        backgroundColorHover: 'white',
        textColorHover: 'black'
    }
});

const submitClaimButtonOptions: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translated('submit_claim'),
        backgroundColor: 'red',
        textColor: 'white',
    }
});

const payAutomaticallyButtonOptions: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translated('pay_bills_automatically'),
        backgroundColor: 'white',
        backgroundColorHover: 'white',
        textColor: 'green',
        borderColor: 'green',
    }
});

const purchaseNowOfferButtonOptions: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: translated('purchase_now'),
        backgroundColor: 'red',
        textColor: 'white',
        borderColor: 'red',
    }
});

const invoicesPaidIcon: Ref<string> = computed(() => {
    return imgTag(warningIconAttributes.value);
});

const invoicesAutoPayIcon: Ref<string> = computed(() => {
    return imgTag(autoPayIconAttributes.value);
});

const isHolder: Ref<boolean> = computed(() => {
    return PolicyDetails.getInstance().currentAgreement ?
        !!PolicyDetails.getInstance().currentAgreement.holder : false;
});

const hasMultipleHealthCards: Ref<boolean> = computed(() => {
    return props.content.subscriptionsBlock.healthCards.length > 1;
});

const hasSingleHealthCard: Ref<boolean> = computed(() => {
    return props.content.subscriptionsBlock.healthCards.length === 1;
});

const firstCardNumber: Ref<string> = computed(() => {
    return props.content.subscriptionsBlock.healthCards[0].cardNumber;
});

const currentCardNumber: Ref<string> = ref('');
const currentCardPeriod: Ref<string> = ref('');

const cardLimits: Ref<PolicyLimit[]> = computed(() => {
    let result: PolicyLimit[] = [];
    const agreementCards: HealthCard[] =  props.content.subscriptionsBlock.healthCards;
    const limitSource: HealthCard | undefined = hasMultipleHealthCards.value
        ? props.content.subscriptionsBlock.healthCards
            .find((card: HealthCard): boolean => card.cardNumber === currentCardNumber.value)
        : agreementCards[0];
    if (limitSource) {
        result = limitSource.limitChunks || [];
    }

    return result;
});

const isHealthAgreement: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (PolicyDetails.getInstance().currentAgreement) {
        const agreementTypeId: string = PolicyDetails.getInstance().currentAgreement.typeId;
        result = new AgreementTypeMap().isHealthAgreement(agreementTypeId);
    }

    return result;
});

const hasLimits: Ref<boolean> = computed(() => {
    return cardLimits.value.length > 0;
});

const subscriptionsDocuments: Ref<ListItem[]> = computed(() => {
    const policyDetails: PolicyDetails = PolicyDetails.getInstance();
    const result: ListItem[] = [];
    props.content?.subscriptionsBlock.subscriptionDocument.forEach((document: SubscriptionDocument): void => {
        const documentNamePrefix: string = policyDetails.currentAgreement.type + '_';
        result.push(new class implements ListItem {
            name: string = documentNamePrefix + document.title;
            url: string = document.url;
            icon: string = 'document';
            extensionName?: string = document.extension;
        });
    });
    if (isHealthAgreement.value) {
        const printoutSource: HealthCard | undefined = hasMultipleHealthCards.value
            ? props.content.subscriptionsBlock.healthCards
                .find((card: HealthCard): boolean => card.cardNumber === currentCardNumber.value)
            : props.content.subscriptionsBlock.healthCards[0];
        if (printoutSource) {
            printoutSource.printouts!.forEach((document: SubscriptionDocument): void => {
                const documentNamePrefix: string = policyDetails.currentAgreement.type + '_';
                result.unshift(new class implements ListItem {
                    name: string = documentNamePrefix + document.title;
                    url: string = document.url;
                    icon: string = 'document';
                    extensionName?: string = document.extension;
                });
            });
        }
    }

    return result;
});

const upcomingDocuments: Ref<ListItem[]> = computed(() => {
    const result: ListItem[] = [];
    props.content?.upcomingPolicyBlock.subscriptionDocument.forEach((document: SubscriptionDocument) => {
        result.push(new class implements ListItem {
            name: string = document.title;
            url: string = document.url;
            icon: string = 'document';
            extensionName?: string = document.extension;
        })
    });

    return result;
});

const policyNoText: Ref<string> = computed(() => {
    let result: string = 'policy_no';
    const policyDetailsAgreement: Agreement = PolicyDetails.getInstance().currentAgreement;
    if (isOffer.value) {
        result = 'offer_no';
    } else if (isMtpl.value && policyDetailsAgreement && policyDetailsAgreement.isSubscription) {
        result = 'active_policy_no';
        if (policyDetailsAgreement.isUpcoming) {
            result = 'upcoming_subscription_policy_no';
        } else if (policyDetailsAgreement.isActive) {
            result = 'active_subscription_policy_no';
        } else if (!policyDetailsAgreement.isActive) {
            result = 'inactive_subscription_policy_no';
        }
    }

    return translated(result);
});

const insuredPeriodText: Ref<string> = computed(() => {
    let result: string = 'insured_period';
    if (isSubscription.value) {
        const policyDetailsAgreement: Agreement = PolicyDetails.getInstance().currentAgreement;
        if (policyDetailsAgreement.isUpcoming) {
            result = 'upcoming_subscription_period';
        } else if (policyDetailsAgreement.isActive) {
            result = 'active_subscription_period';
        } else if (!policyDetailsAgreement.isActive && policyDetailsAgreement.type !== InsurancesTypes.Offers) {
            result = 'inactive_subscription_period';
        } else if (policyDetailsAgreement.isEndingSoon || policyDetailsAgreement.isRenewed) {
            result = 'subscription_period';
        } else {
            result = 'btar_one_dashboard_valid_from';
        }
    }

    return translated(result);
});

const isMtpl: Ref<boolean> = computed(() => {
    return PolicyDetails.getInstance().policyType === AgreementTypes.Mtpl;
});

const isCasco: Ref<boolean> = computed(() => {
    return PolicyDetails.getInstance().policyType === AgreementTypes.Casco;
});

const warningIconAttributes: Ref<ImageTagAttributes> = computed(() => {
    const source: string = 'images/one/circle-exclamation-mark.svg';
    return {
        class: '',
        src: source,
        width: 24,
        height: 24,
    }
});

const invoicesPaidIconAttributes: Ref<ImageTagAttributes> = computed(() => {
    const source: string = 'images/one/smiley-background.svg';
    return {
        class: '',
        src: source,
        width: 24,
        height: 24,
    }
});

const autoPayIconAttributes: Ref<ImageTagAttributes> = computed(() => {
    const source: string = 'images/one/auto-pay.svg';
    return {
        class: '',
        src: source,
        width: 52,
        height: 52,
    }
});

const isOffer: Ref<boolean> = computed(() => {
    return props.content?.subscriptionsBlock.insuranceType === InsurancesTypes.Offers;
});

const isSubscription: Ref<boolean> = computed(() => {
    return props.content?.subscriptionsBlock.isSubscription!;
});
</script>

<template>
    <div class="policy-details-overview">
        <app-payment-widget
            class="overview-block"
            v-if="content.paymentWidget.enabled"
            :show-payment-methods="false"
            :show-pay-button="false"
            :invoice-count="content.paymentWidget.invoiceCount"
            :amount="content.paymentWidget.totalAmount"
            :additional-button-text="content.paymentWidget.additionalButtonText"
            @process-payment-click="onProcessPaymentClick"
            @payment-widget-additional="onPaymentWidgetAdditionalClick">
        </app-payment-widget>
        <div class="overview-block renew-block"
             v-if="content.renewBlock.enabled">
            <div class="header header-with-border">
                <div class="icon" v-html="invoicesPaidIcon"></div>
                <div class="title">{{ translated('policy_ending_soon') }}</div>
            </div>
            <div class="message" v-html="content.renewBlock.message"></div>
            <app-button-with-callback
                class="big"
                v-bind="renewPolicyButtonOptions"
                @button-callback-click="onRenewClick(content.renewBlock.agreement)"></app-button-with-callback>
        </div>
        <div class="overview-block subscriptions-block"
             v-if="content.subscriptionsBlock.enabled">
            <div class="header header-with-badges">
                <div class="title">{{ subscriptionTitle }}</div>
                <div class="badges">
                    <div class="status-badge"
                         :key="index"
                         v-for="(status, index) in content.subscriptionsBlock.statusBadges"
                         :class="String(status).replaceAll('_', '-')"
                    >{{ statusText(status) }}
                    </div>
                </div>
            </div>
            <div class="details-block">
                <div class="details-line"
                     v-if="content.subscriptionsBlock.policyId">
                    <span>{{ policyNoText }}</span>
                    <span class="right-part">{{ content.subscriptionsBlock.policyId }}</span>
                </div>
                <div class="details-line"
                     v-if="isHealthAgreement && hasSingleHealthCard">
                    <span>{{ translated('health_card_number') }}</span>
                    <span class="right-part">{{ firstCardNumber }}</span>
                </div>
                <div class="details-line"
                     v-if="content.subscriptionsBlock.insuredPeriod">
                    <span>{{ insuredPeriodText }}</span>
                    <span class="right-part">{{ content.subscriptionsBlock.insuredPeriod }}</span>
                </div>
                <div class="details-line column"
                     v-if="isHealthAgreement && hasMultipleHealthCards">
                    <div class="grey-line extra-margins"></div>
                    <div class="header small-margin">
                        <div class="title">{{ translated('my_health_cards') }}</div>
                    </div>
                    <app-health-cards-list
                        :mode="'radio'"
                        :health-cards="content.subscriptionsBlock.healthCards"
                        :default-card-callback="defaultHealthCardCallback"
                        @radio-card-change="onHealthCardChange($event)">
                    </app-health-cards-list>
                </div>
                <div class="details-line"
                     v-if="isHealthAgreement && hasMultipleHealthCards">
                    <span>{{ translated('health_card_number') }}</span>
                    <span class="right-part">{{ currentCardNumber }}</span>
                </div>
                <div class="details-line"
                     v-if="isHealthAgreement && hasMultipleHealthCards">
                    <span>{{ translated('health_card_period') }}</span>
                    <span class="right-part">{{ currentCardPeriod }}</span>
                </div>
                <div class="details-line"
                     v-if="content.subscriptionsBlock.subscriptionStatus">
                    <span>{{ translated('one_dashboard_subscription_status') }}</span>
                    <span class="right-part">{{ subscriptionStatusText() }}</span>
                </div>
                <div class="details-line"
                     v-if="content.subscriptionsBlock.payments && !isSubscription && !content.subscriptionsBlock.isBrokerPayment">
                    <span>{{ translated('payments', translationsType) }}</span>
                    <span class="right-part">{{ content.subscriptionsBlock.payments }}</span>
                </div>
                <div class="grey-line" v-if="isOffer"></div>
                <div class="details-line"
                     v-if="content.subscriptionsBlock.premium && !content.subscriptionsBlock.isBrokerPayment">
                    <span
                        :class="{'tiny': isOffer}">{{
                            translated('premium', translationsType)
                        }}</span>
                    <span class="right-part" v-bind:class="{'red': isOffer}">
                    {{ price(content.subscriptionsBlock.premium, false) }}
                    {{ ' ' + translate('btar_currency') }}
                    {{ isSubscription && isOffer ? ' / ' + translated('monthly') : '' }}
                  </span>
                </div>
            </div>
            <div class="broker" v-if="content.subscriptionsBlock.isBrokerPayment">
                <div class="icon" v-html="invoicesPaidIcon"></div>
                <span>{{ translated('broker_payment', translationsType) }}</span>
            </div>
            <app-items-list-with-icons
                v-if="isHolder || isHealthAgreement"
                :list="subscriptionsDocuments">
            </app-items-list-with-icons>
            <div class="offer-purchase-btn" v-if="isOffer">
                <app-button-with-callback
                    class="big"
                    v-bind="purchaseNowOfferButtonOptions"
                    @button-callback-click="onPurchaseOfferClick">
                </app-button-with-callback>
            </div>
        </div>
        <div class="overview-block upcoming-policy-block"
             v-if="content.upcomingPolicyBlock.enabled">
            <div class="header">
                <div class="title">{{ translated('title_upcoming_policy', translationsType) }}
                </div>
            </div>
            <div class="details-block">
                <div class="details-line"
                     v-if="content.upcomingPolicyBlock.policyId">
                    <span>{{ translated('policy_no', translationsType) }}</span>
                    <span class="right-part">{{ content.upcomingPolicyBlock.policyId }}</span>
                </div>
                <div class="details-line"
                     v-if="content.upcomingPolicyBlock.insuredPeriod">
                <span>{{ translated('insured_period', translationsType) }}
                </span>
                    <span class="right-part">{{ content.upcomingPolicyBlock.insuredPeriod }}</span>
                </div>
                <div class="details-line"
                     v-if="content.upcomingPolicyBlock.price">
                    <span>{{ translated('price', translationsType) }}</span>
                    <span class="right-part">
                    {{ price(content.upcomingPolicyBlock.price, false) }}
                    {{ ' ' + translate('btar_currency') }}
                </span>
                </div>
            </div>
            <app-items-list-with-icons
                v-if="!content.subscriptionsBlock.isBrokerPayment"
                :list="upcomingDocuments">
            </app-items-list-with-icons>
        </div>
        <div class="overview-block auto-pay-block"
             v-if="content.autoPayBlock.enabled && !content.addPaymentCardBlock.enabled">
            <div class="header">
                <div class="icon" v-html="invoicesAutoPayIcon"></div>
            </div>
            <div class="content">
                <div class="message" v-html="translated('no_overdue', this.translationsType)"></div>
                <app-button-with-callback
                    class="big"
                    v-bind="payAutomaticallyButtonOptions"
                    @button-callback-click="onStartRecurringPaymentsClick"></app-button-with-callback>
            </div>
        </div>
        <div class="overview-block add-payment-card-block"
             v-if="content.addPaymentCardBlock.enabled">
            <app-credit-card-widget
                :class="'full-width no-shadow'"
                :type="'add'"
                @card-widget-submit="onAddPaymentCard"
            ></app-credit-card-widget>
        </div>
        <div class="overview-block insured-risks-block"
             v-if="content.insuredRisksBlock.enabled">
            <div class="header">
                <div class="title">{{ translated('insured_risks', this.translationsType) }}</div>
            </div>
            <app-insured-risks
                :content="content.insuredRisksBlock.risks"
                :hide-fraction="true"
            ></app-insured-risks>
        </div>
        <div class="overview-block policy-limits-block"
             v-if="isHealthAgreement && hasLimits">
            <div class="header">
                <div class="title">{{ translated('policy_limits', this.translationsType) }}</div>
            </div>
            <app-policy-limits
                :content="cardLimits"
                :hide-fraction="false"
            ></app-policy-limits>
        </div>
        <div class="overview-block actions-block"
             v-if="content.actionsBlock.enabled">
            <div class="header">
                <div class="title">{{ translated('actions', this.translationsType) }}</div>
            </div>
            <div class="buttons">
                <app-button-with-callback
                    class="big"
                    v-bind="submitClaimButtonOptions"
                    @button-callback-click="onSubmitClaimClick"></app-button-with-callback>
                <app-button-with-callback
                    v-if="showSubscriptionCancellationButton()"
                    class="big"
                    v-bind="cancelPolicyButtonOptions"
                    @button-callback-click="onCancelPolicyClick"></app-button-with-callback>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-overview {
    .overview-block {
        width: 100%;
        padding: var(--size-normal);
        border-radius: 16px;
        background-color: var(--white);
        box-shadow: none;
        margin-bottom: 20px;

        .header {
            display: flex;
            align-items: center;
            margin-bottom: var(--size-big);

            &.header-with-border {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--black-300);
            }

            &.small-margin {
                margin-bottom: 20px;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
                max-width: 50%;
            }
        }

        &.renew-block {
            .icon {
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                margin-right: 22px;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: var(--red-100);
                    width: 44px;
                    height: 44px;
                    border-radius: 22px;
                }
            }

            .message {
                font-weight: 500;
                font-size: var(--font-size-tiny);
                line-height: var(--line-height-accented);
                color: var(--text-color-default);
                margin-bottom: 28px;
            }

            button {
                width: 100%;
            }
        }

        &.subscriptions-block {
            .header-with-badges {
                justify-content: space-between;
                align-items: flex-start;
                gap: 20px;

                .badges {
                    display: flex;
                    gap: 5px;
                    max-width: 50%;
                    justify-content: flex-end;
                    flex-wrap: wrap;

                    .status-badge {
                        padding: 8px var(--size-nano);
                        height: 32px;
                        border-radius: 8px;
                        font-size: var(--font-size-pico);

                        &.active {
                            color: var(--brand-teal);
                            background-color: var(--teal-50);
                        }

                        &.ending-soon {
                            color: var(--brand-red);
                            border: 1px solid var(--brand-red);
                        }

                        &.inactive {
                            color: var(--black-500);
                            background-color: var(--black-200);
                        }

                        &.upcoming {
                            color: var(--brand-teal);
                            background-color: var(--teal-50);
                        }
                    }
                }

                @include respond-below('xs') {
                    flex-direction: column;

                    .badges {
                        max-width: 100%;
                        justify-content: flex-start;
                    }
                }
            }

            .details-block {
                margin-bottom: 25px;

                .details-line {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    font-size: var(--font-size-nano);

                    &.column {
                        flex-direction: column;
                    }

                    :last-child {
                        margin-bottom: 0;
                    }

                    .right-part {
                        font-weight: 600;
                        text-align: right;
                    }

                    .red {
                        color: var(--brand-red);
                        font-size: var(--font-size-small);
                        align-self: baseline;
                    }

                    .tiny {
                        font-size: var(--font-size-tiny);
                        align-self: baseline;
                    }
                }

                .grey-line {
                    border-top: solid 1px var(--black-50);
                    margin-bottom: 20px;

                    &.extra-margins {
                        margin-top: var(--size-small);
                        margin-bottom: var(--size-big);
                    }
                }
            }

            .offer-purchase-btn {
                margin-top: var(--size-small);

                .button-with-callback {
                    width: 100%;

                    &:focus,
                    &:hover {
                        background-color: var(--red-700);
                        color: var(--white);
                    }
                }
            }

            .broker {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 14px;
                gap: 12px;
                width: 100%;
                height: 52px;
                background: var(--background-light);
                border-radius: 8px;
                font-size: var(--font-size-nano);
                font-weight: 600;

                .icon {
                    rotate: 180deg;
                    filter: var(--to-color-black);
                }
            }

            ::v-deep .items-list-with-icons {
                .list {
                    grid-template-columns: 1fr 1fr;
                    gap: 20px;

                    .list-item {
                        .list-item-content {
                            max-height: 52px;

                            .left-part {
                                align-items: center;
                            }
                        }
                    }

                    @include respond-below('xs') {
                        grid-template-columns: 1fr;
                    }
                }
            }
        }

        &.upcoming-policy-block {
            .details-block {
                margin-bottom: 25px;

                .details-line {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;
                    font-size: var(--font-size-nano);

                    :last-child {
                        margin-bottom: 0;
                    }

                    .right-part {
                        font-weight: 600;
                    }

                    .red {
                        color: var(--brand-red);
                        font-size: var(--font-size-small);
                        align-self: baseline;
                    }

                    .tiny {
                        font-size: var(--font-size-tiny);
                        align-self: baseline;
                    }
                }
            }
        }

        &.auto-pay-block {
            .content {
                display: flex;
                justify-content: space-between;
                gap: 20px;

                button,
                .message {
                    width: 50%;
                }

                .message {
                    padding: 0 var(--size-big) 0 0;
                    font-weight: 700;
                    font-size: var(--font-size-small);
                }

                @include respond-below('xs') {
                    flex-direction: column;

                    button,
                    .message {
                        width: auto;
                    }

                    .message {
                        padding: 0;
                    }
                }
            }
        }

        &.insured-risks-block {
            padding-bottom: var(--size-small);

            .header {
                margin-bottom: 30px;
            }
        }

        &.policy-limits-block {
            padding-bottom: var(--size-small);
        }

        &.actions-block {
            .buttons {
                display: flex;
                gap: 20px;
                justify-content: space-between;

                button {
                    width: 100%;
                }

                @include respond-below('xs') {
                    button {
                        width: auto;
                    }
                    flex-direction: column;
                }
            }
        }
    }
}
</style>
