import Vue from 'vue'
import iframeResize from 'iframe-resizer/js/iframeResizer';


export default Vue.directive('iframe-resize', {
    bind(el: HTMLElement, {value = {}}): void {
        el.addEventListener('load', () => iframeResize(value, el))
    },

    unbind(el: HTMLElement, {value = {}}): void {
        el.removeEventListener('load', () => iframeResize(value, el))
    }
});
