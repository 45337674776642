import moment, {Moment} from 'moment';
import 'moment/locale/lv';
import 'moment/locale/lt';
import 'moment/locale/et';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import SettingsService from '@/services/settings.service';
import Translations from '@/services/translations.service';
import OneDate from '@/assets/libraries/Date/OneDate';

export default class MomentBuilder {
    private initialDate: string | Date = '';
    private locale: string = '';

    public withInitialDate(value: string | Date): MomentBuilder {
        this.initialDate = value;

        return this;
    }

    public withOneLocale(): MomentBuilder {
        this.locale = MomentBuilder.mappedLocale();

        return this;
    }

    public withPageLocale(): MomentBuilder {
        this.locale = MomentBuilder.mappedLanguage();

        return this;
    }

    public build(): Moment {
        return moment(this.initialDate, OneDate.supportedInitialFormats(), 'en')
            .locale(this.locale);
    }

    public static mappedLocale(): string {
        const result: string = SettingsService.getInstance().localeIso().toLowerCase();

        return result === 'ee' ? 'et' : result;
    }

    public static mappedLanguage(): string {
        const result: string = Translations.getInstance().language;

        return result === 'ee' ? 'et' : result;
    }
}
