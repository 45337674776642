<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { UnwrapNestedRefs } from 'vue/types/v3-generated';
import { computed, nextTick, PropType, reactive, Ref, watch } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import MovablePropertyDeviceOptionEmitters
    from '@/Components/OptionsFieldList/Enums/MovablePropertyDeviceOptionEmitters';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import { useTranslate } from '@/Composables/Translate';

const props = defineProps({
    formField: {type: FormField, default: () => new FormField('')},
    dataStoreDisabled: {type: Boolean, default: false},
    options: {type: Array as PropType<DynamicDictionary[]>, default: () => []},
    description: {type: String, default: ''},
});
const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
const {translate} = useTranslate();
const emit = defineEmits([
    MovablePropertyDeviceOptionEmitters.Toggle,
    MovablePropertyDeviceOptionEmitters.Input,
    MovablePropertyDeviceOptionEmitters.Continue,
]);

const deviceField: UnwrapNestedRefs<FormField> = reactive(props.options.length > 0
    ? props.options[0].field
    : new FormField('blank'));

const continueIsDisabled: Ref<boolean> = computed(() => {
    return hasEnabledOption()
        ? !deviceField.isValid
        : true;
});

watch(() => props.options, () => {
    if (currentEnabledOption()) {
        nextTick().then((): void => {
            Object.assign(deviceField, currentEnabledOption().field)
        });
    }
});

function onListContinue(): void {
    emit(MovablePropertyDeviceOptionEmitters.Continue);
}

function onOptionToggle(optionEmit: DynamicDictionary): void {
    let returnEmit: DynamicDictionary = {};
    props.options.forEach((option: DynamicDictionary): void => {
        returnEmit[option.device.optionIc] = {
            state: optionEmit[option.device.optionIc] ? optionEmit[option.device.optionIc].state : false,
            value: '',
        }
    });
    emit(MovablePropertyDeviceOptionEmitters.Toggle, returnEmit);
}

function onOptionFieldInput(): void {
    let returnEmit: DynamicDictionary = {};
    props.options.forEach((option: DynamicDictionary): void => {
        returnEmit[option.device.optionIc] = {
            state: option.enabled.value,
            value: currentEnabledOption().device.optionIc === option.device.optionIc
            ? deviceField.value : '',
        }
    });
    emit(MovablePropertyDeviceOptionEmitters.Input, returnEmit);
}

function currentEnabledOption(): DynamicDictionary {
    return props.options
        .filter((option: DynamicDictionary): boolean => option.enabled.value)[0];
}

function hasEnabledOption(): boolean {
    return props.options
        .filter((option: DynamicDictionary): boolean => option.enabled.value)
        .length > 0;
}

function hasHint(): boolean {
    return hasEnabledOption()
        ? currentEnabledOption().hint !== ''
        : false;
}

function optionHint(): string {
    return currentEnabledOption().fieldHint;
}

function optionLabel(): string {
    return currentEnabledOption().fieldLabel;
}

function optionPlaceholder(): string {
    return currentEnabledOption().fieldPlaceholder;
}

function continueButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_continue'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}

</script>

<template>
    <div class="options-field-list"
         :id="field.name"
         :class="{...field.classes()}"
         :data-store="dataStoreDisabled ? '' : field.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(field.value)">
        <div class="list-container">
            <component
                :key="index"
                v-for="(option, index) in options"
                :is="option.component"
                :id="'list-option-' + index"
                :option="option"
                :data-store-disabled="true"
                @device-option-toggle="onOptionToggle($event)">
            </component>
        </div>
        <app-input-text class="option-field"
                        v-if="hasEnabledOption()"
                        :data-store-disabled="true"
                        :label="optionLabel()"
                        :placeholder="optionPlaceholder()"
                        :form-field="deviceField"
                        :disable-error-text="true"
                        @input="onOptionFieldInput()"></app-input-text>
        <div class="field-hint"
             v-if="hasHint()"
             v-html="optionHint()"></div>
        <div class="description" v-if="description && !deviceField.isValid" v-html="description"></div>
        <app-button-with-callback
            v-bind="continueButtonParams()"
            :disabled="continueIsDisabled"
            @button-callback-click="onListContinue()">
        </app-button-with-callback>
    </div>
</template>

<style lang="scss" scoped>
.options-field-list {
    width: 100%;

    .list-container {
        gap: var(--size-normal);
        display: flex;
        flex-direction: column;
    }

    .option-field {
        width: 100%;
        margin-top: var(--size-medium);
    }

    .field-hint {
        margin-top: var(--size-pico);
        color: var(--text-color-subtlest);
        display: flex;
        gap: var(--size-nano);
        font-size: var(--font-size-nano);
    }

    .description {
        margin-top: var(--size-small);
        font-size: var(--font-size-nano);
        color: var(--text-color-subtlest);

        @include respond-above('sm') {
            align-self: flex-start;
        }
    }

    .button-with-callback {
        height: 52px;
        width: 100%;
        margin-top: var(--size-small);

        @include respond-above('sm') {
            width: 168px;
            align-self: flex-start;
        }
    }

    @include respond-above('sm') {
        width: 440px;
    }
}
</style>
