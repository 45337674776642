import {Auto, useAuto} from '@/pages/Auto/Composables/Auto';

export default class AutoService {
    private static instance: Auto;

    private constructor() {
    }

    public static getInstance(): Auto {
        if (!AutoService.instance) {
            AutoService.instance = useAuto();
        }

        return AutoService.instance;
    }
}
