<script lang="ts">
import Vue, {defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import {VueApp} from '@/Types/VueAppType';
import MobileUploadModule from '@/Modules/MobileUpload/MobileUploadModule.vue';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const mobileUploadModule: VueApp = new Vue(MobileUploadModule);

        const mobileUpload: Ref<boolean> = ref(true);
        const reference: Ref<string> = ref('');


        const setReference = (): void => {
            reference.value = window.location.href.split(/[/s,]+/).pop()!;
        }

        return {
            ...btaBase,
            ...{
                mobileUploadModule,
                mobileUpload,
                reference,
                setReference
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setReference();

        this.mobileUploadModule.create();
        this.mobileUploadModule.beforeModuleMounted();
        this.mobileUploadModule.moduleMounted();
    },
});
</script>