import {Subject} from 'rxjs';
import {BankInstitution} from '@/interfaces/resources/bank.institution.interface';

export default class Banks {
    private static instance: Banks;
    private banks: BankInstitution[] = [];
    public ready: boolean = false;
    public onExternalDataIsReady = new Subject<void>();

    private constructor() {
    }

    public static getInstance(): Banks {
        if (!Banks.instance) {
            Banks.instance = new Banks();
        }
        return Banks.instance;
    }

    public addBanks(banks: BankInstitution[]): void {
        this.banks = banks;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public bankInstitutions(): BankInstitution[] {
        return this.banks;
    }
}
