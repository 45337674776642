import CreditCardType from '@/interfaces/credit.card.type.interface';
import AppEnvironment from '@/assets/libraries/app/app-environment';
import {useStrings} from '@/Composables/Strings';

export default class CreditCardValidator {
    private static readonly CardHolderNameMinLength: number = 5;
    private static readonly CardHolderNameMaxLength: number = 50;

    public isValidCreditCardNumber(cardType: CreditCardType, cardNumber: string): boolean {
        let isValidCardNumber: boolean = false;
        const cleanCardNumber: string = cardNumber.toString().replace(/[^\d]/g, '');
        if (cardType.numberDigits.includes(cleanCardNumber.length)) {
            let checksum: number = 0
            let isEven: boolean = false;
            for (let i: number = cleanCardNumber.length - 1; i >= 0; i--) {
                let controlDigit: number = parseInt(cleanCardNumber.charAt(i), 10);
                const controlDigitTarget: number = 9;
                if (isEven && (controlDigit *= 2) > controlDigitTarget) {
                    controlDigit -= controlDigitTarget;
                }
                checksum += controlDigit;
                isEven = !isEven;
            }
            if (this.needToUseWhitelist() && cardType.whitelistedNumbers.includes(cleanCardNumber)) {
                isValidCardNumber = cardType.pattern.test(cleanCardNumber);
            } else {
                const checksumDivider: number = 10;
                isValidCardNumber = (checksum % checksumDivider) === 0 && cardType.pattern.test(cleanCardNumber);
            }
        }

        return isValidCardNumber;
    }

    public isValidCardHolderName(value: string): boolean {
        const regex: RegExp = /^(?:[-'`~.\p{Script=Latin}]+ ?){1,4}$/gu;

        return regex.test(value) && this.valueIsWithinLimits(value);
    }

    private valueIsWithinLimits(value: string): boolean {
        const multibyteLength: number = useStrings().multibyteLength(value);
        return multibyteLength >= CreditCardValidator.CardHolderNameMinLength
            && multibyteLength <= CreditCardValidator.CardHolderNameMaxLength
    }

    private needToUseWhitelist(): boolean {
        return !(new AppEnvironment()).isProduction();
    }
}
