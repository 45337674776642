import {LimitedVariant} from '@/Types/LimitedVariantType';

export default abstract class AnyAbstract {
    [key: string]: any;

    public memberValueByName(member: string): LimitedVariant {
        let result: LimitedVariant = null;
        if (Object.prototype.hasOwnProperty.call(this, member)) {
            result = this[member];
        }

        return result;
    }
}
