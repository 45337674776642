<script setup lang="ts">

import { computed, PropType, Ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/assets/libraries/form/form-field';

const {translate} = useTranslate();

const props = defineProps({
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    count: {type: Number, default: 0},
    manual: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    detailsId: {type: String, default: ''},
});
const emit = defineEmits(['show-details', 'refresh-status']);

function onShowDetailsClick(): void {
    emit('show-details', props.detailsId);
}

function onRefreshStatusClick(): void {
    emit('refresh-status', props.detailsId);
}

const processingTitleTranslated: Ref<string> = computed(() => {
    let stringId: string = 'processing_title';
    if (props.manual) {
        stringId += '_manual';
    }

    return translate(stringId, {'%count%': props.count});
});

const processingDescription: Ref<string> = computed(() => {
    let stringId: string = 'processing';
    if (props.manual) {
        stringId += '_manual';
    }

    return translate(stringId);
});

const showDetailsText: Ref<string> = computed(() => {
    return translate('show_details');
});

const refreshStatusText: Ref<string> = computed(() => {
    return translate('refresh_status');
});

const showDetailBackgroundColor: Ref<string> = computed(() => {
    return !props.manual ? 'light-grey' : 'orange';
});

const showDetailTextColor: Ref<string> = computed(() => {
    return !props.manual ? 'black' : 'white';
});
</script>

<template>
    <div class="processing"
         v-bind:id="formField.name"
         v-bind:class="{...formField.classes(), 'disabled': disabled}">
        <div class="wrapper">
            <div class="left-block">
                <img src="images/one/components/processing/processing.svg"
                     alt="processing"
                     width="30"
                     height="30">
                <div class="title">{{ processingTitleTranslated }}</div>
                <div class="description"
                     v-if="processingDescription !== ''">{{ processingDescription }}
                </div>
            </div>
            <div class="buttons">
                <app-button-with-callback
                    v-bind="{
                    title: showDetailsText,
                    backgroundColor: showDetailBackgroundColor,
                    backgroundColorHover: showDetailBackgroundColor,
                    textColor: showDetailTextColor
                }"
                    v-on:button-callback-click="onShowDetailsClick"
                ></app-button-with-callback>
                <app-button-with-callback
                    v-if="!manual"
                    v-bind="{
                        title: refreshStatusText,
                        backgroundColor:'orange',
                        backgroundColorHover:'orange',
                        textColor: 'white'
                    }"
                    v-on:button-callback-click="onRefreshStatusClick"
                ></app-button-with-callback>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.processing {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .wrapper {
        display: flex;
        align-items: center;
        height: 72px;
        background-color: var(--component-color-background-base);
        border-radius: 8px;
        padding: 0 var(--size-small);
        justify-content: space-between;
        transition: all .3s ease-in;
        overflow: hidden;

        .left-block {
            display: flex;
            align-items: center;

            img {
                margin-right: var(--size-small);

                @include infinite-rotation;
            }

            .title {
                margin-right: var(--size-nano);
            }

            .description {
                font-size: var(--font-size-nano);
                color: var(--black-400);
                margin-top: 2px;
            }
        }

        .buttons {
            .button-with-callback {
                font-size: var(--font-size-nano);
                min-width: 115px;
                height: 32px;
                margin-left: var(--size-nano);
            }
        }

        @include respond-below('sm') {
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 10px;
            height: auto;

            .left-block {
                margin-bottom: 10px;
            }
        }

        &-closed {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
</style>
