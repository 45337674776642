<script setup lang="ts">
    import {computed, ComputedRef, PropType, reactive} from 'vue';
    import FormField from '@/assets/libraries/form/form-field';
    import {UnwrapNestedRefs} from 'vue/types/v3-generated';

    const props = defineProps({
        formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
        dataStoreDisabled: {type: Boolean, default: false},
        label: {type: String, default: ''},
        placeholder: {type: String, default: ''},
        rows: {type: Number, default: 5},
        disabled: {type: Boolean, default: false},
        required: {type: Boolean, default: false},
    });
    const emit = defineEmits(['change']);
    const field: UnwrapNestedRefs<FormField<string>> = reactive(props.formField);
    const fieldId: ComputedRef<string> = computed(() => {
        return props.formField.name + '-textArea';
    });

    function onKeyUp(): void {
        props.formField.touch();
        props.formField.validate();
        emitChange();
    }

    function onChange(): void {
        props.formField.touch();
        props.formField.validate();
        emitChange();
    }

    function emitChange(): void {
        emit('change', props.formField.value);
    }

</script>
<template>
    <div class="input input-textarea"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : `${formField.value}`">
        <div class="label informative" v-if="label">
            <label v-bind:for="fieldId">
                {{ label }}<span v-if="required" class="asterisk">&#42;</span>
            </label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
        <textarea
            :id="fieldId"
            v-model="field.value"
            :name="formField.name"
            :disabled="disabled"
            :rows="rows"
            :placeholder="placeholder"
            @change="onChange"
            @keyup="onKeyUp">
        </textarea>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.input-textarea {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .wrapper {
        display: flex;
        height: auto;

        textarea {
            width: 100%;
            padding: var(--size-small) 30px var(--size-small) var(--size-small);
            background: var(--white);
            border: 1px solid var(--black-200);
            border-radius: 3px;
            font-weight: 500;
            font-size: var(--font-size-tiny);
            color: var(--text-color-default);
            mix-blend-mode: normal;
            font-family: var(--text-font-stack);
            resize: none;

            &::placeholder {
                opacity: .56;
                color: var(--black-500);
            }

            &:hover {
                @include input-hover;
            }

            &:focus {
                @include input-focus;
            }
        }
    }

    &.disabled {
        > .wrapper::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: .08;
            background: var(--black-500);
            border-radius: 3px;
        }

        textarea {
            -webkit-text-fill-color: var(--black-500);
            opacity: 1;
            border-color: transparent;
        }
    }

    &.invalid.touched textarea {
        border-color: var(--brand-red);
        outline-color: var(--brand-red);
        background-color: var(--red-50);

        &:focus {
          border-color: transparent;
          background-color: inherit;
        }
    }
}
</style>
