export default class ButtonBackground {
    public static readonly White: string = 'white';
    public static readonly Black: string = 'black';
    public static readonly Grey: string = 'grey';
    public static readonly LightGrey: string = 'light-grey';
    public static readonly Green: string = 'green';
    public static readonly DarkGreen: string = 'dark-green';
    public static readonly Red: string = 'red';
    public static readonly Blue: string = 'blue';
    public static readonly Orange: string = 'orange';
    public static readonly Transparent: string = 'transparent';
}
