import BottomNotificationMessage from '@/interfaces/bottom.notification.message.interface';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';

export default class BottomNotification {
    private static instance: BottomNotification;
    private ready: boolean = false;
    private notifications: BottomNotificationMessage[] = [];

    public static getInstance(): BottomNotification {
        if (!BottomNotification.instance) {
            BottomNotification.instance = new BottomNotification();
        }

        return BottomNotification.instance;
    }

    public setReady(): void {
        toastr.options = {
            'closeButton': false,
            'debug': false,
            'newestOnTop': false,
            'progressBar': false,
            'positionClass': 'toast-bottom-center',
            'preventDuplicates': true,
            'onclick': null,
            'showDuration': '300',
            'hideDuration': '1000',
            'timeOut': '5000',
            'extendedTimeOut': '1000',
            'showEasing': 'swing',
            'hideEasing': 'linear',
            'showMethod': 'fadeIn',
            'hideMethod': 'fadeOut'
        };
        this.ready = true;
        this.show();
    }

    public pushNotification(level: string, message: string): void {
        if (this.ready) {
            this.notifications.push(
                new class implements BottomNotificationMessage {
                    public level: string = level;
                    public message: string = message;
                }
            );
            this.show();
        } else {
            console.debug('[toastr] not initialized yet');
        }
    }

    private show(): void {
        if (this.ready && this.notifications.length > 0) {
            this.notifications.forEach((item: BottomNotificationMessage) => {
                switch (item.level) {
                    case BottomNotificationLevel.Info:
                        toastr.info(item.message)
                        break;
                    case BottomNotificationLevel.Warning:
                        toastr.warning(item.message)
                        break;
                    case BottomNotificationLevel.Error:
                        toastr.error(item.message)
                        break;
                    default:
                        toastr.success(item.message);
                }
            });
            this.notifications = [];
        }
    }
}
