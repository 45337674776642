export const useUrl = (): UrlParams => {
    const guestQueryParam: string = 'guest';

    const clearGet = (): void => {
        history.replaceState('', document.title, window.location.pathname);
    }

    const clearHash = (): void => {
        history.replaceState('', document.title, window.location.pathname + window.location.search);
    }

    const queryParams = (): URLSearchParams => {
        return new URLSearchParams(window.location.search);
    }

    const queryParam = (key: string): string|null => {
        return queryParams().get(key);
    }

    const hasQueryParam = (key: string): boolean => {
        return queryParams().has(key);
    }

    const withQueryParam = (name: string, value: string, url: string): string => {
        const urlInstance: URL = new URL(url);
        const params: URLSearchParams = new URLSearchParams(urlInstance.search);
        if (params.has(name)) {
            params.delete(name);
        }
        params.append(name, value);
        urlInstance.search = params.toString();

        return urlInstance.toString();
    }

    const appendQueryParam = (name: string, value: string, url: string = window.location.href): void => {
        history.replaceState('', document.title, withQueryParam(name, value, url).toString());
    }

    const guestModeEnabled = (): boolean => {
        return hasQueryParam(guestQueryParam) && queryParam(guestQueryParam) === 'true';
    }

    const guestModeDisabled = (): boolean => {
        return !guestModeEnabled();
    }

    const applyGuestMode = (): void => {
        appendQueryParam(guestQueryParam, 'true');
    }

    const guestParam = (): string => {
        return guestQueryParam;
    }

    return {
        clearGet,
        clearHash,
        queryParams,
        queryParam,
        withQueryParam,
        hasQueryParam,
        guestModeEnabled,
        guestModeDisabled,
        applyGuestMode,
        guestParam,
    }
}

export interface UrlParams {
    clearGet: () => void;
    clearHash: () => void;
    queryParams: () => URLSearchParams;
    queryParam: (key: string) => string|null;
    hasQueryParam: (key: string) => boolean;
    withQueryParam: (name: string, value: string, url: string) => string;
    guestModeEnabled: () => boolean;
    guestModeDisabled: () => boolean;
    applyGuestMode: () => void;
    guestParam: () => string;
}
