export default class CompanyValidator {

    public isValidCompanyNumber(value: string, localeIso: string): boolean {
        let isValid: boolean = false;
        const companyRegistrationNumberPatternEE: RegExp = /^(\1|)[1789]{1}[0-9]{2,7}(?:x.+)?$/;
        const companyRegistrationNumberPatternLT: RegExp = /^[1-9](?:\d{6}|\d{8})$/;
        const companyRegistrationNumberPatternLV: RegExp = /^[3459][0-9]{10}?$/;
        switch (localeIso) {
            case 'EE':
                isValid = companyRegistrationNumberPatternEE.test(value);
                break;
            case 'LT':
                isValid = companyRegistrationNumberPatternLT.test(value);
                break;
            case 'LV':
                isValid = companyRegistrationNumberPatternLV.test(value);
                break;
            default:
                throw new Error('Unknown localeIso: ' + localeIso);
        }
        return isValid;
    }
}

