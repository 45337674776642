<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import {computed, watch, onMounted, reactive, Ref} from 'vue';
    import Banks from '@/services/banks.service';
    import {BankInstitution} from '@/interfaces/resources/bank.institution.interface';
    import {UnwrapNestedRefs} from 'vue/types/v3-generated';
    import {InputOption} from '@/interfaces/InputOptionInterface';
    import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';

    const props = defineProps({
        skipOwnValidation: {type: Boolean, default: false, required: false},
        formField: {type: FormField, default: () => new FormField('')},
        disabled: {type: Boolean, default: false},
        placeholder: {type: String, default: () => ''},
        label: {type: String, default: ''},
        dataStoreDisabled: {type: Boolean, default: false},
    });
    const emit = defineEmits(['change']);
    const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
    const banksService: Banks = Banks.getInstance();
    const bankIdFormField: UnwrapNestedRefs<FormField> = reactive(new FormField('bankId'));
    const banks: Ref<BankInstitution[]> = computed(() => banksService.bankInstitutions());
    const options: Ref< InputOption[]> = computed(() => {
      return banks.value.map((bank: BankInstitution) => {
        return new InputOptionBuilder().setValue(bank.id.toString()).setName(bank.name).build();
      });
    });

    watch(() => field.value, (newValue) => {
          if (field.value.id.toString() !== bankIdFormField.value) {
            bankIdFormField.patch(newValue.id.toString());
          }
          field.validate();
          emitChange();
        }
    );

    watch(() => props.skipOwnValidation, (newSkipOwnValidation) => {
        if (!newSkipOwnValidation) {
          applyChildFieldValidators();
        }
        field.validate().then();
    });

    onMounted((): void => {
        if (banksService.ready) {
            init();
        } else {
            banksService.onExternalDataIsReady.subscribe(() => {
                init();
            });
        }
    });

    function onSelectedBankChange(bankId: string): void {
      field.value = banks.value.find((bank: BankInstitution) => bank.id.toString() === bankId);
    }

    function init(): void {
        if (!props.skipOwnValidation) {
            applyChildFieldValidators();
        }
        if (!field.isEmpty()) {
            bankIdFormField.patch(field.value.id.toString());
        }
    }

    function applyChildFieldValidators(): void {
        field.addValidators('required');
    }

    function emitChange(): void {
      field.touch();
      field.sanitize();
      field.validate();
      emit('change', field.value);
    }
</script>

<template>
    <div class="input input-bank"
         :id="field.name"
         :class="field.classes()"
         :data-store="dataStoreDisabled ? '' : field.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(field.value)">
        <div class="wrapper select">
            <app-input-select
                :options="options"
                :data-store-disabled="true"
                :placeholder="placeholder"
                :label="label"
                :form-field="bankIdFormField"
                @change="onSelectedBankChange">
                <template v-slot:app-tooltipster>
                    <slot name="app-tooltipster"></slot>
                </template>
            </app-input-select>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-bank {
  width: 100%;
  scroll-margin-top: 4em;

  @include respond-above('md') {
    width: 350px;
  }

  > .wrapper {
    height: 100%;
  }
}
</style>
