import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {useModel} from '@/Composables/Model';

export default class VueModel {
    public static callMethodByName(
        methodName: string | Function,
        context: DynamicDictionary | null = null,
        params: LimitedVariant = null
    ): LimitedVariant {
        return useModel().callMethodByName(methodName, context, params);
    }

    public static modelValueByName(model: string | Function, context: DynamicDictionary | null = null): LimitedVariant {
        return useModel().modelValueByName(model, context);
    }

    public static setModelValue(modelName: string, value: LimitedVariant): void {
        useModel().setModelValue(modelName, value);
    }
}
