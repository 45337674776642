import LibraryBlock from '@/interfaces/library.block.interface';

export default class Library {
    public static readonly Branches: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = [];
        public js: string[] = [
            '/branches/offices.js',
        ];
    };
    public static readonly Iban: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = [];
        public js: string[] = ['/iban/iban.js'];
    };
    public static readonly Path: string = '/vue/libraries';
    public static readonly Plugins: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = [
            '/tooltipster/dist/css/tooltipster.bundle.min.css',
            '/tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min.css',
        ];
        public js: string[] = [
            '/tooltipster/dist/js/tooltipster.bundle.min.js',
            '/datepicker/datepicker.min.js',
            '/iframe-resizer/js/iframeResizer.contentWindow.min.js',
        ];
    };
    public static readonly Slick: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = [];
        public js: string[] = ['/slick/slick.min.js'];
    };
    public static readonly Toastr: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = [
            '/toastr/toastr.min.css',
            '/toastr/toastr.one.css',
        ];
        public js: string[] = ['/toastr/toastr.min.js'];
    }
    public static readonly AgGrid: LibraryBlock = new class implements LibraryBlock {
        public css: string[] = ['/ag-grid/ag-grid.min.css'];
        public js: string[] = ['/ag-grid/ag-grid.min.js'];
    }
}
