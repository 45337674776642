export default class PolicyDetailsTabs {
    public static readonly Overview: string = 'overview';
    public static readonly Invoices: string = 'invoices';
    public static readonly Documents: string = 'documents';
    public static readonly History: string = 'history';
    public static readonly SubscriptionHistory: string = 'subscription_history';
    public static readonly Objects: string = 'objects';
    public static readonly Card: string = 'card';
    public static readonly Persons: string = 'persons';
}
