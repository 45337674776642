import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import InsurancesUid from '@/pages/OneDashboard/Insurances/Enums/InsurancesUidEnums';
import AppCountry from '@/assets/libraries/app/app-country';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import Agreement from '@/Components/PolicyBlock/Interfaces/AgreementInterface';
import AgreementTypeMap from '@/Components/PolicyBlock/Classes/AgreementTypeMap';

export default class RenewPolicy {
    private static instance: RenewPolicy;
    private renewNowUrls: StringDictionary = {};
    private language: string = '';

    private constructor() {
    }

    public static getInstance(): RenewPolicy {
        if (!RenewPolicy.instance) {
            RenewPolicy.instance = new RenewPolicy();
        }

        return RenewPolicy.instance;
    }

    public applyLanguage(language: string): void {
        this.language = language;
    }

    public applyRenewUrls(renewNowUrls: StringDictionary): void {
        this.renewNowUrls = renewNowUrls;
    }

    public onRenewPolicyClick(agreement: Agreement): void {
        let redirectUrl: string = '';
        let params: DynamicDictionary = {};
        let fragment: string = '';
        const agreementTypeMap: AgreementTypeMap = new AgreementTypeMap();
        const getInTouch: string = 'get-in-touch';
        switch (true) {
            case agreementTypeMap.isTravelAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Travel];
                break;
            case agreementTypeMap.isTransportAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Transport];
                params = this.transportParams(agreement.objectName);
                break;
            case agreementTypeMap.isPropertyAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Property];
                if (!new AppCountry().isEE()) {
                    fragment = getInTouch;
                }
                break;
            case agreementTypeMap.isHealthAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Health];
                fragment = getInTouch;
                break;
            case agreementTypeMap.isAccidentAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Accident];
                if (!new AppCountry().isLV()) {
                    fragment = getInTouch;
                }
                break;
            case agreementTypeMap.isLiabilityAgreement(agreement.typeId):
                redirectUrl = this.renewNowUrls[InsurancesUid.Liability];
                break;
            default:
                redirectUrl = this.renewNowUrls[InsurancesUid.All];
        }
        this.navigateWithParams(redirectUrl, params, fragment);
    }

    private transportParams(objectData: string): DynamicDictionary {
        return {plateNumber: this.retrievePlateNumber(objectData)};
    }

    private retrievePlateNumber(data: string): string {
        return data.split(' ').pop() ?? '';
    }

    private retrieveRegistrationNumber(data: string): string {
        return data.split(', ')[0];
    }

    private navigateWithParams(url: string, params: DynamicDictionary, fragment: string): void {
        window.location.href = new UrlBuilder()
            .withLanguage(this.language)
            .withUri(url)
            .withGetParams(params)
            .withFragment(fragment)
            .build();
    }
}
