<script lang="ts">
import Vue, {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {VueApp} from '@/Types/VueAppType';
import ContractualInstitutions from '@/Modules/ContractualInstitutions/ContractualInstitutions.vue';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();


        const contractualInstitutionsModule: VueApp = new Vue(ContractualInstitutions);


        return {
            ...btaBase,
            ...{
                contractualInstitutionsModule,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.contractualInstitutionsModule.create();
        this.contractualInstitutionsModule.beforeModuleMounted();
        this.contractualInstitutionsModule.moduleMounted();
    }
});
</script>
