<script setup lang="ts">
import {onMounted, ref, Ref} from 'vue';
import Url from '@/assets/libraries/url/url';
import {useTranslate} from '@/Composables/Translate';

const props = defineProps({
    total: {type: Number, default: 0},
    perPage: {type: Number, default: 1},
});
const emit = defineEmits(['change']);
const {translate} = useTranslate();

let pages: Ref<number[]> = ref([]);
let currentPage: Ref<number> = ref(1);
let numberOfPagesAround: number = 3;

onMounted(() => {
    setCurrentPage();
    makePages();
});

function select(page: number): void {
    emitChange(page);
}

function emitChange(page: number): number {
    return page;
}

function prev(): void {
    const page = currentPage.value - 1;
    select(page);
    window.location.href = urlForPage(page);
}

function next(): void {
    const page = currentPage.value + 1;
    select(page);
    window.location.href = urlForPage(page);
}

function urlForPage(page: number): string {
    const url = new Url();
    let urlForPage = '';
    if (page > 1) {
        url.setQueryParam('page', String(page));
        urlForPage = url.pathWithParams()
    } else {
        url.deleteQueryParam('page');
        urlForPage = window.location.pathname;
    }
    return urlForPage;
}

function totalPages(): number {
    return Math.ceil((props.total || 1) / props.perPage) || 1;
}

function isSeparatorRequiredAfterPage(pageIndex: number): boolean {
    const currentPage = pages.value[pageIndex];
    const nextPage = pages.value[pageIndex + 1];
    return !!nextPage && nextPage - currentPage > 1;
}

function setCurrentPage(): void {
    currentPage.value = Number((new Url()).queryParam('page', '1'));
    if (currentPage.value < 1) {
        currentPage.value = 1;
    }
    if (currentPage.value > totalPages()) {
        currentPage.value = totalPages();
    }
}

function makePages(): void {
    const tempPages: number[] = [];
    const pagesEachSide: number = pagesOnEachSide();
    tempPages.push(1);
    tempPages.push(totalPages());
    for (let i = currentPage.value - pagesEachSide; i <= currentPage.value + pagesEachSide; i++) {
        if (i > 0 && i <= totalPages()) {
            tempPages.push(i);
        }
    }
    pages.value = tempPages.filter((page, pos) => tempPages.indexOf(page) === pos).sort((a, b) => a - b);
}

function pagesOnEachSide(): number {
    let pagesEachSide = Math.ceil((numberOfPagesAround - 1) / 2);
    if (currentPage.value - pagesEachSide <= 0) {
        pagesEachSide += pagesEachSide - currentPage.value + 1;
    }
    if (currentPage.value + pagesEachSide > totalPages()) {
        pagesEachSide += (currentPage.value + pagesEachSide) - totalPages();
    }

    return pagesEachSide;
}
</script>

<template>
    <div class="paginator">
        <button class="paginator-item paginator-prev"
                @click="prev()"
                :aria-label="translate('paginator_previous')"
                :disabled="currentPage === 1">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1 7L7 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
        <template v-for="(page, index) in pages">
            <div v-if="page === currentPage"
                 class="paginator-item paginator-current">
                {{ page }}
            </div>
            <a v-if="page !== currentPage"
               class="paginator-item"
               :href="urlForPage(page)"
               @click="select(page)">
                {{ page }}
            </a>
            <div v-if="isSeparatorRequiredAfterPage(index)" class="paginator-item paginator-separator">...</div>
        </template>
        <button class="paginator-item paginator-next"
                @click="next()"
                :aria-label="translate('paginator_next')"
                :disabled="currentPage === totalPages()">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 7L1 13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.paginator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    > .paginator-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        flex-grow: 0;
        flex-shrink: 0;
        color: var(--black-500);
        stroke: var(--brand-red);
        font-size: var(--font-size-pico);
        border-radius: 50%;
        text-decoration: none;

        &.paginator-current {
            background-color: var(--brand-red);
            color: var(--white);
        }

        &.paginator-prev {
            display: none;
            margin-right: 15px;

            @include respond-above('xs') {
                display: flex;
                margin-right: var(--size-big);
            }
        }

        &.paginator-next {
            display: none;
            margin-left: 15px;

            @include respond-above('xs') {
                display: flex;
                margin-left: var(--size-big);
            }
        }

        &:disabled {
            stroke: var(--black-500);
            opacity: .4;
            cursor: auto;
        }

        &:not(.paginator-separator):not(:disabled):not(.paginator-current):hover {
            color: var(--brand-red);
            cursor: pointer;
        }
    }
}
</style>
