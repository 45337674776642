import ModalType from '@/Enums/ModalTypeEnum';
import {Subject} from 'rxjs';
import Vue from 'vue';
import OneBaseService from '@/services/OneBaseService';

export default class Modal {
    public readonly VisibleModal: string = '.single-modal:visible';
    public readonly ModalWrapper: string = '.wrapper';
    public onBeforeClose: Subject<string> = new Subject<string>();
    private static instance: Modal;
    private modalType: string = ModalType.None;
    private hasCloseButton: boolean = true;

    public static getInstance(): Modal {
        if (!Modal.instance) {
            Modal.instance = new Modal();
        }
        return Modal.instance;
    }

    public disableClose(disabled: boolean): Modal {
        this.hasCloseButton = !disabled;

        return this;
    }

    public showModal(type: string): void {
        this.onBeforeClose.next(this.modalType);
        this.modalType = type;
        Vue.nextTick(() => {
            this.focusModal();
        });
    }

    public closeModal(): void {
        this.onBeforeClose.next(this.modalType);
        this.modalType = ModalType.None;
        OneBaseService.getInstance().changeBodyVerticalScrollState();
    }

    public get type(): string {
        return this.modalType;
    }

    private focusModal(): void {
        if (this.modalType !== ModalType.None) {
            Vue.nextTick(() => {
                const element: HTMLElement = $(this.VisibleModal).find(this.ModalWrapper)[0];
                if (element !== undefined && element !== null) {
                    $(element).focus();
                }
            });
        }
    }
}
