export const useStrings = (): StringsParams => {
    const diacriticString = (value: string): string => {
        return value.toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '');
    }

    const transformedToSnakeCase = (value: string): string => {
        let result: string = value;
        const match: RegExpMatchArray | null = value
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
        if (match) {
            result = match.map(x => x.toLowerCase()).join('_');
        }

        return result;
    }

    const capitalize = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    const capitalizeAll = (value: string): string => {
        return value.split(' ')
            .map((fragment: string): string => capitalize(fragment))
            .join(' ');
    }

    const replaceAll = (haystack: string, needle: string, replace: string): string => {
        return haystack.replace(new RegExp(needle, 'g'), replace);
    }

    const multibyteLength = (value: string): number => {
        return new TextEncoder().encode(value).length;
    }

    const patternMatches = (value: string, pattern: RegExp): RegExpMatchArray | [] => {
        const matches: RegExpMatchArray | null = value.match(pattern);

        return matches ?? [];
    }

    const nonWordCharactersLength = (value: string): number => {
        const pattern: RegExp = /[^\p{Script=Cyrillic}|\p{Script=Latin}\w]/gu;

        return patternMatches(value, pattern).length;
    }

    const wordCharactersLength = (value: string): number => {
        const pattern: RegExp = /[\p{Script=Cyrillic}|\p{Script=Latin}\w]/gu;

        return patternMatches(value, pattern).length;
    }

    const isValidWordString = (value: string): boolean => {
        return wordCharactersLength(value) > nonWordCharactersLength(value);
    }

    const camelToSnakeCase = (camel: string): string => {
        const upperChars: RegExpMatchArray | null = camel.match(/([A-Z])/g);
        if (!upperChars) {
            return camel;
        }
        let snake: string = camel.toString();
        const n: number = upperChars.length;
        for (let i: number = 0; i < n; i++) {
            snake = snake.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase());
        }
        if (snake.slice(0, 1) === '_') {
            snake = snake.slice(1);
        }

        return snake;
    }

    return {
        diacriticString,
        transformedToSnakeCase,
        capitalize,
        capitalizeAll,
        replaceAll,
        multibyteLength,
        nonWordCharactersLength,
        wordCharactersLength,
        isValidWordString,
        camelToSnakeCase,
    }
}

export interface StringsParams {
    diacriticString: (value: string) => string;
    transformedToSnakeCase: (value: string) => string;
    capitalize: (value: string) => string;
    capitalizeAll: (value: string) => string;
    replaceAll: (haystack: string, needle: string, replace: string) => string;
    multibyteLength: (value: string) => number;
    nonWordCharactersLength: (value: string) => number;
    wordCharactersLength: (value: string) => number;
    isValidWordString: (value: string) => boolean;
    camelToSnakeCase: (camel: string) => string;
}
