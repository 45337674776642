<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, ComputedRef, onMounted, reactive, Ref, ref, watch } from 'vue';
import AppInputText from '@/Components/InputText/InputText.vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import AppCountry from '@/assets/libraries/app/app-country';
import { SanitizerCallback } from '@/Types/SanitizerCallbackType';
import SanitizersMap from '@/Components/InputIdentityNumber/Classes/SanitizersMap';

const props = defineProps({
    placeholder: {type: String, default: () => ''},
    formField: {type: FormField, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    country: {type: String, default: ''},
});

const emit = defineEmits(['change', 'input']);
const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
const countryIso: Ref<string> = ref('');

const currentCountry: ComputedRef<string> = computed((): string => {
    return props.country ? props.country : countryIso.value;
});

watch(() => props.country, (): void => {
    applyFieldSanitizer();
});

const currentSanitizer: ComputedRef<SanitizerCallback> = computed((): SanitizerCallback => {
    return SanitizersMap.byCountryIso()[currentCountry.value] ?? SanitizersMap.default();
});

onMounted((): void => {
    init();
    if (!field.isEmpty()) {
        onChange();
    }
});

function init(): void {
    applyDefaultCountryIso();
    applyFieldSanitizer();
}

function applyFieldSanitizer(): void {
    field.clearSanitizer();
    field.addSanitizer(currentSanitizer.value);
}

function applyDefaultCountryIso(): void {
    countryIso.value = new AppCountry().iso();
}

function onKeyUp(): void {
    onChange();
}

function onInput(): void {
    emit('input', field.value);
}

function onChange(): void {
    field.touch();
    field.sanitize();
    field.validate().then(() => {
        if (field.isEmpty()) {
            field.markAsUntouched();
        }
    });
    emit('change', field.value);
}

</script>

<template>
    <app-input-text
        :class="'input-identity-number'"
        :required="required"
        :name="formField.name"
        :form-field="formField"
        :label="label"
        :autocomplete="'disabled'"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-store-disabled="dataStoreDisabled"
        :disable-error-text="disableErrorText"
        @input="onInput"
        @change="onChange"
        @keyup="onKeyUp">
        <template v-slot:app-tooltipster>
            <slot name="app-tooltipster"></slot>
        </template>
    </app-input-text>
</template>

<style lang="scss" scoped>
.input-identity-number {
    scroll-margin-top: 4em;

    @include respond-below('md') {
        width: 100%;
    }
}
</style>
