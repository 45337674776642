import {VueApp} from '@/Types/VueAppType';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import {Subject} from 'rxjs';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import {useTranslate} from '@/Composables/Translate';
import {useStrings} from '@/Composables/Strings';
import {InputOption} from '@/interfaces/InputOptionInterface';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';

export default class PopupBase {
    private type!: string;
    private title!: string;
    private secondaryTitle!: string;
    private description!: string;
    private secondaryDescription!: string;
    private icon!: string;
    private secondaryIcon!: string;
    private input: string = '';
    private userInputClass: string = '';
    private userInputs: Form = new Form();
    private customData: any;

    private onCancelCallbackContext!: VueApp;
    private onCancelCallbackModel!: string | Function;
    private onConfirmCallbackContext!: VueApp;
    private onConfirmCallbackModel!: string | Function;
    private onClickCallbackContext!: VueApp;
    private onClickCallbackModel!: string | Function;

    private closeButtonEnabled!: boolean;
    private headerVisible!: boolean;
    private autoWidth: boolean = false;
    private cancelText: string = '';
    private confirmText: string = '';
    private calendarLabel: string = '';

    private confirmColor: string = '';
    private startDateForCalendar: Date = new Date();
    private minDateForCalendarInput: Date = new Date();
    private maxDateForCalendarInput: Date = new Date();
    private inputOptions: InputOption[] = [];
    private showCancelButton: boolean = true;
    private readonly InputSuffix: string = 'Input';
    private readonly PopupSuffix: string = 'Popup';

    private onConfirmSubject: Subject<void> = new Subject<void>();
    private onCancelSubject: Subject<void> = new Subject<void>();
    private onCloseSubject: Subject<string> = new Subject();

    private buttons: ButtonWithCallbackParams[] = [];

    protected translate: Function = useTranslate().translate;
    protected capitalize: Function = useStrings().capitalize;

    public get popupType(): string {
        return this.type;
    }

    public get popupTitle(): string {
        return this.title;
    }

    public get popupSecondaryTitle(): string {
        return this.secondaryTitle;
    }

    public get popupDescription(): string {
        return this.description;
    }

    public get popupSecondaryDescription(): string {
        return this.secondaryDescription;
    }

    public get popupIcon(): string {
        return this.icon;
    }

    public get popupSecondaryIcon(): string {
        return this.secondaryIcon;
    }

    public get hasAutoWidth(): boolean {
        return this.autoWidth;
    }

    public get confirmCallbackContext(): VueApp {
        return this.onConfirmCallbackContext;
    }

    public get confirmCallbackModel(): string | Function {
        return this.onConfirmCallbackModel;
    }

    public get cancelCallbackContext(): VueApp {
        return this.onCancelCallbackContext;
    }

    public get cancelCallbackModel(): string | Function {
        return this.onCancelCallbackModel;
    }

    public get clickCallbackContext(): VueApp {
        return this.onClickCallbackContext;
    }

    public get clickCallbackModel(): string | Function {
        return this.onClickCallbackModel;
    }

    public get inputType(): string {
        return this.input;
    }

    public get inputClass(): string {
        return this.userInputClass;
    }

    public get inputs(): Form {
        return this.userInputs;
    }

    public get data(): any {
        return this.customData;
    }

    public get inputName(): string {
        return [
            this.popupType,
            this.capitalize(this.inputType),
            this.InputSuffix,
        ].join('');
    }

    public get cancelButtonText(): string {
        return this.cancelText === ''
            ? this.translate('confirm_popup_default_cancel_text')
            : this.cancelText;
    }

    public get confirmButtonText(): string {
        return this.confirmText === ''
            ? this.translate('confirm_popup_default_confirm_text')
            : this.confirmText;
    }

    public get continueButtonText(): string {
        return this.confirmText === ''
            ? this.translate('simple_error_popup_default_confirm_text')
            : this.confirmText;
    }

    public get calendarInputLabel(): string {
        return this.calendarLabel === ''
            ? this.translate('confirm_popup_user_input_date_label')
            : this.calendarLabel;
    }

    public get confirmButtonColor(): string {
        return this.confirmColor === ''
            ? ButtonBackground.Red
            : this.confirmColor;
    }

    public get isCloseDisabled(): boolean {
        return !this.closeButtonEnabled;
    }

    public get isHeaderVisible(): boolean {
        return this.headerVisible;
    }

    public get startDate(): Date {
        return this.startDateForCalendar;
    }

    public get minDate(): Date {
        return this.minDateForCalendarInput;
    }

    public get maxDate(): Date {
        return this.maxDateForCalendarInput;
    }

    public get confirmSubject(): Subject<void> {
        return this.onConfirmSubject;
    }

    public get cancelSubject(): Subject<void> {
        return this.onCancelSubject;
    }

    public get closeSubject(): Subject<string> {
        return this.onCloseSubject;
    }

    public get userInputsAreValid(): boolean {
        return this.inputs.isValid()
            && this.inputs.fields()
                .filter((field: FormField): boolean => field.isEmpty()).length === 0;
    }

    public get buttonsWithCallback() {
        return this.buttons;
    }

    protected applyType(value: string): void {
        this.type = value;
    }

    protected applyTitle(value: string): void {
        this.title = value;
    }

    protected applySecondaryTitle(value: string): void {
        this.secondaryTitle = value;
    }

    protected applyDescription(value: string): void {
        this.description = value;
    }

    protected applyAutoWidth(): void {
        this.autoWidth = true;
    }

    protected applySecondaryDescription(value: string): void {
        this.secondaryDescription = value;
    }

    protected applyIcon(value: string): void {
        this.icon = value;
    }

    protected applySecondaryIcon(value: string): void {
        this.secondaryIcon = value;
    }

    protected applyInputClass(value: string): void {
        this.userInputClass = value
    }

    protected applyCancelButtonText(value: string): void {
        this.cancelText = value;
    }

    protected applyConfirmButtonText(value: string): void {
        this.confirmText = value;
    }

    protected applyConfirmButtonColor(value: string): void {
        this.confirmColor = value;
    }

    protected applyInput(value: string): void {
        this.input = value;
        this.userInputs.addField(new FormField(this.inputName));
    }

    protected applyData(value: any): void {
        this.customData = value;
    }

    protected applyOnConfirmCallback(model: string | Function, context: VueApp | null = null): void {
        this.onConfirmCallbackModel = model;
        this.onConfirmCallbackContext = context;
    }

    protected applyOnCancelCallback(model: string | Function, context: VueApp | null = null): void {
        this.onCancelCallbackModel = model;
        this.onCancelCallbackContext = context;
    }

    protected applyOnClickCallback(model: string | Function, context: VueApp | null = null): void {
        this.onClickCallbackModel = model;
        this.onClickCallbackContext = context;
    }

    protected applyDateInputLabel(value: string): void {
        this.calendarLabel = value;
    }

    protected applyStartDateForCalendar(value: Date): void {
        this.startDateForCalendar = value;
    }

    protected applyMinDateForCalendarInput(value: Date): void {
        this.minDateForCalendarInput = value;
    }

    protected applyMaxDateForCalendarInput(value: Date): void {
        this.maxDateForCalendarInput = value;
    }

    protected applyCloseButtonVisibility(value: boolean): void {
        this.closeButtonEnabled = value;
    }

    protected applyHeaderVisibility(value: boolean): void {
        this.headerVisible = value;
    }

    protected applyOptionsInput(value: InputOption[]): void {

        this.inputOptions = value;
    }

    protected applyWithoutCancelButton(): void {

        this.showCancelButton = false;
    }

    protected applyButtons(value: ButtonWithCallbackParams[]): void {
        this.buttons = value;
    }
}
