import InsurancePlanItem from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';

export default class InsurancePlanItemBuilder {
    private insurancePlanItem: InsurancePlanItem = new class implements InsurancePlanItem {
        public title: string = '';
        public description: string = '';
        public price: number = 0;
    }

    public withTitle(value: string): InsurancePlanItemBuilder {
        this.insurancePlanItem.title = value;

        return this;
    }

    public withDescription(value: string): InsurancePlanItemBuilder {
        this.insurancePlanItem.description = value;

        return this;
    }

    public withPrice(value: number): InsurancePlanItemBuilder {
        this.insurancePlanItem.price = value;

        return this;
    }

    public build(): InsurancePlanItem {
        return this.insurancePlanItem;
    }
}
