import AppBrowser from '@/assets/libraries/app/app-browser';

export const useClipboard = (): ClipboardParams => {
    const copyToClipboard = (value: string): Promise<void> => {

        return new Promise<void>((resolve): void => {
            if (navigator.clipboard && window.isSecureContext) {
                copyWithClipboardApi(value);
            } else {
                if (new AppBrowser().isIos()) {
                    copyWithTextArea(value);
                } else {
                    copyWithListener(value);
                }
            }
            resolve();
        });
    }

    /**
     * ClipboardAPi is the preferred way to manipulate clipboard content, regardless of platform.
     * Unfortunately, due to security constraints it only works on SSL connections (HTTPS).
     */
    function copyWithClipboardApi(value: string): void {
        navigator.clipboard.writeText(value).then();
    }

    /**
     * Original copy method with event listener. Does not work on Safari/iOS.
     */
    function copyWithListener(value: string): void {
        const copyListener = (event: ClipboardEvent) => {
            event.clipboardData!.setData('text/plain', value);
            event.preventDefault();
            document.removeEventListener('copy', copyListener)
        }
        document.addEventListener('copy', copyListener);
        document.execCommand('copy');
    }

    /**
     * Manipulate clipboard content via textarea, range & selection.
     * Specific for Safari/iOS.
     */
    function copyWithTextArea(value: string): void {
        if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            const textarea: HTMLTextAreaElement = document.createElement('textarea');
            textarea.textContent = value;
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
        }
    }

    return {
        copyToClipboard,
    }
}

export interface ClipboardParams {
    copyToClipboard: (value: string) => void;
}
