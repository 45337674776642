import Vue from 'vue';
import QrcodeVue from 'qrcode.vue'
import AppModule from '@/Components/Module/Module.vue';
import CustomForm from '@/Components/CustomForm/CustomForm.vue';
import AppInputText from '@/Components/InputText/InputText.vue';
import AppInputTextAjax from '@/Components/InputTextAjax/InputTextAjax.vue';
import InputSelect from '@/Components/InputSelect/InputSelect.vue';
import InputSelectionPanels from '@/Components/InputSelectionPanels/InputSelectionPanels.vue';
import InputDateWithDropdown from "@/Components/InputDateWithDropdown/InputDateWithDropdown.vue";
import InputRadio from '@/Components/InputRadio/InputRadio.vue';
import AppInputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import AppInputCounter from '@/Components/InputCounter/InputCounter.vue';
import InputMultiCounter from '@/Components/InputMultiCounter/InputMultiCounter.vue';
import AppTooltipster from '@/Components/Tooltipster/Tooltipster.vue';
import Country from '@/Components/InputCountry/InputCountry.vue';
import MultiCountry from '@/Components/MultiCountry/MultiCountry.vue';
import PhoneWithCountry from '@/Components/PhoneWithCountry/PhoneWithCountry.vue';
import AppStepperBelt from '@/Components/StepperBelt/StepperBelt.vue';
import InputPlateNumber from '@/Components/InputPlateNumber/InputPlateNumber.vue';
import AppInputTextarea from '@/Components/InputTextarea/InputTextarea.vue';
import AppInputTime from '@/Components/InputTime/InputTime.vue';
import AppInputTimeWithDropdown from '@/Components/InputTimeWithDropdown/InputTimeWithDropdown.vue';
import AppFilesUploader from '@/Components/FilesUploader/FilesUploader.vue';
import AppExpandableItem from '@/Components/ExpandableItem/ExpandableItem.vue';
import AppExpandableAccordion from '@/Components/ExpandableAccordion/ExpandableAccordion.vue';
import AppContentLoader from '@/Components/ContentLoader/ContentLoader.vue';
import AppPopup from '@/Components/Popup/Popup.vue';
import AppModal from '@/Components/Modal/Modal.vue';
import AppImageInfo from '@/Components/ImageInfo/ImageInfo.vue';
import AppPaginator from '@/Components/Paginator/Paginator.vue';
import AppFilesArchive from '@/Components/FilesArchive/FilesArchive.vue';
import AppAddressFinder from '@/Components/AddressFinder/AddressFinder.vue';
import AppMobileDetect from 'mobile-device-detect';
import CountryRegions from '@/Components/CountryRegions/CountryRegions.vue';
import CountryRegionsMulti from '@/Components/CountryRegionsMulti/CountryRegionsMulti.vue';
import InputCompanyType from '@/Components/InputCompanyType/InputCompanyType.vue';
import InputSwitch from '@/Components/InputSwitch/InputSwitch.vue';
import SecurityDeviceConsent from '@/Components/SecurityDeviceConsent/SecurityDeviceConsent.vue';
import InputRadioSwitch from '@/Components/InputRadioSwitch/InputRadioSwitch.vue';
import InputMultiCheckbox from '@/Components/InputMultiCheckbox/InputMultiCheckbox.vue';
import LoadMoreList from '@/Components/LoadMoreList/LoadMoreList.vue';
import InputBankInstitution from '@/Components/InputBankInstitution/InputBankInstitution.vue';
import AppItemsButton from '@/Components/ItemsButton/ItemsButton.vue';
import InputEmail from '@/Components/InputEmail/InputEmail.vue';
import InsuredObjectsBlocks from '@/Components/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import AppOptionsList from '@/Components/OptionsList/OptionsList.vue';
import AppMapWithPlaces from '@/Components/MapWithPlaces/MapWithPlaces.vue';
import AppMapWithAddress from '@/Components/MapWithAddress/MapWithAddress.vue';
import AppMapControls from '@/Components/MapControls/MapControls.vue';
import AppItemsListWithIcons from '@/Components/ItemsListWithIcons/ItemsListWithIcons.vue';
import AppModalOverlayMessage from '@/Components/ModalOverlayMessage/ModalOverlayMessage.vue';
import AppInformationalTextBlock from '@/Components/InformationalTextBlock/InformationalTextBlock.vue';
import AppPopupFilter from '@/Components/PopupFilter/PopupFilter.vue';
import AppOverlayButton from '@/Components/OverlayButton/OverlayButton.vue';
import InputPostCode from '@/Components/InputPostCode/InputPostCode.vue';
import AppButtonWithCallback from '@/Components/ButtonWithCallback/ButtonWithCallback.vue';
import AppProcessing from '@/Components/Processing/Processing.vue';
import AppIconWithLabel from '@/Components/IconWithLabel/IconWithLabel.vue';
import AppLegalModal from '@/Components/LegalModal/LegalModal.vue';
import PersonListWithSearch from '@/Components/PersonListWithSearch/PersonListWithSearch.vue';
import AppInputSmartRadio from '@/Components/InputSmartRadio/InputSmartRadio.vue';
import AppButtonsBeltWithDigits from '@/Components/ButtonsBeltWithDigits/ButtonsBeltWithDigits.vue';
import InputBankAccount from '@/Components/InputBankAccount/InputBankAccount.vue';
import AppInputSearch from '@/Components/InputSearch/InputSearch.vue';
import SelectAccountDropdown from '@/Components/SelectAccountDropdown/SelectAccountDropdown.vue';
import AppInputDocumentUpload from '@/Components/InputDocumentUpload/InputDocumentUpload.vue';
import AppGrid from '@/Components/SmartGrid/SmartGrid.vue';
import AppDrawer from '@/Components/Drawer/Drawer.vue';
import AppPolicyDetails from '@/Components/PolicyDetails/PolicyDetails.vue';
import AppPolicyDetailsObjects from '@/Components/PolicyDetailsObjects/PolicyDetailsObjects.vue';
import AppPolicyDetailsOverview from '@/Components/PolicyDetailsOverview/PolicyDetailsOverview.vue';
import AppPolicyDetailsInvoices from '@/Components/PolicyDetailsInvoices/PolicyDetailsInvoices.vue';
import AppPolicyDetailsPaymentMethod from '@/Components/PolicyDetailsPaymentMethod/PolicyDetailsPaymentMethod.vue';
import AppPolicyDetailsPersons from '@/Components/PolicyDetailsPersons/PolicyDetailsPersons.vue';
import AppCreditCardStatusWidget from '@/Components/CreditCardStatusWidget/CreditCardStatusWidget.vue';
import AppStatusIcon from '@/Components/StatusIcon/StatusIcon.vue';
import InputDate from '@/Components/InputDate/InputDate.vue';
import AppInsuredRisks from '@/Components/InsuredRisks/InsuredRisks.vue';
import AppPolicyLimits from '@/Components/PolicyLimits/PolicyLimits.vue';
import AppOptionsSmartList from '@/Components/OptionsSmartList/OptionsSmartList.vue';
import AppRemotePaginator from '@/Components/RemotePaginator/RemotePaginator.vue';
import AppFieldsetList from '@/Components/FieldsetList/FieldsetList.vue';
import AppPopupSelector from '@/Components/PopupSelector/PopupSelector.vue';
import AppHumanBodyParts from '@/Components/HumanBodyParts/HumanBodyParts.vue';
import InputDateWithCalendar from '@/Components/InputDateWithCalendar/InputDateWithCalendar.vue';
import AppProductBlock from '@/Components/ProductBlock/ProductBlock.vue';
import ProductBlockInnerOneDayPolicy from '@/Components/ProductBlock/Components/ProductBlockInnerOneDayPolicy.vue';
import ProductBlockInnerMovableProperty
    from '@/Components/ProductBlock/Components/ProductBlockInnerMovableProperty.vue';
import ProductBlockInnerBicyclePolicy from '@/Components/ProductBlock/Components/ProductBlockInnerBicyclePolicy.vue';
import InputCreditCardDate from '@/Components/InputCreditCardDate/InputCreditCardDate.vue';
import InputCreditCardNumber from '@/Components/InputCreditCardNumber/InputCreditCardNumber.vue';
import CreditCardDetails from '@/Components/CreditCardDetails/CreditCardDetails.vue';
import HealthCardsList from '@/Components/HealthCardsList/HealthCardsList.vue';
import CreditCardUpdate from '@/Components/CreditCardUpdate/CreditCardUpdate.vue';
import InputVehicleMake from '@/Components/InputVehicleMake/InputVehicleMake.vue';
import CreditCardWidget from '@/Components/CreditCardWidget/CreditCardWidget.vue';
import AppPolicyBlock from '@/Components/PolicyBlock/PolicyBlock.vue';
import PaymentWidget from '@/Components/PaymentWidget/PaymentWidget.vue';
import PreviewHeader from '@/Components/PreviewHeader/PreviewHeader.vue';
import Expandable from '@/Components/Expandable/Expandable.vue';
import ConsentsList from '@/Components/ConsentsList/ConsentsList.vue';
import AdditionalOptionsList from '@/Components/AdditionalOptionsList/AdditionalOptionsList.vue';
import MovablePropertyOption from '@/Components/AdditionalOptionsList/Components/MovablePropertyOption.vue';
import MtplOfferOption from '@/Components/AdditionalOptionsList/Components/MtplOfferOption.vue';
import TravelOptionRentalCarSecurity
    from '@/Components/AdditionalOptionsList/Components/TravelOptionRentalCarSecurity.vue';
import InsurancePlanWidget from '@/Components/InsurancePlanWidget/InsurancePlanWidget.vue';
import OptionsFieldList from '@/Components/OptionsFieldList/OptionsFieldList.vue';
import MovablePropertyDeviceOption from '@/Components/OptionsFieldList/Components/MovablePropertyDeviceOption.vue';
import AppCoveredPopupSingle from '@/Components/CoveredPopup/CoveredPopupSingle.vue';
import AppCoveredPopupMultiple from '@/Components/CoveredPopup/CoveredPopupMultiple.vue';
import AppCoveredPopupBicycleSingle from '@/Components/CoveredPopup/CoveredPopupBicycleSingle.vue';
import AppCoveredPopupBicycleMulti from '@/Components/CoveredPopup/CoveredPopupBicycleMulti.vue';
import TextWithTip from '@/Components/TextWithTip/TextWithTip.vue';
import InputSearchVehicleMake from '@/Components/InputSearchVehicleMake/InputSearchVehicleMake.vue';
import InputRadioOverlayed from '@/Components/InputRadioOverlayed/InputRadioOverlayed.vue';
import OneDayMini from '@/Components/ProductBlock/Components/MiniPlans/OneDayMini.vue';
import BicycleMini from '@/Components/ProductBlock/Components/MiniPlans/BicycleMini.vue';
import MovablePropertyMini from '@/Components/ProductBlock/Components/MiniPlans/MovablePropertyMini.vue';
import InputCount from '@/Components/InputCount/InputCount.vue';
import SingleAppSteps from '@/Components/SingleAppSteps/SingleAppSteps.vue';
import PaymentMethods from '@/Components/PaymentMethods/PaymentMethods.vue';
import LanguageSwitcher from '@/Components/LanguageSwitcher/LanguageSwitcher.vue';
import ObjectList from '@/Components/ObjectList/ObjectList.vue';
import AppInputIdentityNumber from '@/Components/InputIdentityNumber/InputIdentityNumber.vue';
import AppPopupOpener from '@/Components/PopupOpener/PopupOpener.vue';
import Badge from '@/Components/Badge/Badge.vue';
import NextBestAction from '@/Components/NextBestAction/NextBestAction.vue';
import RentersOption from '@/Components/AdditionalOptionsList/Components/RentersOption.vue';
import PlaceholderOption from '@/Components/AdditionalOptionsList/Components/PlaceholderOption.vue';
import InfoWithActions from '@/Components/InfoWithActions/InfoWithActions.vue';
import ContactUs from '@/Components/ContactUs/ContactUs.vue';
import TwoWayModalScreen from '@/Apps/TwoWayCommunication/Components/TwoWayModalScreen.vue';
import ProgressSteps from '@/Components/ProgressSteps/ProgressSteps.vue';

Vue.component('AppModule', AppModule);
Vue.component('AppCustomForm', CustomForm);
Vue.component('AppInputText', AppInputText);
Vue.component('AppInputTextAjax', AppInputTextAjax);
Vue.component('AppInputSelect', InputSelect);
Vue.component('AppInputSelectionPanels', InputSelectionPanels);
Vue.component('AppInputDateWithDropdown', InputDateWithDropdown);
Vue.component('AppInputDateWithCalendar', InputDateWithCalendar);
Vue.component('AppInputRadio', InputRadio);
Vue.component('AppInputCheckbox', AppInputCheckbox);
Vue.component('AppInputSwitch', InputSwitch);
Vue.component('AppInputRadioSwitch', InputRadioSwitch);
Vue.component('AppInputNumber', InputNumber);
Vue.component('AppInputCounter', AppInputCounter);
Vue.component('AppInputMultiCounter', InputMultiCounter);
Vue.component('AppTooltipster', AppTooltipster);
Vue.component('AppInputCountry', Country);
Vue.component('AppMultiCountry', MultiCountry);
Vue.component('AppPhoneWithCountry', PhoneWithCountry);
Vue.component('AppStepperBelt', AppStepperBelt);
Vue.component('AppInputPlateNumber', InputPlateNumber);
Vue.component('AppInputTextarea', AppInputTextarea);
Vue.component('AppInputTime', AppInputTime);
Vue.component('AppInputTimeWithDropdown', AppInputTimeWithDropdown);
Vue.component('AppFilesUploader', AppFilesUploader);
Vue.component('AppExpandableItem', AppExpandableItem);
Vue.component('AppExpandableAccordion', AppExpandableAccordion);
Vue.component('AppContentLoader', AppContentLoader);
Vue.component('AppPopup', AppPopup);
Vue.component('AppModal', AppModal);
Vue.component('AppModalOverlayMessage', AppModalOverlayMessage);
Vue.component('AppImageInfo', AppImageInfo);
Vue.component('AppPaginator', AppPaginator);
Vue.component('AppFilesArchive', AppFilesArchive);
Vue.component('AppAddressFinder', AppAddressFinder);
Vue.component('QrcodeVue', QrcodeVue);
Vue.component('isMobile', AppMobileDetect);
Vue.component('AppCountryRegions', CountryRegions);
Vue.component('AppCountryRegionsMulti', CountryRegionsMulti);
Vue.component('AppHealthCardsList', HealthCardsList);
Vue.component('AppInputCompanyType', InputCompanyType);
Vue.component('AppSecurityDeviceConsent', SecurityDeviceConsent);
Vue.component('AppInputMultiCheckbox', InputMultiCheckbox);
Vue.component('AppLoadMoreList', LoadMoreList);
Vue.component('AppInputBankInstitution', InputBankInstitution);
Vue.component('AppItemsButton', AppItemsButton);
Vue.component('AppInputCreditCardNumber', InputCreditCardNumber);
Vue.component('AppInputCreditCardDate', InputCreditCardDate);
Vue.component('AppCreditCardDetails', CreditCardDetails);
Vue.component('AppCreditCardUpdate', CreditCardUpdate);
Vue.component('AppInputEmail', InputEmail);
Vue.component('AppInsuredObjectsBlocks', InsuredObjectsBlocks);
Vue.component('AppOptionsList', AppOptionsList);
Vue.component('AppMapWithPlaces', AppMapWithPlaces);
Vue.component('AppMapWithAddress', AppMapWithAddress);
Vue.component('AppMapControls', AppMapControls);
Vue.component('AppItemsListWithIcons', AppItemsListWithIcons);
Vue.component('AppInformationalTextBlock', AppInformationalTextBlock);
Vue.component('AppPopupFilter', AppPopupFilter);
Vue.component('AppOverlayButton', AppOverlayButton);
Vue.component('AppInputPostCode', InputPostCode);
Vue.component('AppButtonWithCallback', AppButtonWithCallback);
Vue.component('AppProcessing', AppProcessing);
Vue.component('AppIconWithLabel', AppIconWithLabel);
Vue.component('AppLegalModal', AppLegalModal);
Vue.component('AppPersonListWithSearch', PersonListWithSearch);
Vue.component('AppInputSmartRadio', AppInputSmartRadio);
Vue.component('AppButtonsBeltWithDigits', AppButtonsBeltWithDigits);
Vue.component('AppInputBankAccount', InputBankAccount);
Vue.component('AppInputSearch', AppInputSearch);
Vue.component('AppSelectAccountDropdown', SelectAccountDropdown);
Vue.component('AppInputDocumentUpload', AppInputDocumentUpload);
Vue.component('AppPaymentWidget', PaymentWidget);
Vue.component('AppGrid', AppGrid);
Vue.component('AppDrawer', AppDrawer);
Vue.component('AppPolicyDetails', AppPolicyDetails);
Vue.component('AppPolicyDetailsOverview', AppPolicyDetailsOverview);
Vue.component('AppPolicyDetailsInvoices', AppPolicyDetailsInvoices);
Vue.component('AppPolicyDetailsObjects', AppPolicyDetailsObjects);
Vue.component('AppPolicyDetailsPaymentMethod', AppPolicyDetailsPaymentMethod);
Vue.component('AppPolicyDetailsPersons', AppPolicyDetailsPersons);
Vue.component('AppInputVehicleMake', InputVehicleMake);
Vue.component('AppCreditCardWidget', CreditCardWidget);
Vue.component('AppCreditCardStatusWidget', AppCreditCardStatusWidget);
Vue.component('AppStatusIcon', AppStatusIcon);
Vue.component('AppInputDate', InputDate);
Vue.component('AppInsuredRisks', AppInsuredRisks);
Vue.component('AppPolicyLimits', AppPolicyLimits);
Vue.component('AppOptionsSmartList', AppOptionsSmartList);
Vue.component('AppRemotePaginator', AppRemotePaginator);
Vue.component('AppFieldsetList', AppFieldsetList);
Vue.component('AppPopupSelector', AppPopupSelector);
Vue.component('AppHumanBodyParts', AppHumanBodyParts);
Vue.component('AppProductBlock', AppProductBlock);
Vue.component('AppProductBlockInnerOneDayPolicy', ProductBlockInnerOneDayPolicy);
Vue.component('AppProductBlockInnerMovableProperty', ProductBlockInnerMovableProperty);
Vue.component('AppProductBlockInnerBicyclePolicy', ProductBlockInnerBicyclePolicy);
Vue.component('AppPolicyBlock', AppPolicyBlock);
Vue.component('AppPreviewHeader', PreviewHeader);
Vue.component('AppExpandable', Expandable);
Vue.component('AppConsentsList', ConsentsList);
Vue.component('AppAdditionalOptionsList', AdditionalOptionsList);
Vue.component('AppMovablePropertyOption', MovablePropertyOption);
Vue.component('AppRentersOption', RentersOption);
Vue.component('AppMtplOfferOption', MtplOfferOption);
Vue.component('AppPlaceholderOption', PlaceholderOption);
Vue.component('AppTravelOptionRentalCarSecurity', TravelOptionRentalCarSecurity);
Vue.component('AppInsurancePlanWidget', InsurancePlanWidget);
Vue.component('AppCoveredPopupSingle', AppCoveredPopupSingle);
Vue.component('AppCoveredPopupMultiple', AppCoveredPopupMultiple);
Vue.component('AppCoveredPopupBicycleSingle', AppCoveredPopupBicycleSingle);
Vue.component('AppCoveredPopupBicycleMulti', AppCoveredPopupBicycleMulti);
Vue.component('AppOptionsFieldList', OptionsFieldList);
Vue.component('AppMovablePropertyDeviceOption', MovablePropertyDeviceOption);
Vue.component('AppTextWithTip', TextWithTip);
Vue.component('AppInputSearchVehicleMake', InputSearchVehicleMake);
Vue.component('AppInputRadioOverlayed', InputRadioOverlayed);
Vue.component('AppOneDayMini', OneDayMini);
Vue.component('AppBicycleMini', BicycleMini);
Vue.component('AppMovablePropertyMini', MovablePropertyMini);
Vue.component('AppInputCount', InputCount);
Vue.component('AppSingleAppSteps', SingleAppSteps);
Vue.component('AppPaymentMethods', PaymentMethods);
Vue.component('AppLanguageSwitcher', LanguageSwitcher);
Vue.component('AppObjectList', ObjectList);
Vue.component('AppInputIdentityNumber', AppInputIdentityNumber);
Vue.component('AppPopupOpener', AppPopupOpener);
Vue.component('AppBadge', Badge);
Vue.component('AppNextBestAction', NextBestAction);
Vue.component('AppInfoWithActions', InfoWithActions);
Vue.component('AppContactUs', ContactUs);
Vue.component('AppTwoWayModalScreen', TwoWayModalScreen);
Vue.component('AppProgressSteps', ProgressSteps);
