export default class CssClass {
    public static readonly Active: string = 'active';
    public static readonly BorderWithColor: string = 'border-with-color';
    public static readonly Disabled: string = 'disabled';
    public static readonly Invalid: string = 'invalid';
    public static readonly Fixed: string = 'fixed';
    public static readonly FileUploadSuccess: string = 'success';
    public static readonly FileUploadFail: string = 'fail';
    public static readonly FileUploadProgress: string = 'in-progress';
    public static readonly FileUploadCategoryEmpty: string = 'category-empty';
    public static readonly FileUploadCategoryCollapsed: string = 'category-collapsed';
    public static readonly FileUploadCategoryUploaded: string = 'category-uploaded';
    public static readonly FileUploadButtonExpand: string = 'button-expand';
    public static readonly FileUploadButtonCollapse: string = 'button-collapse';
    public static readonly Hidden: string = 'hidden';
    public static readonly Invisible: string = 'invisible';
    public static readonly NotFilled: string = 'not-filled';
    public static readonly Outside: string = 'outside';
    public static readonly Opened: string = 'opened';
    public static readonly Red: string = 'red';
    public static readonly Selected: string = 'selected';
    public static readonly Visible: string = 'visible';
    public static readonly WithoutDescription: string = 'without-description';
    public static readonly Vertical: string = 'vertical';
}
