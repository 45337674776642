<script lang="ts">
import Vue, {computed, defineComponent, reactive, Ref, ref} from 'vue';
import PropertyDataLayer from '@/pages/Property/PropertyDataLayer';
import OneBaseService from '@/services/OneBaseService';
import Method from '@/Enums/MethodEnum';
import {StepsSubmitterParams, useStepsSubmitter} from '@/Composables/StepsSubmitter';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import {InputOption} from '@/interfaces/InputOptionInterface';
import PropertyEnum from '@/Enums/PropertyEnum';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import OneBase from '@/interfaces/OneBaseInterface';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import {VueApp} from '@/Types/VueAppType';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const getInTouchModule: VueApp = new Vue(GetInTouchModule);

        const {translateForType}: TranslateParams = useTranslate();
        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const request: AxiosParams = useAxios();

        const CurrentStep: number = 1;
        let restrictedByGeoIp: boolean = false;
        let dataLayer: PropertyDataLayer = new PropertyDataLayer();


        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const apartmentAreaMinLimit: Ref<number> = ref(PropertyEnum.Limits.ApartmentAreaMinLimit);
        const houseAreaMinLimit: Ref<number> = ref(PropertyEnum.Limits.HouseAreaMinLimit);
        const formIsReady: Ref<boolean> = ref(false);

        const propertyTypeOptions: Ref<InputOption[]> = computed((): InputOption[] => {
            const result: InputOption[] = [];
            if (btaBase.cmsFieldIsEnabled('propertyType')) {
                btaBase.cmsFields.propertyType.value.forEach((value: DynamicDictionary) => {
                    const translatedLabel: string = translateForType(value.label, PropertyEnum.TranslationType)
                    result.push(
                        new class implements InputOption {
                            public name: string = translatedLabel;
                            public value: string | number | boolean = value.id;
                        }
                    );
                });
            }

            return result;
        });

        const houseAreaLimit: Ref<number> = computed((): number => {
            return houseAreaMinLimit.value;
        });

        const apartmentAreaLimit: Ref<number> = computed((): number => {
            return apartmentAreaMinLimit.value;
        });

        const isHouseSelected: Ref<boolean> = computed(() => {
            return form.field('property-type').value === PropertyEnum.Type.House;
        });

        const feedbackMessage: Ref<string> = computed((): string => {
            let message: string = '';
            if (!form.field('property-area').isValid && !form.field('property-area').isEmpty()) {
                message = translateForType('one_property_information_area_error', 'property');
                message += isHouseSelected.value ?
                    houseAreaLimit.value : apartmentAreaLimit.value;
            }
            return message;
        });

        const propertyTypeDefault: Ref<string> = computed((): string => {
            let result: string = '';
            for (const o in btaBase.cmsFields.propertyType.value) {
                const item: DynamicDictionary = btaBase.cmsFields.propertyType.value[o];
                if (item.isDefault) {
                    result = item.id;
                }
            }

            return result;
        });

        function applyGeoIpRestriction(isRestricted: boolean): void {
            restrictedByGeoIp = isRestricted;
        }

        function submit(): void {
            prepareSubmit();
            dataLayer.pushDataLayer(dataLayerParams());
            if (btaBase.settings.metaApiEnabled()) {
                sendViewContentEvent();
            }
            stepsSubmitter.submitMethod(Method.Get);
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function setupForm(): void {
            form.addField(new FormField('property-type'));
            form.addField(new FormField('property-area', '', {
                isRequired: (): boolean => {
                    return !form.field('property-area').isEmpty();
                },
                isValueWithinLimit: (): boolean => {
                    return isHouseSelected.value ?
                        parseFloat(form.field('property-area').value) >= houseAreaLimit.value :
                        parseFloat(form.field('property-area').value) >= apartmentAreaLimit.value;
                }
            }, Sanitizer.cleanNumber));
            form.setReady();
            formIsReady.value = true;
        }

        function dataLayerParams(): DynamicDictionary {
            const variant: string = transformPropertyType(form.field('property-type').value);
            const area: number = Number(form.field('property-area').value);
            return new class implements DynamicDictionary {
                public price: string = '';
                public quantity: number = 1;
                public variant: string = variant;
                public dimension2: string = '1y'; //INFO: according to BTA: period is 1y year for now
                public dimension9: string = '';
                public dimension10: string = '';
                public dimension14: string = '';
                public dimension15: string = '';
                public metric4: number = area;
            }
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('propertyType', form.field('property-type').value);
            const propertyArea: string = form.field('property-area').value;
            stepsSubmitter.addSubmitCustomParam('propertyArea', parseFloat(propertyArea));
            stepsSubmitter.addSubmitCustomParam('dataLayerParams', dataLayerParams());
        }

        function transformPropertyType(id: string): string {
            const property: DynamicDictionary = new class implements DynamicDictionary {
                public 'PD21_O_BLDF': string = 'house';
                public 'PD21_O_APAR': string = 'flat';
            }

            return property[id];
        }

        function applyDefaultCmsValues(): void {
            if (btaBase.cmsFields && btaBase.cmsFields.area) {
                houseAreaMinLimit.value = btaBase.cmsFields.area.value.house;
                apartmentAreaMinLimit.value = btaBase.cmsFields.area.value.apartment;
            }
        }

        function onAfterFormRestored(): void {
            Vue.nextTick(() => {
                if (!form.field('property-type').value) {
                    form.field('property-type').patch(propertyTypeDefault.value);
                }
            });
        }

        function sendViewContentEvent(): void {
            request.post(Url.Ajax.viewContentEvent, {
                productCategory: 'PropertyInsurance',
            }).then();
        }

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        return {
            ...btaBase,
            ...{
                form,
                getInTouchModule,
                applyGeoIpRestriction,
                submit,
                propertyTypeOptions,
                feedbackMessage,
                applyStepUrls,

                applyDefaultCmsValues,
                setupForm,
                onAfterFormRestored,
                formIsReady,
                CurrentStep,
                restrictedByGeoIp
            }
        };
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        if (!this.restrictedByGeoIp) {
            this.setStep(this.CurrentStep);
            this.setFacility('one-property');
            this.setStorageUsage(true);
            this.applyDefaultCmsValues();
            this.setupForm();
            this.getInTouchModule.create();
            this.getInTouchModule.beforeModuleMounted();
            this.getInTouchModule.moduleMounted();
            this.getInTouchModule.enableScrollToMode();
            const onAfterFormRestoredSubscription =
                this.userStorage.onFormStorageDataIsReady.subscribe(() => {
                    this.onAfterFormRestored();
                    onAfterFormRestoredSubscription.unsubscribe();
                });
        }
    },
});
</script>
