<script setup lang="ts">
import {computed, onMounted, PropType, Ref, ref} from 'vue';
import {useTranslate} from '@/Composables/Translate';
import {useHtml} from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import {usePrice} from '@/Composables/Price';
import IconSide from '@/Components/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/CoveredPopup/Classes/CoveredPopupAutomationIterator';
import {Renters} from '@/Apps/Renters/Interfaces/RentersInterface';
import {CoverageRisk} from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import {useFormatter} from '@/Composables/Formatter';

export interface CoveredRisks {
    id: string,
    risks: (CoverageRisk | null) [],
}

const props = defineProps({
    title: {type: String, default: ''},
    policies: {type: Array as PropType<Renters[]>, default: () => []},
    excludedRisks: {type: Array, default: () => []},
    coveragePlanKey: {type: Number, default: 0},
    selectedRisks: {type: Array as PropType<CoverageRisk[]>, default: () => []},
    mode: {type: String, default: ''},
    translationType: {type: String, default: ''}
});

const {sparsePrice} = useFormatter();
const {translate, translateForType} = useTranslate();
const {price} = usePrice();

const additionalRisks = ref<CoveredRisks[]>([]);
const standardRisks = ref<CoveredRisks[]>([]);

const isSummaryModeEnabled: Ref<boolean> = computed((): boolean => {
    return props.mode === 'summary';
});

onMounted((): void => {
    init();
    CoveredPopupAutomationIterator.init();
});

function init(): void {
    setupRisks();
}

function setupRisks(): void {
    let standardRisksArray: CoveredRisks[] = [];
    let additionalRisksArray: CoveredRisks[] = [];

    separateStandardAndAdditionalRisks(additionalRisksArray, standardRisksArray);
    additionalRisksArray = filteredArray(additionalRisksArray);
    standardRisksArray = filteredArray(standardRisksArray);
    standardRisksArray = removedFakeStandardRisks(standardRisksArray, additionalRisksArray);
    associateRisksFromPolicies(additionalRisksArray);
    associateRisksFromPolicies(standardRisksArray);

    additionalRisks.value = additionalRisksArray;
    standardRisks.value = standardRisksArray;
}

function separateStandardAndAdditionalRisks(additionalRisksArray: CoveredRisks[], standardRisksArray: CoveredRisks[]): void {
    props.policies.forEach((plan) => {
        plan.coveragePlans[props.coveragePlanKey].risks.map((risk) => {
            const isNotExcludedRisk = !props.excludedRisks.some((excludedRisk) => excludedRisk === risk.id)
            if (isNotExcludedRisk) {
                if (risk.isAdditional) {
                    additionalRisksArray.push({
                        id: risk.id,
                        risks: []
                    })
                } else {
                    standardRisksArray.push({
                        id: risk.id,
                        risks: []
                    })
                }
            }
        });
    });
}

function filteredArray(arrayToFilter: CoveredRisks[]): CoveredRisks[] {
    return Array.from(new Set(arrayToFilter.map(risk => risk.id))).map(id =>
        arrayToFilter.find(risk => risk.id === id)! as CoveredRisks
    );
}

function removedFakeStandardRisks(standardRisks: CoveredRisks[], additionalRisks: CoveredRisks[]): CoveredRisks[] {
    const additionalRiskIds = new Set(additionalRisks.map(additionalRisk => additionalRisk.id));

    return standardRisks.filter(standardRisk => !additionalRiskIds.has(standardRisk.id));
}

function standardRiskStatusInfo(risk: CoverageRisk | null): string {
    let result: string;
    if (risk !== null && !risk.withoutInsuredSum) {
        result = sparsePrice(risk.insuredSum, true) + ' &euro;';
    } else if (risk !== null) {
        result = `${iconContent(checkmarkIconAttributes())}`;
    } else {
        result = `${iconContent(crossIconAttributes())}`;
    }

    return result;
}

function additionalRiskStatusInfo(risk: CoverageRisk | null): string {
    let result: string;
    if (isSummaryModeEnabled.value) {
        result = risk !== null && isSelectedRiskFoundInAdditionalRisks(risk) ?
            price(risk!.insuredSum, false, 'EUR', true) :
            `${iconContent(crossIconAttributes())}`;
    } else {
        if (risk !== null && risk.isAdditional) {
            result = price(risk.price, false) + ' ' + translate('btar_policy_price_text');
        } else if (risk !== null && !risk.isAdditional) {
            result = `${iconContent(checkmarkIconAttributes())}`;
        } else {
            result = `${iconContent(crossIconAttributes())}`;
        }
    }

    return result;
}

function associateRisksFromPolicies(arrayToAssociate: CoveredRisks[]): void {
    arrayToAssociate.forEach((standardRisk) => {
        props.policies.forEach((plan) => {
            const foundRisk = plan.coveragePlans[props.coveragePlanKey].risks.find(risk => risk.id === standardRisk.id);
            if (foundRisk) {
                standardRisk.risks.push(foundRisk);
            } else {
                standardRisk.risks.push(null);
            }
        })
    })
}

function isSelectedRiskFoundInAdditionalRisks(risk: CoverageRisk): boolean {
    return additionalRisks.value.some(item => item.id === risk.id) &&
        props.selectedRisks.some(item => item.id === risk.id);
}

function coverageTitle(): string {
    return props.policies[0].id === 'RENTER' ? 'covered_single_renters_title' : 'covered_single_renters+_title';
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    }
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    }
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml()
        .imgTag(iconAttributes);
}
</script>

<template>
    <div class="covered-popup-single">
        <div class="coverage-title">{{
                translateForType(title, translationType,
                    {'%PROGRAM_NAME%': translateForType(policies[0].id, translationType).toLowerCase()})
            }}
        </div>
        <div class="no-plans"></div>
        <div class="covered-information-single">
            <div class="coverage-text">
                <div class="title">{{ translateForType(coverageTitle(), translationType) }}</div>
                <div class="description">{{ translateForType('coverage_description', translationType) }}
                </div>
            </div>
            <div class="coverage">
                <span>{{ translateForType('insurance_limits', translationType) }}</span>
                <span class="price">{{
                        sparsePrice(props.policies[0].coveragePlans[props.coveragePlanKey].insuredSum, true)
                    }}&nbsp;&euro;</span>
            </div>
            <div class="risks"
                 :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                <div class="risk standard" v-for="(risk, index) in standardRisks"
                     :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index">
                    <app-text-with-tip class="sub-title"
                                       :info-icon-side="IconSide.Before"
                                       :title="translateForType(risk.id + '_DETAILED', translationType)"
                                       :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                                       :value="standardRiskStatusInfo(risk.risks[0])">
                    </app-text-with-tip>
                </div>
            </div>
            <div class="section-title">{{ translateForType('additional_coverage', 'renters') }}</div>
            <div class="risks"
                 :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()">
                <div class="risk additional" v-for="(risk, index) in additionalRisks"
                     :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index">
                    <app-text-with-tip class="sub-title"
                                       :info-icon-side="IconSide.Before"
                                       :title="translateForType(risk.id + '_DETAILED', translationType)"
                                       :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                                       :value="additionalRiskStatusInfo(risk.risks[0])">
                    </app-text-with-tip>
                </div>
            </div>
            <div class="section-title">
                {{ translateForType('territory_title', 'renters') }}
            </div>
            <app-text-with-tip class="sub-title"
                               :info-icon-side="IconSide.Before"
                               :title="translateForType(props.policies[0].params.insuranceTerritoryIc, translationType)"
                               :tip-description="translateForType('territory_tooltip', translationType)">
            </app-text-with-tip>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-single {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .no-plans {
        background-color: var(--background-light);
        min-height: 98px;
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            height: 120px;
        }
    }

    .covered-information-single {
        width: 100%;
        padding: 0 var(--size-small) var(--size-small);
        overflow-y: auto;
        max-height: 650px;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .coverage-text {
            .title {
                font-size: var(--size-normal);
                margin-bottom: var(--size-nano) !important;
                margin-top: var(--size-small) !important;
                font-weight: 700;
            }

            .description {
                text-align: left;
                margin-bottom: var(--size-small);
            }
        }

        .coverage {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            border-bottom: 1px solid var(--black-100);
            margin: var(--size-small) 0 0 !important;
            padding-bottom: var(--size-small);

            .price {
                font-weight: 500;
                text-align: right;
            }
        }

        .risks {
            width: 100%;

            .risk {
                ::v-deep .text-with-tip {
                    .inline {
                        .value {
                            font-weight: 500;
                        }
                    }
                }
            }

            .risk {
                border-bottom: 1px solid var(--black-100);
            }
        }

        .sub-title {
            font-size: var(--size-tiny);
            font-weight: 600;
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 200px;
        top: 30px;
        left: 25px;
        font-size: var(--size-normal);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .section-title {
        margin: var(--size-small) 0 0 !important;
        background: var(--system-color-success-light, rgba(0, 180, 173, 0.08));
        border-radius: var(--size-femto, 4px);
        font-size: var(--size-small);
        font-weight: 700;
        height: 54px;
        padding: 16px 20px;
    }

    .sub-title {
        font-size: var(--size-tiny);
        font-weight: 600;
        margin: var(--size-small) 0 !important
    }
}
</style>
