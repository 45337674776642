import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useTransforms} from '@/Composables/Transforms';
import {Md5} from 'ts-md5';

export const useGenerator = (): GeneratorParams => {

    const autoFieldId = (): string => {
        return 'id-' + String(Math.random()).replace('.', '');
    }

    const generateHash = (params: DynamicDictionary): string => {
        const flatteredParams: string = useTransforms().flatteredObject(params);

        return 'cache' + String(Md5.hashStr(flatteredParams));
    }

    return {
        autoFieldId,
        generateHash,
    }
}

export interface GeneratorParams {
    autoFieldId: () => string;
    generateHash: (params: DynamicDictionary) => string;
}
