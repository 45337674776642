<script setup lang="ts">
import {computed, onMounted, PropType, ref, Ref, watch} from 'vue';
import {io, Socket} from 'socket.io-client';
import {isMobile} from 'mobile-device-detect';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import QrcodeVue from 'qrcode.vue';
import moment from 'moment';
import {useDefine} from '@/Composables/Define';
import {useFormatter} from '@/Composables/Formatter';
import {TranslateReplaceParts, useTranslate} from '@/Composables/Translate';
import UploadFile from '@/interfaces/file.upload.interface';
import SettingsService from '@/services/settings.service';
import FileResizer from '@/services/file.resize.service';
import Popup from '@/services/popup.service';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import DataLayer from '@/services/data.layer.service';
import FileThumbnail from '@/interfaces/file.thumbnail.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import Error from '@/services/error.service';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import CssClass from '@/Enums/CssClassEnum';
import FilesUploader from '@/Enums/FileUploadEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import OneDate from '@/assets/libraries/Date/OneDate';

const props = defineProps({
    componentName: {type: String, default: 'FilesUploader'},
    translationType: {type: String, default: 'components'},
    dataLayerEvent: {type: String, default: ''},
    dataStoreDisabled: {type: Boolean, default: false},
    uploader: {type: String, default: 'uploader'},
    uploadCategories: {type: Array as PropType<string[]>, default: () => ['']},
    renew: {type: Boolean, default: false},
    isFilesUploadAreaVisible: {type: Boolean, default: true},
    isMobileUpload: {type: Boolean, default: false},
    isUploadRestricted: {type: Boolean, default: false},
    minDate: {type: Date, default: null},
});
const emit = defineEmits(['files-uploader-ready', 'end-upload']);
const {isSet} = useDefine();
const {translate, translateForType} = useTranslate();
const {formattedUrl} = useFormatter();
const request: AxiosParams = useAxios();
const componentIsReady: Ref<boolean> = ref(false);
const reference: Ref<string> = ref('');
const uploadFiles: Ref<UploadFile[]> = ref([]);
const uploadCategoryId: Ref<number> = ref(0);
const uploadCumulativeSizeBytes: Ref<number> = ref(0);
const temporaryUploadCumulativeSizeBytes: Ref<number> = ref(0);
const showQrCode: Ref<boolean> = ref(false);
const isTouched: Ref<boolean> = ref(false);
const uploadInProgress: Ref<boolean> = ref(false);

const filesCount: Ref<number> = computed((): number => {
    return uploadFiles.value.filter((file: UploadFile) => file.id !== '').length;
});

const filesNotUploaded: Ref<boolean> = computed((): boolean => {
    let result: boolean = true;
    uploadFiles.value.forEach((file: UploadFile) => {
        if (file.class === CssClass.FileUploadSuccess) {
            result = false;
        }
    });

    return result;
});

const uploadsSuccessful: Ref<boolean> = computed((): boolean => {
    let result: boolean = true;
    uploadFiles.value.forEach((file: UploadFile) => {
        if (file.class !== CssClass.FileUploadSuccess && result) {
            result = false;
        }
    });

    return result;
});

const filesLimitNotExceed: Ref<boolean> = computed((): boolean => {
    return uploadFiles.value.length < Number(settingsService().value(FilesUploader.Settings.FilesMax));
});

const categories: Ref<Array<string>> = computed((): Array<string> => {
    return props.uploadCategories.length > 0 ? props.uploadCategories : [''];
});

let socket!: Socket;

watch(() => props.isFilesUploadAreaVisible, async (value) => {
    if (value) {
        initFileUploader();
    }
});

onMounted((): void => {
    if (props.isFilesUploadAreaVisible) {
        initFileUploader();
    }
});

function initFileUploader(): void {
    if (!componentIsReady.value) {
        uploadReference(props.isMobileUpload);
        if (!props.isMobileUpload && !isMobile) {
            showQrCode.value = true;
        }
        emit('files-uploader-ready');
    }
}

function initSocket(): void {
    socket = io(settingsService().uploadWsUrl());
    socket.on(FilesUploader.SocketCommand.Connect, () => {
        socket.emit(FilesUploader.SocketCommand.JoinRoom, {room: reference.value});
    });
    socket.on(FilesUploader.SocketCommand.FilesAdded, () => {
        socket.emit(FilesUploader.SocketCommand.FilesFetch, {room: reference.value}, (files: UploadFile[]) => {
            let collectionFiles: UploadFile[] = files.filter(filesFromWebSocket(uploadFiles.value));
            if (collectionFiles.length > 0) {
                uploadedFiles();
            }
        });
    })
    socket.on(FilesUploader.SocketCommand.FileRemoved, (file: UploadFile) => {
        let index: number = uploadFiles.value.length - 1;
        while (index >= 0) {
            if (uploadFiles.value[index].id === file.id) {
                uploadFiles.value.splice(index, 1);
            }
            index -= 1;
        }
    });
    socket.on(FilesUploader.SocketCommand.RoomClosed, () => {
        emit('end-upload')
    });
}

function addDesktopUploadParams(formData: FormData): void {
    formData.append(FilesUploader.Field.Uploader, props.uploader);
    formData.append(FilesUploader.Field.Renew, props.renew.toString());
    formData.append(FilesUploader.Field.UploadCategories, JSON.stringify(props.uploadCategories));
}

function addMobileUploadParams(formData: FormData): void {
    const reference: string = routeReference();
    formData.append(FilesUploader.Field.Reference, reference);
}

function uploadReference(isMobileUpload: boolean): void {
    const formData: FormData = new FormData();
    const url: string = FilesUploader.Url.UploadReference;
    const config: AxiosRequestConfig = {headers: {'Content-Type': 'form-data'}};
    isMobileUpload ? addMobileUploadParams(formData) : addDesktopUploadParams(formData);
    request.post(url, formData, config)
        .then((response: AxiosResponse<DynamicDictionary>) => {
            if (response.data.data.status === 'ERROR') {
                if (isMobileUpload) {
                    const popup: SimpleError = new OnePopup()
                        .withType()
                        .simpleError
                        .withDescription(translateForType(FilesUploader.Error.UploadErrorWrongReference, props.translationType))
                        .withConfirmCallback(redirectToReferenceErrorPage);
                    PopupService.getInstance().show(popup);
                }
            }
            reference.value = response.data.data.body.reference;
            uploadedFiles();
            initSocket();
        }).catch((reason) => {
        onError('btar_error_common', true);
        Error.log(ErrorType.Error, props.componentName, FilesUploader.Error.UploadFetchInvalid, reason);
    });
}

function routeReference(): string {
    const route: string = FilesUploader.MobileUploadUrl;
    const href: string = window.location.href;

    return href.substring(href.lastIndexOf(route) + route.length + 1);
}

function mobileLink(): string {
    let mobileUrl: string = '';
    if (reference.value !== '') {
        mobileUrl = location.protocol + '//' + location.hostname
            + formattedUrl(FilesUploader.MobileUploadUrl + '/' + reference.value);
    }

    return mobileUrl;
}

async function uploadedFiles(): Promise<void> {
    const formData: FormData = new FormData();
    const url: string = FilesUploader.Url.SharedFiles;
    const config: AxiosRequestConfig = {headers: {'Content-Type': 'form-data'}};
    formData.append(FilesUploader.Field.Reference, reference.value);
    formData.append(FilesUploader.Field.Uploader, props.uploader);
    request.post(url, formData, config)
        .then((response: AxiosResponse<DynamicDictionary>) => {
            if (response.data.data.body.files.length > 0) {
                uploadFiles.value = response.data.data.body.files;
                uploadFiles.value.forEach((uploadFile: UploadFile) => {
                    uploadFile.name = sanitizeString(uploadFile.name);
                    uploadFile.class = CssClass.FileUploadSuccess;
                    uploadCumulativeSizeBytes.value += uploadFile.sizeBytes;
                    temporaryUploadCumulativeSizeBytes.value += uploadFile.sizeBytes;
                });
            }
        }).catch((reason) => {
        onError('btar_error_common', true);
        Error.log(ErrorType.Error, props.componentName, FilesUploader.Error.UploadFetchInvalid, reason);
    }).finally(() => {
        componentIsReady.value = true;
    });
}

function filesFromWebSocket(files: UploadFile[]): any {
    return function (current: UploadFile) {
        return files.filter(function (find: UploadFile) {
            return find.name === current.name || find.id === current.id
        }).length === 0;
    }
}

function filesFromCategory(categoryId: number): UploadFile[] {
    return uploadFiles.value.filter(file => file.categoryId === categoryId);
}

function uploadSubmit(selectedFiles: FileList): void {
    if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
            const uploadFile: File = selectedFiles[i];
            if (isMaxUploadFiles(selectedFiles.length)) {
                onError(FilesUploader.Error.UploadFilesMax);
                break;
            }
            if (isDuplicateFile(uploadFile)) {
                onError(FilesUploader.Error.UploadFileDuplicate);
                break;
            }
            if (!isAllowedFileType(uploadFile)) {
                onError(FilesUploader.Error.UploadWrongFileType);
                break;
            }
            if (!isAllowedFileSize(uploadFile)) {
                onError(FilesUploader.Error.UploadWrongFileSize);
                break;
            }
            if (!isFileDateValid(uploadFile)) {
                onError(FilesUploader.Error.UploadFileInvalidDate);
                break;
            }
            temporaryUploadCumulativeSizeBytes.value += uploadFile.size;
            const isNonResizedFileMimeType: boolean = !String(settingsService().value(FilesUploader.Settings.ResizableMimeTypes)).includes(uploadFile.type);
            if (isNonResizedFileMimeType && !isAllowedUploadSizeBytes(temporaryUploadCumulativeSizeBytes.value)) {
                temporaryUploadCumulativeSizeBytes.value -= uploadFile.size;
                onError(FilesUploader.Error.UploadFilesCumulativeSize);
                break;
            }
            fileResizerService().resizedFile(uploadFile).then((resizedFile: UploadFile) => {
                temporaryUploadCumulativeSizeBytes.value -= uploadFile.size;
                temporaryUploadCumulativeSizeBytes.value += resizedFile.sizeBytes;
                if (isAllowedUploadSizeBytes(temporaryUploadCumulativeSizeBytes.value)) {
                    resizedFile.name = sanitizeString(resizedFile.name);
                    uploadFiles.value.push(resizedFile);
                    postFile(uploadFile, resizedFile);
                } else {
                    temporaryUploadCumulativeSizeBytes.value -= uploadFile.size;
                    onError(FilesUploader.Error.UploadFilesCumulativeSize);
                }
            });
        }
    }
}

function postFile(file: File, uploadFile: UploadFile): void {
    const formData: FormData = new FormData();
    const url: string = FilesUploader.Url.SubmitFile;
    const config: AxiosRequestConfig = {headers: {'Content-Type': 'form-data'}};
    uploadInProgress.value = true;
    uploadFile.categoryId = uploadCategoryId.value;
    formData.append(FilesUploader.Field.Reference, reference.value);
    formData.append(FilesUploader.Field.Uploader, props.uploader);
    formData.append(FilesUploader.Field.CategoryId, uploadCategoryId.value.toString());
    formData.append(FilesUploader.Field.Categories, JSON.stringify(categories.value));
    formData.append(FilesUploader.Field.File, file);
    formData.append(FilesUploader.Field.FileBase64, uploadFile.fileBase64.split(',').pop()!);
    formData.append(FilesUploader.Field.MimeType, uploadFile.mime);
    request.post(url, formData, config)
        .then((response: AxiosResponse<DynamicDictionary>) => {
            if (response.data.data.status === 'ERROR') {
                throw response.data.data.body.error;
            }
            uploadFile.id = response.data.data.body.file.id;
            uploadFile.mime = response.data.data.body.file.mime;
            uploadFile.sizeBytes = response.data.data.body.file.sizeBytes;
            if (isSet(response.data.data.body.file.fileThumbnail)) {
                uploadFile.fileThumbnail = response.data.data.body.file.fileThumbnail;
            }
            uploadFile.class = CssClass.FileUploadSuccess;
        }).catch(() => {
        uploadFile.class = CssClass.FileUploadFail;
    }).finally(() => {
        uploadInProgress.value = false;
        uploadCumulativeSizeBytes.value += uploadFile.sizeBytes;
        pushDataLayer();
    });
}

function removeFile(id: string): void {
    const uploadFile: UploadFile = uploadFiles.value.find((uploadFile: UploadFile) => uploadFile.id === id) as UploadFile;
    if (isSet(uploadFile)) {
        recalculateFilesCumulativeSize(uploadFile.sizeBytes);
        const formData: FormData = new FormData();
        const url: string = FilesUploader.Url.RemoveFile;
        const config: AxiosRequestConfig = {headers: {'Content-Type': 'form-data'}};
        formData.append(FilesUploader.Field.Uploader, props.uploader);
        formData.append(FilesUploader.Field.Id, id);
        formData.append(FilesUploader.Field.CategoryId, uploadCategoryId.value.toString());
        props.isMobileUpload ? formData.append(FilesUploader.Field.Reference, reference.value) : formData.append(FilesUploader.Field.Uploader, props.uploader);
        uploadFiles.value.splice(uploadFiles.value.findIndex((uploadFile: UploadFile) => uploadFile.id === id), 1);
        request.post(url, formData, config)
            .catch(() => {
                Error.log(ErrorType.Error, props.componentName, FilesUploader.Error.UploadErrorRemoveFile);
            })
    }
}

function removeFiles(): void {
    uploadCumulativeSizeBytes.value = 0;
    temporaryUploadCumulativeSizeBytes.value = 0;
    const categoryFiles: UploadFile[] = filesFromCategory(uploadCategoryId.value);
    categoryFiles.forEach((file: UploadFile) => {
        removeFile(file.id);
    });
}

function clearUploads(): void {
    uploadCumulativeSizeBytes.value = 0;
    temporaryUploadCumulativeSizeBytes.value = 0;
    uploadFiles.value = [];
    componentIsReady.value = false;
    initFileUploader();
}

function touch(): void {
    isTouched.value = true;
}

function onDragOver(event: DragEvent): void {
    event.dataTransfer!.dropEffect = 'copy';
}

function onFilesDrop(event: DragEvent): void {
    props.isFilesUploadAreaVisible
        ? uploadSubmit(event.dataTransfer!.files)
        : onError(FilesUploader.Error.UploadFilesMax);
}

function onError(errorMessage: string, isGenericError: boolean = false): void {
    const replaceTranslations: TranslateReplaceParts = {'%mindate%': OneDate.short(props.minDate)};
    const errorDescription: string = isGenericError
        ? translate(errorMessage, replaceTranslations)
        : translateForType(errorMessage, props.translationType, replaceTranslations);
    const popup: SimpleError = new OnePopup()
        .withType()
        .simpleError
        .withDescription(errorDescription);
    PopupService.getInstance().show(popup);
}

function preventFilesLimitExceed(event: DragEvent): void {
    if (!filesLimitNotExceed.value) {
        event.preventDefault();
        if (!filesLimitNotExceed.value) {
            onError(FilesUploader.Error.UploadFilesMax);
        }
    }
}

function recalculateFilesCumulativeSize(sizeBytes: number): void {
    if (Number(uploadCumulativeSizeBytes.value) > 0) {
        uploadCumulativeSizeBytes.value -= sizeBytes;
        temporaryUploadCumulativeSizeBytes.value -= sizeBytes;
    } else {
        uploadCumulativeSizeBytes.value = 0;
    }
}

function isMaxUploadFiles(selectedFilesCount: number): boolean {
    return uploadFiles.value.length + selectedFilesCount > Number(settingsService().value(FilesUploader.Settings.FilesMax)) + 1;
}

function isDuplicateFile(file: File): boolean {
    return uploadFiles.value.findIndex((uploadFile: UploadFile) => uploadFile.name === file.name) !== -1 && file.name !== 'image.jpg';
}

function isAllowedFileType(file: File): boolean {
    return String(settingsService().value(FilesUploader.Settings.FileAllowedTypes))
        .includes(file!.name.split('.').pop()!.toLowerCase());
}

function isAllowedFileSize(file: File): boolean {
    return file.size <= Number(settingsService().value(FilesUploader.Settings.FileSizeMax));
}

function isAllowedUploadSizeBytes(uploadSizeBytes: number): boolean {
    return uploadSizeBytes <= Number(settingsService().value(FilesUploader.Settings.FilesCumulativeSizeMaxBytes));
}

function isFileDateValid(file: File): boolean {
    let result: boolean = true;
    if (props.isUploadRestricted) {
        const validFromDate: Date = moment().subtract(settingsService().uploadRestrictionDays(), 'd').toDate();
        result = moment.utc(file.lastModified).isSameOrAfter(validFromDate);
    }
    if (isSet(props.minDate)) {
        result = moment.utc(file.lastModified).isSameOrAfter(moment.utc(props.minDate), 'day');
    }

    return result;
}

function categoryNotEmpty(categoryId: number): boolean {
    return filesFromCategory(categoryId).length > 0;
}

function onToggleCategory(categoryId: number): void {
    if (uploadCategoryId.value !== categoryId) {
        uploadCategoryId.value = categoryId;
    }
}

function fileUploading(status: string): boolean {
    return status === CssClass.FileUploadProgress;
}

function redirectToReferenceErrorPage(): void {
    window.location.href = FilesUploader.WrongReferenceRedirect;
}

function pushDataLayer(): void {
    const source: string = props.isMobileUpload
        ? DataLayerFacilities.UploadSourceQr
        : DataLayerFacilities.UploadSourcePortal;
    if (props.dataLayerEvent) {
        DataLayer.getInstance()
            .addRootParam(FilesUploader.Field.Event, props.dataLayerEvent)
            .addRootParam(FilesUploader.Field.Form, source)
            .buildAndPush();
    }
}

function categoryStatusIconClass(categoryId: number): string {
    return categoryNotEmpty(categoryId)
        ? CssClass.FileUploadCategoryUploaded
        : uploadCategoryId.value === categoryId
            ? CssClass.FileUploadCategoryEmpty
            : CssClass.FileUploadCategoryCollapsed;
}

function fileThumbnail(fileThumbnail: FileThumbnail | null): string {
    return !fileWithoutThumbnail(fileThumbnail!)
        ? 'background-image: url(data:image/jpg;base64,' + fileThumbnail!.fileBase64 + ')'
        : '';
}

function fileWithoutThumbnail(fileThumbnail: FileThumbnail | null): boolean {
    return !isSet(fileThumbnail) || fileThumbnail!.fileBase64.length === 0;
}

function categoryTogleButtonClass(categoryId: number): string {
    return uploadCategoryId.value === categoryId
        ? CssClass.FileUploadButtonExpand
        : CssClass.FileUploadButtonCollapse;
}

function settingsService(): SettingsService {
    return SettingsService.getInstance();
}

function fileResizerService(): FileResizer {
    return FileResizer.getInstance();
}

function popupService(): Popup {
    return Popup.getInstance();
}

function endUpload() {
    socket.emit(FilesUploader.SocketCommand.CloseRoom, {'room': reference.value});
}

function sanitizeString(name: string): string {
    return name
        .replaceAll('<', '&lt;')
        .replaceAll('>', '&gt;')
        .replaceAll('/', '&#47;')
        .replaceAll('\\', '&#92;');
}

defineExpose({
    componentIsReady,
    filesCount,
    filesNotUploaded,
    uploadInProgress,
    uploadsSuccessful,
    touch,
    clearUploads,
    endUpload,
    filesFromCategory,
});
</script>
<template>
    <div class="files-uploader"
         ref="container"
         @dragover="onDragOver"
         @dragover.prevent
         @drop="onFilesDrop"
         @drop.prevent>
        <div v-for="(categoryCaption, categoryId) in categories" :key="categoryId">
            <div
                :class="{'category': categoryCaption, 'clickable':uploadCategoryId !== categoryId,'invalid': filesCount === 0 && isTouched}">
                <div class="category-header"
                     v-if="categoryCaption"
                     @click="onToggleCategory(categoryId)">
                <span class="icon-category-button"
                      :class="categoryStatusIconClass(categoryId)">
                </span>
                    <span class="category-title"
                          :class="{'clickable': uploadCategoryId !== categoryId}"
                          v-html="translate(categoryCaption)">
                </span>
                    <span class="icon-category-button"
                          :class="categoryTogleButtonClass(categoryId)">
                </span>
                </div>
                <div v-show="uploadCategoryId === categoryId">
                    <div class="files-uploader-area" :class="{'spacer': categoryCaption}">
                        <div class="item upload-container">
                            <div class="icon-open-finder" disabled="!componentIsReady"></div>
                            <div class="item-desktop hint" disabled="!componentIsReady">{{
                                    translateForType('file_upload_add_files', translationType)
                                }}
                            </div>
                            <label class="upload-zone" for="fileupload"></label>
                            <input id="fileupload"
                                   type="file"
                                   multiple
                                   style="opacity: 0;"
                                   @change="uploadSubmit($event.target.files);$event.target.value = '';"
                                   @click="preventFilesLimitExceed($event)">
                        </div>
                        <div class="item qr"
                             v-if="showQrCode">
                            <div class="qr-code">
                                <qrcode-vue
                                    :value="mobileLink()"
                                    :size="136">
                                </qrcode-vue>
                            </div>
                            <span class="hint">{{
                                    translateForType('file_upload_qr_code_description', translationType)
                                }}</span>
                        </div>
                    </div>
                    <div class="loading-files" v-if="!componentIsReady && !uploadInProgress">
                        <app-content-loader :icon-color="'green'"></app-content-loader>
                    </div>
                    <div class="files-area">
                        <div v-for="(file, index) in filesFromCategory(categoryId)"
                             :key="index">
                            <div class="file"
                                 :class="file.class"
                                 :title="file.name"
                                 :style="fileThumbnail(file.fileThumbnail)">
                                <div class="loading" v-if="fileUploading(file.class)">
                                    <app-content-loader :icon-type="'spinner'"></app-content-loader>
                                </div>
                                <div class="fileInfo" v-else>
                                    <div class="deleteButton" @click="removeFile(file.id)"></div>
                                    <div class="fileType"
                                         v-if="fileWithoutThumbnail(file.fileThumbnail)">{{ file.type }}
                                    </div>
                                    <div class="fileName"
                                         v-html="file.name"
                                         v-if="fileWithoutThumbnail(file.fileThumbnail)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="button outside"
                            v-if="categoryNotEmpty(categoryId)"
                            @click="removeFiles()">
                        {{ translateForType('file_upload_button_delete_files', props.translationType) }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>

$assetPath: 'one/components/files-uploader/';

@mixin image($src) {
    background: var(--component-color-background-base) image(#{$assetPath} + $src) no-repeat center center;
}

@mixin icon($src, $backgroundColor) {
    background: $backgroundColor image(#{$assetPath} + $src) no-repeat 50% 50%;
    border-radius: 50%;
}

.files-uploader {
    width: 100%;

    .category {
        background-color: var(--component-color-background-base);
        border: 2px solid var(--white);
        padding: 24px;
        border-radius: 8px;
        margin-bottom: var(--size-tiny);
    }

    .category-header {
        display: flex;
        align-items: center;

        .category-title {
            display: inline-flex;
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            width: 100%;
            margin-left: 18px;
            pointer-events: all;
        }

        .icon-category-button {
            display: inline-flex;
            width: 24px;
            height: 24px;
            background-position: 50% 50%;
            pointer-events: all;
        }

        .category-collapsed {
            cursor: pointer;
            @include image('icon-empty.svg');
        }

        .category-empty {
            @include image('icon-empty.svg');
        }

        .category-uploaded {
            @include image('icon-uploaded.svg');
            cursor: pointer;
        }

        .button-collapse {
            @include image('arrow-right.svg');
            display: none;
            width: 12px;
            cursor: pointer;

            @include respond-above('lg') {
                display: block;
            }
        }

        .button-expand {
            @include image('arrow-down.svg');
            display: none;
            width: 8px;

            @include respond-above('lg') {
                display: block;
            }
        }
    }

    .spacer {
        padding-top: 24px;
    }

    .clickable {
        cursor: pointer;
    }

    .files-uploader-area {
        display: flex;
        width: 100%;

        .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--black-200);
            border-radius: 9px;
            min-height: 52px;

            @include respond-above('lg') {
                min-height: 244px;
                width: 50%;

                &:not(:last-child) {
                    margin-right: var(--size-normal);
                }
            }

            &.upload-container {
                position: relative;
                background-color: var(--teal-50);
                border: 2px dashed var(--teal-400);

                .icon-open-finder {
                    @include image('file-icon-small.svg');
                    display: flex;
                    margin-top: var(--size-normal);
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;

                    @include respond-above('lg') {
                        @include image('file-icon.svg');
                        width: 100px;
                        height: 100px;
                    }
                }

                .upload-zone {
                    cursor: pointer;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }

            .hint {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                text-align: center;
                font-size: var(--font-size-nano);
                padding: 12px;
                max-width: 264px;
            }

            .item-mobile {
                display: flex;

                @include respond-above('lg') {
                    display: none;
                }
            }

            .item-desktop {
                display: none;
                padding-left: 0;
                padding-right: 0;

                @include respond-above('lg') {
                    display: flex;
                }
            }

            .qr-code {
                margin-top: 8px;
            }

            &.qr {
                display: none;
                flex-direction: column;

                @include respond-above('lg') {
                    display: flex;
                }

                img {
                    width: 136px !important;
                    height: 136px !important;
                    image-rendering: pixelated;
                }
            }
        }
    }

    .loading-files {
        margin: var(--size-big) 0 0 var(--size-big);
    }

    .files-area {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .file {
            background-color: var(--background-light);
            margin-right: 13px;
            margin-top: 13px;
            width: 80px;
            height: 80px;
            border-radius: 9px;

            &.fail {
                outline: solid 2px var(--system-color-error-default);
            }

            .loading {
                display: flex;
                width: 100%;
                height: 100%;
                text-align: center;
                justify-content: center;
            }

            .fileInfo {
                .deleteButton {
                    @include icon('delete.svg', var(--white));
                    position: relative;
                    display: flex;
                    top: 6px;
                    right: -58px;
                    width: 16px;
                    height: 16px;
                    margin-bottom: 21px;
                    border-radius: 50%;
                    cursor: pointer;
                }

                .fileType {
                    padding-left: var(--size-pico);
                    color: var(--component-color-icon-default);
                    font-size: var(--font-size-nano);
                }

                .fileName {
                    padding-left: var(--size-pico);
                    font-size: 13px;
                    width: 68px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    @include respond-above('lg') {
        flex-direction: row;
    }

    .invalid {
        border: 2px solid var(--brand-red);
    }

    .outside {
        margin-top: var(--size-nano);
    }
}
</style>
