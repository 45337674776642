import AppCountry from '@/assets/libraries/app/app-country';
import DateFormat from '@/Enums/DateFormatEnum';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import moment from 'moment';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class ExtractDataService {

    public birthDateFromPersonCode(personCode: string, localeIso: string = new AppCountry().iso()): Date | undefined {
        switch (localeIso) {
            case 'EE':
            case 'LT':
                return this.birthDateFromPersonCodeEEandLT(personCode.toString());
            case 'LV':
                return this.birthDateFromPersonCodeLV(personCode.toString());
            default:
                Error.log(ErrorType.Error, 'extractData::birthDateFromPersonCode', 'Unknown app country ISO');
                break;
        }
    }

    private birthDateFromPersonCodeEEandLT(personCode: string): Date | undefined {
        if (personCode === '') {
            Error.log(ErrorType.Error, 'extractData::birthDateFromPersonCodeEEandLT', 'Invalid date');
            return;
        }
        const endOfMonthDay: number = 7;
        const endOfYear: number = 3;
        const year: string = personCode.slice(1, endOfYear);
        const dateString: string = (Number(year) > Number(moment().format('YY'))
            ? '19' : '20') + year + personCode.slice(3, endOfMonthDay);

        return moment(dateString, 'YYYYMMDD').toDate();
    }

    private birthDateFromPersonCodeLV(personCode: string): Date | undefined {
        if (personCode === '') {
            return;
        }
        const tempCode: string = String(personCode)
            .replace(/[^0-9]/g, '');
        const minLength: number = 7;
        const endOfMonthDay: number = 4;
        const century: number = 6;
        let result;
        if (tempCode.length >= minLength) {
            const centuryNumber: number = Number(tempCode.slice(century, century + 1));
            const dayMonth: string = tempCode.slice(0, endOfMonthDay);
            const year: string = ExtractDataService.millenium(centuryNumber)
                + tempCode.slice(endOfMonthDay, endOfMonthDay + 2);
            const dateString: string = [dayMonth, year].join('');
            result = moment(dateString, 'DDMMYYYY').toDate();
        }

        return result;
    }

    public formatPersonCode(personCode: string, localeIso: string = new AppCountry().iso()): string {
        let result: string = personCode.toString();
        if (localeIso === 'LV') {
            result = this.formatPersonCodeLV(result);
        }

        return result;
    }

    private formatPersonCodeLV(personCode: string): string {
        const result: string = personCode.replace('-', '');
        const personCodeCut: number = 6;

        return result.slice(0, personCodeCut) + '-' + result.slice(personCodeCut);
    }

    public ageFromBirthDate(birthDate: string): number {
        return moment().diff(moment(birthDate, DateFormat.Default.Short), 'years');
    }

    public dateWithCustomFormat(date: Date, format: string = 'YYYY-MM-DD'): string {
        return moment(date).format(format);
    }

    private static millenium(centuryNumber: number): string {
        const centuries: DynamicDictionary = {
            1: '19',
            2: '20',
        }

        return centuries[centuryNumber];
    }
}
