export default class TaxiValidator {
    public static readonly minTaxiPlateNumberLength: number = 5;

    public isValidTaxiPlateNumber(value: string, localeIso: string): boolean {
        let result: boolean;
        const taxiPlatePatternEE: RegExp = /^(T\d{5})/;
        const taxiPlatePatternLT: RegExp = /^T\d{5}$/g;
        const taxiPlatePatternLV: RegExp = /^((TQ|TX|EX)\d{5})/;
        switch (localeIso) {
            case 'EE':
                result = taxiPlatePatternEE.test(value);
                break;
            case 'LT':
                result = taxiPlatePatternLT.test(value);
                break;
            case 'LV':
                result = taxiPlatePatternLV.test(value);
                break;
            default:
                result = value.length >= TaxiValidator.minTaxiPlateNumberLength;
        }

        return result;
    }
}
