<script setup lang="ts">
import { computed, onMounted, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import SettingsService from '@/services/settings.service';
import { useDefine } from '@/Composables/Define';
import Payment from '@/Components/PolicyBlock/Interfaces/PaymentInterface';
import BreakPoints from '@/Enums/BreakPointsEnum';
import { useDebounce } from '@/Composables/Debounce';
import AgreementTypeMap from '@/Components/PolicyBlock/Classes/AgreementTypeMap';
import OneDate from '@/assets/libraries/Date/OneDate';
import InsurancesTypes from '@/pages/OneDashboard/Insurances/Enums/InsurancesTypesEnum';
import SubscriptionStatus from '@/Components/PolicyBlock/Enums/SubscriptionStatusEnum';

const props = defineProps({
    item: {
        type: Object, default: () => {
            return {};
        }
    },
});

const emit = defineEmits(['make-payment', 'view-policy', 'view-offer', 'renew-policy']);

const {translateForType, translate} = useTranslate();
const {isSet} = useDefine();
const {debounce} = useDebounce();

const translationType: string = 'dashboard';
const specialOfferIcon: string = 'images/one/components/policy-block/special-offer.svg';
const defaultIconName: string = 'other';
const debounceTimeout: number = 800;
const resizeDebounce: Function = debounce(onResize, debounceTimeout);

let screenWidth: number = window.innerWidth;

const statusText: Ref<string> = computed(() => {
    return isSpecialOffer()
        ? translated('btar_one_dashboard_special_offer')
        : insuranceStatus();
});

const startDate: Ref<string> = computed(() => {
    return isSet(props.item.validFrom) && isSet(props.item.validFrom.date) ?
        OneDate.short(props.item.validFrom.date) : '';
});

const endDate: Ref<string> = computed(() => {
    return isSet(props.item.validTo) && isSet(props.item.validTo.date) ?
        OneDate.short(props.item.validTo.date) : '';
});

const paymentDate: Ref<string> = computed(() => {
    return isSet(props.item.payment.dueDate)
        ? OneDate.short(props.item.payment.dueDate.date)
        : '';
});

const showPolicyPeriod: Ref<boolean> = computed(() => {
    return new AgreementTypeMap().isTravelAgreement(props.item.typeId);
});

const dateRange: Ref<string> = computed(() => {
    return startDate.value + ' - ' + endDate.value;
});

const showValidityDate: Ref<boolean> = computed(() => {
    return !new AgreementTypeMap().isTravelAgreement(props.item.typeId)
        && props.item.isActive;
});

const validityDateText: Ref<string> = computed(() => {
    const prefix: string = 'btar_one_dashboard_';
    let suffix: string = props.item.isUpcoming || props.item.isSubscription
        ? 'valid_from' : 'valid_till';

    return translated(prefix + suffix);
});

const additionalInfoText: Ref<string> = computed(() => {
    let result: string;
    switch (true) {
        case showAdditionalTextForTravel.value:
            result = translated('one_dashboard_insured_travelers', {count: props.item.insuredObjectCount});
            break;
        case showAdditionalTextForProperty.value:
            result = translated('one_dashboard_insured_objects', {count: props.item.insuredObjectCount});
            break;
        case showAdditionalTextForAccident.value:
            result = translated('one_dashboard_insured_persons', {count: props.item.insuredObjectCount});
            break;
        case showAdditionalTextForHealth.value:
            result = translated('one_dashboard_insured_persons', {count: props.item.insuredObjectCount});
            break;
        default:
            result = '';
            break;
    }

    return result;
});

const offerDueDate: Ref<string> = computed(() => {
    return OneDate.short(props.item.dueDate!.date);
});

const showAdditionalTextForTravel: Ref<boolean> = computed(() => {
    return new AgreementTypeMap().isTravelAgreement(props.item.typeId)
        && isHolder.value
        && props.item.insuredObjectCount > 1;
});

const showAdditionalTextForProperty: Ref<boolean> = computed(() => {
    return new AgreementTypeMap().isPropertyAgreement(props.item.typeId)
        && props.item.insuredObjectCount > 1;
});

const showAdditionalTextForHealth: Ref<boolean> = computed(() => {
    return new AgreementTypeMap().isHealthAgreement(props.item.typeId)
        && isHolder.value
        && props.item.insuredObjectCount > 1;
});

const showAdditionalTextForAccident: Ref<boolean> = computed(() => {
    return new AgreementTypeMap().isAccidentAgreement(props.item.typeId)
        && isHolder.value
        && props.item.insuredObjectCount > 1;
});

const payment: Ref<Payment> = computed(() => {
    return props.item.payment;
});

const iconSrc: Ref<string> = computed(() => {
    return `images/one/policy-icons/${isInactive() ? 'grey/' : ''}${agreementType.value || defaultIconName}.svg`;
});

const agreementType: Ref<string> = computed(() => {
    return new AgreementTypeMap().byTypeId(props.item.typeId);
});

const isLargeScreen: Ref<boolean> = computed(() => {
    return screenWidth > BreakPoints.Lg;
});

const showPriceText: Ref<boolean> = computed(() => {
    return !(isLargeScreen.value && screenWidth < BreakPoints.Lp);
});

const isHolder: Ref<boolean> = computed(() => {
    return !!props.item.holder;
});

const isIndividualPayment: Ref<boolean> = computed(() => {
    return props.item.isIndividual;
});

onMounted((): void => {
    window.addEventListener('resize', () => resizeDebounce());
});

function onViewPolicy(): void {
    emit('view-policy', props.item);
}

function makePayment(): void {
    emit('make-payment', props.item);
}

function onViewOffer(): void {
    emit('view-offer', props.item);
}

function onRenewPolicy(): void {
    emit('renew-policy', props.item);
}

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, translationType, replacements);
}

function paymentStatus(): string {
    let result: string;
    switch (true) {
        case payment.value.isLate:
            result = translated('btar_one_dashboard_payment_status_late');
            break;
        case payment.value.isUpcoming:
            result = translated('btar_one_dashboard_payment_status_upcoming');
            break;
        default:
            result = '';
            break;
    }

    return result;
}

function insuranceStatus(): string {
    let result: string;
    if (props.item.isUpcoming) {
        result = translated('btar_one_dashboard_insurance_status_upcoming');
    } else {
        result = props.item.isActive ?
            translated('btar_one_dashboard_insurance_status_active') :
            translated('one_dashboard_insurance_status_ended');
    }

    return result;
}

function isSpecialOffer(): boolean {
    return props.item.type === InsurancesTypes.Offers;
}

function isPaymentStatusBadgeVisible(): boolean {
    const isNotOffer: boolean = !isSpecialOffer();
    const paymentIsLateOrUpcoming: boolean = payment.value.isLate || payment.value.isUpcoming;
    const isHolderOrIndividualPayment: boolean = isHolder.value || isIndividualPayment.value;

    return isNotOffer && paymentIsLateOrUpcoming && isHolderOrIndividualPayment;
}

function isEndingSoonBadgeVisible(): boolean {
    return props.item.isEndingSoon && !payment.value.isLate &&
        !(props.item.isSubscription && props.item.status === SubscriptionStatus.Approved) &&
        SettingsService.getInstance().endingSoonBadgePolicyTypes().includes(props.item.typeId);
}

function isRenewedBadgeVisible(): boolean {
    return props.item.isRenewed && SettingsService.getInstance().renewedBadgePolicyTypes().includes(props.item.typeId);
}

function isPayButtonVisible(): boolean {
    return [
        !isSpecialOffer(),
        hasLatePaymentWithAmount(),
        isPolicyHolderOrHasIndividualPayment(),
    ].every((value: boolean): boolean => value);
}

function hasLatePaymentWithAmount(): boolean {
    return isSet(payment.value) && isSet(payment.value.amount) && payment.value.isLate;
}

function isPolicyHolderOrHasIndividualPayment(): boolean {
    return isHolder.value || isIndividualPayment.value;
}

function isRenewButtonVisible(): boolean {
    return !isSpecialOffer() && isHolder.value &&
        !props.item.isSubscription && props.item.isEndingSoon && !payment.value.isLate &&
        SettingsService.getInstance().renewablePolicyTypes().includes(props.item.typeId);
}

function isInactive(): boolean {
    return !props.item.isActive && !isSpecialOffer();
}

function onResize(): void {
    screenWidth = window.innerWidth;
}
</script>

<template>
    <div v-if="item" class="policy-block"
         :data-block="isSpecialOffer() ? 'offer' : agreementType">
        <div class="insurance-type">
            <div :class="{'title-icon': !isSpecialOffer(), 'inactive' : isInactive()}">
                <img :src="isSpecialOffer() ? specialOfferIcon : iconSrc" alt="option-icon">
            </div>
            <span class="type" :class="{'inactive': isInactive()}">{{ item.name }}</span>
        </div>
        <div class="wrapper">
            <div class="insured-item">
                <div class="text name" v-mask-analytics>{{ item.objectName }}</div>
                <div v-if="showPolicyPeriod" class="text additional-info">{{ dateRange }}</div>
                <div class="text additional-info">{{ additionalInfoText }}</div>
            </div>
            <div class="status-container">
                <div class="insurance-info-container">
                    <div class="text status" :class="{'ended': isInactive()}">{{ statusText }}</div>
                    <div v-if="isRenewedBadgeVisible()" class="text white-border">
                        {{ translated('btar_one_dashboard_renewed') }}
                    </div>
                    <div v-if="isEndingSoonBadgeVisible()" class="text white-border red">
                        {{ translated('btar_one_dashboard_ending_soon') }}
                    </div>
                    <div v-if="item.isSubscription" class="text white-border">
                        {{ translated('btar_one_dashboard_subscription') }}
                    </div>
                    <div class="text additional" v-if="isSpecialOffer()">
                        {{ translated('offer_due', {'%date%': offerDueDate}) }}
                    </div>
                    <div v-if="showValidityDate" class="text additional">
                        {{ validityDateText }}
                        {{ item.isSubscription || item.isUpcoming ? startDate : endDate }}
                    </div>
                    <div v-if="isInactive()" class="text additional">
                        {{ translated('one_dashboard_insurance_ended_on') }} {{ endDate }}
                    </div>
                </div>
                <div v-if="isPaymentStatusBadgeVisible()" class="payment-info-container">
                    <div class="text payment-status"> {{ paymentStatus() }}</div>
                    <div class="text date"> {{ translated('btar_one_dashboard_due') }}
                        {{ paymentDate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="button-container">
            <button v-if=!isSpecialOffer() class="button white outside" @click="onViewPolicy()">
                {{ translated('one_dashboard_insurances_view_details') }}
            </button>
            <button v-if="isPayButtonVisible()" class="button red no-wrap" @click="makePayment()">
                {{ translated('btar_one_dashboard_insurances_pay_now') }}
                {{
                    showPriceText ? ' \u00B7 ' + payment.amount.toString() + ' ' + translate('btar_currency') : ''
                }}
            </button>
            <button v-if="isRenewButtonVisible()" data-type="renew-now-button" class="button green"
                    @click="onRenewPolicy()">
                {{ translated('one_dashboard_insurances_renew_now') }}
            </button>
            <button v-if="isSpecialOffer()" class="button green" @click="onViewOffer()">
                {{ translated('btar_dashboard_view_offer') }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-block {
    display: flex;
    flex-direction: column;
    background-color: var(--component-color-background-base);
    border-radius: 8px;
    width: 100%;
    min-height: 119px;
    padding: var(--size-normal);
    font-size: var(--font-size-nano);
    font-weight: 600;
    box-shadow: 0 1px 0 var(--black-300);

    @include respond-above('lg') {
        flex-direction: row;
        padding: 0 var(--size-medium);
    }

    .insurance-type {
        display: flex;
        width: 100%;
        margin: auto 0;
        flex-shrink: 0;

        @include respond-above('lg') {
            width: 200px;
        }

        .type {
            margin-left: var(--size-small);
            align-self: center;
            font-weight: 700;
            padding-right: var(--size-small);
            max-width: 120px;
        }

        .title-icon {
            display: flex;
            width: 52px;
            height: 52px;
            background-color: var(--blue-100);
            border-radius: 26px;
            justify-content: center;
            align-items: center;

            img {
                max-width: 25px;
                max-height: 25px;
            }
        }

        .title-icon.inactive {
            background-color: var(--background-light);
        }

        .type.inactive {
            color: var(--component-color-text-value);
        }
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        width: auto;
        margin: var(--size-small) 0;

        @include respond-above('lg') {
            margin: var(--size-normal) var(--size-normal) var(--size-normal) 0;
            border-left: 1px solid var(--component-color-border-default);
            padding-left: var(--size-huge);
        }

        .text {
            align-self: center;
        }

        .white-border {
            border-radius: 8px;
            padding: 9px var(--size-nano);
            border: 1px solid var(--teal-300);
            color: var(--brand-teal);

            &.red {
                color: var(--brand-red);
                border: 1px solid var(--red-100);
            }
        }

        .insured-item {
            display: flex;
            flex-direction: column;
            gap: 8px;

            @include respond-above('lg') {
                flex-direction: row;
            }

            .name {
                font-size: var(--font-size-tiny);
                align-self: baseline;
            }

            .additional-info {
                font-weight: 500;
                color: var(--black-500);
                white-space: nowrap;
                align-self: baseline;

                @include respond-above('lg') {
                    margin: 10px;
                }
            }
        }

        .status-container {
            display: flex;
            flex-direction: column;
            margin-top: var(--size-small);
            font-size: var(--font-size-pico);

            @include respond-above('lg') {
                text-align: center;
                margin-top: 10px;
                flex-direction: row;
            }

            .insurance-info-container {
                display: flex;
                flex-direction: row;
                gap: 8px;

                .status {
                    padding: 10px var(--size-nano);
                    color: var(--brand-teal);
                    border-radius: 8px;
                    background-color: var(--teal-200);

                    &.ended {
                        color: var(--component-color-text-disabled);
                        background-color: var(--component-color-background-disabled);
                    }
                }

                .additional {
                    margin-left: auto;
                }
            }

            .payment-info-container {
                display: flex;
                justify-content: space-between;
                margin-top: var(--size-small);
                border-top: 1px solid var(--component-color-border-default);
                gap: 8px;

                @include respond-above('lg') {
                    border-top: none;
                    margin: 0 0 0 var(--size-big);
                }

                .payment-status {
                    margin-top: var(--size-small);
                    padding: 10px var(--size-nano);
                    color: var(--brand-red);
                    border-radius: 8px;
                    background-color: var(--red-100);

                    @include respond-above('lg') {
                        margin: 0;
                    }
                }

                .date {
                    margin-top: var(--size-small);

                    @include respond-above('lg') {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .button-container {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include respond-above('lg') {
            flex-direction: row;
            margin-left: auto;
            align-items: center;
        }

        .button {
            height: 52px;
            border-radius: 8px;
            padding: 0 var(--size-small);

            @include respond-above('lg') {
                width: 150px;
            }

            @include respond-above('lp') {
                min-width: 183px;
            }
        }

        .light-grey {
            background: var(--black-100);
            color: var(--component-color-text-value);

            &:hover {
                border: 1px solid;
            }
        }

        .green {
            &:hover,
            &:focus {
                background-color: var(--teal-950);
            }
        }

        .no-wrap {
            white-space: nowrap;
        }
    }
}
</style>
