export default class PolicyPeriodStrings {
    public static readonly MonthlyPayment: string = 'monthlyPayment';
    public static readonly NineMonthsPayment: string = 'nineMonthsPayment';
    public static readonly OneYearPayment: string = 'oneYearPayment';
    public static readonly OneMonthPayment: string = 'oneMonthPayment';
    public static readonly TwoMonthPayment: string = 'twoMonthPayment';
    public static readonly SixMonthsPayment: string = 'sixMonthsPayment';
    public static readonly SubscriptionPayment: string = 'subscriptionPayment';
    public static readonly ThreeMonthsPayment: string = 'threeMonthsPayment';
}
