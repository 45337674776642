<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { usePrice } from '@/Composables/Price';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import InsurancePlanWidgetEmitters from '@/Components/InsurancePlanWidget/Enums/InsurancePlanWidgetEmitters';
import { PropType } from 'vue';
import InsurancePlan from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanItem from '@/Components/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';

const {sparse} = usePrice();

const props = defineProps({
    insurancePlan: {
        type: Object as PropType<InsurancePlan>, default: () => {
            return {
                title: '',
                priceTitle: '',
                price: 0,
                discount: 0,
                items: [],
                component: '',
            }
        }
    },
});
const {translate} = useTranslate();
const emit = defineEmits([InsurancePlanWidgetEmitters.Continue]);

function continueButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_continue'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}

function itemPrice(item: InsurancePlanItem): string {
    return sparse(item.price, false) + props.insurancePlan.paymentFrequency;
}

function zeroPrice(): string {
    return useHtml().imgTag(successIconAttributes()) + '&nbsp;' + translate('free_of_charge');
}

function successIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    }
}

function fullPrice(): string {
    const price: number = props.insurancePlan.price + props.insurancePlan.discount;

    return sparse(price, false);
}

function onContinueClick(): void {
    emit(InsurancePlanWidgetEmitters.Continue);
}

</script>

<template>
    <div class="insurance-plan-widget"
         :id="'insurance-plan-widget'">
        <div class="title">{{ props.insurancePlan.title }}</div>
        <div class="list-container">
            <div class="list-item"
                 :key="index"
                 v-for="(item, index) in insurancePlan.items"
                 :id="'insurance-plan-item-' + index">
                <div class="title">
                    <span class="item-name">{{ item.title }}</span>
                    <span class="item-price"
                          v-if="item.price > 0"
                          v-html="itemPrice(item)"></span>
                    <span class="item-zero-price"
                          v-if="item.price === 0"
                          v-html="zeroPrice()"></span>
                </div>
                <div class="description"
                     v-if="item.description"
                     v-html="item.description"></div>
            </div>
        </div>
        <div class="payment-info">
            <span>{{ props.insurancePlan.priceTitle }}</span>
            <span class="price">
                <span class="sum">{{ sparse(props.insurancePlan.price, false) }}</span>
                <span class="frequency" v-html="insurancePlan.paymentFrequency"></span>
            </span>
        </div>
        <div class="discount-info" v-if="insurancePlan.discount">
            <span class="sum-full">{{ fullPrice() }}&nbsp;&euro;</span>
            <span class="sum-discount">
                {{ translate('btar_save_title') }}&nbsp;
                {{ props.insurancePlan.discount }}&nbsp;&euro;</span>
        </div>
        <app-button-with-callback
            v-bind="continueButtonParams()"
            @button-callback-click="onContinueClick">
        </app-button-with-callback>
    </div>
</template>

<style lang="scss" scoped>
.insurance-plan-widget {
    width: 100%;
    background-color: var(--white);
    padding: var(--size-small);
    border-radius: var(--size-tiny);

    .title {
        font-size: var(--size-normal);
        font-weight: 700;
    }

    .list-container {
        margin: var(--size-normal) 0;
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--black-100);
        border-bottom: 1px solid var(--black-100);

        .list-item {
            padding: var(--size-small) 0;
            display: flex;
            flex-direction: column;

            .title {
                display: flex;
                justify-content: space-between;

                .item-name {
                    font-weight: initial;
                    font-size: var(--font-size-nano);
                }

                .item-price {
                    font-weight: 700;
                    font-size: var(--font-size-tiny);
                }

                .item-zero-price {
                    color: var(--text-color-link);
                    font-size: var(--font-size-tiny);
                }
            }

            .description {
                font-size: var(--font-size-nano);
                color: var(--black-400);
                margin-top: var(--size-femto);
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--black-100);
            }
        }
    }

    .payment-info {
        display: flex;
        justify-content: space-between;
        height: 38px;

        .price {
            color: var(--text-color-link);
            font-size: var(--font-size-small);
            font-weight: 700;
            line-height: 39px;

            .sum {
                font-size: var(--size-medium);
            }
        }
    }

    .discount-info {
        height: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: var(--font-size-nano);
        margin-top: var(--size-nano);

        .sum-full {
            text-decoration: line-through;
            text-decoration-color: var(--system-color-error-default);
            color: var(--text-subtle);
        }

        .sum-discount {
            color: var(--system-color-success-dark);
            padding: var(--size-femto) var(--size-pico);
            margin-left: var(--size-pico);
            background-color: var(--system-color-success-light);
            border-radius: var(--size-femto);
        }
    }

    .button-with-callback {
        width: 100%;
        height: 52px;
        margin-top: var(--size-medium);
    }

    @include respond-above('sm') {
        padding: var(--size-big);
        width: 400px;
    }
}
</style>
