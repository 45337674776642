import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PriceType from '@/Enums/PriceTypeEnum';
import {useDefine} from '@/Composables/Define';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import UserStorage from '@/services/user.storage.service';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import AutoStorageService from '@/pages/Auto/Services/AutoStorageService';
import SettingsService from '@/services/settings.service';

export const useAutoStorage = (): AutoStorage => {

    const {isSet} = useDefine();

    const storageDataPreCalculatedAdditionalPricesByUserType = (
        vehicleProduct: string,
        logged: boolean
    ): DynamicDictionary => {
        const app: OneBase = OneBaseService.getInstance();
        const userType: string = logged ?
            PriceType.AdditionalRiskAuthenticatedPrices : PriceType.AdditionalRiskGuestPrices;
        const productExists: boolean = !app.userStorage.storageIsEmpty &&
            isSet(app.userStorage.storageData.attributes.vehicleProducts[vehicleProduct]) &&
            isSet(app.userStorage.storageData.attributes.vehicleProducts[vehicleProduct][userType]);

        return productExists ?
            app.userStorage.storageData.attributes.vehicleProducts[vehicleProduct][userType] : [];
    }

    const additionalStorageRisks = (): DynamicDictionary[] => {
        let result: DynamicDictionary[] = [];
        const storage: UserStorage = UserStorage.getInstance();
        if (!storage.additionalOptionsStorageIsEmpty) {
            const mtplRisks: DynamicDictionary = storage.storageAdditionalData
                .attributes.vehicleProducts[VehicleProduct.Mtpl][PriceType.AdditionalRiskAuthenticatedPrices] ?? [];
            const cascoRisks: DynamicDictionary = storage.storageAdditionalData
                .attributes.vehicleProducts[VehicleProduct.Casco][PriceType.AdditionalRiskAuthenticatedPrices] ?? [];
            result = result.concat(mtplRisks, cascoRisks);
        }

        return result;
    }

    const storageDataAdditionalPricesByUserType = (vehicleProduct: string, logged: boolean, deductible: number): DynamicDictionary => {
        const app: OneBase = OneBaseService.getInstance();
        const userType: string = logged ? PriceType.AuthenticatedPrices : PriceType.GuestPrices;
        const prices = pricesByUserType(app.userStorage.storageAdditionalData, userType, vehicleProduct, deductible);

        return isSet(prices) ? prices : [];
    }

    const storageDataPricesByUserType = (vehicleProduct: string, logged: boolean, deductible: number): DynamicDictionary => {
        const app: OneBase = OneBaseService.getInstance();
        const userType: string = logged ? PriceType.AuthenticatedPrices : PriceType.GuestPrices;

        return pricesByUserType(app.userStorage.storageData, userType, vehicleProduct, deductible)
    }

    const storageDataPrices = (vehicleProduct: string, deductible: number): DynamicDictionary => {
        return storageDataPricesByUserType(vehicleProduct, OneBaseService.getInstance().user.isLogged(), deductible);
    }

    const defaultEuDeductible = ((): string => {
        const DefaultTerritory: string = 'EU';

        return AutoStorageService.getInstance().defaultDeductibles(DefaultTerritory) ?? '';
    });

    const pricesByUserType =
        (storage: DynamicDictionary, userType: string, vehicleProduct: string, deductible: number): DynamicDictionary => {
            const productExists: boolean = Object.keys(storage).length !== 0 && isSet(storage.attributes.vehicleProducts[vehicleProduct]);
            let price = null;
            if (productExists) {
                if (new AppCountry().isLV() && vehicleProduct === VehicleProduct.Casco) {
                    const cascoProduct = storage.attributes.vehicleProducts[vehicleProduct]['damageDeductiblePremiums'];
                    if (isSet(cascoProduct[deductible])) {
                        price = cascoProduct[deductible][userType];
                    } else {
                        if (defaultEuDeductible() !== '') {
                            price = cascoProduct[defaultEuDeductible()][userType];
                        } else {
                            const deductibleFromSettings: number = new SettingsService().defaultDeductible() ?? 0;
                            const deductiblesFromProduct: number[] = Object.keys(cascoProduct).map(deductible => Number(deductible));
                            const deductible: number = deductibleFromSettings === 0
                                ? Math.min(...deductiblesFromProduct)
                                : Math.max(...deductiblesFromProduct);
                            price = cascoProduct[deductible][userType];
                        }
                    }
                } else {
                    price = storage.attributes.vehicleProducts[vehicleProduct][userType];
                }
            }

            return price;
        }

    const applyAdditionalOptionsSum = (options: DynamicDictionary | null = null): void => {
        if (!options) {
            return;
        }
        const app: OneBase = OneBaseService.getInstance();
        if (hasAdditionalStorageVehicleProducts()) {
            const storageProducts: DynamicDictionary = app.userStorage.storageAdditionalData;
            if (isSet(options.attributes.vehicleProducts)) {
                const products: DynamicDictionary = options.attributes.vehicleProducts;
                for (const key in products) {
                    storageProducts.attributes.vehicleProducts[key] = products[key];
                }
                app.userStorage.applyAdditionalOptionsStorage(storageProducts);
            }
        } else {
            app.userStorage.applyAdditionalOptionsStorage(options);
        }
    }

    const hasAdditionalStorageVehicleProducts = (): boolean => {
        const app: OneBase = OneBaseService.getInstance();

        return isSet(app.userStorage.storageAdditionalData.attributes?.vehicleProducts);
    }

    const defaultDeductibles = (territoryCode: string): string => {
        let result: string = '';
        const app: OneBase = OneBaseService.getInstance();
        if (isSet(app.userStorage.storageData.attributes) &&
            isSet(app.userStorage.storageData.attributes.vehicleDeductibleOptions) &&
            isSet(app.userStorage.storageData.attributes.vehicleDeductibleOptions.defaultDeductibles) &&
            isSet(app.userStorage.storageData.attributes.vehicleDeductibleOptions.defaultDeductibles[territoryCode])
        ) {
            result = app.userStorage.storageData.attributes.vehicleDeductibleOptions.defaultDeductibles[territoryCode];
        }

        return result;
    }

    return {
        storageDataPreCalculatedAdditionalPricesByUserType,
        additionalStorageRisks,
        storageDataAdditionalPricesByUserType,
        storageDataPricesByUserType,
        storageDataPrices,
        applyAdditionalOptionsSum,
        hasAdditionalStorageVehicleProducts,
        defaultDeductibles,
    }
}

export interface AutoStorage {
    storageDataPreCalculatedAdditionalPricesByUserType:
        (vehicleProduct: string, logged: boolean) => DynamicDictionary;
    additionalStorageRisks: () => DynamicDictionary[];
    storageDataAdditionalPricesByUserType: (vehicleProduct: string, logged: boolean, deductible: number) => DynamicDictionary;
    storageDataPricesByUserType: (vehicleProduct: string, logged: boolean, deductible: number) => DynamicDictionary;
    storageDataPrices: (vehicleProduct: string, deductible: number) => DynamicDictionary;
    applyAdditionalOptionsSum: (options: DynamicDictionary | null) => void;
    hasAdditionalStorageVehicleProducts: () => boolean;
    defaultDeductibles: (territoryCode: string) => string;
}
