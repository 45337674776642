export default class AccidentDataPanelsNames {
    public static readonly TypeOfClaim: string = 'typeOfClaim';
    public static readonly WhenEventHappened: string = 'whenEventHappened';
    public static readonly WhatTimeDidItHappened: string = 'whatTimeDidItHappened';
    public static readonly GuiltyPartyVehicleNumber: string = 'guiltyPartyVehicleNumber';
    public static readonly WhatVehicleWasInvolved: string = 'whatVehicleWasInvolved';
    public static readonly WeFoundElectronically: string = 'weFoundElectronically';
    public static readonly HowWasTheAccidentHandled: string = 'howWasInvolvedHandled';
    public static readonly PoliceProtocolNumber: string = 'policeProtocolNumber';
}
