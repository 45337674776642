import {usePrice} from '@/Composables/Price';
import {useTranslate} from '@/Composables/Translate';
import {VehicleSumInsuredLimit} from '@/pages/Auto/Interfaces/VehicleSumInsuredLimitInterface';
import {reactive, Ref, ref} from 'vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';

const {price} = usePrice();
const {translateForType} = useTranslate();

export class VehicleSumInsuredLimits {
    private limits: Ref<VehicleSumInsuredLimit[]> = ref([]);
    private localLimit: UnwrapNestedRefs<VehicleSumInsuredLimit | object> = reactive({});

    public setLimits(limits: VehicleSumInsuredLimit[]): void {
        this.limits.value = limits;
    }

    public setVehicleType(vehicleType: string): void {
        this.localLimit = {};
        if (vehicleType && this.limits.value.length > 0) {
            this.localLimit =
                (this.limits.value.find(limit => limit.vehicleType === vehicleType) as VehicleSumInsuredLimit) || null;
        }
    }

    public limit(): VehicleSumInsuredLimit | null {
        const limitObject: VehicleSumInsuredLimit = this.localLimit as VehicleSumInsuredLimit;
        return Object.keys(limitObject).length === 0 ? null : limitObject;
    }

    public limitText(): string {
        const currency: string = 'EUR';
        const limit: VehicleSumInsuredLimit | null = this.limit();
        return limit ?
            translateForType(
                'btar_sum_insured_range_hint',
                '',
                {
                    'min': price(limit.minSum, false, currency, true),
                    'max': price(limit.maxSum, false, currency, true)
                }
            ) : '';
    }
}

