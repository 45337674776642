export default class LegalPersonModalState {
    public static readonly None: string = '';
    public static readonly AddPersonsManual: string = 'add-persons-manual';
    public static readonly AddPersonsFromExcel: string = 'add-persons-from-excel';
    public static readonly PersonsProcessingExcel: string = 'persons-processing-excel';
    public static readonly AddPersonsProcessedList: string = 'add-persons-processed-list';
    public static readonly EditPerson: string = 'edit-person';
    public static readonly ViewPerson: string = 'view-person';
    public static readonly DeletePersons: string = 'delete-persons';
    public static readonly DeletePersonsFromExcel: string = 'delete-persons-from-excel';
    public static readonly ProcessingDetails: string = 'processing-details';
}
