import PopupBase from '@/assets/libraries/popups/popup.base';
import PopupType from '@/Enums/PopupTypeEnum';
import { useTranslate } from '@/Composables/Translate';

export default class ClaimSuccess extends PopupBase {
    public constructor() {
        super();
        this.applyType(PopupType.ClaimsSuccessInfo);
    }

    public withTitle(value: string): ClaimSuccess {
        this.applyTitle(value)

        return this;
    }

    public withDescription(value: string): ClaimSuccess {
        this.applyDescription(value);

        return this;
    }

    public withSecondaryDescription(value: string): ClaimSuccess {
        this.applySecondaryDescription(value);

        return this;
    }
}
