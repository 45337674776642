import PolicyDetailsOverviewBuilder
    from '@/Components/PolicyDetailsOverview/Builders/PolicyDetailsOverviewBuilder';
import PolicyDetailsContentTabsBuilder from '@/Components/PolicyDetails/Builders/PolicyDetailsContentTabsBuilder';
import PolicyDetailsContentBuilder from '@/Components/PolicyDetails/Builders/PolicyDetailsContentBuilder';
import DrawerService from '@/services/drawer.service';
import Translations from '@/services/translations.service';
import InsurancesTypes from '@/pages/OneDashboard/Insurances/Enums/InsurancesTypesEnum';
import PolicyDetailsTabs from '@/Components/PolicyDetails/Enums/PolicyDetailsTabsEnum';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import SettingsService from '@/services/settings.service';
import PolicyLimit from '@/interfaces/one_policy/PolicyLimit';
import PolicyRiskChunk from '@/interfaces/one_policy/PolicyRiskChunk';
import InsuredRisksBuilder from '@/Components/InsuredRisks/Builders/InsuredRisksBuilder';
import EventBus from '@/services/event.bus.service';
import InvoiceDocumentBlock from '@/interfaces/one_policy/invoice.document.block.interface';
import RecurringPaymentDetails from '@/interfaces/recurring.payment.details.interface';
import AdjustmentDocumentBlock from '@/interfaces/one_policy/adjustment.document.block.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import UserCredentials from '@/interfaces/user.credentials.interface';
import PolicyDetailsPersonsBuilder from '@/Components/PolicyDetailsPersons/Builders/PolicyDetailsPersonsBuilder';
import PolicyDetailsPaymentMethodBuilder
    from '@/Components/PolicyDetailsPaymentMethod/Builders/PolicyDetailsPaymentMethodBuilder';
import PolicyDetailsObjectsBuilder from '@/Components/PolicyDetailsObjects/Builders/PolicyDetailsObjectsBuilder';
import PolicyDetailsInvoicesBuilder from '@/Components/PolicyDetailsInvoices/Builders/PolicyDetailsInvoicesBuilder';
import Clickable from '@/Components/PolicyDetailsInvoices/Enums/ClickableEnum';
import {HealthCard} from '@/interfaces/resources/health.card.interface';
import Invoice from '@/Components/PolicyBlock/Interfaces/InvoiceInterface';
import InsuredObject from '@/Components/PolicyBlock/Interfaces/InsuredObjectInterface';
import Adjustment from '@/Components/PolicyBlock/Interfaces/AdjustmentInterface';
import Agreement from '@/Components/PolicyBlock/Interfaces/AgreementInterface';
import AgreementTypeMap from '@/Components/PolicyBlock/Classes/AgreementTypeMap';
import SubscriptionStatus from '@/Components/PolicyBlock/Enums/SubscriptionStatusEnum';
import AgreementTypes from '@/Components/PolicyBlock/Enums/AgreementTypesEnum';
import OneDate from '@/assets/libraries/Date/OneDate';
import PolicyState from '@/Enums/OnePolicy/PolicyStateEnum';
import SubscriptionDocument from '@/Enums/OnePolicy/SubscriptionDocumentInterface';

export default class PolicyDetailsPopup {
    private contentTabsBuilder: PolicyDetailsContentTabsBuilder = new PolicyDetailsContentTabsBuilder();
    private drawerOverviewBuilder: PolicyDetailsOverviewBuilder = new PolicyDetailsOverviewBuilder();
    private drawerInvoicesBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
    private drawerDocumentsBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
    private drawerSubscriptionHistoryBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
    private drawerHistoryBuilder: PolicyDetailsInvoicesBuilder = new PolicyDetailsInvoicesBuilder();
    private drawerObjectsBuilder: PolicyDetailsObjectsBuilder = new PolicyDetailsObjectsBuilder();
    private insuredRisksBuilder: InsuredRisksBuilder = new InsuredRisksBuilder();
    private drawerPaymentMethodBuilder: PolicyDetailsPaymentMethodBuilder = new PolicyDetailsPaymentMethodBuilder();
    private drawerPersonsBuilder: PolicyDetailsPersonsBuilder = new PolicyDetailsPersonsBuilder();
    private translations: Translations = Translations.getInstance();
    private invoices: Invoice[] = [];
    private healthCards: HealthCard[] = [];
    private printouts: DynamicDictionary[] = [];
    private upcomingPrintouts: DynamicDictionary[] = [];
    private terms: DynamicDictionary[] = [];
    private objects: InsuredObject[] = [];
    private recurringPayment?: RecurringPaymentDetails;
    private risks: PolicyRiskChunk[] = [];
    private annexes: Adjustment[] = [];
    private history: AdjustmentDocumentBlock[] = [];
    private subscriptionHistory: Agreement[] = [];
    private upcomingAgreements: Agreement[] = [];
    private user: UserCredentials;
    private readonly agreement!: Agreement;
    private readonly translationType: string;

    public constructor(agreement: Agreement, translationType: string, user: UserCredentials) {
        this.agreement = agreement;
        this.translationType = translationType;
        this.user = user;
    }

    public applyInvoices(invoices: Invoice[]): this {
        this.invoices = invoices;

        return this;
    }

    public applyHealthCards(value: HealthCard[]): this {
        this.healthCards = value;

        return this;
    }

    public applyObjects(objects: InsuredObject[]): this {
        this.objects = objects;

        return this;
    }

    public applyRisks(risks: PolicyRiskChunk[]): this {
        this.risks = risks;

        return this;
    }

    public applyAnnexes(value: Adjustment[]): this {
        this.annexes = value;

        return this;
    }

    public applyHistory(value: AdjustmentDocumentBlock[]): this {
        this.history = value;

        return this;
    }

    public applyPrintouts(printouts: DynamicDictionary[]): this {
        this.printouts = printouts;

        return this;
    }

    public applyUpcomingPrintouts(value: DynamicDictionary[]): this {
        this.upcomingPrintouts = value;

        return this;
    }

    public applyRecurringPayment(recurringPayment: RecurringPaymentDetails): this {
        this.recurringPayment = recurringPayment;

        return this;
    }

    public applyTerms(terms: DynamicDictionary[]): this {
        this.terms = terms;

        return this;
    }

    public applySubscriptionHistory(subscriptions: Agreement[]): this {
        this.subscriptionHistory = subscriptions;

        return this;
    }

    public applyUpcomingAgreements(value: Agreement[]): this {
        this.upcomingAgreements = value;

        return this;
    }

    public showDetails(): void {
        if (this.agreement.type === InsurancesTypes.Policies) {
            this.buildPolicy();
            this.buildPolicyTabs();
        } else {
            this.buildOffer();
            this.buildOfferTabs();
        }
        this.buildDrawer();
    }

    private get agreementTypeString(): string {
        return new AgreementTypeMap().byTypeId(this.agreement.typeId);
    }

    private buildPolicy(): void {
        this.buildPolicyOverview();
        this.buildPolicyInvoices();
        this.buildPolicyDocuments();
        this.buildPolicyHistory();
        this.buildPolicyObjects();
        if (this.personsTabIsVisible) {
            this.buildPersons();
        }
        if (this.isMtplAgreement) {
            this.buildPolicySubscriptionHistory();
        }
    }

    private buildPolicyTabs(): void {
        this.buildCommonTabsElements();
        if (this.drawerDocumentsBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Documents)
                .withTitle(this.translations.localized(PolicyDetailsTabs.Documents, this.translationType))
                .withModule(this.drawerDocumentsBuilder.build());
        }
        if (this.drawerSubscriptionHistoryBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.SubscriptionHistory)
                .withTitle(this.translations.localized(PolicyDetailsTabs.SubscriptionHistory, this.translationType))
                .withModule(this.drawerSubscriptionHistoryBuilder.build());
        }
        if (this.drawerHistoryBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.History)
                .withTitle(this.translations.localized(PolicyDetailsTabs.History, this.translationType))
                .withModule(this.drawerHistoryBuilder.build());
        }
        if (this.drawerObjectsBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Objects)
                .withTitle(this.translations.localized(PolicyDetailsTabs.Objects, this.translationType))
                .withBadgeValue(this.drawerObjectsBuilder.elementsCount())
                .withModule(this.drawerObjectsBuilder.build());
        }
        if (this.drawerPaymentMethodBuilder.isEnabled()) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Card)
                .withTitle(this.translations.localized(PolicyDetailsTabs.Card, this.translationType))
        }
        if (this.drawerPersonsBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Persons)
                .withBadgeValue(this.drawerPersonsBuilder.elementsCount())
                .withTitle(this.translations.localized(PolicyDetailsTabs.Persons, this.translationType))
                .withModule(this.drawerPersonsBuilder.build());
        }
    }

    private buildOfferTabs(): void {
        this.buildCommonTabsElements();
        if (this.drawerPersonsBuilder.elementsCount() > 0) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Persons)
                .withBadgeValue(this.drawerPersonsBuilder.elementsCount())
                .withTitle(this.translations.localized(PolicyDetailsTabs.Persons, this.translationType))
                .withModule(this.drawerPersonsBuilder.build());
        }
    }

    private buildOffer(): void {
        this.buildOfferOverview();
        this.buildOfferInvoices();
        if (this.personsTabIsVisible) {
            this.buildPersons();
        }
    }

    private buildPolicyOverview(): void {
        this.drawerOverviewBuilder.reset();
        this.drawerOverviewBuilder
            .withSubscriptionsBlockVisible()
            .withPolicyType(this.agreement.name)
            .withInsuranceType(this.agreement.type)
            .withSubscriptionsBlockIsSubscription(this.agreement.isSubscription)
            .withSubscriptionsBlockAddStateBadge(this.assertPolicyStatus())
            .withSubscriptionsBlockPolicyId(this.agreement.agreementNumber)
            .withSubscriptionsBlockHealthCards(this.transformedCards())
            .withSubscriptionsBlockSubscriptionStatus(this.agreement.subscriptionStatus ?? '')
            .withSubscriptionsBlockInsuredPeriod(this.subscriptionBlockInsuredPeriod())
            .withSubscriptionsBlockActionsBlockVisible();
        this.addAttachments();
        this.buildPolicyOverviewSubscriptionsBlock();
        this.buildPolicyOverviewWidgetBlock();
        this.buildPolicyOverviewNoOverduePaymentsBlock();
        this.buildPolicyOverviewRenewBlock();
        if (this.risksVisibleInOverview) {
            this.buildOverviewInsuredRisksBlock();
        }
        if (this.includeUpcomingPolicyBlock) {
            this.drawerOverviewBuilder.withUpcomingPolicyBlockVisible();
            this.buildPolicyOverviewUpcomingPolicyBlock();
        }
    }

    private buildPolicyInvoices(): void {
        const mappedInvoices: DynamicDictionary = this.mappedInvoices();
        const invoiceCategoryTitles: DynamicDictionary = this.invoiceCategoryTitles();
        const categoryLate: string = String(PolicyState.Late).toLowerCase();
        const categoryUnpaid: string = String(PolicyState.Unpaid).toLowerCase();
        const hasLateInvoices: boolean = this.hasInvoicesByType(categoryLate, mappedInvoices);
        const hasUnpaidInvoices: boolean = this.hasInvoicesByType(categoryUnpaid, mappedInvoices);
        this.drawerInvoicesBuilder
            .withLanguage(this.translations.language)
            .withPaymentWidget()
            .withoutShowMoreButton();
        this.buildPolicyInvoicesDocuments(mappedInvoices, invoiceCategoryTitles);
        if (hasLateInvoices) {
            this.selectAllLatePayments();
        } else if (hasUnpaidInvoices) {
            this.selectFirstUnpaidPayment();
        }
        EventBus.getInstance().subscribe('onPolicyDetailsDocumentClick',
            (invoice: Invoice): void => {
                invoice.selected = !invoice.selected;
            });
    }

    private buildPolicyDocuments(): void {
        if (this.annexes && this.annexes.length > 0 && this.isHolder) {
            this.annexes.forEach((adjustment: Adjustment): void => {
                this.drawerDocumentsBuilder
                    .withLanguage(this.translations.language)
                    .withUseBadge(false)
                    .withShowZeroAmount(false)
                    .withTitle(this.translations.localized('documents'))
                    .startNewDocumentBlock()
                    .startNewDocument()
                    .withDocumentTitle(this.translations.localized('annex'))
                    .withDocumentId(this.agreement.id)
                    .asAdjustment()
                    .withInvoiceId(adjustment.id)
                    .withDocumentAgreement(this.agreement.agreementNumber);
            });
        }
    }

    private buildPolicyHistory(): void {
        if (this.history && this.history.length > 0 && this.isHolder) {
            this.history.forEach((block: AdjustmentDocumentBlock): void => {
                this.drawerHistoryBuilder
                    .withLanguage(this.translations.language)
                    .withUseBadge(false)
                    .withShowZeroAmount(false)
                    .withTitle(this.translations.localized('green_card_and_certificate_history'))
                    .startNewDocumentBlock(block.dateFrom);
                block.documents.forEach((adjustment: Adjustment): void => {
                    this.drawerHistoryBuilder
                        .startNewDocument()
                        .withDocumentTitle(adjustment.name!)
                        .withDocumentPolicyPeriod(OneDate.short(adjustment.dateFrom!.date)
                            + '::' + OneDate.short(adjustment.dateTo!.date))
                        .withDocumentId(this.agreement.id)
                        .asAdjustment()
                        .withInvoiceId(adjustment.id)
                        .withDocumentAgreement(this.agreement.agreementNumber);
                });
            });
        }
    }

    private buildPolicySubscriptionHistory(): void {
        if (this.agreement.isSubscription && this.subscriptionHistory.length > 0) {
            this.subscriptionHistory.forEach((subscription: Agreement): void => {
                this.drawerSubscriptionHistoryBuilder
                    .withLanguage(this.translations.language)
                    .withUseBadge(false)
                    .withTitle(this.translations.localized('subscription_history'))
                    .startNewDocumentBlock(OneDate.custom(subscription.validFrom.date, 'MMM YYYY'))
                    .startNewDocument()
                    .withDocumentId(subscription.id)
                    .withDocumentAgreement(subscription.agreementNumber)
                    .withDocumentAmount(subscription.payment.amount!)
                    .withDocumentPolicyPeriod(
                        OneDate.short(subscription.validFrom.date) + ' - ' + OneDate.short(subscription.validTo.date)
                    )
            });
            this.drawerSubscriptionHistoryBuilder.build();
        }
    }

    private buildPolicyObjects(): void {
        const minObjectsCount: number = 2;
        if (
            new AgreementTypeMap().isPropertyAgreement(this.agreement.typeId) &&
            this.agreement.insuredObjectCount >= minObjectsCount &&
            this.isHolder
        ) {
            this.objects.forEach((insuredObject: InsuredObject): void => {
                this.drawerObjectsBuilder
                    .startNewProperty()
                    .withPropertyName(insuredObject.name);
            });
        }
    }

    private buildPersons(): void {
        this.objects.forEach((insuredPerson: InsuredObject): void => {
            /* TODO: Split `insuredPerson.name` as this value should contain
            insured person's name and name of the program.
            As of now (2023.06.26) `insuredPerson.name` contains person's
            name and personal identification number. */
            this.drawerPersonsBuilder
                .startNewPerson()
                .withPersonName(insuredPerson.name)
                //.withPersonProgram(program)
                .withPersonRisks(insuredPerson.risks);
        });
    }

    private buildOfferOverview(): void {
        this.drawerOverviewBuilder.reset();
        this.drawerOverviewBuilder
            .withSubscriptionsBlockVisible()
            .withPolicyType(this.agreement.name)
            .withInsuranceType(this.agreement.type)
            .withSubscriptionsBlockIsSubscription(this.agreement.isSubscription)
            .withSubscriptionsBlockPolicyId(this.agreement.agreementNumber);
        if (this.isHolder) {
            if (!this.agreement.isSubscription) {
                this.drawerOverviewBuilder.withSubscriptionsBlockPayments(this.agreement.paymentCount!);
            }
            const premium: number = this.agreement.isSubscription && this.isCascoAgreement
                ? this.agreement.payment.amount!
                : this.agreement.premium!;
            this.drawerOverviewBuilder.withSubscriptionsBlockPremium(premium);
        }
        if (this.agreement.isSubscription) {
            this.drawerOverviewBuilder.withSubscriptionsBlockInsuredPeriod(
                OneDate.short(this.agreement.validFrom.date));
        } else {
            this.drawerOverviewBuilder.withSubscriptionsBlockInsuredPeriod(
                OneDate.short(this.agreement.validFrom.date) + ' - ' + OneDate.short(this.agreement.validTo.date));
        }
        this.addAttachments();
        if (this.risksVisibleInOverview) {
            this.buildOverviewInsuredRisksBlock();
        }
        this.drawerOverviewBuilder.build()
    }

    private buildOfferInvoices(): void {
        const offerInvoices: Invoice[] = this.agreement.isSubscription
            ? [this.invoices[0]]
            : this.invoices.filter((invoice: Invoice): boolean =>
                invoice.status === PolicyState.Unpaid || invoice.status === PolicyState.Late);
        this.drawerInvoicesBuilder
            .withLanguage(this.translations.language);
        if (!this.agreement.isSubscription) {
            this.drawerInvoicesBuilder.withPaymentWidget();
        }
        offerInvoices.forEach((invoice: Invoice): void => {
            const payDate: string = invoice.coverageDate
                ? OneDate.short(invoice.coverageDate.date)
                : OneDate.short(invoice.dueDate.date);
            this.drawerInvoicesBuilder
                .startNewDocument()
                .withInvoiceId(invoice.id)
                .withInvoiceNumber(invoice.paymentNumber)
                .withDocumentTitle(invoice.objectName)
                .withDocumentId(invoice.agreementId)
                .withAgreementTypeIc(invoice.agreementTypeIc)
                .withDocumentAgreement(invoice.agreementNumber)
                .withDocumentDueTo(payDate)
                .withDocumentPayType(invoice.status)
                .withDocumentAmount(invoice.amount);
            if (offerInvoices.length > 1) {
                this.drawerInvoicesBuilder.withClickableType(Clickable.Checkbox);
            }
        });
        this.selectFirstPayment();
        EventBus.getInstance().subscribe('onPolicyDetailsDocumentClick',
            (invoice: Invoice): void => {
                invoice.selected = !invoice.selected;
            });
    }

    private buildCommonTabsElements(): void {
        this.contentTabsBuilder
            .startNewTab(PolicyDetailsTabs.Overview)
            .withTitle(this.translations.localized(PolicyDetailsTabs.Overview, this.translationType))
            .withModule(this.drawerOverviewBuilder.build());
        if ((this.isHolder || this.isIndividualPayment) && !this.isBrokerPayment) {
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Invoices)
                .withTitle(this.translations.localized(PolicyDetailsTabs.Invoices, this.translationType))
                .withBadgeValue(this.policyInvoicesBadgeValue());
            this.contentTabsBuilder.withModule(this.drawerInvoicesBuilder.build());
        }
        if ((this.agreement.isActive || this.agreement.isUpcoming) &&
            this.isHolder && this.policyHasRecurringPayment()) {
            const drawerPaymentMethodBuilder: PolicyDetailsPaymentMethodBuilder =
                new PolicyDetailsPaymentMethodBuilder();
            drawerPaymentMethodBuilder
                .withCardNumbers(this.recurringPayment!.cardNumberPart)
                .withCardStatus(this.recurringPayment!.status.toLowerCase());
            this.contentTabsBuilder
                .startNewTab(PolicyDetailsTabs.Card)
                .withTitle(this.translations.localized(PolicyDetailsTabs.Card, this.translationType))
                .withModule(drawerPaymentMethodBuilder.build());
        }
    }

    private policyInvoicesBadgeValue(): number {
        const mustShow: boolean = (this.isHolder || this.isIndividualPayment)
            && !this.isBrokerPayment && !this.isSubscription;
        return mustShow ? this.agreement.paymentCount : 0;
    }

    private policyHasRenewButton(): boolean {
        return this.agreement.type !== InsurancesTypes.Offers &&
            this.isHolder && !this.agreement.isSubscription &&
            this.agreement.isEndingSoon && !this.agreement.payment.isLate &&
            SettingsService.getInstance().renewablePolicyTypes().includes(this.agreement.typeId);
    }

    private get isHolder(): boolean {
        return typeof this.agreement.holder !== 'undefined' && this.agreement.holder.id === this.user.personId;
    }

    private get isIndividualPayment(): boolean {
        return this.agreement.isIndividual;
    }

    private get isInsured(): boolean {
        return !!this.agreement.insured;
    }

    private get isBrokerPayment(): boolean {
        return this.agreement.payment.toBroker!;
    }

    private get isSubscription(): boolean {
        return this.agreement.isSubscription;
    }

    private detailsTitle(): string {
        return this.agreementTypeName() +
            (this.agreement.isSubscription ? ' ' + this.translations.localized('subscription') : '') +
            (this.agreement.type === InsurancesTypes.Offers ? ' ' + this.translations.localized('offer') : '');
    }

    private agreementTypeName(): string {
        return this.translations.hasLocalization('agreement_type_' + this.agreementTypeString, this.translationType)
            ? this.translations.localized('agreement_type_' + this.agreementTypeString, this.translationType)
            : this.translations.localized(this.agreement.name);
    }

    private buildDrawer(): void {
        const contentBuilder: PolicyDetailsContentBuilder = new PolicyDetailsContentBuilder();
        contentBuilder
            .withTranslationsType(this.translationType)
            .withType(this.agreementTypeString)
            .withTitle(this.detailsTitle())
            .withDescriptionTitle(this.agreement.objectName)
            .withTabs(this.contentTabsBuilder.build())
            .withActiveTab(0)
            .withAgreement(this.agreement)
            .withInvoices(this.invoices);
        DrawerService.getInstance()
            .withComponentContent(contentBuilder.build())
            .show();
    }

    private assertPolicyStatus(): string {
        let result: string;
        if (this.agreement.isUpcoming) {
            result = 'upcoming';
        } else {
            result = this.agreement.isActive ? 'active' : 'inactive';
        }

        return result;
    }

    private addAttachments(): void {
        if (this.printouts && this.printouts.length > 0) {
            this.printouts.forEach((printout: DynamicDictionary): void => {
                const url: string = new UrlBuilder()
                    .withLanguage(this.translations.language)
                    .withUri('/Policy/printout/' + printout.link)
                    .build();
                this.drawerOverviewBuilder
                    .withSubscriptionsBlockNewDocument(printout.adjustmentTypeIc, url);
            });
        }
        if (this.upcomingPrintouts && this.upcomingPrintouts.length > 0) {
            this.upcomingPrintouts.forEach((printout: DynamicDictionary): void => {
                const url: string = new UrlBuilder()
                    .withLanguage(this.translations.language)
                    .withUri('/Policy/printout/' + printout.link)
                    .build();
                this.drawerOverviewBuilder
                    .withUpcomingBlockNewDocument(printout.adjustmentTypeIc, url);
            });
        }
        if (this.printouts && this.terms.length > 0) {
            this.terms.forEach((term: DynamicDictionary): void => {
                this.drawerOverviewBuilder.withSubscriptionsBlockNewDocument(term.printoutName, term.printoutFileLink);
            });
        }
    }

    private selectAllLatePayments(): void {
        const categoryLate: string = String(PolicyState.Late).toLowerCase();
        this.drawerInvoicesBuilder.elements.forEach((block: InvoiceDocumentBlock): void => {
            if (block.categoryId === categoryLate) {
                block.documents.forEach((invoice: Invoice): void => {
                    invoice.selected = true;
                });
            }
        });
    }

    private selectFirstUnpaidPayment(): void {
        const categoryUnpaid: string = String(PolicyState.Unpaid).toLowerCase();
        this.drawerInvoicesBuilder.elements.forEach((block: InvoiceDocumentBlock): void => {
            if (block.categoryId === categoryUnpaid) {
                block.documents[0].selected = true;
            }
        });
    }

    private selectFirstPayment(): void {
        this.drawerInvoicesBuilder.elements.forEach((block: InvoiceDocumentBlock): void => {
            block.documents[0].selected = true;
        });
    }

    private mappedInvoices(): DynamicDictionary {
        return this.invoices ? {
                late: this.invoices.filter((invoice: Invoice): boolean => invoice.status === PolicyState.Late),
                unpaid: this.invoices.filter((invoice: Invoice): boolean => invoice.status === PolicyState.Unpaid),
                inProgress: this.invoices.filter((invoice: Invoice): boolean => invoice.status === PolicyState.Pending),
                paid: this.invoices.filter((invoice: Invoice): boolean => invoice.status === PolicyState.Paid
                    || invoice.status === PolicyState.Changes
                    || ((invoice.status === PolicyState.Unpaid || invoice.status === PolicyState.Late)
                        && typeof invoice.acceptedCoveredAmount !== 'undefined'
                        && invoice.uncoveredAmount !== 0.00)
                ),
            }
            : {
                late: [],
                unpaid: [],
                inProgress: [],
                paid: [],
            };
    }

    private invoiceCategoryTitles(): DynamicDictionary {
        return {
            late: 'payments_unpaid_invoices',
            unpaid: 'payments_schedules',
            inProgress: 'payments_pending',
            paid: 'payments_history',
        };
    }

    private hasInvoicesByType(type: string, mappedInvoices: DynamicDictionary): boolean {
        return Object.keys(mappedInvoices)
            .filter((paymentCategory: string): boolean => {
                return paymentCategory === type && mappedInvoices[paymentCategory].length > 0
            }).length > 0;
    }

    private buildPolicyInvoicesDocuments(
        mappedInvoices: DynamicDictionary,
        invoiceCategoryTitles: DynamicDictionary
    ): void {
        Object.keys(mappedInvoices).forEach((paymentCategory: string): void => {
            let invoices: Invoice[] = mappedInvoices[paymentCategory];
            const showSingleInvoice: boolean = this.agreement.isSubscription
                && paymentCategory.toUpperCase() === PolicyState.Unpaid
            if (invoices.length > 0) {
                if (showSingleInvoice) {
                    invoices = [invoices[0]];
                }
                this.drawerInvoicesBuilder
                    .startNewDocumentBlock('', this.translations.localized(
                            invoiceCategoryTitles[paymentCategory],
                            this.translationType),
                        paymentCategory
                    );
                invoices.forEach((invoice: Invoice, index: number): void => {
                    this.drawerInvoicesBuilder
                        .startNewDocument()
                        .withInvoiceId(invoice.id)
                        .withInvoiceNumber(invoice.paymentNumber)
                        .withDocumentTitle(invoice.objectName)
                        .withDocumentId(invoice.agreementId)
                        .withAgreementTypeIc(invoice.agreementTypeIc)
                        .withDocumentAgreement(invoice.agreementNumber)
                        .withDocumentPayDate(this.payDate(invoice, paymentCategory.toUpperCase()))
                        .withDocumentPayType(invoice.status)
                        .withPaymentScheduleTypeAsIndividual(invoice.isIndividual)
                        .withDocumentAmount(this.invoiceAmount(invoice, paymentCategory.toUpperCase()))
                        .withInvoiceVisibility(index === 0);
                    if (paymentCategory.toUpperCase() === PolicyState.Late
                        || paymentCategory.toUpperCase() === PolicyState.Unpaid) {
                        this.drawerInvoicesBuilder
                            .withClickableType(Clickable.Checkbox);
                    }
                    if ((invoice.status === PolicyState.Unpaid || invoice.status === PolicyState.Late)
                        && paymentCategory.toUpperCase() === PolicyState.Paid) {
                        this.drawerInvoicesBuilder.asPartialPayment();
                    }
                });
            }
        });
    }

    private invoiceAmount(invoice: Invoice, type: PolicyState): number {
        let result: number = invoice.amount;
        switch (type) {
            case PolicyState.Paid:
                if (invoice.acceptedCoveredAmount && invoice.uncoveredAmount !== 0.00) {
                    result = invoice.acceptedCoveredAmount!;
                }
                break;
            case PolicyState.Unpaid:
            case PolicyState.Late:
                if (invoice.acceptedCoveredAmount && invoice.uncoveredAmount !== 0.00) {
                    result = invoice.uncoveredAmount;
                }
                break;
            default:
                break;
        }

        return result;
    }

    private payDate(invoice: Invoice, type: PolicyState): string {
        let result: string = invoice.dueDate.date;
        switch (type) {
            case PolicyState.Paid:
                if ((invoice.acceptedCoveredAmount && invoice.uncoveredAmount !== 0.00)
                    || invoice.coverageDate) {
                    result = invoice.coverageDate!.date;
                }
                break;
            default:
                break;
        }

        return OneDate.short(result);
    }

    private buildPolicyOverviewSubscriptionsBlock(): void {
        if (this.isHolder) {
            const premium: number = this.agreement.isSubscription && this.isCascoAgreement
                ? this.agreement.payment.amount!
                : this.agreement.premium!;
            this.drawerOverviewBuilder
                .withSubscriptionsBlockPremium(premium)
                .withSubscriptionsBlockBrokerPayments(this.agreement.payment.toBroker!);
            if (!this.isSubscription) {
                this.drawerOverviewBuilder.withSubscriptionsBlockPayments(this.agreement.paymentCount!)
            }
        }
    }

    private buildPolicyOverviewUpcomingPolicyBlock(): void {
        const upcomingPolicy: Agreement = this.upcomingAgreements[0];
        this.drawerOverviewBuilder
            .withUpcomingPolicyBlockPolicyId(upcomingPolicy.agreementNumber)
            .withUpcomingPolicyBlockPrice(upcomingPolicy.premium!)
            .withUpcomingPolicyBlockInsuredPeriod(this.upcomingPolicyBlockInsuredPeriod(upcomingPolicy));
    }

    private buildPolicyOverviewWidgetBlock(): void {
        if (this.agreement.payment.isLate && (this.isHolder || this.agreement.isIndividual)) {
            this.drawerOverviewBuilder.withPaymentWidgetEnabled()
                .withPaymentWidgetInvoiceCount(this.agreement.payment.lateUnpaidInvoiceCount as number)
                .withPaymentWidgetAmountToPay(Number(this.agreement.payment.amount).toFixed(2))
                .withPaymentWidgetAdditionalButtonText(
                    this.translations.localized('button_view_and_pay_invoices', this.translationType)
                );
        }
    }

    private buildPolicyOverviewNoOverduePaymentsBlock(): void {
        if (this.showNoOverduePaymentsBlock) {
            this.drawerOverviewBuilder.withNoOverduePaymentsBlock();
        }
    }

    private buildPolicyOverviewRenewBlock(): void {
        if (this.agreement.isEndingSoon && this.isHolder &&
            !this.agreement.isSubscription && this.policyHasRenewButton()
        ) {
            this.drawerOverviewBuilder.withRenewBlockEnabled()
                .withRenewBlockMessage(this.translations.localized(
                    'renew_policy_message',
                    this.translationType,
                    {
                        '%policy_type%': this.agreementTypeName(),
                        '%object_name%': this.agreement.objectName,
                        '%policy_end_date%': OneDate.short(this.agreement.validTo.date),
                    }))
                .withRenewBlockDetails(this.agreement);
        }
    }

    private transformedCards(): HealthCard[] {
        this.healthCards.forEach((card: HealthCard): void => {
            const tempPrintouts: DynamicDictionary[] = card.printoutChunks || [];
            card.printouts = [];
            tempPrintouts.forEach((printout: DynamicDictionary): void => {
                const url: string = new UrlBuilder()
                    .withLanguage(this.translations.language)
                    .withUri('/Policy/printout/' + printout.link)
                    .build();
                card.printouts!.push(
                    new class implements SubscriptionDocument {
                        public title: string = printout.adjustmentTypeIc;
                        public url: string = url;
                        public extension: string = '';
                    });
            });
            card.printoutChunks = [];
        });

        return this.healthCards;
    }

    private buildOverviewInsuredRisksBlock(): void {
        if (this.risks.length > 0) {
            this.risks.forEach((risk: PolicyRiskChunk): void => {
                this.insuredRisksBuilder.startNewRisk()
                    .withRiskIc(risk.id)
                    .withRiskName(risk.name)
                    .withRiskInsuredSum(risk.sum);
            });
            this.drawerOverviewBuilder.withInsuredRisksBlock()
                .withInsuredRisks(this.insuredRisksBuilder.build());
        }
    }

    private isLimitVisible(limit: PolicyLimit): boolean {
        return limit.isVisible === undefined || limit.isVisible;
    }

    private policyHasRecurringPayment(): boolean {
        return this.recurringPayment !== null;
    }

    private unpaidInvoicesCount(): number {
        return this.invoices
            .filter((invoice: Invoice): boolean => invoice.status === PolicyState.Unpaid).length;
    }

    private subscriptionBlockInsuredPeriod(): string {
        let insurancePeriod: string = OneDate.short(this.agreement.validFrom.date) +
            ' - ' + OneDate.short(this.agreement.validTo.date);
        if (this.agreement.isSubscription) {
            insurancePeriod = OneDate.short(this.agreement.subscriptionValidFrom!.date);
            if (this.agreement.subscriptionStatus === SubscriptionStatus.Terminated) {
                insurancePeriod += ' - ' + OneDate.short(this.agreement.subscriptionValidTo!.date);
            } else {
                insurancePeriod += ' - ...';
            }
        }

        return insurancePeriod;
    }

    private upcomingPolicyBlockInsuredPeriod(agreement: Agreement): string {
        return OneDate.short(agreement.validFrom.date) + ' - ' + OneDate.short(agreement.validTo.date);
    }

    private get isMtplAgreement(): boolean {
        return new AgreementTypeMap().isMtplAgreement(this.agreement.typeId);
    }

    private get isCascoAgreement(): boolean {
        return new AgreementTypeMap().isCascoAgreement(this.agreement.typeId);
    }

    private get risksVisibleInOverview(): boolean {
        return (this.isInsured && !this.isHolder)
            || (this.isHolder && this.agreement.insuredObjectCount === 1);
    }

    private get includeUpcomingPolicyBlock(): boolean {
        return this.isHolder
            && this.agreement.isSubscription
            && this.agreement.subscriptionStatus !== SubscriptionStatus.Terminated
            && this.isMtplAgreement
            && (new AppCountry()).isLV()
            && this.agreement.upcomingAgreements.length > 0;
    }

    private get personsTabIsVisible(): boolean {
        return [
                AgreementTypes.Accident,
                AgreementTypes.Travel,
            ].includes(this.agreementTypeString) && this.objects.length > 1
            && this.isHolder;
    }

    private get showNoOverduePaymentsBlock(): boolean {
        const latePaymentsCount: number = this.agreement.payment.lateUnpaidInvoiceCount ?? 0;
        const unpaidInvoicesCount: number = this.unpaidInvoicesCount();
        const agreementPaymentsCount: number = latePaymentsCount + unpaidInvoicesCount;
        const minPaymentsCount: number = 1;

        return ((this.agreement.isActive || this.agreement.isUpcoming)
                && this.isHolder
                && !this.isBrokerPayment
                && !this.policyHasRecurringPayment())
            && (agreementPaymentsCount > minPaymentsCount);
    }
}
