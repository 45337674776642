import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import VueApp from '@/Core/Routing/VueApp';
import App from '@/Apps/ClaimsMtpl/App.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';

import AccidentDataPage from '@/Apps/ClaimsMtpl/Pages/AccidentDataPage.vue';
import DamagePage from '@/Apps/ClaimsMtpl/Pages/DamagePage.vue';
import AccidentDetailsPage from '@/Apps/ClaimsMtpl/Pages/AccidentDetailsPage.vue';
import ExtraDetailsPage from '@/Apps/ClaimsMtpl/Pages/ExtraDetailsPage.vue';
import UploadFilesPage from '@/Apps/ClaimsMtpl/Pages/UploadFilesPage.vue';
import InsuranceIndemnityReceiverPage from '@/Apps/ClaimsMtpl/Pages/InsuranceIndemnityReceiverPage.vue';
import YourContactDetailsPage from '@/Apps/ClaimsMtpl/Pages/YourContactDetailsPage.vue';
import BelongingsDamage from '@/Apps/ClaimsMtpl/Components/BelongingsDamage.vue';
import PersonDamage from '@/Apps/ClaimsMtpl/Components/PersonDamage.vue';
import PropertyDamage from '@/Apps/ClaimsMtpl/Components/PropertyDamage.vue';
import VehicleDamage from '@/Apps/ClaimsMtpl/Components/VehicleDamage.vue';


Vue.use(VueRouter);

Vue.component('AccidentDataPage', AccidentDataPage);
Vue.component('DamagePage', DamagePage);
Vue.component('AccidentDetailsPage', AccidentDetailsPage);
Vue.component('ExtraDetailsPage', ExtraDetailsPage);
Vue.component('UploadFilesPage', UploadFilesPage);
Vue.component('InsuranceIndemnityReceiverPage', InsuranceIndemnityReceiverPage);
Vue.component('YourContactDetailsPage', YourContactDetailsPage);

Vue.component('AppStepperLayout', StepperLayout);
Vue.component('AppRouteStepper', RouteStepper);

Vue.component('AppBelongingsDamage', BelongingsDamage);
Vue.component('AppPersonDamage', PersonDamage);
Vue.component('AppPropertyDamage', PropertyDamage);
Vue.component('AppVehicleDamage', VehicleDamage);


export default class {
    public app(selector: string, routes: BackendRoute[]) {
        return new VueApp(App).withRoutes(routes).mount(selector);
    }
}
