<script lang="ts">
import Vue, {defineComponent, reactive, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import ProductBlockItem from '@/Components/ProductBlock/Interfaces/ProductBlockItemInterface';
import ProductBlockCoveredPopup from '@/Components/ProductBlock/classes/ProductBlockCoveredPopup';
import {Subscription} from 'rxjs';
import {useScroll} from '@/Composables/Scroll';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import {useTranslate} from '@/Composables/Translate';
import ProductBlockItemBuilder from '@/Components/ProductBlock/Builders/ProductBlockItemBuilder';
import FormField from '@/assets/libraries/form/form-field';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import {AxiosResponse} from 'axios';
import { Bicycle } from '@/interfaces/resources/Bicycles/BicycleInterface';
import { CoverageRisk } from '@/interfaces/resources/Bicycles/CoverageRiskInterface';
import { CoveragePlan } from '@/interfaces/resources/Bicycles/CoveragePlanInterface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import {CoveredPopupBuilder} from '@/pages/Bicycle/Classes/CoveredPopupBuilder';
import CoveredPopupTypes from '@/Components/CoveredPopup/enums/CoveredPopupTypes';
import {VueApp} from '@/Types/VueAppType';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const getInTouchModule: VueApp = new Vue(GetInTouchModule);
        const {translate, translateForType} = useTranslate();
        const request: AxiosParams = useAxios();
        const stepsSubmitter = useStepsSubmitter();
        const CurrentStep: number = 1;
        const Facility: string = 'bicycle_policy';
        const TranslationType: string = 'bicycle_policy';
        const ProductsContainerId: string = 'bicycleProducts';
        const BadgeTextSuffix: string = '_BADGE_TEXT';
        const IcTranslationSuffix: string = '_MAIN';
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const programs: Ref<ProductBlockItem[]> = ref([]);
        const program: Ref<string> = ref('');

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            fetchProducts();
        }

        function fetchProducts(): void {
            request.get(Url.Ajax.bicycles, {})
                .then((response: AxiosResponse): void => {
                    const policies: DynamicDictionary = response.data.data.body;
                    buildProductBlocks(policies.policies);
                    coveredPopup.withCoveredTitle('covered_compare')
                        .withCoveredType(CoveredPopupTypes.CoveredPopupBicycleMulti)
                        .withTranslationKey('bicycle_policy')
                        .withOnSelectClick(onSelectProductClick as Function)
                        .withContent(policies.policies)
                        .build();
                    form.addField(new FormField('product-panel-blocks'));
                    form.setReady();
                    stepsSubmitter.addForm(form);

                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'Bicycle policy / Policy APP / onAppReady', reason);
                });
        }

        function onSubmitStep(): void {
            useScroll()
                .scrollToId(ProductsContainerId)
                .then();
        }

        function onSelectProductClick(programId: string[]): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            program.value = programId[0];
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function onCustomProductClick(): void {
            showCoveredPopup();
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        function buildProductBlocks(products: Bicycle[]): void {
            products.forEach((policy: Bicycle): void => {
                const programIc: string = policy.id.toLowerCase();
                const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
                const productName: string = translateForType('bicycle_product_' + programIc, TranslationType);
                const price: number = policy.price;
                const badgeText: string = translateForType(policy.id + BadgeTextSuffix, TranslationType);
                const territoryText: string = translateForType(policy.params.insuranceTerritoryIc + IcTranslationSuffix, TranslationType);
                const discountPostfix: string = translate('euro_per_day');
                const customModule: string = 'AppProductBlockInnerBicyclePolicy';
                const selectButtonText: string = translateForType('select_' + programIc, TranslationType);
                const customButtonText: string = translateForType('what_covered_bicycle', TranslationType);
                const featuredRisks: CoverageRisk[] = policy.coveragePlans
                    .map((plan: CoveragePlan): CoverageRisk[] => plan.risks)
                    .flat()
                    .filter((risk: CoverageRisk): boolean => risk.isFeatured);
                const dynamicContent: DynamicDictionary = {
                    risks: featuredRisks,
                    territory: territoryText,
                };
                productBlockItemBuilder
                    .withProductId(policy.id)
                    .withProductName(productName)
                    .withDiscountSum(price)
                    .withDiscountSumPostfix(discountPostfix)
                    .withDynamicContent(customModule, dynamicContent)
                    .withButtonSelectTitle(selectButtonText)
                    .withButtonCustomTitle(customButtonText);
                if (policy.params.withBadge) {
                    productBlockItemBuilder.withBadgeText(badgeText)
                }
                if (policy.params.defaultOnMobile) {
                    productBlockItemBuilder.asDefaultOnMobile();
                }
                programs.value.push(productBlockItemBuilder.build());
            });
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('productId', program.value);
        }


        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                stepsSubmitter,
                programs,
                form,
                coveredPopup,
                onAppReady,
                onSubmitStep,
                onSelectProductClick,
                onCustomProductClick,
                applyStepUrls,
                getInTouchModule,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.getInTouchModule.create();
        this.getInTouchModule.beforeModuleMounted();
        this.getInTouchModule.moduleMounted();
        this.getInTouchModule.enableScrollToMode();
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
