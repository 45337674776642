import PersonState from '@/Enums/PersonStateEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import StatusIcon from '@/Components/StatusIcon/StatusIcon.enum';
import moment from 'moment';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';

export default class DeletePersonsFromExcelTab extends ModalTab {
    public fileBeforeUploadCallback: Function = this.fileBeforeUploadCallbackReceiver.bind(this);
    public excelRows: LegalInsuredPerson[] = [];

    private static instance: DeletePersonsFromExcelTab;
    private readonly PersonCodeRowIndex: number = 0;
    private readonly EndDateRowIndex: number = 1;

    public static getInstance(): DeletePersonsFromExcelTab {
        if (!DeletePersonsFromExcelTab.instance) {
            DeletePersonsFromExcelTab.instance = new DeletePersonsFromExcelTab();
        }

        return DeletePersonsFromExcelTab.instance;
    }

    public fileUploadReceiver(rows: DynamicDictionary[]): void {
        rows.shift();
        const result: LegalInsuredPerson[] = rows.map((row: DynamicDictionary): LegalInsuredPerson => {
            const matchedPerson: LegalInsuredPerson | undefined = this.matchPersonFromInsuredPersons(
                String(row[this.PersonCodeRowIndex])
            );
            const person: LegalInsuredPerson = {
                status: matchedPerson ? matchedPerson.status : PersonState.Active,
                firstName: matchedPerson ? matchedPerson.firstName : '',
                lastName: matchedPerson ? matchedPerson.lastName : '',
                personCode: String(row[this.PersonCodeRowIndex]),
                endDate: row[this.EndDateRowIndex],
                startDate: matchedPerson ? moment(matchedPerson.startDate).format(this.modal.app.DateFormat) : '',
                insuranceProgram: matchedPerson ? matchedPerson.insuranceProgram : {name: ''},
                premium: {amount: matchedPerson ? matchedPerson.premium!.amount || '' : '', currency: 'EUR'},
                isVisible: true,
                processingRecords: [],
                icon: matchedPerson ? matchedPerson!.icon : '',
                relationIc: matchedPerson ? matchedPerson.relationIc : '',
            } as LegalInsuredPerson;
            if (matchedPerson) {
                person.resident = matchedPerson.resident;
            }

            return person;
        });
        this.updateRows(result).then((): void => {
            this.modal.popModalQueue();
            this.modal.pushToModalQueue(LegalPersonModalState.DeletePersons);
        });
    }

    public updateRows(rows: LegalInsuredPerson[]): Promise<void> {
        return new Promise(resolve => {
            this.excelRows = rows;
            resolve();
        });
    }

    private fileBeforeUploadCallbackReceiver(file: File): void {
        const processingText: string = this.modal.app.translated('delete_persons_excel_processing_process');
        this.modal.currentLoadingFileName = processingText.replace('@', file.name);
    }

    private matchPersonFromInsuredPersons(personCode: string): LegalInsuredPerson | undefined {
        return this.modal.app
            .insuredPersons.persons
            .find((person: LegalInsuredPerson): boolean =>
                String(person.personCode).replace('-', '')
                === personCode.replace('-', ''));
    }
}
