import AppCountry from '@/assets/libraries/app/app-country';

export default class AdditionalOptionsMap {
    private readonly mapping: Map<string, string> = new Map();

    public constructor() {
        this.mapping = this.riskIcsMap();
    }

    public icByKey(key: string): string {
        let result: string = '';
        const filtered: string | undefined = this.mapping.get(key);
        if (filtered) {
            result = filtered;
        }

        return result;
    }

    public keyByIc(ic: string): string {
        return [...this.mapping.keys()]
            .filter((key: string): boolean => this.mapping.get(key) === ic)
            .at(0) ?? '';
    }

    private riskIcsMap(): Map<string, string> {
        const countryIso: string = (new AppCountry()).iso();

        return new Map(Object.entries(AdditionalOptionsMap.riskIcs()[countryIso]));
    }

    private static riskIcs(): Record<string, Record<string, string>> {
        return {
            'LV': {
                octaLawyer: 'LVMTPL_RTZR',
                octaLawyerAndDlc: 'LVMTPL_RTZR2',
                accidentInsurance: 'LVTL_RNG',
                roadAssistance: 'LVMTPL_RHELP',
            },
            'EE': {
                roadAssistance: 'TE_R_ROAD_ASSISTANCE',
            }
        };
    }
}
