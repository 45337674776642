import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {Subject} from 'rxjs';
import DrawerContent from '@/Components/drawer/interfaces/drawer.content.interface';

export default class DrawerService {
    public onVisibilityChange: Subject<boolean> = new Subject<boolean>();

    private static instance: DrawerService;
    private drawerContent: DrawerContent = new class implements DrawerContent {
        public component: string = '';
        public content: DynamicDictionary = {};
        public translationsType: string = '';
    }
    private isVisible: boolean = false;
    private isVisibleDeferred: boolean = false;
    private domId: string = '';
    private animator: HTMLElement | null = null;

    private constructor() {
    }

    public static getInstance(): DrawerService {
        if (!DrawerService.instance) {
            DrawerService.instance = new DrawerService();
        }

        return DrawerService.instance;
    }

    public init(domId: string): void {
        this.domId = domId;
        this.animator = document.getElementById(this.domId);
        if (this.animator) {
            this.animator.addEventListener('transitionend', this.transitionEndCallback.bind(this));
        }
    }

    public destroy(): void {
        if (this.animator) {
            this.animator.removeEventListener('transitionend', this.transitionEndCallback);
        }
    }

    public withComponentContent(componentContent: DrawerContent): DrawerService {
        this.drawerContent = componentContent;

        return this;
    }

    public show(): void {
        this.isVisible = true;
        this.isVisibleDeferred = this.isVisible;
        this.onVisibilityChange.next(this.isVisible);
    }

    public hide(): void {
        $('.limit-opener').removeClass('opened');
        $('.opener-icon').removeClass('opened');
        this.isVisible = false;
        this.onVisibilityChange.next(this.isVisible);
        $('#scrollable').scrollTop(0);
    }

    public get componentName(): string {
        return this.drawerContent.component;
    }

    public get componentContent(): DynamicDictionary {
        return this.drawerContent.content;
    }

    public get isValidComponent(): boolean {
        return this.drawerContent.component !== '';
    }

    public get isVisibleComponent(): boolean {
        return this.isVisibleDeferred;
    }

    public get translationsType(): string {
        return this.drawerContent.translationsType;
    }

    private transitionEndCallback(): void {
        this.isVisibleDeferred = this.isVisible;
        this.onVisibilityChange.next(this.isVisible);
    }
}
