export default class TravelTransformations {
    public static readonly AgeGroupBasic: string = '1';
    public static readonly AgeGroupSeniors: string = '2';
    public static readonly AgeGroupBasicEE: string = '0-74';
    public static readonly AgeGroupSeniorsEE: string = '75+';
    public static readonly MultipleTrip: string = '2';
    public static readonly MultipleTripEE: string = 'MULTIPLE';
    public static readonly NoTravelActivity: string = '1';
    public static readonly NoTravelActivityV2: string = 'NOT_NECESSARY';
    public static readonly NoTravelActivityEE: string = 'NO';
    public static readonly NoTravelActivityEEV2: string = 'NO_HIGH_RISK_ACTIVITIES';
    public static readonly SingleTrip: string = '1';
    public static readonly SingleTripEE: string = 'SINGLE';
}
