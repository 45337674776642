export default class IframeService {
    private static instance: IframeService;
    private enabled: boolean = false;

    public static getInstance(): IframeService {
        if (!IframeService.instance) {
            IframeService.instance = new IframeService();
        }
        return IframeService.instance;
    }

    public applyViewMode(viewMode: string): void {
        this.enabled = viewMode === 'embed';
    }

    public isEnabled(): boolean {
        return this.enabled;
    }
}
