import Communication from '@/Apps/TwoWayCommunication/Interfaces/CommunicationInterface';

export default class TwoWayCommunication {
    private static instance: TwoWayCommunication;
    private allCommunications: Communication[] = [];
    private modalName: string = '';

    private constructor() {
    }

    public static getInstance(): TwoWayCommunication {
        if (!TwoWayCommunication.instance) {
            TwoWayCommunication.instance = new TwoWayCommunication();
        }

        return TwoWayCommunication.instance;
    }

    public applyCommunications(communications: string): void {
        try {
            this.allCommunications = JSON.parse(communications);
        } catch (error) {
            console.debug('Error parsing communications: ', error);
        }
    }

    public applyName(name: string): void {
        this.modalName = name || '';
    }

    public communications(): Communication[] {
        return this.allCommunications;
    }

    public name(): string {
        return this.modalName;
    }

    //TODO: ...
}