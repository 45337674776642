import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';

export default class PolicyValidators {
    private form: UnwrapNestedRefs<Form> = new Form();

    public init(form: UnwrapNestedRefs<Form>): void {
        this.form = form;
    }

    public customMedicalExpensesValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('customMedicalExpenses').isEmpty();
            }
        };
    }

    public customLuggageValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('customLuggage').isEmpty();
            }
        };
    }

    public flightInterruptionValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('flightInterruption').isEmpty();
            }
        };
    }

    public extraCoverageValidator(): object {
        return {
            mustBeSelected: () => {
                return !this.form.field('extraCoverage').isEmpty();
            }
        };
    }
}
