<script setup lang="ts">

import {PropType} from 'vue';
import {CoveragePlan} from '@/Apps/Renters/Interfaces/CoveragePlanInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {useHtml} from '@/Composables/Html';
import {usePrice} from '@/Composables/Price';
import {useTranslate} from '@/Composables/Translate';
import {CoverageRisk} from '@/Apps/Renters/Interfaces/CoverageRiskInterface';

const {translateForType} = useTranslate();
const translationType: string = 'renters';
const riskSuffix: string = '_MAIN'

const props = defineProps({
    data: {type: Object as PropType<CoveragePlan>, default: () => ({} as DynamicDictionary)},
});

function riskPriceInfo(risk: CoverageRisk): string {
    const additionalRiskText: string = translateForType('risk_is_additional', translationType);
    let result: string = `<span>${usePrice().sparse(risk.insuredSum, true)} &euro;</span>`;
    if (risk.withoutInsuredSum) {
        result = risk.isAdditional
            ? `<span>${additionalRiskText}</span>`
            : `<span>${option(risk.isIncluded)}</span>`;
    }

    return result;
}

function option(selected: boolean): string {
    const iconSrc: string = selected
        ? 'images/one/checkmark-thin.svg'
        : 'images/one/x-thin.svg';

    return useHtml().imgTag(iconAttributes(iconSrc));
}

function iconAttributes(iconSrc: string): ImageTagAttributes {
    return {
        class: '',
        src: iconSrc,
        width: 24,
        height: 24,
    }
}

</script>

<template>
    <div class="renters-policy">
        <div class="item">
            <div class="title">{{ translateForType('include_all_risks', translationType) }}</div>
            <div class="limit-from-value">
                <span class="from">{{ translateForType('limit_from', translationType) }}&nbsp;
                    <span>{{ usePrice().sparse(data.insuredSum, true) }} &nbsp;&euro;</span></span>
            </div>
        </div>
        <div v-for="(risk, index) in data.risks" class="item" :key="index">
            <div class="title">{{ translateForType(risk.id + riskSuffix , translationType) }}</div>
            <div class="value" v-html="riskPriceInfo(risk)"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.renters-policy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 180px;
    margin-bottom: 20px;

    .item {
        position: relative;
        min-height: 46px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-above('sm') {
            justify-content: center;
            display: inherit;
        }

        .title {
            font-size: var(--font-size-tiny);
            text-align: center;
            padding: 12px 0;
            width: 100%;
        }

        .value {
            position: relative;
            padding-left: 30px;
            display: flex;
            justify-content: right;
            font-size: var(--font-size-nano);
            line-height: var(--line-height-accented);
            min-width: 32px;
            width: 50%;
            color: var(--text-color-subtle);
            white-space: nowrap;

            span {
                &:last-child {
                    padding-left: 4px;
                }
            }
        }

        .limit-from-value {
            color: var(--text-color-subtle);
            font-size: var(--font-size-nano);
            font-weight: 500;
            justify-content: right;
            white-space: nowrap;

            .from {
                color: var(--text-color-subtle);
                font-size: var(--font-size-tiny);
                font-weight: 600;
            }
        }

        .title {
            text-align: left;

            @include respond-above('sm') {
                text-align: left;
            }
        }

        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            border-top: thin solid var(--component-color-border-default);
            bottom: 0;
            opacity: .24;
        }

        &:first-child {
            &::before {
                position: absolute;
                content: '';
                height: 1px;
                width: 100%;
                border-top: thin solid var(--component-color-border-default);
                top: 0;
                opacity: .24;
            }
        }
    }
}
</style>

