import axios, {AxiosPromise, AxiosRequestConfig, AxiosStatic, CancelTokenStatic} from 'axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import OneBaseService from '@/services/OneBaseService';
import {useDefine} from '@/Composables/Define';

const {isSet} = useDefine();

export const useAxios = (): AxiosParams => {
    const get = (uri: string, config?: AxiosRequestConfig): AxiosPromise<DynamicDictionary> => {
        return axiosWithDefaults().get(formattedUrl(uri), config);
    }

    const post = (uri: string,
                  requestContent: DynamicDictionary,
                  config?: AxiosRequestConfig
    ): AxiosPromise<DynamicDictionary> => {
        return axiosWithDefaults().post(formattedUrl(uri), requestContent, config);
    }

    const isCancel = (reason: DynamicDictionary): boolean => {
        return axios.isCancel(reason);
    }

    const formattedUrl = (uri: string): string => {
        const lang: string = String(isSet(OneBaseService.getInstance().language.value) ?
            OneBaseService.getInstance().language.value : OneBaseService.getInstance().language);

        return new UrlBuilder()
            .withLanguage(lang)
            .withForcedLanguage()
            .withUri(uri)
            .withSessionId()
            .build();
    }

    const CancelToken: CancelTokenStatic = axios.CancelToken;

    const axiosWithDefaults = (): AxiosStatic => {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['X-CSRF-TOKEN'] = (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;

        return axios;
    }

    return {
        get,
        post,
        isCancel,
        CancelToken
    }
}

export interface AxiosParams {
    get: (uri: string, config?: AxiosRequestConfig) => AxiosPromise<DynamicDictionary>;
    post: (uri: string, requestContent: DynamicDictionary, config?: AxiosRequestConfig) =>
        AxiosPromise<DynamicDictionary>;
    isCancel: (reason: DynamicDictionary) => boolean;
    CancelToken: CancelTokenStatic;
}
