<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { UnwrapNestedRefs } from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import { Subscription } from 'rxjs';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';
import { useNavigate } from '@/Composables/Navigate';
import User from '@/services/user.service';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import { useTranslate } from '@/Composables/Translate';
import { useScroll } from '@/Composables/Scroll';
import Sanitizer from '@/services/sanitizer.service';
import moment from 'moment/moment';
import UserCredentials from '@/interfaces/user.credentials.interface';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import OneDate from '@/assets/libraries/Date/OneDate';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();
        const {translate, translateForType} = useTranslate();

        const CurrentStep: number = 3;
        const Facility: string = 'accident-single-day';
        const FormFields: string[] = [
            'policyHolderIsResident',
            'policyHolderFirstName',
            'policyHolderLastName',
            'policyHolderIdentityNumber',
            'policyHolderCountry',
            'policyHolderBirthDate',
            'policyHolderEmail',
            'policyHolderPhone',
            'agreeTerms',
            'marketingConsents',
            'policyStartDate',
        ];
        const PaymentOptionsElement: string = '[data-alias="banks-list"]';
        const PaymentOptionsTitle: string = '.payments > .title';
        const ConsentsGlue: string = ':';

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const bankLinkId: Ref<number> = ref(0);
        const informationText: Ref<string | null> = ref(null);

        const policyEndDateToday: Ref<string> = computed(() => {
            return [
                translateForType('single_day_summary_policy_valid_from', 'accident'),
                OneDate.short(moment(form.field('policyStartDate').value.startDate).toDate()),
                '(23:59)',
            ].join('&nbsp;');
        });

        const policyEndDateOther: Ref<string> = computed(() => {
            return [
                OneDate.short(moment(form.field('policyStartDate').value.startDate).toDate()),
                '(00:00 - 23:59)',
            ].join('&nbsp;');
        });

        const showTodayPanel: Ref<boolean> = computed(() => {
            const startDate: Date = form.field('policyStartDate').value.startDate;

            return moment(startDate).isSame(moment(), 'day');
        });

        const isPaymentMethodSelected: Ref<boolean> = computed(() => {
            return bankLinkId.value !== 0;
        });

        const policyHolderIsNonResident: Ref<boolean> = computed(() => {
            return !form.field('policyHolderIsResident').value;
        });

        const policyHolderCodeIsAnonymous: Ref<boolean> = computed(() => {
            const holderCodeField: FormField = form.field('policyHolderIdentityNumber');
            const holderCode: string = String(holderCodeField.value);

            return holderCodeField.isNotEmpty() && Validation.isValidPersonCodeWithoutDate(holderCode);
        });

        const birthDateRequired: Ref<boolean> = computed(() => {
            return policyHolderIsNonResident.value || policyHolderCodeIsAnonymous.value;
        });

        const showMarketingConsents: Ref<boolean> = computed(() => {
            return !btaBase.user.current.receiveMarketing;
        });


        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
            applyFormValidationAndSanitization();
            if (isAuthenticatedUser()) {
                patchUserFields();
            }
        }

        function onBeforeFormRestore(): void {
            if (isAuthenticatedUser()) {
                patchUserFields();
            } else {
                patchGuestFields();
            }
            form.field('policyStartDate').patch({startDate: minPolicyStartDate(), endDate: ''});
        }

        function setupForm(): void {
            FormFields.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function isAuthenticatedUser(): boolean {
            return User.getInstance().isLogged();
        }

        function onBackClick(): void {
            useNavigate().navigate(stepsSubmitter.previousStep());
        }

        function onFormSubmit(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then((): void => {
                if (form.isValid()) {
                    if (isPaymentMethodSelected.value) {
                        prepareSubmit();
                        stepsSubmitter.submitMethod(Method.Post);
                        stepsSubmitter.submitStep(Url.Ajax.accidentSingleDayPurchase);
                    } else {
                        scrollToBanksListAndMark();
                    }
                } else {
                    const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
                    if (invalidElements.length > 0) {
                        invalidElements[0].scrollIntoView({behavior: 'smooth', block: 'start', inline: 'center'});
                    }
                }
            });
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        function choosePayment(event: VueEvent): void {
            const newBankLinkId: number = event.params.bankLinkId;
            const newInformationText: string = event.params.informationText;
            if (newBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId;
                informationText.value = newInformationText ? translate(String(newInformationText).toLowerCase()) : null;
            }
            $('.payments > .title').removeClass(CssClass.NotFilled);
        }

        function personCodeValidator(assignedToField: string, residenceCountryField: string): object {
            const personCodeField: FormField = form.field(assignedToField);
            return {
                validPersonCode: (): boolean => personCodeField.isTouched ?
                    isValidIdentityNumber(assignedToField, residenceCountryField) : true
            }
        }

        function captionValidator(assignedToField: string): object {
            const field: FormField = form.field(assignedToField);
            return {
                validCaption: (): boolean => field.isTouched ?
                    Validation.isValidCaption(field.value) : true
            }
        }

        function isValidPersonCodeWithoutDate(value: string, residenceCountry: string): boolean {
            return value ?
                Validation.isValidPersonCodeWithoutDate(value, residenceCountry) : false;
        }

        function isValidIdentityNumber(assignedToField: string, residenceCountryField: string): boolean {
            const personCodeField: FormField = form.field(assignedToField);
            const countryField: FormField = form.field(residenceCountryField);
            const personIsResident: boolean = !policyHolderIsNonResident.value;
            const fieldIsEmpty: boolean = form.field(assignedToField).isEmpty();
            const validationResult: boolean = personIsResident ?
                Validation.isValidNaturalPersonCode(personCodeField.value) :
                Validation.isValidNaturalPersonCode(personCodeField.value, countryField.value.iso);

            return validationResult && !fieldIsEmpty;
        }

        function scrollToBanksListAndMark(): void {
            $(PaymentOptionsTitle).addClass(CssClass.NotFilled);
            useScroll().scrollToLast(PaymentOptionsElement).then();
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
                field.markAsUntouched();
            });
            if (!birthDateRequired.value) {
                form.field('policyHolderBirthDate').clear().then();
            }
        }

        function touchAndRequireNecessaryFields(): void {
            if (isAuthenticatedUser()) {
                touchAndRequireUserFields();
            } else {
                touchAndRequireGuestFields();
            }
        }

        function touchAndRequireUserFields(): void {
            [
                'policyHolderFirstName',
                'policyHolderLastName',
                'policyHolderEmail',
                'policyHolderPhone',
                'agreeTerms',
            ].forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            })
        }

        function touchAndRequireGuestFields(): void {
            const guestFields: string[] = [
                'policyHolderFirstName',
                'policyHolderLastName',
                'policyHolderIdentityNumber',
                'policyHolderEmail',
                'policyHolderPhone',
                'agreeTerms',
            ];
            if (policyHolderIsNonResident.value) {
                guestFields.push('policyHolderCountry');
            }
            if (birthDateRequired.value) {
                guestFields.push('policyHolderBirthDate');
            }
            guestFields.forEach((field: string): void => {
                form.field(field)
                    .addValidators('required')
                    .touch();
            });
        }

        function applyFormValidationAndSanitization(): void {
            form.field('policyHolderFirstName')
                .addValidators(captionValidator('policyHolderFirstName'));
            form.field('policyHolderLastName')
                .addValidators(captionValidator('policyHolderLastName'));
            form.field('policyHolderIdentityNumber')
                .addValidators(personCodeValidator('policyHolderIdentityNumber', 'policyHolderCountry'));
            form.field('policyHolderFirstName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policyHolderLastName').addSanitizer(Sanitizer.cleanUppercaseName);
            form.field('policyHolderIdentityNumber').addSanitizer(Sanitizer.clearPersonCode);
            form.field('policyHolderPhone').addValidators('required');
            form.field('policyHolderEmail').addValidators('required');
        }

        function patchUserFields(): void {
            const currentUser: UserCredentials = btaBase.user.current;
            form.field('policyHolderFirstName').patch(currentUser.firstname);
            form.field('policyHolderLastName').patch(currentUser.lastname);
            form.field('policyHolderIdentityNumber').patch(currentUser.personCode);
            form.field('marketingConsents').patch({
                customText: {},
                selected: currentUser.marketingConsents.join(ConsentsGlue),
            });
            if (form.field('policyHolderEmail').isEmpty()) {
                form.field('policyHolderEmail').patch(currentUser.email);
            }
            if (form.field('policyHolderPhone').isEmpty()) {
                form.field('policyHolderPhone').patch({
                    code: currentUser.code,
                    iso: currentUser.code,
                    country: currentUser.phoneCode,
                    phone: currentUser.phone,
                });
            }
        }

        function patchGuestFields(): void {
            form.field('policyHolderIsResident').patch(true);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('policyHolder', policyHolder(), false);
            stepsSubmitter.addSubmitParam('policyStartDate', startDate(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl',
                SubmitterUrls.getInstance().currentStep(), false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
        }

        function startDate(): string {
            return OneDate.iris(moment(form.field('policyStartDate').value.startDate).toDate());
        }

        function policyHolder(): DynamicDictionary {
            return {
                firstName: form.field('policyHolderFirstName').value,
                lastName: form.field('policyHolderLastName').value,
                identityNumber: form.field('policyHolderIdentityNumber').value,
                country: form.field('policyHolderCountry').value,
                birthDate: birthDate(),
                email: form.field('policyHolderEmail').value,
                phone: form.field('policyHolderPhone').value,
                isResident: btaBase.user.isLogged() ? true : form.field('policyHolderIsResident').value,
                marketingConsents: marketingConsents(),
            };
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ?
                selectedConsents.split(ConsentsGlue) : [];
        }

        function birthDate(): string {
            const user: User = btaBase.user;
            let result: string = '';
            if (user.isLogged()) {
                result = OneDate.custom(user.current.birthDate, 'YYYY-MM-DD');
            } else {
                if (birthDateRequired.value) {
                    result = moment(form.field('policyHolderBirthDate').value).format('YYYY-MM-DD');
                }
            }

            return result;
        }

        function redirectToBank(): void {
            useNavigate().navigate(Url.PaymentsApi.paymentsPay);
        }

        function isVisibleInformationalText(): boolean {
            return false;
        }

        function minPolicyStartDate(): Date {
            const offset: number = btaBase.settings.accidentSingleDaySettings().minPolicyStartDateOffset;

            return moment().add(offset, 'day').toDate();
        }

        function maxPolicyStartDate(): Date {
            const offset: number = btaBase.settings.accidentSingleDaySettings().maxPolicyStartDateOffset;

            return moment(minPolicyStartDate()).add(offset, 'days').toDate();
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                onAppReady,
                setupForm,
                onBeforeFormRestore,
                isAuthenticatedUser,
                onBackClick,
                applyStepUrls,
                isVisibleInformationalText,
                choosePayment,
                onFormSubmit,
                isValidPersonCodeWithoutDate,
                informationText,
                policyEndDateToday,
                policyEndDateOther,
                isPaymentMethodSelected,
                policyHolderIsNonResident,
                policyHolderCodeIsAnonymous,
                birthDateRequired,
                showMarketingConsents,
                minPolicyStartDate,
                maxPolicyStartDate,
                showTodayPanel,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();

        const onAfterFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onAfterFormRestoredSubscription.unsubscribe();
            });
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
