import Field from '@/Components/FieldsetList/Interfaces/Field.interface';
import FieldName from '@/Components/FieldsetList/Enums/FieldName.enum';
import FieldComponent from '@/Components/FieldsetList/Enums/FieldComponent.enum';

export default class FieldBuilder {
    private field: Field = {
        name: '',
        component: '',
        label: '',
        placeholder: '',
        validator: '',
    };

    public withName(value: FieldName): FieldBuilder {
        this.field.name = value;

        return this;
    }

    public withComponent(value: FieldComponent): FieldBuilder {
        this.field.component = value;

        return this;
    }

    public withLabel(value: string): FieldBuilder {
        this.field.label = value;

        return this;
    }

    public withPlaceholder(value: string): FieldBuilder {
        this.field.placeholder = value;

        return this;
    }

    public withValidator(value: string): FieldBuilder {
        this.field.validator = value;

        return this;
    }

    public withSanitizer(value: Function): FieldBuilder {
        this.field.sanitizer = value;

        return this;
    }

    public build(): Field {
        return this.field;
    }
}
