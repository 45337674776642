import BannerItem from '@/interfaces/banner.interface';

export default class Banner {
    public readonly fadeInSpeed: number = 600;
    private readonly context?: Banner;
    private banners: BannerItem[] = [];
    private defaultDelay: number = 10000;

    public constructor() {
        this.context = this;
    }

    public init(): void {
        const bannersElements = $('.banner');
        if (bannersElements.length > 0) {
            bannersElements.each((item: number, dom: HTMLElement) => {
                this.build($(dom));
            });
        }
    }

    private build(dom: JQuery): void {
        const dataDelay: number = parseInt(dom.data('delay'), 10);
        const delay: number = isNaN(dataDelay) ? this.defaultDelay : dataDelay;
        const id: number = dom.data('id');
        const items: JQuery = dom.children('.banner-item');
        dom.children('.banner-item-0')
            .css('display', 'flex')
            .css('opacity', 1);
        if (items.length > 1) {
            const context: Banner = this.context as Banner;
            const callback: Function = this.switchBanners;
            this.banners[id] = new class implements BannerItem {
                public current: number = 0;
                public dom: JQuery = dom;
                public items: JQuery = items;
                public itemsCount: number = items.length;
                public timer: NodeJS.Timer = setInterval(callback.bind(this, id, context), delay);
            }
        }
    }

    private switchBanners(id: number, context: Banner): void {
        const item: any = context.banners[id];
        const fadeOutId: number = item.current++;
        if (item.current >= item.itemsCount) {
            item.current = 0;
        }
        const fadeInId: number = item.current;
        const vimeoPlayer = $(item.items.get(fadeOutId)).find("iframe").get(0);
        $(item.items.get(fadeOutId)).stop(true, false)
            .animate({opacity: 0}, context.fadeInSpeed, () => {
                $(item.items.get(fadeOutId)).css('display', 'none');
                Banner.vimeoAction(vimeoPlayer, 'pause');
            });
        $(item.items.get(fadeInId)).css('display', 'flex')
            .stop(true, false).animate({opacity: 1}, context.fadeInSpeed, () => {
            Banner.vimeoAction(vimeoPlayer, 'play');
        });
    }

    private static vimeoAction(vimeoPlayer: VimeoPlayer, method: string) {
        const vimeoSrcAttribute: string | undefined = $(vimeoPlayer).attr('src');
        const url: URL = new URL((vimeoSrcAttribute) ? vimeoSrcAttribute : '');
        if (typeof vimeoPlayer === 'object' && url && url.origin.includes('vimeo')) {
            vimeoPlayer.contentWindow.postMessage(JSON.stringify({
                "method": method,
                "value": 1
            }), url.origin);
        }
    }
}
