<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import Vue, { computed, ref, Ref, watch, onMounted, PropType, ComputedRef } from 'vue';
import { useDefine } from '@/Composables/Define';
import * as uuid from 'uuid';
import Popup from '@/services/popup.service';
import AlignedTooltip from '@/services/aligned.tooltip.service';
import PopupType from '@/Enums/PopupTypeEnum';
import VueEvent from '@/Classes/VueEventClass';
import { useInputErrorMessage } from '@/Composables/InputErrorMessage';
import AppBrowser from '@/assets/libraries/app/app-browser';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

const props = defineProps({
    label: {type: String, default: ''},
    labelWithTip: {
        type: Object as PropType<DynamicDictionary>, default: () => {
            return {
                title: '',
                tipDescription: '',
            }
        }
    },
    placeholder: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    formField: {type: FormField, default: () => new FormField('')},
    options: {type: Array as PropType<InputOption[]>, default: () => []},
    loading: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    allowEmptySelection: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
    feedbackMessage: {type: String, default: ''},
    type: {type: String, default: 'dropdown'},
    dropMode: {type: String, default: 'drop'},
    popupLabel: {type: String, default: ''},
    resetOnEmptyOptions: {type: Boolean, default: true},
    emptyOption: {
        type: Object as PropType<InputOption>,
        default: () => new InputOptionBuilder().setName('-----').build()
    },
    tooltipAttachTarget: {type: String, default: ''},
    skipOptionsChangeFormReset: {type: Boolean, default: false},
    alwaysExpanded: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
});

const emit = defineEmits(['close', 'open', 'term-change', 'change', 'disabled-change']);

const {isSet} = useDefine();
const dropdownElement: Ref<HTMLDivElement | null> = ref(null);
const popup: Popup = Popup.getInstance();
const {infoMessageIsVisible, infoMessage} = useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: props.supportTextMessage,
    feedbackMessage: props.feedbackMessage
});
const showLabel: ComputedRef<boolean> = computed(() => {
    return isSet(props.label) || isSet(props.popupLabel);
});
const labelText: ComputedRef<string> = computed(() => {
    return props.popupLabel ? props.popupLabel : props.label;
});
const dropUpwards: ComputedRef<boolean> = computed(() => {
    return props.dropMode === 'up';
});
const showOpenIcon: ComputedRef<boolean> = computed(() => {
    return props.type !== 'popup-wide' ? !props.loading : props.options.length > 1
});

let isDisabled: Ref<boolean> = ref(false);
let isOpened: Ref<boolean> = ref(false);
let selectedOption: Ref<InputOption> = ref(new InputOptionBuilder().build());
let id: string = uuid.v4();
let popupContainerMaxHeight: string = '100%';
let showSingleTooltip: Ref<boolean> = ref(false);
let tipId: string = 'tip-id-' + String(Math.random()).replaceAll('.', '');

watch(() => props.disabled, () => {
    checkDisabledStatus();
});

watch(() => props.formField.value, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        selectedOption.value = optionByValue(String(newValue));
        emitChange();
    }
});

watch(() => props.options, (newValue) => {
    if (props.formField.value && !newValue.some((item: InputOption) => item.value === props.formField.value) &&
        props.resetOnEmptyOptions && !props.skipOptionsChangeFormReset) {
        props.formField.patch('');
    }
    checkDisabledStatus(newValue);
    applyHeightOnWide();
}, {deep: true});

onMounted((): void => {
    isDisabled.value = props.disabled;
    selectedOption.value = props.emptyOption;
    if (props.formField.value) {
        selectedOption.value = optionByValue(props.formField.value);
    }
    applyHeightOnWide();
    checkDisabledStatus();
    if (props.alwaysExpanded) {
        open();
    }
    AlignedTooltip.getInstance().init(tipId, props.tooltipAttachTarget);
});

function fieldId() {
    return props.formField.name + '-select';
}

function open(): void {
    if (props.type == 'popup-wide' && props.options.length === 1 && !props.formField.isEmpty()) {
        showSingleTooltip.value = !showSingleTooltip.value;
        if (showSingleTooltip.value) {
            AlignedTooltip.getInstance().show(props.options[0].name);
        }
    } else {
        if (isOpened.value) {
            close();
        } else {
            isOpened.value = true;
            if (props.type.startsWith('popup')) {
                popup.showPopup(PopupType.CustomPopup);
            }
            emit('open');
        }
    }
}

function close(): void {
    if (isOpened.value) {
        isOpened.value = false;
        props.formField.touch();
        if (props.type.startsWith('popup')) {
            popup.showPopup(PopupType.None);
        }
        emit('close');
    }
}

function closeFromOutside(): void {
    if (new AppBrowser().isDesktop()) {
        close();
    }
}

function select(option: InputOption): void {
    props.formField.patch(option.value);
    props.formField.validate();
    close();
}

function tooltipsterClicked(event: VueEvent): void {
    event.event.stopPropagation();
}

function mouseLeave(): void {
    showSingleTooltip.value = false;
    AlignedTooltip.getInstance().hide();
}

function checkDisabledStatus(value: InputOption[] | null = null): void {
    isDisabled.value = props.options.length === 0 || props.disabled || (value !== null && value.length === 0);
    if (!props.formField.isEmpty() && props.formField.value !== '' && selectedOption.value !== props.formField.value) {
        selectedOption.value = optionByValue(String(props.formField.value));
        emitDisabledChange(isDisabled.value);
    }
}

function applyHeightOnWide(): void {
    if (props.type === 'popup-wide') {
        Vue.nextTick(() => {
            const lineHeight: number = 32;
            const paddings: number = 20;
            const maxLinesBeforeScroll: number = 6;
            const maxHeight: number = maxLinesBeforeScroll * lineHeight + paddings;
            popupContainerMaxHeight = maxHeight + 'px';
        });
    }
}

function optionByValue(value: string): InputOption {
    return props.options.find((option: InputOption) => option.value.toString() === value) || props.emptyOption;
}

function scrollToSelectedElement() {
    const selectedOptionFromSelector: JQuery = $(dropdownElement.value!).find('.selected');
    if (selectedOptionFromSelector && selectedOptionFromSelector.length) {
        dropdownElement.value?.scroll({top: selectedOptionFromSelector[0].offsetTop, behavior: 'smooth'});
    }
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();

    emit('change', props.formField.value);
}

function emitDisabledChange(currentValue: boolean, valueBefore?: boolean | null): void {
    if (currentValue !== valueBefore) {
        emit('disabled-change', currentValue)
    }
}

defineExpose({
    scrollToSelectedElement,
    close,
})
</script>

<template>
    <div class="input input-select"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': isDisabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : formField.value">
        <div v-if="label" class="label hide-on-mobile" v-bind:id="fieldId()">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="labelWithTip.title" class="label" v-bind:id="fieldId()">
            <app-text-with-tip class="label-tooltip"
                               :title="labelWithTip.title"
                               :tip-description="labelWithTip.tipDescription"
            ></app-text-with-tip>
        </div>
        <div class="wrapper"
             :id="tipId"
             @mouseleave="mouseLeave()">
            <div :id="id" class="select default" v-click-outside @click-outside="closeFromOutside">
                <button class="button"
                        v-if="!alwaysExpanded"
                        :aria-labelledby="fieldId()"
                        :id="formField.name + '-open'"
                        @click="open()">
                <span v-if="selectedOption === emptyOption"
                      v-html="placeholder || selectedOption.name"
                      class="text text-icon placeholder"></span>
                    <span v-if="selectedOption !== emptyOption"
                          v-html="selectedOption.name || selectedOption.value"
                          class="text text-icon"></span>
                    <span class="icon" v-if="showOpenIcon">
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                    </svg>
                </span>
                    <span class="icon" v-if="loading">
                <app-content-loader class="loader" :icon-type="'spinner'"></app-content-loader>
            </span>
                </button>
                <div v-if="infoMessageIsVisible()" class="feedback" v-html="infoMessage()"></div>
                <div class="dropdown" ref="dropdownElement"
                     v-if="type === 'dropdown' && isOpened"
                     :class="{'up': dropUpwards}">
                    <button v-if="allowEmptySelection"
                            class="item"
                            :id="formField.name + '-dropdown-select-empty'"
                            :class="{'selected': emptyOption === selectedOption}"
                            v-html="emptyOption.name || emptyOption.value"
                            @click="select(emptyOption)">
                    </button>
                    <button class="item"
                            :class="{'selected': option === selectedOption}"
                            v-for="(option, index) in options"
                            :key="index"
                            :id="formField.name + '-dropdown-select-option-' + index"
                            v-html="option.name || option.value"
                            @click="select(option)">
                    </button>
                </div>
                <div class="popups" v-if="type === 'popup' && isOpened">
                    <app-popup class="simple list" v-on:close="close()">
                        <div v-if="showLabel" class="title">{{ labelText }}</div>
                        <span class="list-details">
                            <button v-if="allowEmptySelection"
                                    class="item"
                                    :id="formField.name + '-popup-select-empty'"
                                    @click="select(emptyOption)">
                                <span class="label">{{ emptyOption.name }}</span>
                            </button>
                            <button v-for="(option, index) in options"
                                    class="item"
                                    :key="index"
                                    :id="formField.name + '-popup-select-option-' + index"
                                    @click="select(option)">
                                <span class="label" v-html="option.name || option.value"></span>
                                <app-tooltipster
                                    v-if="option.tooltip"
                                    :open-on-hover="true"
                                    :title="option.tooltip.title"
                                    :description="option.tooltip.description"
                                    @icon-click="tooltipsterClicked(new VueEvent($event))">
                                </app-tooltipster>
                            </button>
                        </span>
                    </app-popup>
                </div>
                <div class="popups" v-if="type === 'popup-wide' && isOpened">
                    <app-popup class="simple list-wide"
                               :styles="'max-height:' + popupContainerMaxHeight"
                               :close-on-overlay-click="true"
                               @close="close()">
                    <span class="list-details">
                        <button v-if="allowEmptySelection"
                                class="item"
                                :id="formField.name + '-popup-select-empty'"
                                @click="select(emptyOption)">
                            <span class="label">{{ emptyOption.name }}</span>
                        </button>
                        <button v-for="(option, index) in options"
                                :key="index"
                                :id="formField.name + '-popup-select-option-' + index"
                                class="item"
                                :class="{'selected': option.value === formField.value, 'hidden': option.hidden}"
                                @click="select(option)">
                            <span class="label">{{ option.name || option.value }}</span>
                            <img class="check-mark"
                                 src="images/one/components/select/checkmark.svg"
                                 alt="check mark">
                        </button>
                    </span>
                    </app-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-select {
    scroll-margin-top: 4em;

    .wrapper {
        .popups {
            .list {
                .list-details {
                    .item {
                        > .label:not(:only-child) {
                            margin-right: var(--size-big);
                        }

                        > .tooltipster {
                            right: 15px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    &.invalid {
        .wrapper {
            .select {
                .button {
                    border-color: var(--brand-red);
                }
            }
        }
    }
}

.input {
    .placeholder {
        opacity: .56;
        color: var(--black-500);
    }

    .dropdown {
        display: block;

        .item {
            height: auto;
            min-height: 52px;
            text-align: left;
        }
    }

    &.invalid.touched .select .button {
        border-color: var(--brand-red);
    }

    .loader {
        width: 16px;
        height: 16px;
    }
}

.disabled {
    .wrapper {
        > .select {
            pointer-events: none;

            > button {
                background-color: var(--component-color-background-disabled);

                .text {
                    color: var(--black-600);
                }
            }
        }
    }
}

.travel-insurance {
    .travelers-inputs {
        .input-select {
            .hide-on-mobile {
                display: none;

                @include respond-above('sm') {
                    display: flex;
                }
            }
        }
    }
}
</style>
