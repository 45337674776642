<script lang="ts">
import Vue, {computed, defineComponent, reactive, Ref, ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import ClaimOption from '@/interfaces/claim.option.interface';
import {concat, from, Observable, of, Subscription} from 'rxjs';
import FormField, {SanitizerCallback} from '@/assets/libraries/form/form-field';
import DateStringRange from '@/interfaces/date.range.interface';
import {TranslateParams, useTranslate} from '@/Composables/Translate';
import {useDefine} from '@/Composables/Define';
import ContentLoaderIconColor from '@/Enums/ContentLoaderIconColorEnum';
import moment from 'moment/moment';
import Url from '@/Enums/UrlEnum';
import {InputOption} from '@/interfaces/InputOptionInterface';
import {InsuredObjectRisk} from '@/interfaces/one_policy/insured.object.risk.interface';
import Translations from '@/services/translations.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Sanitizer from '@/services/sanitizer.service';
import PersonCodeValidator from '@/Validators/PersonCodeValidator';
import AppCountry from '@/assets/libraries/app/app-country';
import Validation from '@/services/validation.service';
import {combineAll, map, mergeMap, take, toArray} from 'rxjs/operators';
import SelectorItem from '@/Components/PopupSelector/SelectorItem';
import {InputOptionBuilder} from '@/Builders/InputOptionBuilder';
import AccidentTypeEnum from '@/Enums/ClaimsAccidentAccidentTypeEnum';
import {AxiosResponse} from 'axios';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import {useAxios} from '@/Composables/Axios';
import OptionsSmartCustom from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import OptionsSmartTypes from '@/Components/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionsSmartCustomSelectState
    from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import OptionSmartColor from '@/Components/OptionsSmartList/Enums/OptionSmartColorEnum';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import OneDate from '@/assets/libraries/Date/OneDate';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const stepsSubmitter = useStepsSubmitter();
        const {translateForType}: TranslateParams = useTranslate();
        const {isSet, validResponse} = useDefine();

        const MinBirthYear: number = 1900;
        const MinAge: number = 0;
        const CurrentStep: number = 2;
        const Facility: string = 'one-claims-accident';
        const ContentLoaderColor: string = ContentLoaderIconColor.Blue;
        const InsurantBirthDateRange: DateRange = {
            max: moment().subtract(MinAge, 'years').startOf('day').toDate(),
            min: moment(String(MinBirthYear)).toDate(),
        };
        const OtherPersonNonResident: string = 'N';
        const OtherPersonResident: string = 'Y';
        const PoliciesFetchUrl: string = Url.AccidentClaims.openPolicies;
        const InsurantOther: string = 'other';
        const LtTableIcOne: string = 'T1';
        const LtTableIcTwo: string = 'T2';

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const accidentTypeOptions: Ref<InputOption[]> = ref([]);
        const accidentTypesWithRisks: Ref<AccidentTypesWithRisks> = ref({} as AccidentTypesWithRisks);
        const fetchedPolicies: Ref<Policy[] | undefined> = ref([] as Policy[]);
        const currentPolicy: Ref<Policy | undefined> = ref({} as Policy);
        const initialized: Ref<boolean> = ref(false);
        const insurantOptions: Ref<InputOption[]> = ref([]);
        const isFetching: Ref<boolean> = ref(false);
        const policyOptions: Ref<InputOption[]> = ref([]);
        const risksOfSelectedPolicy: Ref<InsuredObjectRisk[]> = ref([]);
        const selectableFieldValidator = (value: SelectableFieldValue) => {
            const selected = ref(value.selected);

            return computed(() => {
                return typeof value === 'object' && isSet(selected.value) && selected.value !== '';
            });
        };
        const storedPolicySearchResponseData: Ref<Record<string, Policy[]>> = ref({});
        const typesAreLoaded: Ref<boolean> = ref(false);
        const hasMatchingPayoutTable: Ref<boolean> = ref(true);
        const hasLoadedMatchingTables: Ref<boolean> = ref(true);
        const hasInjuriesMatchingPolicyInsuranceTerms: Ref<boolean> = ref(true);
        const policies: Ref<HTMLDivElement | null> = ref(null);

        const accidentDate: Ref<string> = computed(() => {
            const dateField: FormField = fields.value.date;
            let dateAndTime: string = '';
            if (dateField.isNotEmpty()) {
                let selectedDateTime: moment.Moment = moment(
                    new Date((dateField.value as DateStringRange).startDate)
                );
                if (!selectedDateTime.isBetween(
                    moment().startOf('day'),
                    moment().endOf('day')
                )) {
                    selectedDateTime = selectedDateTime.endOf('day')
                        .set('seconds', 0);
                }
                dateAndTime = selectedDateTime.format('YYYY-MM-DD HH:mm:ss');
            }

            return dateAndTime;
        });

        const accidentTypesAreLoaded: Ref<boolean> = computed(() => {
            return typesAreLoaded.value;
        });

        const accidentTypeFieldOptions: Ref<InputOption[]> = computed(() => {
            return accidentTypeOptions.value;
        });

        const initializing: Ref<boolean> = computed(() => {
            return !initialized.value;
        });

        const insurantFieldOptions: Ref<InputOption[]> = computed(() => {
            return insurantOptions.value;
        });

        const isFetchingPolicies: Ref<boolean> = computed(() => {
            return isFetching.value;
        });

        const isOtherPerson: Ref<boolean> = computed(() => {
            return insurant.value === InsurantOther;
        });

        const isResident: Ref<boolean> = computed(() => {
            return fields.value.otherPerson.isResident.value === OtherPersonResident;
        });

        const panels: Ref<Panels> = ref(
            {
                insurant: reactive({
                    enabled: true,
                    fields: {
                        insurant: {
                            name: 'insurant',
                            validators: {
                                notEmpty: (
                                    value: SelectableFieldValue
                                ): boolean => fields.value.insurant.isTouched
                                    ? selectableFieldValidator(value).value
                                    : true,
                            }
                        },
                        otherPerson: {
                            birthDate: {
                                name: 'otherDateOfBirth',
                                validators: {
                                    birthDateWithinRange: (
                                        value: Date
                                    ): boolean => isOtherPerson.value
                                    && !isResident.value
                                        ? dateIsWithinRange(
                                            value,
                                            InsurantBirthDateRange
                                        ) : true,
                                }
                            },
                            name: {
                                name: 'otherName',
                                validators: {
                                    notEmpty: (): boolean =>
                                        fields.value.otherPerson.name.isTouched
                                            ? fields.value.otherPerson.name.isNotEmpty()
                                            : true
                                },
                                sanitizer: Sanitizer.cleanName,
                            },
                            identityNumber: {
                                name: 'otherIdentityNumber',
                                validators: {
                                    notEmpty: (): boolean => isOtherPerson.value
                                        ? fields.value.otherPerson.identityNumber.isNotEmpty()
                                        : true,
                                    validIdentityNumber: (
                                        value: string
                                    ): boolean => {
                                        return isOtherPerson.value
                                        && isResident.value
                                            ? (new PersonCodeValidator())
                                                .isIdentityNumberValid(
                                                    String(value),
                                                    new AppCountry().iso()
                                                )
                                            : true;
                                    },
                                },
                                sanitizer: Sanitizer.clearPersonCode,
                            },
                            isResident: {
                                name: 'otherPersonResidency',
                                validators: {
                                    notEmpty: (): boolean => {
                                        const residentField: FormField =
                                            fields.value.otherPerson.isResident;

                                        return residentField.isTouched
                                            ? residentField.isNotEmpty()
                                            : true;
                                    },
                                }
                            },
                            policyNumber: {
                                name: 'policyNumber',
                                validators: {
                                    isValid: (
                                        value: string
                                    ): boolean => isCurrentUser.value
                                        || Validation.isValidPolicyNumber(value),
                                },
                                sanitizer: Sanitizer.cleanPolicyNumber,
                            },
                            surname: {
                                name: 'otherSurname',
                                validators: {
                                    notEmpty: (): boolean => {
                                        const surnameField: FormField =
                                            fields.value.otherPerson.surname;

                                        return surnameField.isTouched
                                            ? surnameField.isNotEmpty()
                                            : true;
                                    }
                                },
                                sanitizer: Sanitizer.cleanName,
                            },
                        },
                    },
                    name: 'submit-claim-for',
                    options: [],
                    passed: false,
                    value: null,
                    visible: true,
                    next: (): PanelOptions => panels.value.when,
                    canProceed: (): boolean => panels.value.insurant.visible
                        && personDataIsProvided.value,
                    proceed: (): Observable<void> => proceedFrom(panels.value.insurant),
                    onProceedButtonClick: (): void => {
                        clearOtherInsurant();
                        panels.value.insurant.proceed().pipe(take(1)).subscribe();
                    },
                }),
                when: reactive({
                    enabled: true,
                    fields: {
                        country: {
                            name: 'country',
                            validators: {
                                notEmpty: (value: string | {
                                    ic: string, iso: string, phoneCode: string
                                }): boolean => typeof value === 'object'
                                    && isSet(value.ic)
                                    && isSet(value.iso)
                                    && isSet(value.phoneCode),
                            },
                        },
                        date: {
                            name: 'date',
                            validators: {
                                dateNotEmpty: (
                                    value: string | DateStringRange
                                ): boolean => {
                                    let isNotEmpty: boolean;
                                    switch (typeof value) {
                                        case 'object':
                                            isNotEmpty = isSet(value.startDate);
                                            break;
                                        default:
                                            isNotEmpty = value !== '';
                                    }

                                    return fields.value.date.isTouched
                                        ? isNotEmpty
                                        : true;
                                },
                                dateWithinRange: (
                                    value: DateStringRange
                                ): boolean => dateIsWithinRange(
                                    value.startDate,
                                    whenRange.value
                                ),
                            },
                        }
                    },
                    name: 'when-happened',
                    options: [],
                    passed: false,
                    value: null,
                    visible: false,
                    next: (): PanelOptions => panels.value.policy,
                    canProceed: (): boolean => panels.value.when.visible
                        && isSet(fields.value.date.value.startDate)
                        && fields.value.date.isValid
                        && fields.value.country.isValid,
                    proceed: (): Observable<void> => proceedFrom(panels.value.when),
                    onProceedButtonClick: (): void => {
                        panels.value.when.proceed().pipe(take(1)).subscribe();
                    },
                }),
                policy: reactive({
                    enabled: true,
                    fields: {
                        policy: {
                            name: 'policy',
                            validators: {
                                notEmpty: (
                                    value: string | InsuredObject
                                ): boolean => {
                                    return typeof value === 'object'
                                        && isSet(value.finder)
                                        && value.finder !== ''
                                        && isSet(value.object)
                                        && value.finder !== '';
                                }
                            }
                        }
                    },
                    name: 'select-policy',
                    options: [],
                    passed: false,
                    value: null,
                    visible: false,
                    next: (): PanelOptions => panels.value.type,
                    canProceed: (): boolean => panels.value.policy.visible
                        && fields.value.policy.isValid
                        && fields.value.policy.isNotEmpty(),
                    onProceed: (): Observable<void> => fetchPolicies(),
                    proceed: (): Observable<void> => proceedFrom(panels.value.policy),
                    onProceedButtonClick: (): void => {
                        panels.value.policy.proceed().pipe(take(1)).subscribe();
                    },
                }),
                type: reactive({
                    enabled: true,
                    fields: {
                        type: {
                            name: 'type',
                            validators: {
                                typeNotEmpty: (
                                    value: SelectableFieldValue
                                ): boolean => selectableFieldValidator(value).value,
                            },
                        }
                    },
                    name: 'select-type',
                    options: [],
                    passed: false,
                    value: null,
                    visible: false,
                    next: (): PanelOptions => shouldInjuredPartsPickerBeShown.value
                        ? panels.value.injuries
                        : panels.value.submit,
                    canProceed: (): boolean => panels.value.type.visible
                        && fields.value.type.isValid && (
                            !shouldInjuredPartsPickerBeShown.value
                                ? form.isValid()
                                : true
                        ) && hasLoadedMatchingTables.value,
                    onProceed: (): Observable<void> => fetchAccidentTypesWithRisks(),
                    proceed: (): Observable<void> => proceedFrom(panels.value.type),
                    onProceedButtonClick: (): void => {
                        shouldInjuredPartsPickerBeShown.value
                            ? panels.value.type.proceed().pipe(take(1)).subscribe()
                            : prepareAndSubmit();
                    },
                }),
                injuries: reactive({
                    enabled: false,
                    fields: {
                        injuries: {
                            name: 'injuries',
                            validators: {
                                notEmpty: (): boolean => {
                                    const injuriesField: FormField =
                                        fields.value.injuries;

                                    return injuriesTypeIsSelected.value
                                    && !selectedPolicy()?.isOpenPolicy && hasMatchingPayoutTable.value &&
                                    hasInjuriesMatchingPolicyInsuranceTerms.value ? (
                                        typeof injuriesField.value === 'string'
                                            ? false
                                            : (injuriesField.value as SelectorItem[])
                                            .length !== 0
                                    ) : true;
                                }
                            }
                        }
                    },
                    name: 'injured-parts',
                    options: [],
                    passed: false,
                    value: null,
                    visible: false,
                    canProceed: (): boolean => panels.value.injuries.visible
                        && form.isValid()
                        && fields.value.injuries.isNotEmpty(),
                    proceed: (): Observable<void> => proceedFrom(panels.value.injuries),
                    onProceedButtonClick: (): void => {
                        proceedFrom(panels.value.injuries).subscribe((): void => {
                            prepareAndSubmit();
                        });
                    },
                }),
                submit: reactive({
                    enabled: true,
                    fields: {},
                    name: 'submit',
                    noAutoProceed: true,
                    options: [],
                    passed: false,
                    value: null,
                    visible: false,
                    canProceed: (): boolean => form.isValid(),
                    proceed: (): Observable<void> => of(void 0),
                    onProceedButtonClick: (): void => {
                    },
                })
            }
        );

        const policyFieldOptions: Ref<InputOption[]> = computed(() => {
            return policyOptions.value;
        });

        const policiesSearchParams: Ref<Record<string, string>> = computed(() => {
            return {
                insuredName: name.value,
                insuredSurname: surname.value,
            };
        });

        const residencyOptions: Ref<InputOption[]> = computed(() => {
            return [
                (new InputOptionBuilder())
                    .setName(translateForType('resident', Translations.getInstance().type))
                    .setValue(OtherPersonResident)
                    .build(),
                (new InputOptionBuilder())
                    .setName(translateForType('non_resident', Translations.getInstance().type))
                    .setValue(OtherPersonNonResident)
                    .build(),
            ];
        });

        const showOtherPersonFields: Ref<boolean> = computed(() => {
            return !!fields.value.otherPerson.isResident.value;
        });

        const whenRange: Ref<{ max: Date, min: Date; }> = computed(() => {
            const yearsBack: number = 5;

            return {
                max: moment().startOf('day').toDate(),
                min: moment().subtract(yearsBack, 'years').startOf('day').toDate(),
            };
        });

        const policyInsuranceTerms: Ref<string> = computed(() => {
            return currentPolicy.value?.insuranceTerms as string;
        })

        const riskIc: Ref<string> = computed(() => {
            return selectedType.value!.riskIc as string;
        });

        const fields: Ref<{
            country: FormField;
            date: FormField;
            injuries: FormField;
            insurant: FormField;
            otherPerson: {
                birthDate: FormField;
                identityNumber: FormField;
                isResident: FormField;
                name: FormField;
                policyNumber: FormField;
                surname: FormField;
            };
            policy: FormField;
            type: FormField;
        }> = computed(() => {
            const otherPersonPanelFields: PanelFieldsSet =
                panels.value.insurant.fields.otherPerson as PanelFieldsSet;
            return {
                country: form.field(
                    (panels.value.when.fields.country as PanelField).name
                ),
                date: form.field(
                    (panels.value.when.fields.date as PanelField).name
                ),
                injuries: form.field(
                    (panels.value.injuries.fields.injuries as PanelField).name
                ),
                insurant: form.field(
                    (panels.value.insurant.fields.insurant as PanelField).name
                ),
                otherPerson: {
                    birthDate: form.field(
                        otherPersonPanelFields.birthDate.name
                    ),
                    identityNumber: form.field(
                        otherPersonPanelFields.identityNumber.name
                    ),
                    isResident: form.field(
                        otherPersonPanelFields.isResident.name
                    ),
                    name: form.field(otherPersonPanelFields.name.name),
                    policyNumber: form.field(
                        otherPersonPanelFields.policyNumber.name
                    ),
                    surname: form.field(otherPersonPanelFields.surname.name
                    ),
                },
                policy: form.field(
                    (panels.value.policy.fields.policy as PanelField).name
                ),
                type: form.field(
                    (panels.value.type.fields.type as PanelField).name
                ),
            };
        });

        const isCurrentUser: Ref<boolean> = computed(() => {
            return insurant.value === userId.value;
        });

        const injuriesTypeIsSelected: Ref<boolean> = computed(() => {
            return selectedType.value?.type === AccidentTypeEnum.Injuries;
        });

        const insurant: Ref<string> = computed(() => {
            return (fields.value.insurant.value as SelectableFieldValue).selected;
        });

        const insurantCode: Ref<string> = computed(() => {
            return isCurrentUser.value
                ? btaBase.currentUser.personCode
                : fields.value.otherPerson.identityNumber.value as string;
        });

        const name: Ref<string> = computed(() => {
            return isCurrentUser.value
                ? btaBase.currentUser.firstname
                : fields.value.otherPerson.name.value as string;
        });

        const personDataIsProvided: Ref<boolean> = computed(() => {
            const otherPersonResidencyField: FormField = fields.value.otherPerson.isResident;

            return !!(fields.value.insurant.value as SelectableFieldValue).selected && (!isOtherPerson.value || (
                otherPersonResidencyField.isValid
                && fields.value.otherPerson.name.isValid
                && fields.value.otherPerson.surname.isValid
                && fields.value.otherPerson.identityNumber.isValid
                && (otherPersonResidencyField.value === OtherPersonNonResident ?
                        fields.value.otherPerson.birthDate.isValid :
                        true
                ) && fields.value.otherPerson.policyNumber.isValid
            ));
        });

        const policyId: Ref<string> = computed(() => {
            return (fields.value.policy.value as InsuredObject).object;
        });

        const shouldInjuredPartsPickerBeShown: Ref<boolean> = computed(() => {
            return injuriesTypeIsSelected.value && !selectedPolicy()?.isOpenPolicy && hasMatchingPayoutTable.value &&
                hasInjuriesMatchingPolicyInsuranceTerms.value;
        });

        const selectedPolicyRisks: Ref<InsuredObjectRisk[]> = computed(() => {
            if (risksOfSelectedPolicy.value.length === 0 && selectedPolicy()?.risks) {
                risksOfSelectedPolicy.value = selectedPolicy()
                    ?.risks as DynamicDictionary as InsuredObjectRisk[];
            }

            return risksOfSelectedPolicy.value;
        });

        const selectedType: Ref<{ type: string, riskIc: string } | undefined> = computed(() => {
            const value: string | undefined = (fields.value.type.value as SelectableFieldValue).selected;

            return value ? (JSON.parse(value) as { type: string; riskIc: string; }) : undefined;
        });

        const surname: Ref<string> = computed(() => {
            return isCurrentUser.value
                ? btaBase.currentUser.lastname
                : fields.value.otherPerson.surname.value as string;
        });

        const userId: Ref<string> = computed(() => {
            return btaBase.currentUser.value.personId;
        });

        function descriptionTransformerCallback(item: InputOption): string[] {
            interface Custom {
                policy: string;
                validFrom: string;
                validTo: string;
            }

            const custom: Custom = item.custom as Custom;

            return [
                `${translateForType('policy_no', Translations.getInstance().type)} ${custom.policy}`,
                `${translateForType('valid', Translations.getInstance().type)} `
                + `${OneDate.short(custom.validFrom)} — `
                + OneDate.short(custom.validTo),
            ];
        }

        function onDateChange(newValue: DateStringRange, oldValue: DateStringRange | string): void {
            fields.value.date.markAsFresh();
            const ymd: string = 'YYYY-MM-DD';
            if (
                typeof oldValue !== 'string'
                && moment(newValue.startDate).startOf('day').format(ymd)
                !== moment(oldValue.startDate).startOf('day').format(ymd)
            ) {
                resetPolicy();
            }
        }

        function onDayButtonClick(daysBack: number = 0): void {
            fields.value.date.patch({
                startDate: (
                    daysBack > 0 ? moment().subtract(daysBack, 'days') : moment()
                ).format(),
                endDate: '',
            });
        }

        function onFinderReceive(policy: Policy): void {
            fetchedPolicies.value = [policy];
            risksOfSelectedPolicy.value = policy.risks as {
                agrrisk: string,
                riskname: string
            }[] as InsuredObjectRisk[];
        }

        function onInsurantChange(newValue: SelectableFieldValue, oldValue: SelectableFieldValue | string): void {
            panels.value.when.visible = false;
            if (fields.value.date.isRestored) {
                fields.value.date.markAsFresh();
            } else {
                fields.value.date.clear();
                fields.value.date.value = '';
                fields.value.date.markAsUntouched();
                fields.value.date.isValid = true;
            }
            if (panels.value.policy.visible || (
                typeof oldValue !== 'string'
                && oldValue.selected !== ""
                && newValue !== oldValue
            )) {
                resetPolicy();
            }
            fields.value.otherPerson.isResident.patch(OtherPersonResident);
        }

        function onPolicyChange(): void {
            const policyIdValue: string = policyId.value;
            panels.value.type.visible = false;
            panels.value.injuries.visible = false;
            clearFields();
            applyCurrentPolicy();
            policyOptions.value.filter((option: InputOption): boolean => option.value === policyIdValue)
                .forEach((option: InputOption): void => {
                    if (option.value === policyIdValue) {
                        risksOfSelectedPolicy.value = (
                            option.custom as { risks: InsuredObjectRisk[] }
                        ).risks;
                    }
            });
        }

        function onPolicyNotFound(): void {
            btaBase.error.show(
                ErrorType.Error,
                'InsuredObjectsBlocks.findObjects()',
                translateForType('policy_not_found_description', Translations.getInstance().type)
            );
            btaBase.popup
                .applyErrorTitle(translateForType('policy_not_found_title', Translations.getInstance().type))
                .applyErrorButtonText(translateForType('proceed', Translations.getInstance().type));
        }

        function onPolicyNumberChange(newValue: string, oldValue: string): void {
            if (newValue && newValue !== oldValue) {
                resetPolicy();
            }
        }

        function onTypeChange(): void {
            if (injuriesTypeIsSelected.value) {
                hasLoadedMatchingTables.value = false;
                fetchIsPayoutTableInDatabase(payoutTable(selectedType.value?.riskIc ?? ''),
                    policyInsuranceTerms.value ?? '');
            }
            panels.value.injuries.visible = false;
            panels.value.injuries.enabled = false;
        }

        function resetPolicy(): void {
            fields.value.policy.clear();
            fields.value.type.clear();
            resetFetchedPolicies();
            resetPolicyOptions();
            panels.value.policy.visible = false;
            panels.value.type.visible = false;
            panels.value.injuries.visible = false;
        }

        function titleTransformerCallback(item: InputOption): string {
            return item.name.split('::')[0];
        }

        function payoutTable(riskIc: string = selectedType.value!.riskIc as string): string {
            const selectedRisk: InsuredObjectRisk | undefined = selectedPolicyRisks.value.find(
                (risk: InsuredObjectRisk) => risk.agrrisk === riskIc
            ) as InsuredObjectRisk | undefined;

            return selectedRisk?.payouttable || '';
        }

        function translated(key: string): string {
            return translateForType(key, Translations.getInstance().type);
        }

        function selectedPolicy(): Policy | undefined {
            return currentPolicy.value;
        }

        function accidentTypeRisks(accidentType: string): string[] {
            const risks: string[] = [];
            if (Object.keys(accidentTypesWithRisks.value[accidentType]).length !== 0) {
                const accidentTypeRisks: AccidentTypeRisks
                    = accidentTypesWithRisks.value[accidentType];
                Object.values(accidentTypeRisks.expenses)
                    .forEach((expensesIc: string) => risks.push(expensesIc));
                Object.values(accidentTypeRisks.subrisks)
                    .forEach((subriskIc: string) => risks.push(subriskIc));
                Object.values(accidentTypeRisks.risk_ics)
                    .forEach((subriskIc: string) => risks.push(subriskIc));
            }

            return risks;
        }

        function buildAccidentTypeOptions(): void {
            accidentTypeOptions.value = Object.keys(accidentTypesWithRisks.value).map((accidentType: string) => {
                const accidentTypeName: string = translateForType(accidentType.toLowerCase(),
                    Translations.getInstance().type);
                const riskIc: string = accidentOptionRiskIc(accidentType);
                return (new InputOptionBuilder())
                    .setCustom(injuryOptionCustom(accidentType))
                    .setName(accidentTypeName)
                    .setTipster(
                        accidentTypeName,
                        translateForType(`${accidentType.toLowerCase()}_tooltip`, Translations.getInstance().type)
                    ).setValue(JSON.stringify({
                        type: accidentType,
                        riskIc: riskIc,
                    })).build();
            });
        }

        function accidentOptionRiskIc(accidentType: string): string {
            const riskIcs: string[] = accidentTypesWithRisks.value[accidentType].risk_ics;
            let policyRisk: InsuredObjectRisk | undefined;
            if (new AppCountry().isLT() && accidentType === AccidentTypeEnum.Injuries && hasBothTables()) {
                policyRisk = selectedPolicyRisks.value.find((risk: InsuredObjectRisk): boolean =>
                    risk.payouttable === LtTableIcOne);
            } else {
                policyRisk = selectedPolicyRisks.value.find((risk: InsuredObjectRisk): boolean =>
                    riskIcs.includes(risk.agrrisk));
            }

            return policyRisk ? policyRisk.agrrisk : riskIcs[0];
        }

        function hasBothTables(): boolean {
            return selectedPolicyRisks.value.some(risk => risk.payouttable === LtTableIcOne) &&
                selectedPolicyRisks.value.some(risk => risk.payouttable === LtTableIcTwo);
        }

        function buildInsurantOptions(): void {
            insurantOptions.value = [
                (new InputOptionBuilder)
                    .setName(btaBase.currentUser.name)
                    .setValue(userId.value)
                    .build(),
                (new InputOptionBuilder)
                    .setName(translateForType('insurant_other', Translations.getInstance().type))
                    .setValue(InsurantOther)
                    .build(),
            ];
        }

        function buildPolicyOptions(policies: Policy[]): void {
            resetPolicyOptions();
            if (policies.length > 0 && !isSet(policies[0].error)) {
                policies.forEach((policy: Policy): void => {
                    policyOptions.value.push(
                        (new InputOptionBuilder())
                            .setValue(policy.id)
                            .setName(policy.name)
                            .setCustom({
                                type: policy.objectType,
                                policy: policy.policyNumber,
                                insuredPersonIdentityNumber: policy.insuredPersonIdentityNumber,
                                risks: policy.risks,
                                validFrom: policy.validFrom,
                                validTo: policy.validTo,
                                insuranceTerms: policy.insuranceTerms,
                                objectAttribute: policy.objectAttribute
                            })
                            .build()
                    );
                });
            }
        }

        function dateIsWithinRange(date: string | Date, range: DateRange): boolean {
            const startOfDate: moment.Moment = moment(date).startOf('day');

            return startOfDate.isSameOrAfter(moment(range.min).startOf('day'))
                && startOfDate.isSameOrBefore(moment(range.max).startOf('day'));
        }

        function fetchAccidentTypesWithRisks(): Observable<void> {
            let observable: Observable<void> = of(void 0);
            if (Object.keys(accidentTypesWithRisks.value).length === 0) {
                observable = from(useAxios().get(Url.AccidentClaims.accidentTypesWithRisks)
                    .then((response: AxiosResponse<DynamicDictionary>) => {
                        if (validResponse(response)) {
                            accidentTypesWithRisks.value = (
                                response.data.data as {
                                    body: { objects: AccidentTypesWithRisks }
                                }
                            ).body.objects;
                            buildAccidentTypeOptions();
                            typesAreLoaded.value = true;
                        }
                    }).catch((reason: LimitedVariant): void => {
                        btaBase.error.show(
                            ErrorType.Error,
                            'accident_data::getAccidentTypes',
                            reason as DynamicDictionary
                        );
                    }));
            } else {
                buildAccidentTypeOptions();
            }

            return observable;
        }

        function fetchPolicies(searchParameters?: PoliciesSearchParameters): Observable<void> {
            let observable: Observable<void> = of(void 0);
            if (!isFetching.value) {
                const parameters: PoliciesSearchParameters = searchParameters
                    ? searchParameters
                    : prepareParametersForFetchingPolicies();
                const serializedParametersData: string = serializedParameters(parameters);
                observable = serializedParametersData in storedPolicySearchResponseData.value
                    ? fetchStoredPolicies(serializedParametersData)
                    : fetchPoliciesViaAjax(parameters);
            }

            return observable;
        }

        function fetchPoliciesViaAjax(params: PoliciesSearchParameters): Observable<void> {
            isFetching.value = true;
            return from(useAxios().get(Url.AccidentClaims.policies, {params})
                .then((response: AxiosResponse<DynamicDictionary>) => {
                if (validResponse(response)) {
                    const policies: Policy[] = ((
                        response.data.data || response.data
                    ) as { body: { objects: Policy[] } }).body.objects;
                    buildPolicyOptions(policies);
                    storedPolicySearchResponseData.value[serializedParameters(params)] = policies;
                    fetchedPolicies.value = policies;
                }
            }).catch((reason: LimitedVariant) => {
                resetFetchedPolicies();
                btaBase.error.show(
                    ErrorType.Error,
                    'accident_data::findInsuredObjects',
                    reason as DynamicDictionary
                );
            }).finally((): void => {
                isFetching.value = false;
            }));
        }

        function fetchStoredPolicies(identifier: string): Observable<void> {
            const policies: Policy[] = storedPolicySearchResponseData.value[identifier];
            isFetching.value = true;
            buildPolicyOptions(policies);
            fetchedPolicies.value = policies;
            isFetching.value = false;

            return of(void 0);
        }

        function injuryOptionCustom(accidentType: string): OptionsSmartCustom {
            const riskIsCovered: boolean = selectedPolicyRisks.value.find((
                policyRisk: InsuredObjectRisk
            ): boolean => {
                return accidentTypeRisks(accidentType).includes(policyRisk.agrrisk);
            }) !== undefined;
            const ownDeathIsSelected: boolean = isCurrentUser.value && accidentType === AccidentTypeEnum.Death;
            const selectionWarning: string = riskIsCovered && ownDeathIsSelected
                ? translateForType('own_death_is_selected', Translations.getInstance().type)
                : translateForType('risk_not_covered_by_the_selected_policy', Translations.getInstance().type);
            const selectionIsValid: boolean = accidentType === AccidentTypeEnum.Other
                || riskIsCovered && !ownDeathIsSelected;

            return new class implements OptionsSmartCustom {
                public type: OptionsSmartTypes = selectionIsValid
                    ? OptionsSmartTypes.Regular
                    : OptionsSmartTypes.Info;
                public initialValue: string = selectionWarning;
                public selectState: OptionsSmartCustomSelectState
                    = new class implements OptionsSmartCustomSelectState {
                    public borderColor?: OptionSmartColor | undefined
                        = selectionIsValid ? OptionSmartColor.Green : OptionSmartColor.Red;
                    public isInvalid: boolean = !selectionIsValid;
                };
            };
        }

        function onAppReady(): void {
            applyCurrentPolicy();
            buildInsurantOptions();
            btaBase.dynamicStepper.enableAll();
        }

        function applyCurrentPolicy(): void {
            const policiesFromStorage = btaBase.userStorage.storageData?.policies as Policy[] || [];

            currentPolicy.value = fetchedPolicies.value?.find((storedPolicy: Policy) =>
                    storedPolicy.id === policyId.value) ||
                policiesFromStorage.find((storedPolicy: Policy) => storedPolicy.id === policyId.value);
        }

        function clearFields(): void {
            fields.value.type.clear();
            fields.value.injuries.clear();
        }

        function prepareAndSubmit(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            resetForm();
            form.validate().then(() => {
                if (form.isValid()) {
                    stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep())
                    stepsSubmitter.addSubmitCustomParam('facility', Facility)
                    stepsSubmitter.addSubmitCustomParam('isResident', isResident.value)
                    stepsSubmitter.addSubmitCustomParam('name', name.value)
                    stepsSubmitter.addSubmitCustomParam('surname', surname.value)
                    stepsSubmitter.addSubmitCustomParam('identityNumber', insurantCode.value)
                    stepsSubmitter.addSubmitCustomParam(
                            'birthDate',
                            isOtherPerson.value
                                ? fields.value.otherPerson.birthDate.value as string
                                : btaBase.currentUser.birthDate
                        )
                    stepsSubmitter.addSubmitCustomParam('accidentDate', accidentDate.value)
                    stepsSubmitter.addSubmitCustomParam(
                            'accidentCountry',
                            fields.value.country.value as string
                        )
                    stepsSubmitter.addSubmitCustomParam('policyObjectId', policyId.value)
                    stepsSubmitter.addSubmitCustomParam(
                            'risksOfSelectedPolicy',
                            selectedPolicyRisks.value
                        )
                    stepsSubmitter.addSubmitCustomParam(
                        'type',
                        selectedType.value?.type as string
                    )
                    stepsSubmitter.addSubmitCustomParam(
                        'injuredParts',
                        fields.value.injuries.value as SelectorItem[]
                    )
                    stepsSubmitter.addSubmitCustomParam('insurant', insurant.value)
                    stepsSubmitter.addSubmitCustomParam(
                            'policyObject',
                            selectedPolicyObject()
                        )
                    stepsSubmitter.addSubmitCustomParam(
                        'riskIc',
                        selectedType.value?.riskIc as string
                    )
                    stepsSubmitter.proceedStep('', 0);
                } else {
                    const invalidFields: FormField[] = form.fields()
                        .filter((field: FormField) => !field.isValid);
                    invalidFields.forEach((field: FormField) => field.touch());
                    scrollToElement(document.querySelector(
                        `#${(invalidFields[0]).name}`
                    ) as HTMLElement);
                }
            });
        }

        function prepareParametersForFetchingPolicies(): PoliciesSearchParameters {
            return {
                eventDate: accidentDate.value,
                insuredCode: insurantCode.value,
                insuredName: name.value,
                insuredSurname: surname.value,
                ...(isCurrentUser.value ? {
                    insuredPersonId: btaBase.currentUser.personId,
                } : {
                    agreementNumber: (
                        fields.value.otherPerson.policyNumber.value as string
                    ),
                }),
                objectAttributeTypeIcs: btaBase.settings.claimsSettings().accident.objectAttributeTypeIcs.join(','),
            };
        }

        function proceedFrom(panel: PanelOptions): Observable<void> {
            return of(void 0).pipe(
                mergeMap((): Observable<void> => {
                    let observable: Observable<void> = of(void 0);
                    if (panel.canProceed()) {
                        panel.passed = true;
                        if (panel.next !== undefined) {
                            const nextPanel: PanelOptions = panel.next();
                            nextPanel.visible = true;
                            if (nextPanel.onProceed !== undefined) {
                                observable = nextPanel.onProceed();
                            }
                            scrollToPartAfterNextTick(nextPanel);
                        }
                    }

                    return observable;
                }),
                map((): void => {
                    if (panel.canProceed() && panel.next !== undefined) {
                        scrollToPartAfterNextTick(panel.next());
                    }
                })
            );
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField): void => {
                field.clearValidators();
            });
        }

        function resetFetchedPolicies(): void {
            fetchedPolicies.value = undefined;
        }

        function resetPolicyOptions(): void {
            policyOptions.value = [];
        }

        function scrollToElement(element: HTMLElement): void {
            if (element !== null) {
                window.scroll({
                    behavior: 'smooth',
                    top: element.offsetTop + parseInt(window.getComputedStyle(
                        document.querySelector('.module.steps') as Element
                    ).marginBottom, 10) + parseInt(window.getComputedStyle(
                        document.querySelector('.accident-data .wrapper') as Element
                    ).marginTop, 10)
                });
            }
        }

        function scrollToPartAfterNextTick(part: PanelOptions): void {
            Vue.nextTick((): void => scrollToPart(part));
        }

        function scrollToPart(part: PanelOptions): void {
            if (part !== undefined) {
                scrollToElement(document.querySelector(
                    `[data-scroll="${part.name}"]`
                ) as HTMLElement);
            }
        }

        function serializedParameters(parameters: PoliciesSearchParameters): string {
            const sortedParameters: Record<string, string | undefined> = {};
            for (const key of Object.keys(parameters).sort()) {
                sortedParameters[key] = parameters[
                    key as keyof PoliciesSearchParameters
                    ];
            }

            return JSON.stringify(sortedParameters);
        }

        function setFormField(fieldOrFieldsSet: PanelField | PanelFieldsSet): void {
            if (fieldOrFieldsSet.validators !== undefined) {
                const panelField: PanelField = fieldOrFieldsSet as PanelField;
                const formField: FormField = new FormField(
                    panelField.name as string,
                    (panelField as PanelField).defaultValue,
                    panelField.validators ?? undefined
                );
                if (panelField.sanitizer !== undefined) {
                    formField.addSanitizer(panelField.sanitizer);
                }
                form.addField(formField);
            } else {
                Object.values(fieldOrFieldsSet).forEach(
                    (field: PanelField): void => setFormField(field)
                );
            }
        }

        function setupForm(): void {
            Object.values(panels.value).forEach((panel: PanelOptions): void => {
                Object.values(panel.fields).forEach((
                    fieldOrFieldsSet: PanelField | PanelFieldsSet
                ): void => setFormField(fieldOrFieldsSet));
            });
            form.setReady();
            subscribeForFormRestore();
        }

        function subscribeForFormRestore(): void {
            btaBase.userStorage.onFormStorageDataIsReady.subscribe({
                next: (): void => {
                    PopupService.getInstance().show(new OnePopup().withType().loading);
                    form.validate().then((): void => {
                        const observables: Observable<void>[] = Object.values(
                            panels.value
                        ).map((panel: PanelOptions): Observable<void> => {
                            if (panel === panels.value.type) {
                                fetchIsPayoutTableInDatabase(payoutTable(selectedType.value?.riskIc ?? ''),
                                    policyInsuranceTerms.value ?? '');
                            }
                            return panel.noAutoProceed
                                ? of(void 0)
                                : panel.proceed();
                        });
                        concat(...observables).pipe(toArray(), combineAll(), take(1)).subscribe({
                            next: (): void => {
                                const lastPanel: PanelOptions | undefined =
                                    Object.values(panels.value).findLast((
                                        panel: PanelOptions
                                    ): boolean => panel.visible);
                                if (lastPanel !== undefined) {
                                    scrollToPart(lastPanel);
                                }
                                form.validate().then();
                                PopupService.getInstance().hide();
                            },
                        });
                    });
                }
            });
        }

        function selectedPolicyObject(): DynamicDictionary {
            const objectId: string = (fields.value.policy.value as InsuredObject).object;
            const source: InputOption[] = policyOptions.value
                .concat(Object(policies.value).currentFinderOptions());
            const propertyObjectToExport: DynamicDictionary | undefined =
                source.find((propertyObject: DynamicDictionary): boolean => propertyObject.value === objectId);

            return propertyObjectToExport ?? {};
        }

        function clearOtherInsurant(): void {
            if (!isOtherPerson.value) {
                fields.value.otherPerson.name.clear().then();
                fields.value.otherPerson.surname.clear().then();
                fields.value.otherPerson.policyNumber.clear().then();
                fields.value.otherPerson.identityNumber.clear().then();
                fields.value.otherPerson.birthDate.clear().then();
            }
        }

        function fetchIsPayoutTableInDatabase(table: string, termsIc: string): Observable<void> {
            const params: Record<string, number | string> = {
                payoutTable: table,
                insuranceTerms: termsIc,
            };
            return from(useAxios().get(Url.AccidentClaims.isInjuriesBlockVisible, {params})
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (validResponse(response)) {
                        const body: DynamicDictionary = response.data.data.body;
                        hasMatchingPayoutTable.value = body.hasMatchingPayoutTable;
                        hasInjuriesMatchingPolicyInsuranceTerms.value = body.hasInjuriesMatchingPolicyInsuranceTerms;
                    }
                })
                .catch((reason: LimitedVariant): void => {
                    resetFetchedPolicies();
                    btaBase.error.show(
                        ErrorType.Error,
                        'accident_data::hasMatchingPayoutTable',
                        reason as DynamicDictionary
                    );
                })
                .finally((): void => {
                        hasLoadedMatchingTables.value = true;
                    }
                ));
        }

        function applyStepUrls(next: string, previous: string): void {
            stepsSubmitter.applyStepUrls(next, previous);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                ContentLoaderColor,
                InsurantBirthDateRange,
                OtherPersonNonResident,
                OtherPersonResident,
                PoliciesFetchUrl,
                InsurantOther,
                LtTableIcOne,
                LtTableIcTwo,
                accidentDate,
                accidentTypesAreLoaded,
                accidentTypeFieldOptions,
                initialized,
                initializing,
                insurantFieldOptions,
                isFetchingPolicies,
                isOtherPerson,
                isResident,
                panels,
                policyFieldOptions,
                policiesSearchParams,
                residencyOptions,
                showOtherPersonFields,
                whenRange,
                policyInsuranceTerms,
                policies,
                riskIc,
                insurantOptions,
                risksOfSelectedPolicy,
                fetchedPolicies,
                currentPolicy,
                isFetching,
                policyOptions,
                storedPolicySearchResponseData,
                fields,
                isCurrentUser,
                injuriesTypeIsSelected,
                insurant,
                insurantCode,
                name,
                personDataIsProvided,
                policyId,
                shouldInjuredPartsPickerBeShown,
                selectedPolicyRisks,
                selectedType,
                surname,
                userId,
                accidentTypesWithRisks,
                accidentTypeOptions,
                setupForm,
                applyStepUrls,
                onAppReady,
                descriptionTransformerCallback,
                onDateChange,
                onDayButtonClick,
                onFinderReceive,
                onInsurantChange,
                onPolicyChange,
                onPolicyNotFound,
                onPolicyNumberChange,
                onTypeChange,
                resetPolicy,
                titleTransformerCallback,
                payoutTable,
                translated,
                selectedPolicy,
                accidentTypeRisks,
                buildAccidentTypeOptions,
                accidentOptionRiskIc,
                hasBothTables,
                buildInsurantOptions,
                buildPolicyOptions,
                dateIsWithinRange,
                fetchAccidentTypesWithRisks,
                fetchPolicies,
                fetchPoliciesViaAjax,
                fetchStoredPolicies,
                injuryOptionCustom,
                applyCurrentPolicy,
                clearFields,
                prepareAndSubmit,
                prepareParametersForFetchingPolicies,
                proceedFrom,
                resetForm,
                resetFetchedPolicies,
                resetPolicyOptions,
                scrollToElement,
                scrollToPartAfterNextTick,
                scrollToPart,
                serializedParameters,
                setFormField,
                subscribeForFormRestore,
                selectedPolicyObject,
                clearOtherInsurant,
                fetchIsPayoutTableInDatabase,
                stepsSubmitter,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        Translations.getInstance().addType('claims_accident');
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
                this.initialized = true;
            });
    }
});

interface AccidentTypeRisks {
    risk_ics: string[];
    expenses: Record<string, string>;
    subrisks: Record<string, string>;
}

interface AccidentTypesWithRisks {
    [key: string]: AccidentTypeRisks;
}

interface PanelOptions extends ClaimOption {
    fields: Record<string, PanelField | PanelFieldsSet>;

    next?(): PanelOptions;

    noAutoProceed?: boolean;

    canProceed(): boolean;

    onProceed?(): Observable<void>;

    proceed(): Observable<void>;

    onProceedButtonClick(): void;
}

interface Panels {
    insurant: UnwrapNestedRefs<PanelOptions>;
    when: UnwrapNestedRefs<PanelOptions>;
    policy: UnwrapNestedRefs<PanelOptions>;
    type: UnwrapNestedRefs<PanelOptions>;
    injuries: UnwrapNestedRefs<PanelOptions>;
    submit: UnwrapNestedRefs<PanelOptions>;

    [name: string]: UnwrapNestedRefs<PanelOptions>;
}

interface DateRange {
    max: Date;
    min: Date;
}

interface InsuredObject {
    finder: string;
    object: string;
}

interface PanelField {
    name: string;
    defaultValue?: string;
    sanitizer?: SanitizerCallback;
    validators: {
        [name: string]: ((value: Date) => boolean)
            | ((value: DateStringRange) => boolean)
            | ((value: SelectableFieldValue) => boolean)
            | ((value: string) => boolean);
    };
}

interface PanelFieldsSet {
    [name: string]: PanelField;
}

interface PoliciesSearchParameters {
    agreementNumber?: string;
    eventDate: string;
    insuredCode?: string;
    insuredFullName?: string;
    insuredName?: string;
    insuredSurname?: string;
    insuredPersonId?: string;
    objectAttributeTypeIcs?: string;
}

interface Policy {
    id: string;
    isOneDayPolicy: boolean;
    isOpenPolicy: boolean;
    name: string;

    [key: string]: boolean | number | string | Record<string, string>[];
}

interface SelectableFieldValue {
    selected: string;
}
</script>
