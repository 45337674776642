<script lang="ts">

import Vue, {defineComponent, ref, Ref} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import VueRouter, {RouteConfig} from 'vue-router';
import {useRouter} from 'vue-router/composables';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import RoutStepperStep from '@/Components/RouteStepper/RouteStepperStepInterface';
import {Subscription} from 'rxjs';
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Translations from '@/services/translations.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import EventBus from '@/services/event.bus.service';


export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

        const router: VueRouter = useRouter();

        const CurrentStep: number = 1;
        const Facility: string = 'claims-mtpl';
        const TranslationType: string = 'claims_mtpl';

        const appPreparedAndReady: Ref<boolean> = ref(false);

        const steps: RoutStepperStep[] = (router.options.routes || []).map((routeConfig: RouteConfig, index: number): RoutStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title,
                rowIndex: index + 1,
                visible: true,
            }
        });

        function applyInitialStepUrl(initialStepUrl: string): void {
            claimsMtplService.initialStepUrl = initialStepUrl;
        }

        function applyTypesOfClaim(types: string[]): void {
            claimsMtplService.claimTypes = types;
        }

        function applyDamageTypeFilterData(data: DynamicDictionary[]): void {
            claimsMtplService.damageTypeFilterData = data;
        }

        function applyThankYouStepUrl(url: string): void {
            claimsMtplService.thankYouStepUrl = url;
        }

        function updateStepVisibility(stepsToUpdate: { stepName: string, isVisible: boolean }[]): void {
            stepsToUpdate.forEach(({ stepName, isVisible }) => {
                const stepToUpdate: RoutStepperStep = this.steps.find((step) => step.routeConfig.name === stepName);
                if (stepToUpdate) {
                    stepToUpdate.visible = isVisible;
                }
            });
        }

        function recalculateStepperIndexes(): void {
            let enabledIndex: number = 1;
            steps.forEach((route) => {
                route.rowIndex = route.visible ? enabledIndex++ : enabledIndex;
            });
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                TranslationType,
                steps,
                appPreparedAndReady,
                applyInitialStepUrl,
                applyTypesOfClaim,
                applyDamageTypeFilterData,
                applyThankYouStepUrl,
                claimsMtplService,
                recalculateStepperIndexes,
                updateStepVisibility
            }
        }
    },
    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();
        this.setStorageUsage(false);
        Translations.getInstance().addType(this.TranslationType);
        GuardsService.getInstance().init();
        EventBus.getInstance().subscribe('updateStepVisibility', (data) => {
            this.updateStepVisibility(data);
            this.recalculateStepperIndexes();
        });
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.appPreparedAndReady = true;
                this.claimsMtplService.init();
                Vue.nextTick(()=>{
                    this.sticky.init();
                });
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
