<script setup lang="ts">
    import SelectorItem from '@/Components/PopupSelector/SelectorItem';
    import {computed, onMounted, PropType, ref, Ref} from 'vue';
    import {useTranslate} from '@/Composables/Translate';
    import FormField from "@/assets/libraries/form/form-field";

    const {translateForType} = useTranslate();
    const props = defineProps({
        items: {type: Array as PropType<SelectorItem[]>, default: () => []},
        title: {type: String, default: 'title_popup_selector'},
        translationType: {type: String, default: 'components'},
        multiple: {type: Boolean, default: true},
    });
    const emit = defineEmits(['apply-items', 'close-filters-popup']);
    const selectorItems: Ref<Array<SelectorItem>> = ref([]);
    const isSelected: Ref<boolean> = computed((): boolean => {
        return selectorItems.value.some((item: SelectorItem) => item.field.value);
    });
    const avoidMultiple: Ref<boolean> = computed((): boolean => {
        return isSelected.value && !props.multiple;
    });

    onMounted((): void => {
        setupItems();
    });

    function selectItem(item: SelectorItem): void {
        item.selected = !item.selected;
    }

    function onApplyItems(): void {
        emit('apply-items', selectorItems.value.map(({field, ...item}) => item));
    }

    function setupItems(): void {
        props.items.forEach((item: SelectorItem) => {
                item.field = new FormField(item.name, item.selected);
                selectorItems.value.push(item);
            }
        );
    }
</script>
<template>
    <div class="popup-selector">
        <div class="container">
            <div class="title">{{ translateForType(title, translationType) }}</div>
            <div class="selectors">
                <div class="item"
                     v-for="item in selectorItems">
                    <app-input-checkbox
                        :form-field="item.field"
                        @click="selectItem(item)"
                        :disabled="avoidMultiple && !item.field.value"
                    >{{ translateForType(item.field.name, translationType) }}
                    </app-input-checkbox>
                </div>
            </div>
            <div class="buttons">
                <button class="button red"
                        :disabled="!isSelected"
                        v-text="translateForType(isSelected ? 'button_popup_selector_confirm' : 'button_popup_selector_select', translationType)"
                        @click="onApplyItems()">
                </button>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.popup-selector {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    z-index: 100;

    .container {
        margin: var(--size-normal);

        .title {
            font-size: var(--font-size-small);
            font-weight: bolder;
            width: 100%;
            margin-bottom: var(--size-normal);
        }

        .selectors {
            display: inline-flex;
            flex-wrap: wrap;
            white-space: pre-wrap;
            text-indent: 10px;
            overflow-y: auto;
            max-height: 224px;

            @include respond-above('lg') {
                max-height: 386px;
            }

            .item {
                width: 100%;
                display: flex;
                white-space: pre-wrap;
                text-indent: 10px;
                height: 44px;
                text-align: left;
            }
        }

        .buttons {
            display: flex;
            margin-top: var(--size-normal);
        }
    }
}
</style>
