import ProductBlockItem from '@/Components/ProductBlock/Interfaces/ProductBlockItemInterface';
import ProductBlockItemButton from '@/Components/ProductBlock/Interfaces/ProductBlockItemButtonInterface';
import ProductBlockItemButtonType from '@/Components/ProductBlock/Enums/ProductBlockItemButtonTypeEnum';
import ProductBlockItemButtonColor from '@/Components/ProductBlock/Enums/ProductBlockItemButtonColorEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import DiscountTooltip from '@/Components/ProductBlock/Interfaces/DiscountTooltipInterface';

export default class ProductBlockItemBuilder {
    private item: ProductBlockItem = new class implements ProductBlockItem {
        public id: string = '';
        public badgeText: string = '';
        public productName: string = '';
        public coverageTitle: string = '';
        public discountSum: number = 0;
        public discountSumPostfix: string = '';
        public discountFullSum: number = 0;
        public discountSavedSum: number = 0;
        public discountSavedSumText: string = '';
        public discountTooltip: DiscountTooltip = {
            title: '',
            description: '',
        };
        public description: string = '';

        public buttonSelect: ProductBlockItemButton = new class implements ProductBlockItemButton {
            public title: string = '';
            public type: ProductBlockItemButtonType = ProductBlockItemButtonType.WithArrow;
            public color: ProductBlockItemButtonColor = ProductBlockItemButtonColor.Red;
        };
        public buttonCustom: ProductBlockItemButton = new class implements ProductBlockItemButton {
            public title: string = '';
            public type: ProductBlockItemButtonType = ProductBlockItemButtonType.WithShield;
            public color: ProductBlockItemButtonColor = ProductBlockItemButtonColor.Outside;
        };
        public dynamicContent: DynamicDictionary = {};
        public dynamicComponent: string = '';
        public defaultOnMobile: boolean = false;
    }

    public withProductId(id: string): this {
        this.item.id = id;

        return this;
    }

    public withBadgeText(badgeText: string): this {
        this.item.badgeText = badgeText;

        return this;
    }

    public withProductName(productName: string): this {
        this.item.productName = productName;

        return this;
    }

    public withDiscountSum(discountSum: number): this {
        this.item.discountSum = discountSum;

        return this;
    }

    public withDiscountSumPostfix(discountSumPostfix: string): this {
        this.item.discountSumPostfix = discountSumPostfix;

        return this;
    }

    public withDiscountTooltip(value: DiscountTooltip): this {
        this.item.discountTooltip = value;

        return this;
    }

    public withDiscountFullSum(discountFullSum: number, pattern: string = ''): this {
        this.item.discountFullSum = discountFullSum;
        if (this.item.discountSum > 0 &&
            this.item.discountFullSum > this.item.discountSum &&
            pattern !== ''
        ) {
            const percent100: number = 100;
            this.item.discountSavedSum = percent100 / this.item.discountFullSum * this.item.discountSum;
            this.item.discountSavedSumText =
                String(pattern).replace('@', Number(this.item.discountSavedSum).toFixed(2));
        }

        return this;
    }

    public withDynamicContent(component: string, data: DynamicDictionary | DynamicDictionary[]): this {
        this.item.dynamicComponent = component;
        this.item.dynamicContent = data;

        return this;
    }

    public withButtonSelectTitle(title: string): this {
        this.item.buttonSelect.title = title;

        return this;
    }

    public withButtonSelectColor(color: ProductBlockItemButtonColor): this {
        this.item.buttonSelect.color = color;

        return this;
    }

    public withButtonSelectType(type: ProductBlockItemButtonType): this {
        this.item.buttonSelect.type = type;

        return this;
    }

    public withButtonCustomTitle(title: string): this {
        this.item.buttonCustom.title = title;

        return this;
    }

    public withButtonCustomColor(color: ProductBlockItemButtonColor): this {
        this.item.buttonCustom.color = color;

        return this;
    }

    public withButtonCustomType(type: ProductBlockItemButtonType): this {
        this.item.buttonCustom.type = type;

        return this;
    }

    public withCoverageTitle(title: string): this {
        this.item.coverageTitle = title;

        return this;
    }

    public withDescription(description: string): this {
        this.item.description = description;

        return this;
    }

    public asDefaultOnMobile(): this {
        this.item.defaultOnMobile = true;

        return this;
    }

    public build(): ProductBlockItem {
        return this.item;
    }
}
