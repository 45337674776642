import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv'
import 'moment/dist/locale/lt'
import 'moment/dist/locale/et'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/ru'
import Vue from 'vue';
import VueRouter from 'vue-router';
import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import VueApp from '@/Core/Routing/VueApp';
import App from '@/Apps/PayByLink/Pay/App.vue';
import DetailsPage from '@/Apps/PayByLink/Pay/Pages/DetailsPage.vue';
import EditOfferPage from '@/Apps/PayByLink/Pay/Pages/EditOfferPage.vue';
import SummaryPage from '@/Apps/PayByLink/Pay/Pages/SummaryPage.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/RouteStepper/RouteStepper.vue';
import InputText from '@/Components/InputText/InputText.vue';
import InputNumber from '@/Components/InputNumber/InputNumber.vue';
import InputCheckbox from '@/Components/InputCheckbox/InputCheckbox.vue';
import { PiniaVuePlugin } from 'pinia';

Vue.use(VueRouter);
Vue.use(PiniaVuePlugin);

// Pages
Vue.component('DetailsPage', DetailsPage);
Vue.component('EditOfferPage', EditOfferPage);
Vue.component('SummaryPage', SummaryPage);

// Components
Vue.component('AppStepperLayout', StepperLayout);
Vue.component('AppRouteStepper', RouteStepper);
Vue.component('AppInputText', InputText);
Vue.component('AppInputNumber', InputNumber);
Vue.component('AppInputCheckbox', InputCheckbox);

export default class {
    public app(selector: string, routes: BackendRoute[]) {
        return new VueApp(App).withRoutes(routes).mount(selector);
    }
}
