export default class PhoneValidator {

    public isValidPhone(value: string, localeIso: string): boolean {
        let isValid: boolean = false;
        const phoneOnlyPattern: RegExp = /^[0-9]{7,10}$/;
        const phoneOnlyPatternEE: RegExp = /^(3[2-3]|35|3[8-9]|4[3-8]|5[0-9]|6[0-9]|7[0-9]|8[1-4]|88)[0-9]{5,6}$/;
        const phoneOnlyPatternLT: RegExp = /^[3-6][0-9]{7}$/;
        const phoneOnlyPatternLV: RegExp = /^[2|6][0-9]{7}$/;
        switch (localeIso) {
            case 'EE':
                isValid = phoneOnlyPatternEE.test(value);
                break;
            case 'LT':
                isValid = phoneOnlyPatternLT.test(value);
                break;
            case 'LV':
                isValid = phoneOnlyPatternLV.test(value);
                break;
            default:
                isValid = phoneOnlyPattern.test(value);
                break;
        }
        return isValid;
    }

    public isValidMobilePhone(value: string, localeIso: string): boolean {
        const minLength: number = 6;
        let isValid: boolean = value.length > minLength;
        const mobilePhonePatternEE: RegExp = /^((8[1-7][0-9]{6})|(5[0-9]{6,7}))$/;
        const mobilePhonePatternLT: RegExp = /^([0-9]{3}6|6)([0-9]{5}|[0-9]{7})$/;
        const mobilePhonePatternLV: RegExp = /^(2[0-9]{7})$/;
        switch (localeIso) {
            case 'EE':
                isValid = mobilePhonePatternEE.test(value);
                break;
            case 'LT':
                isValid = mobilePhonePatternLT.test(value);
                break;
            case 'LV':
                isValid = mobilePhonePatternLV.test(value);
                break;
            default:
        }
        return isValid;
    }

    public isValidFixedPhone(value: string, localeIso: string): boolean {
        let isValid: boolean = false;
        const fixedPhonePatternEE: RegExp = /^((3[2|3|5|8|9]|4[3-8]|6[0-8]|7[1-9]|88)[0-9]{5})$/;
        const validOneDigitsPrefixLT: RegExp = /^([0-9]{3}5|5)([0-9]{7})$/;
        const validTwoDigitsPrefixLT: RegExp = /^([0-9]{3}3[0,2-3,5-7,9]|3[0,2-3,5-7,9]|[0-9]{3}4[0-1,3,5-9]|4[0-1,3,5-9]|[0-9]{3}5[0-1,8-9]|5[0-1,8-9])([0-9]{6})$/;
        const validThreeDigitsPrefixLT: RegExp = /^([0-9]{3}3[1,4,8][0-9]|3[1,4,8][0-9]|[0-9]{3}4[2,4][0-9]|4[2,4][0-9]|[0-9]{3}45[0-3,6-9]|45[0-3,6-9]|[0-9]{3}46[0-1,5-9]|46[0-1,5-9]|[0-9]{3}528|528)([0-9]{5})$/;
        const fixedPhonePatternLV: RegExp = /^([567])([0-9]{7})$/;
        switch (localeIso) {
            case 'EE':
                isValid = fixedPhonePatternEE.test(value);
                break;
            case 'LT':
                isValid = validOneDigitsPrefixLT.test(value) || validTwoDigitsPrefixLT.test(value) ||
                    validThreeDigitsPrefixLT.test(value);
                break;
            case 'LV':
                isValid = fixedPhonePatternLV.test(value);
                break;
            default:
        }
        return isValid;
    }
}
