<script setup lang="ts">
import {Ref, ref} from 'vue';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {InputOption} from '@/interfaces/InputOptionInterface';

const props = defineProps({
    content: {type: Array, default: () => []},
    withLoader: {type: Boolean, default: false},
});

const activeItem: Ref<number|null> = ref(null);

function showLoader(index: number): void {
    activeItem.value = index;
}

function isActive(param: LimitedVariant): boolean {
    return (param as DynamicDictionary).active as boolean;
}

function badgeClass(item: InputOption): string {
    return badgeIsVisible(item) ?
        'badge-with-value' : 'badge-with-empty-value';
}

function badgeText(item: InputOption): string {
    return badgeIsVisible(item) ?
        (item.custom as DynamicDictionary).counter : '';
}

function badgeIsVisible(item: InputOption): boolean {
    return !!item.custom &&
        (item.custom as DynamicDictionary).counter !== '' &&
        String((item.custom as DynamicDictionary).counter) !== '0';
}

function isLoaderVisible(itemIndex: number): boolean {
    return activeItem.value === itemIndex;
}
</script>

<template>
    <div class="buttons-belt-with-digits">
        <a class="belt-button"
           v-for="(item, index) in props.content"
           :key="index"
           :class="{'active': isActive(item.custom)}"
           :href="item.value"
           @click="showLoader(index)">
            {{ item.name }}<span class="badge" :class="badgeClass(item)">{{ badgeText(item) }}</span>
            <div class="empty-box">
                <img src="images/one/components/processing/processing.svg"
                     v-if="withLoader"
                     alt="processing"
                     class="loader"
                     width="18"
                     height="18"
                     :class="isLoaderVisible(index) ? 'visible' : 'invisible'">
            </div>
        </a>
    </div>
</template>

<style lang="scss" scoped>
.buttons-belt-with-digits {
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: left;
    overflow-x: auto;
    overflow-y: hidden;

    @include respond-above('sm') {
        overflow: hidden;
    }

    .belt-button {
        position: relative;
        display: flex;
        align-items: center;
        font-size: var(--font-size-nano);
        margin-right: var(--size-huge);
        height: 60px;
        font-weight: 600;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &.active {
            color: var(--brand-blue);
        }

        &.active {
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                background-color: var(--brand-blue);
            }
        }

        @include respond-below('md') {
            margin-right: var(--size-big);
        }

        .badge {
            height: 17px;
            border-radius: 4px;
            font-size: var(--font-size-femto);
            font-weight: 500;
            line-height: 12px;
            color: var(--brand-blue);
            background-color: var(--blue-300);
            transition: padding .3s ease-in, margin .3s ease-in;

            &.badge-with-value {
                animation-name: badge-with-value;
                animation-duration: 3s;
                animation-direction: normal;
                overflow: hidden;
                white-space: nowrap;
                padding: 3px 5px;
                margin-left: var(--size-nano);
            }

            &.badge-with-empty-value {
                animation-name: badge-with-empty-value;
                animation-duration: 3s;
                animation-direction: reverse;
                animation-fill-mode: forwards;
                overflow: hidden;
                white-space: nowrap;
                padding: 0;
                margin-left: 0;
            }
        }

        .loader {
            margin-left: var(--size-pico);
            animation: loader-rotate 1s infinite linear;
            filter: var(--to-color-green);
            position: absolute;

            @include respond-above('sm') {
                margin-left: var(--size-nano);
            }

            .visible {
                opacity: 1;
                transition: opacity 1s ease;
            }

            .invisible {
                opacity: 0;
            }
        }

        .empty-box {
            display: flex;
            align-items: center;
        }
    }
}

@keyframes badge-with-value {
    0% {
        max-width: 0;
    }

    100% {
        max-width: 200px;
    }
}

@keyframes badge-with-empty-value {
    0% {
        max-width: 200px;
    }

    100% {
        max-width: 0;
    }
}


@keyframes loader-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
