import EventBus from '@/services/event.bus.service';

export const useIntersectionObserver = (): ObserverParams => {
    const defaultIntersectionThreshold: number = 0.9;
    const validIntersectionEvent: string = 'observable-intersecting';
    const invalidIntersectionEvent: string = 'observable-not-intersecting';
    const callback = (entries: IntersectionObserverEntry[]): void => {
        entries.forEach((entry: IntersectionObserverEntry): void => {
            if (entry.isIntersecting) {
                EventBus.getInstance().emit(validIntersectionEvent, entry);
            } else {
                EventBus.getInstance().emit(invalidIntersectionEvent, entry);
            }
        });
    }
    const observerInstance: IntersectionObserver = new IntersectionObserver(callback, {
        threshold: [defaultIntersectionThreshold],
    });

    const intersectionEvent = (): string => validIntersectionEvent;
    const disjoinEvent = (): string => invalidIntersectionEvent;

    const observe = (elementId: string): Promise<void> => {
        return new Promise(resolve => {
            const domElement: HTMLElement | null = document.getElementById(elementId);
            if (domElement) {
                observerInstance.observe(domElement);
            }
            resolve();
        });
    }

    const unobserve = (elementId: string): Promise<void> => {
        return new Promise(resolve => {
            const domElement: HTMLElement | null = document.getElementById(elementId);
            if (domElement) {
                observerInstance.unobserve(domElement);
            }
            resolve();
        });
    }

    return {
        observe,
        unobserve,
        intersectionEvent,
        disjoinEvent,
    }
}

export interface ObserverParams {
    observe: (elementId: string) => Promise<void>;
    unobserve: (elementId: string) => Promise<void>;
    intersectionEvent: () => string;
    disjoinEvent: () => string;
}

