import CoveredContent from '@/Components/ProductBlock/Interfaces/CoveredContentInterface';
import Translations from '@/services/translations.service';
import {usePrice} from '@/Composables/Price';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';

const {price, sparse} = usePrice();

export default class ProductBlockCoveredPopup {
    private content: CoveredContent | null = null;

    public get coveredContent(): CoveredContent {
        return this.content as CoveredContent;
    }

    public isSelectedItem(id: string): boolean {
        return this.content?.selected === id;
    }

    public formattedPrice(targetPrice: string | number): string {
        return price(targetPrice, false);
    }

    public buildCoveredPopup(content: CoveredContent): void {
        this.content = content;
        this.content.selected = '';
    }

    public hasTooltip(id: string): boolean {
        return Translations.getInstance().hasLocalization(id);
    }

    public sparsePrice(targetPrice: number, noCents: boolean = false): string {
        return sparse(targetPrice, noCents);
    }

    public show(id: string): void {
        if (this.content) {
            this.content.selected = id;
        }
        PopupService.getInstance().show(new OnePopup().withType().coveredProduct);
    }
}
