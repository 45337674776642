import ImageTagAttributes from '@/interfaces/image.tag.attributes';

export const useHtml = (): HtmlParams => {
    const imgTag = (attributes: ImageTagAttributes): string => {
        return `<img
                class="${attributes.class}"
                src="${attributes.src}"
                width="${attributes.width}"
                height="${attributes.height}"
                alt="${attributes.alt ?? ''}"
            >`;
    }

    return {
        imgTag,
    }
}

export interface HtmlParams {
    imgTag: (attributes: ImageTagAttributes) => string;
}
