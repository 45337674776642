import {computed, Ref, ref} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';


export const useFileUpload = (): FileUploadParams => {
    const isUploadRestricted: Ref<boolean> = ref(false);
    const filesRequired: Ref<boolean> = ref(true);
    const filesUploader: Ref<HTMLDivElement | null> = ref(null);


    const create = () => {
    }

    const isUploaded: Ref<boolean> = computed(() => {
        return filesUploader.value ?
            !(filesUploader.value as DynamicDictionary).filesNotUploaded &&
            (filesUploader.value as DynamicDictionary).uploadsSuccessful : false;
    });

    const filesCount: Ref<number> = computed(() => {
        return filesUploader.value ?
            (filesUploader.value as DynamicDictionary).filesCount : 0;
    });


    return {
        isUploadRestricted,
        filesRequired,
        filesUploader,
        isUploaded,
        filesCount,
        create,
    }
}

export interface FileUploadParams {
    isUploadRestricted: Ref<boolean>;
    filesRequired: Ref<boolean>;
    create: () => void;
    filesUploader: Ref<HTMLDivElement | null>;
    isUploaded: Ref<boolean>;
    filesCount: Ref<number>;
}
