<script lang="ts">
import { defineComponent, computed, reactive, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { UnwrapNestedRefs } from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { useNavigate } from "@/Composables/Navigate";
import FormField from '@/assets/libraries/form/form-field';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { InputOption } from '@/interfaces/InputOptionInterface';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/ButtonWithCallback/Enums/button.icon.position.enum';
import UserStorage from '@/services/user.storage.service';
import { MovableProperty } from '@/interfaces/resources/MovableProperties/MovablePropertyInterface';
import { CoveragePlan } from '@/interfaces/resources/MovableProperties/CoveragePlanInterface';
import { Subscription } from 'rxjs';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import MovablePropertySteps from '@/pages/ItemsAndBelongings/Classes/MovablePropertySteps';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import {CoveredPopupBuilder} from '@/pages/ItemsAndBelongings/Classes/CoveredPopupBuilder';
import CoveredPopupTypes from '@/Components/CoveredPopup/enums/CoveredPopupTypes';
import { usePrice } from '@/Composables/Price';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const {translate} = useTranslate();
        const {sparse} = usePrice();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 2;
        const Facility: string = 'items-and-belongings';

        const form: UnwrapNestedRefs<Form> = reactive(new Form());
        const formIsReady: Ref<boolean> = ref(false);
        const coverageOptions: Ref<InputOption[]> = ref([]);
        const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();

        const applyStepUrls = (next: string, previous: string): void => {
            SubmitterUrls.getInstance().applyStepUrls(next, previous);
        }

        const productPrice: Ref<number> = computed(() => {
            let result: number = 0;
            const selectedCoverageId: number = Number(form.field('coverage').value);
            const productFromStorage: MovableProperty | undefined = selectedProduct();
            if (productFromStorage) {
                result = productFromStorage.coveragePlans[selectedCoverageId].price;
            }

            return sparse(result, false);
        });

        function setupForm(): void {
            form.addField(new FormField('coverage', 0))
            form.setReady();
            formIsReady.value = true;
        }

        function onAppReady(): void {
            buildCoverageOptions();
            btaBase.dynamicStepper.applyEnabled(MovablePropertySteps.byProductId(selectedProductId()));
        }

        function onBeforeFormRestore(): void {
            setupForm();
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onSubmitStep(): void {
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function selectedProduct(): MovableProperty | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as MovableProperty[])
                .find((product: MovableProperty): boolean => product.id === storedProductId);
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.productId ?? '';
        }

        function buildCoverageOptions(): void {
            const productFromStorage: MovableProperty | undefined = selectedProduct();
            if (productFromStorage) {
                productFromStorage.coveragePlans.forEach((plan: CoveragePlan, index: number): void => {
                    coverageOptions.value.push(
                        new InputOptionBuilder()
                            .setValue(index)
                            .setName(String(plan.insuredSum) + ' &euro;')
                            .build()
                    );
                });
                coveredPopup.withCoveredTitle('see_what_covered_single')
                    .withTranslationKey('items_and_belongings')
                    .withCoveredType(CoveredPopupTypes.CoveredPopupSingle)
                    .withCoveragePlanKey(parseInt(form.field('coverage').value))
                    .withExcludedRisks(btaBase.settings.movablePropertySettings().excludedRisks)
                    .withContent([productFromStorage])
                    .build();
            }
        }

        function onCoverageChange(): void {
            coveredPopup.withCoveragePlanKey(parseInt(form.field('coverage').value));
        }

        function onSelectProductClick(programId: string): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            prepareSubmit();
            stepsSubmitter.proceedStep('', 0);
        }

        function onCustomProductClick(): void {
            showCoveredPopup();
        }

        function showCoveredPopup(): void {
            PopupService.getInstance().show(new OnePopup().withType().oneCovered);
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('coverageId', form.field('coverage').value);
            stepsSubmitter.addSubmitCustomParam('productId', selectedProductId());
        }

        function coveredButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('see_what_covered'),
                textColor: ButtonTextColor.Black,
                backgroundColor: ButtonBackground.White,
                backgroundColorHover: ButtonBackground.White,
                icon: ButtonIcon.Covered,
                iconColor: ButtonIconColor.Green,
                borderColor: ButtonBorder.Pale,
            };
        }

        function selectButtonParams(): ButtonWithCallbackParams {
            return {
                title: translate('btar_continue'),
                textColor: ButtonTextColor.White,
                backgroundColor: ButtonBackground.Red,
                icon: ButtonIcon.LongArrowRight,
                iconColor: ButtonIconColor.White,
                iconPosition: ButtonIconPosition.Right,
            };
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                stepsSubmitter,
                onSubmitStep,
                onBackClick,
                applyStepUrls,
                setupForm,
                onAppReady,
                coverageOptions,
                onCoverageChange,
                coveredPopup,
                onSelectProductClick,
                onCustomProductClick,
                coveredButtonParams,
                selectButtonParams,
                productPrice,
                onBeforeFormRestore,
            }
        }
    },

    mounted() {
        this.applyApp(this);
        this.create();
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);

        const onBeforeFormRestoredSubscription: Subscription =
            this.userStorage.onBeforeFormStorageDataIsRestored.subscribe((): void => {
                this.onBeforeFormRestore();
                onBeforeFormRestoredSubscription.unsubscribe();
            });

        const onAppIsPreparedAndReady: Subscription =
            this.onAppIsPreparedAndReady.subscribe((): void => {
                this.onAppReady();
                onAppIsPreparedAndReady.unsubscribe();
            });
    }
});
</script>
