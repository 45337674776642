import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';

export default class SummaryValidators {
    private form: UnwrapNestedRefs<Form> = new Form();

    public init(form: UnwrapNestedRefs<Form>): void {
        this.form = form;
    }

    public identityNumberValidator(): object {
        return {
            validIdentityNumber: (): boolean => {
                return this.form.field('policyHolderIdentityNumber').isTouched
                    ? this.isValidIdentityNumber()
                    : true;
            }
        }
    }

    public captionValidator(assignedToField: string): object {
        const field: FormField = this.form.field(assignedToField);
        return {
            validCaption: (): boolean => field.isTouched ?
                Validation.isValidCaption(field.value) : true
        }
    }

    private isValidIdentityNumber(): boolean {
        const personCodeField: FormField = this.form.field('policyHolderIdentityNumber');
        const countryField: FormField = this.form.field('policyHolderCountry');
        const personIsResident: boolean = this.form.field('policyHolderIsResident').value;
        const validationResult: boolean = personIsResident ?
            Validation.isValidNaturalPersonCode(String(personCodeField.value)) :
            Validation.isValidNaturalPersonCode(String(personCodeField.value), countryField.value.iso);
        return validationResult && personCodeField.isNotEmpty();
    }
}
