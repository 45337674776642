import 'vite/modulepreload-polyfill';
import 'moment/dist/locale/lv'
import 'moment/dist/locale/lt'
import 'moment/dist/locale/et'
import 'moment/dist/locale/en-gb'
import 'moment/dist/locale/ru'
import '@/Components/Components';
import '@/Directives/Directives';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AutoAdditionalApp from '@/pages/Auto/PolicyAdditional/PolicyAdditional.vue';
import AutoSummaryApp from '@/pages/Auto/Summary/Summary.vue';
import ProductsAccidentApp from '@/pages/Products/Accident/AccidentProduct.vue';
import ProductsGeneralCivilLiabilityApp from '@/pages/Products/GeneralCivilLiability/GeneralCivilLiabilityProduct.vue';
import ProductsHealthApp from '@/pages/Products/Health/HealthProduct.vue';
import SpecialRiskApp from '@/pages/Products/SpecialRisk/SpecialRiskProduct.vue';
import GuaranteeApp from '@/pages/Products/Guarantee/GuaranteeProduct.vue';
import ConstructionApp from '@/pages/Products/Construction/ConstructionProduct.vue';
import BusinessApp from '@/pages/Products/Business/BusinessProduct.vue';
import ProductsBicycleApp from '@/pages/Products/Bicycle/BicycleProduct.vue';
import DevComponentsPreviewApp from '@/pages/DevComponentsPreview/app.vue';
import ClaimsCascoLandingApp from '@/pages/ClaimsCasco/Landing/Landing.vue';
import ClaimsCascoVehicleApp from '@/pages/ClaimsCasco/Vehicle/Vehicle.vue';

import Vue from 'vue';
import DefaultApp from '@/pages/Default/Default.vue';
import PromoApp from '@/pages/Promo/Promo.vue';
import BranchesApp from '@/pages/Branches/Branches.vue';
import SelectAccountEntitiesApp from '@/pages/SelectAccount/Entities/Entities.vue';
import TravelLandingApp from '@/pages/Travel/Insurance/Insurance.vue';
import TravelPolicyApp from '@/pages/Travel/Policy/Policy.vue';
import TravelAdditionalApp from '@/pages/Travel/AdditionalRisks/AdditionalRisks.vue';
import TravelTravellerApp from '@/pages/Travel/Traveller/Traveller.vue'
import TravelSummaryApp from '@/pages/Travel/Summary/Summary.vue';
import PropertyLandingApp from '@/pages/Property/Insurance/Insurance.vue';
import PropertyPolicyApp from '@/pages/Property/Policy/Policy.vue';
import PropertyAdditionalApp from '@/pages/Property/Additional/Additional.vue';
import PropertyInformationApp from '@/pages/Property/Information/Information.vue';
import PropertyOptionsApp from '@/pages/Property/Options/Options.vue';
import PropertySummaryApp from '@/pages/Property/Summary/Summary.vue';
import ClaimsContractualInstitutionsApp from '@/pages/Claims/ContractualInstitutions/ContractualInstitutions.vue';
import ClaimsTravelApp from '@/pages/Claims/Travel/TravelClaim.vue';
import ClaimsPartnerRepairShopsApp from '@/pages/Claims/PartnerRepairShops/ClaimsPartnerRepairShops.vue';
import ClaimsOtherApp from '@/pages/Claims/Other/ClaimsOther.vue';
import ClaimsTransportApp from '@/pages/Claims/Transport/ClaimsTransportApp.vue';
import ClaimsHealthApp from '@/pages/Claims/Health/ClaimsHealth.vue';
import ClaimsThankYouApp from '@/pages/Claims/ThankYou/ClaimsThankYou.vue';
import ClaimsAccidentApp from '@/pages/Claims/Accident/ClaimsAccident.vue';
import FilesByLinkGenerateApp from '@/pages/FilesByLink/Generate/FilesByLinkGenerate.vue';
import FilesByLinkUploadApp from '@/pages/FilesByLink/Upload/FilesByLinkUpload.vue';
import MobileUploadApp from '@/pages/MobileUpload/MobileUpload.vue';
import PaymentsWaitingApp from '@/pages/Payments/Waiting/PaymentsWaiting.vue';
import PaymentConfirmedApp from '@/pages/Payments/Confirmed/PaymentsConfirmed.vue';
import PurchaseThankYouApp from '@/pages/Purchase/ThankYou/PurchaseThankYou.vue';
import ItemsAndBelongingsInsuranceApp from '@/pages/ItemsAndBelongings/Insurance/Insurance.vue';
import ItemsAndBelongingsCoverageApp from '@/pages/ItemsAndBelongings/Coverage/Coverage.vue';
import ItemsAndBelongingsAdditionalRisksApp from '@/pages/ItemsAndBelongings/AdditionalRisks/AdditionalRisks.vue';
import ItemsAndBelongingsInfoAboutDeviceApp from '@/pages/ItemsAndBelongings/InfoAboutDevice/InfoAboutDevice.vue';
import ItemsAndBelongingsSummaryAndPaymentApp from '@/pages/ItemsAndBelongings/SummaryAndPayment/SummaryAndPayment.vue';
import BicycleInsuranceApp from '@/pages/Bicycle/Insurance/Insurance.vue';
import BicyclePersonDataApp from '@/pages/Bicycle/PersonData/PersonData.vue';
import BicycleSummaryAndPaymentApp from '@/pages/Bicycle/SummaryAndPayment/SummaryAndPayment.vue';
import AccidentSingleDayInsuranceApp from '@/pages/AccidentSingleDay/Insurance/Insurance.vue';
import AccidentSingleDayPersonsApp from '@/pages/AccidentSingleDay/Persons/Persons.vue';
import AccidentSingleDaySummaryAndPaymentApp from '@/pages/AccidentSingleDay/Summary/Summary.vue';
import OneDashboardInsurancesApp from '@/pages/OneDashboard/Insurances/Insurances.vue';
import OneDashboardPaymentsApp from '@/pages/OneDashboard/Payments/Payments.vue';
import OneDashboardSrsStatementApp from '@/pages/OneDashboard/SrsStatement/Srs.vue';
import OneDashboardClaimsApp from '@/pages/OneDashboard/Claims/Claims.vue';
import LegalPersonApp from '@/pages/LegalPerson/Workbench/Dashboard.vue';
import LegalPersonWelcomeApp from '@/pages/LegalPerson/Welcome/Welcome.vue';
import OneDashboardAccountApp from '@/pages/OneDashboard/Account/Account.vue';
import ClaimsPropertyLandingApp from '@/pages/ClaimsProperty/Landing/Landing.vue';
import ClaimsPropertyDateAndPlaceApp from '@/pages/ClaimsProperty/DateAndPlace/DateAndPlace.vue';
import ClaimsPropertyEventDetailsApp from '@/pages/ClaimsProperty/EventDetails/Details.vue';
import ClaimsPropertyUploadDocumentsApp from '@/pages/ClaimsProperty/UploadDocuments/Upload.vue';
import ClaimsPropertyRecipientApp from '@/pages/ClaimsProperty/Recipient/Recipient.vue';
import ClaimsCascoAccidentDataApp from '@/pages/ClaimsCasco/AccidentData/AccidentData.vue';
import FlashPaymentsGenerateApp from '@/pages/FlashPayments/Generate/Generate.vue';
import FlashPaymentsAfterApp from '@/pages/FlashPayments/After/After.vue';
import ClaimsCascoExtraDetailsApp from '@/pages/ClaimsCasco/ExtraDetails/ExtraDetails.vue';
import ClaimsAccidentLandingApp from '@/pages/ClaimsAccident/Landing/Landing.vue';
import ClaimsAccidentAccidentDataApp from '@/pages/ClaimsAccident/AccidentData/AccidentData.vue';
import ClaimsAccidentAdditionalInformationApp
    from '@/pages/ClaimsAccident/AdditionalInformation/AdditionalInformation.vue';
import ClaimsAccidentUploadFilesApp from '@/pages/ClaimsAccident/UploadFiles/UploadFiles.vue';
import ClaimsAccidentRecipientDataApp from '@/pages/ClaimsAccident/RecipientData/RecipientData.vue';
import DashboardContactsApp from '@/pages/Dashboard/Contacts/Contacts.vue';
import DashboardOnboardingApp from '@/pages/Dashboard/Onboard/Board.vue';
import DashboardUpdateNamesApp from '@/pages/Dashboard/UpdateUser/Update.vue';
import DashboardUpdateDetailsApp from '@/pages/Dashboard/UpdateUser/Update.vue';
import ClaimsCascoRecipientDataApp from '@/pages/ClaimsCasco/RecipientData/Recipient.vue';
import ClaimsCascoUploadFilesApp from '@/pages/ClaimsCasco/UploadFiles/UploadFiles.vue';
import ClaimsCascoContactDetailsApp from '@/pages/ClaimsCasco/ContactDetails/ContactDetails.vue';
import AutoLandingApp from '@/pages/Auto/Landing/Landing.vue';
import AutoPolicyApp from '@/pages/Auto/Policy/Policy.vue';

// SPA apps
import PayByLinkPayMain from '@/Apps/PayByLink/Pay/main';
import ClaimsMtplMain from '@/Apps/ClaimsMtpl/main';
import SolarPanelsMain from '@/Apps/SolarPanels/main';
import RentersMain from '@/Apps/Renters/main';

export namespace Pages {
    export const SolarPanels = SolarPanelsMain;

    export const Renters = RentersMain;

    export namespace PayByLink {
        export const Pay = PayByLinkPayMain;
    }

    export namespace ClaimsMtpl {
        export const Spa = ClaimsMtplMain;
    }

    export class Default {
        public app(selector: string) {
            return new Vue(DefaultApp).$mount(selector);
        }
    }

    export class Promo {
        public app(selector: string) {
            return new Vue(PromoApp).$mount(selector);
        }
    }

    export namespace Branch {
        export class Branches {
            public app(selector: string) {
                return new Vue(BranchesApp).$mount(selector);
            }
        }
    }

    export namespace LegalPerson {
        // tslint:disable-next-line:no-shadowed-variable
        export class Workbench {
            public app(selector: string) {
                return new Vue(LegalPersonApp).$mount(selector);
            }
        }

        export class Welcome {
            public app(selector: string) {
                return new Vue(LegalPersonWelcomeApp).$mount(selector);
            }
        }
    }

    export namespace SelectAccount {
        export class Entities {
            public app(selector: string) {
                return new Vue(SelectAccountEntitiesApp).$mount(selector);
            }
        }
    }

    export namespace Travel {
        export class Insurance {
            public app(selector: string) {
                return new Vue(TravelLandingApp).$mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new Vue(TravelPolicyApp).$mount(selector);
            }
        }

        export class AdditionalRisks {
            public app(selector: string) {
                return new Vue(TravelAdditionalApp).$mount(selector);
            }
        }

        export class Traveller {
            public app(selector: string) {
                return new Vue(TravelTravellerApp).$mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new Vue(TravelSummaryApp).$mount(selector);
            }
        }

    }

    export namespace Property {
        export class Insurance {
            public app(selector: string) {
                return new Vue(PropertyLandingApp).$mount(selector);
            }
        }

        export class Information {
            public app(selector: string) {
                return new Vue(PropertyInformationApp).$mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new Vue(PropertyPolicyApp).$mount(selector);
            }
        }

        export class Options {
            public app(selector: string) {
                return new Vue(PropertyOptionsApp).$mount(selector);
            }
        }

        export class Additional {
            public app(selector: string) {
                return new Vue(PropertyAdditionalApp).$mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new Vue(PropertySummaryApp).$mount(selector);
            }
        }
    }

    export class DevComponentsPreview {
        public app(selector: string) {
            return new Vue(DevComponentsPreviewApp).$mount(selector);
        }
    }

    export namespace Dashboard {
        export class Onboarding {
            public app(selector: string) {
                return new Vue(DashboardOnboardingApp).$mount(selector);
            }
        }

        export class UpdateName {
            public app(selector: string) {
                return new Vue(DashboardUpdateNamesApp).$mount(selector);
            }
        }

        export class UpdateDetails {
            public app(selector: string) {
                return new Vue(DashboardUpdateDetailsApp).$mount(selector);
            }
        }

        export class Contacts {
            public app(selector: string) {
                return new Vue(DashboardContactsApp).$mount(selector);
            }
        }
    }

    export namespace Auto {
        export class Landing {
            public app(selector: string) {
                return new Vue(AutoLandingApp).$mount(selector);
            }
        }

        export class Policy {
            public app(selector: string) {
                return new Vue(AutoPolicyApp).$mount(selector);
            }
        }

        export class Additional {
            public app(selector: string) {
                return new Vue(AutoAdditionalApp).$mount(selector);
            }
        }

        export class Summary {
            public app(selector: string) {
                return new Vue(AutoSummaryApp).$mount(selector);
            }
        }
    }

    export namespace AccidentSingleDay {
        export class Insurance {
            public app(selector: string) {
                return new Vue(AccidentSingleDayInsuranceApp).$mount(selector);
            }
        }

        export class Persons {
            public app(selector: string) {
                return new Vue(AccidentSingleDayPersonsApp).$mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new Vue(AccidentSingleDaySummaryAndPaymentApp).$mount(selector);
            }
        }
    }

    export namespace ItemsAndBelongings {
        export class Insurance {
            public app(selector: string) {
                return new Vue(ItemsAndBelongingsInsuranceApp).$mount(selector);
            }
        }

        export class Coverage {
            public app(selector: string) {
                return new Vue(ItemsAndBelongingsCoverageApp).$mount(selector);
            }
        }

        export class AdditionalRisks {
            public app(selector: string) {
                return new Vue(ItemsAndBelongingsAdditionalRisksApp).$mount(selector);
            }
        }

        export class InfoAboutDevice {
            public app(selector: string) {
                return new Vue(ItemsAndBelongingsInfoAboutDeviceApp).$mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new Vue(ItemsAndBelongingsSummaryAndPaymentApp).$mount(selector);
            }
        }
    }

    export namespace Bicycle {
        export class Insurance {
            public app(selector: string) {
                return new Vue(BicycleInsuranceApp).$mount(selector);
            }
        }

        export class PersonData {
            public app(selector: string) {
                return new Vue(BicyclePersonDataApp).$mount(selector);
            }
        }

        export class SummaryAndPayment {
            public app(selector: string) {
                return new Vue(BicycleSummaryAndPaymentApp).$mount(selector);
            }
        }
    }

    export namespace ClaimsProperty {
        export class Landing {
            public app(selector: string) {
                return new Vue(ClaimsPropertyLandingApp).$mount(selector);
            }
        }

        export class DateAndPlace {
            public app(selector: string) {
                return new Vue(ClaimsPropertyDateAndPlaceApp).$mount(selector);
            }
        }

        export class EventDetails {
            public app(selector: string) {
                return new Vue(ClaimsPropertyEventDetailsApp).$mount(selector);
            }
        }

        export class UploadDocuments {
            public app(selector: string) {
                return new Vue(ClaimsPropertyUploadDocumentsApp).$mount(selector);
            }
        }

        export class Recipient {
            public app(selector: string) {
                return new Vue(ClaimsPropertyRecipientApp).$mount(selector);
            }
        }
    }

    export namespace ClaimsCasco {
        export class Landing {
            public app(selector: string) {
                return new Vue(ClaimsCascoLandingApp).$mount(selector);
            }
        }

        export class AccidentData {
            public app(selector: string) {
                return new Vue(ClaimsCascoAccidentDataApp).$mount(selector);
            }
        }

        export class Vehicle {
            public app(selector: string) {
                return new Vue(ClaimsCascoVehicleApp).$mount(selector);
            }
        }

        export class ExtraDetails {
            public app(selector: string) {
                return new Vue(ClaimsCascoExtraDetailsApp).$mount(selector);
            }
        }

        export class UploadFiles {
            public app(selector: string) {
                return new Vue(ClaimsCascoUploadFilesApp).$mount(selector);
            }
        }

        export class RecipientData {
            public app(selector: string) {
                return new Vue(ClaimsCascoRecipientDataApp).$mount(selector);
            }
        }

        export class ContactDetails {
            public app(selector: string) {
                return new Vue(ClaimsCascoContactDetailsApp).$mount(selector);
            }
        }
    }

    export namespace ClaimsAccident {
        export class Landing {
            public app(selector: string) {
                return new Vue(ClaimsAccidentLandingApp).$mount(selector);
            }
        }

        export class AccidentData {
            public app(selector: string) {
                return new Vue(ClaimsAccidentAccidentDataApp).$mount(selector);
            }
        }

        export class AdditionalInformation {
            public app(selector: string) {
                return new Vue(ClaimsAccidentAdditionalInformationApp).$mount(selector);
            }
        }

        export class UploadFiles {
            public app(selector: string) {
                return new Vue(ClaimsAccidentUploadFilesApp).$mount(selector);
            }
        }

        export class RecipientData {
            public app(selector: string) {
                return new Vue(ClaimsAccidentRecipientDataApp).$mount(selector);
            }
        }
    }

    export namespace Claims {
        export class Transport {
            public app(selector: string) {
                return new Vue(ClaimsTransportApp).$mount(selector);
            }
        }

        export class PartnerRepairShops {
            public app(selector: string) {
                return new Vue(ClaimsPartnerRepairShopsApp).$mount(selector);
            }
        }

        export class Health {
            public app(selector: string) {
                return new Vue(ClaimsHealthApp).$mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Travel {
            public app(selector: string) {
                return new Vue(ClaimsTravelApp).$mount(selector);
            }
        }

        export class Accident {
            public app(selector: string) {
                return new Vue(ClaimsAccidentApp).$mount(selector);
            }
        }

        export class Other {
            public app(selector: string) {
                return new Vue(ClaimsOtherApp).$mount(selector);
            }
        }

        export class ContractualInstitutions {
            public app(selector: string) {
                return new Vue(ClaimsContractualInstitutionsApp).$mount(selector);
            }
        }

        export class ThankYou {
            public app(selector: string) {
                return new Vue(ClaimsThankYouApp).$mount(selector);
            }
        }
    }

    export namespace FilesByLink {
        export class Generate {
            public app(selector: string) {
                return new Vue(FilesByLinkGenerateApp).$mount(selector);
            }
        }

        export class Upload {
            public app(selector: string) {
                return new Vue(FilesByLinkUploadApp).$mount(selector);
            }
        }
    }

    export namespace FlashPayments {
        export class Generate {
            public app(selector: string) {
                return new Vue(FlashPaymentsGenerateApp).$mount(selector);
            }
        }

        export class After {
            public app(selector: string) {
                return new Vue(FlashPaymentsAfterApp).$mount(selector);
            }
        }
    }

    export namespace Products {
        // tslint:disable-next-line:no-shadowed-variable
        export class Travel {
            public app(selector: string) {
                return new Vue(TravelLandingApp).$mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Property {
            public app(selector: string) {
                return new Vue(PropertyLandingApp).$mount(selector);
            }
        }

        export class Accident {
            public app(selector: string) {
                return new Vue(ProductsAccidentApp).$mount(selector);
            }
        }

        export class GeneralCivilLiability {
            public app(selector: string) {
                return new Vue(ProductsGeneralCivilLiabilityApp).$mount(selector);
            }
        }

        export class Health {
            public app(selector: string) {
                return new Vue(ProductsHealthApp).$mount(selector);
            }
        }

        export class SpecialRisks {
            public app(selector: string) {
                return new Vue(SpecialRiskApp).$mount(selector);
            }
        }

        export class Guarantee {
            public app(selector: string) {
                return new Vue(GuaranteeApp).$mount(selector);
            }
        }

        export class Construction {
            public app(selector: string) {
                return new Vue(ConstructionApp).$mount(selector);
            }
        }

        export class Business {
            public app(selector: string) {
                return new Vue(BusinessApp).$mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Bicycle {
            public app(selector: string) {
                return new Vue(ProductsBicycleApp).$mount(selector);
            }
        }
    }

    export namespace Mobile {
        export class Upload {
            public app(selector: string) {
                return new Vue(MobileUploadApp).$mount(selector);
            }
        }
    }

    export namespace Payments {
        export class Waiting {
            public app(selector: string) {
                return new Vue(PaymentsWaitingApp).$mount(selector);
            }
        }

        export class Confirmed {
            public app(selector: string) {
                return new Vue(PaymentConfirmedApp).$mount(selector);
            }
        }
    }

    export namespace Purchase {
        export class ThankYou {
            public app(selector: string) {
                return new Vue(PurchaseThankYouApp).$mount(selector);
            }
        }
    }

    export namespace OneDashboard {
        // tslint:disable-next-line:no-shadowed-variable

        export class Insurances {
            public app(selector: string) {
                return new Vue(OneDashboardInsurancesApp).$mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Payments {
            public app(selector: string) {
                return new Vue(OneDashboardPaymentsApp).$mount(selector);
            }
        }

        // tslint:disable-next-line:no-shadowed-variable
        export class Claims {
            public app(selector: string) {
                return new Vue(OneDashboardClaimsApp).$mount(selector);
            }
        }

        export class Account {
            public app(selector: string) {
                return new Vue(OneDashboardAccountApp).$mount(selector);
            }
        }

        export class SrsStatement {
            public app(selector: string) {
                return new Vue(OneDashboardSrsStatementApp).$mount(selector);
            }
        }
    }
}

(window as DynamicDictionary).One = {Pages};
