import {SolarPanels} from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';

export class CoveredPopupBuilder {
    private type: string = '';
    private content: SolarPanels[] = [];
    private onSelectClick!: Function;
    private translationType: string = '';
    private mode: string = '';
    private coveragePlanKey: number = 0;

    public withCoveredType(type: string): CoveredPopupBuilder {
        this.type = type;

        return this;
    }

    public withContent(data: SolarPanels[]): CoveredPopupBuilder {
        this.content = data;

        return this;
    }

    public withTranslationKey(key: string): CoveredPopupBuilder {
        this.translationType = key;

        return this;
    }

    public withSingleMode(): CoveredPopupBuilder {
        this.mode = 'single';

        return this;
    }

    public withCoveragePlanKey(key: number): CoveredPopupBuilder {
        this.coveragePlanKey = key;

        return this;
    }

    public withOnSelectClick(callback: Function): CoveredPopupBuilder {
        this.onSelectClick = callback;

        return this;
    }
}
