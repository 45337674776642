export default class FetchParams {
    public insuredObjectId!: string;
    public storageFacility!: string;
    public storageDataType!: string;
    public showInClaims!: string;
    public serviceType!: string;
    public vehicleTypeWeb!: string;
    public vehicleMake!: string;
    public isOtherVehicle!: boolean;
    public vehicleAge!: number;
    public vehicleModel!: string;
    public claimType!: string;
}
