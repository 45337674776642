import AppCountry from "@/assets/libraries/app/app-country";

export default class VehicleValidator {

    public isValidEngineCapacity(value: string): boolean {
        const engineCapacityPattern: RegExp = /^[1-9]{1}[0-9]{1,5}$/;
        return engineCapacityPattern.test(value);
    }

    public isValidVehiclePlateNumber(value: string, localeIso: string): boolean {
        let isValid: boolean = false;
        const plateNumberPatternEE: RegExp = /^[a-zA-Z0-9]{2,9}$/;
        const plateNumberPatternLT: RegExp = /^[a-zA-Z0-9]{1,7}$/;
        const plateNumberPatternLV: RegExp = /^([A-Z]{1,2}[0-9]{1,4})|([A-Z]{2}[0-9]{1,3})|([A-Z]{1}[0-9]{1}[A-Z,0-9]{0,2})|([A-Z,0-9]{2,8})$/;
        switch (localeIso) {
            case 'EE':
                isValid = plateNumberPatternEE.test(value);
                break;
            case 'LT':
                isValid = plateNumberPatternLT.test(value);
                break;
            case 'LV':
                isValid = plateNumberPatternLV.test(value);
                break;
            default:
                throw new Error('Unknown localeIso: ' + localeIso);
        }
        return isValid;
    }

    public isValidVehicleRegistrationNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        let isValid: boolean = false;
        const vehicleRegistrationNumberPatternEE: RegExp = /^(\1|)[1789]{1}[0-9]{2,7}(?:x.+)?$/;
        const vehicleRegistrationNumberPatternLT: RegExp = /^[a-zA-Z0-9]{7,8}$/;
        const vehicleRegistrationNumberPatternLV: RegExp = /^([A-Z]{2}[0-9]{6,7})|([A-Z]{2,3}[0-9]{6,8})|([A-Z]{1,2}[0-9]{5,7})$/;
        switch (localeIso) {
            case 'EE':
                isValid = vehicleRegistrationNumberPatternEE.test(value);
                break;
            case 'LT':
                isValid = vehicleRegistrationNumberPatternLT.test(value);
                break;
            case 'LV':
                isValid = vehicleRegistrationNumberPatternLV.test(value);
                break;
            default:
                throw new Error('Unknown localeIso: ' + localeIso);
        }
        return isValid;
    }

    public isValidDriversLicenseNumber(value: string, localeIso: string = new AppCountry().iso()): boolean {
        let isValid: boolean = false;
        const vehicleRegistrationNumberPatternEE: RegExp = /^([A-Z]{2,3}[0-9]{6,7})$/;
        const vehicleRegistrationNumberPatternLT: RegExp = /^([A-Z]{2,3}[0-9]{6,7})$/;
        const vehicleRegistrationNumberPatternLV: RegExp = /^([A-Z]{2,3}[0-9]{6,7})$/;
        switch (localeIso) {
            case 'EE':
                isValid = vehicleRegistrationNumberPatternEE.test(value);
                break;
            case 'LT':
                isValid = vehicleRegistrationNumberPatternLT.test(value);
                break;
            case 'LV':
                isValid = vehicleRegistrationNumberPatternLV.test(value);
                break;
            default:
                throw new Error('Unknown localeIso: ' + localeIso);
        }

        return isValid;
    }

    public isValidVehicleVinNumber(value: string): boolean {
        const vinNumberPattern: RegExp = /^[A-Z0-9]{17}$/;

        return vinNumberPattern.test(value);
    }
}
