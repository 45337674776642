<script lang="ts">
import {Subscription} from 'rxjs';
import VueEvent from '@/Classes/VueEventClass';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {AxiosResponse} from 'axios';
import ResponseCode from '@/Enums/ResponseCodeEnum';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import {defineComponent, onMounted} from 'vue';
import {AxiosParams, useAxios} from '@/Composables/Axios';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();


        const request: AxiosParams = useAxios();


        onMounted(() => {
            btaBase.initBtaBase();
            const subscription: Subscription = btaBase.onAppIsPreparedAndReady.subscribe(() => {
                showSelectAccountPopup();
                subscription.unsubscribe();
            });
        });


        function showSelectAccountPopup(): void {
            const selectAccountPopup: PopupBase = new OnePopup()
                .withType()
                .selectAccountPopup
                .withHeader()
                .withClickCallback(selectEntity)
                .withoutCloseButton()
            PopupService.getInstance().show(selectAccountPopup);
        }


        function selectEntity(event: VueEvent): void {
            btaBase.lockInput(true);
            request.post(Url.Ajax.selectEntity, event)
                .then((response: AxiosResponse): void => {
                    if (isValidResponse(response)) {
                        btaBase.navigate(response.data.data.body.redirectUrl);
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    btaBase.error.show(ErrorType.Error, 'selectEntity', reason);
                })
                .finally(() => {
                    btaBase.lockInput(false);
                });
        }


        function isValidResponse(response: AxiosResponse): boolean {
            return response.status === ResponseCode.Ok
                && response.data.data.body.redirectUrl !== '';
        }


        return {
            ...btaBase, ...{
                selectEntity,
            }
        };
    }
});
</script>
