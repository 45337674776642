import PropertyEnum from '@/Enums/PropertyEnum';
import VueModel from '@/services/vue.model.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {LimitedVariant} from '@/Types/LimitedVariantType';

export default class PropertyTransformation {

    private static instance: PropertyTransformation;

    private constructor() {
    }

    public static getInstance(): PropertyTransformation {
        if (!PropertyTransformation.instance) {
            PropertyTransformation.instance = new PropertyTransformation();
        }

        return PropertyTransformation.instance;
    }

    public transform(propertyId: string): string {
        let result: string = propertyId;
        for (const type in PropertyEnum) {
            if (typeof VueModel.modelValueByName(type, PropertyEnum) === 'object') {
                const transformed: string = this.transformedSubtype(
                    VueModel.modelValueByName(type, PropertyEnum) as DynamicDictionary, propertyId);
                if (transformed !== '') {
                    result = transformed;
                }
            }
        }

        return result;
    }

    private transformedSubtype(subProperty: DynamicDictionary, propertyId: string): string {
        let result: string = '';
        for (const subType in subProperty) {
            const subTypeElement: string = subProperty[subType];
            if (subTypeElement === propertyId) {
                result = subType;
            }
        }

        return result;
    }
}
