<script setup lang="ts">
    import FormField from '@/assets/libraries/form/form-field';
    import {computed, onMounted, Ref} from 'vue';

    const props = defineProps({
        formField: {type: FormField, default: () => new FormField('')},
        showExpanded: {type: Boolean, default: false},
    });
    const isExpanded: Ref<boolean> = computed(() => props.formField.value);

    onMounted((): void => {
        if (props.formField.isEmpty()) {
            props.formField.patch(props.showExpanded);
        }
    });

    function toggle(): void {
        props.formField.patch(!isExpanded.value);
    }
</script>
<template>
    <div class="expandable-item container"
         :id="formField.name"
         :class="{'expanded': isExpanded}"
         :data-store="formField.name"
         :data-store-value="formField.value"
         :show-expanded="showExpanded">
        <div class="main-item" @click="toggle()">
            <div class="info">
                <div class="item">
                    <div ref="leftSideElement" class="left">
                        <slot name="left-side"></slot>
                    </div>
                    <div ref="rightSideElement" class="right">
                        <slot name="right-side"></slot>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isExpanded" class="expandable">
            <slot name="expandable"></slot>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.expandable-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    border-radius: 3px;

    .main-item {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: hidden;
        pointer-events: auto;
        padding: var(--size-small);
        color: var(--black-500);
        cursor: pointer;

        @include respond-above('xs') {
            padding: var(--size-normal);
        }

        > .icon {
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: var(--size-tiny);

            @include respond-above('xs') {
                margin-right: var(--size-normal);
            }

            ::v-deep > :first-child {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;

            .item {
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                flex-shrink: 1;
                font-size: var(--font-size-pico);

                ::v-deep {
                    .highlighted {
                        font-size: var(--font-size-nano);
                        line-height: var(--font-size-nano);
                        font-weight: 600;
                        color: var(--text-color-default);

                        @include respond-above('xs') {
                            font-size: var(--font-size-tiny);
                        }
                    }

                    .capitalize {
                        text-transform: capitalize;
                    }
                }

                .right ::v-deep .highlighted {
                    font-weight: 600;
                }

                .left {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex-grow: 1;
                    flex-shrink: 1;
                    text-align: left;

                    > p {
                        margin-top: var(--size-small);
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    white-space: nowrap;
                    text-align: right;
                }

                .left,
                .right {
                    ::v-deep > *:not(:last-child) {
                        margin-bottom: var(--size-femto);
                    }
                }
            }
        }
    }

    .expandable {
        display: block;
        flex-direction: column;
        text-align: center;
        pointer-events: auto;
        overflow: hidden;
    }

    &.expanded .expandable {
        padding: 0 var(--size-small) var(--size-small);

        @include respond-above('xs') {
            padding: 0 var(--size-normal) var(--size-normal);
        }
    }
}
</style>
