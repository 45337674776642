<script setup lang="ts">
    import {computed, Ref} from 'vue';
    import {useTranslate} from '@/Composables/Translate';

    const props = defineProps({
        icon: {type: String, default: ''},
        title: {type: String, default: ''},
        subtitle: {type: String, default: ''},
    });
    const {translate} = useTranslate();
    const iconSrc: Ref<string> = computed((): string => {
        return `images/one/components/icon-with-label/${props.icon}.svg`;
    });
</script>
<template>
    <div class="icon-with-label"
         :class="{'without-subtitle': !subtitle}">
        <img :src="iconSrc"
             class="icon"
             alt="">
        <div class="description">
            <p class="title" v-if="title">{{ translate(title) }}</p>
            <span class="subtitle" v-html="subtitle" v-if="subtitle"></span>
            <span class="no-subtitle" v-if="!subtitle">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33301 1L9.33301 9M9.33301 1L1.33301 9" stroke="#E30613" stroke-width="1.5"
                      stroke-linecap="round"/>
            </svg>
            <span class="default-subtitle">{{ translate('icon_with_label_default_subtitle') }}</span>
        </span>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.icon-with-label {
    align-items: center;
    display: flex;
    height: 40px;

    .description {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0 var(--size-small);
        height: 100%;

        .title {
            font-size: var(--font-size-pico);
        }

        .subtitle {
            font-size: var(--font-size-nano);
        }

        .default-subtitle {
            margin-left: 10px;
        }
    }

    &.without-subtitle {
        .icon {
            filter: grayscale(100%);
        }
    }

    .icon {
        width: 40px;
        height: 40px;
    }
}
</style>
