<script lang="ts">
import Vue, {defineComponent} from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import {VueApp} from '@/Types/VueAppType';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const getInTouchModule: VueApp = new Vue(GetInTouchModule);
        return {
            ...btaBase,
            ...{getInTouchModule}
        }
    },

    mounted() {
        this.applyApp(this);
        this.setFacility(DataLayerFacilities.HealthInsurance);
        this.create();
        this.initBtaBase();
    },
});
</script>
