export default class PopupType {
    public static readonly CancelSubscription: string = 'cancel-subscription';
    public static readonly ClaimsSuccessInfo: string = 'claims-success-info';
    public static readonly CookiesDisabled: string = 'cookies-disabled';
    public static readonly Covered: string = 'covered';
    public static readonly CoveredInformative: string = 'remind-informative';
    public static readonly CoveredTravel: string = 'covered-travel';
    public static readonly CoveredProduct: string = 'covered-product';
    public static readonly CustomPopup: string = 'custom-popup';
    public static readonly Error: string = 'error';
    public static readonly HealthPolicyAgree: string = 'health-policy-agree';
    public static readonly Info: string = 'info';
    public static readonly List: string = 'list';
    public static readonly ListSearch: string = 'list-search';
    public static readonly Loading: string = 'loading';
    public static readonly LoadingWait: string = 'loading-wait';
    public static readonly Login: string = 'login';
    public static readonly SelectAccount: string = 'select-account';
    public static readonly None: string = 'none';
    public static readonly CoveredProperty: string = 'covered-property';
    public static readonly Remind: string = 'remind';
    public static readonly SessionEnd: string = 'session-end';
    public static readonly TextAgree: string = 'text-agree';
    public static readonly VehicleInsuranceDriverFields: string = 'vehicle-insurance-driver-fields';
    public static readonly VehicleMissingParams: string = 'vehicle-missing-params';
    public static readonly PersonNotVehicleOwner: string = 'person-not-vehicle-owner';
    public static readonly VideoPopup: string = 'video-popup';
    public static readonly Wait: string = 'wait';
    public static readonly Confirm: string = 'confirm';
    public static readonly SimpleError: string = 'simple-error';
    public static readonly OneCovered: string = 'one-covered';
    public static readonly TravelRentalCarSecurity: string = 'travel-rental-car-security';
    public static readonly OneConfirm: string = 'one-confirm';
    public static readonly NextBestAction: string = 'next-best-action';
}
