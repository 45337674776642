<script setup lang="ts">

import {useTranslate} from '@/Composables/Translate';
import {PropType} from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

const props = defineProps({
    data: {type: Object as PropType<DynamicDictionary>, default: () => []},
});
const {translateForType} = useTranslate();

function policyPlanPrice(targetPrice: number, currency: string): string {
    let result: string = '<span class="zero-price"></span>';
    if (targetPrice > 0) {
        result = '<span>' + targetPrice + '</span><span>' + currency + '</span>';
    }

    return result;
}
</script>

<template>
    <div class="inner-one-day-policy">
        <div class="item"
             :key="index"
             v-for="(risk, index) in props.data.risks">
            <div class="title">{{ translateForType(risk.id + '_MAIN', 'bicycle_policy') }}</div>
            <div class="value" v-html="policyPlanPrice(risk.insuredSum, '&nbsp;&euro;')"></div>
        </div>
        <div class="item">
            <div class="title">{{ translateForType('policy_plan_territory', 'bicycle_policy') }}</div>
            <div class="value">{{ props.data.territory }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.inner-one-day-policy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 180px;
    margin-bottom: 20px;

    .item {
        position: relative;
        min-height: 46px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-above('sm') {
            justify-content: center;
            display: inherit;
        }

        .title {
            color: var(--text-color-subtlest);
            font-size: var(--font-size-nano);
            text-align: center;
            padding: 12px 0;
            width: 100%;
        }

        .value {
            position: relative;
            font-weight: 600;
            padding-left: 30px;
            display: flex;
            justify-content: right;
            font-size: var(--font-size-tiny);
            line-height: var(--line-height-accented);
            min-width: 32px;
            width: 50%;

            .zero-price {
                position: absolute;
                background-color: var(--brand-red);
                width: 32px;
                height: 2px;
                right: 0;

                @include respond-above('sm') {
                    left: 50%;
                    margin-left: -16px;
                    margin-top: 10px;
                }
            }

            span {
                &:last-child {
                    padding-left: 4px;
                }
            }
        }

        .title,
        .price {
            text-align: left;

            @include respond-above('sm') {
                text-align: left;
            }
        }

        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            border-top: thin solid var(--component-color-border-default);
            bottom: 0;
            opacity: .24;
        }

        &:first-child {
            &::before {
                position: absolute;
                content: '';
                height: 1px;
                width: 100%;
                border-top: thin solid var(--component-color-border-default);
                top: 0;
                opacity: .24;
            }
        }
    }
}
</style>

