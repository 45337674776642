<script setup lang="ts">
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import {computed, onMounted, reactive, ref, Ref} from 'vue';
import {AxiosResponse} from 'axios';
import moment from 'moment/moment';
import VueRouter from 'vue-router';
import Form from '@/assets/libraries/form/form';
import ButtonWithCallbackParams from '@/Components/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/ButtonWithCallback/Enums/button.border.enum';
import OnePopup from '@/assets/libraries/popups/one.popup';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FormField from '@/assets/libraries/form/form-field';
import Url from '@/Enums/UrlEnum';
import Value from '@/assets/libraries/form/value';
import UserCredentials from '@/interfaces/user.credentials.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import PopupService from '@/services/custom.popup.service';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import {useTranslate} from '@/Composables/Translate';
import {useNavigate} from '@/Composables/Navigate';
import {useFormatter} from '@/Composables/Formatter';
import {useDefine} from '@/Composables/Define';
import {useRouter} from 'vue-router/composables';
import {useStepsSubmitter} from '@/Composables/StepsSubmitter';
import {Program} from '@/Apps/SolarPanels/Interfaces/ProductProgram';
import {SolarPanels} from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';
import {CoveredPopupBuilder} from '@/Apps/SolarPanels/Builders/CoveredPopupBuilder';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import User from '@/services/user.service';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import SettingsService from '@/services/settings.service';
import RequestService from '@/services/request.service';
import Method from '@/Enums/MethodEnum';
import ExtractDataService from '@/services/extract.data.service';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import {useHtml} from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import {CoverageRisk} from '@/Apps/SolarPanels/Interfaces/CoverageRiskInterface';

const router: VueRouter = useRouter();
const {translate, translateForType} = useTranslate();
const {sparsePrice} = useFormatter();
const {isSet} = useDefine();
const {imgTag} = useHtml();
const user: User = User.getInstance();
const popupService: PopupService = PopupService.getInstance();
const settingsService: SettingsService = SettingsService.getInstance();
const extractDataService: ExtractDataService = new ExtractDataService();
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const storage: Storage = Storage.getInstance();
const stepsSubmitter = useStepsSubmitter();
const form: UnwrapNestedRefs<Form> = reactive(new Form());
const Facility: string = 'solar-panels-insurance';
const TranslationType: string = 'solar_panels_insurance';
const ConsentsGlue: string = ':';
const policyStartMinDate: Date = moment().add(1, 'days').toDate();
const policyStartMaxDate: Date = moment().add(30, 'days').toDate();
const policyInsuredSum: number = storage.fields.selectedProgram!.insuredSum;
const solarPanelsAddress: DynamicDictionary = storage.fields.address as DynamicDictionary;
const solarPanelsAddressValue: string = solarPanelsAddress.addressName;
const solarPanelsApartmentValue: string = solarPanelsAddress.apartment ?? solarPanelsAddress.detailed;

const FormFields: string[] = [
    'policyStartDate',
    'policyHolderIsResident',
    'policyHolderFirstName',
    'policyHolderLastName',
    'policyHolderIdentityNumber',
    'policyHolderCountry',
    'policyHolderBirthDate',
    'policyHolderEmail',
    'policyHolderPhone',
    'agreeTerms',
    'marketingConsents',
];

const commonFields: string[] = [
    'policyHolderFirstName',
    'policyHolderLastName',
    'policyHolderIdentityNumber',
    'policyHolderEmail',
    'policyHolderPhone',
    'agreeTerms',
];

const selectedProgram: Ref<Program> = ref(storage.fields.selectedProgram as Program);

const isAuthenticatedUser: Ref<boolean> = computed(() => {
    return user.isLogged();
});

const showMarketingConsents: Ref<boolean> = computed(() => {
    return !user.current.receiveMarketing;
});

const policyStartDate: Ref<string> = computed(() => {
    const result: Date = moment(form.field('policyStartDate').value.startDate).toDate();

    return OneDate.short(result);
});

const policyEndDate: Ref<string> = computed(() => {
    const startDate: Date = form.field('policyStartDate').value.startDate;
    const result: Date = moment(startDate)
        .add(1, 'year')
        .subtract(1, 'day')
        .toDate();

    return OneDate.short(result);
});

const policyHolderIsFromBalticCountries: Ref<boolean> = computed(() => {
    return policyHolderIsResident.value
        || ['EE', 'LV', 'LT'].includes(form.field('policyHolderCountry').value.iso);
});

const policyHolderIsResident: Ref<boolean> = computed(() => {
    return form.field('policyHolderIsResident').value;
});

const birthDateRequired: Ref<boolean> = computed(() => {
    return !policyHolderIsFromBalticCountries.value
        || policyHolderCodeIsWithoutDate.value;
});

const policyHolderCodeIsWithoutDate: Ref<boolean> = computed(() => {
    const identityNumberField: FormField = form.field('policyHolderIdentityNumber');
    const identityNumber: string = String(identityNumberField.value);

    return identityNumberField.isNotEmpty()
        && (Validation.isValidPersonCodeWithoutDate(identityNumber)
            && form.field('policyHolderCountry').value.iso === 'LV');
});

let products: SolarPanels[] = [];

defineExpose({
    coveredPopup,
});

onMounted(() => {
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    if (isSet(storage.fields.selectedProgram)) {
        fetchProducts().then(() => {
            setupForm();
            buildCoveredPopup();
        });
    }
});

function fetchProducts(): Promise<void> {
    return new Promise(resolve => {
        RequestService.getInstance().get({
            uri: Url.Ajax.solarPanelsProducts,
        }).then((response: AxiosResponse): void => {
            if (useDefine().validResponse(response)) {
                products = response.data.data.body.products;
                resolve();
            }
        }).catch((reason: DynamicDictionary): void => {
            Error.log(ErrorType.Error, 'Solar panels policy / Insurance page / Products from cache', reason);
        });
    });
}

function setupForm(): void {
    FormFields.forEach((field: string): void => {
        form.addField(new FormField(field));
    });
    commonFields.forEach((field: string): void => {
        form.addField(new FormField(field));
    });
    applyFormValidatorsAndSanitizers();
    patchDefaultValues();
    patchUserFields();
    resetForm();
    form.setReady();
}

function buildCoveredPopup(): void {
    const selectedProduct: SolarPanels[] = [];
    const storedProductId: string = storage.fields.selectedProgram!.programIc;
    selectedProduct.push((products!)
        .filter((product: SolarPanels): boolean => product.id === storedProductId)[0]);
    coveredPopup
        .withCoveredType('AppCoveredPopupSolarPanelsMulti')
        .withContent(selectedProduct)
        .withCoveragePlanKey(storage.fields.selectedProgram!.insuredSum)
        .withSingleMode();
}

function coveredButtonParams(): ButtonWithCallbackParams {
    return {
        title: translateForType('what_covered_solar_panels', TranslationType),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Covered,
        iconColor: ButtonIconColor.Green,
        borderColor: ButtonBorder.Pale,
    };
}

function marketingConsents(): string[] {
    const selectedConsents: string = form.field('marketingConsents').value.selected;

    return new Value(selectedConsents).isNotEmpty() ?
        selectedConsents.split(ConsentsGlue) : [];
}

function minPolicyStartDate(): Date {
    const offset: number = settingsService.movablePropertySettings().minPolicyStartDateOffset;

    return moment().add(offset, 'day').toDate();
}

function patchUserFields(): void {
    const currentUser: UserCredentials = user.current;
    form.field('policyHolderFirstName').patch(currentUser.firstname);
    form.field('policyHolderLastName').patch(currentUser.lastname);
    form.field('policyHolderIdentityNumber').patch(currentUser.personCode);
    if (form.field('policyHolderEmail').isEmpty()) {
        form.field('policyHolderEmail').patch(currentUser.email);
    }
    if (form.field('policyHolderPhone').isEmpty()) {
        form.field('policyHolderPhone').patch({
            code: currentUser.code,
            iso: currentUser.code,
            country: currentUser.phoneCode,
            phone: currentUser.phone,
        });
    }
}

function resetForm(): void {
    form.fields().forEach((field: FormField): void => {
        field.markAsUntouched();
    });
}

function redirectToBank(): void {
    useNavigate().navigate(Url.PaymentsApi.paymentsPay);
}

function captionValidator(assignedToField: string): object {
    const field: FormField = form.field(assignedToField);
    return {
        validCaption: (): boolean => field.isTouched ?
            Validation.isValidCaption(field.value) : true
    }
}

function identityNumberValidator(): object {
    return {
        validIdentityNumber: (): boolean => {
            return form.field('policyHolderIdentityNumber').isTouched
                ? isValidIdentityNumber()
                : true;
        }
    }
}

function isValidIdentityNumber(): boolean {
    let result: boolean = true;
    if (policyHolderIsFromBalticCountries.value) {
        const personCodeField: FormField = form.field('policyHolderIdentityNumber');
        const countryField: FormField = form.field('policyHolderCountry');
        const validationResult: boolean = policyHolderIsResident.value ?
            Validation.isValidNaturalPersonCode(personCodeField.value) :
            Validation.isValidNaturalPersonCode(personCodeField.value, countryField.value.iso);
        result = validationResult && personCodeField.isNotEmpty();
    }

    return result;
}

function applyFormValidatorsAndSanitizers(): void {
    form.field('policyHolderFirstName')
        .addValidators(captionValidator('policyHolderFirstName'));
    form.field('policyHolderLastName')
        .addValidators(captionValidator('policyHolderLastName'));
    form.field('policyHolderIdentityNumber')
        .addValidators(identityNumberValidator());
    form.field('policyHolderBirthDate').addValidators('required');
    form.field('policyHolderFirstName').addSanitizer(Sanitizer.cleanUppercaseName);
    form.field('policyHolderLastName').addSanitizer(Sanitizer.cleanUppercaseName);
    form.field('policyHolderIdentityNumber').addSanitizer(Sanitizer.clearPersonCode);
}

function updatePolicyHolderBirthDate(): void {
    const currentUser: UserCredentials = user.current;
    if (isAuthenticatedUser.value) {
        form.field('policyHolderBirthDate').patch(currentUser.birthDate);
    } else if (policyHolderIsFromBalticCountries.value && !policyHolderCodeIsWithoutDate.value) {
        form.field('policyHolderBirthDate').patch(extractedPolicyHolderBirthDate());
    }
}

function scrollToElements(elements: JQuery): void {
    if (elements.length > 0) {
        elements[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center'
        });
    }
}

function policyHolder(): DynamicDictionary {
    return {
        firstName: form.field('policyHolderFirstName').value,
        lastName: form.field('policyHolderLastName').value,
        identityNumber: form.field('policyHolderIdentityNumber').value,
        country: form.field('policyHolderCountry').value,
        birthDate: OneDate.iris(form.field('policyHolderBirthDate').value),
        email: form.field('policyHolderEmail').value,
        phone: form.field('policyHolderPhone').value,
        isResident: isAuthenticatedUser.value
            ? true
            : policyHolderIsResident.value,
        marketingConsents: marketingConsents(),
    };
}

function patchDefaultValues(): void {
    patchDefaultHolderCountry();
    form.field('policyHolderIsResident').patch(true);
    form.field('policyStartDate').patch({startDate: minPolicyStartDate(), endDate: ''});
}

function patchDefaultHolderCountry(): void {
    form.field('policyHolderCountry').patch({
        ic: '100000000',
        iso: settingsService.localeIso(),
        phoneCode: settingsService.phoneCode(),
    });
}

function touchAndRequireNecessaryFields(): void {
    updatePolicyHolderBirthDate();
    if (isAuthenticatedUser.value) {
        touchAndRequireUserFields();
    } else {
        touchAndRequireGuestFields();
    }
}

function touchAndRequireUserFields(): void {
    commonFields.forEach((field: string): void => {
        form.field(field)
            .addValidators('required')
            .touch();
    })
}

function touchAndRequireGuestFields(): void {
    const guestFields: string[] = commonFields;
    if (!policyHolderIsResident.value) {
        guestFields.push('policyHolderCountry');
    }
    if (birthDateRequired.value) {
        guestFields.push('policyHolderBirthDate');
    }
    guestFields.forEach((field: string): void => {
        form.field(field)
            .addValidators('required')
            .touch();
    });
}

function extractedPolicyHolderBirthDate(): string {
    const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
        form.field('policyHolderIdentityNumber').value,
        form.field('policyHolderCountry').value.iso
    );

    return extractedBirthDate ? OneDate.iris(extractedBirthDate) : '';
}

function selectedProduct(): SolarPanels {
    return products.find((product: SolarPanels): boolean => product.id === selectedProgram.value.programIc) as SolarPanels;
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function onIsResidentClick(): void {
    if (form.field('policyHolderIsResident').value) {
        patchDefaultHolderCountry();
    } else {
        form.field('policyHolderCountry').clear();
    }
}

function onConfirmAndPayClick(): void {
    resetForm();
    touchAndRequireNecessaryFields();
    form.validate().then((): void => {
        if (form.isValid()) {
            prepareSubmit();
            stepsSubmitter.submitMethod(Method.Post);
            stepsSubmitter.submitStep(Url.Ajax.solarPanelsPurchase);
        } else {
            const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
            scrollToElements(invalidElements);
        }
    });
}

function prepareSubmit(): void {
    const bankLinkId: number = RecurringPaymentBanklink.ByCountry[(new AppCountry()).iso()];
    stepsSubmitter.addSubmitParam('planId', selectedProduct().id, false);
    stepsSubmitter.addSubmitParam('termsIc', selectedProduct().params.termsIc, false);
    stepsSubmitter.addSubmitParam('deductibleIc', selectedProduct().params.deductibleIc, false);
    stepsSubmitter.addSubmitParam('sumInsured', selectedProgram.value.insuredSum, false);
    stepsSubmitter.addSubmitParam('risks', transformedRisks(includedRisks()), false);
    stepsSubmitter.addSubmitParam('policyStartDate', OneDate.iris(policyStartDate.value), false);
    stepsSubmitter.addSubmitParam('address', storage.fields.address!, false);
    stepsSubmitter.addSubmitParam('policyHolder', policyHolder(), false);
    stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId, false);
    stepsSubmitter.addSubmitParam('paymentFailRedirectUrl',
        router.currentRoute.path, false);
    stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
    stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
    stepsSubmitter.addSubmitCustomParam('facility', Facility);
}

function additionalRisks(): String[] {
    let result: String[] = [];
    products.forEach((product: SolarPanels) => {
        product.risks.map((risk: CoverageRisk) => {
            if (!risk.isIncluded) {
                result.push(risk.id);
            }
        });
    });

    return [...new Set(result)];
}

function includedRisks(): CoverageRisk[] {
    let result: CoverageRisk[] = [];
    additionalRisks().map((additionalRisk: String) => {
            const risks: CoverageRisk[] =
                selectedProduct().risks.filter((risk: CoverageRisk) =>
                    risk.id === additionalRisk
                    && risk.isIncluded);
            result = result.concat(risks);
        }
    );

    return result;
}

function transformedRisks(risks: CoverageRisk[]): DynamicDictionary[] {
    let result: DynamicDictionary[] = [];
    const deductibleIc: string = selectedProduct().params.deductibleIc;
    risks.forEach((includedRisk: CoverageRisk) => {
        let risk: DynamicDictionary = {};
        risk.ic = includedRisk.id;
        risk.isSelected = includedRisk.isIncluded;
        risk.sumInsured = selectedProgram.value.insuredSum;
        risk.deductibleIc = deductibleIc;
        result.push(risk);
    });

    return result;
}

function cardIcon(name: string): string {
    return imgTag(cardIconAttributes(name));
}

function cardIconAttributes(name: string): ImageTagAttributes {
    const source: string = 'images/one/bank/' + name + '_title.svg'
    return {
        class: '',
        src: source,
        width: 36,
        height: 24,
    }
}
</script>

<template>
    <div class="container">
        <div class="loading" v-if="!form.isReady()">
            <app-content-loader></app-content-loader>
        </div>
        <app-custom-form else
                         v-if="form.isReady()"
                         :form="form"
                         class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back">
                <span>{{ translate('back_button') }}</span>
            </a>
            <h1 class="page-title">{{ translateForType('title_summary_and_payment', TranslationType) }}</h1>
            <h2 class="page-subtitle">{{ translateForType('subtitle_summary_and_payment', TranslationType) }}</h2>
            <section class="whiteboard" id="whiteboard-0">
                <div class="whiteboard-title">{{
                        translateForType('section_title_policy_details', TranslationType)
                    }}
                </div>
                <ul class="list details">
                    <li class="item" id="whiteboard-item-0-0">
                        <span>{{ translateForType('caption_insurance_object', TranslationType) }}</span>
                        <span class="item-name">{{ translateForType('label_insured_product', TranslationType) }}</span>
                    </li>
                    <li class="item" id="whiteboard-item-0-1">
                        <span>{{ translateForType('caption_insurance_sum', TranslationType) }}</span>
                        <span class="item-name">{{ sparsePrice(policyInsuredSum, true) }} &euro;</span>
                    </li>
                    <li class="item" id="whiteboard-item-0-2">
                        <span>{{ translateForType('caption_insured_object_address', TranslationType) }}</span>
                        <span class="item-name">{{ solarPanelsAddressValue }}</span>
                    </li>
                    <li class="item" id="whiteboard-item-0-3" v-if="solarPanelsApartmentValue !== ''">
                        <span>{{ translateForType('caption_insured_object_apartment', TranslationType) }}</span>
                        <span class="item-name">{{ solarPanelsApartmentValue }}</span>
                    </li>
                </ul>
                <app-button-with-callback
                    v-bind="coveredButtonParams()"
                    @button-callback-click="onShowCoveredPopupClick">
                </app-button-with-callback>
            </section>
            <section class="whiteboard" id="whiteboard-1">
                <div
                    class="whiteboard-title">{{ translateForType('section_title_insurance_period', TranslationType) }}
                </div>
                <div
                    class="title">{{ translateForType('caption_policy_start_date', TranslationType) }}
                </div>
                <app-input-date-with-calendar
                    class="policy-start-date"
                    :form-field="form.field('policyStartDate')"
                    :min-date="policyStartMinDate"
                    :max-date="policyStartMaxDate"
                    :number-of-calendars="1">
                </app-input-date-with-calendar>
                <div class="list">
                    <div class="item">
                        <span>{{ translateForType('caption_policy_period', TranslationType) }}</span>
                        <span class="item-name">{{ policyStartDate + ' - ' + policyEndDate }}</span>
                    </div>
                </div>
            </section>
            <section class="whiteboard" id="whiteboard-2">
                <div class="whiteboard-title">{{
                        translateForType('section_title_payment_details', TranslationType)
                    }}
                </div>
                <div class="list">
                    <div class="item">
                        <span>{{ translateForType('summary_number_of_payments', TranslationType) }}</span>
                        <span class="item-name">12</span>
                    </div>
                    <div class="item total inline">
                        <span class="item-name payment">{{translateForType('label_total_payment', TranslationType) }}</span>
                        <span class="item-total">
                            {{sparsePrice(selectedProgram.price, false) }}
                            <span class="currency">{{translateForType('eur_per_month', TranslationType) }}</span>
                        </span>
                    </div>
                </div>
            </section>
            <section class="whiteboard" id="whiteboard-3">
                <app-popup-opener
                    v-if="!isAuthenticatedUser"
                    class="login-opener"
                    :button-params="{title: translateForType('login_with_online_bank', 'components')}">
                </app-popup-opener>
                <div class="whiteboard-title">{{
                        translateForType('section_title_policy_holder', TranslationType)
                    }}
                </div>
                <app-input-checkbox
                    v-if="!isAuthenticatedUser"
                    :form-field="form.field('policyHolderIsResident')"
                    @click="onIsResidentClick">
                    {{ translateForType('checkbox_is_resident', TranslationType) }}
                </app-input-checkbox>
                <app-input-country
                    v-if="!isAuthenticatedUser && !policyHolderIsResident"
                    :include-default-country="false"
                    :patch-default-country="false"
                    :disable-error-text="true"
                    :form-field="form.field('policyHolderCountry')"
                    :label="translate('btar_country')"
                    :placeholder="translate('btar_country_placeholder')">
                </app-input-country>
                <div class="credentials three-columns">
                    <app-input-text
                        :label="translate('btar_name')"
                        :disabled="isAuthenticatedUser"
                        :form-field="form.field('policyHolderFirstName')"
                        :placeholder="translate('btar_first_name_placeholder')">
                    </app-input-text>
                    <app-input-text
                        :label="translate('btar_surname')"
                        :disabled="isAuthenticatedUser"
                        :form-field="form.field('policyHolderLastName')"
                        :placeholder="translate('btar_last_name_placeholder')">
                    </app-input-text>
                    <app-input-identity-number
                        :form-field="form.field('policyHolderIdentityNumber')"
                        :disabled="isAuthenticatedUser"
                        :country="form.field('policyHolderCountry').value.iso"
                        :label="translate('btar_personal_code')"
                        :placeholder="translate('btar_identity_number_placeholder')">
                    </app-input-identity-number>
                </div>
                <div>
                    <app-input-date
                        class="half-width"
                        v-if="birthDateRequired"
                        :validate-birth-date="true"
                        :form-field="form.field('policyHolderBirthDate')"
                        :label="translate('btar_birth_date')">
                    </app-input-date>
                </div>
                <div class="credentials three-columns">
                    <app-input-email
                        :label="translate('btar_email')"
                        :disable-error-text="true"
                        :form-field="form.field('policyHolderEmail')"
                        :placeholder="translate('btar_email_placeholder')">
                    </app-input-email>
                    <app-phone-with-country
                        :label="translate('btar_phone')"
                        :disable-error-text="true"
                        :form-field="form.field('policyHolderPhone')"
                        :placeholder="translate('btar_phone_placeholder')">
                    </app-phone-with-country>
                </div>
            </section>
            <section class="whiteboard" id="whiteboard-4">
                <div
                    class="whiteboard-title">{{ translateForType('title_terms_and_agreements', TranslationType) }}
                </div>
                <div class="purchase-terms"
                     v-html="translateForType('description_terms_and_agreements', TranslationType)"></div>
                <hr class="line-break"/>
                <app-input-checkbox :form-field="form.field('agreeTerms')">
                    {{ translateForType('checkbox_agree_terms', TranslationType) }}
                </app-input-checkbox>
                <hr class="line-break"/>
                <app-consents-list
                    v-if="showMarketingConsents"
                    :form-field="form.field('marketingConsents')">
                </app-consents-list>
            </section>
            <h1 class="page-title middle">Subcription payment</h1>
            <div class="payment-details">
                <section class="whiteboard" id="whiteboard-5">
                    <div class="title margin-bottom-small">
                        <div class="title-text">
                            <div>{{ translateForType('caption_subscription_payment_details', TranslationType) }}</div>
                        </div>
                        <div class="title-logos">
                            <div class="title-logos-logo">
                                <div v-html="cardIcon('visa')"></div>
                            </div>
                            <div class="title-logos-logo">
                                <div v-html="cardIcon('master_card')"></div>
                            </div>
                        </div>
                    </div>
                    <div class="list">
                        <div class="item total inline">
                            <span class="item-name payment">{{
                                    translateForType('label_payment_due_now', TranslationType)
                                }}</span>
                            <span class="item-total">{{
                                    sparsePrice(selectedProgram.price, false)
                                }} <span class="currency">&euro;</span>
                            </span>
                        </div>
                    </div>
                    <button class="button red submit-and-pay"
                            @click="onConfirmAndPayClick">{{
                            translateForType('button_confirm_and_pay', TranslationType)
                        }}
                    </button>
                </section>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.custom-form {
    flex-direction: column;
    align-items: flex-start;

    @include respond-above('sm') {
        gap: var(--size-pico);
        align-items: center;
    }
}

.page-subtitle {
    @include respond-above('sm') {
        margin-top: 0 !important;
    }
}

.whiteboard {
    .login-opener {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-above('sm') {
            align-items: flex-end;
            position: absolute;
            max-width: 720px;
        }
    }

    :deep a {
        color: var(--text-color-link);
    }

    .whiteboard-title {
        color: var(--text-color-default);
        font-size: var(--font-size-small);
        font-weight: 700;
    }

    .purchase-terms {
        font-size: var(--font-size-nano);
    }

    .line-break {
        width: 100%;
        height: 1px;
        background-color: var(--component-color-border-default);
    }

    .button-with-callback {
        width: 100%;
        height: 52px;
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }

    .input-date-with-calendar {
        width: 100%;
    }

    .input-date {
        .wrapper {
            height: 52px;
            justify-content: left;
            padding-left: var(--size-small);
        }
    }

    .list {
        width: 100%;
        border-radius: var(--size-pico);
        border: 1px solid var(--black-100);
        display: flex;
        flex-direction: column;

        .item {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: var(--size-pico);
            align-self: stretch;
            font-size: var(--font-size-nano);

            @include respond-above('sm') {
                flex-direction: row;
                justify-content: space-between;
                padding: var(--size-small);
            }

            &.inline {
                display: flex;
                padding: var(--size-small);
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                gap: var(--size-pico);
                align-self: stretch;
                font-size: var(--font-size-nano);
                text-align: left;
            }

            &.total {
                background: var(--system-color-success-light);
            }

            .item-total {
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .currency {
                font-size: var(--font-size-tiny);
                font-weight: 600;
            }

            .item-name {
                font-weight: 600;

                @include respond-above('sm') {
                    width: 50%;
                    text-align: right;
                }

                &.payment {
                    text-align: left;
                    font-size: var(--font-size-nano);
                    font-weight: 600;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--black-100);
            }
        }
    }

    .input-country {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
    }

    .birth-date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-nano);
        align-self: stretch;

        .input-date {
            width: 100%;

            .wrapper {
                padding: var(--size-small);
                height: 52px;
                justify-content: left;
            }
        }

        .date-hint {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-self: stretch;
            color: var(--text-color-subtlest);
            font-feature-settings: 'liga' off;
            font-size: var(--font-size-nano);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            flex: 100;
        }
    }

    .button.red {
        width: 100%;
    }

    .credentials {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;
        gap: inherit;

        @include respond-above('sm') {
            flex-direction: row;
            gap: var(--size-small);
        }

        &.three-columns {
            .input {
                @include respond-above('sm') {
                    width: 100%;
                }
            }
        }
    }
}

.payment-details {
    width: 100%;
    max-width: 880px;

    .title {
        display: flex;
        justify-content: space-between;
        font-size: var(--font-size-small);
        font-weight: 600;
    }

    .title-logos {
        text-align: end;
        display: flex;
    }

    .title-logos-logo {
        padding: 0 0 0 var(--size-pico);
    }
}
</style>
