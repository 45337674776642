import ErrorType from '@/Enums/ErrorTypeEnum';
import Error from '@/services/error.service';

export default class Captcha {
    private static readonly DefaultAction: string = 'validate_captcha';
    private static readonly NotLoaded: string = 'captcha_script_not_loaded';
    private static instance: Captcha;
    private siteKey: string = '';
    private enabled: boolean = false;

    public static getInstance(): Captcha {
        if (!Captcha.instance) {
            Captcha.instance = new Captcha();
        }
        return Captcha.instance;
    }

    public addSiteKey(key: string, enabled: boolean): void {
        this.siteKey = key;
        this.enabled = enabled;
    }

    public executeCaptcha(callback: Function): Promise<string> {
        let result: Promise<string>;
        if (!this.enabled) {
            callback();
            result = Promise.resolve('');
        } else {
            result = new Promise((resolve, reject) => {
                if (typeof grecaptcha === 'undefined') {
                    reject(Captcha.NotLoaded);
                }
                grecaptcha.ready(() => {
                    grecaptcha.execute();
                });
            });
        }

        return result;
    }

    public executeParametricCaptcha(callback: Function, captchaId: string): void {
        if (!this.enabled) {
            callback('');
        } else {
            grecaptcha.ready(() => {
                if (typeof grecaptcha === 'undefined') {
                    Error.log(ErrorType.Error, 'executeParametricCaptcha',
                        'Failed to execute captcha for:  ' + captchaId, true);
                } else {
                    grecaptcha.execute(this.siteKey, {action: captchaId})
                        .then((token: string) => {
                            callback(token);
                        });
                }
            });
        }
    }

    public resetCaptcha(): void {
        if (this.enabled && typeof grecaptcha !== 'undefined') {
            grecaptcha.reset();
        }
    }
}
