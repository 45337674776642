export default class DataLayerType {
    public static readonly AccidentInsurance: string = 'AccidentInsurance';
    public static readonly AccidentProductId: string = 'AccidentProduct';
    public static readonly AutoInsurance: string = 'AutoInsurance';
    public static readonly AutoProductBundle: string = 'bundle';
    public static readonly AutoProductFakeId: string = 'AutoProduct';
    public static readonly AutoProductMonthly: string = '12';
    public static readonly AutoProductOnce: string = '1';
    public static readonly AutoProductPolicyPeriodPostfix: string = 'month';
    public static readonly AutoProductStandalone: string = 'standalone';
    public static readonly AutoProductSubscription: string = 'subscription';
    public static readonly Callback: string = 'call-back';
    public static readonly ClaimStep: string = 'claim-step@';
    public static readonly ClaimSuccess: string = 'claim-success';
    public static readonly ClaimUploadFiles: string = 'claim-upload';
    public static readonly Lead: string = 'lead';
    public static readonly LoginFail: string = 'Login Fail';
    public static readonly ProductTypeAccident: string = 'Accident';
    public static readonly ProductTypeProperty: string = 'HomeInsurance';
    public static readonly ProductTypeTravel: string = 'Travel';
    public static readonly ProductTypeVehicle: string = 'Vehicle';
    public static readonly TravelInsurance: string = 'TravelInsurance';
    public static readonly TravelProductDays: string = '@days';
    public static readonly TravelProductFakeId: string = 'TravelProduct';
    public static readonly TravelProductMultipleDays: string = 'Multiple @days';
    public static readonly PropertyProductFakeId: string = 'PropertyProduct';
    public static readonly PropertyInsurance: string = 'HomeInsurance';
}
