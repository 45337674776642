<script setup lang="ts">
import {Ref, ref, computed, onMounted, reactive, watch, PropType} from 'vue';
    import Validation from '@/services/validation.service';
    import FormField from '@/assets/libraries/form/form-field';
    import PostCode from '@/interfaces/post.code.interface';
    import OptionValue from '@/interfaces/option.value.interface';
    import PostCodeListItem from '@/interfaces/post.code.options.list.item.interface';
    import SettingsService from '@/services/settings.service';
    import {Subscription} from 'rxjs';
    import OptionWithToken from '@/interfaces/option.with.token.interface';
    import axios, {AxiosResponse, CancelTokenSource} from 'axios';
    import VueEvent from '@/Classes/VueEventClass';
    import Url from '@/Enums/UrlEnum';
    import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
    import {useFormatter} from '@/Composables/Formatter';
    import {PostCodeParams} from '@/Components/InputPostCode/PostCodeParams';
import {InputOption} from '@/interfaces/InputOptionInterface';

    const props = defineProps({
      placeholder: {type: String, default: () => ''},
      obligatory: {type: Boolean, default: true},
      formField: {type: Object as PropType<FormField<PostCodeParams>>, default: () => new FormField('')},
      label: {type: String, default: ''},
      disabled: {type: Boolean, default: false},
      dataStoreDisabled: {type: Boolean, default: false},
      disableErrorText: {type: Boolean, default: false},
      feedbackMessage: {type: String, default: ''},
      supportTextMessage: {type: String, default: ''},
      popupLabel: {type: String, default: ''}
    });

    const emit = defineEmits(['change', 'close']);
    const settingsService: SettingsService = SettingsService.getInstance();
    const {formattedUrl} = useFormatter();
    const MaxDefaultElements: number = 5;
    const postCodes: Ref<PostCode[]> = ref([]);
    const postCode: OptionValue = reactive({
      id: ':void:',
      value: '',
    });
    const isVisibleDropdown: Ref<boolean> = computed(() => {
      return typedValue.value !== '' || inputInFocus.value
    });
    const postCodeData: Ref<PostCodeParams> = computed(() => {
      return {
        id: postCode.id,
        code: postCode.value
      }
    });
    const axiosToken: OptionWithToken = new class implements OptionWithToken {
      public options: InputOption[] = [];
      public cancelToken: CancelTokenSource | null = null;
      public fetchIsInProgress: boolean = false;
    };

    let visible: Ref<boolean> = ref(false);
    let typedValue: Ref<string> = ref('');
    let options: Ref<PostCodeListItem[]> = ref([]);
    let index: number = 0;
    let inputInFocus: Ref<boolean> = ref(false);
    let onExternalDataIsReadySubscription!: Subscription;

    watch(() => postCode.id, () => {
      props.formField?.setValue(postCodeData.value);
      emitChange();
    });

    onMounted((): void => {
      if (settingsService.ready) {
        init();
      } else {
      onExternalDataIsReadySubscription = settingsService.onExternalDataIsReady.subscribe(() => {
          init();
        });
      }
    });

    function beforeDestroy(): void {
      if (onExternalDataIsReadySubscription) {
        onExternalDataIsReadySubscription.unsubscribe();
      }
    }

    function showListSearch(): void {
      if (!props.disabled) {
        prepareOptionsList();
      }
    }

    function isVisibleItem(current: PostCodeListItem): boolean {
      let result: boolean = false;
      if (typedValue.value) {
        result = current.code.toLowerCase().includes(typedValue.value.toLowerCase());
      } else if (inputInFocus.value) {
        result = current.index < MaxDefaultElements;
      }

      return result;
    }

    function onFocus(event: VueEvent): void {
      inputInFocus.value = true;
    }

    function onBlur(event: VueEvent): void {
      setTimeout(() => {
      inputInFocus.value = false;
    }, 500);
    }

    function onOptionSelectClick(event: VueEvent): void {
      postCode.id = event.data('id');
      postCode.value = event.data('value');
      close();
    }

    function closePopup(): void {
      close();
    }

    function fieldId(): string {
      return props.formField.name + '-showListSearch';
    }

    function init(): void {
      if (postCodes.value.length === 0) {
        fetchPostCodes();
      }
      props.formField.addValidators(postCodeValidators());
    }

    function postCodeValidators(): object {
      return {
        isValidPostCodeForCountry: () => {
          return props.formField.isTouched
              ? Validation.isValidPostalCode(props.formField.value.code)
              : true;
        },
      }
    }

    function fetchPostCodes(): void {
      if (axiosToken.fetchIsInProgress) {
        axiosToken.cancelToken!.cancel();
      }
      axiosToken.fetchIsInProgress = true;
      axiosToken.cancelToken = axios.CancelToken.source();
      axios.get(formattedUrl(Url.Ajax.fetchPostCodes),
          {cancelToken: axiosToken.cancelToken.token}
      ).then((value: AxiosResponse) => {
        const fetchedPostCodes: PostCode[] = JSON.parse(value.data.data.body.postCodes);
        if (fetchedPostCodes.length > 0) {
          postCodes.value = fetchedPostCodes;
        }
        axiosToken.fetchIsInProgress = false;
      }).catch((reason: DynamicDictionary) => {
        axiosToken.fetchIsInProgress = false;
      })
    }

    function prepareOptionsList(): void {
      clear();
      for (let item in Object.keys(postCodes.value)) {
        addItem(item, postCodes.value[item].code);
      }
      typedValue.value = '';
      visible.value = true;
    }

    function clear(): void {
      options.value = [];
      index = 0;
    }

    function addItem(id: string, code: string): void {
      const itemIndex: number = index++;
      options.value.push(
        new class implements PostCodeListItem {
          public id: string = id;
          public code: string = code;
          public index: number = itemIndex;
        }
      );
    }

    function close(): void {
      visible.value = false;
      emit('close');
    }

    function emitChange(): void {
      props.formField.touch();
      props.formField.sanitize();
      props.formField.validate();
      emit('change', props.formField.value);
    }
</script>

<template>
  <div class="input input-post-code"
       :id="formField.name"
       :class="formField.classes()"
       :data-store="dataStoreDisabled ? '' : formField.name"
       :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
    <div v-if="label" class="label informative">
      <p v-html="label"></p>
      <slot name="app-tooltipster"></slot>
    </div>
    <div class="wrapper select">
      <div class="current button cursor-pointer"
           :id="fieldId()"
           @click="showListSearch()">
        <div class="text" v-html="formField.value.code"></div>
        <span class="icon arrow-icon">
                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 1L7 7L1 1" stroke="#E30613" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                </svg>
            </span>
      </div>
    </div>
    <div class="popups">
      <app-popup v-if="visible" class="simple list-search" @close="closePopup">
        <div class="title" v-html="popupLabel || label"></div>
        <div class="elements">
          <div class="field">
            <div class="added"></div>
            <div class="search">
              <div class="icon">
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.48">
                    <path d="M14 14L20 20" stroke="#9297A0" stroke-width="2"></path>
                    <circle cx="8.5" cy="8.5" r="7.5" stroke="#9297A0"
                            stroke-width="2"></circle>
                  </g>
                </svg>
              </div>
              <input class="text"
                     :id="formField.name + '-typedValue'"
                     v-model="typedValue"
                     @focus="onFocus(new VueEvent($event))"
                     @blur="onBlur(new VueEvent($event))"
                     :placeholder="placeholder"
                     :autocomplete="'disabled'"/>
            </div>
            <div class="dropdown"
                 :class="{hidden:!isVisibleDropdown}">
              <button class="country-item"
                      v-for="(item, index) in options"
                      :key="index"
                      :id="formField.name + '-dropdown-' + item.code"
                      :class="{hidden:!isVisibleItem(item)}"
                      :data-value="item.code"
                      :data-id="item.index"
                      @click="onOptionSelectClick(new VueEvent($event))">
                <span class="text">{{ item.code }}</span>
              </button>
            </div>
          </div>
        </div>
      </app-popup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-post-code {
  scroll-margin-top: 4em;

  &.invalid:not(.untouched) {
    .current {
      border-color: var(--brand-red);
    }
  }
}
</style>
