export default class AppEnvironment {
    private readonly DevEnvironmentUriPattern: string = 'di.lv';
    private readonly TestEnvironmentUriPattern: string = 'www-test';
    private readonly LocalEnvironmentUriPattern: string = 'local';
    private readonly TestEnvironmentAcceptanceUriPattern: string = 'www-acceptance-test';

    private readonly FeatureEnvironmentAcceptanceUriPattern: string = 'acceptance.www-test.bta';

    private readonly LocalEnvironmentAcceptanceUriPattern: string = 'testing-bta';

    private readonly Location: string = '';

    constructor() {
        this.Location = String(window.location);
    }

    public isProduction(): boolean {
        return !this.isDevelopment() && !this.isTest() && !this.isLocal() && !this.isAcceptanceTest();
    }

    public isDevelopment(): boolean {
        return this.Location.includes(this.DevEnvironmentUriPattern);
    }

    public isTest(): boolean {
        return this.Location.includes(this.TestEnvironmentUriPattern);
    }

    public isLocal(): boolean {
        return this.Location.includes(this.LocalEnvironmentUriPattern);
    }

    public isAcceptanceTest(): boolean {
        return this.Location.includes((this.TestEnvironmentAcceptanceUriPattern))
            || this.Location.includes((this.FeatureEnvironmentAcceptanceUriPattern))
            || this.Location.includes((this.LocalEnvironmentAcceptanceUriPattern));
    }
}
