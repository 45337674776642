<script setup lang="ts">

import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import {computed, onMounted, PropType, reactive, Ref, watch} from 'vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';
import FormField from '@/assets/libraries/form/form-field';
import CssClass from '@/Enums/CssClassEnum';
import {useTranslate} from '@/Composables/Translate';
import OptionsSmartTypes from '@/Components/OptionsSmartList/Enums/OptionsSmartTypesEnum';
import OptionsSmartCustom from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomInterface';
import OptionsSmartCustomSelectState
    from '@/Components/OptionsSmartList/Interfaces/OptionsSmartCustomSelectStateInterface';
import {useDefine} from '@/Composables/Define';
import Tooltipster from '@/interfaces/tooltipster.interface';
import Form from '@/assets/libraries/form/form';
import InputDateWithCalendarBinds
    from '@/Components/OptionsSmartList/Components/interfaces/InputDateWithCalendarBindsInterface';
import {LimitedVariant} from '@/Types/LimitedVariantType';
import Sanitizer from '@/services/sanitizer.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import {OptionsCustomValue} from '@/Components/OptionsSmartList/Types/OptionsCustomValueType';
import {useError} from '@/Composables/Error';
import {InputOption} from '@/interfaces/InputOptionInterface';
import uuid from 'uuid';

const props: DynamicDictionary = defineProps({
    options: {type: Array, default: () => []},
    type: {type: String as PropType<'checkbox' | 'radio'>, default: 'checkbox'},
    optionClass: {type: String as PropType<'filled' | ''>, default: ''},
    maxSelect: {type: Number, default: 0},
    minCharacters: {type: Number, default: 3},
    showErrorBorders: {type: Boolean, default: true},
    required: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    label: {type: String, default: ''},
    skipOptionsChangeFormReset: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    formField: {type: Object as PropType<FormField<string>>, default: () => new FormField('')},
    translationType: {type: String, default: 'components'},
});

const emit = defineEmits(['change', 'option-confirm']);

const {translate, translateForType} = useTranslate();
const {isSet} = useDefine();
const {logError} = useError();

const checkboxValuesSeparator: string = '@#@';

const field: UnwrapNestedRefs<FormField> = reactive(props.formField);
const form: UnwrapNestedRefs<Form> = reactive(new Form());

const optionsSmartTypes: Ref<typeof OptionsSmartTypes> = computed(() => {
    return OptionsSmartTypes;
});

const sumLabel: Ref<string> = computed(() => {
    return translate('euro');
});

const invalidClass: Ref<string> = computed(() => {
    return props.formField && (props.formField.classes() as any).invalid ? CssClass.Invalid : '';
});

const isCheckbox: Ref<boolean> = computed(() => {
    return props.type === 'checkbox';
});

const checkboxValues: Ref<string[]> = computed(() => {
    if (props.formField.isEmpty()) {
        prepareValue();
    }

    return props.formField.value.selected === '' ?
        [] : props.formField.value.selected.split(checkboxValuesSeparator);
});

onMounted(() => {
    setupForm().then((): void => {
        props.formField.addValidators(validators());
        setupCalendars();
    });
});

function itemClasses(selected: InputOption): string {
    let result: string[] = [
        'options-smart-list-' + props.type,
        !props.showErrorBorders ? 'omit-red-borders' : ''
    ];
    if (itemIsDisabled(selected)) {
        result.push(CssClass.Disabled);
    }
    if (selected.hidden) {
        result.push(CssClass.Invisible);
    }
    if (isVisibleTooltipBlock(selected)) {
        result.push('options-smart-list-option-with-tooltip');
    }
    if (!isCheckbox.value) {
        result.push('radio');
    }
    if (props.optionClass !== '') {
        result.push(props.optionClass)
    }
    if (itemIsSelected(selected)) {
        if (invalidClass.value !== '') {
            result.push(CssClass.Invalid);
        }
        result.push(CssClass.Selected);
        if (isSet(selected.custom)) {
            const custom: OptionsSmartCustom = selected.custom as OptionsSmartCustom;
            if (isSet(custom.selectState)) {
                if (isSet(custom.selectState?.customComparator)) {
                    const customResult: OptionsSmartCustomSelectState = custom.selectState?.customComparator!();
                    result.push('border-' + customResult.borderColor as string);
                } else if (isSet(custom.selectState?.borderColor)) {
                    result.push('border-' + custom.selectState!.borderColor as string);
                }
            }
            if (isSet(custom.checkboxColor) && isCheckbox) {
                result.push('checkbox-' + custom.checkboxColor as string);
            }
        }
    }

    return result.join(' ');
}

function isVisibleTooltipBlock(option: InputOption): boolean {
    return isSet(option.tooltip) &&
        (
            (option.tooltip as Tooltipster).title !== '' ||
            (option.tooltip as Tooltipster).description !== ''
        );
}

function tooltipTitle(option: InputOption): string {
    return (option.tooltip as Tooltipster).title !== '' ?
        (option.tooltip as Tooltipster).title : '';
}

function tooltipDescription(option: InputOption): string {
    return (option.tooltip as Tooltipster).description !== '' ?
        (option.tooltip as Tooltipster).description : '';
}

function isVisibleExtraBlock(option: InputOption, type: OptionsSmartTypes): boolean {
    return isSet(option.custom) &&
        (option.custom as OptionsSmartCustom).type === type &&
        itemIsSelected(option);
}

function extraInfoText(option: InputOption): string {
    let result: string = '';
    if (isSet(option.custom)) {
        result = String((option.custom as OptionsSmartCustom).initialValue);
    }

    return result;
}

function placeholderText(option: InputOption): string {
    let result: string = '';
    if (isSet(option.custom) && isSet((option.custom as OptionsSmartCustom).placeholder)) {
        result = String((option.custom as OptionsSmartCustom).placeholder);
    }

    return result;
}

function itemIsSelected(selected: InputOption): boolean {
    let result: boolean = false;
    if (isCheckbox.value && hasCheckboxValue(String(selected.value))) {
        result = true;
    } else if (String(selected.value) === props.formField.value.selected) {
        result = true;
    }

    return result;
}

function itemIsDisabled(option: InputOption): boolean {
    return (option.disabled || maxValuesIsReached()) && !itemIsSelected(option);
}

function onOptionClick(option: InputOption): void {
    if (!option.disabled && !option.emit) {
        select(String(option.value));
    } else {
        emit('option-confirm', option.value);
    }
}

function select(value: string): void {
    props.formField.patch({
        selected: isCheckbox.value ? checkboxValue(value) : String(value),
        extra: extraValues()
    });
}

function onCalendarChange(option: InputOption, index: number): void {
    field.value.extra[option.value as string] =
        form.field('extra-calendar-' + index).value;
    props.formField.setValue(field.value);
}

function calendarBinds(option: InputOption): DynamicDictionary {
    const result: DynamicDictionary = {};
    if (isSet(option.custom) && isSet((option.custom as OptionsSmartCustom).binds)) {
        const binds: InputDateWithCalendarBinds =
            (option.custom as OptionsSmartCustom).binds as InputDateWithCalendarBinds;
        Object.keys(binds).forEach((key: string) => {
            if (key === 'placeholder') {
                result.dateVisualEmptyMarker = binds[key];
                result.mobileButtonText = binds[key];
            } else {
                result[key] = (binds as DynamicDictionary)[key];
            }
        });
    }

    return result;
}

function onSumChange(option: InputOption): void {
    const oldValue: LimitedVariant = props.formField.value.extra[option.value as string];
    const sanitized: LimitedVariant = Sanitizer.cleanInputNumber(oldValue as string);
    if (oldValue !== sanitized) {
        field.value.extra[option.value as string] = sanitized;
    }
}

function exceedLimit(option: InputOption): boolean {
    const customParams: OptionsSmartCustom = option.custom as OptionsSmartCustom;

    return Number(customParams.limit) > 0 && Number(field.value.extra[option.value as string]) > Number(customParams.limit);
}

function limitExceedAlert(option: InputOption): string {
    const customParams: OptionsSmartCustom = option.custom as OptionsSmartCustom;

    return translateForType('error_' + (customParams.limitType as string) + '_exceed',
        props.translationType, {'%limit%': customParams.limit!.toString()})
}

function hasCheckboxValue(value: string): boolean {
    return String(props.formField.value.selected).includes(value);
}

function maxValuesNotReached(): boolean {
    return (props.maxSelect > 0 && checkboxValues.value.length < props.maxSelect) ||
        props.maxSelect === 0;
}

function maxValuesIsReached(): boolean {
    return !maxValuesNotReached();
}

function prepareValue(): void {
    props.formField.patch({
        selected: '',
        extra: {},
    });
}

function checkboxValue(value: string): string {
    const checkBoxValues: string[] = checkboxValues.value;
    if (!checkBoxValues.includes(value) && maxValuesNotReached()) {
        checkBoxValues.push(value);
    } else if (checkBoxValues.includes(value)) {
        const index: number = checkBoxValues.indexOf(value);
        if (index > -1) {
            checkBoxValues.splice(index, 1);
        }
    }

    return checkBoxValues.join(checkboxValuesSeparator);
}

function extraValues(): DynamicDictionary {
    let result: DynamicDictionary = {};
    props.options.forEach((option: InputOption, index: number) => {
        if (option.custom && valueIsInSelectedValues(option.value)) {
            if ((option.custom as OptionsSmartCustom).type === OptionsSmartTypes.Calendar) {
                result[String(option.value)] = form.field('extra-calendar-' + index).value;
            } else {
                result[String(option.value)] = (option.custom as OptionsSmartCustom).initialValue;
            }
        }
    });

    return result;
}

function valueIsInSelectedValues(searchValue: string | number | boolean): boolean {
    let result: boolean = false;
    const values: string[] = checkboxValues.value;
    values.forEach((value: string) => {
        if (String(value) === String(searchValue)) {
            result = true;
        }
    });


    return result;
}

function setupForm(): Promise<void> {
    return new Promise(resolve => {
        form.removeFields();
        props.options.forEach((option: InputOption, index: number) => {
            if (isSet(option.custom)) {
                if ((option.custom as OptionsSmartCustom).type === OptionsSmartTypes.Calendar) {
                    form.addField(new FormField('extra-calendar-' + index, ''));
                }
            }
        });
        resolve();
    });
}

function setupCalendars(): void {
    props.options.forEach((option: InputOption, index: number) => {
        if (isSet(option.custom)) {
            if ((option.custom as OptionsSmartCustom).type === OptionsSmartTypes.Calendar) {
                if (props.formField.value.extra && props.formField.value.extra[option.value as string]) {
                    form.field('extra-calendar-' + index).patch(
                        props.formField.value.extra[option.value as string]
                    );
                } else if ((option.custom as OptionsSmartCustom).initialValue) {
                    if (rangeModeHasNoRangeDate(option)) {
                        logError(ErrorType.Error, 'setupCalendars',
                            'Range calendar receive initial value that is not range value (startDate,endDate)');
                    } else {
                        form.field('extra-calendar-' + index).patch(
                            (option.custom as OptionsSmartCustom).initialValue as LimitedVariant
                        );
                    }
                } else {
                    form.field('extra-calendar-' + index).patch(
                        {startDate: '', endDate: ''}
                    );
                }
            }
        }
    });
}

function rangeModeHasNoRangeDate(option: InputOption): boolean {
    let result: boolean = false;
    if (isSet((option.custom as OptionsSmartCustom).binds &&
        ((option.custom as OptionsSmartCustom).binds as InputDateWithCalendarBinds)
            .isRange)) {
        const initialValue: OptionsCustomValue =
            (option.custom as OptionsSmartCustom).initialValue as OptionsCustomValue;
        if (initialValue && (
            !isSet((initialValue as DynamicDictionary).startDate) ||
            !isSet((initialValue as DynamicDictionary).endDate)
        )) {
            result = true;
        }
    }

    return result;
}

function validators(): object {
    return {
        isValid: () => {
            let result: boolean = true;
            const withUserInput: OptionsSmartTypes[] = [
                OptionsSmartTypes.Text,
                OptionsSmartTypes.Textarea,
                OptionsSmartTypes.Sum,
                OptionsSmartTypes.Calendar,
            ];
            if (props.required) {
                result = props.formField.value ?
                    (isSet(props.formField.value.selected) && props.formField.value.selected !== '') :
                    false;
            }
            props.options.forEach((option: InputOption) => {
                if (isSet(option.custom)) {
                    const values: string[] = isCheckbox.value ?
                        String(props.formField.value.selected).split(checkboxValuesSeparator) :
                        [props.formField.value.selected];
                    values.forEach((value: string) => {
                        if (String(option.value) === value) {
                            if (withUserInput.includes((option.custom as OptionsSmartCustom).type)) {
                                if ((option.custom as OptionsSmartCustom).required) {
                                    if (props.formField.value.extra[option.value as string] === '') {
                                        result = false;
                                    }
                                }
                            }
                            if (itemIsSelected(option)) {
                                if (isSet(option.custom)) {
                                    const custom: OptionsSmartCustom = option.custom as OptionsSmartCustom;
                                    if (isSet(custom.selectState)) {
                                        if (isSet(custom.selectState?.customComparator)) {
                                            const customResult: OptionsSmartCustomSelectState = custom.selectState?.customComparator!();
                                            if (customResult.isInvalid) {
                                                result = false;
                                            }
                                        } else if (custom.selectState?.isInvalid === true) {
                                            result = false;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            });

            return result;
        }
    }
}

function isDateSelected(option: InputOption): boolean {
    const calendarField: DynamicDictionary = field.value.extra[option.value as string];

    return calendarField.startDate !== '' || calendarField.endDate !== '';
}

watch(() => props.formField.value, (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        if (props.formField.isEmpty()) {
            select(newValue.selected);
        }
        emit('change', newValue, oldValue);
        setupCalendars();
    }
});

watch(() => props.options, (newValue, oldValue) => {
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue) && !(
        props.formField.value?.selected && (newValue as InputOption[]).some((
            option: InputOption
        ) => option.value === props.formField.value.selected)
    ) && !props.skipOptionsChangeFormReset) {
        props.formField.patch({
            selected: '',
            extra: {}
        });
    }
}, {deep: true, immediate: true});
</script>

<template>
    <div class="options-smart-list"
         :id="formField.name"
         :class="{...formField.classes(), 'disabled': disabled}"
         :data-store="dataStoreDisabled ? '' : formField.name"
         :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)">
        <div class="label informative" v-if="label">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="options-smart-list-option"
             v-for="(option, index) in options"
             :key="index"
             :class="itemClasses(option)">
            <button class="options-smart-list-panel"
                    :class="invalidClass"
                    :data-type="formField.name + '-button-' + index"
                    :data-index="index"
                    @click="onOptionClick(option)">
          <span class="checkbox-icon"
                v-if="isCheckbox">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.8L4.42857 9L13 1" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
                </svg>
          </span>
                <span class="radio-icon"
                      v-if="!isCheckbox"></span>
                {{ option.name }}
            </button>
            <div class="options-smart-list-tooltipster"
                 v-if="isVisibleTooltipBlock(option)">
                <app-tooltipster
                    :title="tooltipTitle(option)"
                    :description="tooltipDescription(option)">
                </app-tooltipster>
            </div>
            <div class="options-smart-list-extra-container"
                 v-if="isVisibleExtraBlock(option, optionsSmartTypes.Info)">
                <div class="options-smart-list-extra options-smart-list-extra-info"
                     v-html="extraInfoText(option)"></div>
            </div>
            <div class="options-smart-list-extra-container"
                 v-if="isVisibleExtraBlock(option, optionsSmartTypes.Text)">
                <input type="text"
                       class="options-smart-list-extra options-smart-list-extra-text"
                       :id="formField.name + '-extra-' + index"
                       v-model="field.value.extra[option.value]"
                       :name="formField.name + '-extra-' + index"
                       :placeholder="placeholderText(option)">
            </div>
            <div class="options-smart-list-extra-container"
                 v-if="isVisibleExtraBlock(option, optionsSmartTypes.Textarea)">
            <textarea class="options-smart-list-extra options-smart-list-extra-textarea"
                      :id="formField.name + '-extra-' + index"
                      v-model="field.value.extra[option.value]"
                      :name="formField.name + '-extra-' + index"
                      :rows="4"
                      :placeholder="placeholderText(option)">
            </textarea>
            </div>
            <div class="options-smart-list-extra-container"
                 v-if="isVisibleExtraBlock(option, optionsSmartTypes.Sum)">
                <div class="options-smart-list-extra-sum-container">
                    <input type="text"
                           :class="exceedLimit(option) ? 'options-smart-list-extra-sum-invalid' : 'options-smart-list-extra-sum'"
                           class="options-smart-list-extra"
                           :id="formField.name + '-extra-' + index"
                           v-model="field.value.extra[option.value]"
                           :name="formField.name + '-extra-' + index"
                           :placeholder="placeholderText(option)"
                           @input="onSumChange(option)"
                           @change="onSumChange(option)"
                           @keyup="onSumChange(option)">
                    <span class="sum-label">{{ sumLabel }}</span>
                    <div class="limit-exceed"
                         v-if="exceedLimit(option)" v-html="limitExceedAlert(option)"></div>
                </div>
            </div>
            <div class="options-smart-list-extra-container"
                 v-if="isVisibleExtraBlock(option, optionsSmartTypes.Calendar)">
                <div class="options-smart-list-extra options-smart-list-extra-calendar">
                    <app-input-date-with-calendar
                        :key="uuid.v4()"
                        class="calendar"
                        :class="{'dark' : isDateSelected(option) }"
                        v-bind="calendarBinds(option)"
                        :form-field="form.field('extra-calendar-' + index)"
                        :disabled="false"
                        :data-store-disabled="true"
                        @change="onCalendarChange(option, index)">
                    </app-input-date-with-calendar>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.options-smart-list {
    width: 100%;

    .label {
        &.informative {
            margin-bottom: 21px;
            font-size: var(--font-size-small);
            font-weight: 600;
            color: var(--text-color-default);
        }
    }

    &-option {
        position: relative;
        width: 100%;
        min-height: 52px;
        margin-bottom: var(--size-nano);
        background-color: var(--white);
        border: 1px solid transparent;
        border-radius: 8px;
        transition: border-color .6s;

        &:last-child {
            margin-bottom: 0;
        }

        &:focus:not(.disabled):not(.selected),
        &:hover:not(.disabled):not(.selected) {
            border: 1px solid var(--component-color-border-default);
        }

        &:not(:focus):not(:hover):not(.disabled):not(.selected):not(.invalid)::before {
            content: '';
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px solid var(--component-color-border-default);
            border-radius: 8px;
            margin-top: -1px;
            margin-left: -1px;
        }
    }

    &-panel {
        position: relative;
        width: 95%;
        text-align: left;
        font-size: var(--font-size-tiny);
        line-height: var(--line-height-accented);
        font-weight: 600;
        color: var(--text-color-default);
        padding: 13px 22px 13px 55px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -12px;
            margin-left: 14px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: 2px solid var(--component-color-border-default);
        }

        .checkbox-icon {
            display: none;
            position: absolute;
            left: 19px;
            top: 50%;
            margin-top: -12px;

            svg {
                min-height: 24px;
            }
        }

        .radio-icon {
            display: none;
            position: absolute;
            top: 50%;
            margin-top: -6px;
            left: 20px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--white);
        }
    }

    &-tooltipster {
        position: absolute;
        right: 13px;
        top: 20px;
    }

    &-option:focus-within:not(.disabled) {
        border: 1px solid var(--component-color-border-default);

        &::before {
            display: none;
        }
    }

    .options-smart-list-radio {
        .options-smart-list-panel {
            width: 95%;

            &::before {
                border-radius: 50%;
            }
        }
    }

    .selected {
        border: 1px solid var(--component-color-border-default);

        .options-smart-list-panel {
            &::before {
                border-color: var(--brand-red);
                background-color: var(--brand-red);
            }

            .checkbox-icon {
                display: block;
            }

            .radio-icon {
                display: block;
                background-color: var(--brand-red);
            }
        }

        &.checkbox-green {
            &.options-smart-list-checkbox {
                .options-smart-list-panel {
                    &::before {
                        border-color: var(--text-color-link);
                        background-color: var(--text-color-link);
                    }
                }
            }
        }

        &.options-smart-list-radio {
            .options-smart-list-panel {
                &::before {
                    background-color: var(--white);
                }
            }
        }

        &.border-red {
            border-color: var(--brand-red);
        }

        &.border-orange {
            border-color: var(--orange-500);
        }

        &.border-green {
            border-color: var(--text-color-link);

            &.options-smart-list-radio:not(.invalid) {
                .options-smart-list-panel {
                    &::before {
                        border-color: var(--text-color-link);
                    }
                }

                .radio-icon {
                    background-color: var(--text-color-link);
                }
            }
        }

        &.border-blue {
            border-color: var(--brand-blue);
        }
    }

    .options-smart-list-option.disabled {
        background-color: var(--background-light);

        .options-smart-list-panel {
            color: var(--black-200);

            .radio-icon,
            .checkbox-icon {
                display: none;
            }
        }
    }

    .options-smart-list-option.invisible {
        visibility: hidden;
        height: 0;
        min-height: 0;
        margin: 0;
        padding: 0;
    }

    &-extra-container {
        padding: 0 15px 15px;
        width: 100%;
    }

    &-extra {
        width: 100%;
        border-radius: 4px;
        font-weight: 500;
        font-size: var(--font-size-nano);
        mix-blend-mode: normal;
        font-family: var(--text-font-stack);
        &-info {
            line-height: 19px;
        }
        &-sum,
        &-text,
        &-textarea {
            padding: var(--size-small) 30px var(--size-small) var(--size-small);
            background-color: var(--white);
            border: 1px solid var(--black-200);
            color: var(--text-color-default);

            &::placeholder {
                opacity: .56;
                color: var(--black-500);
            }

            &:hover {
              @include input-hover;
            }

            &:focus {
              @include input-focus;
            }
        }

        &-textarea {
            height: 104px;
            resize: none;
        }

        &-text,
        &-sum {
            padding: var(--size-tiny) 20px var(--size-tiny) var(--size-small);

            @include respond-above('sm') {
                padding: var(--size-tiny) 80px var(--size-tiny) var(--size-small);
            }
        }

        &-sum-container {
            position: relative;
            width: 100%;

            &::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 24px;
                background-color: var(--black-200);
                right: 70px;
                top: 13px;
                display: block;
            }

            .sum-label {
                position: absolute;
                color: var(--black-500);
                right: 0;
                width: 70px;
                text-align: center;
                line-height: 52px;
            }

            input {
                width: 100%;
            }
        }

        &-sum {
            position: relative;
            width: 100%;

            &-invalid {
                padding: var(--size-tiny) 80px var(--size-tiny) 20px;
                border: 1px solid var(--brand-red);
                background-color: var(--red-50);

                &:focus {
                    border-color: transparent;
                    outline: solid 2px var(--component-color-border-error);
                    background-color: var(--white);
                }
            }
        }

        &-calendar {
            ::v-deep .calendar {
                width: 100%;

                .wrapper {
                    .localized {
                        color: var(--text-color-default);
                    }
                }
            }
        }

        ::v-deep .dark {
            .wrapper {
                .localized {
                    color: var(--text-color-default);
                }
            }
        }
    }

    &-option.invalid:not(.omit-red-borders) {
        border-color: var(--brand-red);
        outline-color: var(--brand-red);
        background-color: var(--red-50);

        &:focus {
          @include input-focus;
          outline-color: var(--brand-red);
          background-color: var(--white);
        }
    }

    &-option.omit-red-borders:not(:focus):not(:hover):not(.disabled):not(.selected)::before {
        content: '';
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 1px solid var(--component-color-border-default);
        border-radius: 8px;
        margin-top: -1px;
        margin-left: -1px;
    }

    .limit-exceed {
        color: var(--brand-red);
        font-size: var(--font-size-nano);
        margin-top: 8px;
    }

    .options-smart-list-option.filled {
		.options-smart-list-tooltipster {
			right: 20px;

			div {
				margin-top: 0;
			}
		}

		.options-smart-list-panel {
			padding: 20px 20px 20px 64px;

			&::before {
				margin-left: 20px;
			}

			span {
				left: 25px;
			}
		}

		&.selected {
			border-width: 2px;
			border-color: var(--component-color-border-active);
			background-color: var(--system-color-success-light);

			.options-smart-list-panel {
				&::before {
					border-color: var(--system-color-success-dark);
					background-color: var(--system-color-success-dark);
				}

				.radio-icon {
					margin-top: -4px;
					width: var(--size-pico);
					height: var(--size-pico);
					left: 28px;
					background-color: var(--background-light);
				}
			}
		}
    }
}
</style>
