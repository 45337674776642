export default class Url {

    private urlParams: URLSearchParams;

    constructor(private url: string = window.location.href) {
        this.urlParams = new URLSearchParams(url.split('?')[1] || '');
    }

    public segments(): string[] {
        const hrefWithoutParams: string = this.url.split('?')[0];
        const matches: string[] = hrefWithoutParams.match('^https?:\\/\\/[^\\/]*(.*)') || [];
        const segmentsString: string = matches.length === 0 ? hrefWithoutParams : (matches[1] || '');

        return segmentsString.split('/').filter((segment: string) => !!segment);
    }

    public setQueryParam(paramName: string, paramValue: string): void {
        this.urlParams.set(paramName, paramValue);
    }

    public deleteQueryParam(paramName: string): void {
        this.urlParams.delete(paramName);
    }

    public queryParamHas(paramName: string): boolean {
        return this.urlParams.has(paramName);
    }

    public queryParam(paramName: string, defaultValue: string | null = null): string | null {
        let value: string | null = this.urlParams.get(paramName);
        if (value) {
            value = value.split('#')[0];
        }

        return value || defaultValue;
    }

    public pathWithParams(): string {
        const path: string = this.url.split('?')[0];
        const params: string = this.urlParams.toString();

        return params.length === 0 ? path : path + '?' + params;
    }
}
