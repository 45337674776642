export default class DataLayerFacilities {
    public static readonly AccidentInsurance: string = 'Accident';
    public static readonly AutoInsurance: string = 'Auto';
    public static readonly BicycleInsurance: string = 'Bicycle';
    public static readonly BusinessInsurance: string = 'Business';
    public static readonly ConstructionInsurance: string = 'Construction';
    public static readonly GuaranteeInsurance: string = 'Guarantee';
    public static readonly HealthInsurance: string = 'Health';
    public static readonly LiabilityInsurance: string = 'Liability';
    public static readonly PropertyInsurance: string = 'Property';
    public static readonly Promo: string = 'Promo';
    public static readonly SpecialRisksInsurance: string = 'SpecialRisks';
    public static readonly TravelInsurance: string = 'Travel';
    public static readonly Contacts: string = 'Contacts';
    public static readonly UploadSourcePortal: string = 'from-folder';
    public static readonly UploadSourceQr: string = 'fromQR';
}
