<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import SettingsService from '@/services/settings.service';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import {computed, ComputedRef, onMounted, reactive} from 'vue';
import Translations from '@/services/translations.service';
import AppInputText from '@/Components/InputText/InputText.vue';
import {UnwrapNestedRefs} from 'vue/types/v3-generated';

const props = defineProps({
    placeholder: {type: String, default: () => ''},
    formField: {type: FormField, default: () => new FormField('')},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    dataStoreDisabled: {type: Boolean, default: false},
    disableErrorText: {type: Boolean, default: false},
    supportTextMessage: {type: String, default: ''},
});

const emit = defineEmits(['change', 'input']);

const settings: SettingsService = SettingsService.getInstance();
const translations: Translations = Translations.getInstance();

const field: UnwrapNestedRefs<FormField> = reactive(props.formField);

const feedbackText: ComputedRef<string> = computed(() => {
    return field.hasError('isValidRestrictedEmail') ?
        translations.localized('input_error_restricted_email') : '';
});

onMounted((): void => {
    field.addSanitizer(Sanitizer.cleanEmail);
    field.addValidators(baseValidators());
    if (isEmailRestrictionEnabled()) {
        field.addValidators(restrictedEmailValidators());
    }
    if (!field.isEmpty()) {
        onChange();
    }
});

function onKeyUp(): void {
    onChange();
}

function onInput(): void {
    emit('input', field.value);
}

function onChange(): void {
    field.touch();
    field.sanitize();
    field.validate().then(() => {
        if (field.isEmpty()) {
            field.markAsUntouched();
        }
    });
    emit('change', field.value);
}

function baseValidators(): object {
    return {
        isValidEmail: Validation.isValidEmail,
    };
}

function restrictedEmailValidators(): object {
    return {
        isValidRestrictedEmail: Validation.isValidRestrictedEmail,
    }
}

function isEmailRestrictionEnabled(): boolean {
    return settings.restrictedEmailsEnabled();
}

</script>

<template>
    <app-input-text
        :class="'input-email'"
        :required="required"
        :name="formField.name"
        :form-field="formField"
        :feedback-message="feedbackText"
        :label="label"
        :autocomplete="'email'"
        :disabled="disabled"
        :placeholder="placeholder"
        :data-store-disabled="dataStoreDisabled"
        :disable-error-text="disableErrorText"
        @input="onInput"
        @change="onChange"
        @keyup="onKeyUp"
        v-lowercase>
        <template v-slot:app-tooltipster>
            <slot name="app-tooltipster"></slot>
        </template>
    </app-input-text>
</template>

<style lang="scss" scoped>
.input-email {
    scroll-margin-top: 4em;

    @include respond-below('md') {
        width: 100%;
    }
}
</style>
