export default class ButtonIcon {
    public static readonly View: string = 'view';
    public static readonly Add: string = 'add';
    public static readonly AddFile: string = 'add-file';
    public static readonly Delete: string = 'delete';
    public static readonly Download: string = 'download';
    public static readonly Check: string = 'check';
    public static readonly ArrowRight: string = 'arrow-right';
    public static readonly LongArrowRight: string = 'long-arrow-right';
    public static readonly LongArrowLeft: string = 'long-arrow-left';
    public static readonly Warning: string = 'warning';
    public static readonly Eye: string = 'eye';
    public static readonly Covered: string = 'covered';
    public static readonly Edit: string = 'edit';
    public static readonly Evacuator: string = 'evacuator';
    public static readonly Mail: string = 'mail';
    public static readonly Phone: string = 'phone';
    public static readonly User: string = 'user';
    public static readonly World: string = 'world';
}
